import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import useAttachFile from './useAttachFile';
import ModalError from '../../../components/ErrorModal';
import { SendMessage } from '../Chat/hooks/useChat';
import { ALLOWED_FILE_TYPES } from './constants';

type IAttachFile = {
  disabled: boolean;
  sendMessage?: SendMessage;
};

export const errorKeys: { [index: number]: string } = { 413: 'errorFileTooLarge' };
export const defaultErrorKey = 'errorUploadFileText';

export const AttachFile: React.FC<IAttachFile> = (props) => {
  const { t } = useTranslation();
  const { disabled, sendMessage } = props;
  const { attachFile, status } = useAttachFile(sendMessage);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const messageKey = errorKeys[status] || defaultErrorKey;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      attachFile(event.target.files);
    }
  };

  const clearInput = (event: React.MouseEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    input.value = '';
  };

  useEffect(() => {
    const isSuccessful = status >= 200 && status < 300;
    setOpenErrorModal(!isSuccessful);
  }, [status]);

  return (
    <>
      <Tooltip title={`${t('sendFiles')}`} placement="top">
        <IconButton color="neutral2" aria-label="upload picture" component="label" disabled={disabled} variant="text">
          <input
            id="icon-button-file"
            data-testid="file-input"
            hidden
            type="file"
            accept={ALLOWED_FILE_TYPES}
            onChange={handleOnChange}
            onClick={clearInput}
            disabled={disabled}
            multiple
          />
          <i className="fa-regular fa-paperclip fa-sm" />
        </IconButton>
      </Tooltip>

      <ModalError
        handleClose={() => setOpenErrorModal(false)}
        message={t(messageKey)}
        title={t('errorUploadFileTitle')}
        open={openErrorModal}
      />
    </>
  );
};
