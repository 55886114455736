import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IFormChangePassword } from '.';

export const schemaChangePassword: SchemaOf<IFormChangePassword> = Yup.object({
  password: Yup.string()
    .min(6, 'fieldIsRequired')
    .test('isValidPass', 'fieldIsRequired', (value) => {
      if (value) {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber];
        const validConditions = conditions.reduce((acc, condition) => (condition ? acc + 1 : acc), 0);
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
      return false;
    })
    .required('fieldIsRequired'),
}).required();
