import React from 'react';
import { List, ListItem, ListItemButton, ListItemText, Popper, ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OptionButton, OptionButtonText } from './style';
import { IMenuOptionsProps } from './types';
import { IOptionsListProps } from '../../types/optionsList';
import shadows from '../../theme/shadows';

const MenuOptions: React.FC<IMenuOptionsProps> = (props: IMenuOptionsProps) => {
  const { anchorEl, handleClose, options, position, status } = props;
  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();

  return (
    <Popper
      style={{ zIndex: 99999 }}
      placement={position === 'top' ? 'top-end' : 'bottom-end'}
      id={idPopover}
      data-testid={idPopover}
      open={open}
      anchorEl={anchorEl}
      role="popoverMenuOptions"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <List
          disablePadding
          sx={{
            minWidth: '190px',
            maxWidth: '240px',
            border: '1px solid neutral.var40',
            boxSizing: 'border-box',
            boxShadow: shadows.shadowBlack4,
            borderRadius: '4px',
            backgroundColor: 'common.white',
            padding: '4px 0px',
          }}
        >
          {options &&
            options.map((item: IOptionsListProps, index: number) => {
              if (item.component) return item.component;
              return (
                <ListItem
                  role="itemOptionDropdownMenu"
                  key={index}
                  onClick={() => {
                    item.function && item.function();
                    handleClose();
                  }}
                  alignItems="flex-start"
                  disablePadding
                  sx={{
                    height: '36px',
                    width: '100%',
                    background:
                      status === 'available' && item.id === 'StatusOn'
                        ? 'neutral.var20'
                        : status === 'not-available' && item.id === 'StatusOff'
                        ? 'neutral.var20'
                        : 'common.white',
                    ':hover': {
                      background: 'neutral.var20',
                    },
                  }}
                >
                  <ListItemButton
                    disableGutters
                    sx={{
                      alignItems: 'center',
                      paddingLeft: '10px',
                    }}
                  >
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={
                        <OptionButton>
                          {item.icon}
                          <OptionButtonText
                            sx={{
                              color:
                                status === 'available' && item.id === 'StatusOn'
                                  ? 'neutral.var90'
                                  : status === 'not-available' && item.id === 'StatusOff'
                                  ? 'neutral.var90'
                                  : 'neutral.var80',
                            }}
                          >
                            {t(`${item.title}`)}
                          </OptionButtonText>
                        </OptionButton>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
      </ClickAwayListener>
    </Popper>
  );
};

export default MenuOptions;
