import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

interface ICreateContact {
  contact: string;
  success: boolean;
}

export interface IParamsCreateContact {
  token: string;
  name: string;
  phone: string;
  customFields?: {
    [key: string]: string;
  };
  contactManager?: {
    username?: string;
  };
}

export const createContact = async (props: IParamsCreateContact): Promise<AxiosResponse<ICreateContact>> => {
  const { token, name, phone, customFields, contactManager } = props;

  const params = {
    token,
    name,
    phone,
    customFields,
    contactManager,
    username: token,
  };

  return AxiosInstance.post('omnichannel/contact', params);
};
