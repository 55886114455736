import React from 'react';
import { Pagination, PaginationItem, Stack, paginationItemClasses, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { IPagination } from './types';
import { RowsPerPage } from '../../constants/pagination';

type Options = {
  [key: string]: string;
};

const CustomPagination: React.FC<IPagination> = (props: IPagination) => {
  const { count, page, handleChangePage, isMobile } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const getPage = (type: string) => {
    const options: Options = {
      page: 'pageNumber',
      previous: 'pagePrevious',
      next: 'pageNext',
    };
    const DEFAULT_OPTION = 'paginationItem';

    return options[type] || DEFAULT_OPTION;
  };

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography data-testid="displayPage" variant="body1" color="neutral.var100">{`${t('displayResults')} ${
        (page - 1) * RowsPerPage + 1
      } - ${count < RowsPerPage * page ? count : RowsPerPage * page} ${t('of')} ${count}`}</Typography>
      <Pagination
        size={isMobile ? 'small' : 'medium'}
        siblingCount={isMobile ? 0 : 1}
        boundaryCount={isMobile ? 0 : 1}
        data-testid="custom-pagination"
        renderItem={(item) => (
          <PaginationItem
            role={getPage(item.type)}
            sx={{
              color: theme.palette.neutral.var100,
              [`${`&.${paginationItemClasses.selected}`}`]: {
                backgroundColor: theme.palette.primary.light,
              },
            }}
            {...item}
          />
        )}
        variant="text"
        count={Math.ceil(count / RowsPerPage)}
        page={page == 0 ? 1 : page}
        onChange={handleChangePage}
      />
    </Stack>
  );
};

export default CustomPagination;
