import { useTranslation } from 'react-i18next';
import { NoRowsOverlay } from'../styles'

const NoRows = () => {
  const { t } = useTranslation();
  return (
    <NoRowsOverlay>
      <img src="https://storage.googleapis.com/yalo-img/console/channels/empty.svg" alt="overlay" />
      <span>{t('business-hours.table.noRowsTitle')}</span>
      <span>{t('business-hours.table.noRowsDescription')}</span>
    </NoRowsOverlay>
  );
}

export default NoRows;
