import { styled, shadow4 } from '@engyalo/design-system';
import { LinearProgress } from '@mui/material';

export const UploadStatusWrapper = styled('div')(
  ({ theme }) => `
  position: fixed;
  bottom: 0rem;
  left: 1.2rem;
  padding: 0.8rem;
  width: 30rem;
  border-radius: 0.4rem;
  box-shadow: ${shadow4};
  background-color: ${theme.palette.background.paper};
  z-index: ${theme.zIndex.modal};
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.open {
    opacity: 1;
    visibility: visible;
    bottom: 1.2rem;
  }
`
);

export const UploadList = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-height: 20rem;
  overflow-y: auto;
`
);

export const UploadItem = styled('div')(
  () => `
  position: relative;
  display: flex;
  flex-direction: column;
`
);

export const UploadItemContent = styled('div')(
  () => `
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.8rem 1.2rem;
  padding: 0.8rem;
  z-index: 1;
`
);

export const UploadProgress = styled(LinearProgress)(
  () => `
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.4rem;
  opacity: 0.2;
`
);
