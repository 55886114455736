import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { List, Collapse, Divider } from '@mui/material';
import { flag, useFeatureFlag } from 'hooks/useFeatureFlag';
import ChatItem from '../ChatItem';
import ChatsContext from '../../contexts/chats/context';
import { ICollapseView } from '../../contexts/menuChats/types';
import MenuChatsContext from '../../contexts/menuChats/context';
import { IRoom } from '../../types/room';

export const CollapseView = ({ item, type }: ICollapseView) => {
  const { goToNewChat, serviceSelected } = useContext(ChatsContext);
  const isFIFO = useFeatureFlag(flag.FIFO_INQUIRIES);
  const {
    setOpenContactManager,
    openContactManager,
    setOpenChatsManager,
    openChatsManager,
    setOpenServiceDashboard,
    openServiceDashboard,
  } = useContext(MenuChatsContext);
  const handleStartService = (service: IRoom) => {
    const serviceMap: [boolean, Dispatch<SetStateAction<boolean>>][] = [
      [openContactManager, setOpenContactManager],
      [openChatsManager, setOpenChatsManager],
      [openServiceDashboard, setOpenServiceDashboard],
    ];
    serviceMap.forEach(([value, setter]) => {
      if (value) {
        setter(() => false);
      }
    });

    goToNewChat(service);
  };

  const itemRoomsParsed = useMemo(() => {
    const filteredRooms = item.rooms?.filter(({ lastMessage }: IRoom) => !!lastMessage?.ts) ?? [];
    if (isFIFO) {
      return filteredRooms;
    }

    return filteredRooms.sort(
      ({ lastMessage: { ts: aLast } }: IRoom, { lastMessage: { ts: bLast } }: IRoom) =>
        new Date(bLast).getTime() - new Date(aLast).getTime()
    );
  }, [item.rooms, isFIFO]);

  return (
    <Collapse in={item.expanded}>
      <List component="div" disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
        {itemRoomsParsed.map((room: IRoom & { hasDiscussion: boolean }) => {
          const { _id, lastMessage, fname, tags, unreadChat, unreadMessages, hasDiscussion } = room;
          return (
            <React.Fragment key={_id}>
              <ChatItem
                iscurrentchat={serviceSelected?._id === _id}
                key={_id}
                id={_id}
                onClick={() => handleStartService(room)}
                message={lastMessage}
                name={fname}
                tags={tags}
                hasError={false}
                sessionType={item.id}
                unreadChat={!!unreadChat}
                unreadMessages={unreadMessages || 0}
                room={room}
                tabType={type}
                hasDiscussion={hasDiscussion}
              />

              <Divider variant="fullWidth" component="li" />
            </React.Fragment>
          );
        })}
      </List>
    </Collapse>
  );
};
