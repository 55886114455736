import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { RocketChatUser } from './types';

export const updateUserInfo = async (user: RocketChatUser): Promise<AxiosResponse> => {
  const { userId } = user;
  const data = { ...user, userId: undefined };
  return AxiosInstance.post('users.update', {
    userId,
    data,
  });
};
