import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@engyalo/design-system';
import { IError } from '../../../components/Tags/CreateTagModal/types';
import { tagsColor } from '../../../helpers/tagsColors';
import { CustomChip } from '../../../components/Tags/style';
import { dateToText } from '../../../helpers/dateToText';
import { getCannedMessages } from '../../../services/getCannedMessages';
import { getLiveChatUsersAgent } from '../../../services/getLivechatUsersAgt';
import { deleteCannedMessages } from '../../../services/deleteCannedMessages';
import { createKeyToken } from '../../../helpers/createKeyToken';
import { CannedMessage, User } from '../../../services/types';

type ScopeType = 'global' | 'user' | 'department';

const useCannedMessages = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
  const [cannedMessages, setCannedMessages] = useState<CannedMessage[]>([]);
  const [selectCannedMessage, setSelectCannedMessage] = useState<CannedMessage>();
  const [headerColumns, setHeaderColumns] = useState<Array<string>>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [usersCreatedBy, setUsersCreatedBy] = useState<User[]>([]);
  const [filterCannedMessages, setFilterCannedMessages] = useState({
    shortcut: '',
    scope: '',
    createdBy: '',
  });
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showModalUnsavedChanges, setShowModalUnsavedChanges] = useState(false);
  const [errorMessage, setErrorMessage] = useState<IError>();
  const handleCurrentPage = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  }, []);

  const handleClickItem = (deleteItem: boolean, item?: any) => {
    if (deleteItem) {
      setOpenDeleteModal(!openDeleteModal);
    } else {
      setShowDrawer(!showDrawer);
    }

    if (item) {
      const select = cannedMessages.find(({ shortcut }) => shortcut === item.shortcut);
      if (select) {
        setSelectCannedMessage(select);
      }
      return;
    }
    setSelectCannedMessage(undefined);
  };

  const handleCloseTagModal = (confirm: boolean) => {
    if (confirm) {
      loadCannedMessages();
    }
    setOpenCreateTagModal(!openCreateTagModal);
  };

  const getScope = (scope: ScopeType) => {
    if (scope === 'global') {
      return t('allOrganization');
    }
    if (scope === 'user') {
      return t('onlyMe');
    }
    return t('specificTeam');
  };

  const getTags = (tags: Array<string> | undefined) => {
    if (tags && tags.length > 0) {
      const listTagsToShow = tags
        .map((tag) => {
          const color = tagsColor(tag);
          return <CustomChip style={{ marginRight: '8px' }} key={createKeyToken()} label={tag} customcolor={color} />;
        })
        .slice(0, 1);
      if (tags.length > 1) {
        listTagsToShow.push(<CustomChip key={createKeyToken()} label={`+${tags.length - 1}`} />);
      }
      if (tags.length > 1) {
        return (
          <Tooltip
            title={
              <div style={{ textAlign: 'justify' }}>
                {tags.map((item: string) => (
                  <div key={createKeyToken()}>{item}</div>
                ))}
              </div>
            }
          >
            <div>{listTagsToShow}</div>
          </Tooltip>
        );
      }
      return listTagsToShow;
    }
  };

  const handleTable = (list?: Array<any>) => {
    const listValues = list || cannedMessages;
    setHeaderColumns(['shortcut', 'tags', 'scope', 'createdBy', 'updatedAt']);

    const values = listValues.map((item, index) => {
      const updatedDate = new Date(item._updatedAt) as Date;
      return {
        shortcut: item.shortcut,
        tags: getTags(item.tags),
        scope: getScope(item.scope),
        createdBy: item.createdBy.username,
        updatedAt: dateToText(updatedDate)
          ? `${t('today')} ${updatedDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`
          : updatedDate.toLocaleString(),
        tagsList: item.tags && item.tags.length > 0 ? item.tags.join(',') : '',
        dateValue: updatedDate,
      };
    });
    setTableRows(values);
  };

  const loadCannedMessages = async () => {
    try {
      const {
        data: { cannedResponses, total },
      } = await getCannedMessages(
        filterCannedMessages.shortcut,
        filterCannedMessages.scope,
        filterCannedMessages.createdBy
      );
      setCannedMessages(cannedResponses);
      setTotalItems(total);
      handleTable(cannedResponses);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDelete = async (confirm: boolean) => {
    if (confirm && selectCannedMessage && selectCannedMessage._id) {
      try {
        const { data } = await deleteCannedMessages(selectCannedMessage._id);
        if (data?.success) {
          loadCannedMessages();
        }
      } catch (error) {
        const errorValues = {
          title: t('errorModelMessageModalTitle'),
          bodyMessage: t('errorModelMessageModalText'),
        };
        setErrorMessage(errorValues);
        setOpenErrorModal(true);
        console.log(error);
      }
    }
    setOpenDeleteModal(false);
  };

  const getUsersCreatedBy = async () => {
    try {
      const {
        data: { users },
      } = await getLiveChatUsersAgent();
      setUsersCreatedBy(users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeForm = (isChanged: boolean) => {
    setUnsavedChanges(isChanged);
  };

  useEffect(() => {
    loadCannedMessages();
    getUsersCreatedBy();
    handleTable();
  }, []);

  useEffect(() => {
    loadCannedMessages();
  }, [filterCannedMessages]);

  return {
    handleClickItem,
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    selectCannedMessage,
    handleCloseTagModal,
    openDeleteModal,
    handleConfirmDelete,
    openErrorModal,
    errorMessage,
    setOpenErrorModal,
    usersCreatedBy,
    setFilterCannedMessages,
    showDrawer,
    setShowDrawer,
    loadCannedMessages,
    handleChangeForm,
    unsavedChanges,
    showModalUnsavedChanges,
    setShowModalUnsavedChanges,
  };
};

export default useCannedMessages;
