import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { IMobile } from '../../../types/mobile';

export const Container = styled('div')<IMobile>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  padding: ${({ isMobile }) => (isMobile ? '16px' : 'unset')};
`;

export const Buttons = styled('div')<IMobile>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  margin-top: ${({ isMobile }) => (isMobile ? 'auto' : '32px')};
  gap: 20px;
`;

export const ItemStack = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  margin-top: 32px;
`;

export const ItemOpcionalStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
