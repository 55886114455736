import { EventEmitter } from 'eventemitter3';
import ReconnectingWebSocket, { Options, CloseEvent, ErrorEvent } from 'reconnecting-websocket';

export enum SocketEvents {
  Open = 'open',
  Message = 'message',
  Error = 'error',
  Close = 'close',
}

export enum ErrorMessages {
  PARSE_ERROR = 'Failed to parse incoming message',
}

class WebSocketService extends EventEmitter {
  private static instance: WebSocketService;

  private static url: string;

  private socket: ReconnectingWebSocket;

  constructor(url: string, options?: Options) {
    super();
    this.socket = new ReconnectingWebSocket(url, [], options);
    this.setupEventHandlers();
  }

  private onSocketOpen = (): void => {
    this.emit(SocketEvents.Open);
  };

  private onSocketMessage = (event: MessageEvent): void => {
    let response: unknown;

    try {
      response = JSON.parse(event.data);
    } catch (error) {
      this.emit(SocketEvents.Error, ErrorMessages.PARSE_ERROR);
    }

    this.handleMessage(response);
  };

  private onSocketError = (event: ErrorEvent): void => {
    this.emit(SocketEvents.Error, event);
  };

  private onSocketClose = (event: CloseEvent): void => {
    this.emit(SocketEvents.Close, event);
  };

  private setupEventHandlers = (): void => {
    this.socket.onopen = this.onSocketOpen;
    this.socket.onmessage = this.onSocketMessage;
    this.socket.onerror = this.onSocketError;
    this.socket.onclose = this.onSocketClose;
  };

  private handleMessage = (data: unknown): void => {
    this.emit(SocketEvents.Message, data);
  };

  static getInstance(url: string, options?: Options) {
    if (!this.instance || this.url !== url) {
      this.url = url;
      this.instance = new WebSocketService(url, options);
    }
    return this.instance;
  }

  sendMessage = (data: unknown): void => {
    this.socket.send(JSON.stringify(data));
  };

  disconnect = (): void => {
    this.socket.close();
  };
}

export default WebSocketService;
