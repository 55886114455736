import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileAnchor, FileContainer } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';
import { handleFileDownload } from './utils';

enum MimeTypes {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  RAR_DEPRECATED = 'application/x-rar-compressed',
  RAR = 'application/vnd.rar',
}

export const ICON_CLASS_MAP: Record<string, string> = {
  [MimeTypes.PDF]: 'fa-file-pdf',
  [MimeTypes.DOC]: 'fa-file-word',
  [MimeTypes.DOCX]: 'fa-file-word',
  [MimeTypes.XLS]: 'fa-file-excel',
  [MimeTypes.XLSX]: 'fa-file-excel',
  [MimeTypes.ZIP]: 'fa-file-archive',
  [MimeTypes.RAR]: 'fa-file-archive',
  [MimeTypes.RAR_DEPRECATED]: 'fa-file-archive',
};

const DEFAULT_ICON_CLASS = 'fa-file-alt';
const ERROR_ICON_CLASS = 'fa-file-slash';
const ERROR_DESCRIPTION_KEY = 'messages.mediaFallback';
const DEFAULT_DESCRIPTION_KEY = 'downloadFile';

const FileMessage: React.FC<ChatMessageComponentProps> = ({ document }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  if (!document) {
    return null;
  }

  const { url, mimeType = '', caption = '' } = document;

  const iconClass = hasError ? ERROR_ICON_CLASS : ICON_CLASS_MAP[mimeType] || DEFAULT_ICON_CLASS;
  const description = hasError ? t(ERROR_DESCRIPTION_KEY) : caption || t(DEFAULT_DESCRIPTION_KEY);

  const handleFileDownloadClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(url);

      if (!response.ok) {
        setHasError(true);
        return;
      }

      await handleFileDownload({ response, url, caption });
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FileContainer>
      <FileAnchor loading={loading} href={url} onClick={handleFileDownloadClick} rel="noopener noreferrer">
        {loading && <i data-testid="loading-icon" className="loading fa-regular fa-circle-notch" />}
        <i data-testid="file-icon" className={`file fa-regular ${iconClass}`} />
        {description}
      </FileAnchor>
    </FileContainer>
  );
};

export default FileMessage;
