import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';
import paramsSerializer from './AxiosParamsSerializer';
import { STORAGE_KEYS } from '../constants/defaultValues';

const AxiosInstanceYalo = axios.create({
  paramsSerializer,
  headers: { 'Content-type': 'application/json' },
});

export const setBaseUrlAxiosInstanceYalo = (path: string) => {
  AxiosInstanceYalo.defaults.baseURL = `${process.env.REACT_APP_NOTIFICATION_API_URL}`;
  AxiosInstanceYalo.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const authToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      if (authToken && userId) {
        config.headers.set({ 'X-User-Id': userId });
        config.headers.set({ 'X-Auth-Token': authToken });
        config.headers.set({ 'X-Bot-Id': path });
      }

      return config;
    },
    async (error: AxiosError) => Promise.reject(error)
  );
};

export default AxiosInstanceYalo;
