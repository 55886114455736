import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DrawerContext, { TABS } from '../../contexts/drawer/context';
import shadows from '../../theme/shadows';
import LoadingAnimation from '../AnimationLoading';
import { IContextDrawer } from './types';
import SearchDrawer from './SearchDrawer';
import ContactDrawer from './ContactDrawer';

const drawerStyle = {
  backgroundColor: 'common.white',
  height: '100%',
  boxShadow: shadows.shadowBlack4,
};

const ContextDrawer: React.FC<IContextDrawer> = (props) => {
  const { open } = props;
  const theme = useTheme();
  const { selectedTab, loading } = useContext(DrawerContext);

  if (!open) {
    return null;
  }

  if (loading) {
    return (
      <Stack sx={drawerStyle} direction="column" justifyContent="center" alignItems="center">
        <LoadingAnimation color={theme.palette.neutral.var90} />
      </Stack>
    );
  }

  if (selectedTab === TABS.CONTACT_INFO || selectedTab === TABS.CHATS_HISTORY) {
    return (
      <Stack sx={drawerStyle}>
        <ContactDrawer />
      </Stack>
    );
  }

  return (
    <Stack sx={drawerStyle}>
      <SearchDrawer />
    </Stack>
  );
};

export default ContextDrawer;
