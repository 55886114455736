import {
  OutgoingMessageChatMessage,
  OutgoingMessageMessageContext,
  OutgoingMessageOutgoingMessage,
} from '@engyalo/salesdesk-chat-socket-ts-client';

import React, { ComponentType } from 'react';
import ChatMessage from './ChatMessage';
import StatusMessage from './StatusMessage';

export type MessageKind = typeof OutgoingMessageMessageContext.Chat | typeof OutgoingMessageMessageContext.Status;

export type ChatMessageType = OutgoingMessageChatMessage & {
  pending?: boolean;
  error?: boolean;
  delivered?: boolean;
  read?: boolean;
};

export type MessageType = OutgoingMessageOutgoingMessage & {
  kind: MessageKind;
  chat?: ChatMessageType;
};

export const MESSAGE_COMPONENTS: Record<MessageKind, ComponentType<SpecificMessageProps>> = {
  chat: ChatMessage,
  status: StatusMessage,
};

type MessageComponentProps = {
  message: MessageType;
  onClick?: (message: MessageType) => void;
};

export type SpecificMessageProps = Omit<MessageType, 'kind'> & {
  onClick?: () => void;
};

const Message: React.FC<MessageComponentProps> = React.memo((props) => {
  const { onClick, message } = props;
  const { kind, ...rest } = message;

  const MessageComponent = MESSAGE_COMPONENTS[kind];

  const handleClick = () => {
    onClick?.(message);
  };

  return <MessageComponent {...rest} onClick={handleClick} />;
});

export default Message;
