import { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IContactEditInfo } from './types';
import { updateContactInfo } from '../../../services/updateContactInfo';
import DrawerContext from '../../../contexts/drawer/context';
import { createYupSchema } from '../../../helpers/yupSchemaCreator';
import { searchUser } from '../../../services/contactSearch';

const useEdit = ({ token, handleEditing, customFields, handleIsDirtyForm }: any) => {
  const [stateSchema, setStateSchema] = useState(yup.object().shape({}));

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
    getValues,
    clearErrors,
  } = useForm<IContactEditInfo>({
    mode: 'onBlur',
    resolver: yupResolver(stateSchema),
  });

  useEffect(() => {
    if (customFields) {
      const yepSchema = customFields.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setStateSchema(validateSchema);
    }
  }, [customFields]);
  const { loadUserInfo, contactInfo } = useContext(DrawerContext);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorUpdt, setErrorUpdt] = useState(false);
  const [errorDocNumber, setErrorDocNumber] = useState('');

  useEffect(() => {
    if (handleIsDirtyForm) {
      handleIsDirtyForm(isDirty);
    }
  }, [isDirty]);

  const onSubmit: SubmitHandler<IContactEditInfo> = async (form) => {
    setLoading(true);
    try {
      const { name, email } = form;
      if (!name) {
        setError('name', {
          type: 'required',
          message: 'fieldIsRequired',
        });
        return;
      }
      const { _fields } = control;
      if (contactInfo) {
        const previousTags = _fields.tags?._f.value;
        if (!form.tags && previousTags) {
          form.tags = previousTags;
        }
        if (contactInfo._id) {
          const { data } = await updateContactInfo(
            contactInfo._id,
            token,
            name,
            email,
            contactInfo.phone ? contactInfo.phone[0].phoneNumber : '',
            form
          );

          if (data.success) {
            loadUserInfo(contactInfo._id);
            setLoading(false);
            handleEditing(true);
          }
        }
      }
    } catch (error: any) {
      setErrorUpdt(true);
      setLoading(false);
      console.log('error UPDT', error);
    }
  };

  const checkField = async (field: string) => {
    if (field === 'email') {
      const { data: contact } = await searchUser(field, getValues(field));
      if (contact.contact) {
        setError('email', {
          type: 'required',
          message: 'emailAlreadyExists',
        });
      } else {
        clearErrors();
        setValue('email', getValues(field));
      }
    }
  };

  return {
    control,
    handleSubmit,
    translation: t,
    onSubmit,
    errors,
    errorUpdt,
    loading,
    setValue,
    errorDocNumber,
    setErrorDocNumber,
    checkField,
  };
};

export default useEdit;
