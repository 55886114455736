/* eslint-disable no-underscore-dangle */
import React from 'react';
import { t } from 'i18next';
import { Typography, Box, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { ListItemSelect } from 'views/ServiceManagement/VerifiedTemplatesManager/style';
import { Agent } from 'services/types';
import { useFullScreenHandle } from 'react-full-screen';
import { IconButton, Tooltip } from '@engyalo/design-system';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { Container, Header, SelectsDiv, SelectWrapper, CardsDiv, TableHeaderDiv, FullScreenContainer } from './style';
import TimeSelector from './components/TimeSelector';
import GenericAutocomplete from '../../../components/Autocomplete';
import CustomSnackBar from '../../../components/Snackbar';
import CustomTable from '../../../components/Table';
import useServiceDashboard from './useServiceDashboard';
import Card from './components/Card';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import { IConversationStatus } from './getConversationsStats';

const ServiceDashboard = () => {
  const {
    teams,
    agentsTable,
    tableHeader,
    conversationStats,
    selectedTeams,
    setSelectedTeams,
    totalOnline,
    formatedDate,
    formatedHour,
    loadingDataError,
    setLoadingDataError,
    timeUpdateSelected,
    setTimeUpdateSelected,
    shouldIncludePreviousDayData,
    setShouldIncludePreviousDayData,
    loading,
  } = useServiceDashboard();

  const handle = useFullScreenHandle();
  const handleSnackbar = () => {
    setLoadingDataError((old: boolean) => !old);
  };

  const handleToggleFullScreen = handle.active ? handle.exit : handle.enter;

  return (
    <FullScreenContainer handle={handle}>
      <Container data-testid="dashboardAnalyticsContainer">
        <Header>
          <Typography variant="h2">{t('liveData')}</Typography>
          <div className="timeSelector">
            <TimeSelector timeUpdateSelected={timeUpdateSelected} setTimeUpdateSelected={setTimeUpdateSelected} />
            <div className="flex flex-1 items-start h-full ml-2">
              <IconButton data-testid="btnFullScreen" color="primary" onClick={handleToggleFullScreen}>
                {handle.active ? (
                  <ZoomInMapIcon fontSize="large" color="action" />
                ) : (
                  <ZoomOutMapIcon fontSize="large" color="action" />
                )}
              </IconButton>
            </div>
          </div>
        </Header>
        <Typography variant="subtitle1">
          {`${t('dataFromToday')} (${formatedDate} ${t('hourUpUntil')} ${formatedHour})`}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldIncludePreviousDayData}
                onChange={(event) => {
                  setShouldIncludePreviousDayData(event.target.checked);
                }}
              />
            }
            label={
              <>
                {t('previousDayDataCheckbox')}
                <Tooltip title={t('previousDayDataTooltip')} placement="left" />
              </>
            }
          />
        </FormGroup>
        <SelectsDiv>
          <SelectWrapper>
            <GenericAutocomplete
              disableClearable
              hideSearchIcon
              placeholder="Teams"
              renderOption={(props, option, { selected }) => (
                <ListItemSelect
                  {...props}
                  onClick={(e) => {
                    if (selectedTeams.length === 1 && selected) {
                      return;
                    }
                    props.onClick?.(e);
                  }}
                >
                  <Checkbox checked={selected} disabled={selectedTeams.length === 1 && selected} />
                  <Typography variant="caption1">{option.name}</Typography>
                </ListItemSelect>
              )}
              handleChange={(values: Agent[]) => {
                if (values.length) {
                  setSelectedTeams(values.map((value) => value._id ?? ''));
                } else {
                  setSelectedTeams([]);
                }
              }}
              multiple
              options={teams}
              defaultValue={selectedTeams}
            />
          </SelectWrapper>
        </SelectsDiv>
        {loading ? (
          <Box alignSelf="center">
            <Loading />
          </Box>
        ) : (
          <>
            <Typography style={{ marginTop: 32, marginBottom: 8 }} variant="h3">
              {t('conversations')}
            </Typography>
            <CardsDiv>
              {Object.keys(conversationStats).map((key) => {
                const value = conversationStats[key as keyof IConversationStatus];
                if (value) {
                  return <Card key={key} data={value} title={key} />;
                }
                return null;
              })}
            </CardsDiv>
            <TableHeaderDiv>
              <Typography variant="h3" style={{ marginBottom: 8 }}>
                {t('agents')}
              </Typography>
              <Typography style={{ marginLeft: 8 }} variant="subtitle1">
                ({totalOnline} {t('onlineAgents')})
              </Typography>
            </TableHeaderDiv>
            <CustomTable
              order="asc"
              orderBy="status"
              rows={agentsTable}
              headItems={tableHeader}
              page={1}
              handleClickItem={() => {}}
            />
          </>
        )}

        {loadingDataError && (
          <CustomSnackBar
            open={loadingDataError}
            message={t('dashboadError')}
            handleClose={handleSnackbar}
            type="error"
          />
        )}
      </Container>
    </FullScreenContainer>
  );
};
export default ServiceDashboard;
