import React, { useState, useEffect, useContext } from 'react';
import { List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tabs, Tab } from '@engyalo/design-system';
import TabPanel from '../Tabs/TabPanel';
import { Container, OptionItem, CustomPopover } from './style';
import InputSearch from '../InputSearch';
import TransferModal from './TransferModal';
import TransferContext from '../../contexts/transfer/context';
import ModalTransferError from './ModalTransferError';
import { IAgentOfDepartment } from '../../contexts/transfer/provider';

export interface ITransferMenu {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  departmentId: string;
  isMobile: boolean;
}

const MenuTransfer: React.FC<ITransferMenu> = (props) => {
  const theme = useTheme();
  const { open, anchorEl, handleClose, departmentId, isMobile } = props;
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [agentsOfDepartment, setAgentsOfDepartment] = useState<IAgentOfDepartment[]>([]);
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [inputText, setInputText] = useState('');

  const {
    loadAgents,
    loadDepartments,
    AllAgentsOfDepartment,
    allDepartments,
    checkIfHasAgentsAvailable,
    handleSetSelectedDepartment,
    handleSetSelectedAgent,
    handleReturnToQueue,
    loading,
  } = useContext(TransferContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const filterAgents = (search?: string) => {
    const filteredAgents = AllAgentsOfDepartment.filter((item) =>
      item.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || '')
    );

    setAgentsOfDepartment(filteredAgents);
  };

  const filterDepartments = (search?: string) => {
    const filteredDepartments = allDepartments.filter((item) =>
      item.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || '')
    );
    setDepartments(filteredDepartments);
  };

  const handleOpenModalByDepartment = async (departmentID: string) => {
    const hasAgents = await checkIfHasAgentsAvailable(departmentID);
    handleSetSelectedDepartment(departmentID);
    if (hasAgents) {
      setOpenTransferModal(true);
    } else {
      setOpenErrorModal(true);
    }
  };

  const returnToQueueTeamStack = (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        boxShadow: `inset 0px 1px 0px ${theme.palette.neutral.var20}`,
      }}
    >
      <Button
        onClick={() => {
          setOpenTransferModal(true);
          handleReturnToQueue();
        }}
        fullWidth
        startIcon={<i className="fa-regular fa-inbox" />}
      >
        {t('returnToQueueTeam')}
      </Button>
    </div>
  );

  const departmentsList = () => (
    <Container>
      <InputSearch
        testId="inputSearchDepartmentMenuTransfer"
        placeholder="search"
        value={inputText}
        onChange={(value) => {
          filterDepartments(value);
          setInputText(value);
        }}
      />
      <List
        sx={{
          width: '100%',
          minHeight: '20px',
          maxHeight: isMobile ? '100%' : '200px',
          overflowY: 'scroll',
        }}
      >
        {departments.map((item, index) => (
          <ListItem
            data-testid="departmentsMenuTransfer"
            onClick={() => handleOpenModalByDepartment(item._id)}
            sx={{ height: '36px' }}
            key={index}
          >
            <OptionItem>
              <i className="fa-regular fa-inbox" />
              <Typography variant="caption1" color="neutral.var100">
                {item.name}
              </Typography>
            </OptionItem>
          </ListItem>
        ))}
      </List>
      {returnToQueueTeamStack}
    </Container>
  );

  const agentsList = () => (
    <Container>
      <InputSearch
        testId="inputSearchAgentMenuTransfer"
        placeholder="search"
        value={inputText}
        onChange={(value) => {
          filterAgents(value);
          setInputText(value);
        }}
      />
      <List
        sx={{
          width: '100%',
          minHeight: '20px',
          maxHeight: isMobile ? '100%' : '200px',
          overflowY: 'scroll',
        }}
      >
        {agentsOfDepartment.map((agent, index) => (
          <ListItem
            id={`${agent.status + agent._id}`}
            sx={{ height: '36px' }}
            key={index}
            onClick={() => {
              handleSetSelectedAgent(agent._id);
              setOpenTransferModal(true);
            }}
            data-testid="agentItem"
            defaultValue={agent.status}
          >
            <OptionItem>
              {agent.status === 'online' ? (
                <i className="fa-solid fa-circle" style={{ color: theme.palette.success.main }} />
              ) : (
                <i className="fa-regular fa-circle" style={{ color: theme.palette.neutral.var60 }} />
              )}

              <Typography variant="caption1" color="neutral.var100">
                {agent.name}
              </Typography>
            </OptionItem>
          </ListItem>
        ))}
      </List>
      {!allDepartments.length && !loading && returnToQueueTeamStack}
    </Container>
  );

  const closeTransfer = () => {
    handleClose();
  };

  useEffect(() => {
    loadDepartments(departmentId);
  }, []);

  useEffect(() => {
    if (!allDepartments.length) {
      loadAgents(departmentId);
    }
  }, [allDepartments]);

  useEffect(() => {
    setDepartments(allDepartments);
    setAgentsOfDepartment(AllAgentsOfDepartment);
  }, [allDepartments, AllAgentsOfDepartment]);

  return (
    <CustomPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={closeTransfer}
      data-testid="menuTransfer"
    >
      {!allDepartments.length ? agentsList() : null}
      {allDepartments.length ? (
        <Stack>
          {isMobile && (
            <Stack
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={handleClose} variant="text" color="neutral2">
                <i className="fa-regular fa-arrow-left" />
              </IconButton>

              <Typography variant="subtitle1" color="neutral.var90">
                {t('transferChatModalMobile')}
              </Typography>
            </Stack>
          )}
          <Tabs value={tabValue} onChange={handleChange} aria-label="tags tabs" sx={{ width: '100%' }}>
            <Tab
              data-testid="tabTransferToTeam"
              onClick={() => loadDepartments(departmentId)}
              label={t('transferToTeam')}
              id="tags-tab-0"
              aria-controls="tags-tabpanel-0"
              key={0}
            />
            <Tab
              data-testid="tabAgentsMenuTransfer"
              onClick={() => loadAgents(departmentId)}
              label={t('transferToAgent')}
              id="tags-tab-1"
              aria-controls="tags-tabpanel-1"
              key={1}
            />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {departmentsList()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {agentsList()}
          </TabPanel>
        </Stack>
      ) : null}

      {openTransferModal && (
        <TransferModal openTransferModal={openTransferModal} handleClose={() => setOpenTransferModal(false)} />
      )}

      {openErrorModal && (
        <ModalTransferError
          open={openErrorModal}
          title={t('transferWithoutAgentsAvailable')}
          message={t('transferWithoutAgentsAvailableMessage')}
          confirmButtonText={t('transfer')}
          cancelButton
          handleClose={(confirm) => {
            const value = !!confirm;
            setOpenTransferModal(value);
            setOpenErrorModal(false);
            if (!value) {
              handleSetSelectedDepartment('');
            }
          }}
        />
      )}
    </CustomPopover>
  );
};

export default MenuTransfer;
