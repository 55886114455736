import { useState, useEffect, useContext } from 'react';
import { HIDDEN_TAGS } from 'constants/defaultValues';
import { logError } from 'services/Logger';
import ChatsContext from '../../../contexts/chats/context';
import DepartmentContext from '../../../contexts/departments/context';
import { tagsColor } from '../../../helpers/tagsColors';
import { getContactInfo } from '../../../services/contactInfo';
import { getRoomInfo } from '../../../services/getRoomInfo';
import { getListTags } from '../../../services/getTags';
import { saveRoomInfo } from '../../../services/saveRoomInfo';
import { sendCustomField } from '../../../services/sendCustomField';
import { ITagsList } from '.';
import { Tags } from '../../../services/types';

interface IUseMenuTags {
  anchorEl?: HTMLDivElement | HTMLButtonElement;
  clientToken: string;
  chatId: string;
  contactId: string;
}

type TypeDescription = 'room' | 'visitor';
const useMenuTags = ({ anchorEl, clientToken, chatId, contactId }: IUseMenuTags) => {
  const open = Boolean(anchorEl);
  const [tabValue, setTabValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [typeDescription, setTypeDescription] = useState<TypeDescription>('room');
  const [currentRoomTags, setCurrentRoomTags] = useState<string[] | null>(null);
  const [currentVisitorTags, setCurrentVisitorTags] = useState('');
  const [currentTag, setCurrentTag] = useState<Tags>();
  const [allPageTags, setAllPageTags] = useState<Tags[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITagsList[]>([]);
  const [isHandleChecked, setIsHandleChecked] = useState(false);
  const [pagination, setPagination] = useState({ count: 100, offset: 0 });
  const [idRequest, setIdRequest] = useState(0);
  const { loadRoomInfo } = useContext(ChatsContext);
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const { departments } = useContext(DepartmentContext);
  const departmentsIds = departments.map((item) => item._id);

  const removeTagsClientSide = (tags: Tags[] = [], total: number = 0) => {
    const filteredTags = tags.filter((tag) => !HIDDEN_TAGS.includes(tag.name));
    const numberOfFilteredTags = tags.length - filteredTags.length;
    const totalAfterFiltering = total - numberOfFilteredTags;
    return { filteredTags, totalAfterFiltering };
  };

  const loadTags = async () => {
    const { data } = await getListTags({ count: pagination.count, offset: pagination.offset, text: typeDescription });
    const { tags, total } = data;

    const { filteredTags, totalAfterFiltering } = removeTagsClientSide(tags, total);

    const mappedTags = filteredTags
      .filter((item) => {
        const existTaginCurrentList = allPageTags.find((tag) => tag._id === item._id);
        if (!existTaginCurrentList && departmentsIds.some((dep) => item.departments.includes(dep))) {
          return item;
        }
        if (!existTaginCurrentList && !item.departments.length) {
          return item;
        }
        return null;
      })
      .map((tag) => {
        const tagWithCustomColor = { ...tag };
        tagWithCustomColor.customColor = tagsColor(tag.name);
        return tagWithCustomColor;
      });
    const newArray = [...allPageTags, ...mappedTags];

    if (pagination.count < totalAfterFiltering) {
      setPagination((old) => ({
        ...old,
        offset: newArray.length,
        count: pagination.count + filteredTags.length,
      }));

      setAllPageTags(newArray);
    } else {
      setAllPageTags(newArray);
    }
  };

  const handleChecked = async (checked: boolean, tagValue: string, typeDescriptionParam: string) => {
    const selectedTagsCopy = [...selectedTags];

    const tag = selectedTagsCopy.find((t) => t.tag.name === tagValue);
    if (tag) {
      tag.selected = !tag.selected;
      setSelectedTags(selectedTagsCopy);
    }

    if (typeDescriptionParam === 'room') {
      if (checked) {
        setCurrentRoomTags((old) => {
          if (!old) {
            return [tagValue];
          }
          return [...old, tagValue];
        });
      } else {
        setCurrentRoomTags((old) => old?.filter((item) => item !== tagValue) ?? []);
      }
    }
    setIsHandleChecked((oldState) => !oldState);
  };

  const callSaveTags = async () => {
    const paramsSaveRoomInfo = {
      chatId,
      contactId,
      tags: currentRoomTags ?? [],
      uuid: `${idRequest}`,
    };
    setIdRequest(idRequest + 1);
    await saveRoomInfo(paramsSaveRoomInfo);
  };

  const filterTags = (type: 'visitor' | 'room', search?: string) => {
    const filteredTags = allPageTags.filter(
      (item) =>
        item.description &&
        item.description.includes(type) &&
        item.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || '')
    );

    setTypeDescription(type);
    const currentVisitorTagsSplitted = currentVisitorTags.split(';');
    const tagsSelected = type === 'room' ? currentRoomTags ?? [] : currentVisitorTagsSplitted;

    setSelectedTags(
      filteredTags
        .map((item) => ({
          tag: item,
          selected: tagsSelected.includes(item.name),
        }))
        .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1))
    );
  };

  const loadChatInfo = async () => {
    try {
      const {
        data: { room = {} },
      } = await getRoomInfo(chatId);
      const {
        data: { contact },
      } = await getContactInfo(contactId);
      setCurrentRoomTags(room.tags || []);
      setCurrentVisitorTags(contact.livechatData?.tags || '');
    } catch (error) {
      logError('Error while loading chat info', error);
    }
  };

  const handleSaveVisitorTags = async (selectedTagsParam: string) => {
    try {
      await sendCustomField(clientToken, 'tags', selectedTagsParam);
      if (!selectedTagsParam.length) {
        if (typeDescription === 'room') {
          setCurrentRoomTags([]);
        } else {
          setCurrentVisitorTags('');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal((oldState) => !oldState);
    loadTags();
    setCurrentTag(undefined);
  };

  useEffect(() => {
    loadTags();
  }, [pagination]);

  useEffect(() => {
    loadChatInfo();
  }, [chatId]);

  useEffect(() => {
    filterTags(typeDescription);
  }, [allPageTags, currentRoomTags]);

  useEffect(() => {
    if (currentRoomTags !== null) {
      callSaveTags();
    }
  }, [currentRoomTags]);

  useEffect(() => {
    if (currentRoomTags && selectedTags.length && isHandleChecked) {
      const currentSelectedTags = selectedTags.filter((item) => item.selected).map((item) => item.tag.name);

      setIsHandleChecked((oldState) => !oldState);
      if (typeDescription === 'room') {
        setTimeout(() => {
          loadRoomInfo();
        }, 500);
      } else {
        const tagsString = currentSelectedTags.join(';');
        handleSaveVisitorTags(tagsString);
      }
    }
  }, [selectedTags]);

  return {
    tabValue,
    selectedTags,
    openModal,
    handleChecked,
    setOpenModal,
    setCurrentTag,
    currentTag,
    open,
    handleChange,
    filterTags,
    typeDescription,
    handleCloseModal,
    loadTags,
  };
};

export default useMenuTags;
