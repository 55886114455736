import React from 'react';
import { ListItem, useMediaQuery, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { managementRoles } from 'constants/roles';
import GenericAutocomplete from '../../Autocomplete';
import GenericModal from '../../Modal';
import EditTags from '../../Tags/EditTags';
import { OptionItem } from '../style';
import { Buttons, Container, ItemStack, ItemOpcionalStack } from './style';
import useTransferModal from './useTransferModal';
import ModalTransferError from '../ModalTransferError';
import usePermissions from '../../../hooks/usePermissions';

export interface ITransferModal {
  handleClose: () => void;
  openTransferModal: boolean;
}

const TransferModal: React.FC<ITransferModal> = ({ handleClose, openTransferModal }: ITransferModal) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { checkPermission } = usePermissions();
  const {
    AllAgentsOfDepartment,
    allDepartments,
    setAnotation,
    setAgentId,
    setSelectedDepartmentId,
    selectedDepartment,
    handleTransferChat,
    handleSelectTags,
    openErrorModal,
    handleCloseErrorModal,
    selectedAgent,
    closeModal,
    isReturnToQueue,
    returnToQueueOptions,
    messageError,
    handleDisableConfirmButton,
    currentRoomTags,
  } = useTransferModal({ handleClose });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isManagerOrSupervisor = checkPermission(managementRoles);
  return (
    <GenericModal
      hasCloseIcon
      handleModal={closeModal}
      open={openTransferModal}
      title={t('transferChat')}
      isFullScreen={isMobile}
    >
      <Container isMobile={isMobile}>
        {selectedDepartment && (
          <ItemStack>
            <Typography variant="caption1" color="neutral.var100">
              {t('team')}
            </Typography>

            <GenericAutocomplete
              disableCloseOnSelect
              placeholder={t('selectTeam')}
              renderOption={(props, option) => (
                <ListItem data-testid="teamListItem" sx={{ height: '36px' }} {...props}>
                  <OptionItem>
                    <Typography variant="caption1" color="neutral.var100">
                      {option.name}
                    </Typography>
                  </OptionItem>
                </ListItem>
              )}
              defaultValue={selectedDepartment}
              handleChange={(value) => {
                setSelectedDepartmentId(value?._id || '');
              }}
              limitTagsNumber={1}
              multiple={false}
              options={allDepartments}
            />
          </ItemStack>
        )}
        {isManagerOrSupervisor && (
          <ItemStack>
            <ItemOpcionalStack>
              {selectedDepartment ? (
                <>
                  <Typography variant="caption1" color="neutral.var100">
                    {t('agent')}
                  </Typography>
                  <Typography variant="caption1" color="neutral.var50">
                    ({t('optional')})
                  </Typography>
                </>
              ) : (
                <Typography variant="caption1" color="neutral.var100">
                  {t('transferChatTo')}
                </Typography>
              )}
            </ItemOpcionalStack>
            <GenericAutocomplete
              disableCloseOnSelect
              placeholder={t('selectAgent')}
              renderOption={(props, option) => (
                <ListItem data-testid="agentListItem" sx={{ height: '36px' }} {...props}>
                  <OptionItem>
                    {!isReturnToQueue &&
                      (option.status === 'online' ? (
                        <i className="fa-solid fa-circle" style={{ color: theme.palette.success.main }} />
                      ) : (
                        <i className="fa-regular fa-circle" style={{ color: theme.palette.neutral.var60 }} />
                      ))}

                    <Typography variant="caption1" color="neutral.var100">
                      {option.name}
                    </Typography>
                  </OptionItem>
                </ListItem>
              )}
              handleChange={(value) => {
                setAgentId(value?._id || '');
              }}
              defaultValue={isReturnToQueue ? returnToQueueOptions[0]._id : selectedAgent}
              limitTagsNumber={1}
              multiple={false}
              options={isReturnToQueue ? returnToQueueOptions : AllAgentsOfDepartment}
            />
          </ItemStack>
        )}

        {!isReturnToQueue && (
          <>
            <ItemStack>
              <Typography variant="caption1" color="neutral.var100">
                {t('conversationTags')}
              </Typography>

              <EditTags
                type="room"
                placeholder={t('selectTags')}
                editTag={isManagerOrSupervisor}
                createTag={isManagerOrSupervisor}
                handleChange={(value: string) => handleSelectTags(value)}
                currentTags={currentRoomTags}
                limitTagsNumber={1}
              />
            </ItemStack>
            <ItemStack>
              <ItemOpcionalStack>
                <Typography variant="caption1" color="neutral.var100">
                  {t('annotation')}
                </Typography>
                <Typography variant="caption1" color="neutral.var50">
                  ({t('optionals')})
                </Typography>
              </ItemOpcionalStack>
              <TextField
                multiline
                sx={{ width: '100%' }}
                maxRows={3}
                placeholder={t('anotationForChat')}
                onChange={(event) => {
                  setAnotation(event.target.value);
                }}
                inputProps={{ 'data-testid': 'inputAnotationTransferModal' }}
              />
            </ItemStack>
          </>
        )}

        <Buttons isMobile={isMobile}>
          {!isMobile && (
            <Button onClick={() => closeModal()} disableElevation variant="outlined" color="neutral2">
              {t('cancel')}
            </Button>
          )}
          <Button
            data-testid="transferButton"
            onClick={() => handleTransferChat()}
            disableElevation
            disabled={handleDisableConfirmButton()}
            sx={{ width: isMobile ? '90%' : 'auto' }}
          >
            {t('transfer')}
          </Button>
        </Buttons>
      </Container>

      {openErrorModal && (
        <ModalTransferError
          open={openErrorModal}
          title={t(messageError.title)}
          message={t(messageError.message)}
          confirmButtonText={t(messageError.confirmText)}
          cancelButton={messageError.cancelButton}
          handleClose={(confirm) => {
            const value = !!confirm;
            handleCloseErrorModal(value);
          }}
        />
      )}
    </GenericModal>
  );
};

export default TransferModal;
