import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { shouldForwardPropHelper } from '@engyalo/design-system';

interface IHeader {
  isEditing?: boolean;
}

export const Header = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IHeader>`
  display: flex;
  height: 4.8rem;
  align-items: center;
  justify-content: ${({ isEditing }) => (isEditing ? 'start' : 'space-between')};
  box-shadow: inset 0px -1px 0px #e7ebec;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 99;
  padding: 8px;
`;

export const HeaderTitle = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<IHeader>`
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'none')};
  height: 4.8rem;
  align-items: center;
`;

export const TabPanel = styled('div')<{ display: boolean }>`
  height: calc(100% - 4.8rem);
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
`;
