import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatModalAction } from 'contexts/chats/types.d';
import ChatsContext from '../../contexts/chats/context';
import WebsocketContext from '../../contexts/websocket/context';
import useSegment from '../../services/Analytics';
import { closeChatInstance } from '../../contexts/websocket/provider';
import { logError } from '../../services/Logger';
import useEnvironment from '../../hooks/useEnvironments';

export const ERROR_CLOSE_CHAT_API = 'Error while closing chat through API';
export const ERROR_HANDLE_CLOSE_CONVERSATION = 'Error in handleCloseConversation';

export const generateComment = (ticketNumber: string, ticketMessage: string, annotation: string, comment: string) => {
  const ticketInfo = `${ticketMessage.toLocaleUpperCase()}: ${ticketNumber}`;
  const closeChatComment = annotation || comment;

  return `${closeChatComment} ${ticketInfo}`;
};

const useCloseConversation = () => {
  const { getInstance } = useEnvironment();
  const { basicContactInfo, toggleCloseChatModal, chatId, serviceSelected, handleServiceSelected, setErrorCloseChat } =
    useContext(ChatsContext);
  const { unsubscribeRoomMessages, comment, setComment, setSnackBarMessage } = useContext(WebsocketContext);
  const { t } = useTranslation();
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [anotation, setAnotation] = useState('');
  const [chatTags, setChatTags] = useState('');
  const { sendEvent, EVENT_NAMES } = useSegment();
  const [botId, setBotId] = useState('');

  useEffect(() => {
    if (serviceSelected.tags) {
      setChatTags(serviceSelected.tags.map((el: string) => el).join(';'));
    }
  }, [serviceSelected.tags]);

  useEffect(() => {
    const clientBotId = getInstance();
    setBotId(clientBotId);
  }, [getInstance]);

  const handleEventsOnSucess = () => {
    sendEvent(EVENT_NAMES.USER_CLICKED_ON_CLOSE_CONVERSATION);
    setSnackBarMessage('ChatFinishSuccess');
    toggleCloseChatModal(ChatModalAction.Close);
    unsubscribeRoomMessages('chat');
    setComment('');
    handleServiceSelected({});
  };

  const handleEventsOnError = () => {
    setErrorCloseChat(true);
  };

  const closeChat = async ({
    id,
    botId: botIdLocal,
    tags,
    phone,
    chatComment,
  }: {
    id: string;
    botId: string;
    tags: string[];
    phone: string;
    chatComment: string;
  }) => {
    try {
      await closeChatInstance(id, botIdLocal, tags, phone, chatComment);
      handleEventsOnSucess();
    } catch (error) {
      handleEventsOnError();
      logError(ERROR_CLOSE_CHAT_API, error);

      throw error;
    }
  };

  const handleCloseConversation = async () => {
    const ticketMessage = t('ticket');
    const ticketNumber = serviceSelected?.livechatData?.ticket;

    const chatComment = ticketNumber ? generateComment(ticketNumber, ticketMessage, anotation, comment) : '';

    try {
      await closeChat({
        id: chatId,
        botId,
        tags: tagsSelected,
        phone: basicContactInfo.phone,
        chatComment,
      });
    } catch (error) {
      logError(ERROR_HANDLE_CLOSE_CONVERSATION, error);
    }
  };

  return {
    anotation,
    basicContactInfo,
    chatTags,
    tagsSelected,
    handleCloseConversation,
    toggleCloseChatModal,
    setAnotation,
    setTagsSelected,
    translation: t,
  };
};

export default useCloseConversation;
