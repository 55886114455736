import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  // Workaround: Applies a z-index to the .inputSearch class to prevent overlap issues, ensuring the input remains clickable
  .inputSearch {
    z-index: 1;
  }
`;

export const Header = styled('div')`
  height: 4.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 16px;
  justify-content: space-between;

  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ResultMessages = styled(Typography)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral.var10};
`;

export const NotFoundChats = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 32px 36px;
`;
