import colors from '../theme/colors';

export const tagsColor = (name?: string) => {
  const arrayColors = [
    { color: colors.colorNeutral20, text: colors.colorNeutral90 },
    { color: colors.colorNeutral30, text: colors.colorNeutral90 },
    { color: colors.colorInformational30, text: colors.colorInformational70 },
    { color: colors.colorSuccess10, text: colors.colorSuccess60 },
    { color: colors.colorWarning10, text: colors.colorWarning70 },
    { color: colors.colorSecondary10, text: colors.colorWarning70 },
    { color: colors.colorTertiary10, text: colors.colorTertiary70 },
  ];

  if (name) {
    const sum = Array.from(name).reduce((accum, char) => accum + char.charCodeAt(0), 0);
    return arrayColors[sum % arrayColors.length];
  }

  return arrayColors[0];
};
