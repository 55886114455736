import { AxiosResponse } from 'axios';
import { filterBotDepartment } from 'utils/filterBotDepartment';
import AxiosInstance from './AxiosInstance';
import { IDepartmentsListUnit } from './types';

type GetDepartmentsListUnit = {
  departments: IDepartmentsListUnit[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
};
export const getDepartmentsListUnit = async (unitId: string): Promise<AxiosResponse<GetDepartmentsListUnit>> =>
  AxiosInstance.get(`livechat/units/${unitId}/departments`).then((response) => ({
    ...response,
    departments: filterBotDepartment(response.data.departments),
  }));
