import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DataGrid } from '@engyalo/design-system';
import { Typography, Icon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FilterButton } from 'components/FilterButton';
import { UserStatusFilterState } from 'services/types';
import { ButtonsStack } from 'components/ButtonsStack/style';
import DialogConfirmation from 'components/DialogConfirmation';
import { ContainerTableAndFooter, FiltersContainer, ItemManagement, SearchContainer } from '../style';
import {
  Container,
  ContainerLicenses,
  ContainerTitleAndLicenses,
  ContainerTitleLicenses,
  CustomLinearProgress,
  FiltersGroup,
  FiltersRow,
} from './style';
import useUsersManager from './useUsersManager';
import InputSearch from '../../../components/InputSearch';
import ModalError from '../../../components/ErrorModal';
import HeaderUserManager from './Header';
import Drawer from '../../../components/Drawer';
import ViewUserInfo from './ViewUserInfo';
import FormUserInfo from './FormUserInfo';
import FormTeams from './FormUserInfo/FormTeams';
import { ModalEdit } from '../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import { ModalPassword } from '../../../components/ModalPassword';

const refreshIcon = <Icon className="fa-solid fa-arrows-rotate" />;

const UsersManager: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    columns,
    userList,
    loading,
    licenses,
    showModalSeatsAvailableUsers,
    showDrawer,
    drawerType,
    userSelectedId,
    currentTabValue,
    unsavedChanges,
    handleModalSeatsAvailable,
    newUser,
    handleClickItem,
    handleShowDrawer,
    onHandleForm,
    changeForm,
    setUnsavedChanges,
    handleShowEditingDrawer,
    setCurrentTabValue,
    isManager,
    showModalEditPassword,
    handleShowModalEditPassword,
    showErrorModal,
    handleShowModalError,
    showErrorConnectionModal,
    setShowErrorConnectionModal,
    currentUserSelected,
    userSearchFilter,
    userActiveFilter,
    lastUpdate,
    searchUser,
    isRequestingActivationToggle,
    handleConfirmActivationToggle,
    handleCancelActivationToggle,
    isLoadingActivationToggle,
  } = useUsersManager();

  const checkMaxActiveUsers = useCallback(
    () => !!licenses.activeUsers && licenses.maxActiveUsers - licenses.activeUsers <= 0,
    [licenses]
  );

  const maxLicensesWithoutSystem = licenses.activeUsers === 0 ? 0 : licenses.activeUsers - 1;
  const maxActiveUsersWithoutSystem = licenses.maxActiveUsers === 0 ? 0 : licenses.maxActiveUsers - 1;

  return (
    <>
      <ItemManagement>
        <ContainerTableAndFooter>
          <Container>
            <ContainerTitleAndLicenses>
              <Typography variant="h2" color="common.black">
                {t('users')}
              </Typography>
              <ContainerLicenses>
                <ContainerTitleLicenses>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    {checkMaxActiveUsers() && (
                      <i
                        className="fa-solid fa-triangle-exclamation"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: theme.palette.warning.main,
                        }}
                      />
                    )}
                    <Typography variant="body1" color="common.black">
                      {`${maxActiveUsersWithoutSystem - maxLicensesWithoutSystem} ${t('numberUsersAvailable')}`}
                    </Typography>
                  </div>

                  <Typography
                    variant="overline"
                    color="neutral.var80"
                    display="flex"
                    alignItems="center"
                  >{`${maxLicensesWithoutSystem}/${maxActiveUsersWithoutSystem}`}</Typography>
                </ContainerTitleLicenses>

                <CustomLinearProgress
                  variant="determinate"
                  value={(maxLicensesWithoutSystem / maxActiveUsersWithoutSystem) * 100}
                />
              </ContainerLicenses>
            </ContainerTitleAndLicenses>

            <FiltersContainer>
              <SearchContainer>
                <InputSearch
                  padding="8px 8px 8px 0px"
                  placeholder={t('search')}
                  value={userSearchFilter}
                  onChange={(value) => searchUser({ search: value, active: UserStatusFilterState.All })}
                />
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => newUser()}
                  sx={{
                    minInlineSize: 'fit-content',
                  }}
                >
                  {t('newUser')}
                </Button>
              </SearchContainer>
              <FiltersRow>
                <ButtonsStack justifyContent="flex-start" gap="10px" marginTop="0px">
                  <FilterButton
                    data-testid="button_filter_all_users"
                    onClick={() => searchUser({ active: UserStatusFilterState.All })}
                    selected={userActiveFilter === UserStatusFilterState.All}
                  >
                    {t('all')}
                  </FilterButton>
                  <FilterButton
                    data-testid="button_filter_active_users"
                    onClick={() => searchUser({ active: UserStatusFilterState.Active })}
                    selected={userActiveFilter === UserStatusFilterState.Active}
                  >
                    {t('userActive')}
                  </FilterButton>
                  <FilterButton
                    data-testid="button_filter_inactive_users"
                    onClick={() => searchUser({ active: UserStatusFilterState.Inactive })}
                    selected={userActiveFilter === UserStatusFilterState.Inactive}
                  >
                    {t('userInactive')}
                  </FilterButton>
                </ButtonsStack>
                <FiltersGroup>
                  <Typography variant="caption1" color="neutral.var60" align="right" noWrap>
                    <b>{t('updatedAt')}</b> <br />
                    {lastUpdate}
                  </Typography>
                  <Button color="neutral" size="small" endIcon={refreshIcon} onClick={() => searchUser()}>
                    {t('update')}
                  </Button>
                </FiltersGroup>
              </FiltersRow>
            </FiltersContainer>
            <DataGrid
              columns={columns}
              rows={userList}
              loading={loading}
              getRowId={({ _id: rowId }) => rowId}
              height="85%"
              onRowClick={({ row }) => handleClickItem(row)}
              showActionsOnHover
              initialState={{
                sorting: { sortModel: [{ field: 'active', sort: 'desc' }] },
              }}
              localeText={{
                footerTotalRows: t('totalUsers'),
              }}
            />
          </Container>
        </ContainerTableAndFooter>
      </ItemManagement>
      {showModalSeatsAvailableUsers && (
        <ModalError
          handleClose={() => handleModalSeatsAvailable()}
          message={t('NoMoreSeatsAvailableMessages')}
          title={t('NoMoreSeatsAvailableTitle')}
          open={showModalSeatsAvailableUsers}
        />
      )}

      {showDrawer && (
        <Drawer open={showDrawer}>
          <HeaderUserManager
            currentTabValue={currentTabValue}
            onCloseModal={() => handleShowDrawer()}
            type={drawerType}
            setCurrentTabValue={setCurrentTabValue}
            isManager={isManager}
          />
          {drawerType === 'view' && (
            <ViewUserInfo id={userSelectedId} handleShowEditingDrawer={handleShowEditingDrawer} />
          )}
          {['edit', 'new'].includes(drawerType) && currentTabValue === 0 && (
            <FormUserInfo
              isChangeForm={changeForm}
              onCloseForm={onHandleForm}
              id={userSelectedId}
              handleShowModalEditPassword={handleShowModalEditPassword}
              setShowErrorConnectionModal={setShowErrorConnectionModal}
            />
          )}
          {['edit', 'new'].includes(drawerType) && currentTabValue === 1 && (
            <FormTeams
              isChangeForm={changeForm}
              onCloseForm={onHandleForm}
              userId={userSelectedId}
              setShowErrorConnectionModal={setShowErrorConnectionModal}
            />
          )}
        </Drawer>
      )}

      {unsavedChanges && (
        <ModalEdit
          open={unsavedChanges}
          errorUpdt={false}
          handleEditing={(confirm) => {
            if (confirm) {
              handleShowDrawer(confirm);
            }
            setUnsavedChanges(false);
          }}
          handleModal={() => setUnsavedChanges(false)}
        />
      )}

      {showModalEditPassword && (
        <ModalPassword
          handleShowModalEditPassword={handleShowModalEditPassword}
          open={showModalEditPassword}
          userSelected={currentUserSelected}
          onCloseForm={onHandleForm}
          handleShowModalError={handleShowModalError}
        />
      )}

      {showErrorModal && (
        <ModalError
          handleClose={handleShowModalError}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showErrorModal}
        />
      )}

      {isRequestingActivationToggle && (
        <DialogConfirmation
          open={isRequestingActivationToggle}
          title={currentUserSelected.active ? t('disableUser') : t('enableUser')}
          description={currentUserSelected.active ? t('disableUserMessage') : t('enableUserMessage')}
          onConfirm={handleConfirmActivationToggle}
          onCancel={handleCancelActivationToggle}
          disabled={isLoadingActivationToggle}
        />
      )}

      {showErrorConnectionModal && (
        <ModalError
          handleClose={() => setShowErrorConnectionModal(!showErrorConnectionModal)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showErrorConnectionModal}
        />
      )}
    </>
  );
};

export default UsersManager;
