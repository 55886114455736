class AudioEncoder {
  worker: Worker = {} as Worker;

  scriptNode: any;

  constructor(source: any, { bufferLen = 4096, numChannels = 1, bitRate = 32 } = {}) {
    const workerPath = '/workers/mp3-encoder/index.js';

    this.worker = new Worker(workerPath);

    this.worker.postMessage({
      command: 'init',
      config: {
        sampleRate: source.context.sampleRate,
        numChannels,
        bitRate,
      },
    });

    this.scriptNode = (source.context.createScriptProcessor || source.context.createJavaScriptNode).call(
      source.context,
      bufferLen,
      numChannels,
      numChannels
    );
    this.scriptNode.onaudioprocess = this.handleAudioProcess;

    source.connect(this.scriptNode);
    this.scriptNode.connect(source.context.destination);
  }

  close() {
    this.worker.postMessage({ command: 'finish' });
  }

  handleAudioProcess = (event: any) => {
    for (let channel = 0; channel < event.inputBuffer.numberOfChannels; channel++) {
      const buffer = event.inputBuffer.getChannelData(channel);
      this.worker.postMessage({
        command: 'encode',
        buffer,
      });
    }
  };
}

export { AudioEncoder };
