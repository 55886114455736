import React, { useState } from 'react';
import { Divider, Stack, Typography, Icon, Autocomplete } from '@mui/material';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { ArrowIcon } from 'views/ServiceManagement/BusinessHours/TimezoneSelector';
import { IDepartment } from 'types/department';
import { capitalizeFirstLetter } from 'helpers/capitalizeFirstLetter';
import LoadingAnimation from '../../../components/AnimationLoading';
import { PreviewContainer } from './PreviewContainer';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import { AutoCompleteInputStyle, AutoCompleteStyle } from './style';

export const PreviewMessage = ({
  checkTitleAndSubtitle,
  handleSubmit,
  onSubmit,
  control,
  departments,
  templateSelected,
  replaceVariable,
  isDirty,
  loadingRequest,
  currentUserInfo,
  previewText,
  t,
  isMobile,
}: any) => {
  const theme = useTheme();
  const [autoCompletevalue, setAutoCompletevalue] = useState<IDepartment | null>(null);

  return (
    <Stack direction="row" justifyContent="space-between" width="100%" height="95%">
      {templateSelected.ID ? (
        <Stack
          sx={{
            width: isMobile ? '100%' : '61.5%',
            borderRight: !isMobile ? '1px solid neutral.var20' : '',
            padding: isMobile ? '' : '40px 48px 48px',
          }}
          gap="10px"
        >
          {!isMobile && checkTitleAndSubtitle(true)}
          <form onSubmit={handleSubmit(onSubmit)} data-testid="formSendNotification">
            <Stack
              paddingTop="32px"
              sx={{
                overflowY: 'scroll',
                minHeight: isMobile ? '50vh' : '7vh',
                maxHeight: isMobile ? '50vh' : '70vh',
                paddingX: isMobile ? '16px' : '',
              }}
              gap={1}
            >
              <Typography variant="caption1" color="common.black">
                {t('teamResponsibleForReplies')}
                <Tooltip title={`${t('teamResponsibleMessage')}`} placement="top">
                  <Icon
                    className="fa-solid fa-circle-question"
                    sx={{
                      color: 'neutral.var40',
                      marginLeft: '5px',
                      cursor: 'pointer',
                      fontSize: '1.1rem',
                    }}
                  />
                </Tooltip>
              </Typography>
              <Controller
                name="team"
                control={control}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <Autocomplete
                      data-testid="customAutocompleteTestId"
                      popupIcon={<ArrowIcon />}
                      options={departments}
                      multiple={false}
                      value={autoCompletevalue}
                      getOptionLabel={(option: IDepartment) => option.name}
                      sx={AutoCompleteStyle}
                      renderInput={(params) => (
                        <TextField {...params} placeholder={t('selectOneOption')} sx={AutoCompleteInputStyle} />
                      )}
                      onChange={(_event, newValue) => {
                        setAutoCompletevalue(newValue);
                        onChange(newValue);
                      }}
                    />
                  );
                }}
              />
              {templateSelected.Messages[0].Parameters?.length && (
                <Typography variant="h3">{t('parameters')}</Typography>
              )}
              <Stack gap={1} display="flex" direction="column">
                {templateSelected.Messages[0].Parameters?.map((parameter: string) => (
                  <Controller
                    name={parameter}
                    control={control}
                    key={parameter}
                    render={({ field }) => {
                      const { name, value, onChange } = field;
                      const parameterLabel = capitalizeFirstLetter(parameter);
                      return (
                        <TextField
                          {...field}
                          name={name}
                          value={value}
                          label={parameterLabel}
                          onChange={(event) => {
                            replaceVariable(event.target.name, event.target.value);
                            onChange(event.target.value);
                          }}
                          inputProps={{
                            'data-testid': 'inputParameters',
                          }}
                        />
                      );
                    }}
                  />
                ))}
              </Stack>
            </Stack>

            {isMobile && (
              <Stack paddingTop="10px">
                <Divider orientation="horizontal" color={theme.palette.primary.main} />
                <PreviewContainer
                  currentUserInfo={currentUserInfo}
                  t={t}
                  previewText={previewText}
                  templateSelected={templateSelected}
                  isMobile={isMobile}
                />
              </Stack>
            )}

            <Button
              disabled={!isDirty || loadingRequest}
              variant="contained"
              type="submit"
              sx={{
                marginTop: '32px',
                width: isMobile ? '-webkit-fill-available' : 'fit-content',
                marginX: isMobile ? '16px' : '',
              }}
              startIcon={loadingRequest ? null : <i className="fa-regular fa-comment-plus fa-sm" />}
            >
              {loadingRequest ? <LoadingAnimation color={theme.palette.neutral.var90} /> : <>{t('sendMessage')}</>}
            </Button>
          </form>
        </Stack>
      ) : (
        <Loading />
      )}

      {!isMobile && (
        <PreviewContainer
          currentUserInfo={currentUserInfo}
          t={t}
          previewText={previewText}
          templateSelected={templateSelected}
          isMobile={isMobile}
        />
      )}
    </Stack>
  );
};
