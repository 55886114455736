import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import LoadingAnimation from '../AnimationLoading';
import GenericModal from '../Modal';
import { BodyText, FooterButtons, ContainerModal } from './style';
import { IConfirmDelete } from './types';

const ConfirmDelete: React.FC<IConfirmDelete> = (props: IConfirmDelete) => {
  const { handleConfirm, open, title, message, loading } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <GenericModal open={open} title={t(title)}>
      <ContainerModal data-testid="deleteTagModal">
        <BodyText variant="body1" color="neutral.var100">
          {t(message)}
        </BodyText>
        <FooterButtons>
          <Button
            data-testid="confirmButton"
            onClick={() => handleConfirm(true)}
            variant="outlined"
            color="error"
            sx={{
              marginLeft: '20px',
            }}
            size="small"
            disableElevation
          >
            {loading ? <LoadingAnimation color={theme.palette.error.main} /> : t('exclude')}
          </Button>

          <Button
            data-testid="cancelButton"
            onClick={() => {
              handleConfirm(false);
            }}
            disableElevation
            sx={{
              textTransform: 'none',
            }}
            size="small"
            color="neutral2"
            variant="outlined"
          >
            {t('cancel')}
          </Button>
        </FooterButtons>
      </ContainerModal>
    </GenericModal>
  );
};

export default ConfirmDelete;
