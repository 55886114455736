import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { Box, Typography } from '@mui/material';
import GenericModal from 'components/Modal';
import AuthContext from 'contexts/auth/context';
import logo from 'assets/icons/logo.svg';

const NewVersionModal = () => {
  const { t } = useTranslation();
  const { latestVersion, showReloadModal, setShowReloadModal } = useContext(AuthContext);

  const handleOnClick = () => {
    setShowReloadModal(false);
    window.location.reload();
  };

  const messageText = t('newVersion.message').replace('{version}', latestVersion);

  return (
    <GenericModal open={showReloadModal} title={t('newVersion.title')} imageAlt={t('yaloLogo')} image={logo}>
      <Box gap={2} alignContent="center" display="flex" flexDirection="column">
        <Typography>{messageText}</Typography>
        <Button onClick={handleOnClick}>{t('newVersion.confirmationButton')}</Button>
      </Box>
    </GenericModal>
  );
};

export default NewVersionModal;
