import React, { useContext, useEffect, useState } from 'react';

import { IDiscussionNotificationContent } from 'contexts/websocket/types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Alert } from '@engyalo/design-system';
import { Typography, Snackbar } from '@mui/material';
import { logInfo } from '../../services/Logger';
import WebsocketContext from '../../contexts/websocket/context';
import ChatsContext from '../../contexts/chats/context';

export interface ISnackbar {
  type: SnackbarType;
  message: string;
  title?: string;
  open: boolean;
  handleClose?: () => void;
  discussion?: IDiscussionNotificationContent | null;
  hasTextButton?: boolean;
  delayMillis?: number;
  logName?: string;
}

export type SnackbarType = 'success' | 'info' | 'warning' | 'error';

const SnackbarCustom: React.FC<ISnackbar> = (props: ISnackbar) => {
  const { handleClose, message, type, open, discussion, hasTextButton, title, delayMillis = 0, logName } = props;
  const { chatId, handleOpenDiscussion } = useContext(ChatsContext);
  const { handleShowNotificationMarkedInDiscussion, unsubscribeRoomMessages } = useContext(WebsocketContext);
  const { t } = useTranslation();
  const [isDelayed, setIsDelayed] = useState(true);
  const navigate = useNavigate();
  const AUTO_HIDE_DURATION = 6000;

  useEffect(() => {
    function prepareToShow() {
      setIsDelayed(false);
      if (logName) {
        logInfo(logName);
      }
    }

    let timeout: number;
    if (delayMillis) {
      setIsDelayed(true);
      timeout = window.setTimeout(prepareToShow, delayMillis);
    } else {
      prepareToShow();
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [delayMillis, logName]);

  return (
    <Snackbar
      open={!isDelayed && open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={AUTO_HIDE_DURATION + delayMillis}
      data-testid="snackbarCustom"
    >
      <Alert
        severity={type}
        onClose={handleClose}
        sx={{
          maxWidth: '460px',
          verticalAlign: 'top',
        }}
      >
        {title ? (
          <Typography variant="subtitle2" color="common.black">
            {title}
          </Typography>
        ) : null}
        <Typography variant="body1" color="common.black">
          {message}
        </Typography>
        {type === 'success' && hasTextButton && (
          <Button
            variant="text"
            data-testid="redirect"
            onClick={() => {
              navigate(`/${chatId}`);
              handleClose?.();
            }}
          >
            {t('visualize')}
          </Button>
        )}

        {type === 'info' && (
          <Button
            variant="text"
            data-testid="openChatSnackbarButton"
            onClick={() => {
              unsubscribeRoomMessages('discussion');
              navigate(`/${discussion?.prid}`);
              handleOpenDiscussion(true);
              handleShowNotificationMarkedInDiscussion(false);
              handleClose?.();
            }}
          >
            {t('openChat')}
          </Button>
        )}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;
