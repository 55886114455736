import { shouldForwardPropHelper } from '@engyalo/design-system';
import { styled, Typography } from '@mui/material';

interface IPasswordRule {
  isValid: boolean;
  hasError: boolean;
}

export const PasswordRulesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const PasswordRuleText = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<IPasswordRule>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  color: ${({ isValid, hasError, theme }) =>
    isValid ? theme.palette.neutral.var80 : hasError ? theme.palette.error.main : theme.palette.neutral.var60};
`;
