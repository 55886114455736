import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IFormCannedMessages } from './index';

export const schemaCannedMessages: SchemaOf<IFormCannedMessages> = Yup.object({
  scope: Yup.string().required(''),
  text: Yup.string().required(''),
  shortcut: Yup.string().required(''),
  tags: Yup.array().optional(),
  departmentId: Yup.string().when('scope', {
    is: (scope: string) => scope === 'department',
    then: Yup.string().required('Field is required'),
  }),
}).required();
