import React from 'react';
import { ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { SearchContainer } from './style';
import GenericAutocomplete from '../../../components/Autocomplete';
import InputSearch from '../../../components/InputSearch';
import { TemplateInfo } from '../../../services/types';
import { IDepartment } from '../../../types/department';

interface ISearchView {
  templateSelected?: TemplateInfo;
  departments: IDepartment[];
  setShowDrawerCreateContact: React.Dispatch<React.SetStateAction<boolean>>;
  inputText: string;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  loadHSMs: (term?: string, sortType?: string, sortValue?: number, departmentId?: string) => void;
}
export const SearchView = ({
  templateSelected,
  departments,
  setShowDrawerCreateContact,
  inputText,
  setInputText,
  loadHSMs,
}: ISearchView) => {
  const { t } = useTranslation();
  return (
    <SearchContainer>
      <div style={{ width: '75%' }}>
        <InputSearch
          padding="8px 8px 8px 0px"
          placeholder={t('search')}
          value={inputText}
          onChange={(value) => {
            setInputText(value);
          }}
        />
      </div>
      <div style={{ width: '25%' }}>
        {templateSelected?.ID ? (
          <Button
            data-testid="buttonCreateNewContactConversation"
            onClick={() => {
              setShowDrawerCreateContact(true);
            }}
            variant="outlined"
          >
            {t('newContact')}
          </Button>
        ) : (
          <GenericAutocomplete
            hideSearchIcon
            placeholder={t('filterByTeam')}
            renderOption={(props, option) => (
              <ListItem
                sx={{
                  height: '36px',
                  gap: '6px',
                  padding: '8px 12px',
                }}
                {...props}
              >
                <Typography variant="body1" color="common.black">
                  {option.name}
                </Typography>
              </ListItem>
            )}
            handleChange={(value) => {
              if (value) {
                loadHSMs(undefined, undefined, undefined, value._id);
                return;
              }
              loadHSMs();
            }}
            limitTagsNumber={1}
            multiple={false}
            options={departments}
          />
        )}
      </div>
    </SearchContainer>
  );
};
