import { styled, Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  height: 46px;
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => ` 1px solid ${theme.palette.neutral.var20}`};
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
`;

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.var90};
`;

export const ViewTypeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;
