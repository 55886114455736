/* eslint-disable  @typescript-eslint/no-explicit-any */
import { BFFError, RocketChatApiError, RocketChatMethodError } from './provider';

export function isRocketChatApiError(obj: any): obj is RocketChatApiError {
  return (
    obj &&
    typeof obj === 'object' &&
    'error' in obj &&
    (typeof obj.error === 'number' ||
      typeof obj.error === 'string' ||
      (typeof obj.error === 'object' && obj.error !== null))
  );
}

export function isRocketChatMethodError(obj: any): obj is RocketChatMethodError {
  return obj && typeof obj === 'object' && 'message' in obj && typeof obj.message === 'string';
}

export function isRecord(value: any): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isBFFError(data: any): data is BFFError {
  if (typeof data === 'string') {
    return true;
  }

  if (isRecord(data)) {
    return Object.values(data).every(
      // The BFF can return null values in Err key
      (value) => typeof value === 'string' || typeof value === 'number' || value === null || isRecord(value)
    );
  }

  return false;
}
