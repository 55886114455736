import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { List, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChatItemHistory from '../ChatItemHistory';
import { IHistoryListChats } from './types';

import InputSearch from '../../InputSearch';
import { getHistoryChats } from '../../../services/historyChats';
import { IHistoryChats } from '../../../types/historyChat';
import SnackbarCustom from '../../Snackbar';

const HistoryChatsList = (props: IHistoryListChats) => {
  const { roomId, visitorId, openHistory } = props;
  const { t } = useTranslation();
  const [historyChats, setHistoryChats] = useState<Array<IHistoryChats>>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [inputText, setInputText] = useState('');
  const theme = useTheme();

  const loadHistory = async (search?: string) => {
    try {
      const {
        data: { history = [] },
      } = await getHistoryChats(roomId, visitorId, search, true, true);

      const filter = history.filter(
        (item: IHistoryChats, index: number) =>
          history.findIndex((fIndex: IHistoryChats) => fIndex._id === item._id) === index
      );
      setHistoryChats(filter);
    } catch (err) {
      setOpenSnackbar(true);
      setHistoryChats([]);
    }
  };

  const handleSnackbar = () => {
    setOpenSnackbar((old: boolean) => !old);
  };

  useEffect(() => {
    if (roomId && visitorId) {
      loadHistory();
    }
  }, [roomId, visitorId]);

  return (
    <>
      <InputSearch
        value={inputText}
        onChange={(value: string) => {
          loadHistory(value);
          setInputText(value);
        }}
        placeholder="search"
      />
      <Divider />
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          height: 'calc(100% - 53px)',
          [`${theme.breakpoints.down('md')}`]: {
            height: 'calc(100% - 100px)',
          },
        }}
      >
        {historyChats &&
          historyChats.map((item, index) => (
            <React.Fragment key={index}>
              <ChatItemHistory openChat={(value) => openHistory(value)} historyChat={item} />
              {historyChats && <Divider />}
            </React.Fragment>
          ))}
        {historyChats?.length === 0 && (
          <Typography
            variant="subtitle1"
            color="neutral.var70"
            role="notFoundChats"
            padding="32px 36px"
            textAlign="center"
          >
            {t('notFoundChatOrMessage')}
          </Typography>
        )}
      </List>

      {openSnackbar && (
        <SnackbarCustom
          open={openSnackbar}
          message={t('errorSearch') || ''}
          handleClose={() => handleSnackbar()}
          type="error"
        />
      )}
    </>
  );
};

export default HistoryChatsList;
