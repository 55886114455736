import React, { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { getAvailableUsers } from '../../services/getAvailableUsers';
import SelectAgentContext from './context';
import { IAgentOfDepartment } from '../transfer/provider';

const SelectAgentProvider: React.FC = (props) => {
  const { children } = props;

  const [allAgents, setAllAgents] = useState<IAgentOfDepartment[]>([]);
  const [agentsFiltered, setFilteredAgents] = useState<IAgentOfDepartment[]>([]);

  const [agentSelected, setAgentSelected] = useState('');
  const [onClickAgent, setonClickAgent] = useState(false);
  const [agentOnHover, setAgentOnHover] = useState('');
  const [showSelectAgent, setShowSelectAgent] = useState(false);

  const handleAgentSelected = useCallback((value: string, onClickAgentProp: boolean) => {
    setonClickAgent(onClickAgentProp);
    setAgentSelected(value);
    setShowSelectAgent(false);
    setAgentOnHover('');
  }, []);

  const handleAgentSelection = useCallback(() => {
    setShowSelectAgent(false);
  }, []);

  const handleSelectAgent = useCallback(() => {
    setShowSelectAgent((state) => !state);
  }, []);

  const handleAgentOnHover = useCallback((agt: string) => {
    setAgentOnHover(agt);
  }, []);

  const loadAgents = useCallback(async (filter?: string) => {
    try {
      console.log('filter', filter);
      const {
        data: { items: availableUsers },
      } = await getAvailableUsers({ filter });
      setAllAgents(availableUsers);
      setFilteredAgents(availableUsers);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const filterAgents = useCallback(
    debounce((filterText: string) => {
      const finalUserFilter = filterText.split(' ');
      const findIdx = finalUserFilter.reverse().findIndex((el) => el.startsWith('@'));
      const filterTextSubstring = finalUserFilter[findIdx].substring(1);
      filterTextSubstring.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      console.log('filterTextSubstring', filterTextSubstring);
      loadAgents(filterTextSubstring);
    }, 300),
    [allAgents]
  );

  const resetAgents = useCallback(() => {
    handleAgentSelected('', false);
    setFilteredAgents(allAgents);
  }, [allAgents, handleAgentSelected]);

  useEffect(() => {
    if (!showSelectAgent) {
      resetAgents();
    }
  }, [showSelectAgent]);

  useEffect(() => {
    if (onClickAgent) {
      handleAgentSelected(agentOnHover, false);
      handleAgentSelection();
    }
  }, [agentOnHover, onClickAgent]);

  const memoizedValuesSelectAgentProvider = useMemo(
    () => ({
      filterAgents,
      handleAgentSelected,
      allAgents,
      agentsFiltered,
      agentSelected,
      handleAgentSelection,
      handleAgentOnHover,
      resetAgents,
      showSelectAgent,
      handleSelectAgent,
      loadAgents,
    }),
    [
      filterAgents,
      handleAgentSelected,
      allAgents,
      agentsFiltered,
      agentSelected,
      handleAgentSelection,
      handleAgentOnHover,
      resetAgents,
      showSelectAgent,
      handleSelectAgent,
      loadAgents,
    ]
  );

  return (
    <SelectAgentContext.Provider value={memoizedValuesSelectAgentProvider}>{children}</SelectAgentContext.Provider>
  );
};

export default SelectAgentProvider;
