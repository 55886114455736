import { BaseSyntheticEvent, useState } from 'react';
import { Image, ImageAnchor } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';
import MediaMessageFallback from '../MediaMessageFallback';
import { logError } from '../../../../services/Logger';

const ImageMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { image } = props;
  const [hasError, setHasError] = useState(false);

  if (!image) {
    return null;
  }

  const { url, caption: title } = image;

  const handleError = (event: BaseSyntheticEvent) => {
    setHasError(true);
    logError('Error loading image', event.target.error, { event, targetURL: url });
  };

  return hasError ? (
    <MediaMessageFallback />
  ) : (
    <ImageAnchor href={url} target="_blank" rel="noopener noreferrer">
      <Image src={url} alt={title} loading="lazy" onError={handleError} />
    </ImageAnchor>
  );
};

export default ImageMessage;
