import { AnalyticsWindow } from '../types/analytics';

declare let window: AnalyticsWindow;

/**
 * Load Segment Analytics Window Object
 */
export const loadSegment = () => {
  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    window.analytics.load(`${process.env.REACT_APP_SEGMENT_WRITE_KEY}`);
  }
};
