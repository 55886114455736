import React, { useState } from 'react';
import { ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import GenericAutocomplete from '../../../components/Autocomplete';
import ConfirmDelete from '../../../components/ConfirmDeleteModal';
import InputSearch from '../../../components/InputSearch';
import Drawer from '../../../components/Drawer';
import ModalError from '../../../components/ErrorModal';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import HeaderDrawerMenu from '../HeaderDrawerMenu';
import { FooterPagination, TableContainer } from '../style';
import FormCannedMessages from './FormCannedMessages';
import { Container, ContainerCannedMessages, ContainerTableAndFooter, SearchContainer } from './style';
import useCannedMessages from './useCannedMessages';
import { ModalEdit } from '../../../components/ContextDrawer/EditContactInformation/ModalEdit';

const CannedMessagesManager: React.FC = () => {
  const { t } = useTranslation();
  const {
    handleClickItem,
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    usersCreatedBy,
    setFilterCannedMessages,
    selectCannedMessage,
    showDrawer,
    setShowDrawer,
    loadCannedMessages,
    openDeleteModal,
    handleConfirmDelete,
    openErrorModal,
    setOpenErrorModal,
    errorMessage,
    handleChangeForm,
    unsavedChanges,
    setShowModalUnsavedChanges,
    showModalUnsavedChanges,
  } = useCannedMessages();

  const [inputText, setInputText] = useState('');

  return (
    <ContainerCannedMessages>
      <ContainerTableAndFooter>
        <Container>
          <Typography variant="h2" color="common.black" data-testid="cannedMessagesTitle">
            {t('cannedMessages')}
          </Typography>
          <SearchContainer>
            <div style={{ width: '25%' }}>
              <InputSearch
                padding="8px 8px 8px 0px"
                placeholder={t('searchShortcut')}
                value={inputText}
                onChange={(value) => {
                  setFilterCannedMessages((old) => ({
                    ...old,
                    shortcut: value,
                  }));
                  setInputText(value);
                }}
              />
            </div>

            <div style={{ width: '25%' }}>
              <GenericAutocomplete
                hideSearchIcon
                prefixPlaceholder={t('scope')}
                placeholder={t('all')}
                renderOption={(props, option) => (
                  <ListItem
                    sx={{
                      height: '36px',
                      gap: '6px',
                      padding: '8px 12px',
                    }}
                    {...props}
                  >
                    <Typography variant="caption1">{option.name}</Typography>
                  </ListItem>
                )}
                handleChange={(value) => {
                  setFilterCannedMessages((old) => ({
                    ...old,
                    scope: value?._id,
                  }));
                }}
                limitTagsNumber={1}
                multiple={false}
                options={[
                  { _id: '', name: t('all') },
                  { _id: 'global', name: t('allOrganization') },
                  { _id: 'user', name: t('onlyMe') },
                  { _id: 'department', name: t('specificTeam') },
                ]}
              />
            </div>

            <div style={{ width: '25%' }}>
              <GenericAutocomplete
                hideSearchIcon
                prefixPlaceholder={t('createdBy')}
                placeholder={t('all')}
                renderOption={(props, option) => (
                  <ListItem
                    sx={{
                      height: '36px',
                      gap: '6px',
                      padding: '8px 12px',
                    }}
                    {...props}
                  >
                    <Typography variant="caption1">{option.name}</Typography>
                  </ListItem>
                )}
                handleChange={(value) => {
                  setFilterCannedMessages((old) => ({
                    ...old,
                    createdBy: value?._id,
                  }));
                }}
                limitTagsNumber={1}
                multiple={false}
                options={[{ _id: '', name: t('all') }, ...usersCreatedBy]}
              />
            </div>

            <Button data-testid="newModel" size="small" onClick={() => handleClickItem(false)} variant="outlined">
              {t('newModel')}
            </Button>
          </SearchContainer>
          <TableContainer>
            <CustomTable
              hasButtonOptions
              handleClickItem={(item: any) => handleClickItem(false, item)}
              page={currentPage}
              headItems={headerColumns}
              rows={tableRows}
              handleDeleteItem={(item) => handleClickItem(true, item)}
            />
          </TableContainer>
        </Container>
        <FooterPagination>
          <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
        </FooterPagination>
      </ContainerTableAndFooter>

      {showDrawer && (
        <Drawer open={showDrawer}>
          <HeaderDrawerMenu
            handleClickBackButton={() => {
              if (unsavedChanges) {
                setShowModalUnsavedChanges(true);
                return;
              }
              setShowDrawer(!showDrawer);
            }}
            title={selectCannedMessage?._id ? t('editModelMessage') : t('createModelMessage')}
          />
          <FormCannedMessages
            onClose={() => {
              setShowDrawer(!showDrawer);
              loadCannedMessages();
            }}
            formIsChanged={handleChangeForm}
            cannedMessage={selectCannedMessage}
          />
        </Drawer>
      )}

      {openDeleteModal && (
        <ConfirmDelete
          title="deleteModelMessageModalText"
          message="deleteModelMessageModalText"
          open={openDeleteModal}
          handleConfirm={(confirm) => handleConfirmDelete(confirm)}
        />
      )}

      {openErrorModal && errorMessage?.title && (
        <ModalError
          handleClose={() => setOpenErrorModal(!openErrorModal)}
          message={errorMessage?.bodyMessage}
          title={errorMessage?.title}
          open={openErrorModal}
        />
      )}

      {showModalUnsavedChanges && (
        <ModalEdit
          open={showModalUnsavedChanges}
          errorUpdt={false}
          handleEditing={(confirm) => {
            if (confirm) {
              setShowDrawer(!showDrawer);
            }
            setShowModalUnsavedChanges(false);
          }}
          handleModal={() => setShowModalUnsavedChanges(false)}
        />
      )}
    </ContainerCannedMessages>
  );
};

export default CannedMessagesManager;
