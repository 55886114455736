import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { Typography } from '@mui/material';
import GenericModal from '../../../../components/Modal';
import { IEnableModal } from '../types';
import { FooterButtons } from './style';

const EnableModal: React.FC<IEnableModal> = (props) => {
  const { open, isEnable, handleClose } = props;
  const { t } = useTranslation();
  return (
    <GenericModal open={open} title={isEnable ? t('disableTeam') : t('enableTeam')} handleModal={handleClose}>
      <Typography data-testid="enableModalMessage" variant="body1" color="neutral.var80" margin="32px 0px 48px">
        {isEnable ? t('textDisableTeam') : t('textEnableTeam')}
      </Typography>
      <FooterButtons>
        {isEnable && (
          <Button
            data-testid="disableButton"
            onClick={() => handleClose(true)}
            disableElevation
            color="error"
            variant="outlined"
          >
            {t('disable')}
          </Button>
        )}
        <Button data-testid="cancelButton" color="neutral2" onClick={() => handleClose()} variant="outlined">
          {t('cancel')}
        </Button>
        {!isEnable && (
          <Button data-testid="enableButton" onClick={() => handleClose(true)}>
            {t('activate')}
          </Button>
        )}
      </FooterButtons>
    </GenericModal>
  );
};
export default EnableModal;
