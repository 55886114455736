import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const saveUserProfile = async (
  realname: string,
  newPassword: string,
  username: string,
  nickname: string
): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'saveUserProfile',
      params: [
        {
          realname,
          newPassword,
          username,
          statusText: '',
          statusType: 'online',
          nickname,
          bio: '',
        },
        {},
      ],
    }),
  };
  return AxiosInstance.post('method.call/saveUserProfile', params);
};
