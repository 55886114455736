/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
/* eslint-disable no-await-in-loop */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography } from '@mui/material';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { HIDDEN_DEPARTMENTS, HIDDEN_UNITS } from 'constants/defaultValues';
import { IFormTeamsUserManager, IFormTeamsUserProps } from '../../types';
import { Container, FieldsSection, FooterButton, FormCustom, ListItemSelect } from '../style';
import { schemaTeamsUserManager } from '../yupSchema';
import { OptionalLabel } from '../../../style';
import GenericAutocomplete from '../../../../../components/Autocomplete';
import DepartmentContext from '../../../../../contexts/departments/context';
import { ReactComponent as Loading } from '../../../../../assets/icons/loadingYalo.svg';
import { getUserInfo } from '../../../../../services/getUserInfo';
import { getUnits } from '../../../../../services/getUnits';
import { saveAgentInfo } from '../../../../../services/saveAgentInfo';
import { saveUnit } from '../../../../../services/saveUnit';
import { getMonitorsListUnit } from '../../../../../services/getMonitorsUnit';
import { getDepartmentsListUnit } from '../../../../../services/getDepartmentsUnit';
import { getDepartmentsOfAgent } from '../../../../../services/getDepartmentsOfAgent';
import LoadingAnimation from '../../../../../components/AnimationLoading';
import { getLivechatUserInfo } from '../../../../../services/getLivechatUserInfo';
import { Monitors, Unit } from '../../../../../services/types';
import { logError } from '../../../../../services/Logger';

type CurrentUserFormTeams = {
  _id: string;
  username: string;
};

const FormTeams: React.FC<IFormTeamsUserProps> = (props: IFormTeamsUserProps) => {
  const theme = useTheme();
  const { userId, onCloseForm, isChangeForm, setShowErrorConnectionModal } = props;
  const { t } = useTranslation();
  const [unitsList, setUnitsList] = useState<Unit[]>([]);
  const [monitorUnits, setMonitorUnits] = useState<string[]>([]);
  const { departments } = useContext(DepartmentContext);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [currentUser, setCurrentuser] = useState<CurrentUserFormTeams>({} as CurrentUserFormTeams);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm<IFormTeamsUserManager>({
    mode: 'onChange',
    resolver: yupResolver(schemaTeamsUserManager),
  });

  const unitOptions = useMemo(() => {
    const filteredUnits = unitsList.filter((unit) => !HIDDEN_UNITS.includes(unit.name));
    if (filteredUnits.length > 0) {
      return [
        {
          _id: 'allOptions',
          name: t('allUnits'),
        },
        ...filteredUnits,
      ];
    }
    return [];
  }, [unitsList, t]);

  const departmentOptions = useMemo(() => {
    const filteredDepartments = departments.filter((department) => !HIDDEN_DEPARTMENTS.includes(department.name));
    if (filteredDepartments.length > 0) {
      return [
        {
          _id: 'allOptions',
          name: t('allTeams'),
        },
        ...filteredDepartments,
      ];
    }
    return [];
  }, [departments, t]);

  const getListMonitorsUnit = async (unitId: string) => {
    try {
      const {
        data: { monitors },
      } = await getMonitorsListUnit(unitId);
      const list = monitors.map((item) => ({
        monitorId: item.monitorId,
        username: item.username,
      }));
      return list;
    } catch (error) {
      logError('error getMonitorsListUnit form teams', error);
    }
    return [];
  };

  const getListDeparmentsUnit = async (unitId: string) => {
    try {
      const {
        data: { departments },
      } = await getDepartmentsListUnit(unitId);
      const list = departments.map((item) => ({
        departmentId: item._id,
      }));
      return list;
    } catch (error) {
      logError('error getDepartmentsListUnit form teams', error);
    }
    return [];
  };

  const updateUserUnit = async (
    unitId: string,
    unitInfo: { name: string; visibility: string },
    monitors: { monitorId: string; username: string }[],
    departmentsParam: { departmentId: string }[]
  ) => {
    try {
      const { data } = await saveUnit(unitId, unitInfo, monitors, departmentsParam);
      const res = JSON.parse(data.message);
      return res.result || res;
    } catch (error) {
      logError('error updateUserUnit form teams', error);
    }
  };

  const getUnitsList = async () => {
    try {
      const {
        data: { units },
      } = await getUnits();
      setUnitsList(units.filter((item) => item.type === 'u'));
    } catch (error) {
      logError('error getUnitsList form teams', error);
    }
  };

  const loadUserInfo = async () => {
    setLoading(true);
    try {
      const {
        data: { user },
      } = await getUserInfo(userId);

      const response = await getLivechatUserInfo(userId);

      const { data } = await getDepartmentsOfAgent(userId);

      const auxDpt: string[] = [''];
      data.departments.forEach(({ departmentId }) => {
        departments.forEach((dptCtx) => {
          if (dptCtx._id === departmentId) {
            auxDpt.push(dptCtx._id);
          }
        });
      });

      if (user.roles.includes('livechat-monitor')) {
        setIsSupervisor(true);
        setCurrentuser({
          _id: user._id,
          username: user.username,
        });
        await getUnitsList();
      }

      setValue('teams', auxDpt);

      setValue(
        'maxNumberSimultaneousChat',
        !response.data.user.livechat.maxNumberSimultaneousChat
          ? 0
          : parseInt(response.data.user.livechat.maxNumberSimultaneousChat, 10)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const saveLivechatInfo = async (teams: string[], maxNumberSimultaneousChat: string) => {
    try {
      const {
        data: { success },
      } = await saveAgentInfo(userId, teams, maxNumberSimultaneousChat);

      if (success) {
        return;
      }
      setShowErrorConnectionModal(true);
    } catch (error) {
      setShowErrorConnectionModal(true);
    }
  };

  const onSubmit: SubmitHandler<IFormTeamsUserManager> = async (form) => {
    const { maxNumberSimultaneousChat, teams, units } = form;

    try {
      setLoadingRequest(true);
      if (!isSupervisor) {
        const maxNumber =
          !maxNumberSimultaneousChat || maxNumberSimultaneousChat <= 0 ? '' : maxNumberSimultaneousChat?.toString();
        const list = teams?.filter((item: string) => item !== 'allOptions');
        await saveLivechatInfo(list || [], maxNumber);

        onCloseForm('close');
        return;
      }

      if (units) {
        const selectedUnits: Unit[] = unitsList.filter((item) => units.includes(item._id));
        const departmentsUnits: string[] = [];
        unitsList.forEach(async (unit) => {
          const unitDepartments = await getListDeparmentsUnit(unit._id);
          let currentMonitors = await getListMonitorsUnit(unit._id);

          const isUnitSelected = selectedUnits.find((su) => su._id === unit._id);
          const isMonitorUnit = monitorUnits.includes(unit._id);

          if (!isUnitSelected && isMonitorUnit) {
            currentMonitors = currentMonitors.filter((monitor) => monitor.monitorId !== currentUser._id);
          }

          if (isUnitSelected && !isMonitorUnit) {
            currentMonitors.push({
              monitorId: currentUser._id,
              username: currentUser.username,
            });
          }

          const info = { name: unit.name, visibility: unit.visibility };
          const res = await updateUserUnit(unit._id, info, currentMonitors, unitDepartments);
          if (res && res.error) {
            throw res.error;
          }

          const departmentsIds = unitDepartments.map((item) => item.departmentId);
          departmentsUnits.push(...departmentsIds);
        });

        if (departmentsUnits.length > 0) {
          await saveLivechatInfo(departmentsUnits, '');
        }
      }
      onCloseForm('close');
    } catch (error) {
      setShowErrorConnectionModal(true);
      return;
    } finally {
      setLoadingRequest(false);
    }
  };

  useEffect(() => {
    isChangeForm(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (userId) {
      loadUserInfo();
    }
  }, [userId]);

  useEffect(() => {
    async function updateMonitorUnits() {
      const promises = unitsList.map((unit) =>
        getListMonitorsUnit(unit._id).then((monitors) => monitors.map((monitor) => ({ ...monitor, unitId: unit._id })))
      );

      const responses = await Promise.all(promises);
      const monitors = responses.flat();
      const result = monitors.reduce<string[]>(
        (accumulatedMonitors: string[], monitor: Partial<Monitors>): string[] => {
          if (monitor.unitId && monitor.monitorId === userId && !accumulatedMonitors.includes(monitor.unitId)) {
            accumulatedMonitors.push(monitor.unitId);
          }

          return accumulatedMonitors;
        },
        []
      );

      setMonitorUnits(result);
    }

    if (unitsList) {
      updateMonitorUnits();
    }
  }, [unitsList, userId]);

  return (
    <Container>
      {loading ? (
        <Box alignSelf="center">
          <Loading />
        </Box>
      ) : (
        <FormCustom onSubmit={handleSubmit(onSubmit)} data-testid="formTeams">
          <FieldsSection>
            {isSupervisor && (
              <div style={{ width: '100%' }}>
                <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
                  {t('units')} <OptionalLabel>{`(${t('optional')})`} </OptionalLabel>
                  <Tooltip title="">
                    <i
                      className="fa-solid fa-circle-question"
                      style={{
                        color: theme.palette.neutral.var50,
                        marginLeft: 3,
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Controller
                  name="units"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <GenericAutocomplete
                        placeholder={t('selectUnits')}
                        defaultValue={monitorUnits}
                        renderOption={(props, option, { selected }) => {
                          props.className = '';
                          return (
                            <ListItemSelect {...props}>
                              <Checkbox checked={selected} />
                              {option.name}
                            </ListItemSelect>
                          );
                        }}
                        handleChange={(value) => {
                          const newValue = value.map((item: any) => item._id);
                          onChange(newValue);
                        }}
                        limitTagsNumber={1}
                        multiple
                        options={unitOptions}
                      />
                    );
                  }}
                />
              </div>
            )}
            {!isSupervisor && (
              <>
                <div style={{ width: '100%' }}>
                  <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
                    {t('teams')} <OptionalLabel>{`(${t('optional')})`} </OptionalLabel>
                  </Typography>
                  <Controller
                    name="teams"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => {
                      const { onChange, value } = field;

                      return (
                        <GenericAutocomplete
                          placeholder={t('selectTeams')}
                          defaultValue={value ?? ''}
                          renderOption={(props, option, { selected }) => {
                            props.className = '';
                            return (
                              <ListItemSelect {...props}>
                                <Checkbox checked={selected} />
                                {option.name}
                              </ListItemSelect>
                            );
                          }}
                          handleChange={(value) => {
                            const newValue = value.map((item: any) => item._id);
                            onChange(newValue);
                          }}
                          limitTagsNumber={1}
                          multiple
                          options={departmentOptions}
                        />
                      );
                    }}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
                    {t('maxNumberSimultaneousChat')} <OptionalLabel>{`(${t('optional')})`} </OptionalLabel>
                    <Tooltip title={t('maxNumberSimultaneousMessageTooltip') || ''}>
                      <i
                        className="fa-solid fa-circle-question"
                        style={{
                          color: theme.palette.neutral.var50,
                          marginLeft: 3,
                        }}
                      />
                    </Tooltip>
                  </Typography>
                  <Controller
                    name="maxNumberSimultaneousChat"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        {...field}
                        sx={{ width: '100%' }}
                        error={!!errors.maxNumberSimultaneousChat}
                        helperText={errors.maxNumberSimultaneousChat?.message}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </FieldsSection>
          <FooterButton>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              startIcon={loadingRequest ? null : <i className="fa-regular fa-floppy-disk" />}
            >
              {loadingRequest ? <LoadingAnimation color={theme.palette.neutral.var50} /> : t('saveChanges')}
            </Button>
          </FooterButton>
        </FormCustom>
      )}
    </Container>
  );
};

export default FormTeams;
