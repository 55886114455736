import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Button } from '@engyalo/design-system';
import { ITeams } from 'types/department';
import InputSearch from '../../../components/InputSearch';
import ModalError from '../../../components/ErrorModal';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import { ContainerTableAndFooter, FooterPagination, ItemManagement, SearchContainer, TableContainer } from '../style';
import EnableModal from './EnableModal';
import FormTeam from './Form';
import { ContainerTeamsManager } from './style';
import useTeamsManager from './useTeamsManager';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';

const TeamsManager: React.FC = () => {
  const { t } = useTranslation();
  const {
    currentPage,
    headerColumns,
    filterTableRows,
    totalItems,
    showModalEnableTeam,
    currentTeam,
    openDrawerForm,
    showErrorModal,
    setShowErrorModal,
    handleCloseEnableModal,
    handleCurrentPage,
    onClickItem,
    handleFilter,
    handleNewTeam,
    setOpenDrawerForm,
    loadTeams,
    loading,
  } = useTeamsManager();
  const [inputText, setInputText] = useState('');

  return (
    <>
      <ItemManagement>
        <ContainerTableAndFooter>
          <ContainerTeamsManager data-testid="teamsManagerContainer">
            <Typography data-testid="teamsManagerTitle" variant="h2" color="common.black">
              {t('teams')}
            </Typography>
            <SearchContainer>
              <InputSearch
                testId="inputTeamsManager"
                padding="8px 16px 8px 0px"
                placeholder={t('search')}
                value={inputText}
                onChange={(value) => {
                  handleFilter(value);
                  setInputText(value);
                }}
              />
              <Button
                data-testid="buttonNewTeam"
                size="small"
                onClick={() => handleNewTeam()}
                variant="outlined"
                sx={{
                  minInlineSize: 'fit-content',
                }}
              >
                {t('newTeam')}
              </Button>
            </SearchContainer>
            {loading ? (
              <Box alignSelf="center">
                <Loading />
              </Box>
            ) : (
              <TableContainer>
                <CustomTable
                  handleClickItem={(item: ITeams) => onClickItem(item)}
                  page={currentPage}
                  headItems={headerColumns}
                  rows={filterTableRows}
                  hasButtonOptions={false}
                />
              </TableContainer>
            )}
          </ContainerTeamsManager>
        </ContainerTableAndFooter>
      </ItemManagement>
      <FooterPagination>
        <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
      </FooterPagination>

      {showModalEnableTeam && currentTeam && (
        <EnableModal isEnable={currentTeam?.enabled} open={showModalEnableTeam} handleClose={handleCloseEnableModal} />
      )}

      {openDrawerForm && (
        <FormTeam
          team={
            currentTeam
              ? {
                  teamId: currentTeam?._id,
                  unitId: currentTeam?.unitId,
                }
              : undefined
          }
          open={openDrawerForm}
          handleClose={(reload) => {
            if (reload) {
              loadTeams();
            }
            setOpenDrawerForm(false);
          }}
        />
      )}

      {showErrorModal && (
        <ModalError
          handleClose={() => setShowErrorModal(!showErrorModal)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showErrorModal}
        />
      )}
    </>
  );
};

export default TeamsManager;
