import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { CalendarPickerView, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';

// @ts-ignore
import { CsvBuilder } from 'filefy';

import ChatManagerContext, {
  IChatTableItem,
  SortChatsManagerHome,
  DATE_FILTER_OPTIONS,
} from 'contexts/chatManager/context';
import { DrawerMessageType } from 'components/DrawerMessages/types.d';
import { SelectView } from 'components/SelectView/SelectView';
import DrawerMessagesServiceManager from 'components/DrawerMessages/DrawerMessagesServiceManager';
import InputSearch from '../../../components/InputSearch';
import Drawer from '../../../components/Drawer';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import { FooterPagination, TableContainer } from '../style';
import { Container, SearchContainer, CancelSelection, FiltersContainer } from './style';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import ModalError from '../../../components/ErrorModal';
import { CustomChip } from '../../../components/Tags/style';
import { checkLanguage } from '../../../helpers/checkLanguage';
import AdvancedFilters from '../../../components/ChatsAdvancedFilters';

const CANCEL_START_DATE = 'startDate';
const CANCEL_END_DATE = 'endDate';
const SHOULD_REMOVE_SITUATION_FILTER = false;
const headerColumns: Array<keyof IChatTableItem> = [
  'ticket',
  'contact',
  'team',
  'attendedBy',
  'startedAt',
  'lastMessage',
  'closedAt',
  'situation',
];

const reportHeaderColumns: Array<keyof IChatTableItem> = [
  'ticket',
  'contact',
  'phone',
  'team',
  'attendedBy',
  'startedAt',
  'lastMessage',
  'closedAt',
  'situation',
  'tags',
];

const ChatsManager: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    currentPage,
    tableRows,
    totalItems,
    reportRows,
    handleCurrentPage,
    handleClickItem,
    showDrawer,
    handleShowDrawer,
    chatId,
    setContactName,
    setTicket,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    contactName,
    ticket,
    language,
    showError,
    handleShowError,
    showAdvancedFilters,
    handleCloseAdvancedFilter,
    handleClickAvancedFilter,
    anchorEl,
    handleFilterChats,
    currentAvancedFilters,
    agents,
    departmentsState,
    removeFilter,
    clearFilters,
    loading,
    loadChats,
    orderChat,
    orderByChat,
    filterDateBy,
    setFilterDateBy,
  } = useContext(ChatManagerContext);

  const [anchorElKebab, setAnchorElKebab] = useState<HTMLDivElement | null>(null);
  const [calendarView, setCalendarView] = useState('day');
  const [showLabelStartDate, setShowLabelStartDate] = useState(true);
  const [showLabelEndDate, setShowLabelEndDate] = useState(true);
  const [shouldDownloadReport, setShouldDownloadReport] = useState(false);

  useEffect(() => {
    if (shouldDownloadReport && reportRows.length > 0) {
      const columns = reportHeaderColumns.map((reportHeaderColumn) => t(reportHeaderColumn).toString());
      const rows = reportRows.map((row) =>
        reportHeaderColumns.map((column) => {
          const cellValue = column === 'team' ? row.teamName : row[column];
          return cellValue ? cellValue.toString() : '';
        })
      );

      new CsvBuilder(`${t('conversations')}.csv`).setColumns(columns).addRows(rows).exportFile();
      setShouldDownloadReport(false);
    }
  }, [reportRows, shouldDownloadReport, t]);

  const getChipFilter = (label: string, index: string) => (
    <CustomChip
      label={label}
      customcolor={{
        color: theme.palette.neutral.var20,
        text: theme.palette.neutral.var90,
      }}
      key={index}
      onDelete={() => removeFilter(index)}
      deleteIcon={
        <i
          role="presentation"
          className="fa-regular fa-xmark fa-2xs"
          style={{ cursor: 'pointer', color: theme.palette.neutral.var50 }}
        />
      }
    />
  );

  const showSelectFilters = () =>
    Object.entries(currentAvancedFilters).map(([key, value]) => {
      let label = '';

      if (key === 'tags') {
        const tags = value as string[];
        label = `${t('chatTags')}: ${tags.join(',')}`;
      } else if (key === 'departmentId') {
        label = departmentsState.find((item) => item._id === value)?.name || '';
      } else if (key === 'agentId') {
        label = agents.find((item) => item._id === value)?.name || '';
      } else if (key === 'open') {
        label = `${t('situation')}: ${value ? t('opened') : t('closed')}`;
      } else if (key === 'onhold') {
        label = `${t('situation')}: ${t('onHold')}`;
      } else if (key === 'customFields') {
        return Object.entries(value).map(([customKey, customValue]) => {
          label = `${customKey}: ${customValue}`;
          return getChipFilter(label, customKey);
        });
      } else {
        label = `${key}: ${value}`;
      }

      return getChipFilter(label, key);
    });

  const cancelSelect = (cancelSelection: string) => (
    <Stack
      sx={{
        position: 'absolute',
        bottom: 0,
        cursor: 'pointer',
        zIndex: 1,
        display: calendarView === 'day' ? 'flex' : 'none',
      }}
      width="100%"
    >
      <CancelSelection
        variant="body1"
        color="neutral.var80"
        onClick={() => (cancelSelection === 'startDate' ? setStartDate(null) : setEndDate(null))}
      >
        {t('cancelSelect')}
      </CancelSelection>
    </Stack>
  );

  const handleRenderTableChatsManager = useCallback(() => {
    if (totalItems >= 1) {
      return (
        <>
          <TableContainer>
            <CustomTable
              handleClickItem={(item: IChatTableItem) => handleClickItem(item)}
              page={currentPage}
              headItems={headerColumns}
              rows={tableRows}
              hasButtonOptions={false}
              hasKebabButton
              handleOpenKebab={(event: React.MouseEvent<HTMLDivElement>) => setAnchorElKebab(event.currentTarget)}
              anchorElKebab={anchorElKebab}
              handleCallApi={(sortType: string, sortValue: number) => {
                loadChats(undefined, SortChatsManagerHome[sortType as keyof typeof SortChatsManagerHome], sortValue);
              }}
              order={orderChat}
              orderBy={orderByChat}
              tableName="chatsManager"
            />
          </TableContainer>
          <FooterPagination>
            <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
          </FooterPagination>
          ;
        </>
      );
    }

    if ((contactName || ticket || startDate || endDate) && !totalItems) {
      return (
        <Typography
          data-testid="notFoundChatsServiceManagement"
          variant="subtitle1"
          color="neutral.var70"
          textAlign="center"
        >
          {t('notFoundChats')}
        </Typography>
      );
    }

    if (!totalItems && !loading) {
      return (
        <Typography
          data-testid="emptyChatsMessageServiceManagement"
          variant="subtitle1"
          color="neutral.var70"
          textAlign="center"
        >
          {t('emptyChatsMessage')}
        </Typography>
      );
    }

    return (
      <Box alignSelf="center">
        <Loading />
      </Box>
    );
  }, [
    anchorElKebab,
    contactName,
    currentPage,
    endDate,
    handleClickItem,
    handleCurrentPage,
    loadChats,
    loading,
    orderByChat,
    orderChat,
    startDate,
    t,
    tableRows,
    ticket,
    totalItems,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={checkLanguage(language)}>
      <Container data-testid="containerChatsManager">
        <Typography variant="h2" color="common.black">
          {capitalizeFirstLetter(t('conversations'))}
        </Typography>
        <SearchContainer>
          <Stack direction="row" alignItems="center" gap="20px">
            <InputSearch
              placeholder={t('searchByTicket')}
              value={ticket}
              onChange={(value) => {
                setTicket(value);
              }}
              testId="searchByTicket"
            />

            <InputSearch
              placeholder={t('searchByContacts')}
              onChange={(value) => {
                setContactName(value);
              }}
              testId="searchByContacts"
            />

            <SelectView
              translation={t}
              placeholder={t('filterDateBy')}
              value={filterDateBy}
              setValue={(value: string) => {
                setFilterDateBy(value);
              }}
              onChange={(value: string) => {
                setFilterDateBy(value);
              }}
              optionsAux={DATE_FILTER_OPTIONS}
            />

            <DesktopDatePicker
              disabled={filterDateBy === ''}
              showToolbar
              disableFuture
              label={showLabelStartDate ? t('startDatePlaceholder') : null}
              inputFormat="MM/dd/yyyy"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={() => setShowLabelStartDate(false)}
                  sx={{
                    paddingRight: '6px',
                    minWidth: '150px',
                  }}
                  insidelabel
                />
              )}
              ToolbarComponent={() => cancelSelect(CANCEL_START_DATE)}
              onViewChange={(view: CalendarPickerView) => setCalendarView(view)}
              onClose={() => setCalendarView('day')}
            />
            <DesktopDatePicker
              disabled={filterDateBy === '' || !startDate}
              onError={() => {
                handleShowError();
                setEndDate(null);
              }}
              minDate={new Date(startDate ?? '2000-01-01')}
              showToolbar
              disableFuture
              label={showLabelEndDate ? t('endDatePlaceholder') : null}
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={() => setShowLabelEndDate(false)}
                  sx={{ minWidth: '150px' }}
                  insidelabel
                />
              )}
              ToolbarComponent={() => cancelSelect(CANCEL_END_DATE)}
              onViewChange={(view: CalendarPickerView) => setCalendarView(view)}
              onClose={() => setCalendarView('day')}
            />
          </Stack>
          <Button
            data-testid="btnAdvancedFiltersChatsManager"
            size="small"
            onClick={(event) => handleClickAvancedFilter(event)}
            variant="outlined"
            sx={{
              minInlineSize: 'fit-content',
              marginX: '8px',
            }}
          >
            {t('advancedFilters')}
          </Button>
          <Button
            data-testid="btnExportChats"
            size="small"
            onClick={async () => {
              await loadChats(undefined, undefined, undefined, true);
              setShouldDownloadReport(true);
            }}
            variant="outlined"
          >
            {t('export')}
          </Button>
        </SearchContainer>
        {Object.keys(currentAvancedFilters).length ? (
          <FiltersContainer>
            <i
              role="presentation"
              className="fa-regular fa-filter-slash"
              style={{ cursor: 'pointer' }}
              onClick={() => clearFilters()}
            />
            {showSelectFilters()}
          </FiltersContainer>
        ) : null}
        {handleRenderTableChatsManager()}
      </Container>
      <FooterPagination>
        <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
      </FooterPagination>
      {showDrawer && (
        <Drawer open={showDrawer}>
          <Stack
            data-testid="drawerChatsServiceManager"
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'common.white',
            }}
          >
            <DrawerMessagesServiceManager
              roomId={chatId}
              type={DrawerMessageType.HISTORY}
              user="admin"
              // The flow of handling the drawer and the use of context should be reviewed.
              onClose={handleShowDrawer}
            />
          </Stack>
        </Drawer>
      )}

      {showError && (
        <ModalError
          title={t('invalidDate')}
          message={t('invalidDateMessage')}
          open={showError}
          handleClose={handleShowError}
        />
      )}

      {showAdvancedFilters && (
        <AdvancedFilters
          handleClose={handleCloseAdvancedFilter}
          anchorEl={anchorEl}
          agents={agents}
          departments={departmentsState}
          handleValue={(filters) => handleFilterChats(filters)}
          currentFilters={currentAvancedFilters}
          removeFilterByChatStatus={SHOULD_REMOVE_SITUATION_FILTER}
        />
      )}
    </LocalizationProvider>
  );
};

export default ChatsManager;
