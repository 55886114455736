import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { getRoomInfo } from '../../../services/getRoomInfo';
import { IRoom } from '../../../types/room';
import WebsocketContext from '../../../contexts/websocket/context';
import { MessageType } from '../../../types/message';
import ChatsContext from '../../../contexts/chats/context';

const messageTextOptions = [
  {
    icon: <i className="fa-regular fa-copy" />,
    title: 'copyText',
    function: () => {},
    sessionTypes: [''],
    type: 'copyText',
  },
];

const messageMediaOptions = [
  {
    icon: <i className="fa-regular fa-arrow-down-to-bracket" />,
    title: 'downloadFile',
    sessionTypes: [''],
    function: () => {},
    type: 'download',
  },
];

export const useMessages = (roomId: string, messages: MessageType[]) => {
  const theme = useTheme();
  const refDiv = useRef({} as HTMLUListElement);
  const [serviceSelected, setServiceSelected] = useState<IRoom>({} as IRoom);
  const [floatButton, setFloatButton] = useState(false);
  const { executeScroll } = useContext(WebsocketContext);
  const { handleTrySendAgainMessage } = useContext(ChatsContext);

  const inlineNotificationOptions = [
    {
      icon: <i className="fa-regular fa-inbox" />,
      title: 'returnToQueueTeam',
      function: () => {},
      sessionTypes: [''],
      type: 'returnToQueue',
    },
    {
      icon: <i className="fa-regular fa-hand" />,
      title: 'assignToMe',
      function: () => {},
      sessionTypes: [''],
      type: 'joinChat',
    },
    {
      icon: (
        <i
          className="fa-regular fa-alarm-clock"
          style={{
            color: theme.palette.neutral.var70,
          }}
        />
      ),
      title: 'resumeOnHold',
      function: () => {},
      sessionTypes: [''],
      type: 'omnichannel_placed_chat_on_hold',
    },
  ];

  const messageErrorOptions = [
    {
      icon: <i className="fa-solid fa-arrow-rotate-right" />,
      title: 'tryAgain',
      function: () => handleTrySendAgainMessage(),
      sessionTypes: [''],
    },
  ];

  const downloadFiles = async (url: string, name: string) => {
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener');
    link.setAttribute('href', url);
    link.setAttribute('download', name);

    try {
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getRoom = async () => {
    const {
      data: { room = {} as IRoom },
    } = await getRoomInfo(roomId);
    setServiceSelected(room);
  };

  useEffect(() => {
    executeScroll(refDiv);
  }, [messages, serviceSelected]);

  useEffect(() => {
    if (roomId) getRoom();
  }, [roomId]);

  return {
    inlineNotificationOptions,
    messageErrorOptions,
    messageMediaOptions,
    messageTextOptions,
    downloadFiles,
    serviceSelected,
    floatButton,
    setFloatButton,
    refDiv,
    executeScroll,
  };
};
