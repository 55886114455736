// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserInformation } from '../../types/user';

export interface IConfigStore {
  user: UserInformation | null;
}

const initialState: IConfigStore = {
  user: null,
};

export const configSlice = createSlice({
  name: 'configSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInformation>) => ({
      ...state,
      user: action.payload,
    }),
  },
});

export const { setUser } = configSlice.actions;

export const selectUser = (state: RootState) => state.config.user;

export default configSlice.reducer;
