import axios, { AxiosError, CancelTokenSource } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ChatsContext from 'contexts/chats/context';
import AuthContext from 'contexts/auth/context';
import {
  UserMessageTextMessage,
  UserMessageMessageKind,
  OutgoingMessageOutgoingMessage as WebsocketMessageModel,
  OutgoingMessageMessageContext,
  OutgoingMessageOutgoingMessage,
  OutgoingMessageResponseStatus,
  UserMessageAudioMessage,
  UserMessageDocumentMessage,
  UserMessageImageMessage,
  UserMessageVideoMessage,
} from '@engyalo/salesdesk-chat-socket-ts-client';
import { MessageType } from 'components/Messages';
import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { logError } from 'services/Logger';
import WebSocketService, { SocketEvents } from 'services/Websocket';
import { getChatHistory } from 'services/chat';
import {
  getMessagesFromStorage,
  setMessagesInStorage,
  createNewMessage,
  isMessageType,
  isResponseMessage,
  chatToOutgoingMessage,
  updateMessageFromResponse,
  createUpdatedMessageStatus,
} from './utils';

type UseChatProps = { chatId: string; webSocket: WebSocketService | null };

export type IncomingMessage = WebsocketMessageModel;

export type KindToPayload = {
  [UserMessageMessageKind.Text]: Omit<UserMessageTextMessage, 'roomId'>;
  [UserMessageMessageKind.Audio]: Omit<UserMessageAudioMessage, 'roomId'>;
  [UserMessageMessageKind.Document]: Omit<UserMessageDocumentMessage, 'roomId'>;
  [UserMessageMessageKind.Image]: Omit<UserMessageImageMessage, 'roomId'>;
  [UserMessageMessageKind.Video]: Omit<UserMessageVideoMessage, 'roomId'>;
};

type OutgoingMessage<K extends keyof KindToPayload> = {
  kind: K;
  payload: KindToPayload[K];
};

export type SendMessage = <K extends keyof KindToPayload>(message: OutgoingMessage<K>) => void;

type RetrySendingMessage = (message: MessageType) => void;

export type ResponseMessage = {
  kind: typeof OutgoingMessageMessageContext.Response;
} & Pick<OutgoingMessageOutgoingMessage, 'id'> &
  Required<Pick<OutgoingMessageOutgoingMessage, 'response'>>;

type QueueMessages = boolean;

type Guard = (message: IncomingMessage) => boolean;
type Handler = (props: { message: IncomingMessage; queueMessages: QueueMessages }) => void;
type Actions = Array<[Guard, Handler]>;

const useChat = ({ chatId, webSocket }: UseChatProps) => {
  const cancelTokenRef = useRef<CancelTokenSource>();
  const currentChatId = useRef(chatId);
  const queue = useRef<IncomingMessage[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const { currentUserInfo } = useContext(AuthContext);

  // Temporarily using the following context while their features are not completely migrated
  const {
    serviceSelected: { departmentId },
  } = useContext(ChatsContext);

  const handleChatMessage = useCallback(
    ({ message, queueMessages }: { message: MessageType; queueMessages: QueueMessages }) => {
      const roomId = message.status?.roomId || message.chat?.roomId;

      if (roomId !== chatId) {
        return;
      }

      if (queueMessages) {
        queue.current.push(message);
        return;
      }

      setMessages((prevState) => {
        const newMessages = [...prevState, message];

        setMessagesInStorage(newMessages, chatId);

        return newMessages;
      });
    },
    [chatId]
  );

  const handleResponse = useCallback(
    (message: ResponseMessage) => {
      const { response } = message;
      const { status, reason } = response;

      setMessages((prevMessages) => {
        const newMessages = prevMessages.map((msg) => updateMessageFromResponse(msg, response));

        setMessagesInStorage(newMessages, chatId);

        return newMessages;
      });

      if (status === OutgoingMessageResponseStatus.ResponseStatusFailed) {
        logError(`Unable to send message. Reason: ${reason}`);
      }
    },
    [chatId]
  );

  const actions: Actions = useMemo(
    () => [
      [
        isMessageType,
        ({ message, queueMessages }) => {
          if (isMessageType(message)) {
            handleChatMessage({ message, queueMessages });
          }
        },
      ],
      [
        isResponseMessage,
        ({ message }) => {
          if (isResponseMessage(message)) {
            handleResponse(message);
          }
        },
      ],
    ],
    [handleChatMessage, handleResponse]
  );

  const handleSocketMessage = useCallback(
    ({ queueMessages }: { queueMessages: QueueMessages }) =>
      (message: IncomingMessage) => {
        const [_, handler] = actions.find(([check]) => check(message)) || [];
        if (handler) {
          handler({ message, queueMessages });
        }
      },
    [actions]
  );

  const mergeQueuedMessages = (currentMessages: IncomingMessage[]) => {
    const messagesMap = new Map();

    currentMessages.forEach((message) => {
      const messageId = message.chat?.id || message.status?.id;

      if (messageId) {
        messagesMap.set(messageId, message);
      }
    });

    queue.current.forEach((message) => {
      const messageId = message.chat?.id || message.status?.id;

      if (messageId) {
        messagesMap.set(messageId, message);
      }
    });

    queue.current = [];

    return Array.from(messagesMap.values());
  };

  const fetchChatHistory = useCallback(
    async (roomId: string) => {
      try {
        setLoadingHistory(true);
        cancelTokenRef.current = axios.CancelToken.source();
        const { data: messagesHistory } = await getChatHistory({
          roomId,
          cancelToken: cancelTokenRef.current.token,
        });

        const mergedMessages = mergeQueuedMessages(messagesHistory);
        setMessagesInStorage(mergedMessages, chatId);
        setMessages(mergedMessages);
      } catch (error) {
        if (!(error instanceof AxiosError && error.code === AxiosError.ERR_CANCELED)) {
          logError(`Error while fetching chat history for room id ${roomId}`, error);
        }
      } finally {
        setLoadingHistory(false);
      }
    },
    [chatId]
  );

  const retryMessage: RetrySendingMessage = useCallback(
    (message: MessageType) => {
      if (webSocket && message.chat && message.id) {
        const {
          chat: { id: messageId },
        } = message;

        const outgoingMessage = chatToOutgoingMessage(message.chat, message.id);

        webSocket.sendMessage(outgoingMessage);

        setMessages((prevMessages) =>
          prevMessages.map((currentMessage) => {
            if (currentMessage.chat && currentMessage.chat.id === messageId) {
              return createUpdatedMessageStatus({ message, status: { error: false, pending: true } });
            }

            return currentMessage;
          })
        );
      }
    },
    [webSocket]
  );

  const sendMessage = useCallback<SendMessage>(
    ({ kind, payload }) => {
      if (webSocket) {
        const { name, username } = currentUserInfo;
        const { socketMessage, stateMessage } = createNewMessage({
          kind,
          name,
          username,
          payload,
          departmentId,
          roomId: chatId,
        });

        webSocket.sendMessage(socketMessage);

        if (currentChatId.current === chatId) {
          setMessages((prevState) => [...prevState, stateMessage]);
        }
      }
    },
    [chatId, currentUserInfo, departmentId, webSocket]
  );

  useEffect(() => {
    currentChatId.current = chatId;
  }, [chatId]);

  useEffect(() => {
    cancelTokenRef.current?.cancel();

    setMessages(getMessagesFromStorage(chatId));

    fetchChatHistory(chatId);
  }, [chatId, fetchChatHistory]);

  useEffect(() => {
    webSocket?.sendMessage({
      messageRef: uuidv4(),
      kind: UserMessageMessageKind.SubscribeRoom,
      payload: {
        rooms: [chatId],
      },
    });
  }, [chatId, webSocket]);

  useEffect(() => {
    const handler = handleSocketMessage({ queueMessages: loadingHistory });

    if (webSocket) {
      webSocket.on(SocketEvents.Message, handler);
    }

    return () => {
      if (webSocket) {
        webSocket.off(SocketEvents.Message, handler);
      }
    };
  }, [webSocket, handleSocketMessage, loadingHistory]);

  return { messages, isLoading: loadingHistory, sendMessage, retryMessage };
};

export default useChat;
