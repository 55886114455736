import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IEditProfileFormProps } from './types';

export const schemaEditProfile: SchemaOf<IEditProfileFormProps> = Yup.object({
  name: Yup.string().required(''),
  password: Yup.string()
    .optional()
    .test('isValidPass', ' is not valid', (value) => {
      if (value) {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasMinLength = value.length >= 6;
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasMinLength];
        conditions.forEach((condition) => (condition ? validConditions++ : null));
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
      return true;
    }),
  confirmPassword: Yup.string()
    .optional()
    .when('password', (password, schema) =>
      schema.test({
        test: (confirmPassword: string) => confirmPassword === password,
        message: 'passwords must be the same',
      })
    )
    .when('password', {
      is: (password: string) => !!password,
      then: Yup.string().required('Field is required'),
    }),
}).required();
