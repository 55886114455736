import React from 'react';
import { Stack, Grid, useMediaQuery, Theme, Typography } from '@mui/material';
import { Button, TextField } from '@engyalo/design-system';
import { managementRoles } from 'constants/roles';
import { ChatModalAction } from 'contexts/chats/types.d';
import GenericModal from '../Modal';
import EditTags from '../Tags/EditTags';
import useCloseConversation from './useCloseConversation';
import usePermissions from '../../hooks/usePermissions';
import ButtonsRow from '../ButtonsStack';

export interface IModalCloseConversationProps {
  open: boolean;
}

export const ModalCloseConversation: React.FC<IModalCloseConversationProps> = (props) => {
  const { open } = props;
  const { checkPermission } = usePermissions();
  const {
    translation,
    basicContactInfo,
    tagsSelected,
    setTagsSelected,
    setAnotation,
    handleCloseConversation,
    toggleCloseChatModal,
    chatTags,
  } = useCloseConversation();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleFinishChatModalClose = () => {
    toggleCloseChatModal(ChatModalAction.Close);
  };

  const canModifyTags = checkPermission(managementRoles);

  return (
    <GenericModal
      open={open}
      title={`${translation('closeChat')}`}
      hasCloseIcon
      handleModal={handleFinishChatModalClose}
      isFullScreen={isMobile}
    >
      {basicContactInfo ? (
        <>
          <Stack data-testid="modalCloseChat" width="100%" sx={{ padding: '16px' }}>
            <Grid item xs={12}>
              <Typography variant="caption1" color="neutral.var100">
                {translation('conversationTags')}
              </Typography>
              <EditTags
                type="room"
                createTag={canModifyTags}
                editTag={canModifyTags}
                handleChange={(value: string) => {
                  if (value === '') {
                    setTagsSelected([]);
                  } else {
                    setTagsSelected(value.split(';'));
                  }
                }}
                currentTags={chatTags || ''}
                limitTagsNumber={1}
                open
              />
            </Grid>
            <TextField
              sx={{
                marginTop: '32px',
              }}
              placeholder={translation('anotationForChat')}
              onChange={(event) => {
                setAnotation(event.target.value);
              }}
              inputProps={{ 'data-testid': 'inputAnotation' }}
              label={`${translation('anotation')} (${translation('optional')})`}
            />
          </Stack>
          <ButtonsRow marginTop={isMobile ? 'auto' : 'unset'} justifyContent="space-around">
            {!isMobile && (
              <Button variant="outlined" onClick={handleFinishChatModalClose}>
                {translation('cancel')}
              </Button>
            )}
            <Button
              data-testid="close"
              color="primary"
              disabled={!tagsSelected.length}
              onClick={() => {
                handleCloseConversation();
              }}
            >
              {translation('finalize')}
            </Button>
          </ButtonsRow>
        </>
      ) : null}
    </GenericModal>
  );
};
