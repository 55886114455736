const WEBSOCKET_BASE_URL = process.env.REACT_APP_WEBSOCKET_BASE_URL;
const WEBSOCKET_VERSION = 'v1';
const WEBSOCKET_ENDPOINT = `/${WEBSOCKET_VERSION}/sockets/connect`;

const extractInstance = (): string => {
  const isEphemeral = process.env.REACT_APP_EPHEMERAL === 'true';
  const [primaryPath = '', secondaryPath = ''] = window.location.pathname.split('/').filter(Boolean);

  return isEphemeral ? secondaryPath : primaryPath;
};

const getWebsocketURL = (userId: string, authToken: string): string => {
  const instance = extractInstance();

  return `${WEBSOCKET_BASE_URL}${WEBSOCKET_ENDPOINT}?instance=${instance}&userId=${userId}&token=${authToken}`;
};

interface EnvironmentUtils {
  getEnvironmentInstance: () => string;
  getWebsocketURL: (userId: string, authToken: string) => string;
}

const environmentUtils: EnvironmentUtils = {
  getEnvironmentInstance: extractInstance,
  getWebsocketURL,
};

export default environmentUtils;
