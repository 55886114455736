import { Collapse, List } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { SidebarListIcon, SidebarListItem, SidebarListItemText } from './style';
import { IRenderListItem } from './types';

const RenderListItem: React.FC<IRenderListItem> = (props: IRenderListItem) => {
  const theme = useTheme();
  const { index, item, menuItemType, currentItem, handleClickCollapse, handleMenuOption, sidebarOpenCollapse } = props;

  return (
    <React.Fragment key={index}>
      <SidebarListItem
        isCollapseOpen={item.key === sidebarOpenCollapse}
        isActive={currentItem === menuItemType}
        disablePadding
        key={index}
        onClick={() => {
          if (item.subItems.length > 0) {
            handleClickCollapse(menuItemType);
          } else {
            handleMenuOption(menuItemType);
          }
        }}
      >
        <SidebarListIcon
          sx={{
            color: currentItem === menuItemType ? 'common.white' : 'neutral.var70',
          }}
        >
          {item.icon}
        </SidebarListIcon>
        <SidebarListItemText variant="body1" isActive={currentItem === menuItemType}>
          {capitalizeFirstLetter(item.name)}
        </SidebarListItemText>
        {item.subItems.length > 0 &&
          (sidebarOpenCollapse === item.key ? (
            <i
              className="fa-regular fa-chevron-up"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ) : (
            <i
              className="fa-regular fa-chevron-down fa-2xs"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ))}
      </SidebarListItem>
      {item.subItems.length > 0 && (
        <Collapse in={item.key === sidebarOpenCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subItems.map((subItem) => {
              const itemKey = subItem.key;
              return (
                <SidebarListItem
                  key={itemKey}
                  isActive={currentItem === itemKey}
                  onClick={() => {
                    handleMenuOption(itemKey);
                  }}
                  sx={{ pl: 4 }}
                >
                  <SidebarListItemText isActive={currentItem === itemKey}>{subItem.name}</SidebarListItemText>
                </SidebarListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default RenderListItem;
