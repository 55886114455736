import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const saveAgentInfo = async (
  userId: string,
  departmentsIds: string[],
  maxNumberSimultaneousChat: string
): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'livechat:saveAgentInfo',
      params: [userId, { maxNumberSimultaneousChat }, departmentsIds],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveAgentInfo', params);
};
