import { AxiosResponse } from 'axios';
import axiosMockInstanceLooker from 'services/AxiosInstanceLooker';

export type LookerProps = {
  type: string;
  embedURL: string;
  userTimezone: string;
  userFirstName: string;
  userLastName: string;
  userLocale: string;
  customerName: string;
  yaloBotId: string;
};

interface IGetAnalytics {
  success: string;
  url: string;
}

export const getAnalytics = async (props: LookerProps): Promise<AxiosResponse<IGetAnalytics>> => {
  const { userFirstName, userLastName, customerName, yaloBotId, userTimezone, type, embedURL, userLocale } = props;

  return axiosMockInstanceLooker.post(
    'https://hdunx2cxg9.execute-api.us-east-1.amazonaws.com/prod/create-looker-embed-url-staging',
    {
      externalUserId: 'google-oauth2|101760224938635977006',
      permissions: [
        'access_data',
        'see_lookml_dashboards',
        'see_looks',
        'see_user_dashboards',
        'download_without_limit',
        'see_drill_overlay',
      ],
      models: ['dwh_salesdesk'],
      accessFilters: {},
      firstName: userFirstName,
      lastName: userLastName,
      userAttributes: {
        yalo_account_id: customerName,
        yalo_bots_id: yaloBotId,
        yalo_enviroment: 'global',
        locale: userLocale,
      },
      userTimezone,
      sessionLength: 6000,
      embedUrl: `/embed/dashboards/dwh_salesdesk::${type}?embed_domain=${embedURL}`,
      forceLogoutLogin: true,
    },
    {
      headers: {
        'x-api-key': 'X4PxEYg0lp3WfWvcV2hxb3NS8dqbC4MD5RSHehcd',
        'content-type': 'application/json',
      },
    }
  );
};
