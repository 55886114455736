import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { ContactInfo } from './types';

type GetContatInfo = { contact: ContactInfo };

export const getContactInfo = async (contactId: string): Promise<AxiosResponse<GetContatInfo>> =>
  AxiosInstance.get('omnichannel/contact', {
    params: {
      contactId,
    },
  });
