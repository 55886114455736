import { styled } from '@engyalo/design-system';
import { Stack } from '@mui/material';

export const TagListWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
`;

export const TagsTooltip = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  flex-wrap: wrap;
  margin: 0.6rem 0;
`;
