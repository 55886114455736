import { AxiosResponse } from 'axios';
import { DATE_FILTER_OPTIONS } from 'contexts/chatManager/context';
import AxiosInstance from './AxiosInstance';
import { IRoom } from '../types/room';

export interface GetChatsFilterParameters {
  open?: boolean;
  tags?: string[];
  agentIds?: string[];
  departmentId?: string;
  roomName?: string;
  onHold?: boolean;
  offset?: number;
  count?: number;
  iniDate?: Date | null;
  finalDate?: Date | null;
  customFields?: { [key: string]: string | number };
  sort?: string;
  sortValue?: number;
  filterDateBy?: string | undefined;
}

interface GetChatsFilter {
  rooms: IRoom[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}

export const getChatsFilter = async (props: GetChatsFilterParameters): Promise<AxiosResponse<GetChatsFilter>> => {
  const {
    open,
    tags,
    agentIds,
    departmentId,
    roomName,
    onHold,
    offset,
    count,
    iniDate,
    finalDate,
    customFields,
    sort,
    sortValue,
    filterDateBy,
  } = props;

  const startDate = iniDate ? new Date(iniDate) : new Date();
  startDate.setHours(0, 0, 0);

  const endDate = finalDate ? new Date(finalDate) : new Date();
  endDate.setHours(23, 59, 59);

  const params = {
    tags,
    agents: agentIds,
    open,
    departmentId,
    roomName,
    onhold: onHold,
    offset,
    count,
    customFields,
    sort: sort && { [sort]: sortValue },
    createdAt:
      filterDateBy === DATE_FILTER_OPTIONS[0] ? { start: startDate.toISOString(), end: endDate.toISOString() } : null,
    closedAt:
      filterDateBy === DATE_FILTER_OPTIONS[1] ? { start: startDate.toISOString(), end: endDate.toISOString() } : null,
  };

  return AxiosInstance.get('livechat/rooms', {
    params,
  });
};
