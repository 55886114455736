import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tag } from '@engyalo/design-system';
import { ISchedule } from '../types';

const getAvailableStatus = (schedule: ISchedule): boolean => {
  const { timezone, workHours, disabledAt } = schedule;
  const standardizedDate = new Date().toLocaleString('en-US', { timeZone: timezone });
  const today = new Date(standardizedDate);
  const currentDay = format(today, 'eeee').toLowerCase();
  const currentWorkHour = workHours.find(({ dayOfWeek }) => dayOfWeek === currentDay);

  if (!currentWorkHour || disabledAt) {
    return false;
  }

  const { opens, closes } = currentWorkHour;
  const [startHour, startMinute] = opens.split(':').map(Number);
  const [endHour, endMinute] = closes.split(':').map(Number);
  const start = new Date(today);
  const end = new Date(today);
  start.setHours(startHour, startMinute, 0, 0);
  end.setHours(endHour, endMinute, 0, 0);
  const isAvailable = today >= start && today <= end;
  return isAvailable;
};

const StatusTag = (schedule: ISchedule) => {
  const { t } = useTranslation();
  const value = getAvailableStatus(schedule);
  return value ? (
    <Tag icon={<i className="fa-solid fa-timer" />} label={t('business-hours.table.available')} color="success" />
  ) : (
    <Tag
      icon={<i className="fa-solid fa-alarm-snooze" />}
      label={t('business-hours.table.unavailable')}
      color="default"
    />
  );
};

export default StatusTag;
