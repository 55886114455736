import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Tab, Button } from '@engyalo/design-system';
import { saveChatSuggestedMessages } from 'services/saveChatSuggestedMessages';
import CannedMessagesContext from 'contexts/cannedMessages/context';
import type { MessageType } from 'types/message';
import { StyledTabs } from './style';
import QuickMessages from '../QuickMessages/QuickMessages';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IAssistantMessage {
  role: 'user' | 'agent';
  text: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ backgroundColor: 'white' }}
      id={`suggested-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `suggested-tab-${index}`,
  'aria-controls': `suggested-tabpanel-${index}`,
});

const formatMessages = (messages: MessageType[]): IAssistantMessage[] =>
  messages.map((item) => {
    const username = item.username || item.u.username;
    const isBot = username.includes('.bot');
    return { role: isBot ? 'agent' : 'user', text: item.msg };
  });

const removeEmptyMessages = (messages: MessageType[]) => messages.filter((item) => item.msg && item.msg !== '');

const findLastUserMessage = (messages: IAssistantMessage[]) => {
  const userMessages = messages.filter((message) => message.role === 'user');
  return userMessages[userMessages.length - 1];
};

interface AssistantProps {
  messages: Array<MessageType>;
}

enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

const Assistant = (props: AssistantProps) => {
  const { messages } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const [status, setStatus] = React.useState<Status>(Status.IDLE);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [suggestedReply, setSuggestedReply] = React.useState('');
  const validMessages = React.useMemo(() => removeEmptyMessages(messages), [messages]);
  const formattedMessages = React.useMemo(() => formatMessages(validMessages), [validMessages]);
  const lastUserMessage = React.useMemo(() => findLastUserMessage(formattedMessages), [formattedMessages]);
  const { handleCannedMessageSelected } = React.useContext(CannedMessagesContext);

  React.useEffect(() => {
    if (activeTab === 0) {
      return;
    }
    setStatus(Status.LOADING);
    saveChatSuggestedMessages({
      userQuestion: lastUserMessage?.text || '',
      // TODO: get collectionId from the current user context, this is not implemented in the backend yet
      // so we are using a hardcoded value for now (for demo purposes).
      collectionId: '64ef8c9c8ac2a51e718f7ded',
      conversation: { messages: formattedMessages },
    })
      .then(({ data }) => {
        setSuggestedReply(data.suggested_reply);
        setStatus(Status.RESOLVED);
      })
      .catch(({ data }) => {
        setErrorMessage(data.message);
        setStatus(Status.REJECTED);
      });
  }, [activeTab, formattedMessages, lastUserMessage]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClick = () => {
    handleCannedMessageSelected(suggestedReply, true);
  };

  return (
    <Paper>
      <StyledTabs value={activeTab} onChange={handleChange}>
        <Tab label={t('quickMessages')} {...a11yProps(0)} />
        <Tab
          label={t('suggestedMessages')}
          iconPosition="start"
          icon={<i className="fa-solid fa-lightbulb" />}
          {...a11yProps(1)}
        />
      </StyledTabs>
      <TabPanel value={activeTab} index={0}>
        <QuickMessages isTabsMode />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {status === Status.LOADING ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : null}
        {status === Status.REJECTED ? (
          <Typography variant="body1" color="neutral.var70">
            {errorMessage}
          </Typography>
        ) : null}
        {status === Status.RESOLVED && suggestedReply ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" color="neutral.var70">
              <span style={{ whiteSpace: 'pre-wrap' }}>{suggestedReply}</span>
            </Typography>
            <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={handleClick}>
              {t('insertMessage')}
            </Button>
          </Box>
        ) : null}
      </TabPanel>
    </Paper>
  );
};

export default Assistant;
