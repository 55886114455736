import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  gap: 40px;
  height: 100%;
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  justify-content: space-between;
`;

export const ButtonFormContainer = styled('div')`
  padding: 8px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const CheckboxIcon = styled('span')`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.palette.neutral.var20};
  border: ${({ theme }) => `1.3px solid ${theme.palette.neutral.var50}`};
`;

export const ListItemSelect = styled(ListItem)`
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  cursor: pointer;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  background: ${({ theme }) => theme.palette.common.white};
  :hover {
    color: ${({ theme }) => theme.palette.neutral.var90};
    background: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :active {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
`;
