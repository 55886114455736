import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  width: 100%;
  height: 16px;
  display: flex;
`;

export const BorderLine = styled('div')`
  height: 8px;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var60}`};
  margin: 0 8px;
`;
