import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { ChatStatus, hasStatus } from './types';

type ChatStatusReponse = { success?: boolean } & Record<string, ChatStatus>;

export const getDashChartsPerAgent = async (
  startDate: string,
  endDate: string,
  departmentId?: string
): Promise<AxiosResponse<ChatStatusReponse>> => {
  const params = {
    departmentId,
    start: startDate,
    end: endDate,
  };

  return AxiosInstance.get('livechat/analytics/dashboards/charts/chats-per-agent', {
    params,
  });
};

export const getDashChartsPerAgentByMultipleDepartments = async (
  startDate: string,
  endDate: string,
  departmentIds: string[]
): Promise<Record<string, ChatStatus>> => {
  const promises = departmentIds.map((departmentId) => getDashChartsPerAgent(startDate, endDate, departmentId));
  const results = await Promise.all(promises);

  const aggregate = {} as Record<string, ChatStatus>;

  results.forEach(({ data }) => {
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value !== 'boolean') {
        const chatStatus = value;
        if (hasStatus(chatStatus)) {
          if (aggregate[key] === undefined) {
            aggregate[key] = {
              username: key,
              open: chatStatus.open,
              closed: chatStatus.closed,
              onhold: chatStatus.onhold,
            };
          } else {
            aggregate[key].open += chatStatus.open;
            aggregate[key].closed += chatStatus.closed;
            aggregate[key].onhold += chatStatus.onhold;
          }
        }
      }
    });
  });

  return aggregate;
};
