import { logError } from '../services/Logger';
import { getDepartmentsListUnit } from '../services/getDepartmentsUnit';
import { getMonitorsListUnit } from '../services/getMonitorsUnit';

export const getListMonitorsUnit = async (unitId: string) => {
  try {
    const {
      data: { monitors },
    } = await getMonitorsListUnit(unitId);

    const values = monitors.map((item) => ({
      monitorId: item.monitorId,
      username: item.username,
    }));

    return values;
  } catch (error) {
    logError('error getMonitorsListUnit usedeleteunit', error);
    throw Error('error get monitors list by unit');
  }
};

export const getListDeparmentsUnit = async (unitId: string) => {
  try {
    const {
      data: { departments },
    } = await getDepartmentsListUnit(unitId);

    const values = departments.map((item) => ({
      departmentId: item._id,
      ...item,
    }));
    return values;
  } catch (error) {
    logError('error getListDeparmentsUnit usedeleteunit', error);
    throw Error('error get departments list by unit');
  }
};
