import { shouldForwardPropHelper } from '@engyalo/design-system';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ITextItem {
  isEmpty?: boolean;
}

export const Contact = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ContactInfoView = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
  width: -webkit-fill-available;
`;

export const ContactBody = styled(Stack)`
  color: ${({ theme }) => theme.palette.neutral.var90};
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Phone = styled(Typography)`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TextItem = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<ITextItem>`
  text-align: left;
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.palette.neutral.var30 : theme.palette.neutral.var90)};
  margin-top: 8px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Tags = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ListArea = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const TableContainer = styled('div')`
  display: flex;
  overflow-y: hidden;
  width: -webkit-fill-available;
`;
