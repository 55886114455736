import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogButton,
  DialogContentText,
  DialogTitle,
} from '@engyalo/design-system';
import InputSearch from '../../../components/InputSearch';
import CreateTagModal from '../../../components/Tags/CreateTagModal';
import { TableContainer } from '../style';
import { Container, SearchContainer } from './style';
import useTagManager from './useTagManager';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';

export interface ITagManager {
  type: 'visitor' | 'room';
}

const TagManager: React.FC<ITagManager> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const {
    handleSearchTags,
    handleClickItem,
    headerColumns,
    tableRows,
    totalItems,
    openCreateUpdateTagModal,
    selectTag,
    handleCloseTagModal,
    handleConfirmDisableTag,
    openDisableModal,
    textToSearch,
    loading,
  } = useTagManager(props);

  const handleRenderTableTagsManager = () => {
    if (totalItems >= 1) {
      return (
        <TableContainer>
          <DataGrid
            sortingOrder={['desc', 'asc']}
            columns={headerColumns}
            rows={tableRows}
            loading={loading}
            getRowId={({ _id }) => _id}
            onRowClick={({ row }) => handleClickItem(row)}
            initialState={{
              sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            }}
            localeText={{
              footerTotalRows: `${t('totalTags')}:`,
            }}
          />
        </TableContainer>
      );
    }

    if (textToSearch && !totalItems && !loading) {
      return (
        <Typography data-testid="tagsNotFound" variant="body1" color="neutral.var60" textAlign="center">
          {t('tagsNotFound')}
        </Typography>
      );
    }

    if (!totalItems && !loading) {
      return (
        <Typography data-testid="emptyTagsMessage" variant="body1" color="neutral.var60" textAlign="center">
          {t('emptyTags')}
        </Typography>
      );
    }

    return (
      <Box data-testid="loadingTagsManager" alignSelf="center">
        <Loading />
      </Box>
    );
  };

  return (
    <>
      <Container data-testid="tagManagerContainer">
        <Typography variant="h2" color="common.black">
          {type === 'visitor' ? t('contactTags') : t('conversationTags')}
        </Typography>
        <SearchContainer>
          <InputSearch
            allowEmptySearch
            testId="searchTagManager"
            padding="8px 16px 8px 0px"
            placeholder={t('search')}
            value={textToSearch}
            onChange={(value) => {
              handleSearchTags(value);
            }}
          />
          <Button
            data-testid="btnCreateTagTagsManager"
            size="small"
            onClick={() => handleClickItem()}
            variant="outlined"
            sx={{
              minInlineSize: 'fit-content',
            }}
          >
            {t('newTag')}
          </Button>
        </SearchContainer>
        {handleRenderTableTagsManager()}
      </Container>
      {openCreateUpdateTagModal && (
        <CreateTagModal
          typeDescription={type}
          tag={selectTag && { ...selectTag, customColor: { color: '', text: '' } }}
          open={openCreateUpdateTagModal}
          handleCloseModal={(confirm: boolean) => handleCloseTagModal(confirm)}
        />
      )}
      <Dialog data-testid="confirm-tag-disable" open={openDisableModal} variant="info" title={t('confirm')}>
        <DialogTitle>
          {t('confirmDisableTag')}
          <DialogContentText>
            {type === 'room' ? t('confirmDisableConversationsTagText') : t('confirmDisableContactTagText')}
          </DialogContentText>
        </DialogTitle>
        <DialogActions>
          <DialogButton onClick={() => handleConfirmDisableTag(false)}>{t('cancel')}</DialogButton>
          <DialogButton primary onClick={() => handleConfirmDisableTag(true)}>
            {t('disable')}
          </DialogButton>
        </DialogActions>
      </Dialog>
      );
    </>
  );
};

export default TagManager;
