import { LanguageTags } from 'i18n';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ButtonLanguage, Container } from './style';
import { ReactComponent as PTBR } from '../../../../assets/icons/PT-BR.svg';
import { ReactComponent as ENG } from '../../../../assets/icons/ENG.svg';
import { ReactComponent as ESP } from '../../../../assets/icons/ESP.svg';
import PreferencesContext from '../../../../contexts/preferences/context';

const LanguagePicker: React.FC = () => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const { selectedLanguage, handleSelectLanguage } = useContext(PreferencesContext);

  useEffect(() => {
    handleSelectLanguage(i18n.language);
  }, [i18n.language]);

  const checkIsSelectedForFillColorIcon = (language: string) =>
    selectedLanguage === language ? theme.palette.primary.main : theme.palette.neutral.var80;

  const languages = [
    {
      icon: <ESP fill={checkIsSelectedForFillColorIcon(LanguageTags.ES)} />,
      language: LanguageTags.ES,
      ariaLabel: t('spanish'),
    },
    {
      icon: <ENG fill={checkIsSelectedForFillColorIcon(LanguageTags.EN)} />,
      language: LanguageTags.EN,
      ariaLabel: t('english'),
    },
    {
      icon: <PTBR fill={checkIsSelectedForFillColorIcon(LanguageTags.PT_BR)} />,
      language: LanguageTags.PT_BR,
      ariaLabel: t('portuguese'),
    },
  ];

  return (
    <Container data-testid="languagePicker">
      {languages.map(({ icon, language, ariaLabel }) => (
        <ButtonLanguage
          key={language}
          data-testid="selectLanguage"
          aria-label={ariaLabel}
          type="button"
          onClick={() => handleSelectLanguage(language)}
          className={selectedLanguage === language ? 'selected' : ''}
        >
          {icon}
        </ButtonLanguage>
      ))}
    </Container>
  );
};

export default LanguagePicker;
