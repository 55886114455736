import { styled } from '@mui/material/styles';
import { Box as Mbox } from '@mui/material';
import { shouldForwardPropHelper } from '@engyalo/design-system';

interface IframeProps {
  isClosed?: boolean;
}

export const Iframe = styled('iframe', {
  shouldForwardProp: shouldForwardPropHelper,
})<IframeProps>`
  width: ${(props) => (props.isClosed === false ? '0' : '100')}%;
  min-width: 0vw;
  height: ${(props) => (props.isClosed === false ? '0' : '100')}vh;
  display: flex;
`;

export const Box = styled(Mbox)`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;
