import React from 'react';
import { MenuItem } from '@mui/material';
import { TextField } from '@engyalo/design-system';
import { v4 as uuid } from 'uuid';

export const SelectView = ({
  translation,
  field,
  value,
  setValue,
  id,
  onChange,
  optionsAux,
  error,
  FormHelperTextProps,
  helperText,
  placeholder,
}: any): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> => (
  <TextField
    data-testid="selectView"
    {...field}
    value={value}
    onChange={(event) => {
      setValue(id);
      onChange(event.target.value);
    }}
    select
    error={error}
    FormHelperTextProps={FormHelperTextProps}
    helperText={helperText}
    aria-label={placeholder}
    sx={{ width: '100%' }}
    placeholder={placeholder}
  >
    {optionsAux.map((option: string) => (
      <MenuItem key={uuid()} value={option} sx={{ fontSize: '14px' }}>
        {translation ? translation(option) : option}
      </MenuItem>
    ))}
  </TextField>
);
