import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TextField } from '@engyalo/design-system';
import shadows from '../../theme/shadows';

export const CustomInput = styled(TextField)`
  .MuiInputBase-input {
    padding: 0px;
  }
  .MuiOutlinedInput-root {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 8px 12px;
  }
  width: 100%;
  min-height: 70px;
`;

export const IconOption = styled('div')`
  margin: 0px 20px 0px 0px;
  cursor: pointer;
`;

export const ContainerTextFormatting = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  width: 100%;
  padding: 0px 2px 0px;
  align-items: center;
  justify-content: space-between;
`;

export const IconsOptions = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const TextDynamicData = styled(Typography)`
  color: ${({ theme }) => theme.palette.neutral.var50};
  cursor: pointer;
`;

export const ContextualDynamicValues = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.var40}`};
  box-shadow: ${shadows.shadowBlack4};
  border-radius: 8px;
  width: 205px;
`;

export const TitleItems = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleDynamicValues = styled(Typography)`
  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${({ theme }) => theme.palette.neutral.var70};
  white-space: nowrap;
`;

export const DivisorItem = styled('div')`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.palette.neutral.var20};
  margin-left: 8px;
`;

export const DynamicValueItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;
  height: 36px;
  background: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
`;
