import React, { useEffect, useContext, useState } from 'react';
import { Stack, Divider, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Button, TextField } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { managementRoles } from 'constants/roles';
import { Form, ItemForm, helperTextStyle } from './style';
import { IContactInfoProps } from './types';
import useEdit from './useEdit';
import { ModalEdit } from './ModalEdit';
import EditTags from '../../Tags/EditTags';
import DrawerContext from '../../../contexts/drawer/context';
import usePermissions from '../../../hooks/usePermissions';
import { SelectView } from '../../SelectView/SelectView';
import LoadingAnimation from '../../AnimationLoading';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';

const getHelperText = (helperText: unknown): string => {
  if (typeof helperText === 'string') {
    return helperText;
  }

  return '';
};

const EditContactInformation: React.FC<IContactInfoProps> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleEditing, isediting, handleIsDirtyForm } = props;
  const { checkPermission } = usePermissions();
  const { contactInfo, customFields } = useContext(DrawerContext);
  const [editInformationModalOpen, setEditInformationModalOpen] = useState(false);

  const canModifyTags = checkPermission(managementRoles);
  const handleModal = () => {
    setEditInformationModalOpen((prevState) => !prevState);
  };

  const { control, handleSubmit, onSubmit, errors, loading, errorUpdt, setValue, checkField } = useEdit({
    token: contactInfo ? contactInfo.token : '',
    handleEditing,
    customFields,
    handleIsDirtyForm,
  });

  useEffect(() => {
    if (errorUpdt) handleModal();
  }, [errorUpdt]);

  return (
    <>
      {contactInfo && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            sx={{
              padding: '16px',
              gap: '40px',
              height: '100%',
              overflowY: 'scroll',
            }}
            data-testid="editContactInformationForm"
          >
            <ItemForm role="field">
              <Controller
                name="name"
                control={control}
                defaultValue={contactInfo.name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.name}
                    helperText={t((errors.name?.message && (errors.name.message as string)) ?? '')}
                    sx={{ width: '100%' }}
                    label={t('name')}
                    InputProps={{}}
                    inputProps={{
                      role: 'fieldName',
                    }}
                  />
                )}
              />
            </ItemForm>
            <ItemForm role="field">
              <Controller
                name="email"
                control={control}
                defaultValue={contactInfo.visitorEmails.length ? contactInfo.visitorEmails[0].address : ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onBlur={() => checkField('email')}
                    error={!!errors.email}
                    label={t('email')}
                    sx={{ width: '100%' }}
                    inputProps={{
                      role: 'fieldEmail',
                    }}
                    helperText={t(getHelperText(errors?.email?.message))}
                  />
                )}
              />
            </ItemForm>

            {customFields.map((ctmField: any, index: number) => {
              const { _id: id, options, label, type, defaultValue } = ctmField;

              if (type === 'select') {
                const optionsAux = options.split(',');
                optionsAux.pop();

                return (
                  <ItemForm key={`${index}select`} role="field">
                    <Typography variant="caption1">{capitalizeFirstLetter(label)}</Typography>
                    <Controller
                      name={id}
                      control={control}
                      defaultValue={contactInfo.livechatData[id] ?? defaultValue}
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <SelectView
                            field={field}
                            value={value}
                            setValue={setValue}
                            id={id}
                            onChange={onChange}
                            optionsAux={optionsAux}
                            error={!!errors[id]}
                            FormHelperTextProps={{
                              style: helperTextStyle,
                            }}
                            helperText={t(getHelperText(errors[id]?.message))}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }

              if (type === 'input' && id !== 'tags') {
                return (
                  <ItemForm key={`${index}input`} role="field">
                    <Controller
                      name={id}
                      control={control}
                      defaultValue={contactInfo.livechatData[id] ?? ''}
                      render={({ field }) => {
                        const { onChange, name, value } = field;
                        return (
                          <TextField
                            aria-label={name}
                            error={!!errors[id]}
                            helperText={t((errors[id]?.message && (errors[id]?.message as unknown as string)) ?? '')}
                            label={capitalizeFirstLetter(label)}
                            sx={{ width: '100%' }}
                            inputProps={{ 'data-testid': 'textFieldCustom' }}
                            name={name}
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }

              if (type === 'input' && id === 'tags') {
                return (
                  <ItemForm key={`${index}input-tags`} role="field">
                    <Typography variant="caption1"> {capitalizeFirstLetter(label)}</Typography>
                    <Controller
                      name={id}
                      control={control}
                      defaultValue={contactInfo.livechatData[id] ?? ''}
                      render={({ field }) => {
                        const { onChange, value } = field;

                        return (
                          <EditTags
                            type="visitor"
                            editTag={canModifyTags}
                            createTag={canModifyTags}
                            handleChange={(selectTags: string) => {
                              if (selectTags !== value) {
                                onChange(selectTags);
                              }
                            }}
                            currentTags={value}
                            open={isediting}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }
            })}
          </Stack>
          <Stack sx={{ height: '53px' }}>
            <Divider />
            <Stack
              sx={{
                justifyContent: 'center',
                margin: '8px',
                height: '52px',
                bottom: 0,
                width: '-webkit-fill-available',
                background: 'common.wite',
              }}
            >
              <Button
                data-testid="buttonSaveEditInfo"
                id="buttonSaveEditInfo"
                type="submit"
                disabled={!!errors.email}
                variant="outlined"
                startIcon={<i className="fa-regular fa-check fa-sm" />}
              >
                {loading ? <LoadingAnimation color={theme.palette.primary.main} /> : t('saveChanges')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
      <ModalEdit
        open={editInformationModalOpen}
        errorUpdt={errorUpdt}
        handleModal={handleModal}
        handleEditing={handleEditing}
      />
    </>
  );
};

export default EditContactInformation;
