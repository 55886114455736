import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

interface ISaveTag {
  message: string;
  success: boolean;
}

export interface IParamsSaveTag {
  idRequest: string;
  name: string;
  description?: string;
  id?: string;
  departmentsIds?: string[];
}
export const saveTag = async (props: IParamsSaveTag): Promise<AxiosResponse<ISaveTag>> => {
  const { idRequest, name, description, id, departmentsIds } = props;
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: idRequest,
      method: 'livechat:saveTag',
      params: [
        id,
        {
          name,
          description,
        },
        [...(departmentsIds || [])],
      ],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveTag', params);
};
