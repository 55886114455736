import { getDashChartsPerAgentByMultipleDepartments } from '../../../services/dashChartsPerAgent';
import { ChatStatus } from '../../../services/types';

export const getUserStats = async (dateStart: string, dateEnd: string, teamIds: string[]) => {
  const data = await getDashChartsPerAgentByMultipleDepartments(dateStart, dateEnd, teamIds);

  const validUsers: ChatStatus[] = Object.values(data);

  return validUsers;
};
