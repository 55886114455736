const ALLOWED_AUDIO_FILE_TYPES = ['.aac', '.amr', '.mp3', '.m4a'];
const ALLOWED_DOCUMENT_FILE_TYPES = ['.txt', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx', '.pdf'];
const ALLOWED_IMAGE_FILE_TYPES = ['.jpeg', '.jpg', '.png'];
const ALLOWED_VIDEO_FILE_TYPES = ['.mp4'];

export const ALLOWED_FILE_TYPES = [
  ALLOWED_AUDIO_FILE_TYPES,
  ALLOWED_DOCUMENT_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES,
  ALLOWED_VIDEO_FILE_TYPES,
]
  .flat()
  .join(',');

export enum MAIN_TYPE {
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
}

export enum STATUS {
  FAILED = 500,
  SUCCESS = 200,
}
