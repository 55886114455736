import React, { useCallback, useEffect, useState } from 'react';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { IChatHistory } from './types';
import { Tags } from './style';
import { CustomChip } from '../../Tags/style';
import { tagsColor } from '../../../helpers/tagsColors';
import LoadingAnimation from '../../AnimationLoading';

export const TICKET_NUMBER_REGEX = /\s*TICKET\s*:\s*(?<ticketNumber>#\s*[\d\w]{6})/;

const ChatItemHistory: React.FC<IChatHistory> = (props: IChatHistory) => {
  const { t } = useTranslation();
  const { historyChat, openChat } = props;
  const { closedAt, tags, ts } = historyChat;
  const [ticket, setTicket] = useState('');
  const [chipTags, setChipTags] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const handleFinishByName = () => {
    if (historyChat.closedBy.username === historyChat?.closingMessage?.u?.username) {
      return historyChat.closingMessage.u.name;
    }
    return historyChat.closedBy.username;
  };

  const loadTags = useCallback(() => {
    if (tags?.length > 0) {
      const list = tags
        .map((item: any) => {
          const color = tagsColor(item);
          return {
            name: item,
            customColor: color,
          };
        })
        .slice(0, 2);
      if (tags.length > 2) {
        const color = tagsColor();
        list.push({
          name: `+${tags.length - 2}`,
          customColor: color,
        });
      }
      setChipTags(list);
    }

    setLoading(false);
  }, [tags]);

  useEffect(() => {
    const found = historyChat?.closingMessage?.msg.match(TICKET_NUMBER_REGEX);
    const ticketNumber = found?.groups?.ticketNumber.replace(' ', '');

    if (ticketNumber) {
      setTicket(`Ticket: ${ticketNumber}`);
    }

    loadTags();
  }, [historyChat, loadTags]);

  return (
    <ListItem
      role="HistoryItem"
      onClick={() => openChat(historyChat._id)}
      disablePadding
      sx={{
        padding: '16px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListItemText
        role="titleHistory"
        sx={{ width: '100%' }}
        primary={
          <Typography variant="body1" color="neutral.var90">
            {ticket || t('serviceHeldInBot')}
            <Typography variant="overline" color="neutral.var90" sx={{ float: 'right' }}>
              {new Date(closedAt ?? ts).toLocaleDateString()}
            </Typography>
          </Typography>
        }
        secondary={
          <Typography variant="caption1" color="neutral.var70">
            {historyChat.closedBy && (
              <span>
                {`${historyChat.msgs || 0} ${t('messagesCountHistorychats')} - ${t(
                  'finishBy'
                )} ${handleFinishByName()}`}
              </span>
            )}
            <Typography variant="overline" color="neutral.var70" sx={{ float: 'right' }}>
              {new Date(closedAt ?? ts).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
          </Typography>
        }
      />
      <Tags>
        {loading && <LoadingAnimation color={theme.palette.neutral.var90} />}
        {chipTags?.map((tag, index) => (
          <CustomChip key={index} label={tag.name} customcolor={tag.customColor} />
        ))}
      </Tags>
    </ListItem>
  );
};

export default ChatItemHistory;
