import React from 'react';
import { Button } from '@engyalo/design-system';
import { MainText } from './style';
import GenericModal from '../Modal';
import { IModalErrorCloseConversation } from './types';

import useCloseConversation from '../ModalCloseConversation/useCloseConversation';
import ButtonsRow from '../ButtonsStack';

export const ModalErrorCloseConversation: React.FC<IModalErrorCloseConversation> = (
  props: IModalErrorCloseConversation
) => {
  const { openError, handleErrorCloseChat } = props;
  const { translation, basicContactInfo, handleCloseConversation } = useCloseConversation();

  return (
    <>
      {basicContactInfo && (
        <GenericModal open={openError} title={translation('errorCloseChat')}>
          <MainText variant="body1" color="neutral.var100">
            {translation('errorMessageWhenCloseChat')}
          </MainText>
          <ButtonsRow justifyContent="space-around">
            <Button
              role="cancelBtn"
              color="neutral2"
              variant="outlined"
              onClick={() => {
                handleErrorCloseChat(false);
              }}
            >
              {translation('cancel')}
            </Button>
            <Button
              role="tryAgainBtn"
              onClick={() => {
                handleErrorCloseChat(false);
                handleCloseConversation();
              }}
            >
              {translation('tryAgain')}
            </Button>
          </ButtonsRow>
        </GenericModal>
      )}
    </>
  );
};
