import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getMonitors = async (): Promise<AxiosResponse> =>
  AxiosInstance.get('livechat/monitors', {
    params: {
      count: 100,
      offset: 0,
      text: '',
    },
  });
