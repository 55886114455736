import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';
import paramsSerializer from './AxiosParamsSerializer';
import { STORAGE_KEYS } from '../constants/defaultValues';

const AxiosInstance = axios.create({
  paramsSerializer,
  headers: { 'Content-type': 'application/json' },
});

export const setBaseUrlAxiosInstance = (url: string) => {
  AxiosInstance.defaults.baseURL = `${url}/api/v1/`;
  AxiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      const authToken = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
      const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
      if (authToken && userId) {
        config.headers.set({ 'X-User-Id': userId });
        config.headers.set({ 'X-Auth-Token': authToken });
      }

      return config;
    },
    async (error: AxiosError) => Promise.reject(error)
  );
};

export default AxiosInstance;
