import { styled } from '@mui/material/styles';
import { Typography, Popover } from '@mui/material';

export const CustomPopover = styled(Popover)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    .MuiPaper-root {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100%;
      left: 0 !important;
      top: 0 !important;
      height: 100%;
    }
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HeaderTransfer = styled(Typography)`
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 16px;
`;

export const OptionItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 0px;
  cursor: pointer;
  gap: 4px;
`;
