import {
  OutgoingMessageMessageContext,
  OutgoingMessageResponseMessage,
  OutgoingMessageResponseStatus,
  UserMessageMessageKind,
} from '@engyalo/salesdesk-chat-socket-ts-client';
import { getItem, setItem } from 'utils/localStorageUtils';
import { v4 as uuidv4 } from 'uuid';
import { MessageType } from 'components/Messages';
import { ChatMessageType, MessageKind } from 'components/Messages/Message';
import { logWarn } from 'services/Logger';
import { IncomingMessage, KindToPayload, ResponseMessage } from '../useChat';

const STORAGE_KEY_PREFIX = 'chatMessages_';
const PENDING_MESSAGE_ALERT_MILLISECONDS = 5000;
const pendingMessageErrorKey = 'PENDING_MESSAGE_TAKING_TOO_LONG';
const pendingAlertTimeouts: Record<string, NodeJS.Timeout | number> = {};

export const getMessagesFromStorage = (id: string) => getItem<MessageType[]>(`${STORAGE_KEY_PREFIX}${id}`) || [];
export const getChatLastMessageFromStorage = (id: string) => {
  const chatMessages = getItem<MessageType[]>(`${STORAGE_KEY_PREFIX}${id}`) || [];
  return chatMessages.at(-1);
};

export const setMessagesInStorage = (messages: MessageType[], chatId: string) => {
  setItem(`${STORAGE_KEY_PREFIX}${chatId}`, messages);
};

const messageKinds: Array<MessageKind> = [OutgoingMessageMessageContext.Chat, OutgoingMessageMessageContext.Status];

export const isMessageType = (message: IncomingMessage): message is MessageType =>
  messageKinds.some((kind) => kind === message.kind);

export const isResponseMessage = (message: IncomingMessage): message is ResponseMessage =>
  message.kind === OutgoingMessageMessageContext.Response;

export const createNewMessage = <K extends keyof KindToPayload>({
  name,
  username,
  roomId,
  kind,
  payload,
  departmentId,
}: {
  kind: K;
  name: string;
  username: string;
  payload: KindToPayload[K];
  roomId: string;
  departmentId: string;
}) => {
  const currentDate = new Date();
  const currentTimestamp = String(currentDate.getTime());

  const messageRef = uuidv4();

  const socketMessage = {
    kind,
    messageRef,
    payload: {
      ...payload,
      roomId,
    },
  };

  pendingAlertTimeouts[messageRef] = setTimeout(
    () => logWarn(pendingMessageErrorKey, null, { messageRef, roomId }),
    PENDING_MESSAGE_ALERT_MILLISECONDS
  );

  const stateMessage: MessageType = {
    id: messageRef,
    kind: 'chat',
    chat: {
      id: uuidv4(),
      kind,
      pending: true,
      name,
      timestamp: currentTimestamp,
      authorRole: 'agent',
      departmentId,
      roomId,
      username,
      [kind]: payload,
    },
  };

  return {
    socketMessage,
    stateMessage,
  };
};

type Conditions<K extends keyof KindToPayload> = Array<[boolean, KindToPayload[K]]>;

export const chatToOutgoingMessage = (chatMessage: ChatMessageType, messageRef: string) => {
  const conditions: Conditions<keyof KindToPayload> = [
    [
      chatMessage.kind === UserMessageMessageKind.Text,
      {
        body: chatMessage.text?.body || '',
      },
    ],
    [
      chatMessage.kind === UserMessageMessageKind.Audio,
      {
        url: chatMessage.audio?.url,
        mimeType: chatMessage.audio?.mimeType,
      },
    ],
    [
      chatMessage.kind === UserMessageMessageKind.Image,
      {
        url: chatMessage.image?.url,
        mimeType: chatMessage.image?.mimeType,
        caption: chatMessage.image?.caption,
      },
    ],
    [
      chatMessage.kind === UserMessageMessageKind.Video,
      {
        url: chatMessage.video?.url,
        mimeType: chatMessage.video?.mimeType,
        caption: chatMessage.video?.caption,
      },
    ],
    [
      chatMessage.kind === UserMessageMessageKind.Document,

      {
        url: chatMessage.document?.url,
        mimeType: chatMessage.document?.mimeType,
        caption: chatMessage.document?.caption,
      },
    ],
  ];

  const [_, payload] = conditions.find(([check]) => check) || [];

  const { kind, roomId } = chatMessage;

  const message = {
    kind,
    messageRef,
    payload: {
      ...payload,
      roomId,
    },
  };

  return message;
};

type CreateUpdatedMessageStatus = {
  message: MessageType;
  status: { error?: boolean; pending?: boolean; delivered?: boolean; read?: boolean };
};

export const createUpdatedMessageStatus = ({ message, status }: CreateUpdatedMessageStatus): MessageType => {
  if (message.chat) {
    return { ...message, chat: { ...message.chat, ...status } };
  }

  return message;
};

export const updateMessageFromResponse = (message: MessageType, response: OutgoingMessageResponseMessage) => {
  const { messageRef, status } = response;

  if (!messageRef || !status) {
    return message;
  }

  const messageStatus = {
    [OutgoingMessageResponseStatus.ResponseStatusFailed]: { error: true },
    [OutgoingMessageResponseStatus.ResponseStatusSent]: { pending: false },
    [OutgoingMessageResponseStatus.ResponseStatusDelivered]: { delivered: true },
    [OutgoingMessageResponseStatus.ResponseStatusRead]: { read: true },
  };

  if (status === OutgoingMessageResponseStatus.ResponseStatusSent) {
    clearTimeout(pendingAlertTimeouts[messageRef]);
  }

  const messageReferences = [
    // For messages created by this app
    message.id,
    // For messages read from the history (BFF)
    message.chat?.messageRef,
  ];

  if (messageReferences.includes(messageRef)) {
    const updatedMessage = createUpdatedMessageStatus({ message, status: messageStatus[status] });

    return updatedMessage;
  }

  return message;
};
