import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IScheduleForm } from './types';
import { DayOfWeek, HourRange } from '../types';

export const schemaBusinessHours: SchemaOf<IScheduleForm> = Yup.object({
  name: Yup.string().min(3, 'business-hours.error.name').required(),
  departmentIds: Yup.array().min(1, 'business-hours.error.departmentIds').required(),
  timezone: Yup.string().required('business-hours.error.timezone'),
  days: Yup.array().min(1, 'business-hours.error.days').required(),
  workHours: Yup.array()
    .of(
      Yup.object({
        dayOfWeek: Yup.mixed().oneOf(Object.values(DayOfWeek)).required(),
        [HourRange.START]: Yup.string().required(),
        [HourRange.END]: Yup.string().required(),
      })
    )
    .required(),
}).required();
