import React from 'react';
import { ICheckbox } from './types';
import { CheckboxCustom, CheckboxIcon } from './style';

const Checkbox: React.FC<ICheckbox> = (props: ICheckbox) => {
  const { selected, handleChecked } = props;
  return (
    <CheckboxCustom
      disableRipple
      disableTouchRipple
      checked={selected}
      icon={<CheckboxIcon />}
      onChange={handleChecked}
    />
  );
};

export default Checkbox;
