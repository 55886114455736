import { MessageBubble } from '@engyalo/design-system';
import React from 'react';
import { MessageArea } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';

export enum MessageVariant {
  AGENT = 'agent',
  USER = 'user',
  FLOW = 'flow',
  INTERNAL = 'internal',
}

export const MESSAGE_VARIANT_MAP: Record<string, MessageVariant> = {
  agent: MessageVariant.AGENT,
  user: MessageVariant.USER,
  bot: MessageVariant.FLOW,
};

export enum MessageType {
  TEXT_ONLY = 'text-only',
}

const TextMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { authorRole, text } = props;
  const body = text?.body || '';

  return (
    <MessageArea>
      <MessageBubble variant={MESSAGE_VARIANT_MAP[authorRole]} type={MessageType.TEXT_ONLY} text={body} />
    </MessageArea>
  );
};

export default TextMessage;
