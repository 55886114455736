import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@engyalo/design-system';
import { Iframe, Box } from './style';
import { ReactComponent as Loading } from '../../assets/icons/loadingYalo.svg';
import colors from '../../theme/colors';
import useEnvironment from '../../hooks/useEnvironments';
import { STORAGE_KEYS } from '../../constants/defaultValues';

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setisOpen] = useState(false);
  const navigate = useNavigate();
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  useEffect(() => {
    setTimeout(() => {
      const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)?.toString();
      const iframeElement: HTMLElement | any = document.getElementById('iframeRC');

      iframeElement.contentWindow.postMessage(
        {
          externalCommand: 'login-with-token',
          token,
        },
        '*'
      );

      setisOpen(true);
    }, 5000);
  }, []);

  const logout = () => {
    setisOpen(false);
    navigate('/', { replace: true });

    const iframeElement: HTMLElement | any = document.getElementById('iframeRC');

    iframeElement.contentWindow.postMessage(
      {
        externalCommand: 'logout',
      },
      '*'
    );
  };

  return (
    <>
      {isOpen === false && (
        <Box>
          <Loading />
        </Box>
      )}
      <Stack direction="column">
        {isOpen && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography
                fontSize="16px"
                lineHeight="22px"
                fontWeight="450"
                color={colors.colorNeutral90}
                marginLeft="16px"
              >
                {t('settings')}
              </Typography>
              <IconButton onClick={logout} sx={{ margin: '5px 5px 0px 0px' }} color="neutral2" variant="text">
                <i className="fa-regular fa-xmark fa-xs" />
              </IconButton>
            </Stack>
            <Divider sx={{ marginTop: '5px' }} />
          </>
        )}
        <Iframe
          width={1}
          height={1}
          isClosed={isOpen}
          id="iframeRC"
          src={`${baseUrl}/admin/info?layout=embedded
        `}
        />
      </Stack>
    </>
  );
};
