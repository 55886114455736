import { useTranslation } from 'react-i18next';
import { MediaFallback } from './styled';

const MediaMessageFallback: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MediaFallback>
      <i data-testid="file-icon" className="fa-regular fa-file-slash" />
      <span>{t('messages.mediaFallback')}</span>
    </MediaFallback>
  );
};

export default MediaMessageFallback;
