import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ITypeChat {
  type: string;
}

export const Container = styled('div')<ITypeChat>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px ${({ type }) => (type === 'chat' ? '6vw' : '16px')};
  margin-top: 24px;
  margin-bottom: 32px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 8px 12px;
    isolation: isolate;
  }
`;

export const IconView = styled('div')`
  padding: 0 12px;
`;

interface IInline {
  width: number;
  type: string;
}

export const Inline = styled(Stack)<IInline>`
  width: ${({ width, type }) => (width > 0 && type === 'chat' ? `${width}px` : type === 'chat' ? '11vw' : '100%')};
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => ` 1px solid ${theme.palette.neutral.var30}`};
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  flex-direction: row;
  isolation: isolate;
`;

export const Content = styled(Stack)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
