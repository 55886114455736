import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IFormCustomFields } from '../types';

export const schemaCustomFields: SchemaOf<IFormCustomFields> = Yup.object()
  .shape({
    field: Yup.string()
      .required('fieldIsRequired')
      .matches(/^[a-zA-Z0-9]+$/, 'invalidValue'),
    label: Yup.string().required('fieldIsRequired'),
    type: Yup.string().required('fieldIsRequired'),
    options: Yup.array().when(['type'], {
      is: (type: string) => type === 'select',
      then: Yup.array().required('mandatoryFilling'),
    }),
    required: Yup.boolean().required(),
  })
  .required();
