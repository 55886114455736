import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { Agent } from './types';

interface IGetAgentsByDepartments {
  agents: Agent[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}
export const getAgentsByDepartments = async (departmentId: string): Promise<AxiosResponse<IGetAgentsByDepartments>> =>
  AxiosInstance.get(`livechat/department/${departmentId}/agents`);
