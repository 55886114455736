import { Stack, styled } from '@mui/material';
import { IconButton } from '@engyalo/design-system';

export const MainStack = styled(Stack)`
  background: ${({ theme }) => theme.palette.common.white};
  border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
`;

export const EmojiButton = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: '0px 16px';
  }
`;
