import { styled } from '@mui/material/styles';

interface FormProps {
  haserror: boolean;
}

export const Form = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  border-radius: 8px;
  gap: 16px;
`;

export const DeleteIconContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 40px;
  height: 36px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.tertiary.light};
  color: ${({ theme }) => theme.palette.tertiary.main};
  border-radius: 8px;
`;

export const Buttons = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;
