import { Typography } from '@mui/material';
import { STATUS_UPLOAD } from 'types/chat';
import { useAppSelector } from 'hooks/redux';
import { progressColors, statusIcons, percentageColors } from './constants';
import { UploadItem, UploadItemContent, UploadList, UploadProgress, UploadStatusWrapper } from './style';

const UploadStatus = () => {
  const fileQueue = useAppSelector((state) => state.chat.fileQueue);
  const open = Boolean(fileQueue.length);
  const classNames = ['upload-status', open ? 'open' : ''].join(' ');

  return (
    <UploadStatusWrapper className={classNames}>
      <UploadList>
        {fileQueue.map(({ name, status = STATUS_UPLOAD.LOADING, progress = 0 }) => (
          <UploadItem key={name}>
            <UploadItemContent>
              {statusIcons[status]}
              <Typography variant="body1" flex={1} noWrap>
                {name}
              </Typography>
              <Typography variant="caption1" fontSize={10} color={percentageColors[status]}>
                {progress}%
              </Typography>
            </UploadItemContent>
            <UploadProgress variant="determinate" value={progress} color={progressColors[status]} />
          </UploadItem>
        ))}
      </UploadList>
    </UploadStatusWrapper>
  );
};

export default UploadStatus;
