import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

type AgentDepartments = {
  _id: string;
  agentId: string;
  departmentId: string;
  _updatedAt: string;
  count: number;
  departmentEnabled: boolean;
  order: number;
  username: string;
};
interface GetDepartmentsOfAgent {
  departments: AgentDepartments[];
  success: boolean;
}
export const getDepartmentsOfAgent = async (agentId: string): Promise<AxiosResponse<GetDepartmentsOfAgent>> =>
  AxiosInstance.get(`livechat/agents/${agentId}/departments`);
