import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { Tags } from './types';
import hasDisabledSuffix from '../helpers/hasDisabledSuffix';

interface IGetListTags {
  tags: Tags[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}

type GetListTagsArgs = {
  count?: number;
  offset?: number;
  text?: string;
  sort?: string;
  sortValue?: number;
  includeDisabled?: boolean;
};

export const getListTags = async (args: GetListTagsArgs = {}): Promise<AxiosResponse<IGetListTags>> => {
  const { count, offset, text, sort = 'name', sortValue = 1, includeDisabled } = args;
  const params = {
    count,
    offset,
    text,
    fields: { name: 1 },
    sort: { [sort]: sortValue, usernames: sortValue },
    viewAll: true,
  };

  const response = await AxiosInstance.get('livechat/tags', { params });
  if (!includeDisabled) {
    response.data.tags = response.data.tags.filter((tag: Tags) => !hasDisabledSuffix(tag.description));
    const amountFiltered = response.data.count - response.data.tags.length;
    response.data.count -= amountFiltered;
    response.data.total -= amountFiltered;
  }

  return response;
};
