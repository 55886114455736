import { styled } from '@mui/material/styles';
import { ListItemText } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  max-height: 387px;
`;

export const ListItemTxt = styled(ListItemText)`
  span {
    color: ${({ theme }) => theme.palette.neutral.var80};
    font-size: 14px;
    margin: 0px 8px;
  }
`;
