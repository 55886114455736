import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex: 1;
`;

export const LoadingContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
