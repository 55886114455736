import { styled } from '@mui/material/styles';

interface IButtonStack {
  justifyContent: string;
  gap?: string;
  marginTop?: string;
}

export const ButtonsStack = styled('div')<IButtonStack>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ marginTop }) => marginTop || '48px'};
  width: -webkit-fill-available;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-around'};
  gap: ${({ gap }) => gap || ''};
`;
