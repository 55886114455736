import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { CustomFieldsType } from '../../types/customFields';
import { getCustomFields } from '../../services/getCustomFields';
import { FilterProps, IFilterChats, IFilterOptions } from '.';
import { Department, User } from '../../services/types';

interface IUseAdvancedFilter {
  handleValue: (value: FilterProps[]) => void;
  agents: User[];
  departments: Department[];
  removeFilterByChatStatus: boolean;
  currentFilters: IFilterChats;
}

interface ISelectFilter {
  _id: string;
  name: string;
}

export interface IAttendBy {
  _id: string;
  username: string;
  emails: [
    {
      address: string;
      verified: boolean;
    }
  ];
  status: string;
  name: string;
  livechat: {
    maxNumberSimultaneousChat: string;
  };
  statusLivechat: string;
}

export interface IFilterTeams {
  _id: string;
  enabled: boolean;
  name: string;
  description: string;
  showOnRegistration: boolean;
  showOnOfflineForm: boolean;
  requestTagBeforeClosingChat: boolean;
  email: string;
  chatClosingTags: string[];
  offlineMessageChannelName: string;
  abandonedRoomsCloseCustomMessage: string;
  waitingQueueMessage: string;
  departmentsAllowedToForward: string;
  _updatedAt: string;
  numAgents: number;
  ancestors: string[];
  fallbackForwardDepartment: string;
  visitorInactivityTimeoutInSeconds: string;
}

const SITUATION_FILTER_TYPE = 'situation';

const checkChatStatus = (key: string, value: string) => {
  if (key === 'onhold') {
    return key;
  }

  if (value) {
    return 'open';
  }

  return 'closed';
};

const useAdvancedFilter = ({
  handleValue,
  currentFilters,
  agents,
  departments,
  removeFilterByChatStatus,
}: IUseAdvancedFilter) => {
  const { t } = useTranslation();

  const defaultfilterOptions = [
    { _id: 'teams', name: t('teams') },
    { _id: 'attendedBy', name: t('attendedBy') },
    { _id: 'tags', name: t('tags') },
    { _id: 'situation', name: t('situation') },
  ];

  const filterOptionsFinal = removeFilterByChatStatus
    ? defaultfilterOptions.filter((el) => el._id !== SITUATION_FILTER_TYPE)
    : defaultfilterOptions;

  const [filterOptions, setFilterOptions] = useState(filterOptionsFinal);
  const [filters, setFilters] = useState<FilterProps[]>([]);
  const [loading, setLoading] = useState(true);
  const addFilter = () => {
    if (filters.length >= filterOptions.length) return;
    const availableFilters = [] as IFilterOptions[];
    filterOptions.forEach((item) => {
      const hasFilter = filters.find((filter) => filter.selectFilter === item._id);
      if (!hasFilter) {
        availableFilters.push(item);
      }
    });
    const filterItem = {
      _id: uuidv4(),
      options: availableFilters,
    } as FilterProps;

    setFilters((old) => [...old, filterItem]);
  };

  const removeItem = (idItem: string) => {
    const items = filters.filter((item) => item._id !== idItem);
    setFilters(items);
    if (!items.length) {
      handleValue([]);
    }
  };

  const handleReloadFilter = () => {
    const selectFilters = filters.map((item) => item.selectFilter);
    setFilters((oldState) =>
      oldState.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.options = filterOptions.filter((filterOption) => {
          if (item.selectFilter === filterOption._id) {
            return item;
          }
          if (item.selectFilter !== filterOption._id && !selectFilters.includes(filterOption._id)) {
            return item;
          }
          return filterOption;
        });
        return item;
      })
    );
  };

  const selectFilter = (index: number, value: ISelectFilter) => {
    const values = [...filters];
    values[index].name = value.name;
    values[index].selectFilter = value._id;
    values[index].value = '';
    setFilters(values);
    handleReloadFilter();
  };

  const selectValueFilter = (index: number, value: string | IAttendBy | IFilterTeams) => {
    if (value) {
      const values = [...filters];
      const newValue = typeof value === 'string' ? value : value._id;
      values[index].value = newValue;
      setFilters(values);
      handleValue(values);
    }
  };

  const handleCurrentFilters = () => {
    const currentItems = [] as FilterProps[];
    Object.entries(currentFilters).forEach(([key, value]) => {
      const filterItem = {
        _id: uuidv4(),
        name: '',
        options: [],
      } as FilterProps;
      const hasFilter = true;
      if (key === 'tags') {
        filterItem.name = t('tags');
        filterItem.selectFilter = 'tags';
        filterItem.value = value.join(';');
      }
      if (key === 'agentId') {
        filterItem.name = t('attendedBy');
        filterItem.selectFilter = 'attendedBy';
        filterItem.value = value;
      }
      if (key === 'departmentId') {
        filterItem.name = t('teams');
        filterItem.selectFilter = 'teams';
        filterItem.value = value;
      }
      if (key === 'open' || key === 'onhold') {
        filterItem.name = t('situation');
        filterItem.selectFilter = 'situation';
        filterItem.value = checkChatStatus(key, value);
      } else if (key === 'customFields') {
        Object.entries(value).forEach(([customKey, customValue]) => {
          const item = {
            _id: uuidv4(),
            name: customKey,
            selectFilter: customKey,
            value: customValue,
          };
          const hasItem = !!filterOptions.find((el) => el._id === customKey);
          if (hasItem) {
            currentItems.push(item as FilterProps);
          }
        });
      }

      if (hasFilter && key !== 'customFields') {
        currentItems.push(filterItem);
      }
    });
    if (currentItems.length) {
      setFilters((oldState) => [...oldState, ...currentItems]);
      handleReloadFilter();
    }
  };

  const getListCustomFields = async () => {
    try {
      const {
        data: { customFields = [] },
      } = await getCustomFields();

      const fields = customFields
        .filter(
          (item: CustomFieldsType) => item._id !== 'ticket' && item.scope === 'room' && item.visibility === 'visible'
        )
        .map((item: CustomFieldsType) => ({
          _id: item._id,
          name: item.label,
        }));

      setFilterOptions((oldState) => [...oldState, ...fields]);
    } catch (error) {
      console.log(error);
    }
  };
  const clearFilter = () => {
    setFilters([]);
    handleValue([]);
  };

  const loadValues = async () => {
    await Promise.all([getListCustomFields()]);
    setLoading(false);
  };

  useEffect(() => {
    loadValues();
  }, []);

  useEffect(() => {
    if (!loading) handleCurrentFilters();
  }, [loading]);

  return {
    filters,
    removeItem,
    addFilter,
    selectFilter,
    selectValueFilter,
    clearFilter,
    agents,
    departments,
  };
};

export default useAdvancedFilter;
