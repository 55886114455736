import { styled } from '@mui/material/styles';

export const OptionItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  width: 100%;
  padding: 8px 16px 8px 0px;
  cursor: pointer;

  .MuiButtonBase-root {
    margin-right: 0.4rem;
  }
`;
