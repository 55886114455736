import React, { useContext } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@engyalo/design-system';
import { Container, Header, Main, PaginationContainer, TableContainer } from './style';
import ChatsContext from '../../../contexts/chats/context';
import ConversationContext from '../../../contexts/conversation/context';
import { HeaderConversation } from './HeaderConversation';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import useConversation from './useConversation';
import CustomTable from '../../../components/Table';
import CustomPagination from '../../../components/Pagination';
import { SearchView } from './SearchView';
import AuthContext from '../../../contexts/auth/context';
import SnackbarCustom from '../../../components/Snackbar';
import { PreviewMessage } from './PreviewMessage';
import GenericModal from '../../../components/Modal';
import ButtonsRow from '../../../components/ButtonsStack';
import Drawer from '../../../components/Drawer';
import FormNewContact from '../ContactManager/FormNewContact';
import ModalError from '../../../components/ErrorModal';

const Conversation = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(ChatsContext);
  const { currentUserInfo } = useContext(AuthContext);
  const { handleStartNewConversation, contactSelected, templateSelected, handleSelectTemplate, handleSelectContact } =
    useContext(ConversationContext);

  const {
    departments,
    totalItems,
    loading,
    currentPage,
    headerColumns,
    tableRows,
    handleClickItem,
    handleCurrentPage,
    checkTitleAndSubtitle,
    showPreviewMessage,
    previewText,
    replaceVariable,
    handleSubmit,
    onSubmit,
    control,
    isDirty,
    showSnackBarMsgSentSuccess,
    loadingRequest,
    setShowSnackBarMsgSentSuccess,
    hsmSelectedData,
    showModalSendAgain,
    setShowModalSendAgain,
    showModalError,
    setShowModalError,
    showHistoryData,
    setShowHistoryData,
    showDrawerCreateContact,
    setShowDrawerCreateContact,
    showErrorModal,
    setShowErrorModal,
    inputText,
    setInputText,
    orderContacts,
    orderContactsBy,
    loadContacts,
    orderVerifiedHSMs,
    orderByVerifiedHSMs,
    loadHSMs,
    showErrorModalSendMessage,
    setShowErrorSendMessage,
    setHsmSelectedData,
  } = useConversation();

  const { innerHeight } = window;

  const handleRenderTableConversation = () => {
    if (totalItems >= 1) {
      return (
        <>
          <TableContainer>
            <CustomTable
              handleClickItem={(item: any) => {
                handleClickItem(item);
              }}
              page={currentPage}
              headItems={headerColumns}
              rows={tableRows}
              handleCallApi={(sortType: string, sortValue: number) => {
                if (templateSelected?.template) {
                  loadContacts(undefined, sortType, sortValue);
                } else {
                  loadHSMs(undefined, sortType, sortValue);
                }
              }}
              order={templateSelected?.template ? orderContacts : orderVerifiedHSMs}
              orderBy={templateSelected?.template ? orderContactsBy : orderByVerifiedHSMs}
            />
          </TableContainer>
          <PaginationContainer isMobile={isMobile}>
            <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
          </PaginationContainer>
          ;
        </>
      );
    }

    if (inputText && !totalItems) {
      return (
        <Typography data-testid="infoNotFoundMessage" variant="subtitle1" color="neutral.var70" textAlign="center">
          {templateSelected?.template ? t('noContactsFound') : t('templatesNotFound')}
        </Typography>
      );
    }

    if (!totalItems && !loading) {
      return (
        <Typography data-testid="emptyMessage" variant="subtitle1" color="neutral.var70" textAlign="center">
          {templateSelected?.template ? t('emptyContacts') : t('emptyTemplates')}
        </Typography>
      );
    }

    return (
      <Box alignSelf="center">
        <Loading />
      </Box>
    );
  };

  return (
    <>
      <Container sx={{ height: isMobile ? innerHeight : '100%' }} data-testid="conversationContainer">
        <Header isMobile={isMobile}>
          <IconButton
            data-testid="previousPageConversation"
            onClick={() => {
              if (contactSelected?._id && templateSelected?._id && !showHistoryData) {
                handleSelectContact(undefined);
                return;
              }
              if (templateSelected?._id && !showHistoryData) {
                setHsmSelectedData(undefined);
                handleSelectTemplate(undefined);
                return;
              }

              if (showHistoryData) {
                handleSelectContact(undefined);
                handleSelectTemplate(undefined);
                setHsmSelectedData(undefined);
                setShowHistoryData(false);
                return;
              }
              handleStartNewConversation(false);
            }}
            sx={{ height: '4.8rem !important', alignItems: 'center' }}
            variant="text"
            color="neutral2"
          >
            <i className="fa-solid fa-arrow-left fa-2xs" />
          </IconButton>

          <HeaderConversation
            contactSelected={contactSelected}
            templateSelected={templateSelected}
            showHistoryData={showHistoryData}
            isMobile={isMobile}
          />
        </Header>
        {showPreviewMessage ? (
          <PreviewMessage
            checkTitleAndSubtitle={checkTitleAndSubtitle}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            t={t}
            control={control}
            departments={departments}
            templateSelected={hsmSelectedData}
            replaceVariable={replaceVariable}
            isDirty={isDirty}
            loadingRequest={loadingRequest}
            currentUserInfo={currentUserInfo}
            previewText={previewText}
            isMobile={isMobile}
          />
        ) : (
          <Main isMobile={isMobile}>
            {!isMobile && checkTitleAndSubtitle()}

            <SearchView
              templateSelected={hsmSelectedData}
              departments={departments}
              setShowDrawerCreateContact={setShowDrawerCreateContact}
              inputText={inputText}
              setInputText={setInputText}
              loadHSMs={loadHSMs}
            />

            {handleRenderTableConversation()}
          </Main>
        )}
        <SnackbarCustom
          open={showSnackBarMsgSentSuccess}
          message={t('messageSentSuccess')}
          handleClose={() => {
            setShowSnackBarMsgSentSuccess(false);
          }}
          type="success"
        />

        <GenericModal
          open={showModalSendAgain || showModalError}
          title={showModalSendAgain ? `${t('sendMessageAgain')}?` : `${t('errorSendMessage')}`}
        >
          <Typography variant="body1" color="common.black" width="24rem" textAlign="center" marginTop="32px">
            {showModalSendAgain ? `${t('sendMessageAgainText')}` : `${t('errorSendMessageText')}`}
          </Typography>
          <ButtonsRow justifyContent={showModalSendAgain ? 'space-between' : 'center'}>
            {showModalSendAgain && (
              <Button onClick={() => setShowModalSendAgain(false)} disableElevation variant="text">
                {t('cancel')}
              </Button>
            )}

            <Button
              variant="contained"
              onClick={() => {
                if (showModalError) {
                  setShowModalError(false);
                }
              }}
              disableElevation
            >
              {showModalSendAgain ? t('sendAnyway') : `${t('ok')}`}
            </Button>
          </ButtonsRow>
        </GenericModal>

        <ModalError
          open={showErrorModal}
          title={t('connectionErrorModalTitle')}
          message={t('errorGetVetifiedTemplates')}
          handleClose={() => {
            setShowErrorModal(false);
          }}
        />

        <ModalError
          open={showErrorModalSendMessage}
          title={t('unableToSendMessage')}
          message={t('errorSendMessageText')}
          handleClose={() => {
            setShowErrorSendMessage(false);
          }}
        />
      </Container>

      {showDrawerCreateContact && (
        <Drawer open={showDrawerCreateContact} isMobile={isMobile}>
          <FormNewContact
            handleOpenNewUser={() => {
              setShowDrawerCreateContact(false);
            }}
            sendNotification
          />
        </Drawer>
      )}
    </>
  );
};

export default Conversation;
