import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from '../AxiosInstanceYalo';

export interface ITransferChat {
  roomId: string;
  username: string;
  departmentId: string;
  tags: string[];
  agentId?: string;
  comment?: string;
}
export const transferChat = async (params: ITransferChat): Promise<AxiosResponse> => {
  const { roomId, username, departmentId, agentId, comment, tags } = params;

  return AxiosInstanceYalo.post('v1/chat/transfer/open', {
    roomId,
    username,
    departmentId,
    agentId,
    comment,
    tags,
  });
};
