import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@engyalo/design-system';
import { FontSizeOptions } from 'types/user';
import PreferencesContext from 'contexts/preferences/context';
import { FontSizeWrapper } from './style';

export const fontSizes = [FontSizeOptions.SMALL, FontSizeOptions.MEDIUM, FontSizeOptions.LARGE];

const FontSizePicker = () => {
  const { t } = useTranslation();
  const { globalFontSize, updateGlobalFontSize, setDisable } = useContext(PreferencesContext);

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newFontSize: FontSizeOptions | null) => {
    if (newFontSize) {
      updateGlobalFontSize(newFontSize, true);
      setDisable(true);
    }
  };

  return (
    <FontSizeWrapper>
      <Typography variant="h4" color="neutral.var70">
        {t('fontSize.title')}
      </Typography>
      <Typography variant="caption1" color="neutral.var60">
        {t('fontSize.info')}
      </Typography>
      <ToggleButtonGroup value={globalFontSize} color="primary" onChange={handleChange} exclusive>
        {fontSizes.map((fontSize) => (
          <ToggleButton key={fontSize} value={fontSize} aria-label={t(`fontSize.${fontSize}Info`)}>
            <Icon className="fa-solid fa-text-size" fontSize={fontSize} sx={{ width: 'auto' }} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FontSizeWrapper>
  );
};

export default FontSizePicker;
