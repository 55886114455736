import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { Department } from './types';
import { IDepartment } from '../types/department';

export interface DptsByIds extends Department {
  offlineMessageChannelName: string;
  ancestors: [];
}

interface GetDepartmentsByIds {
  departments: IDepartment[];
  success: boolean;
}
export const getDepartmentsInfo = async (departmentIds: string[]): Promise<AxiosResponse<GetDepartmentsByIds>> =>
  AxiosInstance.get('livechat/department.listByIds', {
    params: {
      ids: departmentIds,
    },
  });
