import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { ITeamFormSchema } from '../types';

export const schemaTeam: SchemaOf<ITeamFormSchema> = Yup.object({
  name: Yup.string().required(''),
  email: Yup.string().email().required(''),
  visitorInactivityTimeoutInSeconds: Yup.string().optional(),
  abandonedRoomsCloseCustomMessage: Yup.string().optional(),
  maxNumberSimultaneousChat: Yup.string().optional(),
  agents: Yup.array().optional(),
  unit: Yup.string().required(),
  chatClosingTags: Yup.array().optional(),
}).required();
