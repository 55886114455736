import React, { useRef, useState, useEffect, useCallback } from 'react';
import Picker from 'emoji-picker-react';

import { useTranslation } from 'react-i18next';
import { Popper, Typography } from '@mui/material';
import {
  ContainerTextFormatting,
  CustomInput,
  IconOption,
  TextDynamicData,
  IconsOptions,
  ContextualDynamicValues,
  TitleDynamicValues,
  DivisorItem,
  TitleItems,
  DynamicValueItem,
} from './style';
import { TextOptionEnum } from './TextOptionEnum';
import { icons } from './icons';

interface IEmojiData {
  unified: string;
  originalUnified: string;
  names: string[];
  emoji: string;
}
export interface IInputEditorText {
  value: string;
  handleValue: (value: string) => void;
  placeholder: string;
  error: boolean;
  helperText?: string;
}

const dynamicValuesCustomer = [
  { label: 'name', field: '{{contact.name}}' },
  { label: 'email', field: '{{contact.email}}' },
  { label: 'phone', field: '{{contact.phone}}' },
];

const dynamicValuesAgent = [
  { label: 'name', field: '{{agent.name}}' },
  { label: 'email', field: '{{agent.email}}' },
];

const InputEditorText = (props: IInputEditorText) => {
  const { handleValue, value, placeholder, error, helperText } = props;
  const inputRef = useRef({} as HTMLInputElement);
  const { t } = useTranslation();
  const [anchorElEmoji, setAnchorElEmoji] = useState<HTMLDivElement | null>(null);
  const [anchorElDynamicData, setAnchorElDynamicData] = useState<HTMLSpanElement | null>(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [highlightedText, setHighlightedText] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectionStart, setSelectionStart] = useState<number>(0);

  const handleText = useCallback(
    (formatting: TextOptionEnum, valueSelected?: string) => {
      if (highlightedText && formatting !== TextOptionEnum.DYNAMIC_VALUE) {
        const finalText = value.replace(highlightedText, `${formatting}${highlightedText}${formatting} `);
        handleValue(finalText);
        setInputValue(finalText);
      } else {
        const finalText = [
          value.slice(0, selectionStart),
          valueSelected || formatting,
          value.slice(selectionStart),
        ].join(' ');
        handleValue(finalText);
        setInputValue(finalText);
      }
    },
    [highlightedText, value, selectionStart, handleValue]
  );

  const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
    handleText(TextOptionEnum.EMOJI, emojiObject.emoji);
    setShowEmojis(false);
  };

  const updateSelectionStart = () => setSelectionStart(inputRef.current.selectionStart || 0);

  useEffect(() => {
    const highlightText = () => {
      if (window.getSelection()?.toString().length) {
        const exactText = window.getSelection()?.toString();
        if (exactText) {
          setHighlightedText(exactText);
        }
      }
    };
    document.addEventListener('mouseup', highlightText);

    return () => {
      window.removeEventListener('mouseup', highlightText);
    };
  }, []);

  return (
    <CustomInput
      multiline
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      value={inputValue || value}
      onSelect={updateSelectionStart}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(ev.target.value);
        handleValue(ev.target.value);
      }}
      inputRef={inputRef}
      maxRows={4}
      InputProps={{
        endAdornment: (
          <ContainerTextFormatting>
            <IconsOptions>
              {icons.map(({ icon, textOption }) => (
                <IconOption
                  data-testid="fontStyleButtons"
                  onClick={(event) => {
                    if (textOption === TextOptionEnum.EMOJI) {
                      setAnchorElEmoji(event.currentTarget);
                      setShowEmojis(!showEmojis);
                      return;
                    }
                    handleText(textOption);
                  }}
                  key={textOption}
                >
                  {icon}
                </IconOption>
              ))}
            </IconsOptions>

            <TextDynamicData
              data-testid="dynamicValue"
              variant="caption1"
              onClick={(event) => {
                setAnchorElDynamicData(event.currentTarget);
              }}
            >
              {t('insertDynamicValue')}
            </TextDynamicData>
            {showEmojis && (
              <Popper
                data-testid="emoji-popper"
                id="emoji-popper"
                open={showEmojis}
                anchorEl={anchorElEmoji}
                sx={{ zIndex: 9999 }}
              >
                <Picker
                  onEmojiClick={onEmojiClick}
                  pickerStyle={{
                    bottom: '45px',
                    left: '-25px',
                  }}
                />
              </Popper>
            )}
            {anchorElDynamicData && (
              <Popper
                placement="bottom-start"
                open={anchorElDynamicData !== null}
                anchorEl={anchorElDynamicData}
                sx={{ zIndex: 9999 }}
              >
                <ContextualDynamicValues data-testid="dynamicValuesPopover">
                  <TitleItems>
                    <TitleDynamicValues variant="overline">{t('contactData')}</TitleDynamicValues>
                    <DivisorItem />
                  </TitleItems>
                  {dynamicValuesCustomer.map(({ field, label }) => (
                    <DynamicValueItem
                      data-testid="customerDinamycValues"
                      onClick={() => {
                        handleText(TextOptionEnum.DYNAMIC_VALUE, field);
                        setAnchorElDynamicData(null);
                      }}
                      key={field}
                    >
                      <Typography variant="caption1" color="neutral.var80">
                        {t(label)}
                      </Typography>
                    </DynamicValueItem>
                  ))}
                  <TitleItems>
                    <TitleDynamicValues variant="overline"> {t('agentData')}</TitleDynamicValues>
                    <DivisorItem />
                  </TitleItems>
                  {dynamicValuesAgent.map(({ label, field }) => (
                    <DynamicValueItem
                      data-testid="agentDinamycValues"
                      onClick={() => {
                        handleText(TextOptionEnum.DYNAMIC_VALUE, field);
                        setAnchorElDynamicData(null);
                      }}
                      key={label}
                    >
                      <Typography variant="caption1" color="neutral.var80">
                        {t(label)}
                      </Typography>
                    </DynamicValueItem>
                  ))}
                </ContextualDynamicValues>
              </Popper>
            )}
          </ContainerTextFormatting>
        ),
      }}
      inputProps={{ 'data-testid': 'inputEditorText' }}
    />
  );
};

export default InputEditorText;
