import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YaloThemeProvider } from '@engyalo/design-system';
import { datadogRum } from '@datadog/browser-rum';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import userflow from 'userflow.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Provider } from 'react-redux';
import { logWarn } from 'services/Logger';
import RoutesApp from './routes';
import './App.css';
import './i18n';
import './index.css';
import pkgjson from '../package.json';
import { loadSegment } from './helpers/segment';
import { setBaseUrlAxiosInstance } from './services/AxiosInstance';
import { setBaseUrlAxiosInstanceYalo } from './services/AxiosInstanceYalo';
import useEnvironment from './hooks/useEnvironments';
import store from './redux/store';
import ErrorModal from './components/ErrorModal';

loadSegment();

const userFlowToken = process.env.REACT_APP_USERFLOW_TOKEN;
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY || 'unknown');

enum ignoreErrors {
  USERFLOW_COULD_NOT_LOAD = 'Could not load Userflow.js',
  NETWORK_ERROR = 'Network Error',
  ERROR_401 = 'Request failed with status code 401',
}

if (userFlowToken) {
  try {
    userflow.init(userFlowToken);
  } catch (error) {
    logWarn(ignoreErrors.USERFLOW_COULD_NOT_LOAD, error);
  }
}

if (process.env.REACT_APP_DD_TOKEN) {
  datadogRum.init({
    applicationId: `${process.env.REACT_APP_DD_ID}`,
    clientToken: `${process.env.REACT_APP_DD_TOKEN}`,
    site: `${process.env.REACT_APP_DD_SITE}`,
    service: `${process.env.REACT_APP_DD_SERVICE}`,
    env: `${process.env.REACT_APP_DD_ENV}`,
    version: `${pkgjson.version}`,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    beforeSend: (event) => {
      if (event.type === 'error') {
        const isFalsePositiveStackError = Object.values(ignoreErrors).find((ignoreErrorString) =>
          event.error.stack?.includes(ignoreErrorString)
        );
        if (isFalsePositiveStackError) {
          return false;
        }
      }
      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
}

const noop = () => {};

const App = () => {
  const { getInstance, getUrl } = useEnvironment();
  const { t } = useTranslation();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const instance = getInstance();
    const url = getUrl('http');

    async function checkInstance() {
      try {
        await fetch(url, { method: 'HEAD' });
      } catch {
        setShowErrorModal(true);
      }
    }

    setBaseUrlAxiosInstance(url);
    setBaseUrlAxiosInstanceYalo(instance);
    checkInstance();
  }, []);

  return (
    <Provider store={store}>
      <YaloThemeProvider>
        {showErrorModal ? (
          <ErrorModal
            open={showErrorModal}
            title={t('invalidInstanceTitle')}
            message={t('invalidInstanceMessage')}
            handleClose={noop}
          />
        ) : (
          <RoutesApp />
        )}
      </YaloThemeProvider>
    </Provider>
  );
};

export default App;
