import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomAvatar } from './style';
import { IEditAvatarProps } from './types';

const EditAvatar: React.FC<IEditAvatarProps> = (props) => {
  const { height, width, handleImage, urlImage } = props;
  const { t } = useTranslation();

  return (
    <label htmlFor="icon-button-file-avatar">
      <input
        onChange={(event) => handleImage(event)}
        multiple={false}
        className="hidden"
        id="icon-button-file-avatar"
        type="file"
        accept="image/*"
      />
      <CustomAvatar width={width} height={height} contenthovertext={t('hoverAvatarText')} src={urlImage} />
    </label>
  );
};

export default EditAvatar;
