/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { devToolsEnhancer } from '@redux-devtools/extension';
import { splitReducer, initSplitSdk } from '@splitsoftware/splitio-redux';
import { STORAGE_KEYS } from 'constants/defaultValues';
import businessHoursReducer from 'redux/reducers/businessHoursReducer';
import chatReducer from '../reducers/chatReducer';
import configReducer from '../reducers/configReducer';

const sdkBrowserConfig = {
  core: {
    authorizationKey: `${process.env.REACT_APP_FF_TOKEN}`,
    key: localStorage.getItem(STORAGE_KEYS.PATH) ?? 'none',
  },
};

const rootReducer = combineReducers({
  splitio: splitReducer,
  config: configReducer,
  chat: chatReducer,
  businessHours: businessHoursReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

store.dispatch(initSplitSdk({ config: sdkBrowserConfig }));
export default store;

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    enhancers: [devToolsEnhancer({})],
  });
}
export { rootReducer };
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
