import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';

export const updateTemplate = async (
  templateId: string,
  IsActiveSalesdesk: boolean,
  SharedWith: string[]
): Promise<AxiosResponse> =>
  AxiosInstanceYalo.put(`v1/template/${templateId}`, {
    IsActiveSalesdesk,
    SharedWith,
  });
