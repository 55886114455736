import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PreviewBubbleMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  margin: 32px;
`;

export const TextMessage = styled(Typography)`
  overflow: hidden;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`;
