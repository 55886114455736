import { LanguageTags } from 'i18n';
import { IDepartment } from './department';

export enum UserStatus {
  Online = 'online',
  Away = 'away',
  Offline = 'offline',
  Busy = 'busy',
}

export enum UserStatusLivechat {
  Available = 'available',
  NotAvailable = 'not-available',
}

export enum FontSizeOptions {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  default = 'small',
}

export type UserType = {
  userId: string;
  authToken: string;
  me?: {
    language: `${LanguageTags}`;
    _id: string;
    status: UserStatus;
    active: boolean;
    roles: string[];
    name: string;
    username: string;
    avatarUrl: string;
  };
};

export interface UserInformation {
  _id: string;
  services: Services;
  username: string;
  emails: Email[];
  status: string;
  active: boolean;
  _updatedAt: string;
  roles: string[];
  name: string;
  nickname: string;
  settings: Settings;
  statusConnection: string;
  utcOffset: number;
  statusLivechat: string;
  statusText: string;
  statusDefault: string;
  email: string;
  avatarUrl: string;
  success: boolean;
  language: `${LanguageTags}`;
  livechat?: {
    maxNumberSimultaneousChat: string;
  };
  departments: Array<IDepartment>;
}

export interface UserInformationSegment extends UserInformation {
  organizationId?: string;
  customerName?: string;
}

export interface Services {
  password: Password;
}

export interface Password {
  exists: boolean;
}

export interface Email {
  address: string;
  verified: boolean;
}

export interface Settings {
  profile: Profile;
  preferences: Preferences;
}

export interface Profile {}

export interface Preferences {
  desktopNotifications: string;
  notificationsSoundVolume: number;
  autoImageLoad: boolean;
  sidebarShowUnread: boolean;
  unreadAlert: boolean;
  pushNotifications: string;
  messageViewMode: number;
  saveMobileBandwidth: boolean;
  sidebarShowFavorites: boolean;
  emailNotificationMode: string;
  sidebarDisplayAvatar: boolean;
  collapseMediaByDefault: boolean;
  sidebarSortby: string;
  sendOnEnter: string;
  muteFocusedConversations: boolean;
  hideFlexTab: boolean;
  sidebarViewMode: string;
  desktopNotificationRequireInteraction: boolean;
  useEmojis: boolean;
  hideUsernames: boolean;
  hideRoles: boolean;
  convertAsciiEmoji: boolean;
  displayAvatars: boolean;
  enableAutoAway: boolean;
  idleTimeLimit: number;
  sidebarGroupByType: boolean;
  newRoomNotification: string;
  newMessageNotification: string;
  alsoSendThreadToChannel: string;
  useLegacyMessageTemplate: boolean;
  language: `${LanguageTags}`;
  fontSize: FontSizeOptions;
}

export enum RoutingMethods {
  ManualSelection = 'Manual_Selection',
  AutoSelection = 'Auto_Selection',
  External = 'External',
  Load_Balancing = 'Load_Balancing',
  Load_Rotation = 'Load_Rotation',
}
