import { useState, useContext, useCallback } from 'react';
import { logError } from 'services/Logger';
import { STATUS_UPLOAD } from 'types/chat';
import { uploadMedia } from 'services/chat';
import { useChatStore } from 'redux/reducers/chatReducer';
import { MAIN_TYPE, STATUS } from './constants';
import ChatsContext from '../../../contexts/chats/context';
import { uploadFiles } from '../../../services/uploadFiles';
import { SendMessage } from '../Chat/hooks/useChat';

const formDataName = 'file';
const removeTime = 3000; // 3 seconds

const useAttachFile = (sendMessage?: SendMessage) => {
  const [status, setStatus] = useState<number>(STATUS.SUCCESS);
  const { addFileQueue, updateFileQueue, removeFileQueue } = useChatStore();
  const { serviceSelected, discussionId, discussionOpened } = useContext(ChatsContext);
  const { _id: serviceId } = serviceSelected;

  const handleSendFileToRocketChat = useCallback(
    async (file: File) => {
      const { name } = file;
      const id = discussionOpened ? discussionId : serviceId;
      const formData = new FormData();
      formData.append(formDataName, file, name);

      let response;
      try {
        response = await uploadFiles(id, formData, {
          onUploadProgress: ({ loaded, total = 1 }) => {
            const progress = Math.round((loaded * 100) / total);
            updateFileQueue({ name, progress, status: STATUS_UPLOAD.LOADING });
          },
        });
        updateFileQueue({ name, status: STATUS_UPLOAD.SUCCESS });
      } catch (e) {
        response = { status: STATUS.FAILED };
        updateFileQueue({ name, status: STATUS_UPLOAD.FAILED });
        logError('Error while uploading a file to Rocket Chat', e);
      }

      setStatus(response.status);
    },
    [discussionId, discussionOpened, serviceId, updateFileQueue]
  );

  const sendSocketMessage = useCallback(
    ({ url, mimeType, caption }: { url: string; mimeType: string; caption: string }) => {
      const [mainType] = mimeType.split('/');

      const kind = mainType === MAIN_TYPE.IMAGE || mainType === MAIN_TYPE.VIDEO ? mainType : MAIN_TYPE.DOCUMENT;

      sendMessage?.({
        kind,
        payload: {
          url,
          caption,
          mimeType,
        },
      });
    },
    [sendMessage]
  );

  const uploadFileToSalesdesk = useCallback(
    async (file: File) => {
      const { name } = file;

      try {
        const {
          status: currentStatus,
          data: { url },
        } = await uploadMedia(file, {
          onUploadProgress: ({ loaded, total = 1 }) => {
            const progress = Math.round((loaded * 100) / total);
            updateFileQueue({ name, progress, status: STATUS_UPLOAD.LOADING });
          },
        });

        setStatus(currentStatus);
        updateFileQueue({ name, status: STATUS_UPLOAD.SUCCESS });

        return url;
      } catch (error) {
        setStatus(STATUS.FAILED);
        updateFileQueue({ name, status: STATUS_UPLOAD.FAILED });
        logError('Error while uploading a file to salesdesk', error);
        return undefined;
      }
    },
    [updateFileQueue]
  );

  const handleSendFileToSalesDesk = useCallback(
    async (file: File) => {
      const url = await uploadFileToSalesdesk(file);
      const { type: mimeType, name: caption } = file;

      if (url) {
        sendSocketMessage({ url, mimeType, caption });
      }
    },
    [sendSocketMessage, uploadFileToSalesdesk]
  );

  const handleSendFile = useCallback(
    async (file: File) => {
      const { name } = file;
      addFileQueue({ name, progress: 0, status: STATUS_UPLOAD.LOADING });

      if (discussionOpened) {
        await handleSendFileToRocketChat(file);
      } else {
        await handleSendFileToSalesDesk(file);
      }

      setTimeout(() => {
        removeFileQueue(name);
      }, removeTime);
    },
    [discussionOpened, handleSendFileToRocketChat, handleSendFileToSalesDesk, addFileQueue, removeFileQueue]
  );

  const attachFile = useCallback(
    (files: FileList | null) => {
      if (files && files.length) {
        Array.from(files).forEach((file) => {
          handleSendFile(file);
        });
      }
    },
    [handleSendFile]
  );

  return {
    status,
    attachFile,
  };
};

export default useAttachFile;
