import { styled } from '@mui/material/styles';

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  height: 46px;
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const BackButton = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  cursor: pointer;
`;

export const IconDelete = styled('div')`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.tertiary.main};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 8px;
`;
