import SplitNames from 'constants/featureFlags';
import { useAppSelector } from 'hooks/redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import useEnvironment from 'hooks/useEnvironments';

export const useFeatureFlag = (splitName: SplitNames) => {
  const { getInstance } = useEnvironment();
  const instance = getInstance();

  const splitState = useAppSelector((store) => store.splitio);
  const userState = useAppSelector((store) => store.config.user);

  return selectTreatmentValue(splitState, splitName, userState?.email, instance) === 'on';
};

export const flag = SplitNames;
