import { ptBR, enUS, es } from 'date-fns/locale';
import { LanguageTags } from 'i18n';

export const checkLanguage = (language: string) => {
  if (language === LanguageTags.PT_BR) {
    return ptBR;
  }
  if (language === LanguageTags.EN) {
    return enUS;
  }

  return es;
};
