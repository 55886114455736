import { createContext } from 'react';
import { UserInformation } from '../../types/user';
import { IDepartment } from '../../types/department';

export interface IDepartmentContext {
  departments: IDepartment[];
  currentUserInfo: UserInformation;
}

const DepartmentContext = createContext<IDepartmentContext>({} as IDepartmentContext);

export default DepartmentContext;
