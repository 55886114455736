import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerModal = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BodyText = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 48px;
  justify-content: center;
`;

export const FooterButtons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0px 0px;
  gap: 16px;
`;
