import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const resetUserPassword = async (token: string, password: string): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'resetPassword',
      params: [token, password],
    }),
  };
  return AxiosInstance.post('method.callAnon/resetPassword', params);
};
