import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IOptionsListProps } from '../../types/optionsList';

import ChatsContext from '../../contexts/chats/context';
import WebsocketContext from '../../contexts/websocket/context';
import AuthContext from '../../contexts/auth/context';
import { STORAGE_KEYS } from '../../constants/defaultValues';

const useChatItem = ({ sessionType, id, unreadMessage, room }: any) => {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [currentOptions, setCurrentOptions] = useState<IOptionsListProps[]>();
  const today = new Date();
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const [language, setLanguage] = useState('');

  const { handleUnreadMessage, isSelectedItems, handleFinishChat, handleServiceSelected } = useContext(ChatsContext);
  const { handleResumeOnHoldChat } = useContext(WebsocketContext);
  const { currentUserInfo } = useContext(AuthContext);
  const menuOptions = [
    {
      icon: <i className="fa-regular fa-box-check" />,
      title: t('finalize'),
      function: () => handleFinishChat(room),
      sessionTypes: ['inAttendance'],
    },
    {
      icon: <i className="fa-regular fa-alarm-exclamation" />,
      title: t('returnAttendance'),
      function: () => {
        handleServiceSelected({});
        handleResumeOnHoldChat(id);
      },
      sessionTypes: ['onHold'],
    },
  ];

  const handleOptions = () => {
    const unreadOption = unreadMessage
      ? {
          icon: <i className="fa-regular fa-eye-slash" />,
          title: t('markAsRead'),
          function: () => handleUnreadMessage(id, false),
          sessionTypes: ['inAttendance'],
        }
      : {
          icon: <i className="fa-regular fa-eye" />,
          title: t('markAsUnRead'),
          function: () => handleUnreadMessage(id, true),
          sessionTypes: ['inAttendance'],
        };
    menuOptions.unshift(unreadOption);

    if (sessionType) {
      const options = menuOptions.filter((item) => item.sessionTypes.includes(sessionType));
      if (options.length > 0) {
        setCurrentOptions(options);
      }
    }
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChecked = () => {
    setIsSelected((old) => !old);
  };

  const checkData = (dateParam: any) => {
    const finalDate = new Date(dateParam);
    if (finalDate.toLocaleDateString() === today.toLocaleDateString()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handleOptions();
  }, [sessionType, unreadMessage]);

  useEffect(() => {
    if (languageLocalStorage) {
      setLanguage(languageLocalStorage);
    } else {
      setLanguage(window.navigator.language);
    }
  }, [languageLocalStorage]);

  return {
    currentOptions,
    handleOptionClick,
    handleClose,
    handleChecked,
    isSelected,
    anchorEl,
    isSelectedItems,
    checkData,
    currentUserInfo,
    language,
  };
};

export default useChatItem;
