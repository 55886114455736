import React from 'react';
import { t } from 'i18next';
import { ListItemSelectFilter } from './style';
import GenericAutocomplete from '../Autocomplete';
import { User } from '../../services/types';
import { IAttendBy } from './useAdvancedFilter';

export function handleRenderAttendBy(agents: User[], selectValueFilter: (index: number, value: IAttendBy) => void) {
  return (index: number, value?: string) => {
    const options = [{ _id: 'allOptions', name: t('all') }, ...agents];
    return (
      <GenericAutocomplete
        hideSearchIcon
        disableCloseOnSelect
        prefixPlaceholder={t('filter')}
        placeholder={`${t('select')}...`}
        defaultValue={value}
        renderOption={(props, option) => <ListItemSelectFilter {...props}>{option.name}</ListItemSelectFilter>}
        handleChange={(selectedValue) => {
          selectValueFilter(index, selectedValue);
        }}
        limitTagsNumber={1}
        multiple={false}
        options={options}
      />
    );
  };
}
