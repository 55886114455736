import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Bubble, ChatBubbleArrow, MediaImage } from './styles';
import { IPreviewTemplateContentProps } from './types';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeftChat.svg';

const PreviewTemplateContent = ({ Message, isMobile = false, previewText }: IPreviewTemplateContentProps) => {
  const { Header, Body, Footer } = Message;
  const theme = useTheme();
  return (
    <Bubble isMobile={isMobile}>
      <ChatBubbleArrow>
        <ArrowLeft fill={theme.palette.primary.contrastText} width={8} height={10} />
      </ChatBubbleArrow>
      <Stack direction="column" gap="8px" alignItems="center">
        {Header?.Media?.Link ? <MediaImage src={Header?.Media?.Link} /> : null}
        <Typography variant="body1" color="neutral.contrastText" alignSelf="flex-start">
          {previewText || Body?.Text}
        </Typography>
        {Footer?.Text ? (
          <Typography variant="caption1" color="neutral.var60" alignSelf="flex-start">
            {Footer?.Text}
          </Typography>
        ) : null}
      </Stack>
    </Bubble>
  );
};

export default PreviewTemplateContent;
