import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItem, Typography } from '@mui/material';
import { Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import InputSearch from '../../../components/InputSearch';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import { FooterPagination, TableContainer } from '../style';
import { Container, SearchContainer } from './style';

import useVerifiedTemplatesManager from './useVerifiedTemplatesManager';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import GenericAutocomplete from '../../../components/Autocomplete';
import Drawer from '../../../components/Drawer';
import HeaderDrawerMenu from '../HeaderDrawerMenu';
import FormVerifiedTemplates from './FormVerifiedTemplatesManager';
import { ModalEdit } from '../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import ModalError from '../../../components/ErrorModal';

export type ItemTable = {
  _id: string;
  template: string;
  sharedWith: ReactNode;
  preview: ReactNode;
  dateAdded: ReactNode;
  enable: ReactNode;
};

const VerifiedTemplatesManager: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    loading,
    handleClickItem,
    showDrawer,
    setShowDrawer,
    hsmSelectedData,
    teams,
    unsavedChanges,
    handleChangeForm,
    showModalUnsavedChanges,
    setShowModalUnsavedChanges,
    errorUpdt,
    setErrorUpdt,
    showErrorModal,
    setShowErrorModal,
    inputText,
    setInputText,
    loadHSMs,
    orderVerifiedHSMs,
    orderByVerifiedHSMs,
  } = useVerifiedTemplatesManager();

  const handleRenderTableVerifiedTemplatesManager = () => {
    if (totalItems >= 1) {
      return (
        <TableContainer>
          <CustomTable
            handleClickItem={(item: ItemTable) => {
              handleClickItem(item);
            }}
            page={currentPage}
            headItems={headerColumns}
            rows={tableRows}
            handleCallApi={(sortType: string, sortValue: number) => {
              loadHSMs(undefined, sortType, sortValue);
            }}
            order={orderVerifiedHSMs}
            orderBy={orderByVerifiedHSMs}
          />
        </TableContainer>
      );
    }

    if (inputText && !totalItems) {
      return (
        <Typography data-testid="noTemplatesFoundManager" variant="caption1" color="neutral.var70" textAlign="center">
          {t('templatesNotFound')}
        </Typography>
      );
    }
    if (!totalItems && !loading) {
      return (
        <Typography
          data-testid="emptyTemplatesServiceManager"
          variant="caption1"
          color="neutral.var70"
          textAlign="center"
        >
          {t('emptyTemplates')}
        </Typography>
      );
    }

    return (
      <Box data-testid="loadingTemplatesServiceManager" alignSelf="center">
        <Loading />
      </Box>
    );
  };

  return (
    <>
      <Container data-testid="verifiedTemplatesContainer">
        <Typography variant="h2" color="common.black" data-testid="verifiedTemplatesTitle">
          {t('verified-templates')}
        </Typography>
        <Typography variant="body1" color="common.black" data-testid="verifiedTemplatesSubTitle">
          {t('verifiedHSMsSubtitle')}{' '}
          <Tooltip title={t('tooltipHSMs')}>
            <i className="fa-solid fa-circle-question" style={{ color: theme.palette.neutral.var50, marginLeft: 3 }} />
          </Tooltip>
        </Typography>
        <SearchContainer>
          <div style={{ width: '75%' }}>
            <InputSearch
              testId="inputSearchTemplatesManager"
              padding="8px 16px 8px 0px"
              placeholder={t('search')}
              value={inputText}
              onChange={(value) => {
                loadHSMs(value);
                setInputText(value);
              }}
            />
          </div>
          <div style={{ width: '25%' }}>
            <GenericAutocomplete
              hideSearchIcon
              prefixPlaceholder={t('scope')}
              placeholder={t('allTeams')}
              renderOption={(props, option) => (
                <ListItem
                  sx={{
                    height: '36px',
                    gap: '6px',
                    padding: '8px 12px',
                  }}
                  {...props}
                >
                  <Typography variant="caption1">{option.name}</Typography>
                </ListItem>
              )}
              handleChange={(value) => {
                if (value) {
                  loadHSMs(undefined, undefined, undefined, value._id);
                  return;
                }
                loadHSMs();
              }}
              limitTagsNumber={1}
              multiple={false}
              options={teams}
            />
          </div>
        </SearchContainer>

        {handleRenderTableVerifiedTemplatesManager()}
      </Container>
      <FooterPagination>
        <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
      </FooterPagination>

      {showDrawer && (
        <Drawer open={showDrawer}>
          <HeaderDrawerMenu
            handleClickBackButton={() => {
              if (unsavedChanges) {
                setShowModalUnsavedChanges(true);
                return;
              }
              setShowDrawer(!showDrawer);
            }}
            title={`${t('HSMDrawerTitle')} "${hsmSelectedData?.Name}"`}
          />
          <FormVerifiedTemplates
            onClose={() => {
              setShowDrawer(!showDrawer);
            }}
            formIsChanged={handleChangeForm}
            HSMSelected={hsmSelectedData}
            handleErrorUpdt={setErrorUpdt}
            loadHSMs={loadHSMs}
            teams={teams}
          />
        </Drawer>
      )}

      {showModalUnsavedChanges && (
        <ModalEdit
          open={showModalUnsavedChanges}
          errorUpdt={errorUpdt}
          handleModal={() => {
            setShowModalUnsavedChanges(false);
          }}
          handleEditing={() => {
            setShowDrawer(false);
            setShowModalUnsavedChanges(false);
          }}
        />
      )}

      {errorUpdt && (
        <ModalEdit
          open={errorUpdt}
          errorUpdt={errorUpdt}
          handleModal={() => {
            setErrorUpdt(false);
          }}
          handleEditing={() => {}}
        />
      )}

      <ModalError
        open={showErrorModal}
        title={t('connectionErrorModalTitle')}
        message={t('errorGetVetifiedTemplates')}
        handleClose={() => {
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

export default VerifiedTemplatesManager;
