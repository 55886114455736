import React, { useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { Span } from './style';
import GenericModal from '../Modal';
import PasswordRules from '../PasswordRules';
import { schemaChangePassword } from './yupSchema';
import { updateUserInfo } from '../../services/updateUsersInfo';
import AuthContext from '../../contexts/auth/context';
import { login } from '../../services/login';
import ModalError from '../ErrorModal';
import ButtonsRow from '../ButtonsStack';
import { CheckPassword } from './CheckPassword';
import { UserInfo, RocketChatUser } from '../../services/types';
import { onHandleFormType } from '../../views/ServiceManagement/UsersManager/types';

export interface IModalPassword {
  open: boolean;
  handleShowModalEditPassword: () => void;
  userSelected: UserInfo;
  onCloseForm: (prop: onHandleFormType, userId?: string) => void;
  handleShowModalError: () => void;
}

export interface IFormChangePassword {
  password: string;
}

export const ModalPassword: React.FC<IModalPassword> = (props) => {
  const theme = useTheme();
  const { open, handleShowModalEditPassword, userSelected, onCloseForm, handleShowModalError } = props;
  const { t } = useTranslation();
  const { currentUserInfo } = useContext(AuthContext);
  const {
    control,
    formState: { errors, isValid },
    getFieldState,
    watch,
    getValues,
  } = useForm<IFormChangePassword>({
    mode: 'onChange',
    resolver: yupResolver(schemaChangePassword),
  });

  const [showModalPasswordAdmin, setShowModalPasswordAdmin] = useState(false);
  const [showModalErrorAdminPassword, setShowModalErrorPasswordAdmin] = useState(false);
  const [admPassword, setAdmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (password: string) => {
    try {
      const { _id: userId, name, emails, username } = userSelected;
      const user: RocketChatUser = {
        userId,
        name,
        email: emails ? emails[0].address : '',
        username,
        password,
        active: true,
        requirePasswordChange: true,
      };
      const { data } = await updateUserInfo(user);
      if (data.success && data.user) {
        onCloseForm('close');
        handleShowModalEditPassword();
        setLoading(false);
        return;
      }
      handleShowModalError();
    } catch (error) {
      handleShowModalError();
    }
  };

  const validatePassword = async () => {
    setLoading(true);
    try {
      const { data } = await login(currentUserInfo.username, admPassword);

      const { status } = data;
      if (status) {
        setShowModalPasswordAdmin(false);
        const newPassword = getValues('password');
        onSubmit(newPassword);
      }
    } catch (e) {
      setLoading(false);
      setShowModalErrorPasswordAdmin(true);
    }
  };

  return (
    <>
      <GenericModal
        open={open}
        title={`${t('redefinePassword')}`}
        hasCloseIcon
        handleModal={handleShowModalEditPassword}
      >
        <Stack width="100%" data-testid="modalPassword">
          <Span variant="caption1" color="neutral.var100">
            {t('temporaryPassword')}{' '}
            <Tooltip title={t('tooltipPassword') || ''} placement="top" data-testid="temporaryPasswordTooltip">
              <i
                className="fa-duotone fa-circle-question"
                style={{ color: theme.palette.neutral.var40, marginLeft: 3 }}
              />
            </Tooltip>
          </Span>

          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                placeholder={`${t('temporaryPasswordPlaceholed')}`}
                error={!!errors.password}
                helperText={t(errors.password?.message ?? '')}
                InputProps={{}}
                type="password"
                inputProps={{ 'data-testid': 'inputPassword' }}
              />
            )}
          />
          <div
            style={{
              height: '15px',
              display: errors.password ? 'block' : 'none',
            }}
          />
          <PasswordRules watch={watch} getFieldState={getFieldState} />

          <ButtonsRow justifyContent="flex-end">
            <Button
              data-testid="cancelButton"
              sx={{
                width: '14.8rem',
              }}
              variant="outlined"
              color="neutral2"
              onClick={handleShowModalEditPassword}
            >
              {t('cancel')}
            </Button>
            <Button
              data-testid="savePasswordButton"
              sx={{
                marginLeft: '8px',
                width: '14.8rem',
              }}
              disabled={!isValid}
              onClick={() => {
                setShowModalPasswordAdmin(true);
              }}
            >
              {t('save')}
            </Button>
          </ButtonsRow>
        </Stack>
      </GenericModal>

      {showModalPasswordAdmin && (
        <CheckPassword
          validatePassword={validatePassword}
          showModalPasswordAdmin={showModalPasswordAdmin}
          handleShowModalEditPassword={handleShowModalEditPassword}
          admPassword={admPassword}
          errors={errors}
          loading={loading}
          isValid={isValid}
          setAdmPassword={setAdmPassword}
        />
      )}

      {showModalErrorAdminPassword && (
        <ModalError
          handleClose={() => {
            setShowModalErrorPasswordAdmin(false);
            setAdmPassword('');
          }}
          message={t('errorValidatePassword')}
          title={t('invalidPassword')}
          open={showModalErrorAdminPassword}
        />
      )}
    </>
  );
};
