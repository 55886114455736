import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WithFeatureFlag from 'hocs/WithFeatureFlag';
import { Roles } from 'constants/roles';
import { Container, Body, SideBar, ViewOption, SidebarList } from './style';
import { SideItems } from './sidebarItems';
import { MenuItemsType, ISidebarItems, MENU_ITEMS } from './types.d';
import useSegment from '../../services/Analytics';
import RenderListItem from './RenderListItem';
import { createKeyToken } from '../../helpers/createKeyToken';
import usePermissions from '../../hooks/usePermissions';
import SelectedMenuItem from './SelectedMenuItem';
import Title from './Title/Title';

const ServiceManagement: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sidebarItems, setSidebarItems] = useState<Array<ISidebarItems>>([]);
  const [sidebarOpenCollapse, setSidebarOpenCollapse] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<MenuItemsType>(MENU_ITEMS.USERS);
  const handleClickCollapse = (itemKey: string) => {
    setSidebarOpenCollapse(itemKey === sidebarOpenCollapse ? '' : itemKey);
  };

  const handleMenuOption = (item: MenuItemsType) => {
    setCurrentItem(item);
  };
  const { sendPage } = useSegment();
  const { checkPermission } = usePermissions();

  useEffect(() => {
    const isManager = checkPermission(Roles.MANAGER);

    const idxVerifiedTemplates = SideItems.map((element) => element.key).indexOf('verified-templates');

    if (idxVerifiedTemplates > -1 && !isManager) {
      SideItems.splice(idxVerifiedTemplates, 1);
    }

    setSidebarItems(
      SideItems.map((item) => ({
        ...item,
        name: t(item.key),
        subItems: item.subItems.map((subItem) => ({
          ...subItem,
          name: t(subItem.key),
        })),
      }))
    );
    sendPage();
  }, []);

  return (
    <Container>
      <Title title="managementChats" onClose={() => navigate('/')} />
      <Body>
        <SideBar>
          <SidebarList disablePadding>
            {sidebarItems.map((item, index) => {
              const itemKey = item.key;
              const featureFlag = item.featureFlagName;
              return (
                <React.Fragment key={createKeyToken()}>
                  {featureFlag ? (
                    <WithFeatureFlag
                      featureFlag={`${featureFlag}`}
                      WrappedComponent={
                        <RenderListItem
                          index={index}
                          item={item}
                          sidebarOpenCollapse={sidebarOpenCollapse}
                          currentItem={currentItem}
                          menuItemType={itemKey}
                          handleClickCollapse={() => {
                            handleClickCollapse(itemKey);
                          }}
                          handleMenuOption={handleMenuOption}
                        />
                      }
                    />
                  ) : (
                    <RenderListItem
                      key={itemKey}
                      index={index}
                      item={item}
                      sidebarOpenCollapse={sidebarOpenCollapse}
                      currentItem={currentItem}
                      menuItemType={itemKey}
                      handleClickCollapse={() => {
                        handleClickCollapse(itemKey);
                      }}
                      handleMenuOption={handleMenuOption}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </SidebarList>
        </SideBar>
        <ViewOption>
          <SelectedMenuItem currentItem={currentItem} />
        </ViewOption>
      </Body>
    </Container>
  );
};

export default ServiceManagement;
