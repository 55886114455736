import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { sendEmptyMessageFromAgent } from 'services/sendEmptyMessageFromAgent';
import ChatsContext from '../../../contexts/chats/context';
import TransferContext from '../../../contexts/transfer/context';
import WebsocketContext from '../../../contexts/websocket/context';
import { getRoomInfo } from '../../../services/getRoomInfo';
import useSegment from '../../../services/Analytics';
import { transferChat } from '../../../services/chat/transferChat';
import { logError } from '../../../services/Logger';
import MenuChatsContext from '../../../contexts/menuChats/context';

interface IUseTransferModal {
  handleClose: () => void;
}

type ErrorMessage = {
  title: string;
  message: string;
  confirmText: string;
  cancelButton: boolean;
};

type ErrorMessages = {
  default: ErrorMessage;
  unavailableAgent: ErrorMessage;
};

export const errorMessages: ErrorMessages = {
  default: {
    title: 'errorWhenTransfer',
    message: 'errorMessageWhenTransfer',
    confirmText: 'tryAgain',
    cancelButton: true,
  },
  unavailableAgent: {
    title: 'unavailableAgent',
    message: 'unavailableAgentMessage',
    confirmText: 'ok',
    cancelButton: false,
  },
};

export const ERROR_USER_IS_OFFLINE = 'error-user-is-offline';

const useTransferModal = ({ handleClose }: IUseTransferModal) => {
  const {
    loadTags,
    loadAgents,
    allDepartments,
    AllAgentsOfDepartment,
    selectedDepartment,
    selectedAgent,
    clearSelectedsTransfer,
    isReturnToQueue,
    handleReturnToQueue,
  } = useContext(TransferContext);
  const {
    resultTransferRequest,
    handleResultResponse,
    returnToQueue,
    setSnackBarMessage,
    handleSnackBar,
    unsubscribeRoomMessages,
  } = useContext(WebsocketContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceSelected, setServiceSelected } = useContext(ChatsContext);
  const { handleSearchChats } = useContext(MenuChatsContext);
  const [anotation, setAnotation] = useState('');
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [agentId, setAgentId] = useState('');
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [currentRoomTags, setCurrentRoomTags] = useState('');
  const [messageError, setMessageError] = useState({
    title: '',
    message: '',
    confirmText: '',
    cancelButton: true,
  });
  const returnToQueueOptions = [{ _id: uuidv4(), name: t('queueOfTeam') }];
  const { sendEvent, EVENT_NAMES } = useSegment();

  const closeModal = () => {
    handleClose();
    clearSelectedsTransfer();
    if (isReturnToQueue) {
      handleReturnToQueue();
    }
  };

  const handleErrorTransfer = (errorMessage?: string) => {
    const error = errorMessage === ERROR_USER_IS_OFFLINE ? errorMessages.unavailableAgent : errorMessages.default;

    setMessageError(error);
    setOpenErrorModal(true);
  };

  const handleTransferSuccess = () => {
    closeModal();
    setOpenErrorModal(false);
    unsubscribeRoomMessages('chat');
    setSnackBarMessage(t('successTransferMessage'));
    handleSnackBar();
    setServiceSelected({});
    navigate('/', { replace: true });
    handleResultResponse();
    handleSearchChats('yourChats');
  };

  const handleResultTransfer = () => {
    if (resultTransferRequest?.error || resultTransferRequest?.result === false) {
      handleErrorTransfer(resultTransferRequest.error?.error);
    } else if (resultTransferRequest?.result === true) {
      handleTransferSuccess();
    }
  };

  const loadCurrentTags = async () => {
    const {
      data: { room = {} },
    } = await getRoomInfo(serviceSelected._id);
    if (room.tags?.length) {
      setCurrentRoomTags(room.tags.join(';') || '');
    }
  };

  const handleTransferChat = async () => {
    if (isReturnToQueue && serviceSelected.livechatData.userKey) {
      try {
        const response = await returnToQueue(serviceSelected._id, serviceSelected.livechatData.userKey);
        if (response?.data?.success) {
          handleTransferSuccess();
        } else {
          handleErrorTransfer();
        }
      } catch (e) {
        logError('error transfer chat', e);
        handleErrorTransfer();
      }
    } else {
      const dptId = selectedDepartmentId || selectedDepartment || serviceSelected.departmentId;
      const propsTransferChat = {
        roomId: serviceSelected._id,
        username: serviceSelected.livechatData.userKey,
        departmentId: dptId,
        agentId: agentId || selectedAgent,
        comment: anotation,
        tags: selectedTag,
      };
      try {
        await sendEmptyMessageFromAgent(serviceSelected._id);

        transferChat(propsTransferChat)
          .then(() => {
            if (agentId || selectedAgent) {
              sendEvent(EVENT_NAMES.USER_CLICKED_ON_TRANSFER_TO_AGENT);
            } else if (dptId) {
              sendEvent(EVENT_NAMES.USER_CLICKED_ON_TRANSFER_TO_TEAM);
            }
            handleTransferSuccess();
          })
          .catch((e): void => {
            if (e.data) {
              handleErrorTransfer(e.data);
              logError('error transfer chat', undefined, e.data);
            } else {
              handleErrorTransfer();
            }
          });
      } catch (e) {
        logError('error transfer chat', e);
        handleErrorTransfer();
      }
    }
  };

  const handleCloseErrorModal = (confirm: boolean) => {
    if (confirm && messageError.cancelButton) {
      handleTransferChat();
    } else {
      setOpenErrorModal(false);
    }
  };

  const handleSelectTags = (selectedtags: string) => {
    if (selectedtags) {
      setSelectedTag(selectedtags.split(';'));
    } else {
      setSelectedTag([]);
    }
  };

  const handleDisableConfirmButton = () => {
    if (isReturnToQueue) return false;

    if (selectedDepartment) {
      return !selectedDepartmentId || !selectedTag.length;
    }

    return !agentId || !selectedTag.length;
  };

  useEffect(() => {
    if (selectedAgent) {
      setAgentId(selectedAgent);
    }
  }, [selectedAgent]);

  useEffect(() => {
    loadTags();
    loadCurrentTags();
  }, []);

  useEffect(() => {
    loadAgents(selectedDepartmentId || selectedDepartment);
  }, [selectedDepartmentId]);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectedDepartmentId(selectedDepartment);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    handleResultTransfer();
  }, [resultTransferRequest]);

  return {
    allDepartments,
    AllAgentsOfDepartment,
    setAnotation,
    setSelectedTag,
    setAgentId,
    setSelectedDepartmentId,
    selectedDepartment,
    handleTransferChat,
    handleSelectTags,
    openErrorModal,
    handleCloseErrorModal,
    selectedAgent,
    agentId,
    selectedTag,
    closeModal,
    isReturnToQueue,
    returnToQueueOptions,
    messageError,
    handleDisableConfirmButton,
    currentRoomTags,
  };
};

export default useTransferModal;
