import { styled } from '@mui/material/styles';
import { Typography, Stack, StackProps, ListItem as ListItemMui } from '@mui/material';

interface QuickMessagesContainerProps extends StackProps {
  isTabsMode?: boolean;
}

export const QuickMessagesContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isTabsMode',
})<QuickMessagesContainerProps>`
  background: ${({ theme }) => theme.palette.common.white};
  border-top: ${({ theme, isTabsMode = false }) => (isTabsMode ? 'none' : `solid 1px ${theme.palette.neutral.var20}`)};
  width: auto;
`;

export const ListItem = styled(ListItemMui)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  :focus,
  :hover {
    background: ${({ theme }) => theme.palette.neutral.var10};
    border-radius: 4px;
    outline: none;
  }
`;

export const ShortcutMessage = styled(Typography)`
  margin-top: 12px;
  color: ${({ theme }) => theme.palette.neutral.var70};
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
`;

export const Tags = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 10px 8px 0px 0px;
`;
