import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { UserStatus } from 'types/user';
import useEnvironment from '../../../../hooks/useEnvironments';
import { getUserInfo } from '../../../../services/getUserInfo';
import { IViewUserInfo, IViewUserInfoProps } from '../types';
import { Container, ContainerName, ContainerContactInfo, SectionItem, SectionInfo, SectionButton } from './style';
import { getUserRoles } from '../../../../helpers/getUserRoles';
import { getDepartmentsOfAgent } from '../../../../services/getDepartmentsOfAgent';
import { getDepartmentsInfo } from '../../../../services/getDepartmentsInfo';
import { ReactComponent as OfflineSvg } from '../../../../assets/icons/iconDisable.svg';
import { ReactComponent as Loading } from '../../../../assets/icons/loadingYalo.svg';

const ViewUserInfo: React.FC<IViewUserInfoProps> = (props: IViewUserInfoProps) => {
  const theme = useTheme();
  const { id, handleShowEditingDrawer } = props;
  const { getUrl } = useEnvironment();
  const { t } = useTranslation();
  const baseUrl = getUrl('http');
  const [userInfo, setUserInfo] = useState<IViewUserInfo>({} as IViewUserInfo);
  const keysToShow = ['email', 'user', 'role', 'teams', 'createdAt'];
  const [loading, setLoading] = useState(false);
  const getDepartments = async () => {
    try {
      const {
        data: { departments },
      } = await getDepartmentsOfAgent(id);
      const ids = departments.map((item: any) => item.departmentId);
      if (ids.length === 0) return '';
      const { data } = await getDepartmentsInfo(ids);
      const names = data.departments.map((item: any) => item.name).join(',');
      return names;
    } catch (error) {
      console.log(error);
      return '';
    }
  };

  const loadUserInfo = async () => {
    try {
      const {
        data: { user },
      } = await getUserInfo(id);
      const roles = getUserRoles(user.roles);

      const departments = await getDepartments();

      setUserInfo({
        _id: user._id,
        email: user.emails.length > 0 ? user.emails[0].address : '',
        user: user.username,
        role: roles.map((item) => t(`${item}`)).join(','),
        teams: departments,
        createdAt: new Date(user.createdAt).toLocaleDateString(),
        name: user.name,
        status: user.status,
        eTag: user.avatarETag,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadUserInfo();
    }
  }, [id]);

  return (
    <Container>
      {loading ? (
        <Box alignSelf="center">
          <Loading />
        </Box>
      ) : (
        <>
          <SectionInfo>
            <ContainerName>
              {userInfo.user && (
                <Badge
                  overlap="circular"
                  badgeContent={
                    userInfo.status === UserStatus.Online ? (
                      <i className="fa-solid fa-circle" style={{ color: theme.palette.success.main }} />
                    ) : (
                      <OfflineSvg width={16} height={16} />
                    )
                  }
                  sx={{
                    '.MuiBadge-badge': {
                      top: '26px',
                      right: '0px',
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: '36px',
                      height: '36px',
                    }}
                    src={`${baseUrl}/avatar/${userInfo.user}?etag=${userInfo.eTag}`}
                  />
                </Badge>
              )}
              <Typography variant="body2" color="common.black">
                {userInfo.name}
              </Typography>
            </ContainerName>
            <ContainerContactInfo>
              <Typography variant="h4" color="neutral.var90">
                {t('contactData')}
              </Typography>
              {Object.entries(userInfo)
                .filter(([key]) => keysToShow.includes(key))
                .map(([key, value], index) => (
                  <SectionItem key={index}>
                    <Typography variant="body2" color="neutral.var90">
                      {t(key)}:
                    </Typography>
                    <Typography variant="body1" color="" sx={{ wordBreak: 'break-all' }}>
                      {value}
                    </Typography>
                  </SectionItem>
                ))}
            </ContainerContactInfo>
          </SectionInfo>
          <SectionButton>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                handleShowEditingDrawer();
              }}
              sx={{ textTransform: 'initial' }}
            >
              <i className="fa-regular fa-pencil" style={{ color: theme.palette.primary.main }} />
              {t('editUser')}
            </Button>
          </SectionButton>
        </>
      )}
    </Container>
  );
};

export default ViewUserInfo;
