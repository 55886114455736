import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@engyalo/design-system';

export const Container = styled('div')`
  background: white;
  display: flex;
  min-height: 100vh;
  justify-content: center;
`;

export const BoxForm = styled('form')`
  width: 300px;
  border-radius: 6px;
  align-self: center;
  z-index: 1;
`;

export const HeaderForm = styled(Stack)`
  height: 121px;
  width: 300px;
  border-radius: 6px 6px 0px 0px;
  background: ${({ theme }) => theme.palette.neutral.var10};
  justify-content: center;
  align-items: center;
`;

export const BoxInputs = styled(Stack)`
  width: 300px;
  background: ${({ theme }) => theme.palette.common.white};
  padding: 0px 20px 20px 20px;
`;

export const ButtonSubmit = styled(Button)(({ theme }) => ({
  fontFamily: 'Avenir Next !important',
  fontStyle: 'normal',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '1px',
  height: '68px',
  background: `${theme.palette.common.black} !important`,
  color: `${theme.palette.common.white} !important`,
  borderTopLeftRadius: '0px !important',
  borderTopRightRadius: '0px !important',
  textTransform: 'uppercase',
}));

export const TextInput = styled(TextField)`
  background: ${({ theme }) => theme.palette.common.white} !important;
  border-radius: 3px;
  .MuiFormControl-root-MuiTextField-root {
    margin: 0px;
    padding: 0px;
  }
  .MuiOutlinedInput-root {
    height: 42px !important;
    padding: 0px;
    border: ${({ theme }) => `1px solid ${theme.palette.neutral.var10}`};
  }
  input:-webkit-autofill {
    padding: 0px;
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.palette.common.white} inset;
  }
`;

interface IPasswordParametersText {
  haserror: boolean;
}

export const PasswordParametersText = styled(Typography)<IPasswordParametersText>`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ haserror, theme }) => (haserror ? theme.palette.error.main : theme.palette.neutral.var60)};
  padding: 5px 20px 10px 20px;
`;
