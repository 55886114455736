import React from 'react';
import { t } from 'i18next';
import { ListItemSelectFilter } from './style';
import GenericAutocomplete from '../Autocomplete';
import { Department } from '../../services/types';
import { IFilterTeams } from './useAdvancedFilter';

export function handleRenderTeams(
  departments: Department[],
  selectValueFilter: (index: number, value: IFilterTeams) => void
) {
  return (index: number, value?: string) => {
    const options = [{ _id: 'allOptions', name: t('all') }, ...departments];
    return (
      <GenericAutocomplete
        hideSearchIcon
        disableCloseOnSelect
        prefixPlaceholder={t('filter')}
        placeholder={`${t('select')}...`}
        defaultValue={value}
        renderOption={(props, option) => (
          <ListItemSelectFilter key={option} {...props}>
            {option.name}
          </ListItemSelectFilter>
        )}
        handleChange={(selectedValue) => {
          selectValueFilter(index, selectedValue);
        }}
        limitTagsNumber={1}
        multiple={false}
        options={options}
      />
    );
  };
}
