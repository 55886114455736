import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Span = styled(Typography)`
  margin-top: 36px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
