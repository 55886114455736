import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const MessageModal = styled(Typography)`
  max-width: 289px;
  text-align: center;
  align-self: center;
  margin-top: 32px;
  white-space: pre-line;
`;

export const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-top: 32px;
`;

export const ContainerModal = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
