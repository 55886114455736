import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const StackHeader = styled(Stack)`
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
  padding: 26px 12px;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
  gap: 0.8rem;
`;

export const InfoArea = styled('div')`
  display: flex;
  gap: 0.8rem;
`;

export const InfoTagContainer = styled('div')<{ color?: string; clickable?: boolean }>`
  border-radius: 16px;
  border: ${({ theme, color }) => `1px solid ${color || theme.palette.neutral.var30}`};
  color: ${({ theme, color }) => color || theme.palette.neutral.var30};
  display: flex;
  padding: 0.6rem 1.2rem;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  gap: 4px;
  line-height: 1.2rem;
  font-weight: 500;
  text-align: center;
  align-items: center;
`;

export const TagsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 110px;
  }
`;
