import {
  OutgoingMessageInteractiveMessageKind,
  OutgoingMessageInteractiveMessage,
} from '@engyalo/salesdesk-api-ts-client';
import ButtonContent from './ButtonContent';
import ListContent from './ListContent';
import { ChatMessageComponentProps } from '../ChatMessage';
import UserReplyContent from './UserReplyContent/UserReplyContent';

export type InteractiveMessageComponentProps = Omit<OutgoingMessageInteractiveMessage, 'kind'>;

const MESSAGE_COMPONENTS: Record<OutgoingMessageInteractiveMessageKind, React.FC<InteractiveMessageComponentProps>> = {
  button: ButtonContent,
  list: ListContent,
  userReply: UserReplyContent,
};

const InteractiveMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { interactive } = props;

  if (!interactive) {
    return null;
  }

  const { kind, ...rest } = interactive;

  const MessageComponent = MESSAGE_COMPONENTS[kind];

  return <MessageComponent {...rest} />;
};

export default InteractiveMessage;
