import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

interface DeleteUnit {
  message: string;
  success: boolean;
}
export const deleteUnit = async (unitId: string, uuid: string): Promise<AxiosResponse<DeleteUnit>> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuid,
      method: 'livechat:removeUnit',
      params: [unitId],
    }),
  };

  return AxiosInstance.post('method.call/livechat:removeUnit', params);
};
