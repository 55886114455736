import { BaseSyntheticEvent, useState } from 'react';
import { Video } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';
import MediaMessageFallback from '../MediaMessageFallback';
import { logError } from '../../../../services/Logger';

const VideoMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { video } = props;
  const [hasError, setHasError] = useState(false);

  if (!video) {
    return null;
  }

  const { url, caption, mimeType: type } = video;

  const handleError = (event: BaseSyntheticEvent) => {
    const { code, message } = event.target.error;
    logError('Error loading video', null, { event, targetURL: url, error: { code, message } });

    if (event.target.error.code !== MediaError.MEDIA_ERR_DECODE) {
      setHasError(true);
    }
  };

  return hasError ? (
    <MediaMessageFallback />
  ) : (
    <Video preload="metadata" controls aria-label={caption} onError={handleError}>
      <source src={url} type={type} />
    </Video>
  );
};

export default VideoMessage;
