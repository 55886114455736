import { styled } from '@mui/material';

export const ContainerTeamsManager = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;
