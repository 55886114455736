import { Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Header, BackButton, IconDelete } from './style';
import { IHeaderDrawerMenu } from './types';

const HeaderDrawerMenu: React.FC<IHeaderDrawerMenu> = (props: IHeaderDrawerMenu) => {
  const theme = useTheme();
  const { title, handleClickBackButton, handleClickDeleteButton, showDeleteButton } = props;

  return (
    <Header>
      <BackButton data-testid="closeDrawer" onClick={handleClickBackButton}>
        <i
          className="fa-regular fa-arrow-left"
          style={{
            color: theme.palette.neutral.var70,
          }}
        />
      </BackButton>
      <Typography variant="subtitle2" color="common.black">
        {title}
      </Typography>
      {showDeleteButton && (
        <IconDelete
          onClick={() => {
            handleClickDeleteButton && handleClickDeleteButton();
          }}
        >
          <i
            className="fa-solid fa-trash-can fa-sm"
            style={{
              color: theme.palette.tertiary.main,
            }}
          />
        </IconDelete>
      )}
    </Header>
  );
};

export default HeaderDrawerMenu;
