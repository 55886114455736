import { styled } from '@mui/material';

export const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-height: calc(100% - 46px);
`;

export const FormFields = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 25px;
  overflow-y: scroll;
  height: 100%;
`;
export const Footer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  height: 52px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 16px;
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  height: 46px;
`;
export const Field = styled('div')`
  width: 100%;
`;

export const OptionsListItemUnit = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
