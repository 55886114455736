import React from 'react';
import { IFilterButtonProps } from './types';
import { RoundedButton } from './style';

export const FilterButton = ({ onClick, children, selected, ...props }: IFilterButtonProps) => (
  <RoundedButton
    size="small"
    variant="outlined"
    onClick={onClick}
    fullWidth={false}
    aria-selected={selected}
    {...props}
  >
    {children}
  </RoundedButton>
);
