import React from 'react';
import { Stack, Link } from '@mui/material';
import { FooterProps } from './types';

export const Footer = ({ translation }: FooterProps) => (
  <Stack direction="row" justifyContent="space-between" marginTop="56px">
    <Link
      sx={{
        color: 'neutral.var60',
        cursor: 'pointer',
        textDecoration: 'none',
        fontFamily: 'Suisse Int',
      }}
      target="_blank"
      href="https://www.yalo.com/terms-and-conditions"
    >
      {translation('termsAndConditions')}
    </Link>
    <Link
      target="_blank"
      href="https://www.yalo.com/privacy"
      sx={{
        color: 'neutral.var60',
        cursor: 'pointer',
        textDecoration: 'none',
        fontFamily: 'Suisse Int',
      }}
    >
      {translation('privacyPolicy')}
    </Link>
  </Stack>
);
