import { shouldForwardPropHelper, styled } from '@engyalo/design-system';
import { Stack } from '@mui/material';
import shadows from 'theme/shadows';
import { IMobile } from 'types/mobile';

export const Bubble = styled(Stack, {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  white-space: ${({ isMobile }) => (isMobile ? '' : 'pre-line')};
  max-height: ${({ isMobile }) => (isMobile ? '24vh' : '83vh')};
  max-width: 100%;
  display: block;
  min-width: 3vw;
  border-radius: 0 8px 8px 8px;
  border-top-right-radius: 0px;
  background: ${({ theme }) => theme.palette.primary.contrastText};
  color: ${({ theme }) => theme.palette.neutral.contrastText};
  box-shadow: ${shadows.shadowBlack4};
  padding: 12px;
  text-align: left;
  position: relative;
`;
