import { useEffect, useState, useContext } from 'react';

import { uploadMedia } from 'services/chat';
import { format } from 'date-fns';
import { logError } from 'services/Logger';
import { SendMessage } from '../../hooks/useChat';
import ChatsContext from '../../../../../contexts/chats/context';
import { AudioRecorder } from './audioRecorder';

const useAudio = (sendMessage?: SendMessage) => {
  const [blob, setBlob] = useState<Blob>();
  const [chatId, setChatId] = useState('');
  const [sendAudio, setSendAudio] = useState(false);
  const [audioRecorder] = useState(new AudioRecorder());

  const { discussionOpened, discussionId } = useContext(ChatsContext);

  const handleWorkerMessage = (event: any) => {
    if (audioRecorder?.encoder) {
      switch (event.data.command) {
        case 'end': {
          const blob = new Blob(event.data.buffer, {
            type: 'audio/mpeg',
          });
          setBlob(blob);
          audioRecorder?.encoder.worker.terminate();
          break;
        }
      }
    }
  };

  const sendSocketMessage = (url?: string) => {
    sendMessage?.({
      kind: 'audio',
      payload: {
        url,
      },
    });
  };

  const uploadAudioToSalesdesk = async (file: File) => {
    try {
      const {
        data: { url },
      } = await uploadMedia(file);

      return url;
    } catch (error) {
      logError('Error while uploading an audio file to salesdesk', error);
      return undefined;
    }
  };

  const handleSendAudioToSalesdesk = async (currentBlob: Blob) => {
    const now = new Date();
    const formattedDate = format(now, 'yyyyMMdd');
    const fileName = `audio_${formattedDate}_${chatId}.mp3`;
    const file = new File([currentBlob], fileName, { type: 'audio/mpeg' });

    const url = await uploadAudioToSalesdesk(file);

    if (url) {
      sendSocketMessage(url);
    }

    audioRecorder?.cancel();
    setBlob(undefined);
  };

  const handleSendAudioState = (value: boolean) => {
    setSendAudio(value);
  };

  const handleSendAudioToRocketChat = async (currentBlob: Blob) => {
    const data = await audioRecorder?.sendRecording(discussionOpened ? discussionId : chatId, currentBlob);

    if (data) {
      handleSendAudioState(false);
    }

    audioRecorder?.cancel();
    setBlob(undefined);
  };

  const handleSendAudio = async (currentBlob: Blob) => {
    // Discussion messages still go to rocket chat
    if (!discussionOpened) {
      handleSendAudioToSalesdesk(currentBlob);
      return;
    }

    handleSendAudioToRocketChat(currentBlob);
  };

  const createAudioInstance = async () => {
    const audioState = await audioRecorder.start();
    return audioState;
  };

  const stopAudioInstance = () => {
    audioRecorder.stop();
  };

  useEffect(() => {
    if (audioRecorder?.encoder) {
      audioRecorder.encoder.worker.onmessage = handleWorkerMessage;
    }
  }, [audioRecorder?.encoder?.worker]);

  useEffect(() => {
    if (sendAudio && blob) {
      handleSendAudio(blob);
    }
  }, [sendAudio, blob]);

  return {
    audioRecorder,
    handleSendAudioState,
    setChatId,
    createAudioInstance,
    stopAudioInstance,
  };
};

export default useAudio;
