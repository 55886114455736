import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';
import { Notification } from './types';

export type GetAllNotificationsHistoryProps = {
  Count: number;
  Offset: number;
  accountId: string;
  search?: string;
  sort: string;
  sortValue: number;
};

type GetAllNotificationsHistory = { Notifications: Notification[]; Count: number; Offset: number; Total: number };
export const getNotificationHistory = async (
  props: GetAllNotificationsHistoryProps
): Promise<AxiosResponse<GetAllNotificationsHistory>> => {
  const { Count, Offset, accountId, search, sort, sortValue } = props;

  const params = {
    Count,
    Offset,
    accountId,
    search,
    sort: { Field: sort, Sort: `${sortValue}` },
  };

  return AxiosInstanceYalo.get('v1/notification', {
    params,
  });
};
