export enum MENU_ITEMS {
  TAGS = 'tags',
  TAGS_CONTACT = 'tagsContact',
  TAGS_ROOM = 'tagsRoom',
  CANNED_MESSAGES = 'cannedMessages',
  CUSTOM_FIELDS = 'customFields',
  CONVERSATIONS = 'conversations',
  TEAMS = 'teams',
  USERS = 'users',
  OVERVIEW = 'overview',
  SALESDESK_PERFORMANCE = 'salesdesk_performance',
  AGENTS_PERFORMANCE = 'agents_performance',
  CSAT = 'csat_salesdesk',
  ANALYTICS = 'analytics',
  VERIFIED_TEMPLATES = 'verified-templates',
  CHAT_COPILOT_ASSISTANT = 'chat-copilot-assistant',
  BUSINESS_HOURS = 'business-hours.title',
}

export type MenuItemsType = `${MENU_ITEMS}`;

export interface ISidebarItems {
  key: MenuItemsType;
  name: string;
  icon: React.ReactElement;
  featureFlagName?: string;
  permission: string;
  subItems: Array<{ name: string; key: MenuItemsType }>;
}

export interface IRenderListItem {
  index: number;
  item: ISidebarItems;
  sidebarOpenCollapse: string;
  currentItem: string;
  menuItemType: MenuItemsType;
  handleClickCollapse: (itemKey: string) => void;
  handleMenuOption: (item: MenuItemsType) => void;
}
