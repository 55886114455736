import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  max-height: 387px;
`;

export const MainText = styled(Typography)`
  height: 60px;
  left: 0px;
  margin-top: 32px;

  display: flex;
  align-items: center;
  text-align: center;
`;
