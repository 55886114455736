import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { User } from './types';

type GetAllLivechatUsersAgent = { users: User[]; count: number; offset: number; total: number; success: boolean };

export const getLiveChatUsersAgent = async (): Promise<AxiosResponse<GetAllLivechatUsersAgent>> =>
  AxiosInstance.get('livechat/users/agent', {
    params: {
      offset: 0,
      count: 100,
      sort: { name: 1 },
    },
  });
