import { IGenericValueLabelItem } from '../BusinessHoursSettings/types';

export interface IMultiSelectProps {
  items: IGenericValueLabelItem[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string[];
  itemLabel: string;
  placeholder: string;
  id?: string;
  error?: boolean;
  helperText?: string;
}

export const isStringArray = (value: unknown): value is string[] => {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.every((item) => typeof item === 'string');
};
