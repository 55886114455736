import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ICustomAvatarProps {
  contenthovertext: string;
  width: string;
  height: string;
}

export const CustomAvatar = styled(Avatar)<ICustomAvatarProps>(({ contenthovertext, height, width, theme }) => ({
  width,
  height,
  cursor: 'pointer',
  ':hover': {
    display: 'flex',
    background: theme.palette.neutral.var90,
    '.MuiAvatar-img': {
      opacity: 0.25,
    },
    '::before': {
      position: 'absolute',
      textAlign: 'center',
      fontWeight: '450',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.common.white,
      zIndex: 99,
      content: `"${contenthovertext}"`,
    },
  },
}));
