import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material/styles';
import useEnvironment from '../../hooks/useEnvironments';
import MenuChatsContext from '../../contexts/menuChats/context';
import DrawerContext from '../../contexts/drawer/context';
import ConversationContext from '../../contexts/conversation/context';

const useKebabOptionsServiceManager = (itemSelected: any, handlePopoverKebab: any, kebabName: string) => {
  const theme = useTheme();
  const { getBasePath } = useEnvironment();
  const [domain, setDomain] = useState('');
  const [origin, setOrigin] = useState('');
  const [showTooltipCopied, setShowTooltipCopied] = useState(false);
  const [options, setOptions] = useState([] as any);
  const { t } = useTranslation();
  const { setOpenContactManager, setOpenDrawerContactManager } = useContext(MenuChatsContext);
  const { handleStartNewConversation, handleSelectContact } = useContext(ConversationContext);
  const { loadUserInfo } = useContext(DrawerContext);

  const chatOptions = [
    {
      type: 'expandChat',
      icon: itemSelected?.open ? (
        <i
          className="fa-regular fa-arrow-up-right-from-square"
          style={{
            color: theme.palette.neutral.var80,
          }}
        />
      ) : (
        <i
          className="fa-solid fa-up-right-and-down-left-from-center fa-2xl"
          style={{
            color: theme.palette.neutral.var80,
          }}
        />
      ),
      title: itemSelected?.open ? t('seeInQueue') : t('expandChat'),
      function: () => {
        handlePopoverKebab();
        window.open(`${domain}/${itemSelected?._id}`, '_blank')?.focus();
      },
    },
    {
      type: 'copyLink',
      icon: (
        <i
          className="fa-regular fa-link"
          style={{
            color: theme.palette.neutral.var80,
          }}
        />
      ),
      title: t('copyLink'),
      function: () => {
        navigator.clipboard.writeText(`${origin}${domain}/${itemSelected?._id}`);
        setShowTooltipCopied(true);
        setTimeout(() => {
          setShowTooltipCopied(false);
          handlePopoverKebab();
        }, 2000);
      },
    },
  ];

  const contactManagerOptions = [
    {
      type: 'startNewConversation',
      icon: (
        <i
          className="fa-regular fa-comment-plus"
          style={{
            color: theme.palette.neutral.var80,
          }}
        />
      ),
      title: t('startNewConversation'),
      function: () => {
        handleSelectContact(itemSelected);
        handleStartNewConversation(true);
        setOpenContactManager(false);
      },
    },
    {
      type: 'editInformation',
      icon: (
        <i
          className="fa-regular fa-pen"
          style={{
            color: theme.palette.neutral.var80,
          }}
        />
      ),
      title: t('editInformation'),
      function: () => {
        loadUserInfo(itemSelected?._id);
        setOpenDrawerContactManager(true);
        handlePopoverKebab();
      },
    },
  ];

  useEffect(() => {
    if (kebabName === 'chatsManager') {
      setOptions(chatOptions);
      return;
    }

    setOptions(contactManagerOptions);
  }, [kebabName, itemSelected, domain, origin]);

  useEffect(() => {
    const path = getBasePath();
    setDomain(path);
    setOrigin(window.location.origin);
  }, []);

  return {
    options,
    translation: t,
    showTooltipCopied,
  };
};

export default useKebabOptionsServiceManager;
