import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContentText, DialogButton, DialogActions } from '@engyalo/design-system';

interface IDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  disabled?: boolean;
}

const DialogConfirmation = ({ open, onCancel, onConfirm, title, description, disabled }: IDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog onClose={onCancel} variant="info" open={open}>
      <DialogTitle>
        {title}
        <DialogContentText>{description}</DialogContentText>
      </DialogTitle>
      <DialogActions>
        <DialogButton onClick={onCancel} disabled={disabled}>
          {t('confirmationDialog.cancel')}
        </DialogButton>
        <DialogButton onClick={onConfirm} primary disabled={disabled}>
          {t('confirmationDialog.confirm')}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
