import { useTranslation } from 'react-i18next';
import React, { ComponentType, MouseEvent } from 'react';
import {
  OutgoingMessageChatMessageKind,
  OutgoingMessageResponseStatus,
} from '@engyalo/salesdesk-chat-socket-ts-client';
import { useTheme } from '@mui/material/styles';
import { useFeatureFlag, flag } from '../../../hooks/useFeatureFlag';
import { ErrorMessage, MessageRow, MessageWrapper } from './styled';
import TextMessage from './TextMessage';
import AudioMessage from './AudioMessage';
import ImageMessage from './ImageMessage';
import VideoMessage from './VideoMessage';
import FileMessage from './FileMessage';
import InteractiveMessage from './InteractiveMessage';
import { ChatMessageType, SpecificMessageProps } from '../Message';
import LocationMessage from './LocationMessage';
import { getStatusIconClass } from './utils';
import ContactInfoArea from './ContactInfoArea';

export type ChatMessageComponentProps = Omit<ChatMessageType, 'kind'>;

export const MESSAGE_COMPONENTS: Record<OutgoingMessageChatMessageKind, ComponentType<ChatMessageComponentProps>> = {
  text: TextMessage,
  audio: AudioMessage,
  image: ImageMessage,
  video: VideoMessage,
  document: FileMessage,
  location: LocationMessage,
  interactive: InteractiveMessage,
};

const ChatMessage: React.FC<SpecificMessageProps> = ({ chat, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDeliveredStatusEnabled = useFeatureFlag(flag.DELIVERED_STATUS);
  const isReadStatusEnabled = useFeatureFlag(flag.READ_STATUS);

  if (!chat) {
    return null;
  }

  const { kind, ...rest } = chat;
  const { pending, error, delivered, read, authorRole, name: nameProp, username, timestamp, status } = chat;
  const name = nameProp || username;

  const hasDeliveredStatus = status === OutgoingMessageResponseStatus.ResponseStatusDelivered;
  const hasReadStatus = status === OutgoingMessageResponseStatus.ResponseStatusRead;
  const statusIconClass = getStatusIconClass({
    pending,
    error,
    delivered: isDeliveredStatusEnabled && (delivered || hasDeliveredStatus),
    read: isReadStatusEnabled && (read || hasReadStatus),
  });

  const isRead = statusIconClass.includes('$read');
  const isAgent = authorRole === 'agent';
  const isBot = authorRole === 'bot';

  const MessageComponent = MESSAGE_COMPONENTS[kind];

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (error) {
      event.preventDefault();
    }

    onClick?.();
  };

  return (
    <MessageRow error={error} onClick={handleClick}>
      <MessageWrapper authorRole={authorRole}>
        <ContactInfoArea name={name} timestamp={timestamp} isBot={isBot} />
        <MessageComponent {...rest} />
        {isAgent && (
          <i
            data-testid="status-icon"
            className={statusIconClass}
            style={{
              color: isRead ? theme.palette.info.main : undefined,
              fontWeight: isRead ? 900 : undefined,
            }}
          />
        )}
      </MessageWrapper>
      {error && <ErrorMessage>{t('messages.error')}</ErrorMessage>}
    </MessageRow>
  );
};

export default ChatMessage;
