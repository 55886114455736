type HandleFileDownload = { response: Response; url: string; caption: string };

const DEFAULT_NAME = 'file';

export const handleFileDownload = async ({ response, url, caption }: HandleFileDownload) => {
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = window.document.createElement('a');
  link.href = downloadUrl;
  link.download = caption || url.split('/').pop() || DEFAULT_NAME;
  window.document.body.appendChild(link);
  link.click();
  link.remove();
};
