import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      min-height: 6rem;
    }
  `}
`;

export const Status = styled(Typography)`
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 0.8rem 2rem;
  width: 90%;
  border: ${({ theme }) => ` 1px solid ${theme.palette.neutral.var30}`};
  border-radius: 1.6rem;
  text-align: center;
  gap: 1rem;
`;
