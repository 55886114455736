import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomTextField = styled(TextField)`
  max-height: 50px;
  :hover {
    border: none;
    box-shadow: none;
  }
  :active {
    border: none;
    box-shadow: none;
  }
  & .MuiInput-underline:before {
    border-bottom: none !important;
  }
  & .MuiInput-underline:after {
    border-bottom: none;
  }
  .MuiAutocomplete-inputRoot {
    border-bottom: none;
    padding-right: 0px !important;
  }
  & .MuiAutocomplete-inputRoot .MuiAutocomplete-tag {
    background: ${({ theme }) => theme.palette.success.light};
    color: ${({ theme }) => theme.palette.success.main};
    border-radius: 16px;
    padding: 6px 10px;
    width: 35px;
    height: 28px;
  }
`;

export const InputItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 12px;
`;

export const IconEditTag = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.var10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
`;

export const OptionItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ListItemCustom = styled('li')`
  height: 44px;
  width: 100%;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.palette.neutral.var20};
`;
