import React, { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import { InputAdornment, CircularProgress, Stack, useMediaQuery, Theme, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import {
  LoginContainer,
  Bg,
  BlueCircle,
  YellowBorderedCircle,
  GreenTriangle,
  YellowHalfCircle,
  BoxForm,
  HeaderForm,
  BoxInputs,
  ButtonSubmit,
  TextInput,
} from './style';

import useLogin from './useLogin';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/emailIconLogin.svg';
import { ErrorMessage } from './ErrorMessage';
import { Footer } from './Footer';
import shadows from '../../theme/shadows';
import { EmailSentSuccess } from './EmailSentSuccess';
import useSegment from '../../services/Analytics';

const Login: React.FC = () => {
  const theme = useTheme();
  const {
    translation,
    handleSubmit,
    onSubmit,
    control,
    invalidCredentials,
    errors,
    loading,
    connectionError,
    forgotPassword,
    setForgotPassword,
    emailSent,
  } = useLogin();
  const { sendPage } = useSegment();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const phrases = ['Keep it simple.', 'It’s all about connections.'];

  const [randomNumber, setRandomNumber] = useState(-1);

  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 2));
    sendPage();
  }, []);

  return (
    <LoginContainer data-testid="login">
      <Bg style={{ display: isMobile ? 'none' : 'flex' }}>
        <BlueCircle />
        <YellowBorderedCircle />
        <GreenTriangle />
        <YellowHalfCircle />
        <Typography
          variant="h1"
          display="flex"
          textAlign="center"
          flexDirection="column"
          color="common.white"
          width="100%"
        >
          {phrases[randomNumber]}
        </Typography>
      </Bg>
      <div
        style={{
          position: 'relative',
          flex: '1 ',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <BoxForm onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ background: theme.palette.common.white, borderRadius: '6px' }}>
            <HeaderForm>
              <Stack
                direction="row"
                display="flex"
                justifyContent={forgotPassword ? 'space-between' : 'center'}
                sx={{ width: '100%' }}
              >
                <IconButton
                  data-testid="arrowBackBtn"
                  onClick={() => setForgotPassword(false)}
                  sx={{
                    display: forgotPassword ? 'flex' : 'none',
                    width: '21px',
                    height: '21px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    shadow: shadows.shadowButton,
                  }}
                >
                  <ArrowBackIcon
                    sx={{
                      color: 'black',
                      width: '15px',
                      height: '15px',
                    }}
                  />
                </IconButton>
                <Logo />
                <div />
              </Stack>
            </HeaderForm>
            <Typography variant="caption1" display={forgotPassword ? 'flex' : 'none'} textAlign="center">
              {translation('resetPasswordMessage')}
            </Typography>
            {(invalidCredentials || connectionError) && (
              <ErrorMessage translation={translation} connectionError={connectionError} />
            )}

            {emailSent && <EmailSentSuccess translation={translation} />}

            <BoxInputs
              style={{
                paddingTop: forgotPassword ? '0px' : '',
              }}
            >
              <Typography variant="caption1" color="neutral.var80">
                {translation('email')}
              </Typography>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    fullWidth
                    value={field.value || ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            width: '42px',
                            height: '36px',
                            borderRadius: '3px 0px 0px 3px',
                            background: theme.palette.neutral.var10,
                            justifyContent: 'center',
                            marginRight: '12px',
                          }}
                        >
                          <EmailIcon fill={theme.palette.neutral.var50} />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.username}
                    helperText={translation(errors.username?.message ?? '')}
                    placeholder={translation('emailPlaceholder').toLocaleLowerCase()}
                    type="text"
                    inputProps={{ 'data-testid': 'inputEmail' }}
                  />
                )}
              />

              <Typography
                variant="caption1"
                color="neutral.var80"
                display={forgotPassword ? 'none' : 'flex'}
                marginTop="16px"
              >
                {translation('password')}
              </Typography>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    fullWidth
                    value={field.value || ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            width: '42px',
                            height: '36px',
                            borderRadius: '3px 0px 0px 3px',
                            background: theme.palette.neutral.var10,
                            justifyContent: 'center',
                            marginRight: '12px',
                          }}
                        >
                          <i className="fa-thin fa-lock-keyhole" style={{ color: `${theme.palette.neutral.var50}` }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      display: forgotPassword ? 'none' : 'flex',
                    }}
                    error={!!errors.password}
                    helperText={translation(errors.password?.message ?? '')}
                    placeholder={translation('password').toLocaleLowerCase()}
                    type="password"
                    inputProps={{ 'data-testid': 'inputPassword' }}
                  />
                )}
              />

              <Typography
                variant="caption1"
                data-testid="forgotPassword"
                sx={{
                  marginTop: '20px',
                  cursor: 'pointer',
                  display: forgotPassword ? 'none' : 'inline',
                }}
                textAlign="center"
                onClick={() => setForgotPassword(true)}
              >
                {translation('forgotPassword')}
              </Typography>
            </BoxInputs>

            <ButtonSubmit
              data-testid="btnSubmit"
              type="submit"
              disabled={loading}
              sx={{
                background: loading ? `${theme.palette.neutral.var30} !important` : '',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: theme.palette.neutral.var40 }} />
              ) : (
                <>
                  {forgotPassword ? translation('sendEmail') : translation('signIn')}{' '}
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{
                      color: theme.palette.common.white,
                      marginTop: '-2px',
                    }}
                  />
                </>
              )}
            </ButtonSubmit>
          </Stack>
          <Footer translation={translation} />
        </BoxForm>
      </div>
    </LoginContainer>
  );
};

export default Login;
