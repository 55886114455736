import React, { useEffect, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { Options, OptionsContainer } from './style';
import { IDropdown } from './types';
import MenuOptions from '../MenuOption';

const DropDown: React.FC<IDropdown> = ({
  item,
  listOptions,
  colorIcon,
  isUserlogged,
  isbot,
  iserror,
  ismidia,
  isanotation,
  isdiscussion,
  downloadMedia,
  isLongClick,
  msg,
  handleIsLongClick,
  position,
}: IDropdown) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const divRef = useRef<HTMLInputElement | null>(null);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const handleOptionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (handleIsLongClick) handleIsLongClick();
  };

  const copyText = () => {
    navigator.clipboard.writeText(msg || '');
  };

  listOptions = listOptions.map((option: any) => {
    if (option.type === 'download') {
      option.function = downloadMedia;
    }
    if (option.type === 'copyText') {
      option.function = copyText;
    }
    return option;
  });

  useEffect(() => {
    if (isLongClick) {
      setAnchorEl(divRef.current);
    }
  }, [isLongClick]);

  return (
    <OptionsContainer ref={divRef} data-testid="dropDown" position={position}>
      <>
        {!isMobile && (
          <Options
            data-testid="optionMenu"
            parentItem={item}
            isUserlogged={isUserlogged}
            isbot={isbot}
            iserror={iserror}
            ismidia={ismidia}
            isanotation={isanotation}
            isdiscussion={isdiscussion}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => handleOptionClick(event)}
          >
            <i
              className="fa-regular fa-chevron-down fa-2xs"
              style={{
                color: colorIcon,
              }}
            />
          </Options>
        )}
        {anchorEl && (
          <MenuOptions options={listOptions} anchorEl={anchorEl} handleClose={handleClose} position="bottom" />
        )}
      </>
    </OptionsContainer>
  );
};

export default DropDown;
