import { styled } from '@engyalo/design-system';
import { Drawer } from '@mui/material';

export const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
  .MuiPaper-root {
    background: ${theme.palette.common.white};
    box-shadow: ${theme.shadows[10]};
    width: 48rem;
  
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`
);
