import { InteractiveMessageComponentProps } from '../InteractiveMessage';
import { MessageHeader } from '../styled';
import { Option, Wrapper } from './styled';

const ButtonContent: React.FC<InteractiveMessageComponentProps> = ({ button }) => {
  if (!button) {
    return null;
  }

  const { actions, body } = button;

  return (
    <Wrapper>
      <MessageHeader>{body}</MessageHeader>
      {actions.map((action) => (
        <Option aria-label={action.title} key={action.id}>
          {action.title}
        </Option>
      ))}
    </Wrapper>
  );
};

export default ButtonContent;
