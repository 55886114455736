import { styled } from '@mui/material/styles';
import { Chip, Typography } from '@mui/material';
import { shouldForwardPropHelper } from '@engyalo/design-system';

export const ContainerModal = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BodyText = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 48px;
  justify-content: center;
`;

export const FooterButtons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0px 0px;
`;

export interface ChipProps {
  customcolor?: {
    color?: string;
    text?: string;
  };
  width?: string;
}

export const CustomChip = styled(Chip, {
  shouldForwardProp: shouldForwardPropHelper,
})<ChipProps>(
  ({ customcolor, width, size, theme }) => `
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.12em;
  background-color: ${customcolor ? customcolor.color : theme.palette.neutral.var20};
  color: ${customcolor ? customcolor.text : theme.palette.neutral.var90};
  text-transform: uppercase;
  height:24px;
  padding: 6px 4px;
  gap: 8px;

  ${
    !!width &&
    `
    max-width: ${width}px;
    overflow: hidden;
    text-overflow: ellipsis;
  `
  };

  ${
    size === 'small' &&
    `
    height: 18px;
    padding: 4px 0;
    font-size: 9px;
  `
  };
`
);
