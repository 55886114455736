import React from 'react';
import { TextField } from '@engyalo/design-system';

export function handleRenderCustomFieldText(selectValueFilter: (index: number, value: string) => void) {
  return (index: number, value?: string) => (
    <TextField
      sx={{ width: '100%' }}
      defaultValue={value}
      onChange={(event) => {
        selectValueFilter(index, event.target.value);
      }}
      inputProps={{ 'data-testid': 'inputDepartmentName' }}
    />
  );
}
