import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const saveCustomFields = async (
  field: string,
  label: string,
  type: string,
  update: boolean,
  required?: boolean,
  options?: string
  // defaultValue?: string,
): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      method: 'livechat:saveCustomField',
      id: uuidv4().toString(),
      params: [
        update ? field : null,
        {
          field,
          label,
          scope: 'visitor', // passar campos via params quando entrarem de opções no form
          visibility: 'visible', // passar campos via params quando entrarem de opções no form
          regexp: '',
          type,
          required,
          defaultValue: '',
          searchable: true,
          options,
          public: true, // passar campos via params quando entrarem de opções no form
        },
      ],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveCustomField', params);
};
