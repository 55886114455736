import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getHistoryChats = async (
  roomId: string,
  visitorId: string,
  searchText?: string,
  servedChatsOnly?: boolean,
  closedChatsOnly?: boolean
): Promise<AxiosResponse> => {
  const params = {
    count: 50,
    offset: 0,
    servedChatsOnly,
    closedChatsOnly,
    ...(searchText && { searchText }),
  };
  return AxiosInstance.get(`livechat/visitors.searchChats/room/${roomId}/visitor/${visitorId}`, {
    params,
  });
};
