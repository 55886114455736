/* eslint no-underscore-dangle: ["error", { "allow": ["_id", "_updatedAt"] }] */

import { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Switch, Tooltip } from '@engyalo/design-system';
import { YDSGridColDef } from '@engyalo/design-system/dist/src/DataGrid/customCols';
import { GridColDef } from '@mui/x-data-grid-pro';
import { HIDDEN_TAGS } from '../../../constants/defaultValues';
import { DISABLED_SUFFIX } from '../../../constants';
import DepartmentContext from '../../../contexts/departments/context';
import { tagsColor } from '../../../helpers/tagsColors';
import hasDisabledSuffix from '../../../helpers/hasDisabledSuffix';
import { ITag } from '../../../types/tags';
import { CustomChip } from '../../../components/Tags/style';
import { dateToText } from '../../../helpers/dateToText';
import { getListTags } from '../../../services/getTags';
import { saveTag } from '../../../services/saveTag';
import { ITagManager } from '.';
import { Tags } from '../../../services/types';

interface ITableRow {
  _id: string;
  name: string;
  updatedAt: string;
  dateValue: Date;
  teamsValue?: string;
}

const useTagManager = (props: ITagManager) => {
  const { type } = props;
  const { departments } = useContext(DepartmentContext);
  const { t } = useTranslation();
  const [openCreateUpdateTagModal, setOpenCreateUpdateTagModal] = useState(false);
  const [allTags, setAllTags] = useState<Tags[]>([]);
  const [selectTag, setSelectTag] = useState<Tags | undefined>();
  const [headerColumns, setHeaderColumns] = useState<YDSGridColDef<ITableRow>[]>([]);
  const [tableRows, setTableRows] = useState<Array<ITableRow>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [textToSearch, setTextToSearch] = useState<string | undefined>('');
  const [foundTags, setFoundTags] = useState<Tags[] | undefined>([]);
  const [loading, setLoading] = useState(false);

  const removeTagsClientSide = (tags: Tags[], total: number) => {
    const filteredTags = tags.filter((tag) => !HIDDEN_TAGS.includes(tag.name));
    const numberOfFilteredTags = tags.length - filteredTags.length;
    const totalAfterFiltering = total - numberOfFilteredTags;

    return { filteredTags, totalAfterFiltering };
  };

  const loadTags = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { tags, total },
      } = await getListTags({ count: 0, text: type, includeDisabled: true });

      const { filteredTags, totalAfterFiltering } = removeTagsClientSide(tags, total);

      setAllTags(filteredTags);
      setFoundTags(undefined);
      setTotalItems(totalAfterFiltering);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [type]);

  const handleClickItem = useCallback(
    (item?: ITableRow) => {
      setSelectTag(allTags.find((tag) => tag.name === item?.name));
      setOpenCreateUpdateTagModal(true);
    },
    [allTags]
  );

  const handleToggle = useCallback(
    async (enabled: boolean, tag?: Tags) => {
      if (enabled) {
        setOpenDisableModal(true);
      } else if (tag) {
        await saveTag({
          idRequest: uuidv4(),
          name: tag.name,
          description: type,
          id: tag._id,
          departmentsIds: tag.departments,
        });

        loadTags();
      }
    },
    [loadTags, type]
  );

  const baseTagsColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('name'),
        renderCell: ({ row }: { row: ITableRow }) => (
          <CustomChip key={row._id} label={row.name} customcolor={tagsColor(row.name)} />
        ),
      },
      { field: 'updatedAt', headerName: t('updatedAt') },
    ],
    [t]
  );

  const enabledColumn = useMemo<GridColDef<ITableRow>>(
    () => ({
      field: 'enabled',
      headerName: t('enabled'),
      width: 100,
      renderCell: ({ row, value }) => (
        <Switch
          checked={value}
          onClick={(e) => e.stopPropagation()}
          onChange={() => {
            const foundTag = allTags.find((tag) => tag.name === row.name);
            setSelectTag(foundTag);
            handleToggle(value, foundTag);
          }}
          data-testid={`switch-${row._id}`}
        />
      ),
    }),
    [allTags, handleToggle, t]
  );

  const handleTable = useCallback(
    (list?: ITag[]) => {
      const listValues = list || allTags;
      if (type === 'visitor') {
        setHeaderColumns([...baseTagsColumns, enabledColumn]);
        const values = listValues.map((item) => {
          const updatedDate = new Date(item._updatedAt) as Date;
          return {
            _id: item._id,
            name: item.name,
            updatedAt: dateToText(updatedDate)
              ? `${t('today')} ${updatedDate.toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}`
              : updatedDate.toLocaleString(),
            dateValue: updatedDate,
            enabled: !hasDisabledSuffix(item.description),
          };
        });

        setTableRows(values);
        return;
      }

      // Room tags
      setHeaderColumns([
        ...baseTagsColumns,
        {
          field: 'teams',
          headerName: t('teams'),
          renderCell: ({ row }: { row: ITableRow }) => (
            <Tooltip
              title={
                <div style={{ textAlign: 'justify' }}>
                  {row.teamsValue?.split(',').map((departmentName: string) => {
                    const name = departmentName;
                    return <div key={name}>{name}</div>;
                  })}
                </div>
              }
            >
              <span>{row.teamsValue}</span>
            </Tooltip>
          ),
        },
        enabledColumn,
      ]);

      const values = listValues.map((item) => {
        let departmentsName = '';
        if (item.departments.length) {
          departmentsName = departments
            .filter((department) => item.departments.includes(department._id))
            .map((department) => department.name)
            .join(',');
        }

        const updatedDate = new Date(item._updatedAt);
        return {
          _id: item._id,
          name: item.name,
          updatedAt: dateToText(updatedDate)
            ? `${t('today')} ${updatedDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}`
            : updatedDate.toLocaleString(),
          teamsValue: departmentsName,
          dateValue: updatedDate,
          enabled: !hasDisabledSuffix(item.description),
        };
      });

      setTableRows(values);
    },
    [enabledColumn, allTags, baseTagsColumns, departments, t, type]
  );

  const handleCloseTagModal = (confirm: boolean) => {
    if (confirm) {
      loadTags();
    }
    setOpenCreateUpdateTagModal(!openCreateUpdateTagModal);
  };

  const handleConfirmDisableTag = async (confirm: boolean) => {
    if (confirm && selectTag && selectTag._id) {
      const { data } = await saveTag({
        idRequest: uuidv4(),
        name: selectTag.name,
        description: `${type}${DISABLED_SUFFIX}`,
        id: selectTag._id,
        departmentsIds: selectTag.departments,
      });

      const response = JSON.parse(data.message);
      if (response?.result) {
        loadTags();
      }

      setTextToSearch('');
    }

    setOpenDisableModal(false);
  };

  const handleSearchTags = useCallback(
    async (searchTerm: string) => {
      setTextToSearch(searchTerm || undefined);
      const result = allTags.filter((tag) => tag.name.toUpperCase().includes(searchTerm.toUpperCase()));
      setFoundTags(result.length ? result : undefined);
      setTotalItems(result.length);
    },
    [allTags]
  );

  useEffect(() => {
    setTextToSearch('');
    loadTags();
  }, [loadTags, type]);

  useEffect(() => {
    handleTable(foundTags);
  }, [allTags, foundTags, handleTable, textToSearch]);

  return {
    handleSearchTags,
    handleClickItem,
    headerColumns,
    tableRows,
    totalItems,
    openCreateUpdateTagModal,
    selectTag,
    handleCloseTagModal,
    handleConfirmDisableTag,
    openDisableModal,
    textToSearch,
    loading,
  };
};

export default useTagManager;
