import { styled, Typography } from '@mui/material';

export const MainText = styled(Typography)`
  width: 24rem;
  left: 0px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  text-align: center;
`;
