import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  height: 100%;
  overflow: hidden;

  .messageGroup {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const MessagesArea = styled('div')`
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 2rem;
`;

export const ButtonArea = styled('div')`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
`;

export const LoadingWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
