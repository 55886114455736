import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getLivechatRoomInfo = async (token: string, roomId: string): Promise<AxiosResponse> =>
  AxiosInstance.get('livechat/room', {
    params: {
      token,
      rid: roomId,
    },
  });
