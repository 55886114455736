import { LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { setWorkHours, setCommonSchedule } from 'redux/reducers/businessHoursReducer';
import { UpdateSelectedDayTimeProps, Schedule } from './types';
import Pickers from './components/Pickers';

const TimePickerComponent = () => {
  const { workHours } = useAppSelector((state) => state.businessHours.selectedSchedule);
  const { useSameSchedule, opens, closes } = useAppSelector((state) => state.businessHours);
  const dispatch = useAppDispatch();

  const updateSelectedDayTime = ({ value, type, index }: UpdateSelectedDayTimeProps) => {
    if (!value || Number.isNaN(value.getTime())) {
      return;
    }

    const time = format(value, 'HH:mm');
    const newDays = [...workHours];

    let targetDay = newDays[index];
    targetDay = { ...targetDay, [type]: time };
    newDays[index] = targetDay;
    dispatch(setWorkHours(newDays));
  };

  const updateCommonSchedule = ({ value, type }: Schedule) => {
    if (!value || Number.isNaN(value.getTime())) {
      return;
    }

    const time = format(value, 'HH:mm');
    dispatch(setCommonSchedule({ schedule: time, scheduleType: type }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {useSameSchedule && workHours.length > 0 && (
        <Pickers
          dayLabel="business-hours.timePicker.schedule"
          onChange={(newValue, type) => updateCommonSchedule({ value: newValue, type })}
          opens={opens}
          closes={closes}
        />
      )}
      {!useSameSchedule &&
        workHours.map((day, index) => (
          <Pickers
            dayLabel={`business-hours.days.${day.dayOfWeek}`}
            onChange={(newValue, type) => updateSelectedDayTime({ value: newValue, type, index })}
            opens={day.opens}
            closes={day.closes}
          />
        ))}
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
