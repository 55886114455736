import { Stack } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { DataGrid } from '@engyalo/design-system';

export const Container = styled(Stack)(
  () => css`
    width: 100%;
    height: 100%;
    padding: 4rem 4.8rem 0;
    gap: 2.4rem;
  `
);

export const HeaderContainer = styled(Stack)(
  () => css`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.8rem;
  `
);

export const Header = styled(Stack)(
  () => css`
    gap: 0.4rem;
  `
);

export const HeaderActions = styled(Stack)(
  () => css`
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.8rem;
  `
);

export const DrawerForm = styled('form')(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `
);

export const DrawerBody = styled(Stack)(
  () => css`
    flex-grow: 1;
    padding: 1.6rem;
    gap: 1.6rem;
    overflow-y: auto;
  `
);

export const DrawerFooter = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    justify-content: stretch;
    padding: 1.2rem;
    gap: 1.2rem;
    border-top: 1px solid ${theme.palette.neutral.var20};

    * {
      flex-grow: 1;
    }
  `
);

export const NoRowsOverlay = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: ${theme.palette.neutral.var60};
    padding: 2rem;
    span {
      margin: .5rem 0;
      font-size: 1.4rem;
    }
    span:nth-child(2) {
      font-weight: 700;
      font-size: 1.8rem;
    }
  `
);

export const StyledGrid = styled(DataGrid)(
  () => css`
    .MuiDataGrid-overlayWrapperInner {
      height: 350px !important;
    }
    .MuiDataGrid-overlayWrapper {
      display: flex;
      height: 350px !important;
      flex-direction: column;
    }
  `
);
