import { Divider, Fab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import AuthContext from '../../../contexts/auth/context';
import { formatDate } from '../../../helpers/formatterDates';
import shadows from '../../../theme/shadows';
import { IAttachment, LivechatStatus, MessageType } from '../../../types/message';
import InlineNotification from './inlineNotification';
import { ContainerMessage, ListMessages } from './style';
import { IMessagesList } from './type';
import { useMessages } from './useMessages';
import useEnvironment from '../../../hooks/useEnvironments';
import Message from './Message';
import DateBegininngConversation from '../../DateBegininngConversation';

const MESSAGE_COMMENT_CLOSE_SESSION = 'Comment to Leave on Closing Session';

const FIVE_MINUTES_IN_MILISECONS = 300000;

const WHATSAPP = 'whatsapp';
const GUEST = 'guest';

const DEFAULT_STATUS = [LivechatStatus.PUT_CHAT_ON_HOLD, LivechatStatus.CLOSE];

const USER_INLINE_NOTIFICATION = [...DEFAULT_STATUS, LivechatStatus.CHAT_ON_HOLD_RESUME];

const STATUS_SHOW_INLINE_NOTIFICATION = [
  ...DEFAULT_STATUS,
  LivechatStatus.STARTED,
  LivechatStatus.TRANSFER_HISTORY,
  LivechatStatus.CHAT_ON_HOLD_RESUME,
];

const LAST_INDEX_NOTIFICATION = [...DEFAULT_STATUS, LivechatStatus.TRANSFER_HISTORY, LivechatStatus.COMMAND];

const MessagesList: React.FC<IMessagesList> = (props: IMessagesList) => {
  const theme = useTheme();
  const { messages, roomId, type } = props;

  const { getCurrentUserId } = useContext(AuthContext);

  const {
    inlineNotificationOptions,
    messageErrorOptions,
    messageMediaOptions,
    messageTextOptions,
    serviceSelected,
    setFloatButton,
    floatButton,
    refDiv,
    executeScroll,
    downloadFiles,
  } = useMessages(roomId, messages);

  const { i18n } = useTranslation();

  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  const userId = getCurrentUserId();
  const copyMessages = JSON.parse(JSON.stringify([...messages]));
  const isLastIndexInlineNotification = copyMessages
    .reverse()
    .findIndex((item: MessageType) => LAST_INDEX_NOTIFICATION.includes(item.t));

  const handleMediaDownload = (attach: IAttachment, user: string) => {
    downloadFiles(`${baseUrl}${attach.title_link}`, `${user}-${attach.title}`);
  };

  const getCurrentDateFromItemMsg = (item: MessageType) => {
    if (item.customFields?.timestamp) {
      return new Date(item.customFields.timestamp);
    }

    if (item?.ts) {
      return new Date(item.ts);
    }

    return new Date(item.date);
  };
  return (
    <div
      style={{
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <ListMessages
        role="list"
        disablePadding
        ref={refDiv}
        onScroll={() => {
          setFloatButton(refDiv.current.clientHeight + refDiv.current.scrollTop < refDiv.current.scrollHeight);
        }}
        sx={{
          height: '100% ',
          [`${theme.breakpoints.down('md')}`]: {
            height: 'calc(100% - 53px)',
          },
        }}
      >
        {serviceSelected._id
          ? messages.map((itemMsg: MessageType, idx: number) => {
              if ([LivechatStatus.COMMAND, LivechatStatus.UL].includes(itemMsg.t)) {
                return <div data-testid="messageWithCommandOrUl" />;
              }

              const currentDate = getCurrentDateFromItemMsg(itemMsg);

              let hasValidIntervalBetweenMessages = false;
              const username = itemMsg.username || itemMsg.u.username;
              const isBot = username.includes('.bot');

              const hasCustomFieldsType = Boolean(itemMsg.customFields && itemMsg.customFields.type);

              const isClient = username.startsWith(WHATSAPP) || username.includes(GUEST);
              const isAnotation = [LivechatStatus.TRANSFER_HISTORY, LivechatStatus.CLOSE].includes(itemMsg.t);

              const isInvalidUser = isClient || isBot;
              const isUserLoggedIn = !isInvalidUser || itemMsg.userId === userId;

              const hasChatStarted = itemMsg.t === LivechatStatus.STARTED;

              const showInlineNotifcation = STATUS_SHOW_INLINE_NOTIFICATION.includes(itemMsg.t);

              let daysOfDifference = 0;

              if (idx && !!(itemMsg.from || itemMsg.u._id)) {
                let previousMessageIdx = 0;
                let validateIdx = 0;
                if (type === 'history') {
                  previousMessageIdx = messages[idx - 1].u._id ? 1 : 2;
                  validateIdx = idx - previousMessageIdx <= -1 ? 1 : previousMessageIdx;
                } else {
                  previousMessageIdx = messages[idx - 1].from || messages[idx - 1].u._id ? 1 : 2;
                  validateIdx = idx - previousMessageIdx <= -1 ? 1 : previousMessageIdx;
                }

                const messagePosition = idx - validateIdx;
                const message = messages[messagePosition];
                const customFieldTS = message?.customFields?.timestamp;

                const previousDate = new Date(customFieldTS || message.ts || message.date);

                const differenceLowerThenFiveMinutes =
                  currentDate.getTime() - previousDate.getTime() < FIVE_MINUTES_IN_MILISECONS;

                if (type === 'history' && message.u._id === itemMsg.u._id) {
                  hasValidIntervalBetweenMessages = differenceLowerThenFiveMinutes;
                } else {
                  hasValidIntervalBetweenMessages = message.userId === itemMsg.userId && differenceLowerThenFiveMinutes;
                }

                const checkIsDifferentDay = Math.abs(currentDate.getDate() - previousDate.getDate());

                daysOfDifference = checkIsDifferentDay;
              }
              const hasError = itemMsg.hasError || false;

              let hasAttachments = false;

              if (itemMsg.attachments && itemMsg.attachments.length) {
                hasAttachments = true;
              }

              let isLastInlineNotification = false;

              if (isAnotation && showInlineNotifcation) {
                isLastInlineNotification =
                  itemMsg.t === LivechatStatus.CLOSE
                    ? true
                    : messages.length - 1 - isLastIndexInlineNotification === idx;
              }

              if (itemMsg.rid !== roomId) {
                return null;
              }

              // Use the same criterium to render messages as the Rocket Chat client:
              // https://github.com/RocketChat/Rocket.Chat/blob/2c0260d1069fc525635f69b1020043a09d5228e4/apps/meteor/client/components/message/variants/room/RoomMessageContent.tsx#L49
              const hasMarkdown = itemMsg.md?.length;
              const isUserOrAgentMessageHasAttachments =
                (((type === 'history' || hasMarkdown) && itemMsg.msg) || hasAttachments) &&
                itemMsg.msg !== MESSAGE_COMMENT_CLOSE_SESSION;

              return (
                <ContainerMessage
                  key={itemMsg.id}
                  idx={idx}
                  messagesLength={messages.length}
                  type={type}
                  data-testid="containerMessage"
                >
                  {hasChatStarted && (
                    <DateBegininngConversation
                      key={`${itemMsg?.id}-date`}
                      date={formatDate(currentDate, i18n.language)}
                    />
                  )}
                  {(isAnotation || showInlineNotifcation) && (
                    <InlineNotification
                      typeListChat={type}
                      lastItem={isLastInlineNotification}
                      user={USER_INLINE_NOTIFICATION.includes(itemMsg.t) ? itemMsg.u : serviceSelected.lastMessage.u}
                      time={currentDate}
                      type={itemMsg.t}
                      transferData={itemMsg.transferData}
                      menuOptions={inlineNotificationOptions}
                      comment={
                        itemMsg.t === LivechatStatus.CLOSE ? itemMsg.msg : serviceSelected?.livechatData?.putInHoldCause
                      }
                    />
                  )}

                  {isUserOrAgentMessageHasAttachments ? (
                    <>
                      {daysOfDifference >= 1 && (
                        <Divider
                          sx={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 99,
                            padding: '0px 6.4%',
                            '::before': {
                              borderColor: 'neutral.var50',
                            },
                            '::after': {
                              borderColor: 'neutral.var50',
                            },
                            [theme.breakpoints.down('md')]: {
                              padding: '0px 12px',
                            },
                          }}
                        >
                          <Typography
                            variant="h4"
                            marginX="16px"
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '10px',
                              lineHeight: '12px',
                              color: 'neutral.var90',
                            }}
                          >
                            {formatDate(currentDate, i18n.language)}
                          </Typography>
                        </Divider>
                      )}

                      <Message
                        isbot={isBot}
                        isclient={isClient}
                        isUserlogged={isUserLoggedIn}
                        type={type}
                        checkIntervalBetweenMessages={hasValidIntervalBetweenMessages}
                        itemMsg={itemMsg}
                        currentDate={currentDate}
                        hasError={hasError}
                        isAnotation={isAnotation}
                        hasAttachments={hasAttachments}
                        messageErrorOptions={messageErrorOptions}
                        messageMediaOptions={messageMediaOptions}
                        messageTextOptions={messageTextOptions}
                        username={username}
                        hasCustomFields={hasCustomFieldsType}
                        messages={messages}
                        handleMediaDownload={handleMediaDownload}
                      />
                    </>
                  ) : (
                    <div data-testid="systemHiddenMessages" />
                  )}
                </ContainerMessage>
              );
            })
          : null}
      </ListMessages>
      {floatButton ? (
        <Fab
          data-testid="fabButtonScrollBottom"
          onClick={() => {
            executeScroll(refDiv);
            setFloatButton(false);
          }}
          size="small"
          sx={{
            alignSelf: 'end',
            marginRight: '16px',
            marginBottom: '16px',
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            backgroundColor: 'common.white',
            width: '42px',
            height: '42px',
            boxShadow: shadows.shadowBlack4,
          }}
        >
          <i
            className="fa-regular fa-chevron-down fa-sm"
            style={{
              color: theme.palette.neutral.var70,
            }}
          />
        </Fab>
      ) : null}
    </div>
  );
};

export default MessagesList;
