import camelCase from 'lodash.camelcase';
import { OutgoingMessageStatusMessage, OutgoingMessageStatusMessageKind } from '@engyalo/salesdesk-api-ts-client';
import { TOptions } from 'i18next';

export type MessageDetails = Partial<OutgoingMessageStatusMessage>;

interface PropsForKind {
  iconClass: string;
  translationKey: string;
  translationOptions?: TOptions;
}

export type SpecificKind = Extract<
  OutgoingMessageStatusMessageKind,
  'department-transfer' | 'agent-transfer' | 'queue-transfer'
>;

const specificKindTranslationMap: Record<SpecificKind, (messageDetails: MessageDetails) => TOptions> = {
  'department-transfer': (messageDetails) => ({
    previousDepartment: messageDetails['department-transfer']?.previousDepartment?.name,
    nextDepartment: messageDetails['department-transfer']?.nextDepartment?.name,
  }),
  'agent-transfer': (messageDetails) => ({
    transferedBy: messageDetails['agent-transfer']?.transferedBy?.name,
    transferedTo: messageDetails['agent-transfer']?.transferedTo?.username,
  }),
  'queue-transfer': (messageDetails) => ({
    transferedBy: messageDetails['queue-transfer']?.transferedBy?.name,
    previousDepartment: messageDetails['queue-transfer']?.previousDepartment?.name,
  }),
};

export const isSpecificKind = (key: string): key is SpecificKind => key in specificKindTranslationMap;

export const createMessageOptions = (kind: OutgoingMessageStatusMessageKind, messageDetails: MessageDetails) => {
  const { authorName, authorUsername } = messageDetails;

  const baseProps = {
    name: authorName || authorUsername,
  };

  if (isSpecificKind(kind)) {
    return { ...baseProps, ...specificKindTranslationMap[kind](messageDetails) };
  }

  return baseProps;
};

export const DEFAULT_ICON_CLASS_PER_KIND = 'fa-circle';

export const iconClassPerKind: Record<OutgoingMessageStatusMessageKind, string> = {
  'chat-started': 'fa-comment-dots',
  'chat-closed': 'fa-box-check',
  'chat-on-hold': 'fa-pause-circle',
  'chat-resumed': 'fa-play-circle',
  'department-transfer': 'fa-share',
  'agent-transfer': 'fa-comment-exclamation',
  'queue-transfer': 'fa-share',
};

export const getPropsForKind = (
  kind: OutgoingMessageStatusMessageKind,
  messageDetails: MessageDetails
): PropsForKind => ({
  iconClass: iconClassPerKind[kind] || DEFAULT_ICON_CLASS_PER_KIND,
  translationKey: `messages.status.${camelCase(kind)}`,
  translationOptions: createMessageOptions(kind, messageDetails),
});
