import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuChatsContext from './context';

export type TemplateSelected = {
  dateAdded: object;
  preview: object;
  sharedWith: object;
  template: string;
  _id: string;
};

export type ContactSelected = {
  _id: string;
  name: string;
  phone: string;
  lastChat: string;
  tagsList: string;
};

export interface IConversation {
  openStartNewConversation: boolean;
  handleStartNewConversation: (value: boolean) => void;
  contactSelected?: ContactSelected;
  handleSelectContact: (contact?: ContactSelected) => void;
  templateSelected?: TemplateSelected;
  handleSelectTemplate: (template?: TemplateSelected) => void;
}

const ConversationProvider: React.FC = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [openStartNewConversation, setOpenStartNewConversation] = useState(false);
  const [contactSelected, setContactSelected] = useState<ContactSelected>();
  const [templateSelected, setTemplateSelected] = useState<TemplateSelected>();

  const handleStartNewConversation = (value: boolean) => {
    setOpenStartNewConversation(value);
  };

  const handleSelectContact = (contact?: ContactSelected) => {
    setContactSelected(contact);
  };

  const handleSelectTemplate = (template?: TemplateSelected) => {
    setTemplateSelected(template);
  };

  useEffect(() => {
    if (openStartNewConversation) {
      navigate('/', { replace: true });
    } else {
      setContactSelected(undefined);
      setTemplateSelected(undefined);
    }
  }, [openStartNewConversation]);

  const memoizedValues = useMemo(
    () => ({
      openStartNewConversation,
      handleStartNewConversation,
      contactSelected,
      handleSelectContact,
      templateSelected,
      handleSelectTemplate,
    }),
    [openStartNewConversation, contactSelected, templateSelected]
  );

  return <MenuChatsContext.Provider value={memoizedValues}>{children}</MenuChatsContext.Provider>;
};

export default ConversationProvider;
