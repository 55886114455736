import { AxiosResponse } from 'axios';
import AxiosInstanceBFF from './AxiosInstanceBFF';
import { Department } from './types';

type GetDepartmentsResponse = {
  departments: Department[];
};

export const getDepartments = async (): Promise<AxiosResponse<GetDepartmentsResponse>> =>
  AxiosInstanceBFF.get('v1/business-hours/departments');
