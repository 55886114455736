import { MENU_ITEMS } from '../types.d';
import CannedMessagesManager from '../CannedMessagesManager';
import ChatsManager from '../ChatsManager';
import UsersManager from '../UsersManager';
import SuggestedMessages from '../SuggestedMessages';
import CustomFieldsManager from '../CustomFieldsManager';
import TeamsManager from '../TeamsManager';
import Analytics from '../Analytics';
import TagManager from '../TagManager/index';
import VerifiedTemplatesManager from '../VerifiedTemplatesManager';
import BusinessHours from '../BusinessHours';
import { ISelectedMenuItemProps } from './types';

const SelectedMenuItem = ({ currentItem }: ISelectedMenuItemProps) => {
  const isTagsManager = currentItem === MENU_ITEMS.TAGS_CONTACT || currentItem === MENU_ITEMS.TAGS_ROOM;
  const isCannedMessagesManager = currentItem === MENU_ITEMS.CANNED_MESSAGES;
  const isChatsManager = currentItem === MENU_ITEMS.CONVERSATIONS;
  const isUsersManager = currentItem === MENU_ITEMS.USERS;
  const isCustomFieldsManager = currentItem === MENU_ITEMS.CUSTOM_FIELDS;
  const isTeamsManager = currentItem === MENU_ITEMS.TEAMS;
  const isAnalytics =
    currentItem === MENU_ITEMS.OVERVIEW ||
    currentItem === MENU_ITEMS.SALESDESK_PERFORMANCE ||
    currentItem === MENU_ITEMS.AGENTS_PERFORMANCE ||
    currentItem === MENU_ITEMS.CSAT;
  const isVerifiedTemplates = currentItem === MENU_ITEMS.VERIFIED_TEMPLATES;
  const isChatCopilotAssistant = currentItem === MENU_ITEMS.CHAT_COPILOT_ASSISTANT;
  const isBusinessHours = currentItem === MENU_ITEMS.BUSINESS_HOURS;
  const VISITOR = 'visitor';
  const ROOM = 'room';

  return (
    <>
      {isTagsManager && <TagManager type={currentItem === MENU_ITEMS.TAGS_CONTACT ? VISITOR : ROOM} />}
      {isCannedMessagesManager && <CannedMessagesManager />}
      {isChatsManager && <ChatsManager />}
      {isUsersManager && <UsersManager />}
      {isCustomFieldsManager && <CustomFieldsManager />}
      {isTeamsManager && <TeamsManager />}
      {isAnalytics && <Analytics type={currentItem} />}
      {isVerifiedTemplates && <VerifiedTemplatesManager />}
      {isChatCopilotAssistant && <SuggestedMessages />}
      {isBusinessHours && <BusinessHours />}
    </>
  );
};

export default SelectedMenuItem;
