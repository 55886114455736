import React, { useState } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useEnvironment from '../../../../hooks/useEnvironments';
import { IAttachment } from '../../../../types/message';
import SimpleGallery from '../ModalGallery';
import { ClickableElement, CustomAudio } from './style';
import { MessageListType } from '../type';

interface MediaTypesViewProps {
  username: string;
  attachments: IAttachment[];
  type: MessageListType;
}

export const MediaTypesView = ({ username, attachments, type }: MediaTypesViewProps) => {
  const theme = useTheme();
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  const [showCurrentMedia, setShowCurrentMedia] = useState(false);
  const [url, setUrl] = useState('');
  const handleCurrentMedia = (item: IAttachment | null) => {
    if (item) {
      setUrl(item.video_url || item.image_url || '');
      setShowCurrentMedia(true);
      return;
    }
    setShowCurrentMedia(false);
  };
  const isMobile = useMediaQuery((currentTheme: Theme) => currentTheme.breakpoints.down('md'));

  return (
    <>
      <span>
        {attachments.map((atch: IAttachment) => {
          if (atch.audio_type) {
            return (
              <CustomAudio controls controlsList="nodownload noplaybackrate" key={atch.ts} type={type}>
                <source src={`${baseUrl}${atch.audio_url}`} type={atch.audio_type} />
              </CustomAudio>
            );
          }

          if (atch.image_type) {
            return (
              <ClickableElement key={atch.ts} onClick={() => handleCurrentMedia(atch)}>
                <img src={`${baseUrl}${atch.title_link}`} width="240px" height="170px" alt={atch.title} />
              </ClickableElement>
            );
          }

          if (atch.video_type) {
            return (
              <ClickableElement
                key={atch.ts}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCurrentMedia(atch);
                }}
              >
                <video width="320" height="240" controls>
                  <source src={`${baseUrl}${atch.video_url}`} type={atch.video_type} />
                </video>
              </ClickableElement>
            );
          }

          if (atch.image_type === undefined && atch.audio_type === undefined) {
            return (
              <a
                key={atch.ts}
                target="_blank"
                href={`${baseUrl}${atch.title_link}?download`}
                rel="noreferrer"
                download={atch.title}
                style={{
                  color: theme.palette.neutral.var80,
                  fontWeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i className="fa-regular fa-file" style={{ marginRight: '10px' }} />
                {atch.title}
              </a>
            );
          }

          return null;
        })}
      </span>

      {showCurrentMedia && (
        <SimpleGallery
          username={username}
          url={url}
          onClose={() => handleCurrentMedia(null)}
          type={type}
          isMobile={isMobile}
        />
      )}
    </>
  );
};
