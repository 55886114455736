import { Typography, Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardPropHelper } from '@engyalo/design-system';
import { IMobile } from '../../../types/mobile';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  justify-content: space-between;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
  height: 100%;
`;

export const Header = styled(Stack, {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4.8rem;
  padding: ${({ isMobile }) => (isMobile ? '' : '6px 16px')};
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'space-between')};
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  gap: ${({ isMobile }) => (isMobile ? '8px' : '')};
`;

export const StackTitle = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

export const Main = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.8rem);
  padding: ${({ isMobile }) => (!isMobile ? '40px 36px 48px' : '')};
  box-sizing: border-box;
  gap: ${({ isMobile }) => (isMobile ? '' : '32px')};
  position: relative;
`;

export const SearchContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  .MuiButtonBase-root.MuiIconButton-root {
    color: ${theme.palette.neutral.var40};
  }

  ${theme.breakpoints.down('md')} {
    padding: 16px;
    flex-direction: column;
  }
`
);

export const CancelSelection = styled(Typography)`
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding-bottom: 12px;
`;

export const FiltersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const StackInputsAndButton = styled(Stack)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: -webkit-fill-available;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 8px;
  }
`
);

export const InputContainer = styled('div')(
  ({ theme }) => `
  flex: 1;
  min-width: 150px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`
);

export const DateRangeContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .MuiFormControl-root {
    flex: 1;
    min-width: 150px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const TableContainer = styled('div')`
  display: flex;
  overflow-y: scroll;
  margin-bottom: 48px;
`;

export const Pagination = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  left: 0;
  bottom: 0px;
  width: 100%;

  display: flex;
  padding: ${({ isMobile }) => (!isMobile ? '16px 48px' : '')};
  gap: 24px;
  height: 4.8rem;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
  position: absolute;
`;

export const TabsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  height: 4.8rem;
`;

export const FiltersGroup = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;
