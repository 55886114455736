import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

type Member = {
  _id: string;
  status: string;
  _updatedAt: string;
  name: string;
  username: string;
  nickname: string;
};
interface IGetGroupMembers {
  members: Member[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
}
export const getGroupMembers = async (rid: string): Promise<AxiosResponse<IGetGroupMembers>> =>
  AxiosInstance.get(`groups.members?roomId=${rid}`);
