import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export interface ISaveRoomInfo {
  chatId: string;
  contactId: string;
  tags: string[];
  uuid?: string;
}

interface IResponseSaveRoomInfo {
  message: string;
  success: boolean;
}

export const saveRoomInfo = async (props: ISaveRoomInfo): Promise<AxiosResponse<IResponseSaveRoomInfo>> => {
  const { chatId, contactId, tags, uuid } = props;
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuid,
      method: 'livechat:saveInfo',
      params: [
        {
          _id: contactId,
        },
        {
          _id: chatId,
          topic: '',
          tags,
        },
      ],
    }),
  };

  return AxiosInstance.post('method.call/livechat:saveInfo', params);
};
