export const stringToColor = (stringName: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < stringName.length; i += 1) {
    hash = stringName.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substring(-2);
  }
  return color;
};
