import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstanceGAI';

interface IAssistantMessage {
  role: 'user' | 'agent';
  text: string;
}

interface IAssistantParams {
  collectionId: string;
  userQuestion: string;
  conversation: {
    messages: IAssistantMessage[];
  };
}

interface IAssistantResponse {
  suggested_reply: string;
}

export const saveChatSuggestedMessages = async (params: IAssistantParams): Promise<AxiosResponse<IAssistantResponse>> =>
  AxiosInstance.post('salesdesk/v1/conversation/reply', {
    collection_id: params.collectionId,
    user_question: params.userQuestion,
    conversation: params.conversation,
  });
