import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { MessageType } from 'react-idle-timer';
import { stringAvatar } from '../../../../helpers/stringAvatar';
import { HeaderMessageTitle, HeaderMessageTime, ErroMessageTryAgain } from './style';
import ChatsContext from '../../../../contexts/chats/context';
import { IMessageHeader } from './types';
import AuthContext from '../../../../contexts/auth/context';
import useEnvironment from '../../../../hooks/useEnvironments';

export const MessageHeaderUserInfo = ({
  isUserlogged,
  itemMsg,
  currentDate,
  errorSendMessage,
  isbot,
  isAnotation,
}: IMessageHeader) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { basicContactInfo, discussionOpened, handleTrySendAgainMessage, serviceSelected } = useContext(ChatsContext);
  const { currentUser, currentUserInfo } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');

  const checkAvatarName = useCallback(() => {
    const nameKey = ['name', 'username', 'from'].find((key) => itemMsg[key as keyof MessageType]) as keyof MessageType;

    const avatarName = nameKey ? itemMsg[nameKey] : itemMsg.u.name;

    return stringAvatar(avatarName);
  }, [itemMsg]);

  const avatarName = checkAvatarName();

  useEffect(() => {
    let headerName = '';
    if (isAnotation && itemMsg.transferData && itemMsg.transferData?.transferredBy) {
      headerName = itemMsg.transferData?.transferredBy?.name;
    } else if (!discussionOpened && itemMsg.userId === currentUser.userId) {
      headerName = currentUserInfo.name;
    } else if (!basicContactInfo || basicContactInfo?._id === itemMsg?.u?._id) {
      if (basicContactInfo && serviceSelected?.v?._id === basicContactInfo._id) {
        headerName = basicContactInfo.name;
      } else {
        const nameKey = ['name', 'username', 'from'].find(
          (key) => itemMsg[key as keyof MessageType]
        ) as keyof MessageType;

        headerName = nameKey ? itemMsg[nameKey] : itemMsg.u.name;
      }
    } else {
      headerName = itemMsg.from;
    }
    setName(headerName);
    const defaultAvatarURL = `${baseUrl}/avatar/${itemMsg.u.username}`;

    setAvatarUrl(defaultAvatarURL);
  }, [basicContactInfo, currentUserInfo]);

  return (
    <div>
      {errorSendMessage ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            paddingTop: '16px',
            paddingBottom: '8px',
          }}
        >
          <i
            className="fa-regular fa-triangle-exclamation"
            style={{
              color: theme.palette.error.main,
              marginRight: '8px',
            }}
          />

          <HeaderMessageTitle variant="subtitle2" color="error.main" data-testid="headerMessageTitleError">
            {t('errorSendMessage')}
          </HeaderMessageTitle>
          <ErroMessageTryAgain
            data-testid="retrySendMessage"
            onClick={() => handleTrySendAgainMessage()}
            variant="subtitle2"
            color="error.main"
          >
            {t('tryAgain')}
          </ErroMessageTryAgain>
        </div>
      ) : (
        <div
          style={{
            display: isAnotation ? 'none' : 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            paddingBottom: '8px',
            paddingTop: '16px',
          }}
        >
          <div style={{ display: 'flex', marginRight: '20px' }}>
            {isbot || isUserlogged ? (
              <Avatar
                src={avatarUrl}
                sx={{
                  bgcolor: itemMsg.color,
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                  fontSize: ' 80%',
                }}
                variant={isbot ? 'square' : 'circular'}
              >
                {avatarName.children}
              </Avatar>
            ) : null}
            <HeaderMessageTitle variant="subtitle2" data-testid="headerMessageTitle" color="neutral.var60">
              {name}
            </HeaderMessageTitle>
          </div>
          <HeaderMessageTime variant="overline" color="neutral.var60">
            {currentDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </HeaderMessageTime>
        </div>
      )}
    </div>
  );
};
