import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { managementRoles } from 'constants/roles';
import {
  Container,
  FormCustom,
  FooterButton,
  Span,
  ItemForm,
  Header,
  BackButton,
  OptionalLabel,
  ListItemSelect,
  helperTextStyle,
} from './style';
import { ReactComponent as Loading } from '../../../../assets/icons/loadingYalo.svg';
import LoadingAnimation from '../../../../components/AnimationLoading';
import EditTags from '../../../../components/Tags/EditTags';
import usePermissions from '../../../../hooks/usePermissions';
import GenericAutocomplete from '../../../../components/Autocomplete';
import ModalError from '../../../../components/ErrorModal';
import useNewContact from './useNewContact';
import { ModalEdit } from '../../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import { SelectView } from '../../../../components/SelectView/SelectView';
import { countryCodes } from '../../../../constants/countryCodes';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';

export interface ICreateContactManager {
  handleOpenNewUser: () => void;
  sendNotification?: boolean;
}

const FormNewContact: React.FC<ICreateContactManager> = (props: ICreateContactManager) => {
  const theme = useTheme();
  const { handleOpenNewUser, sendNotification } = props;
  const { t } = useTranslation();

  const { checkPermission } = usePermissions();
  const isManagerOrSupervisor = checkPermission(managementRoles);
  const {
    handleSubmit,
    onSubmit,
    agents,
    control,
    errors,
    isValid,
    loadingRequest,
    customFields,
    loading,
    showModalError,
    setShowErrorConnectionModal,
    isDirty,
    showModalChanges,
    setShowModalChanges,
    setValue,
    checkField,
  } = useNewContact({ handleOpenNewUser, sendNotification });

  const renderValue = useCallback(
    (value: unknown) => {
      const countryCode = countryCodes.find((current) => current.dial_code === String(value));
      if (countryCode) {
        return (
          <Box style={{ display: 'flex', gap: '4px' }}>
            <Span
              variant="caption2"
              className={`fi fi-${countryCode.code.toLocaleLowerCase()}`}
              style={{ lineHeight: '16px', backgroundSize: 'unset' }}
            />
            <Typography variant="body1" sx={{ lineHeight: '16px' }}>
              {countryCode.dial_code}
            </Typography>
          </Box>
        );
      }
      return null;
    },
    [countryCodes]
  );

  return (
    <Container data-testid="formNewContact">
      <Header>
        <BackButton
          onClick={() => {
            if (isDirty) {
              setShowModalChanges(isDirty);
              return;
            }
            handleOpenNewUser();
          }}
        >
          <i className="fa-solid fa-arrow-left" />
        </BackButton>
        <Typography variant="subtitle2" color="neutral.var90">
          {t('newContact')}
        </Typography>
      </Header>
      {loading ? (
        <Box alignSelf="center">
          <Loading />
        </Box>
      ) : (
        <FormCustom data-testid="contactForm" onSubmit={handleSubmit(onSubmit)}>
          <Stack padding="16px" gap="32px" sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <ItemForm>
              <Span variant="caption2">{t('name')}</Span>

              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: '100%' }}
                    error={!!errors.name}
                    helperText={t((errors.name && (errors.name.message as unknown as string)) ?? '')}
                    inputProps={{ 'data-testid': 'contactName' }}
                  />
                )}
              />
            </ItemForm>

            <ItemForm>
              <Span variant="caption2">{t('phone')}</Span>
              <Stack direction="row" gap="5px">
                <Controller
                  name="countryCode"
                  control={control}
                  defaultValue="+55"
                  render={({ field }) => {
                    const { onChange, value } = field;
                    return (
                      <TextField
                        {...field}
                        value={value}
                        onChange={(event: any) => {
                          onChange(event.target.value);
                        }}
                        select
                        defaultValue="+55"
                        sx={{
                          minWidth: '9rem',
                          minHeight: '36px',
                          maxHeight: '36px',
                        }}
                        SelectProps={{ renderValue }}
                      >
                        {countryCodes.map((el: any, idx: number) => (
                          <MenuItem key={`${idx}countryCodeItem`} value={el.dial_code} sx={{ fontSize: '14px' }}>
                            <Span variant="caption2" className={`fi fi-${el.code.toLocaleLowerCase()}`} />
                            {el.dial_code}
                          </MenuItem>
                        ))}
                      </TextField>
                    );
                  }}
                />

                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onBlur={() => checkField('phone')}
                      error={!!errors.phone}
                      sx={{ width: '100%', minWidth: 'unset' }}
                      helperText={t((errors.phone && (errors.phone.message as unknown as string)) ?? '')}
                      inputProps={{ 'data-testid': 'contactPhone' }}
                    />
                  )}
                />
              </Stack>
            </ItemForm>
            {customFields.map((ctmField: any, index: number) => {
              const { _id: id, label, type, required, options } = ctmField;

              if (type === 'input' && id !== 'tags') {
                return (
                  <ItemForm key={`${index}input`}>
                    <Span variant="caption2" sx={{ marginTop: '0px' }}>
                      {capitalizeFirstLetter(label)}
                      {!required && <OptionalLabel variant="caption2">{`(${t('optional')})`} </OptionalLabel>}
                      {id === 'documentNumber' && (
                        <Tooltip title={`${t('documentNumberTooltip')}`}>
                          <i
                            className="fa-solid fa-circle-question"
                            style={{
                              color: theme.palette.neutral.var50,
                              marginLeft: 3,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Span>

                    <Controller
                      name={id}
                      control={control}
                      defaultValue=""
                      render={({ field }) => {
                        const { onChange, name, value } = field;
                        return (
                          <TextField
                            error={!!errors[id]}
                            sx={{ width: '100%' }}
                            helperText={t((errors[id]?.message && (errors[id]?.message as unknown as string)) ?? '')}
                            name={name}
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }

              if (type === 'input' && id === 'tags') {
                return (
                  <ItemForm key={`${index}input-tags`}>
                    <Span variant="caption2" sx={{ marginTop: '0px' }}>
                      {capitalizeFirstLetter(label)}
                      <OptionalLabel variant="caption2">{`(${t('optional')})`} </OptionalLabel>

                      {id === 'tags' && (
                        <Tooltip title={`${t('tagsTooltip')}`}>
                          <i
                            className="fa-solid fa-circle-question"
                            style={{
                              color: theme.palette.neutral.var50,
                              marginLeft: 3,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Span>
                    <Controller
                      name={id}
                      control={control}
                      defaultValue=""
                      render={({ field }) => {
                        const { onChange, value } = field;

                        return (
                          <EditTags
                            placeholder={t('selectContactTags')}
                            type="visitor"
                            editTag={isManagerOrSupervisor}
                            createTag={isManagerOrSupervisor}
                            handleChange={(selectTags: string) => {
                              if (selectTags !== value) {
                                onChange(selectTags);
                              }
                            }}
                            currentTags={value}
                            open={false}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }

              if (type === 'select') {
                const optionsAux = options.split(',');
                optionsAux.pop();

                return (
                  <ItemForm key={`${index}select`}>
                    <Span variant="caption2"> {capitalizeFirstLetter(label)}</Span>
                    <Controller
                      name={id}
                      control={control}
                      defaultValue=""
                      render={({ field }) => {
                        const { onChange, value } = field;
                        return (
                          <SelectView
                            field={field}
                            value={value}
                            setValue={setValue}
                            id={id}
                            onChange={onChange}
                            optionsAux={optionsAux}
                            error={!!errors[id]}
                            FormHelperTextProps={{
                              style: helperTextStyle,
                            }}
                            helperText={t((errors[id]?.message && (errors[id]?.message as unknown as string)) ?? '')}
                          />
                        );
                      }}
                    />
                  </ItemForm>
                );
              }
            })}

            <ItemForm>
              <Span variant="caption2">
                {t('agentResponsibleForThisContact')}{' '}
                <OptionalLabel variant="caption2">{`(${t('optional')})`} </OptionalLabel>
                <Tooltip title={`${t('agentResponsibleTooltip')}`}>
                  <i
                    className="fa-solid fa-circle-question"
                    style={{
                      color: theme.palette.neutral.var50,
                      marginLeft: 3,
                    }}
                  />
                </Tooltip>
              </Span>

              <Controller
                name="agentResponsibleForThisContact"
                control={control}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <GenericAutocomplete
                      disableCloseOnSelect
                      defaultValue={value}
                      placeholder={t('selectOneOption')}
                      renderOption={(props, option) => (
                        <ListItemSelect data-testid="agentResponsibleListItem" disablePadding {...props}>
                          {option.name}
                        </ListItemSelect>
                      )}
                      handleChange={(value) => {
                        onChange(value?._id);
                      }}
                      limitTagsNumber={1}
                      multiple={false}
                      options={agents}
                    />
                  );
                }}
              />
            </ItemForm>
          </Stack>
          <FooterButton>
            <Button
              disabled={!isValid || loadingRequest || !isDirty}
              type="submit"
              fullWidth
              variant="contained"
              color="neutral2"
            >
              <>
                {loadingRequest ? (
                  <LoadingAnimation color={theme.palette.neutral.var90} />
                ) : (
                  <i
                    className="fa-solid fa-check"
                    style={{
                      color:
                        !isValid || loadingRequest || !isDirty
                          ? theme.palette.neutral.var40
                          : theme.palette.neutral.var90,
                    }}
                  />
                )}
                {t('createContact')}
              </>
            </Button>
          </FooterButton>
        </FormCustom>
      )}

      {showModalError && (
        <ModalError
          handleClose={() => setShowErrorConnectionModal(!showModalError)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTypography')}
          open={showModalError}
        />
      )}

      {showModalChanges && (
        <ModalEdit
          open={showModalChanges}
          errorUpdt={false}
          handleModal={() => setShowModalChanges(!showModalChanges)}
          handleEditing={handleOpenNewUser}
        />
      )}
    </Container>
  );
};

export default FormNewContact;
