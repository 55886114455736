import React from 'react';
import { flag } from 'hocs/WithFeatureFlag';
import { ISidebarItems, MENU_ITEMS } from './types.d';

export const SideItems: Array<ISidebarItems> = [
  {
    key: MENU_ITEMS.USERS,
    name: 'Usuários',
    icon: <i className="fa-regular fa-user-headset" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.TEAMS,
    name: 'Equipes',
    icon: <i className="fa-regular fa-users" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.CONVERSATIONS,
    name: 'Conversas',
    icon: <i className="fa-regular fa-comments" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.CUSTOM_FIELDS,
    name: 'Campos personalizados',
    icon: <i className="fa-solid fa-input-text" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.CANNED_MESSAGES,
    name: 'Mensagens modelo',
    icon: <i className="fa-regular fa-square-bolt" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.TAGS,
    name: 'Etiquetas',
    icon: <i className="fa-regular fa-tags" />,
    permission: '',
    subItems: [
      { key: 'tagsContact', name: 'Etiquetas de contato' },
      { key: 'tagsRoom', name: 'Etiquetas de conversa' },
    ],
  },
  {
    key: MENU_ITEMS.ANALYTICS,
    name: 'Analytics',
    icon: <i className="fa-solid fa-chart-mixed" />,
    permission: '',
    subItems: [
      { key: 'overview', name: 'Overview' },
      { key: 'salesdesk_performance', name: 'Team performance' },
      { key: 'agents_performance', name: 'Agent performance' },
      { key: 'csat_salesdesk', name: 'CSAT' },
    ],
  },
  {
    key: MENU_ITEMS.VERIFIED_TEMPLATES,
    name: 'Templates verificados',
    icon: <i className="fa-regular fa-badge-check" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.CHAT_COPILOT_ASSISTANT,
    featureFlagName: flag.CHAT_COPILOT_ASSISTANT,
    name: 'Suggested Messages',
    icon: <i className="fa-solid fa-lightbulb" />,
    permission: '',
    subItems: [],
  },
  {
    key: MENU_ITEMS.BUSINESS_HOURS,
    featureFlagName: flag.BUSINESS_HOURS,
    name: 'Business hours',
    icon: <i className="fa-regular fa-clock" />,
    permission: '',
    subItems: [],
  },
];
