import * as Yup from 'yup';
import { SchemaOf } from 'yup';

interface ISchemaValidator {
  username: string;
  password: string;
}

export const schemaLogin: SchemaOf<ISchemaValidator> = Yup.object({
  username: Yup.string().required('messageErrorEmptyEmail'),
  password: Yup.string().required('messageErrorEmptyPassword'),
}).required();

interface ISchemaValidatorReset {
  username: string;
}
export const schemaLoginReset: SchemaOf<ISchemaValidatorReset> = Yup.object({
  username: Yup.string().required('messageErrorEmptyEmail'),
}).required();
