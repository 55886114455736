import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button } from '@engyalo/design-system';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  setSchedule,
  clearSchedule,
  setDepartments,
  setScheduleList,
  setIsLoading,
} from 'redux/reducers/businessHoursReducer';
import { getDepartments } from 'services/getDepartments';
import { getBusinessHoursSchedules } from 'services/getBusinessHoursSchedules';
import DialogConfirmation from 'components/DialogConfirmation';
import { STORAGE_KEYS } from 'constants/defaultValues';
import AlertsContext, { Severity } from 'contexts/alerts/context';
import { deleteBusinessHoursSchedules } from 'services/deleteBusinessHours';
import { patchBusinessHoursSchedule } from 'services/patchBusinessHoursSchedule';
import { logError } from 'services/Logger';
import { ISchedule, EXPECTED_HTTP_RESPONSE } from './types';
import { Container, HeaderContainer, Header, HeaderActions } from './styles';
import ScheduleTable from './ScheduleTable';
import BusinessHoursSettings from './BusinessHoursSettings/BusinessHoursSettings';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { CONFIRM_MODAL_TYPE } from './ConfirmModal/types';

const BusinessHours: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { addAlert } = useContext(AlertsContext);
  const { scheduleList, selectedSchedule, isLoading } = useAppSelector((state) => state.businessHours);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [confirmType, setConfirmType] = useState<CONFIRM_MODAL_TYPE>();
  const [scheduleId, setScheduleId] = useState<string>('');
  const [scheduleIsEnabled, setScheduleIsEnabled] = useState<boolean>(false);

  const getDepartmentsData = async () => {
    const { data } = await getDepartments();
    dispatch(setDepartments(data?.departments || []));
  };

  const onCreate = async () => {
    setIsOpenDrawer(true);
    dispatch(clearSchedule());
    getDepartmentsData();
  };

  const onEdit = async (schedule: ISchedule) => {
    dispatch(clearSchedule());
    const { workHours } = schedule;
    const days = workHours.map((day) => day.dayOfWeek);
    dispatch(
      setSchedule({
        ...schedule,
        days,
      })
    );
    getDepartmentsData();
    setIsOpenDrawer(true);
  };

  const handleCloseDialogDelete = () => setIsOpenDialogDelete(!isOpenDialogDelete);
  const handleOpenDialogDelete = () => setIsOpenDialogDelete(true);
  const handleCloseConfirmModal = () => setIsOpenConfirmModal(!isOpenConfirmModal);

  const onDelete = (schedule: ISchedule) => {
    dispatch(setSchedule(schedule));
    handleOpenDialogDelete();
  };

  const onToggleEnabled = (schedule: ISchedule, enable: boolean) => {
    const newConfirmType = enable ? CONFIRM_MODAL_TYPE.enable : CONFIRM_MODAL_TYPE.disable;
    setConfirmType(newConfirmType);
    dispatch(setSchedule(schedule));
    setIsOpenConfirmModal(true);
    setScheduleId(schedule.id);
    setScheduleIsEnabled(enable);
  };

  const getData = useCallback(async () => {
    dispatch(setIsLoading(true));
    try {
      const response = await getBusinessHoursSchedules();
      dispatch(setScheduleList(response.data || []));
    } catch (error) {
      // Implement Error logic
      logError('error', error);
    } finally {
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  const onSubmitted = (schedule: ISchedule) => {
    getData();
    const { id, name } = schedule;
    const isEditing = !!id;
    const message = isEditing
      ? t('business-hours.alert.update-success', { name })
      : t('business-hours.alert.create-success', { name });
    addAlert({ message, severity: Severity.SUCCESS });
  };

  const deleteSchedule = async (schedule: ISchedule) => {
    dispatch(setIsLoading(true));
    const userName = localStorage.getItem(STORAGE_KEYS.USERNAME) || '';
    const { name } = schedule;
    try {
      const response = await deleteBusinessHoursSchedules(schedule.id, userName);
      if (response.status === EXPECTED_HTTP_RESPONSE.NoContent) {
        getData();
        addAlert({ message: t('business-hours.alert.delete-success', { name }), severity: Severity.SUCCESS });
      }
    } catch (error) {
      // Implement Error logic
      logError('error', error);
    } finally {
      dispatch(setIsLoading(false));
      handleCloseDialogDelete();
    }
  };

  const onConfirmDelete = async () => {
    deleteSchedule(selectedSchedule);
  };

  const onConfirmToggleEnabled = async (schedule: ISchedule, type: CONFIRM_MODAL_TYPE) => {
    dispatch(setIsLoading(true));
    const isEnabled = type === CONFIRM_MODAL_TYPE.enable;
    const { name } = schedule;
    const message = isEnabled
      ? t('business-hours.alert.enable-success', { name })
      : t('business-hours.alert.disable-success', { name });

    try {
      await patchBusinessHoursSchedule(scheduleIsEnabled, scheduleId);
      handleCloseConfirmModal();
      getData();
      addAlert({ message, severity: Severity.SUCCESS });
    } catch (error) {
      logError('error', error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <Typography variant="h2">{t('business-hours.title')}</Typography>
          <Typography variant="body1" color="text.secondary">
            {t('business-hours.description')}
          </Typography>
        </Header>
        <HeaderActions>
          <Button variant="outlined" size="small" onClick={onCreate}>
            {t('business-hours.actions.new-schedule')}
          </Button>
        </HeaderActions>
      </HeaderContainer>
      <ScheduleTable
        loading={isLoading}
        schedules={scheduleList}
        onEdit={onEdit}
        onDelete={onDelete}
        onToggleEnabled={onToggleEnabled}
      />
      <BusinessHoursSettings open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} onSubmitted={onSubmitted} />
      <DialogConfirmation
        open={isOpenDialogDelete}
        onCancel={handleCloseDialogDelete}
        onConfirm={onConfirmDelete}
        title={t('business-hours.dialog.delete-schedule.title')}
        description={t('business-hours.dialog.delete-schedule.description')}
      />
      <ConfirmModal
        type={confirmType}
        open={isOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={onConfirmToggleEnabled}
      />
    </Container>
  );
};

export default BusinessHours;
