/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { List, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuickMessagesContainer, ListItem, ShortcutMessage, Tags } from 'views/home/Chat/QuickMessages/style';

import CannedMessagesContext from 'contexts/cannedMessages/context';
import { CustomChip } from 'components/Tags/style';
import InputSearch from 'components/InputSearch';
import { useChatStore } from 'redux/reducers/chatReducer';
import { useAppSelector } from 'hooks/redux';
import { selectChatSelectedCannedMessages } from 'redux/selectors';

const QuickMessages: React.FC<{ isTabsMode?: boolean }> = ({ isTabsMode = false }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');

  const {
    filteredCannedMessages,
    handleCannedMessageSelected,
    handleMessageOnHover,
    cannedMessagesReferences,
    cursor,
    handleCursor,
    filterCannedMessages,
    handleMessageSelection,
    serviceSelected,
  } = useContext(CannedMessagesContext);
  const selectedCannedMessages = useAppSelector(selectChatSelectedCannedMessages);
  const { setSelectedCannedMessages } = useChatStore();

  const keyHandler = React.useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === 'ArrowDown') {
        const finalCursor = cursor !== filteredCannedMessages.length - 1 ? cursor + 1 : 0;

        handleMessageOnHover(filteredCannedMessages[finalCursor].text);
        handleCursor(finalCursor);
      } else if (key === 'ArrowUp') {
        const finalCursor = cursor !== 0 ? cursor - 1 : filteredCannedMessages.length - 1;

        handleMessageOnHover(filteredCannedMessages[finalCursor].text);
        handleCursor(finalCursor);
      } else if (key === 'Enter') {
        handleMessageSelection(true);
      } else if (key === 'Tab') {
        handleMessageSelection(true);
      }
    },
    [cursor, filteredCannedMessages, handleCursor, handleMessageOnHover, handleMessageSelection]
  );

  useEffect(() => {
    window.addEventListener('keyup', keyHandler);
    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  }, [cursor, keyHandler]);

  return (
    <QuickMessagesContainer data-testid="quickMessages" isTabsMode={isTabsMode}>
      {!isTabsMode && (
        <Typography variant="subtitle2" color="neutral.var90" margin="16px">
          {t('quickMessages')}
        </Typography>
      )}
      <InputSearch
        placeholder="search"
        value={inputText}
        onChange={(value) => {
          filterCannedMessages(value);
          setInputText(value);
        }}
      />

      <List sx={{ overflowY: 'scroll', maxHeight: '200px' }} disablePadding>
        {filteredCannedMessages.length ? (
          filteredCannedMessages.map((quickMsg, idx: number) => (
            <ListItem
              ref={cannedMessagesReferences[idx]}
              tabIndex={0}
              key={uuidv4()}
              disablePadding
              onClick={() => {
                handleCannedMessageSelected(quickMsg.text, true);
                setSelectedCannedMessages({
                  ...selectedCannedMessages,
                  [serviceSelected._id]: quickMsg,
                });
              }}
            >
              <Stack direction="row" alignItems="baseline" sx={{ gap: '10px' }}>
                <Typography id="shortcut-id" variant="body2" color="neutral.var70">
                  {quickMsg.shortcut}
                </Typography>

                <Tags style={{ gap: '5px', flexWrap: 'wrap' }}>
                  {quickMsg.customTags?.map((el) => (
                    <CustomChip key={uuidv4()} label={el.tag} customcolor={el.customColor} />
                  ))}
                </Tags>
              </Stack>
              <ShortcutMessage variant="body1">{quickMsg.text}</ShortcutMessage>
            </ListItem>
          ))
        ) : (
          <Stack sx={{ padding: '26px 16px' }}>
            <Typography variant="body2" color="neutral.var90">
              {t('withoutResults')}
            </Typography>
            <Typography variant="body1" color="neutral.var70">
              {t('withoutResultsMessage')}
            </Typography>
          </Stack>
        )}
      </List>
    </QuickMessagesContainer>
  );
};

export default QuickMessages;
