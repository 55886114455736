import { styled } from '@mui/material/styles';

export const MediaFallback = styled('div')`
  position: relative;
  width: 18rem;
  height: 18rem;
  padding: 1.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  border-radius: 0.8rem;
  font-size: 1.5rem;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.var20};
  }

  i {
    font-size: 3.5rem;
    margin-bottom: 0.8rem;
    color: ${({ theme }) => theme.palette.neutral.var50};
  }
`;
