import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { STORAGE_KEYS } from '../../constants/defaultValues';
import { tagsColor } from '../../helpers/tagsColors';
import { getAgentsByDepartments } from '../../services/getAgentsByDepartments';
import { getDepartments } from '../../services/getDepartments';
import { getListTags } from '../../services/getTags';
import { TagsType } from '../../types/tags';
import AuthContext from '../auth/context';
import TransferContext from './context';
import { Department } from '../../services/types';
import { getAvailableUsers } from '../../services/getAvailableUsers';
import { UserStatus } from '../../types/user';

export type IAgentOfDepartment = {
  name: string;
  _id: string;
  status: UserStatus;
  username: string;
  departmentId?: string;
};

const TransferProvider: React.FC = (props) => {
  const { children } = props;

  const [allDepartments, setAllDepartments] = useState<Department[]>([]);
  const [tags, setTags] = useState<Array<TagsType>>([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isReturnToQueue, setIsReturnToQueue] = useState(false);
  const [loading, setLoading] = useState(true);
  const { getCurrentUserId, currentUserInfo } = useContext(AuthContext);
  const [AllAgentsOfDepartment, setAllAgentsOfDepartment] = useState<IAgentOfDepartment[]>([]);
  const [pagination, setPagination] = useState({ count: 100, offset: 0 });
  const [userId, setUserId] = useState('');
  const botDptName = STORAGE_KEYS.BOT_DEPARTMENT_NAME;

  const loadTags = useCallback(async () => {
    const { data } = await getListTags();
    const { tags, total } = data;
    if (!tags) {
      return;
    }
    const mappedTags = tags
      .filter((item: TagsType) => {
        if (item.description === 'room') {
          return item;
        }
      })
      .map((item: TagsType) => {
        item.customColor = tagsColor(item.name);
        return item;
      });

    const newArray = [...tags, ...mappedTags];
    if (pagination.count < total) {
      setPagination((old) => ({
        ...old,
        offset: tags.length,
        count: pagination.count + tags.length,
      }));

      setTags(newArray);
    }
  }, [pagination.count]);

  const loadDepartments = useCallback(
    async (departmentId: string) => {
      const {
        data: { departments },
      } = await getDepartments();

      const finalDepartments = departments.filter((el) => el._id !== departmentId && el.name !== botDptName);

      setAllDepartments(finalDepartments);

      setLoading(false);
    },
    [currentUserInfo.roles]
  );

  const loadAgents = useCallback(
    async (departmentId: string) => {
      try {
        const {
          data: { agents },
        } = await getAgentsByDepartments(departmentId);
        if (agents.length > 0) {
          const agentUsernames: string[] = agents.map((item) => item.username);
          const { data } = await getAvailableUsers({ agentUsernames, loggedUser: userId, statusLivechat: 'available' });
          const availableUsers = data.items;

          const list = availableUsers.map((availableUser) => {
            const userInfo = agents.find((user) => user?._id !== userId);
            const agentUser = {
              name: availableUser.name,
              _id: availableUser._id,
              status: availableUser.status,
              username: availableUser.username,
              departmentId: userInfo?.departmentId,
            };

            return agentUser;
          });

          setAllAgentsOfDepartment(list);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [userId]
  );

  const checkIfHasAgentsAvailable = useCallback(
    async (departmentId: string) => {
      let hasAvailableAgents = false;
      if (userId) {
        const {
          data: { items },
        } = await getAvailableUsers({ loggedUser: userId, statusLivechat: 'available' });

        const {
          data: { agents },
        } = await getAgentsByDepartments(departmentId);

        if (agents.length) {
          hasAvailableAgents = agents.some((agent) => items.find((item) => item.username === agent.username));
        }
      }
      return hasAvailableAgents;
    },
    [userId]
  );

  const handleSetSelectedDepartment = useCallback((departmentId: string) => {
    setSelectedDepartment(departmentId);
  }, []);

  const handleSetSelectedAgent = useCallback((agentId: string) => {
    setSelectedAgent(agentId);
  }, []);

  const clearSelectedsTransfer = useCallback(() => {
    setSelectedDepartment('');
    setSelectedAgent('');
  }, []);

  const handleReturnToQueue = useCallback(() => {
    setIsReturnToQueue((oldState) => !oldState);
  }, []);

  useEffect(() => {
    loadTags();
  }, [pagination]);

  useEffect(() => {
    const id = getCurrentUserId();
    if (id) {
      setUserId(id);
    }
  }, []);

  const memoizedValuesTransferProvider = useMemo(
    () => ({
      tags,
      loadTags,
      loadAgents,
      loadDepartments,
      allDepartments,
      AllAgentsOfDepartment,
      checkIfHasAgentsAvailable,
      handleSetSelectedDepartment,
      selectedDepartment,
      selectedAgent,
      handleSetSelectedAgent,
      clearSelectedsTransfer,
      handleReturnToQueue,
      isReturnToQueue,
      loading,
    }),
    [
      tags,
      loadTags,
      loadAgents,
      loadDepartments,
      allDepartments,
      AllAgentsOfDepartment,
      checkIfHasAgentsAvailable,
      handleSetSelectedDepartment,
      selectedDepartment,
      selectedAgent,
      handleSetSelectedAgent,
      clearSelectedsTransfer,
      handleReturnToQueue,
      isReturnToQueue,
      loading,
    ]
  );
  return <TransferContext.Provider value={memoizedValuesTransferProvider}>{children}</TransferContext.Provider>;
};

export default TransferProvider;
