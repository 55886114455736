import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Button } from '@engyalo/design-system';

import { useTranslation } from 'react-i18next';
import { MainText } from './style';
import GenericModal from '../Modal';
import ButtonsRow from '../ButtonsStack';
import { STORAGE_KEYS } from '../../constants/defaultValues';

const THIRTY_MIN_IN_MS = '1800000';

export const TimeOutModal: React.FC = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const timeoutLocalStorage = parseInt(localStorage.getItem(STORAGE_KEYS.TIMEOUT) ?? THIRTY_MIN_IN_MS, 10);

  const [timeout, setTimeout] = useState(timeoutLocalStorage);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const { getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (isIdle) {
      setOpenModal(true);
    }
  }, [isIdle]);

  return (
    <>
      {openModal && (
        <GenericModal open={openModal} title={`${t('messageTimeoutTitle')}`}>
          <MainText variant="body1" color="neutral.var100">
            {t('messageTimeout')}
          </MainText>
          <ButtonsRow justifyContent="space-around">
            <Button onClick={() => setOpenModal(false)}>{t('buttonCloseTimeout')}</Button>
          </ButtonsRow>
        </GenericModal>
      )}
    </>
  );
};
