import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { PasswordRulesContainer, PasswordRuleText } from './style';
import { IPasswordRulesProps } from './types';

const PasswordRules: React.FC<IPasswordRulesProps> = (props) => {
  const theme = useTheme();

  const { watch, getFieldState } = props;
  const { t } = useTranslation();
  const passwordRequiredRules = [
    { key: 'minSixcharacters', value: /^.{6,}$/ },
    { key: 'uppercaseLetter', value: /[A-Z]/ },
    { key: 'lowercaseLetter', value: /[a-z]/ },
    { key: 'numberCharacter', value: /[0-9]/ },
  ];
  const handleValidatePassword = (pattern: RegExp) => {
    const values = watch('password');
    const tested = pattern.test(values || '');
    return tested;
  };

  return (
    <PasswordRulesContainer>
      {passwordRequiredRules.map((item, index) => {
        const isValid = handleValidatePassword(item.value);
        const hasError = !!getFieldState('password').error;
        return (
          <PasswordRuleText hasError={!isValid && hasError} isValid={isValid} key={index}>
            {!isValid && hasError ? (
              <i className="fa-regular fa-circle-exclamation fa-xs" style={{ color: theme.palette.error.main }} />
            ) : (
              <i
                className="fa-regular fa-check fa-xs"
                style={{
                  color: isValid ? theme.palette.success.main : theme.palette.neutral.var60,
                }}
              />
            )}
            {t(item.key)}
          </PasswordRuleText>
        );
      })}
    </PasswordRulesContainer>
  );
};

export default PasswordRules;
