/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'lodash.debounce';
import { DependencyList, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

const DEBOUNCE_DELAY_MS = 300;
const SCROLL_THRESHOLD = 5;

interface UseScrollToBottomProps {
  containerRef: RefObject<HTMLElement>;
  deps?: DependencyList;
}

const useScrollToBottom = ({ containerRef, deps = [] }: UseScrollToBottomProps) => {
  const prevContainerRef = useRef<HTMLElement | null>(null);
  const [visible, setVisible] = useState(false);

  const scrollToBottom = useCallback(
    ({ smooth }: { smooth: boolean }) => {
      if (containerRef.current) {
        const position = containerRef.current.scrollHeight - containerRef.current.clientHeight;

        containerRef.current.scrollTo({
          top: position,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }
    },
    [containerRef.current]
  );

  const handleContainerScroll = useCallback(
    debounce(() => {
      if (containerRef.current) {
        const scrollPosition = containerRef.current.scrollTop + containerRef.current.clientHeight;
        const { scrollHeight } = containerRef.current;

        const isAtBottom = Math.abs(scrollHeight - scrollPosition) <= SCROLL_THRESHOLD;

        setVisible(!isAtBottom);
      }
    }, DEBOUNCE_DELAY_MS),
    [containerRef.current]
  );

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', handleContainerScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleContainerScroll);
    };
  }, [containerRef.current, handleContainerScroll]);

  useLayoutEffect(() => {
    const isSameContainer = prevContainerRef.current === containerRef.current;

    if (!visible || !isSameContainer) {
      scrollToBottom({ smooth: isSameContainer });
    }

    prevContainerRef.current = containerRef.current;
  }, [visible, scrollToBottom, containerRef.current, ...deps]);

  return { visible, scrollToBottom: () => scrollToBottom({ smooth: true }) };
};

export default useScrollToBottom;
