import { Input as InputField, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

export const Input = styled(InputField)`
  width: 100%;
  height: 36px;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.var80};
  background: ${({ theme }) => theme.palette.neutral.var10};
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 12px;
  gap: 4px;
`;

export const Options = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 60px;
  height: 36px;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.palette.neutral.var90};
  cursor: pointer;
  background: ${({ theme }) => theme.palette.neutral.var20};
  border-radius: 8px;
  :hover {
    background: ${({ theme }) => theme.palette.neutral.var30};
  }
`;

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
  color: ${({ theme }) => theme.palette.neutral.var60};
  ${Options}:hover & {
    color: ${({ theme }) => theme.palette.neutral.var80};
  }
`;

export const ButtonAndLabelContainer = styled(Box)`
  display: flex;
  padding: 12px;
  height: 6.8rem;
  border-radius: 0;
  border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
  align-items: center;
`;
