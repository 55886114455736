import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const updateContactInfo = async (
  _id: string,
  token: string,
  name: string,
  email: string,
  phone: string,
  customFields: any
): Promise<AxiosResponse> => {
  delete customFields.name;
  return AxiosInstance.post('omnichannel/contact', {
    _id,
    token,
    name,
    email,
    phone,
    customFields,
  });
};
