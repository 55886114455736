import { ListItem, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Item = styled(ListItem)`
  padding: 0px 0px 0px 16px;
  gap: 20px;
  height: 68px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.neutral.var10};
  }
`;
export const ContainerChatName = styled(Stack)`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const ChatName = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.var60};
  max-width: 200px;
`;

export const ItemTitleAllChats = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const ClientNameEllipsis = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 110px;
`;
