import React, { useContext, useState } from 'react';
import { Divider, ListItemButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ChatList, ContainerListChats, NotFoundChatsContainer } from '../style';
import MenuChatsContext from '../../../../contexts/menuChats/context';
import { IAccordeon } from '../../../../contexts/menuChats/types';
import { SessionInfoView } from '../../../../components/sessions/SessionInfoView';
import { CollapseView } from '../../../../components/sessions/CollapseView';
import SearchAndStatusBar from '../../../../components/SearchAndStatusBar';
import ChatItemFilter from '../../../../components/ChatItemFilter';
import ChatsContext from '../../../../contexts/chats/context';
import AuthContext from '../../../../contexts/auth/context';

const AllChats: React.FC = () => {
  const theme = useTheme();
  const { accordeonsAllChats, handleExpand, searchAllChats, handleSearchChats, isSearchAllChats } =
    useContext(MenuChatsContext);
  const { t } = useTranslation();
  const { goToNewChat } = useContext(ChatsContext);
  const { allowsManualOnHold } = useContext(AuthContext);
  const [preValue, setPreValue] = useState('');

  return (
    <ContainerListChats>
      <SearchAndStatusBar
        value={preValue}
        handleChangeSearch={(value: string) => {
          if (value !== 'delete') {
            handleSearchChats('allChats', value);
            setPreValue(value);
          } else {
            const newValue = preValue.substring(0, preValue.length - 1);

            handleSearchChats('allChats', newValue);
            setPreValue(newValue);
          }
        }}
      />

      <ChatList disablePadding>
        {isSearchAllChats &&
          searchAllChats.length &&
          searchAllChats.map((item, index) => (
            <React.Fragment key={index}>
              <ChatItemFilter
                tabType="allChats"
                id={item._id}
                name={item.fname}
                closedAt={item.closedAt}
                department={item.department}
                closedBy={item.closedBy}
                servedBy={item.servedBy}
                handleOpenChat={() => goToNewChat(item)}
              />
              <Divider variant={index === searchAllChats.length - 1 ? 'fullWidth' : 'inset'} component="li" />
            </React.Fragment>
          ))}

        {!isSearchAllChats &&
          accordeonsAllChats &&
          accordeonsAllChats.map((item: IAccordeon, index: number) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: !allowsManualOnHold && item.id === 'onHold' ? 'none' : 'block',
                }}
              >
                <ListItemButton
                  disableGutters
                  onClick={() => {
                    handleExpand(item, 'accordeonsAllChats');
                  }}
                  style={{
                    position: 'sticky',
                    zIndex: 99,
                  }}
                  sx={{
                    ':hover': {
                      background: `${theme.palette.neutral.var10} !important`,
                    },
                  }}
                >
                  <SessionInfoView type="allChats" item={item} index={index} />
                </ListItemButton>
              </div>
              <Divider variant="fullWidth" component="li" />
              {item.rooms.length ? <CollapseView type="allChats" item={item} /> : null}
            </React.Fragment>
          ))}

        {isSearchAllChats && !searchAllChats.length ? (
          <NotFoundChatsContainer>
            <Typography variant="caption1" color="neutral.var60">
              {t('notFoundChats')}
            </Typography>
          </NotFoundChatsContainer>
        ) : null}
      </ChatList>
    </ContainerListChats>
  );
};

export default AllChats;
