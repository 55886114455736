import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.02), 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.neutral.var0};
`;
