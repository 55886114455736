import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@engyalo/design-system';
import { UserStatus } from 'types/user';
import { MainText } from '../style';
import GenericModal from '../../../../components/Modal';
import { IStatus } from './types';
import AuthContext from '../../../../contexts/auth/context';
import MenuChatsContext from '../../../../contexts/menuChats/context';
import { IRoom } from '../../../../types/room';
import WebsocketContext from '../../../../contexts/websocket/context';
import ButtonsRow from '../../../../components/ButtonsStack';
import ChatsContext from '../../../../contexts/chats/context';
import useSegment from '../../../../services/Analytics';

export const ModalChangeStatus: React.FC<IStatus> = (props: IStatus) => {
  const { t } = useTranslation();
  const { showModalChangeStatus, setShowModalChangeStatus, changeStatus } = props;
  const { accordeonsYourChats } = useContext(MenuChatsContext);
  const { returnToQueue } = useContext(WebsocketContext);
  const { forwardChat } = useContext(AuthContext);
  const { handleServiceSelected } = useContext(ChatsContext);
  const [chatsToReturnToQueue, setChatsToReturn] = useState<Array<IRoom>>([]);
  const navigate = useNavigate();
  const { sendEvent, EVENT_NAMES } = useSegment();

  const handleChangeStatus = async () => {
    if (forwardChat === 'forward') {
      chatsToReturnToQueue.forEach((chat) => {
        if (chat.livechatData?.userKey) {
          returnToQueue(chat._id, chat.livechatData.userKey);
        }
      });
    }

    await changeStatus(UserStatus.Offline);
    setShowModalChangeStatus(false);
    handleServiceSelected({});
    navigate('/', { replace: true });
  };

  useEffect(() => {
    if (accordeonsYourChats) {
      const chatsToReturn = accordeonsYourChats.filter((el) => el.id === 'inAttendance')[0];

      setChatsToReturn(chatsToReturn.rooms);
    }
  }, [accordeonsYourChats]);

  return (
    <GenericModal open={showModalChangeStatus} title={`${t('messageChangeStatusOffline')}`}>
      <MainText variant="body1" color="neutral.var80">
        {t('messageExistingConversations')}
      </MainText>
      <ButtonsRow justifyContent="space-around">
        <Button
          data-testid="cancelBtn"
          variant="outlined"
          color="neutral2"
          onClick={() => {
            setShowModalChangeStatus(false);
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          data-testid="okBtn"
          onClick={() => {
            handleChangeStatus();
            sendEvent(EVENT_NAMES.USER_CHANGE_STATUS_OFFLINE);
          }}
        >
          {t('ok')}
        </Button>
      </ButtonsRow>
    </GenericModal>
  );
};
