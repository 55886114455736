import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';

interface IGetCustomerInfo {
  ID: string;
  BotID: string;
  AccountID: string;
  SalesdeskAccountID: string;
  Name: string;
  WebhookMetadata: {
    webhook: string;
    messagesPerRequest: number;
    chatBacklogInHours: number;
    phone: string;
  };
  UpdatedAt: string;
  CreatedAt: string;
  CustomerId: string;
  CustomerName: string;
  IntegrationsKey: string;
}
export const getCustomerInfo = async (instanceName: string): Promise<AxiosResponse<IGetCustomerInfo>> =>
  AxiosInstanceYalo.get(`v1/bot?instance=${instanceName}`);
