import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';
import { Notification } from './types';

type GetAllNotificationsContact = { Notifications: Notification[]; Count: number; Offset: number; Total: number };

export const getAllContactNotifications = async (id: string): Promise<AxiosResponse<GetAllNotificationsContact>> =>
  AxiosInstanceYalo.get(`v1/contact/${id}/notification`, {
    params: {
      offset: 0,
      count: 100,
    },
  });
