import { Checkbox, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerCannedMessages = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ContainerTableAndFooter = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Span = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: right;
  float: right;
`;

export const ButtonFormContainer = styled('div')`
  padding: 8px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  justify-content: space-between;
`;

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding: 0px;
  }
`;

export const PreviewBubbleMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  width: 334px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 32px;
`;

export const CustomChip = styled(Chip)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;

  background: ${({ theme }) => theme.palette.neutral.var30};
  border-radius: 4px;

  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  color: ${({ theme }) => theme.palette.neutral.var80};
`;

export const CheckboxCustom = styled(Checkbox)`
  padding: 0;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border-radius: 4px;
  & .MuiSvgIcon-root {
    font-size: 21px;
    padding: 0;
  }
  & input:focus + * {
    background: ${({ theme }) => theme.palette.informational.light};
    border-radius: 4px;
  }
`;

export const CheckboxIcon = styled('span')`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.palette.neutral.var20};
  border: ${({ theme }) => ` 1.3px solid ${theme.palette.neutral.var50}`};
`;
