export enum DrawerMessageType {
  SEARCH = 'search',
  HISTORY = 'history',
}

export interface IDrawerMessages {
  roomId: string;
  type: DrawerMessageType;
  user: 'admin' | 'agent';
  onClose?: () => void;
}
