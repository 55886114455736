import { logError, logWarn } from 'services/Logger';
import { uploadFiles } from '../../../../../services/uploadFiles';
import { AudioEncoder } from './audioEncoder';

const { AudioContext } = window;
class AudioRecorder {
  audioContext: AudioContext | undefined = undefined;

  stream: MediaStream | undefined = undefined;

  encoder: AudioEncoder | undefined = undefined;

  async getUserMedia() {
    const { navigator } = window;
    if (navigator.mediaDevices) {
      return navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => stream)
        .catch((err) => err);
    }
  }

  isSupported() {
    return Boolean(this.getUserMedia) && Boolean(AudioContext);
  }

  createAudioContext() {
    if (this.audioContext) {
      return;
    }

    this.audioContext = new AudioContext();
  }

  destroyAudioContext() {
    if (!this.audioContext) {
      return;
    }

    this.audioContext.close();
    this.audioContext = undefined;
  }

  async createStream() {
    if (this.stream) {
      return;
    }

    this.stream = await this.getUserMedia();
  }

  destroyStream() {
    if (!this.stream || this.stream instanceof DOMException) {
      return;
    }

    this.stream.getAudioTracks().forEach((track) => track.stop());
    delete this.stream;
  }

  async createEncoder() {
    if (this.encoder) {
      return;
    }

    if (this.audioContext && this.stream) {
      const input = this.audioContext.createMediaStreamSource(this.stream);
      this.encoder = new AudioEncoder(input);
    }
  }

  destroyEncoder() {
    if (!this.encoder) {
      return;
    }

    this.encoder.close();
    delete this.encoder;
  }

  async start() {
    try {
      await this.createAudioContext();
      await this.createStream();
      await this.createEncoder();
      return true;
    } catch (error) {
      logWarn('error start audio', error);
      this.destroyEncoder();
      this.destroyStream();
      this.destroyAudioContext();
    }
  }

  cancel() {
    try {
      this.stop();
      this.destroyEncoder();
      this.destroyStream();
      this.destroyAudioContext();
    } catch (e) {
      logError('error cancel audio', e);
    }
  }

  stop() {
    if (this.encoder) {
      this.encoder.close();
    }
  }

  async sendRecording(_id: string, blob: Blob) {
    if (blob) {
      try {
        const formData = new FormData();
        formData.append('file', blob, 'audio.mp3');
        const { data } = await uploadFiles(_id, formData);

        return data.success;
      } catch (e) {
        logError('error sendRecording', e);
      }
    }
  }
}

export { AudioRecorder };
