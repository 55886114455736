import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Button, TextField } from '@engyalo/design-system';
import { IEditProfileProps } from './types';
import GenericModal from '../../../components/Modal';
import { Container, CurrentInfo, Form, FormItem, TextUserInfo, UserInfo } from './style';
import PasswordRules from '../../../components/PasswordRules';
import ModalError from '../../../components/ErrorModal';
import EditAvatar from '../../../components/EditAvatar';
import useEditProfile from './useEditProfile';
import ButtonsRow from '../../../components/ButtonsStack';

const EditProfile: React.FC<IEditProfileProps> = (props) => {
  const { open, handleCloseModal } = props;
  const { t } = useTranslation();
  const {
    uploadImage,
    onSubmit,
    watch,
    setShowModalError,
    avatarURL,
    currentUserInfo,
    handleSubmit,
    getFieldState,
    control,
    errors,
    currentFile,
    isChangeForm,
    showModalError,
    isMobile,
  } = useEditProfile({
    handleCloseModal,
  });

  return (
    <>
      <GenericModal
        title={t('editProfile')}
        hasCloseIcon
        handleModal={handleCloseModal}
        open={open}
        isFullScreen={isMobile}
      >
        <Container data-testid="editProfile">
          <CurrentInfo sx={{ padding: isMobile ? '16px' : null }}>
            <EditAvatar height="64px" width="64px" urlImage={avatarURL} handleImage={uploadImage} />

            <UserInfo>
              <TextUserInfo variant="caption1" color="common.black">
                {t('email')}:
                <Typography variant="body1" color="neutral.var80">
                  {currentUserInfo.email}
                </Typography>
              </TextUserInfo>
              <TextUserInfo variant="caption1" color="common.black">
                {t('name')}:
                <Typography variant="body1" color="neutral.var80">
                  {currentUserInfo.name}
                </Typography>
              </TextUserInfo>
            </UserInfo>
          </CurrentInfo>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ padding: isMobile ? '16px' : null, gap: '20px' }}>
              <FormItem>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={currentUserInfo.name}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: '100%' }}
                      placeholder={t('name')}
                      label={t('name')}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      InputProps={{}}
                      inputProps={{
                        role: 'inputName',
                      }}
                    />
                  )}
                />
              </FormItem>
              <FormItem>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="password"
                      label={t('newPassword')}
                      sx={{ width: '100%' }}
                      placeholder={t('enterStrongPassword')}
                      error={!!errors.password?.message}
                      InputProps={{}}
                      inputProps={{
                        role: 'inputPassword',
                      }}
                    />
                  )}
                />
                <PasswordRules watch={watch} getFieldState={getFieldState} />
              </FormItem>
              <FormItem>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('confirmationPassword')}
                      disabled={watch('password') === ''}
                      sx={{ width: '100%' }}
                      placeholder={t('insertPasswordAgain')}
                      type="password"
                      error={!!errors.confirmPassword}
                      InputProps={{}}
                    />
                  )}
                />
              </FormItem>
              <ButtonsRow
                justifyContent={isMobile ? 'center' : 'flex-end'}
                gap="20px"
                marginTop={isMobile ? '0px' : '16px'}
              >
                <Button
                  data-testid="buttonCloseEditModal"
                  onClick={() => handleCloseModal()}
                  variant="outlined"
                  color="neutral2"
                  sx={{ display: isMobile ? 'none' : 'flex' }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!currentFile && !isChangeForm}
                  type="submit"
                  variant="contained"
                  sx={{
                    position: isMobile ? 'absolute' : null,
                    bottom: isMobile ? 0 : null,
                    width: isMobile ? '95%' : '14.8rem',
                    margin: isMobile ? '8px' : '0px',
                  }}
                >
                  {t('save')}
                </Button>
              </ButtonsRow>
            </Stack>
          </Form>
        </Container>
      </GenericModal>

      {showModalError && (
        <ModalError
          handleClose={() => setShowModalError(false)}
          title={t('errorOnSaveTitle')}
          message={t('errorOnSaveMessage')}
          open={showModalError}
        />
      )}
    </>
  );
};

export default EditProfile;
