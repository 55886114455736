import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import AxiosInstance from './AxiosInstance';

export const uploadFiles = async (
  chatId: string,
  formData: FormData,
  options?: RawAxiosRequestConfig
): Promise<AxiosResponse> =>
  AxiosInstance.post(`rooms.upload/${chatId}`, formData, {
    headers: {
      'content-type': 'multipart/form-data;',
    },
    ...options,
  });
