import { useContext } from 'react';
import AuthContext from '../contexts/auth/context';

const usePermissions = () => {
  const { currentUserInfo, getUserRoles } = useContext(AuthContext);

  const checkPermission = (permission: string | string[]) => {
    const roles = currentUserInfo?.roles || getUserRoles();
    if (Array.isArray(permission)) {
      return permission.some((perm) => roles.includes(perm));
    }
    return roles.includes(permission);
  };

  return {
    checkPermission,
  };
};

export default usePermissions;
