import { styled } from '@mui/material/styles';

export const ImageAnchor = styled('a')<{ width?: number; height?: number }>`
  position: relative;
  max-width: 100%;
  max-height: 32rem;
  min-width: 25rem;
  min-height: 25rem;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '25rem')};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.var20};
  border-radius: 0.8rem;
`;

export const Image = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
