import React, { useContext, useEffect } from 'react';
import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@engyalo/design-system';
import { CustomPopover, DiscussionBody, DiscussionHeader, DiscussionWrapper } from './style';
import { IDiscussion } from './types';
import ChatMessageStack from '../Chat/InputBar/ChatMessageStack';
import MessagesList from '../../../components/legacy/Messages';
import WebsocketContext from '../../../contexts/websocket/context';
import SelectAgent from './selectAgent';
import SelectAgentContext from '../../../contexts/selectAgentContext/context';
import ChatsContext from '../../../contexts/chats/context';

const Discussion: React.FC<IDiscussion> = (props: IDiscussion) => {
  const { open, anchorEl, handleClose, roomId } = props;
  const { t } = useTranslation();
  const { showSelectAgent } = useContext(SelectAgentContext);
  const { isMobile } = useContext(ChatsContext);
  const {
    loadHistoryMessages,
    messagesListDiscussion,
    listeningRoomMessages,
    unsubscribeRoomMessages,
    websocketIsConnect,
  } = useContext(WebsocketContext);

  const closeDiscussion = () => {
    handleClose();
    unsubscribeRoomMessages('discussion');
  };

  useEffect(() => {
    if (open && roomId && websocketIsConnect) {
      loadHistoryMessages(roomId, 'discussion');
      listeningRoomMessages(roomId, 'discussion');
    }
  }, [open, roomId, websocketIsConnect]);

  return (
    <CustomPopover
      open={open}
      anchorEl={anchorEl}
      onClose={closeDiscussion}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <DiscussionWrapper data-testid="discussion">
        <DiscussionHeader>
          {isMobile && (
            <IconButton
              data-testid="closeDiscussionBtnMobile"
              onClick={() => {
                closeDiscussion();
              }}
              color="neutral2"
              variant="text"
            >
              <i className="fa-solid fa-arrow-left-long" />
            </IconButton>
          )}
          <Typography
            variant="subtitle2"
            color="neutral.var90"
            display="flex"
            width="100%"
            justifyContent="center"
            padding="8px"
          >
            {t('internDiscussionTitle')}
          </Typography>
        </DiscussionHeader>
        <Divider />
        <DiscussionBody hasMessages={!!messagesListDiscussion.length}>
          {!messagesListDiscussion.length ? (
            <Typography variant="body1" color="neutral.var70" textAlign="center">
              {t('internDiscussionEmptyMessage')}
            </Typography>
          ) : (
            <MessagesList messages={messagesListDiscussion} roomId={roomId} type="discussion" />
          )}
        </DiscussionBody>
        {/** The Discussion component hasn't been refactored to use the new socket
         *   It still sends messages through Rocket
         */}
        <ChatMessageStack showQuickMessagesIcon={false} isDiscussion isWsConnected={websocketIsConnect} />
        {showSelectAgent && <SelectAgent />}
      </DiscussionWrapper>
    </CustomPopover>
  );
};
export default Discussion;
