import React, { useState } from 'react';
import { ClickAwayListener, List, ListItem, Popper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InteractiveButton, InteractiveButtonList, InteractiveContainer, InteractiveTextButton } from '../style';
import { ReactComponent as IconMenu } from '../../../../assets/icons/iconMenu.svg';
import shadows from '../../../../theme/shadows';
import { IInteractiveMessages } from '../type';

interface IButtonReply {
  id: number;
  title: string;
}

interface IButton {
  reply: IButtonReply;
}

interface ISectionRow {
  id: number;
  title: string;
}

interface ISection {
  rows: ISectionRow[];
}

interface IListReply {
  id: number;
  title: string;
}

function isButton(item: unknown): item is IButton {
  return typeof item === 'object' && item !== null && 'reply' in item;
}

function isSection(item: unknown): item is ISection {
  return typeof item === 'object' && item !== null && 'rows' in item;
}

function isSectionRow(item: unknown): item is ISectionRow {
  return typeof item === 'object' && item !== null && 'id' in item && 'title' in item;
}

const InteractiveMessage: React.FC<IInteractiveMessages> = ({ customFields, messages, messageId }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClickInteractiveMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseInteractiveList = () => {
    setAnchorEl(null);
  };

  if (customFields.type === 'interactive' && customFields.values) {
    const { type = '', action = {} } = customFields.values;

    if (type === 'button' && action.buttons) {
      const { buttons } = action;

      let findResponse: IButtonReply | undefined;
      const auxMessages = messages.filter((el) => el.customFields && el.customFields.type === 'interactive');

      for (let i = 0; i < auxMessages.length; i += 1) {
        if (auxMessages[i].id === messageId && i + 1 < auxMessages.length) {
          findResponse = auxMessages[i + 1].customFields?.values.action?.button_reply;
        }
      }

      return (
        <InteractiveContainer data-testid="interactiveMessageButton">
          {buttons.map((item: unknown) => {
            if (isButton(item)) {
              const { title, id } = item.reply;
              let select = false;
              if (findResponse) {
                select = findResponse.id === id;
              }
              return (
                <InteractiveButton isSelected={select ? 1 : 0} key={id}>
                  <InteractiveTextButton isSelected={0}>{title}</InteractiveTextButton>
                </InteractiveButton>
              );
            }
            return null;
          })}
        </InteractiveContainer>
      );
    }

    if (type === 'list' && action.sections) {
      const { button, sections } = action;
      let findResponse: IListReply | null = null;

      for (const message of messages) {
        if (findResponse) break;

        if (message.customFields && message.customFields.type === 'interactive') {
          const { type: customFieldsType = '', list_reply } = message.customFields.values;
          if (customFieldsType === 'list_reply' && list_reply && sections.length > 0) {
            for (const element of sections) {
              if (isSection(element)) {
                const hasSelectRow = element.rows.find(
                  (item) => isSectionRow(item) && item.id === list_reply.id && item.title === list_reply.title
                );
                if (hasSelectRow) {
                  findResponse = list_reply;
                  break;
                }
              }
            }
          }
        }
      }

      return (
        <InteractiveContainer data-testid="interactiveMessageList">
          <InteractiveButtonList onClick={handleClickInteractiveMenu} data-testid="interactiveMessageListButton">
            <IconMenu />
            <InteractiveTextButton isSelected={1}>{button}</InteractiveTextButton>
            <i
              className="fa-regular fa-chevron-down fa-sm"
              style={{
                color: theme.palette.common.white,
              }}
            />
          </InteractiveButtonList>
          {anchorEl && (
            <Popper
              style={{ zIndex: 9999 }}
              placement="bottom"
              id="simple-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
            >
              <ClickAwayListener onClickAway={handleCloseInteractiveList}>
                <List
                  data-testid="popoverList"
                  disablePadding
                  sx={{
                    padding: '4px 0px 8px',
                    background: 'common.white',
                    border: '1px solid neutral.var40',
                    boxShadow: shadows.shadowBlack4,
                    borderRadius: '4px',
                    minWidth: '180px',
                  }}
                >
                  {sections.map((section: unknown) =>
                    isSection(section)
                      ? section.rows.map((option, index) => {
                          let isSelected = false;
                          if (findResponse && findResponse.id === option.id) {
                            isSelected = true;
                          }
                          return (
                            <ListItem
                              disablePadding
                              sx={{
                                padding: '8px 12px',
                                background: isSelected ? 'neutral.var20' : '',
                                color: isSelected ? 'neutral.var60' : 'neutral.var80',
                              }}
                              key={option.id}
                            >
                              {`${index + 1}. ${option.title}`}
                            </ListItem>
                          );
                        })
                      : null
                  )}
                </List>
              </ClickAwayListener>
            </Popper>
          )}
        </InteractiveContainer>
      );
    }
  }
  return null;
};

export default InteractiveMessage;
