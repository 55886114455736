import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { TagGroup, Tooltip } from '@engyalo/design-system';
import { ISchedule } from '../types';
import { formatWorkHours } from '../utils';
import { FormattedWorkDays } from './types';

const DaysResume = ({ workDays }: { workDays: FormattedWorkDays }) => (
  <Stack spacing={1} justifyContent="stretch" padding="0.4rem 0">
    {workDays.map((day) => {
      const { dayLabel, hourLabel, isToday, hasWorkHours } = day;
      const color = isToday ? 'primary.light' : 'inherit';
      const sx = hasWorkHours ? {} : { opacity: 0.5 };

      return (
        <Stack key={dayLabel} direction="row" justifyContent="space-between" whiteSpace="nowrap" width="100%" sx={sx}>
          <Typography variant="caption2" color={color} minWidth={90}>
            {dayLabel}
          </Typography>
          <Typography variant="caption2" color={color} minWidth={74}>
            {hourLabel}
          </Typography>
        </Stack>
      );
    })}
  </Stack>
);

const DayTags = (schedule: ISchedule) => {
  const { t } = useTranslation();
  const { workHours } = schedule;
  const workDays = formatWorkHours(workHours, t);
  const tags = workDays.map(({ dayShortLabel, hasWorkHours }) => ({
    label: dayShortLabel,
    color: hasWorkHours ? 'primary' : 'default',
  }));

  return (
    <Tooltip title={<DaysResume workDays={workDays} />} sx={{ maxWidth: '30rem' }}>
      <TagGroup color="primary" variant="variable" tags={tags} />
    </Tooltip>
  );
};

export default DayTags;
