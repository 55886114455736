import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { MainText } from './style';
import GenericModal from '../../Modal';
import { IModal } from './types';
import ButtonsRow from '../../ButtonsStack';

export const ModalEdit: React.FC<IModal> = (props: IModal) => {
  const { open, errorUpdt, handleModal, handleEditing } = props;
  const { t } = useTranslation();

  return (
    <GenericModal open={open} title={errorUpdt ? `${t('errorSaveChanges')}` : `${t('getOutWhithoutSaveChanges')}`}>
      <MainText variant="body1" color="neutral.var100">
        {errorUpdt ? `${t('errorMessageWhenSaveChanges')}` : `${t('changesWillBeLost')}`}
      </MainText>
      {errorUpdt ? (
        <Button onClick={handleModal}>{t('Ok')}</Button>
      ) : (
        <ButtonsRow justifyContent="space-around">
          <Button variant="outlined" color="error" data-testid="exitBtn" onClick={() => handleEditing(true)}>
            {t('exit')}
          </Button>
          <Button
            data-testid="cancelBtn"
            onClick={() => {
              handleModal();
            }}
          >
            {t('cancel')}
          </Button>
        </ButtonsRow>
      )}
    </GenericModal>
  );
};
