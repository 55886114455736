import { STORAGE_KEYS } from 'constants/defaultValues';
import { FontSizeOptions } from 'types/user';

type fontSizeValue = FontSizeOptions | null;
export const classPrefix = 'font-size-';

/**
 * Update the font size class on the html, removing the old one and adding the new one
 * @param fontSize - The new font size
 * @param prevFontSize - The old font size
 */
const updateHtmlFontSize = (fontSize?: fontSizeValue, prevFontSize?: fontSizeValue) => {
  const fontSizeLocalStorage = localStorage.getItem(STORAGE_KEYS.FONTSIZE) as fontSizeValue;
  const fontSizeToAdd = fontSize || fontSizeLocalStorage;
  const fontSizeToRemove = prevFontSize || fontSizeLocalStorage;
  const htmlClassList = document.documentElement.classList;

  if (fontSizeToRemove) {
    htmlClassList.remove(`${classPrefix}${fontSizeToRemove}`);
  }

  if (fontSizeToAdd) {
    htmlClassList.add(`${classPrefix}${fontSizeToAdd}`);
  }
};

// Update the font size class on the html in the first render
updateHtmlFontSize();

export default updateHtmlFontSize;
