import { ModelsOutgoingMessage } from '@engyalo/salesdesk-api-ts-client';
import { MessageType } from 'components/Messages';
import { convertDateToSeparator } from 'helpers/formatterDates';
import { isMessageType } from 'views/home/Chat/hooks/utils';

export const groupMessagesByDateStamp = (
  messages: Array<MessageType | ModelsOutgoingMessage>
): Record<string, Array<MessageType>> =>
  messages.reduce<Record<string, Array<MessageType>>>((accumulatedmessages, currentMessage) => {
    if (!isMessageType(currentMessage)) {
      return accumulatedmessages;
    }

    const { status, chat } = currentMessage;
    const timestamp = status?.timestamp || chat?.timestamp;
    const dateStamp = convertDateToSeparator(Number(timestamp));

    if (!accumulatedmessages[dateStamp]) {
      return { ...accumulatedmessages, [dateStamp]: [currentMessage] };
    }

    accumulatedmessages[dateStamp].push(currentMessage);

    return accumulatedmessages;
  }, {});
