import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { TextField } from '@engyalo/design-system';

export const InputItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 0px 12px 0px 12px;
  height: 36px;
`;

export const CustomTextField = styled(TextField)`
  color: ${({ theme }) => theme.palette.neutral.var60};
  ::placeholder {
    color: ${({ theme }) => theme.palette.neutral.var60};
  }

  border-radius: 4px;
  width: 100%;
  :hover {
    border: none;
    box-shadow: none;
  }

  & .MuiInputBase-root {
    height: 'auto';
    min-height: '1.4375em';
    max-height: '4rem';
    padding: '11px 4px 11px 11px';
    & .MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
      padding: '0';
    }
  }

  :active {
    border: none;
    box-shadow: none;
  }
  & .MuiInput-underline:before {
    border-bottom: none !important;
  }

  & .MuiInput-underline:after {
    border-bottom: none;
  }
  .MuiAutocomplete-inputRoot {
    border-bottom: none;
    padding-right: 0px !important;
  }
  & .MuiAutocomplete-inputRoot .MuiAutocomplete-tag {
    background: ${({ theme }) => theme.palette.success.light};
    color: ${({ theme }) => theme.palette.success.main};
    border-radius: 8px;
    padding: 6px 10px;
    width: 35px;
    height: 28px;
  }
`;

export const InputPlaceholderItem = styled(Typography)`
  color: ${({ theme }) => theme.palette.neutral.var70};
  margin-right: 10px;
  white-space: nowrap;
`;

export const SelectItemsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.neutral.var90};
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 200px;
  white-space: nowrap;
`;

export const CreateItemContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 8px 12px;
  gap: 8px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  cursor: pointer;
`;
