import React from 'react';
import { Stack, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Footer = ({ translation }: any) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" marginTop="56px">
      <Link
        sx={{
          fontSize: '12px',
          color: theme.palette.common.white,
          fontFamily: 'Roboto !important',
          fontWeight: 400,
          lineHeight: '18px',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        target="_blank"
        href="https://www.yalo.com/terms-and-conditions"
      >
        {translation('termsAndConditions')}
      </Link>
      <Link
        target="_blank"
        href="https://www.yalo.com/privacy"
        sx={{
          fontSize: '12px',
          color: theme.palette.common.white,
          fontFamily: 'Roboto !important',
          fontWeight: 400,
          lineHeight: '18px',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
      >
        {translation('privacyPolicy')}
      </Link>
    </Stack>
  );
};
