enum RolesEnum {
  'livechat-agent' = 'agent',
  'livechat-manager' = 'manager',
  'livechat-monitor' = 'supervisor',
}
export const getUserRoles = (roles: Array<string>) =>
  roles
    .filter((role) => role !== 'user' && RolesEnum[role as keyof typeof RolesEnum])
    .map((item: string) => {
      item = RolesEnum[item as keyof typeof RolesEnum];
      return item;
    });
