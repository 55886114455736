import { Dispatch, useEffect, useState } from 'react';

export function useControlledProp<T>(initialValue: T) {
  const [controlledValue, setControlledValue] = useState<T>(initialValue);

  useEffect(() => {
    // Update controlledValue if the initialValue changes
    setControlledValue(initialValue);
  }, [initialValue]);

  return [controlledValue, setControlledValue] as [T, Dispatch<T>];
}
