import { Stack, Typography } from '@mui/material';
import { Bubble } from './styles';
import { IPreviewTemplateButtonsProps } from './types';

const PreviewTemplateButtons = ({ Buttons, isMobile = false }: IPreviewTemplateButtonsProps) => (
  <Stack direction="row" gap={1} marginTop="8px">
    {Buttons?.map((button: { Text: string }) => (
      <Bubble
        isMobile={isMobile}
        sx={{ display: 'flex', flex: '1', borderRadius: '8px' }}
        key={button.Text}
        data-testid="QuickReplybutton"
      >
        <Typography variant="caption1" color="primary.main" sx={{ margin: '0 auto' }}>
          {button.Text}
        </Typography>
      </Bubble>
    ))}
  </Stack>
);

export default PreviewTemplateButtons;
