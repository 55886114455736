import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ErrorMessage = ({ translation, connectionError }: any) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        background: theme.palette.error.main,
        height: connectionError ? '60px' : '40px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography textAlign="center" variant="overline" color="common.white">
        {connectionError ? translation('errorConnectionSocket') : translation('invalidCredentials')}
      </Typography>
    </Stack>
  );
};
