import { managementRoles } from 'constants/roles';
import usePermissions from '../hooks/usePermissions';
import { IRoom } from '../types/room';
import { UserInformation } from '../types/user';

export const checkDisableDiscussion = (
  userIsInDiscussion: boolean,
  serviceSelected: IRoom,
  currentUserInfo: UserInformation
) => {
  const { checkPermission } = usePermissions();
  const isManagerOrSupervisor = checkPermission(managementRoles);
  if (isManagerOrSupervisor) {
    return false;
  }

  if (userIsInDiscussion) {
    return false;
  }

  if (serviceSelected.servedBy?._id === currentUserInfo._id) {
    return false;
  }

  return true;
};

export const checkDisableTransfer = (serviceSelected: IRoom, currentUserInfo: UserInformation) => {
  const { checkPermission } = usePermissions();
  const isManagerOrSupervisor = checkPermission(managementRoles);
  if (isManagerOrSupervisor) {
    return false;
  }

  if (serviceSelected.servedBy?._id === currentUserInfo._id) {
    return false;
  }

  if (!serviceSelected?.closedBy) {
    return true;
  }

  return true;
};

export const checkDisableTags = (serviceSelected: IRoom, currentUserInfo: UserInformation) => {
  const { checkPermission } = usePermissions();
  const isManagerOrSupervisor = checkPermission(managementRoles);
  if (isManagerOrSupervisor) {
    return false;
  }

  if (serviceSelected.servedBy?._id === currentUserInfo._id) {
    return false;
  }

  return true;
};
