import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';

export const closeChat = async (
  roomId: string,
  botId: string,
  tags: string[],
  phone: string,
  comment?: string
): Promise<AxiosResponse> =>
  AxiosInstanceYalo.post('v1/chat/close', {
    roomId,
    comment,
    botId,
    tags,
    phone,
  });
