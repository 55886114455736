import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const EmailSentSuccess = ({ translation }: any) => {
  const theme = useTheme();
  return (
    <Stack
      role="emailSent"
      sx={{
        background: theme.palette.success.main,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontWeight: 700,
          fontSize: '11px',
          lineHeight: '180%',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: theme.palette.common.white,
        }}
      >
        {translation('messageEmailResetSent')}
      </Typography>
    </Stack>
  );
};
