import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LabelForm = styled('label')`
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.neutral.var80};
  margin: 40px 0px 2px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OptionalLabel = styled('span')`
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.neutral.var60};
  margin-left: 3px;
`;

export const ButtonFormContainer = styled('div')`
  padding: 8px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  justify-content: space-between;
`;

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  gap: 40px;
  width: 385px;
  height: 100%;
`;

export const ViewMessage = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

export const PreviewBubbleMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  width: 334px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 32px;
`;

export const ContainerTitleAndLicenses = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerLicenses = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitleLicenses = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CustomLinearProgress = styled(LinearProgress)`
  width: 220px;
  height: 4px;
  border-radius: 8px;
  & .${linearProgressClasses.bar} {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  & .${linearProgressClasses.colorPrimary} {
    background-color: ${({ theme }) => theme.palette.neutral.var20};
  }
`;

export const FiltersRow = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const FiltersGroup = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
