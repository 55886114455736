import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IconButton } from '@engyalo/design-system';
import { ITitleProps } from './types';
import { Header } from '../style';

const Title = ({ title, onClose }: ITitleProps) => {
  const { t } = useTranslation();
  return (
    <Header>
      <IconButton data-testid="back-button" size="small" color="neutral" variant="text" onClick={onClose}>
        <i role="presentation" className="fa-regular fa-arrow-left" />
      </IconButton>
      <Typography variant="subtitle2">{t(title)}</Typography>
    </Header>
  );
};

export default Title;
