import React, { useContext, useEffect, useState } from 'react';
import { Divider, Fab, ListItemButton, Typography, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@engyalo/design-system';
import { RoutingMethods } from 'types/user';
import { UnReadBadge } from './style';
import { SessionInfoView } from '../../../../components/sessions/SessionInfoView';
import { CollapseView } from '../../../../components/sessions/CollapseView';
import { Container, IconButton, ButtonsStack } from '../../../../components/sessions/style';
import SearchAndStatusBar from '../../../../components/SearchAndStatusBar';
import MenuChatsContext from '../../../../contexts/menuChats/context';
import { ISessionProps } from '../../../../contexts/menuChats/types';
import ChatItemFilter from '../../../../components/ChatItemFilter';
import ChatsContext from '../../../../contexts/chats/context';
import { ChatList, NotFoundChatsContainer } from '../style';
import shadows from '../../../../theme/shadows';
import WebsocketContext from '../../../../contexts/websocket/context';
import SnackbarCustom from '../../../../components/Snackbar';
import AuthContext from '../../../../contexts/auth/context';
import useSegment from '../../../../services/Analytics';

const Sessions: React.FC = () => {
  const {
    accordeonsYourChats,
    handleExpand,
    isSelectedItems,
    handleSelectedItems,
    handleSearchChats,
    searchYourChats,
    isSearchYourChats,
  } = useContext(MenuChatsContext);
  const navigate = useNavigate();
  const { goToNewChat, handleOpenDiscussion } = useContext(ChatsContext);

  const { allowsManualOnHold, routingMethod } = useContext(AuthContext);

  const {
    handleShowNotificationMarkedInDiscussion,
    showNotificationMarkedInDiscussion,
    discussionNotificationContent,
  } = useContext(WebsocketContext);

  const { t } = useTranslation();
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [preValue, setPreValue] = useState('');

  const handleSnackbar = () => {
    setOpenSnackbarSuccess(false);
  };

  const { sendEvent, EVENT_NAMES } = useSegment();

  useEffect(() => {
    if (showNotificationMarkedInDiscussion) setOpenSnackbarSuccess(true);
  }, [showNotificationMarkedInDiscussion]);

  return (
    <Container>
      <SearchAndStatusBar
        value={preValue}
        handleChangeSearch={(value: string) => {
          if (value !== 'delete') {
            handleSearchChats('yourChats', value);
            setPreValue(value);
          } else {
            const newValue = preValue.substring(0, preValue.length - 1);

            handleSearchChats('yourChats', newValue);
            setPreValue(newValue);
          }
        }}
      />

      <ChatList disablePadding>
        {isSearchYourChats &&
          searchYourChats &&
          searchYourChats.length > 1 &&
          searchYourChats.map((item) => (
            <React.Fragment key={item._id}>
              <ChatItemFilter
                tabType="yourChats"
                id={item._id}
                name={item.fname}
                closedAt={item.closedAt}
                handleOpenChat={() => goToNewChat(item)}
                department={item.department}
                closedBy={item.closedBy}
                servedBy={item.servedBy}
              />
              <Divider variant="fullWidth" component="li" />
            </React.Fragment>
          ))}

        {isSearchYourChats && searchYourChats && searchYourChats.length === 0 ? (
          <NotFoundChatsContainer>
            <Typography variant="caption1" color="neutral.var60">
              {t('notFoundChats')}
            </Typography>
          </NotFoundChatsContainer>
        ) : null}

        {!isSearchYourChats &&
          accordeonsYourChats &&
          accordeonsYourChats.map((item: ISessionProps, index: number) => (
            <React.Fragment key={item.id}>
              {item.id === 'pending' && routingMethod !== RoutingMethods.ManualSelection ? (
                <></>
              ) : (
                <div
                  style={{
                    display: !allowsManualOnHold && item.id === 'onHold' ? 'none' : 'block',
                  }}
                >
                  <ListItemButton
                    disableGutters
                    onClick={() => {
                      sendEvent(EVENT_NAMES.CONVERSATION_SELECTED, {
                        queue: item.id,
                      });
                      if (index === 0 || index === 1) return;
                      handleExpand(item, 'accordeonsYourChats');
                    }}
                    style={{
                      position: 'sticky',
                      zIndex: 99,
                    }}
                    sx={{
                      ':hover': {
                        background: 'neutral.var10 !important',
                      },
                    }}
                  >
                    <SessionInfoView type="yourChats" item={item} index={index} />
                  </ListItemButton>
                  <Divider variant="fullWidth" component="li" />
                  {item.rooms.length ? <CollapseView type="yourChats" item={item} /> : null}
                </div>
              )}
            </React.Fragment>
          ))}

        {showNotificationMarkedInDiscussion ? (
          <>
            <Fab
              data-testid="btnOpenDiscussion"
              onClick={() => {
                if (discussionNotificationContent) {
                  navigate(`/${discussionNotificationContent.prid}`);
                }

                handleOpenDiscussion(true);
                handleShowNotificationMarkedInDiscussion(false);
              }}
              size="small"
              sx={{
                alignSelf: 'end',
                position: 'sticky',
                bottom: '16px',
                left: '88%',
                backgroundColor: 'common.white',
                boxShadow: shadows.shadowBlack4,
              }}
            >
              <Icon
                className="fa-solid fa-screen-users"
                sx={{
                  color: 'neutral.var70',
                }}
              />

              <UnReadBadge>1</UnReadBadge>
            </Fab>

            <SnackbarCustom
              data-testid="snackbarCustom"
              open={openSnackbarSuccess}
              message={
                `${discussionNotificationContent?.lastMessage?.u?.name}
          ${t('markedYouInInternalMessage')}
          ${discussionNotificationContent?.lastMessage?.msg}` ?? ''
              }
              handleClose={handleSnackbar}
              type="info"
              discussion={discussionNotificationContent}
            />
          </>
        ) : null}
      </ChatList>
      {isSelectedItems ? (
        <ButtonsStack onClick={() => handleSelectedItems()} data-testid="btnSelectedItems">
          <IconButton>
            <Icon
              className="fa-regular fa-xmark fa-xs"
              sx={{
                color: 'neutral.var80',
              }}
            />
          </IconButton>
          <Button>{t('finalize')} </Button>
        </ButtonsStack>
      ) : null}
    </Container>
  );
};

export default Sessions;
