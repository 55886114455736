import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

interface SetUserStatusResponse {
  success: boolean;
  error?: string;
}

export const setUserStatus = async (status: string): Promise<AxiosResponse<SetUserStatusResponse>> =>
  AxiosInstance.post('users.setStatus', {
    status,
    message: 'My status update',
  });
