import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import { Typography } from '@mui/material';
import GenericAutocomplete from '../../../../components/Autocomplete';
import ModalError from '../../../../components/ErrorModal';
import GenericModal from '../../../../components/Modal';
import { STORAGE_KEYS } from '../../../../constants/defaultValues';
import { ListItemDropdown } from '../../style';
import { Container, Fields, Footer } from './style';
import useDeleteUnit from './useDeleteUnit';
import { IUnit } from '../../../../types/unit';

export interface IUnitModal {
  open: boolean;
  handleClose: (confirm?: boolean) => void;
  unit?: IUnit;
}

const DeleteUnitModal: React.FC<IUnitModal> = (props) => {
  const { handleClose, open, unit } = props;
  const { t } = useTranslation();
  const { unitsList, showModalError, handleDeleteUnit, setNewUnit, setModalError } = useDeleteUnit({
    unit,
    handleClose,
  });
  const defaultUnit = localStorage.getItem(STORAGE_KEYS.DEFAULT_UNIT);

  return (
    <>
      <GenericModal open={open} title={t('deleteUnit')} hasCloseIcon handleModal={() => handleClose()}>
        <Container data-testid="deleteUnitModal">
          <Fields>
            <Typography variant="caption1" color="neutral.var80">
              {t('unitToMoveTeams')}
            </Typography>
            <GenericAutocomplete
              disableCloseOnSelect
              options={unitsList}
              defaultValue={unitsList.find((item) => item.name === defaultUnit)?._id}
              multiple={false}
              handleChange={(value: any) => {
                setNewUnit(value?._id);
              }}
              renderOption={(props, option) => {
                const { className, ...rest } = props;
                return <ListItemDropdown {...rest}>{option.name}</ListItemDropdown>;
              }}
              placeholder=""
            />
          </Fields>
          <Footer>
            <Button
              data-testid="deleteUnitBtn"
              onClick={() => handleDeleteUnit()}
              disableElevation
              color="error"
              variant="outlined"
            >
              {t('exclude')}
            </Button>
            <Button onClick={() => handleClose()} variant="outlined">
              {t('cancel')}
            </Button>
          </Footer>
        </Container>
      </GenericModal>
      {showModalError && (
        <ModalError
          handleClose={() => setModalError(!showModalError)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showModalError}
        />
      )}
    </>
  );
};

export default DeleteUnitModal;
