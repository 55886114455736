import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const sendCustomField = async (token: string, key: string, value: string): Promise<AxiosResponse> =>
  AxiosInstance.post('livechat/custom.field', {
    token,
    key,
    value,
    overwrite: true,
  });
