import { useContext, useEffect, useState } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { UserStatus } from 'types/user';
import DepartmentContext from '../../../contexts/departments/context';
import { getLivechatUserInfo } from '../../../services/getLivechatUserInfo';
import MenuChatsContext from '../../../contexts/menuChats/context';
import AuthContext from '../../../contexts/auth/context';
import { setUserStatus } from '../../../services/setUserStatus';
import { changeLivechatStatus } from '../../../services/changeLivechatStatus';
import useEnvironment from '../../../hooks/useEnvironments';
import useSegment from '../../../services/Analytics';
import { logError } from '../../../services/Logger';
import { STORAGE_KEYS } from '../../../constants/defaultValues';

const liveChatStatusMapping: {
  online: string;
  offline: string;
  [index: string]: string;
} = {
  online: 'available',
  offline: 'not-available',
};

const useQueueMenu = () => {
  const { departments } = useContext(DepartmentContext);
  const [tabTitle, setTabTitle] = useState<string>('allChats' || '');
  const [showModalChangeStatus, setShowModalChangeStatus] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState('');
  const { changeAgentStatus } = useContext(AuthContext);
  const { departmentsSelected } = useContext(MenuChatsContext);
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID) || '';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [showModalIsMobile, setShowModalIsMobile] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [openPreferences, setOpenPreferences] = useState(false);
  const [anchorElAvatar, setAnchorElAvatar] = useState<HTMLDivElement | null>(null);

  const [statusOptions, setStatusOptions] = useState<Array<any>>([]);
  const [domain, setDomain] = useState('');
  const { getBasePath } = useEnvironment();
  const { sendMenuHomeEvent } = useSegment();
  const [avatarUrl, setAvatarUrl] = useState('');

  const handleTabTitle = () => {
    if (departmentsSelected && (!departmentsSelected.length || departmentsSelected.length === departments.length)) {
      setTabTitle('allChats');
    } else {
      const title = departments
        .filter((item) => departmentsSelected.includes(item._id))
        .map((item) => item.name)
        .join(',');
      setTabTitle(title);
    }
  };

  const changeStatus = async (value: string) => {
    try {
      const response = await changeLivechatStatus(liveChatStatusMapping[value]);
      const message = JSON.parse(response.data.message);
      const apiError = message.error;
      if (apiError) {
        setShowErrorModal(true);
        setErrorMessageKey(`rocketChat.${apiError.error}`);
        throw new Error(apiError.message);
      }

      await setUserStatus(value);
      localStorage.setItem(STORAGE_KEYS.MANUAL_OFFLINE, String(value === UserStatus.Offline));
      const { data: livechatInfo } = await getLivechatUserInfo(userId);

      changeAgentStatus({
        status: livechatInfo.user.status,
        statusLivechat: livechatInfo.user.statusLivechat,
      });
    } catch (e) {
      logError((e as Error).message, e);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    sendMenuHomeEvent(newValue);
  };

  const handleOptionClick = (event: HTMLDivElement) => {
    setAnchorEl(event);
  };

  const handleClickAvatar = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElAvatar(event.currentTarget);
  };

  const handleClickPreferences = () => {
    setOpenPreferences((state) => !state);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElAvatar(null);
  };

  const handleOpenAdminPanel = () => {
    if (isMobile) {
      setShowModalIsMobile(true);
      return;
    }
    window.open(`${domain}/service-manager`)?.focus();
  };

  useEffect(() => {
    const path = getBasePath();
    setDomain(path);
  }, []);

  useEffect(() => {
    handleTabTitle();
  }, [departmentsSelected]);

  return {
    departments,
    tabTitle,
    showModalChangeStatus,
    showModalLogout,
    showEditProfileModal,
    showErrorModal,
    errorMessageKey,
    setShowModalChangeStatus,
    setShowModalLogout,
    changeStatus,
    setShowEditProfileModal,
    setShowErrorModal,
    handleChange,
    handleOptionClick,
    handleClickAvatar,
    handleClose,
    tabValue,
    anchorEl,
    anchorElAvatar,
    statusOptions,
    domain,
    setStatusOptions,
    handleClickPreferences,
    openPreferences,
    handleOpenAdminPanel,
    isMobile,
    showModalIsMobile,
    setShowModalIsMobile,
    avatarUrl,
    setAvatarUrl,
  };
};

export default useQueueMenu;
