import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getDiscussions = async (roomId: string): Promise<AxiosResponse> => {
  const params = {
    roomId,
  };

  return AxiosInstance.get('rooms.getDiscussions', {
    params,
  });
};
