import { Avatar } from '@engyalo/design-system';
import { Box } from '@mui/material';
import { AvatarStatusProps } from './types';

const AvatarStatus = ({ active, src, name, onlineStatus }: AvatarStatusProps) => (
  <Box sx={{ opacity: active ? 1 : 0.4 }}>
    <Avatar variant="image" image={src} displayName={name} onlineStatus={onlineStatus} />
  </Box>
);

export default AvatarStatus;
