import { AxiosResponse } from 'axios';
import { RoutingMethods } from 'types/user';
import AxiosInstance from './AxiosInstance';

type configRoutingResponse = {
  id: string;
  value: RoutingMethods;
  success: boolean;
};

export const getConfigRoutingMethod = async (): Promise<AxiosResponse<configRoutingResponse>> =>
  AxiosInstance.get('settings/Livechat_Routing_Method');
