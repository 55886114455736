import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { logError } from 'services/Logger';
import { IRoom } from 'types/room';
import { tagsColor } from '../../helpers/tagsColors';
import { getLivechatUserInfo } from '../../services/getLivechatUserInfo';
import { getCannedMessages } from '../../services/getCannedMessages';
import ChatsContext from '../chats/context';
import CannedMessagesContext from './context';
import useSegment from '../../services/Analytics';
import { CannedMessage } from '../../services/types';
import { STORAGE_KEYS } from '../../constants/defaultValues';

type CannedMessageReferences = {
  current: HTMLLIElement | null;
};

type ReplaceFields = {
  field: string;
  textToReplace: string;
};
export interface ICannedMessages {
  handleQuickMessages: () => void;
  showQuickMessages: boolean;
  filterCannedMessages: (value: string) => void;
  handleCannedMessageSelected: (value: string, wasClicked: boolean) => void;
  cannedMessages: CannedMessage[];
  filteredCannedMessages: CannedMessage[];
  cannedMessageSelected: string;
  handleMessageSelection: (value: boolean) => void;
  handleMessageOnHover: (value: string) => void;
  cursor: number;
  tabPressed: boolean;
  handleCursor: (value: number) => void;
  resetCannedMessages: () => void;
  handleFocus: (value: number) => void;
  cannedMessagesReferences: CannedMessageReferences[];
  onClick: boolean;
  serviceSelected: IRoom;
}

const clientReplaces = [
  {
    field: 'name',
    textToReplace: '{{contact.name}}',
  },
  {
    field: 'email',
    textToReplace: '{{contact.email}}',
  },
  {
    field: 'phone',
    textToReplace: '{{contact.phone}}',
  },
];

const agentReplaces = [
  {
    field: 'name',
    textToReplace: '{{agent.name}}',
  },
  {
    field: 'email',
    textToReplace: '{{agent.email}}',
  },
];

const CannedMessagesProvider: React.FC = (props) => {
  const { children } = props;
  const { basicContactInfo, serviceSelected } = useContext(ChatsContext);
  const { t } = useTranslation();
  const [showQuickMessages, setShowQuickMessages] = useState(false);
  const [cannedMessages, setCannedMessages] = useState<CannedMessage[]>([]);
  const [filteredCannedMessages, setFilteredCannedMessages] = useState<CannedMessage[]>([]);
  const [cannedMessagesReferences, setCannedMessagesReferences] = useState<CannedMessageReferences[]>([]);

  const [cannedMessageSelected, setCannedMessageSelected] = useState('');
  const [textFilter, setFilterText] = useState('');
  const [tabPressed, setTabPressed] = useState(false);
  const [onClick, setOnClick] = useState(false);
  const [messageOnHover, setMessageOnHover] = useState('');
  const [cursor, setCursor] = useState(0);
  const [agentInfo, setAgentInfo] = useState({} as any);
  const { sendEvent, EVENT_NAMES } = useSegment();

  const replaceFields = useCallback(
    (stringToReplace: string, fieldsArr: ReplaceFields[], infoToReplace: any) => {
      fieldsArr.forEach(({ textToReplace, field }) => {
        stringToReplace = stringToReplace.replace(
          textToReplace,
          infoToReplace[field]
            ? `${infoToReplace[field]} `
            : ` ${textToReplace.slice(2, textToReplace.length - 2)} ${t('fieldNotFound')} `
        );
      });
      return stringToReplace;
    },
    [t]
  );

  const handleCannedMessageSelected = useCallback(
    (value: string, wasClicked: boolean) => {
      value = replaceFields(value, clientReplaces, basicContactInfo);
      value = replaceFields(value, agentReplaces, agentInfo);

      setOnClick(wasClicked);
      setCannedMessageSelected(value);
      setShowQuickMessages(false);
      setMessageOnHover('');
    },
    [basicContactInfo, agentInfo, replaceFields]
  );

  const handleMessageSelection = useCallback((value: boolean) => {
    setTabPressed(value);
    setShowQuickMessages(false);
  }, []);

  const handleQuickMessages = useCallback(() => {
    setShowQuickMessages((state) => !state);
  }, []);

  const handleMessageOnHover = useCallback((msg: string) => {
    setMessageOnHover(msg);
  }, []);

  const handleFocus = useCallback(
    (value: number) => {
      if (showQuickMessages) {
        cannedMessagesReferences[value]?.current?.focus();
      }
    },
    [showQuickMessages, cannedMessagesReferences]
  );

  const handleCursor = useCallback(
    (value: number) => {
      setCursor(value);
      if (showQuickMessages) {
        cannedMessagesReferences[value]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        handleFocus(value);
      }
    },
    [showQuickMessages, cannedMessagesReferences, handleFocus]
  );

  const filterCannedMessages = useCallback(
    (filterText: string) => {
      if (filterText.startsWith('!')) {
        filterText = filterText.substring(1);
      }
      setFilterText(filterText);
      const cannedMessagesFiltered = cannedMessages.filter((item) => item.shortcut.includes(filterText));

      setFilteredCannedMessages(cannedMessagesFiltered);
    },
    [cannedMessages]
  );

  const resetCannedMessages = useCallback(() => {
    handleCannedMessageSelected('', false);
    handleCursor(0);
    handleFocus(0);
    setFilteredCannedMessages(cannedMessages);
  }, [cannedMessages, handleCannedMessageSelected, handleCursor, handleFocus]);

  const generateReferencesArr = (arrayToReduce: CannedMessage[]) => {
    setCannedMessagesReferences((oldState) => arrayToReduce.map((_el, i: number) => oldState[i] || React.createRef()));
  };

  const callGetAgentInfo = async () => {
    const id = localStorage.getItem(STORAGE_KEYS.USER_ID);
    if (id) {
      try {
        const { data } = await getLivechatUserInfo(id);
        const { user } = data;
        setAgentInfo({
          name: user.name,
          email: user.emails && user.emails.length ? user.emails[0].address : '',
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getCannedMessagesFromApi = async () => {
    try {
      const { data } = await getCannedMessages();
      const { cannedResponses } = data;
      if (!cannedResponses) {
        return;
      }
      const customCannedResponses = cannedResponses.map((cannedResponse) => {
        const customCannedResponse = cannedResponse;
        cannedResponse.tags?.forEach((tag) => {
          const color = tagsColor();
          const item = {
            tag,
            customColor: color,
          };

          if (cannedResponse.customTags) {
            cannedResponse.customTags.push(item);
          } else {
            customCannedResponse.customTags = [item];
          }
        });

        return customCannedResponse;
      });
      setCannedMessages(customCannedResponses);
      setFilteredCannedMessages(customCannedResponses);
      generateReferencesArr(customCannedResponses);
    } catch (error) {
      logError('Error while getting canned messages', error);
    }
  };

  useEffect(() => {
    getCannedMessagesFromApi();
    callGetAgentInfo();
  }, []);

  useEffect(() => {
    if (serviceSelected?._id) {
      if (serviceSelected.servedBy) {
        callGetAgentInfo();
      }
      setShowQuickMessages(false);
    }
  }, [serviceSelected]);

  useEffect(() => {
    if (showQuickMessages) {
      generateReferencesArr(filteredCannedMessages);
      sendEvent(EVENT_NAMES.USER_CLICKED_ON_THE_QUICK_MESSAGES);
    } else {
      resetCannedMessages();
    }
  }, [showQuickMessages]);

  useEffect(() => {
    if (cannedMessagesReferences && showQuickMessages && textFilter) {
      handleFocus(0);
    }
  }, [cannedMessagesReferences]);

  useEffect(() => {
    if (tabPressed || onClick) {
      handleCannedMessageSelected(messageOnHover, false);
      handleMessageSelection(false);
    }
  }, [tabPressed, messageOnHover, onClick]);

  const contextValues = {
    showQuickMessages,
    cannedMessages,
    filteredCannedMessages,
    cannedMessageSelected,
    cannedMessagesReferences,
    cursor,
    tabPressed,
    onClick,
    serviceSelected,
    handleQuickMessages,
    filterCannedMessages,
    handleCannedMessageSelected,
    handleMessageSelection,
    handleMessageOnHover,
    handleCursor,
    handleFocus,
    resetCannedMessages,
  };

  const memoizedValues = useMemo(() => contextValues, [...Object.values(contextValues)]);

  return <CannedMessagesContext.Provider value={memoizedValues}>{children}</CannedMessagesContext.Provider>;
};

export default CannedMessagesProvider;
