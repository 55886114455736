import { ISchedule } from '../types';

export enum CONFIRM_MODAL_TYPE {
  enable = 'enable',
  disable = 'disable',
}

export interface IConfirmModalProps {
  open: boolean;
  type?: CONFIRM_MODAL_TYPE;
  onClose: () => void;
  onConfirm: (schedule: ISchedule, type: CONFIRM_MODAL_TYPE) => void;
}
