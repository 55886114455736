import { useEffect, useState } from 'react';
import WebSocketService, { SocketEvents } from 'services/Websocket';
import { OutgoingMessageOutgoingMessage as WebsocketMessageModel } from '@engyalo/salesdesk-chat-socket-ts-client';
import { isMessageType } from 'views/home/Chat/hooks/utils';
import { MessageType } from 'components/Messages';

type useSalesDeskWebsocketProps = { websocket: WebSocketService | null; enabled?: boolean };

export const useSalesDeskWebsocket = ({ websocket, enabled }: useSalesDeskWebsocketProps) => {
  const [salesDeskWebsocketNotification, setSalesDeskWebsocketNotification] = useState<MessageType>();

  useEffect(() => {
    const handler = (message: WebsocketMessageModel) => {
      if (isMessageType(message)) {
        setSalesDeskWebsocketNotification(message);
      }
    };

    if (websocket && enabled) {
      websocket.on(SocketEvents.Message, handler);
    }

    return () => {
      if (websocket && enabled) {
        websocket.off(SocketEvents.Message, handler);
      }
    };
  }, [websocket, enabled]);

  return { salesDeskWebsocketNotification };
};
