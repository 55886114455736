import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogButton } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/redux';
import { CONFIRM_MODAL_TYPE, IConfirmModalProps } from './types';

const ConfirmModal = ({ open, onClose, onConfirm, type = CONFIRM_MODAL_TYPE.enable }: IConfirmModalProps) => {
  const { t } = useTranslation();
  const schedule = useAppSelector((state) => state.businessHours.selectedSchedule);

  const title =
    type === CONFIRM_MODAL_TYPE.enable
      ? t('business-hours.confirmationModal.enable-title')
      : t('business-hours.confirmationModal.disable-title');
  const description =
    type === CONFIRM_MODAL_TYPE.enable
      ? t('business-hours.confirmationModal.enable-description')
      : t('business-hours.confirmationModal.disable-description');
  const confirmText = type === CONFIRM_MODAL_TYPE.enable ? t('enable') : t('disable');
  return (
    <Dialog data-testid="confirmation-modal-switch" open={open} variant="info" onClose={onClose} title="Confirm">
      <DialogTitle>
        {title}
        <DialogContentText>{description}</DialogContentText>
      </DialogTitle>
      <DialogActions>
        <DialogButton onClick={onClose}>{t('cancel')}</DialogButton>
        <DialogButton primary onClick={() => onConfirm(schedule, type)}>
          {confirmText}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
