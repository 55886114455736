import { Checkbox, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Button, IconButton, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import { User } from 'services/types';
import Drawer from '../../../../components/Drawer';
import { ListItemDropdown, OptionalLabel } from '../../style';
import { FormContainer, Footer, FormFields, Header, Field, OptionsListItemUnit } from './style';
import GenericAutocomplete from '../../../../components/Autocomplete';
import useFormTeam from './useFormTeam';
import EditTags from '../../../../components/Tags/EditTags';
import { ModalEdit } from '../../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import ModalError from '../../../../components/ErrorModal';
import UnitModal from '../Units';
import DeleteUnitModal from '../DeleteUnit';
import LoadingAnimation from '../../../../components/AnimationLoading';

interface ITeamProps {
  teamId: string;
  unitId: string;
}

export interface IFormTeam {
  open: boolean;
  handleClose: (reload?: boolean) => void;
  team?: ITeamProps;
}

const helperTextEmail = (type?: string, message?: string) => {
  if (type === 'custom') {
    return message;
  }

  if (type === 'required') {
    return t('fieldIsRequired');
  }

  if (type === 'email') {
    return t('invalidEmail');
  }

  return '';
};

const FormTeam: React.FC<IFormTeam> = (props) => {
  const theme = useTheme();
  const { open, handleClose, team } = props;
  const {
    control,
    errors,
    agents,
    units,
    showModalUnsavedChanges,
    unsavedChanges,
    defaultUnit,
    isLoadForm,
    showErrorModal,
    isSaving,
    showModalUnit,
    currentSelectUnit,
    showModalDeleteUnit,
    handleDeleteUnit,
    setShowErrorModal,
    handleSubmit,
    onSubmit,
    setShowModalUnsavedChanges,
    handleChangeForm,
    handleModalUnit,
    handleCloseModalUnit,
  } = useFormTeam({ handleClose, team });

  const buttonText = (loading: boolean, teamId?: string) => {
    if (loading) {
      return <LoadingAnimation color={theme.palette.neutral.var50} />;
    }

    if (teamId) {
      return t('saveChanges');
    }

    return t('createTeam');
  };

  return (
    <Drawer open={open}>
      <Header>
        <IconButton
          color="neutral2"
          size="small"
          variant="text"
          data-testid="closeFormTeamButton"
          onClick={() => {
            if (unsavedChanges) {
              setShowModalUnsavedChanges(true);
              return;
            }
            handleClose();
          }}
        >
          <i className="fa-solid fa-arrow-left-long fa-2xs" />
        </IconButton>
        <Typography variant="subtitle2" color="neutral.var90">
          {team?.teamId ? t('editTeam') : t('createTeam')}
        </Typography>
      </Header>
      <FormContainer onSubmit={handleSubmit(onSubmit)} data-testid="formTeam">
        <FormFields>
          <Field>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '100%' }}
                  placeholder={t('name')}
                  label={t('name')}
                  error={!!errors.name}
                  helperText={errors.name ? t('fieldIsRequired') : ''}
                  inputProps={{ 'data-testid': 'name' }}
                />
              )}
            />
          </Field>
          <Field>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '100%' }}
                  placeholder={t('emailPlaceholder')}
                  error={!!errors.email}
                  helperText={helperTextEmail(errors.email?.type, errors.email?.message)}
                  label={t('email')}
                  inputProps={{ 'data-testid': 'email' }}
                />
              )}
            />
          </Field>
          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('visitorInactivityTimeoutInMinutes')} <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
              <Tooltip title={t('inactivityTimeoutTooltipInfo') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="visitorInactivityTimeoutInSeconds"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '100%' }}
                  type="number"
                  error={!!errors.visitorInactivityTimeoutInSeconds}
                  helperText={errors.visitorInactivityTimeoutInSeconds?.message}
                  inputProps={{ 'data-testid': 'visitorInactivityTimeout' }}
                />
              )}
            />
          </Field>

          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('abandonedRoomsCloseCustomMessage')} <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
              <Tooltip title={t('abandonedRoomsCloseCustomMessageTooltipInfo') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="abandonedRoomsCloseCustomMessage"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '100%' }}
                  error={!!errors.abandonedRoomsCloseCustomMessage}
                  helperText={errors.abandonedRoomsCloseCustomMessage?.message}
                  inputProps={{ 'data-testid': 'abandonedRoomsCloseCustomMessage' }}
                />
              )}
            />
          </Field>

          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('maxNumberSimultaneousChat')} <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
              <Tooltip title={t('maxNumberSimultaneousChatTooltipInfo') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="maxNumberSimultaneousChat"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  sx={{ width: '100%' }}
                  error={!!errors.maxNumberSimultaneousChat}
                  helperText={errors.maxNumberSimultaneousChat?.message}
                  inputProps={{ 'data-testid': 'maxNumberSimultaneousChat' }}
                />
              )}
            />
          </Field>
          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('agents')}
              <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
            </Typography>
            <Controller
              name="agents"
              control={control}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <GenericAutocomplete
                    placeholder={t('selectAgents')}
                    defaultValue={value}
                    renderOption={(properties, option, { selected }) => {
                      const { ...rest } = properties;
                      return (
                        <ListItemDropdown {...rest}>
                          <Checkbox disableRipple disableTouchRipple style={{ marginRight: 8 }} checked={selected} />
                          {option.name}
                        </ListItemDropdown>
                      );
                    }}
                    handleChange={(changedValue) => {
                      const newValue = changedValue.map((item: User) => item._id);
                      onChange(newValue);
                    }}
                    limitTagsNumber={1}
                    multiple
                    options={agents}
                    error={!!errors.agents}
                    renderCountItemsSelecteds
                    labelCountItemsSelecteds={t('agents')}
                  />
                );
              }}
            />
          </Field>
          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('unit')}{' '}
              <Tooltip title={t('unitTooltipInfo') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            {isLoadForm && (
              <Controller
                name="unit"
                control={control}
                render={({ field }) => (
                  <GenericAutocomplete
                    createItem
                    onCreateItem={() => {
                      handleModalUnit();
                    }}
                    placeholder=""
                    defaultValue={field.value}
                    renderOption={(properties, option) => {
                      const { ...rest } = properties;
                      return (
                        <ListItemDropdown
                          sx={{
                            justifyContent: 'space-between',
                          }}
                          {...rest}
                        >
                          {option.name}
                          {defaultUnit?._id !== option._id && (
                            <OptionsListItemUnit>
                              <i
                                aria-hidden="true"
                                className="fa-regular fa-pen-to-square"
                                style={{
                                  color: theme.palette.neutral.var50,
                                  cursor: 'pointer',
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleModalUnit(option);
                                }}
                              />

                              <i
                                aria-hidden="true"
                                className="fa-regular fa-trash-can"
                                style={{
                                  color: theme.palette.neutral.var50,
                                  cursor: 'pointer',
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  handleDeleteUnit(option);
                                }}
                              />
                            </OptionsListItemUnit>
                          )}
                        </ListItemDropdown>
                      );
                    }}
                    handleChange={(value) => {
                      field.onChange(value._id);
                    }}
                    limitTagsNumber={1}
                    multiple={false}
                    options={units}
                    error={!!errors.unit}
                    disableCloseOnSelect
                  />
                )}
              />
            )}
          </Field>
          <Field>
            <Typography variant="caption1" color="neutral.var80">
              {t('chatClosingTags')}
              <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
              <Tooltip title={t('chatClosingTagsTooltipInfo') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="chatClosingTags"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <EditTags
                  placeholder={t('selectTagsPlaceholder')}
                  type="room"
                  editTag={false}
                  createTag={false}
                  handleChange={(selectTags: string) => {
                    const splitedTags = !selectTags ? [] : selectTags.split(';');
                    if (selectTags !== field.value?.join(';')) {
                      field.onChange(splitedTags);
                    }
                  }}
                  currentTags={field.value?.join(';')}
                />
              )}
            />
          </Field>
        </FormFields>
        <Footer>
          <Button
            disabled={isSaving}
            id="buttonSaveEditInfo"
            disableElevation
            type="submit"
            variant="contained"
            fullWidth
            startIcon={isSaving ? null : <i className="fa-regular fa-check" />}
          >
            {buttonText(isSaving, team?.teamId)}
          </Button>
        </Footer>
      </FormContainer>

      {showModalUnsavedChanges && (
        <ModalEdit
          open={showModalUnsavedChanges}
          errorUpdt={false}
          handleEditing={(confirm) => {
            if (confirm) {
              handleClose();
            }
            setShowModalUnsavedChanges(false);
          }}
          handleModal={() => {
            handleChangeForm(false);
            setShowModalUnsavedChanges((state) => !state);
          }}
        />
      )}

      {showErrorModal && (
        <ModalError
          handleClose={() => setShowErrorModal(!showErrorModal)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showErrorModal}
        />
      )}

      {showModalUnit && <UnitModal handleClose={handleCloseModalUnit} unit={currentSelectUnit} open={showModalUnit} />}

      {showModalDeleteUnit && (
        <DeleteUnitModal handleClose={handleCloseModalUnit} unit={currentSelectUnit} open={showModalDeleteUnit} />
      )}
    </Drawer>
  );
};

export default FormTeam;
