import React from 'react';
import { UserOnlineStatus } from 'services/types';
import AvatarStatus from 'components/AvatarStatus';

export const renderAvatar = (
  baseUrl: string,
  username: string,
  avatarETag?: string,
  name?: string,
  status?: `${UserOnlineStatus}`,
  active?: boolean
) => {
  const onlineStatus = !active ? UserOnlineStatus.Inactive : status;
  const src = `${baseUrl}/avatar/${username}?etag=${avatarETag}`;
  const displayName = name || username;

  return (
    <AvatarStatus
      active={active}
      src={src}
      name={displayName}
      onlineStatus={onlineStatus || UserOnlineStatus.Offline}
    />
  );
};
