import React from 'react';
import EditTags from '../Tags/EditTags';

export function handleRenderTags(selectValueFilter: (index: number, value: string) => void) {
  return (index: number, value?: string) => (
    <EditTags
      includeDisabled
      type="room"
      editTag={false}
      createTag={false}
      handleChange={(selectTags: string) => {
        selectValueFilter(index, selectTags);
      }}
      currentTags={value}
      open={false}
      limitTagsNumber={1}
      sizedTag="180"
    />
  );
}
