import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import DropDown from '../../../DropDown';
import InteractiveMessage from '../InteractiveMessage';
import { MediaTypesView } from '../MediaTypes/MediaTypesView';
import { MessageHeaderUserInfo } from '../MessageHeaderUserInfo/MessageHeaderUserInfo';
import { ChatBubble, ContentItemMessage, ListItemMessage } from '../style';
import { IMessageProps } from '../type';
import { createKeyToken } from '../../../../helpers/createKeyToken';
import ChatBubbleTail, { TailDirection } from '../ChatBubble/ChatBubbleTail';

const CLICK_DURATION_MOBILE = 1000;
const messageColor = (isclient: boolean, type: string) =>
  isclient || type === 'discussion' ? 'neutral.var70' : 'common.white';

const Message: React.FC<IMessageProps> = (props) => {
  const theme = useTheme();
  const {
    isbot,
    isclient,
    isUserlogged,
    type,
    checkIntervalBetweenMessages,
    itemMsg,
    currentDate,
    hasError,
    isAnotation,
    hasAttachments,
    messageErrorOptions,
    messageMediaOptions,
    messageTextOptions,
    username,
    hasCustomFields,
    messages,
    handleMediaDownload,
  } = props;
  const [onTouchStartValue, setOnTouchStartValue] = useState(0);
  const [isLongClick, setIsLongClick] = useState(false);
  const { t } = useTranslation();
  const CHATBUBBLEID = 'chatBubble';
  const tailDirection = isbot || isUserlogged ? TailDirection.RIGHT : TailDirection.LEFT;

  const linkify = (txt: string) => {
    const urlRegex = /((https|ftp|http)?:\/\/[^\s]+)/g;
    return txt.split(' ').map((part) =>
      urlRegex.test(part) ? (
        <a
          key={createKeyToken()}
          href={part.includes('http') ? part : `https://${part}`}
          style={{ textDecoration: 'underline' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          {part}{' '}
        </a>
      ) : (
        `${part} `
      )
    );
  };

  const ISDISCUSSION = type === 'discussion';

  const checkListOptions = () => {
    if (hasError) {
      return messageErrorOptions;
    }

    if (hasAttachments) {
      return messageMediaOptions;
    }

    return messageTextOptions;
  };

  return (
    <ListItemMessage
      isBot={isbot}
      isClient={isclient}
      isUserLogged={isUserlogged}
      type={type}
      data-testid="messageItem"
    >
      <ContentItemMessage
        data-testid="contentItemMessage"
        onTouchStart={(event) => setOnTouchStartValue(event.timeStamp)}
        onTouchEnd={(event) => {
          const isLongClickCalc = event.timeStamp - onTouchStartValue;
          if (isLongClickCalc > CLICK_DURATION_MOBILE) {
            setOnTouchStartValue(0);
            setIsLongClick(true);
          }
        }}
        type={type}
      >
        {!checkIntervalBetweenMessages && (
          <MessageHeaderUserInfo
            isUserlogged={isUserlogged}
            itemMsg={itemMsg}
            currentDate={currentDate}
            errorSendMessage={hasError}
            isbot={isbot}
            isAnotation={isAnotation}
          />
        )}

        <ChatBubble
          isUserLogged={isUserlogged}
          isBot={isbot}
          isError={hasError}
          isMedia={hasAttachments}
          isAnotation={isAnotation}
          isDiscussion={ISDISCUSSION}
          id={CHATBUBBLEID}
        >
          {!isAnotation && (
            <DropDown
              msg={itemMsg.msg}
              handleIsLongClick={() => {
                setIsLongClick(false);
              }}
              isLongClick={isLongClick}
              isUserlogged={isUserlogged}
              isbot={isbot}
              iserror={hasError}
              ismidia={hasAttachments}
              isanotation={isAnotation}
              isdiscussion={ISDISCUSSION}
              colorIcon={isclient ? theme.palette.neutral.var50 : theme.palette.common.white}
              downloadMedia={() =>
                itemMsg.attachments && handleMediaDownload(itemMsg?.attachments[0], itemMsg.username)
              }
              item={CHATBUBBLEID}
              listOptions={checkListOptions()}
              position="absolute"
            />
          )}

          {itemMsg.attachments && itemMsg.attachments.length ? (
            <MediaTypesView username={username} attachments={itemMsg.attachments} type={type} />
          ) : (
            <>
              <Typography
                id={`${type}-message`}
                variant="body1"
                color={messageColor(isclient, type)}
                sx={{
                  userSelect: 'text',
                }}
              >
                {linkify(itemMsg.msg)}
              </Typography>

              {isbot && (
                <Typography
                  variant="body1"
                  color={messageColor(isclient, type)}
                  sx={{
                    userSelect: 'text',
                    wordBreak: 'break-word',
                    marginTop: '16px',
                  }}
                >
                  {t('messageSendByBot')}
                </Typography>
              )}
            </>
          )}
          <ChatBubbleTail visible={!isAnotation && !ISDISCUSSION} direction={tailDirection} />
        </ChatBubble>
        {hasCustomFields && (
          <InteractiveMessage messageId={itemMsg.id} customFields={itemMsg.customFields} messages={messages} />
        )}
      </ContentItemMessage>
    </ListItemMessage>
  );
};

export default Message;
