import { useCallback, useState } from 'react';

type RequestConfirmationState = {
  isRequesting: boolean;
  isLoading: boolean;
};

export function useRequestConfirmation<Args extends Array<unknown>, CallbackReturn extends void | Promise<void>>(
  callback: (...args: Args) => CallbackReturn
) {
  const [state, setState] = useState<RequestConfirmationState>({
    isRequesting: false,
    isLoading: false,
  });

  const requestConfirmation = useCallback(() => setState((prev) => ({ ...prev, isRequesting: true })), []);
  const onCancel = useCallback(() => setState((prev) => ({ ...prev, isRequesting: false })), []);

  const callbackMemoized = useCallback((...args: Args) => callback(...args), [callback]);
  const onConfirm = useCallback(
    async (...args: Args) => {
      setState((prev) => ({ ...prev, isLoading: true }));
      await callbackMemoized(...args);
      setState((prev) => ({ ...prev, isRequesting: false, isLoading: false }));
    },
    [callbackMemoized]
  ) as (...args: Args) => CallbackReturn;

  return {
    isRequesting: state.isRequesting,
    isLoading: state.isLoading,
    requestConfirmation,
    onCancel,
    onConfirm,
  };
}
