import { useTranslation } from 'react-i18next';
import { LocationAnchor } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';

export const generateMapLink = (lat: string, long: string): string => `https://www.google.com/maps?q=${lat},${long}`;

const LocationMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { location } = props;

  const { t } = useTranslation();

  if (!location) {
    return null;
  }

  const { latitude = '', longitude = '' } = location;

  const mapLink = generateMapLink(latitude, longitude);

  return (
    <LocationAnchor href={mapLink} target="_blank" rel="noopener noreferrer">
      <i className="fa-regular fa-location-dot" />
      <span>{t('messages.location.openInGmaps')}</span>
    </LocationAnchor>
  );
};

export default LocationMessage;
