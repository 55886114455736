import { ListItemText, List, ListItem, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardPropHelper } from '@engyalo/design-system';
import { MessageListType } from './type';
import { TailDirection } from './ChatBubble/ChatBubbleTail';

interface IChatBubble {
  isUserLogged: boolean;
  isBot?: boolean;
  isError?: boolean;
  isMedia?: boolean;
  isAnotation?: boolean;
  isDiscussion?: boolean;
}

interface IListItemText {
  checkIntervalBetweenMessages: boolean;
}

function selectBubbleColor(
  isUserlogged: boolean,
  theme: Theme,
  isBot?: boolean,
  isError?: boolean,
  isAnotation?: boolean,
  isDiscussion?: boolean
) {
  if (isUserlogged && !isBot && !isError && !isAnotation && !isDiscussion) {
    return theme.palette.primary.main;
  }

  if (!isUserlogged && isBot && !isError && !isAnotation && !isDiscussion) {
    return theme.palette.neutral.var70;
  }

  if (isError) {
    return theme.palette.error.main;
  }

  if (isAnotation || isDiscussion) {
    return theme.palette.warning.light;
  }

  return theme.palette.common.white;
}

export const ChatBubble = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IChatBubble>`
  overflow-wrap: break-word;
  white-space: pre-line;
  display: ${(props) => (props.isAnotation ? 'none' : 'block')};
  max-width: 100%;
  min-width: 3vw;
  border-radius: 8px;
  border-top-right-radius: ${(props) =>
    (props.isUserLogged || props.isBot) && !props.isAnotation && !props.isDiscussion ? '0px' : '8px'};
  border-top-left-radius: ${(props) => (props.isUserLogged || props.isBot ? '8px' : '0px')};
  background: ${({ isUserLogged, isBot, isError, isAnotation, isDiscussion, theme }) =>
    selectBubbleColor(isUserLogged, theme, isBot, isError, isAnotation, isDiscussion)};

  color: ${({ theme }) => theme.palette.common.white};
  padding: 12px;
  text-align: left;
  position: relative;
  box-shadow: ${(props) =>
    !props.isUserLogged && !props.isBot && !props.isError
      ? '0px 1.2px 3.6px rgba(47, 27, 154, 0.11), 0px 6.4px 14.4px rgba(47, 27, 154, 0.11)'
      : null};
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }

  & .chat-bubble-tail svg {
    fill: ${({ isUserLogged, isBot, isError, isAnotation, isDiscussion, theme }) =>
      selectBubbleColor(isUserLogged, theme, isBot, isError, isAnotation, isDiscussion)};
  }
`;

export const BubbleTail = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<{ direction: TailDirection }>`
  position: absolute;
  top: 0px;
  right: ${({ direction }) => direction === TailDirection.RIGHT && '-7px'};
  left: ${({ direction }) => direction === TailDirection.LEFT && '-7px'};
`;

export const ListItemTxt = styled(ListItemText, {
  shouldForwardProp: shouldForwardPropHelper,
})<IListItemText>`
  display: ${({ checkIntervalBetweenMessages }) => (checkIntervalBetweenMessages ? 'none' : 'inline')};
  justify-content: space-between;
  align-items: center;
  max-width: 20vw;
  min-width: 3vw;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
`;

export const InteractiveContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
interface IInteractiveButton {
  isSelected: number;
}
export const InteractiveButton = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IInteractiveButton>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
  border-radius: 8px;
  background: ${({ isSelected, theme }) => (isSelected ? theme.palette.neutral.var90 : theme.palette.neutral.var70)};
  margin: 4px 0px;
`;
export const InteractiveTextButton = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IInteractiveButton>`
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${({ isSelected, theme }) => (isSelected ? theme.palette.common.white : theme.palette.neutral.var20)};
`;

export const InteractiveButtonList = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.var70};
  margin: 4px 0px;
  cursor: pointer;
`;

interface IListMessage {
  type?: string;
}
export const ListMessages = styled(List)<IListMessage>`
  overflow-y: ${({ type }) => (type === 'history' ? 'hidden' : 'scroll')};
  display: flex;
  flex-direction: column;
`;
interface IContainerMessage {
  idx: number;
  type: MessageListType;
  messagesLength: number;
}
export const ContainerMessage = styled('div')<IContainerMessage>`
  margin-top: ${({ idx, type }) => (idx === 0 && type !== 'history' ? 'auto' : 'inherit')};
  margin-bottom: ${({ idx, messagesLength }) => (idx === messagesLength - 1 ? '16px' : '0px')};
`;

interface IListItemMessage {
  isUserLogged: boolean;
  isBot: boolean;
  isClient: boolean;
  type: MessageListType;
}
export const ListItemMessage = styled(ListItem, {
  shouldForwardProp: shouldForwardPropHelper,
})<IListItemMessage>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isUserLogged, isBot, isClient }) =>
    isUserLogged || isBot || !isClient ? 'flex-end' : 'flex-start'};
  justify-content: ${({ isUserLogged, isBot, isClient }) =>
    isUserLogged || isBot || !isClient ? 'flex-end' : 'flex-start'};
  padding: ${({ type }) => (type === 'history' || type === 'discussion' ? '0px 16px 8px 16px' : '0px 5% 8px 5%')};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px 16px 8px 16px;
  }
`;

interface IContentItemMessage {
  type: MessageListType;
}

export const ContentItemMessage = styled('div')<IContentItemMessage>`
  display: flex;
  flex-direction: column;
  max-width: ${({ type }) => (type === 'history' ? '100%' : type === 'discussion' ? '-webkit-fill-available' : '65%')};
  ${({ type }) => type === 'discussion' && { width: '-webkit-fill-available' }};
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: ${({ type }) => (type === 'discussion' ? '100%' : 'calc(100% - 40px)')};
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
