import { createContext } from 'react';
import { IDrawerContext } from './provider';

export enum TABS {
  CONTACT_INFO = 'contactInfo',
  CHATS_HISTORY = 'chatsHistory',
  SEARCH_MESSAGES = 'searchMessages',
}

const DrawerContext = createContext<IDrawerContext>({} as IDrawerContext);

export default DrawerContext;
