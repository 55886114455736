import { styled } from '@engyalo/design-system';

export const MainContainer = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const DropAreaContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.open {
    opacity: 1;
    visibility: visible;
  }
  * {
    pointer-events: none;
  }
`;

export const DropAreaContent = styled('div')`
  position: relative;
  top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.primary.lighter};
  border: 0.1rem dashed ${({ theme }) => theme.palette.primary.main};
  transition: all 0.3s;

  .open & {
    top: 0;
  }
  .disabled & {
    color: ${({ theme }) => theme.palette.neutral.var50};
    background: ${({ theme }) => theme.palette.neutral.var20};
    border-color: ${({ theme }) => theme.palette.neutral.var40};
  }
`;

export const DropAreaMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  .material-icons {
    font-size: 3.2rem;
  }
`;
