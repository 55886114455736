import React from 'react';
import { t } from 'i18next';
import { TextField } from '@engyalo/design-system';
import { IFilterFields } from '.';

export function renderDinamicSelectorInput(
  filterFields: IFilterFields,
  customFieldText: (index: number, value?: string) => JSX.Element
) {
  return (key: string, index: number, value?: string) => {
    if (key) {
      if (Object.keys(filterFields).includes(key)) {
        return filterFields[key as keyof IFilterFields](index, value);
      }
      return customFieldText(index, value);
    }
    return <TextField disabled placeholder={`${t('selectOneFilter')}...`} />;
  };
}
