import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChatsContext from './context';
import AuthContext from '../auth/context';
import { getInquiry } from '../../services/getInquiry';
import { takeInquiry } from '../../services/takeInquiry';
import { unreadMessage } from '../../services/unreadMessage';
import { readMessage } from '../../services/readMessage';
import { SubscribeType } from '../../types/subscribe';
import { getSubscribes } from '../../services/getSubscribes';
import { getContactInfo } from '../../services/contactInfo';
import { putChatOnHoldService } from '../../services/putChatOnHold';
import { generateTicketId } from '../../helpers/generateTicketId';
import { getChatsByCustomField } from '../../services/getChatsByCustomField';
import { logError } from '../../services/Logger';
import WebsocketContext from '../websocket/context';
import { getRoomInfo } from '../../services/getRoomInfo';
import { IRoom } from '../../types/room';
import { getDiscussions } from '../../services/getDiscussions';

import { getGroupMembers } from '../../services/getGroupMembers';
import { MessageType } from '../../types/message';
import { sendMessage } from '../../services/sendMessage';
import { saveRoomInfo } from '../../services/saveRoomInfo';
import { ChatModalAction } from './types.d';

const MAX_ATTEMPTS = 3;

const ChatsProvider: React.FC = (props) => {
  const navigate = useNavigate();
  const { children } = props;
  const { t } = useTranslation();
  const { currentUser, currentUserInfo } = useContext(AuthContext);
  const { handleErrorNewMessage, discussionNotification } = useContext(WebsocketContext);
  const [serviceSelected, setServiceSelected] = useState<IRoom>({} as IRoom);
  const [activeInput, setActiveInput] = useState(false);
  const [inAttendanceChatsLength, setInAttendanceLength] = useState(0);
  const [isSelectedItems, setIsSelectedItems] = useState(false);
  const [serviceLimitReached, setServiceLimitReached] = useState(false);
  const [disabledInputChat, setDisabledInputChat] = useState(false);
  const [basicContactInfo, setBasicContactInfo] = useState({
    _id: '',
    name: '',
    email: '',
    phone: '',
    documentType: '',
    documentNumber: '',
    tags: '',
  });
  const [subscribeChats, setSubscribeChats] = useState<Array<SubscribeType>>([]);

  const [reload, setReload] = useState(false);
  const [openModalCloseChat, setOpenModalCloseChat] = useState(false);
  const [errorCloseChat, setErrorCloseChat] = useState(false);
  const [chatId, setChatId] = useState('');
  const [discussionId, setDiscussionId] = useState('');
  const [alertBarMessage, setAlertBarMessage] = useState('');
  const [discussionOpened, setDiscussionOpened] = useState(false);
  const [userIsInDiscussion, setUserInDiscussionGroup] = useState(false);
  const [emoji, setEmoji] = useState('');
  const [messagesListErrors, setMessagesListErrors] = useState<MessageType[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const loadUserInfo = useCallback(
    async (service: IRoom) => {
      const {
        v: { _id = '' },
      } = service ?? serviceSelected;

      if (_id) {
        const { data } = await getContactInfo(_id);
        const { contact } = data;
        if (contact) {
          const info = {
            _id,
            name: contact.name,
            email: contact.visitorEmails && contact.visitorEmails.length > 0 ? contact.visitorEmails[0].address : '',
            phone: contact?.phone[0].phoneNumber ?? '',
            documentType: (contact.livechatData && contact.livechatData.documentType) || '',
            documentNumber: (contact.livechatData && contact.livechatData.documentNumber) || '',
            tags: contact?.livechatData?.tags || '',
          };
          setBasicContactInfo(info);
        }
      }
    },
    [serviceSelected]
  );

  const loadDiscussionInfo = useCallback(async (serviceId: string) => {
    const {
      data: { discussions = {} },
    } = await getDiscussions(serviceId);
    if (discussions.length > 0) {
      setDiscussionId(discussions[0]._id);
    } else {
      setDiscussionId('');
    }
  }, []);

  useEffect(() => {
    if (discussionNotification?.rid) {
      setDiscussionId(discussionNotification?.rid);
    }
  }, [discussionNotification?.rid]);

  const handleServiceSelected = useCallback(
    (service: IRoom) => {
      if (service?._id) {
        setServiceSelected(service);
        setChatId(service._id);
        loadUserInfo(service);
        loadDiscussionInfo(service._id);
      } else {
        setServiceSelected({} as IRoom);
        navigate('/', { replace: true });
      }
    },
    [loadDiscussionInfo, loadUserInfo, navigate]
  );

  const handleOpenDiscussion = useCallback((value: boolean) => {
    setDiscussionOpened(value);
  }, []);

  const toggleCloseChatModal = useCallback((action: ChatModalAction) => {
    if (action === ChatModalAction.Open) {
      setOpenModalCloseChat(true);
    } else if (action === ChatModalAction.Close) {
      setOpenModalCloseChat(false);
    }
  }, []);

  const handleReload = useCallback(() => {
    setReload(!reload);
  }, [reload]);

  const loadRoomInfo = useCallback(async () => {
    const {
      data: { room = {} },
    } = await getRoomInfo(serviceSelected._id || chatId);
    handleServiceSelected(room);
  }, [chatId, handleServiceSelected, serviceSelected?._id]);

  const getSubscribesChats = useCallback(async () => {
    try {
      const { data } = await getSubscribes();
      setSubscribeChats(data.update.filter((item: SubscribeType) => item.t === 'l'));
    } catch (error) {
      logError('Error while fetching Subscribed Chats', error);
    }
  }, []);

  const handleActiveInput = useCallback(() => {
    const { servedBy } = serviceSelected;
    if (servedBy) {
      if (servedBy._id === currentUser.userId) {
        setActiveInput(true);
        return;
      }
    }
    setActiveInput(false);
  }, [currentUser.userId, serviceSelected]);

  const checkIfExistChat = async (ticket: string) => {
    const {
      data: { rooms = [] },
    } = await getChatsByCustomField(ticket);

    if (rooms.length > 0) {
      return true;
    }
    return false;
  };

  const generateTicket = useCallback(async (ticketParam: string) => {
    if (ticketParam) return ticketParam;

    const ticket = generateTicketId();
    let exists = false;
    exists = await checkIfExistChat(ticket);

    if (exists) {
      let tryAgain = 0;
      do {
        tryAgain += 1;
        exists = await checkIfExistChat(ticket);
      } while (exists && tryAgain < MAX_ATTEMPTS);

      return '';
    }

    return ticket;
  }, []);

  const initChat = useCallback(async () => {
    try {
      if (serviceSelected?._id) {
        const hasTicket = await generateTicket(serviceSelected.livechatData?.ticket ?? '');

        if (!hasTicket) {
          return;
        }
        const { data: dataInquiry } = await getInquiry(serviceSelected._id);

        const {
          inquiry: { _id },
        } = dataInquiry;

        if (_id) {
          const { data: response } = await takeInquiry(_id);

          if (response.success) {
            setActiveInput(true);
            const paramsSaveRoomInfo = {
              chatId: serviceSelected._id,
              contactId: serviceSelected.v._id,
              tags: serviceSelected.tags,
              uuid: uuidv4(),
            };

            await saveRoomInfo(paramsSaveRoomInfo);
            navigate(`/${serviceSelected._id}`);
            setTimeout(() => {
              loadRoomInfo();
            }, 500);
            return;
          }
          setActiveInput(false);
        }
      }
    } catch (error) {
      logError('initchatError', error);
    }
  }, [generateTicket, loadRoomInfo, navigate, serviceSelected]);

  const putChatOnHold = useCallback(async (roomId: string) => {
    setChatId(roomId);
    await putChatOnHoldService(roomId);
  }, []);

  const goToNewChat = useCallback(
    (service: IRoom) => {
      navigate(`/${service._id}`);
    },
    [navigate]
  );

  const handleUnreadMessage = useCallback(async (id: string, unread: boolean) => {
    try {
      if (unread) {
        await unreadMessage(id);
        return;
      }
      await readMessage(id);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSelectedItems = useCallback(() => {
    setIsSelectedItems((item) => !item);
  }, []);

  const handleFinishChat = useCallback((room: IRoom) => {
    loadUserInfo(room);
    toggleCloseChatModal(ChatModalAction.Close);
    setChatId(room._id);
  }, []);

  const handleDisableInput = () => {
    const livechatStatusIsAvailable = currentUserInfo.statusLivechat === 'available';

    if (serviceSelected?.servedBy && serviceSelected?.servedBy._id !== currentUserInfo._id) {
      setDisabledInputChat(true);
      setAlertBarMessage(t('chatAssignedtoAnotherAgent'));
      return;
    }

    if (!serviceSelected?.open) {
      setDisabledInputChat(true);
      setAlertBarMessage('');
      return;
    }
    if (
      currentUserInfo.livechat &&
      currentUserInfo.livechat.maxNumberSimultaneousChat !== '' &&
      inAttendanceChatsLength === Number(currentUserInfo.livechat.maxNumberSimultaneousChat)
    ) {
      setServiceLimitReached(true);
      if (
        (!serviceSelected.servedBy || serviceSelected.servedBy._id !== currentUser.userId) &&
        livechatStatusIsAvailable
      ) {
        setDisabledInputChat(true);
      } else {
        setDisabledInputChat(false);
      }
    } else if (livechatStatusIsAvailable) {
      setServiceLimitReached(false);
      setDisabledInputChat(false);
      setAlertBarMessage('');
    } else if (
      (!serviceSelected.servedBy ||
        (serviceSelected.servedBy && serviceSelected.servedBy._id !== currentUser.userId)) &&
      !livechatStatusIsAvailable
    ) {
      setDisabledInputChat(true);
      setAlertBarMessage(t('changeStatusToStart'));
    } else {
      setServiceLimitReached(false);
      setDisabledInputChat(false);
      setAlertBarMessage('');
    }
  };

  const loadGroupMembers = async (roomIdParam: string) => {
    const { data } = await getGroupMembers(roomIdParam);
    const { members } = data;
    const exists = members.some((item) => item._id === currentUserInfo._id);
    setUserInDiscussionGroup(exists);
  };

  const handleTrySendAgainMessage = useCallback(async () => {
    try {
      const sendMessagePromises = messagesListErrors.map(async (message) => {
        await sendMessage(message.msg, message.rid);
      });

      await Promise.all(sendMessagePromises);

      handleErrorNewMessage();
      setMessagesListErrors([]);
    } catch (error) {
      console.log(error);
    }
  }, [handleErrorNewMessage, messagesListErrors]);

  const handleAddErrorMessages = useCallback(async (message: MessageType) => {
    setMessagesListErrors((oldState) => [...oldState, message]);
  }, []);

  useEffect(() => {
    handleDisableInput();
    if (serviceSelected?._id && currentUserInfo._id && discussionId) {
      loadGroupMembers(discussionId);
    }
  }, [serviceSelected?.servedBy, serviceSelected?._id, discussionId, currentUserInfo._id, inAttendanceChatsLength]);

  useEffect(() => {
    handleDisableInput();
  }, [currentUserInfo.status]);

  const memoizedValues = useMemo(
    () => ({
      activeInput,
      serviceSelected,
      handleActiveInput,
      initChat,
      handleServiceSelected,
      inAttendanceChatsLength,
      setInAttendanceLength,
      handleUnreadMessage,
      isSelectedItems,
      handleSelectedItems,
      subscribeChats,
      getSubscribesChats,
      handleReload,
      basicContactInfo,
      openModalCloseChat,
      toggleCloseChatModal,
      loadUserInfo,
      handleFinishChat,
      chatId,
      setServiceSelected,
      errorCloseChat,
      setErrorCloseChat,
      putChatOnHold,
      serviceLimitReached,
      disabledInputChat,
      loadRoomInfo,
      loadDiscussionInfo,
      discussionId,
      alertBarMessage,
      handleOpenDiscussion,
      discussionOpened,
      userIsInDiscussion,
      setEmoji,
      emoji,
      handleAddErrorMessages,
      handleTrySendAgainMessage,
      goToNewChat,
      isMobile,
      setIsMobile,
    }),
    [
      activeInput,
      serviceSelected,
      handleActiveInput,
      initChat,
      handleServiceSelected,
      inAttendanceChatsLength,
      setInAttendanceLength,
      handleUnreadMessage,
      isSelectedItems,
      handleSelectedItems,
      subscribeChats,
      getSubscribesChats,
      handleReload,
      basicContactInfo,
      openModalCloseChat,
      toggleCloseChatModal,
      loadUserInfo,
      handleFinishChat,
      chatId,
      setServiceSelected,
      errorCloseChat,
      setErrorCloseChat,
      putChatOnHold,
      serviceLimitReached,
      disabledInputChat,
      loadRoomInfo,
      loadDiscussionInfo,
      discussionId,
      alertBarMessage,
      handleOpenDiscussion,
      discussionOpened,
      userIsInDiscussion,
      setEmoji,
      emoji,
      handleAddErrorMessages,
      handleTrySendAgainMessage,
      goToNewChat,
      isMobile,
      setIsMobile,
    ]
  );

  return <ChatsContext.Provider value={memoizedValues}>{children}</ChatsContext.Provider>;
};

export default ChatsProvider;
