import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { Agent, Department } from './types';

interface DepartmentFullInfo extends Department {
  maxNumberSimultaneousChat: string;
  visitorInactivityTimeoutInSeconds: string;
  ancestors: string[];
  parentId: string;
}

type GetDepartmentFullInfo = { agents: Agent[]; department: DepartmentFullInfo };

export const getDepartmentInfo = async (id: string): Promise<AxiosResponse<GetDepartmentFullInfo>> =>
  AxiosInstance.get(`livechat/department/${id}`);
