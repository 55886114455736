/* eslint-disable  no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBusinessHoursStore, ISelectedSchedule, IDepartment } from 'types/businessHours';
import { DEFAULT_DAY_CONFIG } from 'views/ServiceManagement/BusinessHours/constants';
import { DayOfWeek, HourRange, ISetSchedule, IWorkHour } from 'views/ServiceManagement/BusinessHours/types';

export const initialState: IBusinessHoursStore = {
  selectedSchedule: {
    id: '',
    name: '',
    departmentIds: [],
    timezone: 'Pacific/Midway',
    workHours: [],
    days: [],
  },
  useSameSchedule: true,
  opens: DEFAULT_DAY_CONFIG.opens,
  closes: DEFAULT_DAY_CONFIG.closes,
  scheduleList: [],
  departments: [],
  isLoading: false,
};

export const businessHoursSlice = createSlice({
  name: 'businessHoursSlice',
  initialState,
  reducers: {
    setSchedule: (state, action: PayloadAction<ISelectedSchedule>) => {
      state.selectedSchedule = action.payload;
    },
    setScheduleName: (state, action: PayloadAction<string>) => {
      state.selectedSchedule.name = action.payload;
    },
    setSelectedDepartment: (state, action: PayloadAction<string[]>) => {
      state.selectedSchedule.departmentIds = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.selectedSchedule.timezone = action.payload;
    },
    setDays: (state, action: PayloadAction<string[]>) => {
      const selectedDays = action.payload;
      state.selectedSchedule.days = selectedDays;
      const schedule = selectedDays.map((day: string) => {
        const previousValue = state.selectedSchedule.workHours.find((item) => item.dayOfWeek === day);
        if (previousValue) {
          return previousValue;
        }

        if (state.useSameSchedule) {
          return {
            dayOfWeek: day as DayOfWeek,
            opens: state.opens,
            closes: state.closes,
          };
        }
        return {
          dayOfWeek: day as DayOfWeek,
          ...DEFAULT_DAY_CONFIG,
        };
      });
      state.selectedSchedule.workHours = schedule;
    },
    setWorkHours: (state, action: PayloadAction<IWorkHour[]>) => {
      state.selectedSchedule.workHours = action.payload;
    },
    clearSchedule: (state) => {
      state.selectedSchedule = initialState.selectedSchedule;
      state.useSameSchedule = true;
      state.opens = DEFAULT_DAY_CONFIG.opens;
      state.closes = DEFAULT_DAY_CONFIG.closes;
    },
    setDepartments: (state, action: PayloadAction<IDepartment[]>) => {
      state.departments = action.payload;
    },
    setUseSameSchedule: (state, action: PayloadAction<boolean>) => {
      state.useSameSchedule = action.payload;

      if (action.payload) {
        const newSchedules = state.selectedSchedule.workHours.map((day) => ({
          ...day,
          [HourRange.START]: state.opens,
          [HourRange.END]: state.closes,
        }));
        state.selectedSchedule.workHours = newSchedules;
      }
    },
    setCommonSchedule: (state, action: PayloadAction<ISetSchedule>) => {
      const { scheduleType } = action.payload;
      const newSchedule = action.payload.schedule;
      if (scheduleType === HourRange.START) {
        state.opens = newSchedule;
      } else {
        state.closes = newSchedule;
      }

      const newSchedules = state.selectedSchedule.workHours.map((day) => ({
        ...day,
        [scheduleType]: newSchedule,
      }));

      state.selectedSchedule.workHours = newSchedules;
    },
    setScheduleList: (state, action: PayloadAction<ISelectedSchedule[]>) => {
      state.scheduleList = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setSchedule,
  setScheduleName,
  setDays,
  setSelectedDepartment,
  setTimezone,
  setWorkHours,
  clearSchedule,
  setDepartments,
  setScheduleList,
  setIsLoading,
  setUseSameSchedule,
  setCommonSchedule,
} = businessHoursSlice.actions;

export default businessHoursSlice.reducer;
