import React from 'react';
import { Avatar, Stack } from '@mui/material';
import PreviewTemplateContent from 'components/PreviewTemplateContent';
import PreviewTemplateButtons from 'components/PreviewTemplateButtons';

export const PreviewContainer = ({ currentUserInfo, t, previewText, templateSelected, isMobile }: any) => {
  const { Messages = [] } = templateSelected;
  const { Buttons = [] } = Messages[0] || {};
  return (
    <Stack
      sx={{
        width: isMobile ? '100%' : '38.5%',
        padding: isMobile ? '0px 16px' : '16px 24px 0px 16px',
      }}
    >
      <Stack direction="row" paddingBottom="8px" alignItems="center">
        <Avatar
          sx={{
            width: '20px',
            height: '20px',
            marginRight: '8px',
            fontSize: ' 80%',
          }}
          variant="circular"
        >
          {currentUserInfo?.name?.slice(0, 1)}
        </Avatar>
        {t('you')}
      </Stack>
      <PreviewTemplateContent Message={Messages[0]} isMobile={isMobile} previewText={previewText} />
      <PreviewTemplateButtons Buttons={Buttons} />
    </Stack>
  );
};
