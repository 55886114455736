import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ErrorMessageProps } from './types';

export const ErrorMessage = ({ translation, connectionError }: ErrorMessageProps) => (
  <Stack
    sx={{
      backgroundColor: 'error.main',
      height: connectionError ? '60px' : '40px',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography textAlign="center" variant="overline" color="common.white">
      {connectionError ? translation('errorConnectionSocket') : translation('tokenExpired')}
    </Typography>
  </Stack>
);
