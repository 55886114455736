import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const createDiscussion = async (
  prid: string,
  t_name: string,
  reply: string,
  users?: Array<string>,
  pmid?: string // mensagem citada
): Promise<AxiosResponse> =>
  AxiosInstance.post('rooms.createDiscussion', {
    prid,
    t_name,
    reply,
    users,
    pmid,
  });
