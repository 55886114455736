import { Button } from '@engyalo/design-system';
import { styled } from '@mui/material/styles';

export const RoundedButton = styled(Button)`
  width: 7.5rem;
  height: 30px;
  border-radius: 40px;
  border: 0;
  color: ${({ theme, ...props }) =>
    props['aria-selected'] ? theme.palette.primary.lighter : theme.palette.primary.main};
  background: ${({ theme, ...props }) =>
    props['aria-selected'] ? theme.palette.primary.main : theme.palette.primary.lighter};

  &:hover {
    border: 0;
    color: ${({ theme, ...props }) =>
      props['aria-selected'] ? theme.palette.primary.contrastText : theme.palette.primary.darker};
    background: ${({ theme, ...props }) =>
      props['aria-selected'] ? theme.palette.primary.darker : theme.palette.primary.light};
  }
`;
