import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { IRoom } from '../types/room';

export enum sortValue {
  ASCENDING = 1,
  DESCENDING = -1,
}

type Sort = {
  [K in keyof Partial<IRoom>]: sortValue.ASCENDING | sortValue.DESCENDING;
};

export const getInquiries = async (count?: number, sort: Sort = { ts: sortValue.DESCENDING }): Promise<AxiosResponse> =>
  AxiosInstance.get('livechat/inquiries.queued', {
    params: {
      sort,
      count,
    },
  });
