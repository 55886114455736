import DrawerMessages from 'components/DrawerMessages';
import { DrawerMessageType } from 'components/DrawerMessages/types.d';
import DrawerContext from 'contexts/drawer/context';
import { useContext } from 'react';

const SearchDrawer: React.FC = () => {
  const { handleTabSelection } = useContext(DrawerContext);

  const handleDrawerClose = () => {
    handleTabSelection(null);
  };

  return (
    <DrawerMessages
      roomId=""
      type={DrawerMessageType.SEARCH}
      user="agent"
      // The flow of handling the drawer and the use of context should be reviewed.
      onClose={handleDrawerClose}
    />
  );
};

export default SearchDrawer;
