import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Switch } from '@engyalo/design-system';
import { logError } from 'services/Logger';
import { useRequestConfirmation } from 'hooks/useRequestConfirmation';
import { updateUserActiveStatus } from 'services/updateUserActiveStatus';
import AlertsContext, { Severity } from 'contexts/alerts/context';
import AvatarStatus from 'components/AvatarStatus';
import { DrawerType, ILoadUserList, onHandleFormType } from './types';
import useEnvironment from '../../../hooks/useEnvironments';
import { getUsersList } from '../../../services/getUsersList';
import { getLicenses } from '../../../services/getLicenses';
import { getUserInfo } from '../../../services/getUserInfo';
import AuthContext from '../../../contexts/auth/context';
import { getUserRoles } from '../../../helpers/getUserRoles';
import {
  UserInfo,
  UserOnlineStatus,
  UserTypes,
  UserStatusFilterState,
  UserStatusFilterStateValues,
} from '../../../services/types';

const internalTypes = [`${UserTypes.System}`, `${UserTypes.Bot}`, `${UserTypes.App}`, `${UserTypes.Admin}`];
const timeout = 60000; // 60 seconds
let loadUserListInterval: NodeJS.Timeout | null = null;

const useUsersManager = () => {
  const { t } = useTranslation();
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  const [userList, setUserList] = useState<Array<UserInfo>>([]);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState<DrawerType>('view');
  const [userSelectedId, setUserSelectedId] = useState('');
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModalSeatsAvailableUsers, setShowModalSeatsAvailableUsers] = useState(false);
  const [licenses, setLicenses] = useState({
    maxActiveUsers: 0,
    activeUsers: 0,
  });
  const [showModalEditPassword, setShowModalEditPassword] = useState(false);
  const [userSelected, setUserSelected] = useState<UserInfo>({} as UserInfo);
  const [currentUserSelected, setCurrentUserSelected] = useState<UserInfo>({} as UserInfo);
  const [showErrorConnectionModal, setShowErrorConnectionModal] = useState(false);
  const [userSearchFilter, setUserSearchFilter] = useState('');
  const [userActiveFilter, setUserActiveFilter] = useState<UserStatusFilterStateValues>(UserStatusFilterState.All);
  const [lastUpdate, setLastUpdate] = useState('');
  const { addAlert } = useContext(AlertsContext);
  const { currentUserInfo } = useContext(AuthContext);
  const { _id: currentUserId } = currentUserInfo;

  const loadLicensesInfo = useCallback(async () => {
    try {
      const {
        data: { maxActiveUsers, activeUsers },
      } = await getLicenses();
      setLicenses({ maxActiveUsers: maxActiveUsers ?? 0, activeUsers: activeUsers ?? 0 });
    } catch (error) {
      logError('Error while fetching licenses info', error);
    }
  }, []);

  const loadUserList = useCallback(async ({ search = '', active, backGroundLoading }: ILoadUserList = {}) => {
    try {
      setLoading(() => !backGroundLoading);
      const {
        data: { users },
      } = await getUsersList(search, active);

      const filteredUsers = users.filter((el) => {
        const { type = `${UserTypes.User}`, roles = [] } = el;
        const isInternal = internalTypes.includes(type);
        const isAdmin = roles.includes('admin');
        return !isInternal && !isAdmin;
      });
      setLastUpdate(format(new Date(), 'PPpp'));
      setUserList(filteredUsers);
    } catch (error) {
      logError('Error while fetching users list', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const {
    requestConfirmation,
    onConfirm: onConfirmActivationToggle,
    onCancel: onCancelActivationToggle,
    isLoading: isLoadingActivationToggle,
    isRequesting: isRequestingActivationToggle,
  } = useRequestConfirmation(async (userId: string, value: boolean) => {
    try {
      await updateUserActiveStatus(userId, value);
      Promise.all([loadUserList({ search: userSearchFilter }), loadLicensesInfo()]);
      setCurrentUserSelected({} as UserInfo);
      addAlert({
        severity: Severity.SUCCESS,
        message: t('changeUserStatusSuccess'),
      });
    } catch (e) {
      logError(`Error while updating user status with id: ${userId} to value: ${value}`);
    }
  });

  const handleModalSeatsAvailable = () => {
    setShowModalSeatsAvailableUsers((old) => !old);
  };

  const handleDrawerType = (type: DrawerType) => {
    setDrawerType(type);
  };

  const handleShowDrawer = (confirm?: boolean) => {
    if (isChangeForm && !confirm && drawerType !== 'view') {
      setUnsavedChanges(true);
      return;
    }
    setUnsavedChanges(false);
    setIsChangeForm(false);
    setShowDrawer((old) => !old);
  };

  const newUser = () => {
    if (licenses.maxActiveUsers - licenses.activeUsers === 0) {
      handleModalSeatsAvailable();
      return;
    }
    setUserSelectedId('');
    setCurrentTabValue(0);
    handleShowDrawer();
    handleDrawerType('new');
  };

  const loadUserInfo = useCallback(async (id: string) => {
    try {
      const {
        data: { user },
      } = await getUserInfo(id);
      const isManagerValue = user.roles.includes('livechat-manager');

      setIsManager(isManagerValue);
      setUserSelected(user);
    } catch (error) {
      logError('Error while fetching user info', error);
    }
  }, []);

  const handleShowEditingDrawer = () => {
    handleDrawerType('edit');
  };

  const handleClickItem = (user: UserInfo) => {
    const { _id: userId } = user;
    if ((drawerType === 'edit' || drawerType === 'new') && isChangeForm) {
      setUnsavedChanges(true);
      return;
    }
    if (!showDrawer) {
      handleShowDrawer();
    }
    handleDrawerType('view');
    setUserSelectedId(userId);
    setCurrentUserSelected(user);
    loadUserInfo(userId);
    setCurrentTabValue(0);
  };

  const onHandleForm = useCallback(
    (prop: onHandleFormType, userId?: string) => {
      if (prop === 'changeTab' && userId) {
        setUserSelectedId(userId);
        setCurrentTabValue(1);
      }
      if (prop === 'close') {
        handleDrawerType('view');
        loadUserList({ search: userSearchFilter, active: userActiveFilter });
        loadLicensesInfo();
        setCurrentTabValue(0);
        loadUserInfo(userId ?? '');
      }
    },
    [userSearchFilter, userActiveFilter, loadUserList, loadLicensesInfo, loadUserInfo]
  );

  const changeForm = useCallback((isChanged: boolean) => {
    setIsChangeForm(isChanged);
  }, []);

  const handleShowModalEditPassword = () => {
    setShowModalEditPassword((state) => !state);
  };

  const handleShowModalError = () => {
    setShowErrorModal((state) => !state);
  };

  const handleConfirmActivationToggle = () => {
    onConfirmActivationToggle(currentUserSelected._id, !currentUserSelected.active);
  };

  const searchUser = useCallback(
    ({ search = userSearchFilter, active = userActiveFilter }: ILoadUserList = {}) => {
      loadUserList({ search, active });
      setUserSearchFilter(search);
      setUserActiveFilter(active);
    },
    [userSearchFilter, userActiveFilter, loadUserList]
  );

  // TODO: Uncomment when the functionality is implemented
  // const onToggleUserStatus = useCallback((userId: string, status: boolean) => {
  //   // toggle status functionality goes here
  // }, []);
  const handleCancelActivationToggle = () => {
    setCurrentUserSelected({} as UserInfo);
    onCancelActivationToggle();
  };

  // TODO: Uncomment when the functionality is implemented
  // const onDeleteUser = useCallback((userId: string) => {
  //   // delete user functionality goes here
  // }, []);

  const columns = useMemo<GridColDef<UserInfo>[]>(
    () => [
      {
        field: 'name',
        headerName: t('name'),
        renderCell: ({ row }) => {
          const { name, status, active } = row;
          const onlineStatus = !active ? UserOnlineStatus.Inactive : status;
          const src = `${baseUrl}/avatar/${row.username}?etag=${row.avatarETag}`;

          return (
            <AvatarStatus
              active={active}
              src={src}
              name={name}
              onlineStatus={onlineStatus || UserOnlineStatus.Offline}
            />
          );
        },
      },
      {
        field: 'username',
        headerName: t('username'),
      },
      {
        field: 'emails',
        headerName: t('email'),
        valueGetter: (params) => params?.row?.emails?.[0].address,
      },
      {
        field: 'roles',
        headerName: t('role'),
        valueGetter: (params) =>
          getUserRoles(params.row.roles)
            .map((item) => t(`${item}`))
            .join(', '),
      },
      {
        field: 'active',
        headerName: t('userActive'),
        width: 100,
        renderCell: ({ row, value }) => (
          <Switch
            checked={value}
            onClick={(e) => e.stopPropagation()}
            onChange={() => {
              const hasAvailableSeats = licenses.maxActiveUsers - licenses.activeUsers > 0;
              if (!value && !hasAvailableSeats) {
                setShowModalSeatsAvailableUsers(true);
              } else {
                setCurrentUserSelected(row);
                requestConfirmation();
              }
            }}
          />
        ),
      },
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   headerName: '',
      //   width: 56,
      //   getActions: ({ row }) => [
      //     // TODO: Import Tooltip and GridActionsCellItem components from @engyalo/design-system
      //     <Tooltip title={t('delete')}>
      //       <GridActionsCellItem
      //         label={t('delete')}
      //         // TODO: Import Icon component from @mui/material
      //         icon={<Icon className="fa-solid fa-trash" />}
      //         color="neutral"
      //         onClick={() => onDeleteUser(row._id)}
      //       />
      //     </Tooltip>
      //   ],
      // }
    ],
    [baseUrl, licenses, requestConfirmation, t]
  );

  // Load user list on component mount
  useEffect(() => {
    loadUserList({});
    loadLicensesInfo();
  }, [loadUserList, loadLicensesInfo]);

  // Load user list every x time
  useEffect(() => {
    loadUserListInterval = setInterval(() => {
      loadUserList({ search: userSearchFilter, active: userActiveFilter, backGroundLoading: true });
    }, timeout);

    return () => {
      if (loadUserListInterval) {
        clearInterval(loadUserListInterval);
      }
    };
  }, [userSearchFilter, userActiveFilter, loadUserList]);

  // Load user info when currentUserId changes
  useEffect(() => {
    if (currentUserId) {
      loadUserInfo(currentUserId);
    }
  }, [currentUserId, loadUserInfo]);

  return {
    columns,
    userList,
    loading,
    licenses,
    showModalSeatsAvailableUsers,
    showDrawer,
    drawerType,
    userSelectedId,
    currentTabValue,
    unsavedChanges,
    handleModalSeatsAvailable,
    newUser,
    loadUserList,
    handleClickItem,
    handleShowDrawer,
    onHandleForm,
    changeForm,
    setUnsavedChanges,
    handleShowEditingDrawer,
    setCurrentTabValue,
    isManager,
    showModalEditPassword,
    handleShowModalEditPassword,
    userSelected,
    handleShowModalError,
    showErrorModal,
    showErrorConnectionModal,
    setShowErrorConnectionModal,
    currentUserSelected,
    userActiveFilter,
    userSearchFilter,
    lastUpdate,
    searchUser,
    isRequestingActivationToggle,
    handleConfirmActivationToggle,
    handleCancelActivationToggle,
    isLoadingActivationToggle,
  };
};

export default useUsersManager;
