import React, { useEffect, useRef } from 'react';
import GenericModal from '../Modal';
import { PreviewBubbleMessage, TextMessage } from './style';

export interface IPreviewMessageProps {
  open: boolean;
  message: string;
  handleClose: () => void;
  title: string;
}

const PreviewMessageModal: React.FC<IPreviewMessageProps> = (props: IPreviewMessageProps) => {
  const { message, open, handleClose, title } = props;
  const textRef = useRef({} as HTMLParagraphElement);
  const textFormatation = [
    { pattern: /\*(.*?)\*/g, value: '<b>$1</b>' },
    { pattern: /\_(.*?)\_/g, value: '<i>$1</i>' },
    { pattern: /\~(.*?)\~/g, value: '<del>$1</del>' },
    { pattern: /\`(.*?)\`/g, value: "<a href='$1'>$1</a>" },
  ];

  const handleMessage = () => {
    let str = message;
    textFormatation.forEach((item) => {
      str = str.replaceAll(item.pattern, item.value);
    });

    return str;
  };

  useEffect(() => {
    handleMessage();
  }, [message, textRef.current]);

  return (
    <GenericModal hasCloseIcon handleModal={handleClose} open={open} title={title}>
      {message && (
        <PreviewBubbleMessage>
          <TextMessage
            data-testid="previewMessage"
            variant="body1"
            color="common.white"
            dangerouslySetInnerHTML={{ __html: handleMessage() }}
          />
        </PreviewBubbleMessage>
      )}
    </GenericModal>
  );
};

export default PreviewMessageModal;
