import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { CannedMessage } from './types';

export const getCannedMessages = async (
  text?: string,
  scope?: string,
  createdBy?: string
): Promise<AxiosResponse<GetAllCannedResponses>> =>
  AxiosInstance.get('canned-responses', {
    params: {
      text,
      scope,
      createdBy,
    },
  });

type GetAllCannedResponses = {
  cannedResponses: CannedMessage[];
  count: number;
  offset: number;
  total: number;
  success: boolean;
};
