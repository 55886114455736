import { Box, Typography, Icon as IconComponent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: flex-start;
`;

export const NumberOfClientsWaiting = styled(Typography)`
  font-weight: bold;
`;

export const Icon = styled(IconComponent)`
  color: ${({ theme }) => theme.palette.neutral.var70};
`;
