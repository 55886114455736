import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { UserStatus } from '../types/user';

type AvailableUser = {
  _id: string;
  username: string;
  status: UserStatus;
  name: string;
  nickname: string;
};
interface IGetAvailableUsers {
  items: AvailableUser[];
  success: boolean;
}

export interface IGetAvailableUsersParams {
  agentUsernames?: string[];
  loggedUser?: string;
  filter?: string;
  statusLivechat?: string;
}

export const getAvailableUsers = async ({
  agentUsernames,
  loggedUser,
  filter,
  statusLivechat,
}: IGetAvailableUsersParams): Promise<AxiosResponse<IGetAvailableUsers>> =>
  AxiosInstance.get('users.autocomplete', {
    params: {
      selector: {
        term: filter ?? '',
        conditions: {
          _id: loggedUser && { $ne: loggedUser },
          username: agentUsernames && { $in: agentUsernames },
          statusLivechat,
        },
      },
    },
  });
