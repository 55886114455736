import { styled } from '@mui/material/styles';

export const FileContainer = styled('div')`
  cursor: pointer;
  padding: 0.8rem;
  border-radius: 0.4rem;
  transition: background-color 0.3s;
  min-width: 15rem;
  height: 10rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.var20};
  }

  i {
    font-size: 3.5rem;
    margin-bottom: 0.8rem;
    color: ${({ theme }) => theme.palette.neutral.var50};
  }
`;

export const FileAnchor = styled('a', {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{ loading: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;
  i.file {
    opacity: ${({ loading }) => loading && 0.2};
  }
  i.loading {
    position: absolute;
    animation: spin 2s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
