import { Box } from '@mui/material';
import { styled, shadow8 } from '@engyalo/design-system';

export const ModalWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40rem;
  max-width: 48rem;
  padding: 3.2rem;
  border-radius: 8px;
  background-color: ${theme.palette.common.white};
  box-shadow: ${shadow8};

  .isFullScreen {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  ${theme.breakpoints.down('md')} {
    min-width: 3.2rem;
    width: calc(100% - 16px);
    padding: 1.6rem;
  }
`
);

export const ModalHeader = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: '-webkit-fill-available';
  
  .hasCloseIcon & {
    justify-content: space-between;
  }

  ${theme.breakpoints.down('md')} {
    padding-bottom: 0.8rem;
    border-bottom: 1px solid ${theme.palette.divider};
  }
`
);
