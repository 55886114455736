import { styled } from '@mui/material/styles';

export enum AuthorRoles {
  USER = 'user',
  AGENT = 'agent',
  BOT = 'bot',
}

export const MessageRow = styled('div')<{ error?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  cursor: ${({ error }) => error && 'pointer'};

  i.status-icon {
    position: absolute;
    bottom: 0.6rem;
    right: 1.6rem;
    color: ${({ theme, error }) => (error ? theme.palette.error.main : theme.palette.neutral.var50)};
  }
`;

export const MessageWrapper = styled('div', {
  shouldForwardProp: (propName) => propName !== 'authorRole',
})<{
  authorRole?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  overflow-wrap: anywhere;
  min-width: 16.8rem;
  align-items: ${({ authorRole }) => authorRole !== AuthorRoles.USER && 'flex-end'};
  align-self: ${({ authorRole }) => authorRole !== AuthorRoles.USER && 'flex-end'};

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      max-width: 30.4rem;
    }
    ${theme.breakpoints.up('md')} {
      max-width: 39.2rem;
    }
    ${theme.breakpoints.up('lg')} {
      max-width: 46.4rem;
    }
    ${theme.breakpoints.up('xl')} {
      max-width: 52rem;
    }
  `}

  &:hover .messageOptions {
    visibility: visible;
  }

  .MuiStack-root {
    margin-top: 0;
    min-width: unset;
  }
`;

export const InfoArea = styled('div')`
  margin-bottom: 0.8rem;
  padding: 0 0.8rem;
`;

export const ErrorMessage = styled('div')`
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: auto;
  margin-right: 0.8rem;
  margin-top: 0.2rem;
`;
