import { List, ListItem, Popover } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import InputSearch from '../../../../components/InputSearch';
import { IFilterDepartment, IFilterDepartmentsMenu } from './types';
import { OptionItem } from './style';
import Checkbox from '../../../../components/Checkbox';
import MenuChatsContext from '../../../../contexts/menuChats/context';

const MenuFilterDepartments: React.FC<IFilterDepartmentsMenu> = (props: IFilterDepartmentsMenu) => {
  const { anchorEl, handleClose, departments, isMobile } = props;
  const { t } = useTranslation();
  const { setDepartmentsSelected, departmentsSelected } = useContext(MenuChatsContext);

  const [AllfilterOptions, setAllFilterOptions] = useState<Array<IFilterDepartment>>([]);
  const [filterOptions, setFilterOptions] = useState<Array<IFilterDepartment>>([]);
  const open = Boolean(anchorEl);
  const [inputText, setInputText] = useState('');

  const handleOptions = () => {
    const list = departments.map((item) => ({
      department: { ...item },
      selected: false,
    }));
    let values = [
      ...filterOptions.map((item) => {
        if (!item.department._id) {
          item.selected = false;
        }
        const hasDepartment = departmentsSelected.find((dep) => dep === item.department._id);
        if (hasDepartment) {
          item.selected = true;
        }
        return item;
      }),
      ...list,
    ];

    if (departmentsSelected.length === 0) {
      values = values.map((item) => {
        item.selected = true;
        return item;
      });
    }
    setAllFilterOptions(values);
    setFilterOptions(values);
  };

  const handleChecked = (id: string, checked: boolean) => {
    setFilterOptions((oldState) =>
      oldState.map((item) => {
        if (!id || item.department._id === id) {
          item.selected = checked;
        }
        return item;
      })
    );
  };

  const filterDepartments = (search?: string) => {
    const options = [...AllfilterOptions];
    const filteredDepartments = options.filter((item) =>
      item.department.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || '')
    );

    setFilterOptions(filteredDepartments);
  };

  useEffect(() => {
    handleOptions();
  }, [departments]);

  useEffect(() => {
    const selects = filterOptions
      .filter((item) => item.selected && item.department._id)
      .map((item) => item.department._id);

    setDepartmentsSelected(selects);
  }, [filterOptions]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={
        isMobile
          ? {
              style: {
                width: '100%',
                margin: '0 8px !important',
              },
            }
          : {}
      }
      sx={{
        '.MuiPaper-root': isMobile
          ? {
              left: '8px !important',
              right: '8px !important',
              maxWidth: 'calc(100% - 18px) !important',
            }
          : '',
      }}
      data-testid="menuFilterDepartments"
    >
      <InputSearch
        placeholder="search"
        value={inputText}
        padding="1rem 1rem 0"
        onChange={(value) => {
          filterDepartments(value);
          setInputText(value);
        }}
      />
      <List sx={{ width: '100%' }}>
        <ListItem sx={{ height: '36px' }}>
          <OptionItem>
            <Checkbox
              selected={filterOptions.every((item) => item.selected)}
              handleChecked={(event) => handleChecked('', event.target.checked)}
            />
            <i className="fa-regular fa-inbox" />
            <Typography variant="caption1" color="neutral.var80">
              {t('allChats')}
            </Typography>
          </OptionItem>
        </ListItem>
        {filterOptions.map((item, index) => (
          <ListItem sx={{ height: '36px' }} key={index}>
            <OptionItem>
              <Checkbox
                selected={item.selected}
                handleChecked={(event) => handleChecked(item.department._id, event.target.checked)}
              />
              <i className="fa-regular fa-inbox" />
              <Typography variant="caption1" color="neutral.var80">
                {item.department.name}
              </Typography>
            </OptionItem>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default MenuFilterDepartments;
