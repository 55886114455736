const regexCheckEspecialCaracters = /[!@#$%^&*()_+\-=\[\];':"\\|,.<>\/?]+/;

export const replaceFieldsTable = (stringToReplace: string) => {
  const finalString = stringToReplace
    .split(' ')
    .map((el: any) => {
      const isVariable = el.includes('{');

      if (isVariable) {
        let replaceText = '';
        if (regexCheckEspecialCaracters.test(el)) {
          replaceText = el.substring(2, el.length - 3);
        } else {
          replaceText = el.substring(2, el.length - 2);
        }
        el = `<span class="HSMHighlightText">${replaceText}</span>`;
      }
      return el;
    })
    .join(' ');

  return finalString;
};
