import { useContext, useEffect, useRef, useState } from 'react';
import DepartmentContext from '../../../contexts/departments/context';
import { tagsColor } from '../../../helpers/tagsColors';
import { getListTags } from '../../../services/getTags';
import { TagsType } from '../../../types/tags';
import { responseCreateTagModal } from '../CreateTagModal/types';

interface IUseEditTags {
  handleChange: (value: string) => void;
  type: 'room' | 'visitor';
  currentTags?: string;
  open?: boolean;
  includeDisabled?: boolean;
}

const useEditTags = ({ handleChange, currentTags, type, open, includeDisabled }: IUseEditTags) => {
  const [isFocus, setFocus] = useState(false);
  const [value, setValue] = useState<TagsType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentTag, setCurrentTag] = useState<TagsType>();
  const [tags, setTags] = useState<TagsType[]>([]);

  const [pagination, setPagination] = useState({
    count: 100,
    offset: 0,
    total: false,
  });
  const [listTags, setListTags] = useState<TagsType[]>([]);
  const isFirstRender = useRef(true);
  const { departments } = useContext(DepartmentContext);
  const departmentsIds = departments.map((item) => item._id);

  const handleFocus = () => {
    setFocus((oldState) => !oldState);
  };

  const getSelectTags = async () => {
    const currentListTags = currentTags ? currentTags.split(';') : null;

    if (currentListTags) {
      let selectTags = [] as any;
      for (let index = 0; index < currentListTags.length; index += 1) {
        const tagName = currentListTags[index];
        if (tagName) {
          const hasTagInList = tags.find((item) => item.name === tagName);
          if (!hasTagInList) {
            const { data } = await getListTags({ includeDisabled, count: 1, offset: 0, text: tagName });
            const { tags: listTags } = data;
            if (!listTags.length) {
              currentListTags.splice(index, 1);
              currentTags = currentListTags.join(';');
            } else {
              selectTags = [...selectTags, ...listTags];
            }
          }
        }
      }
      selectTags = selectTags.map((item: TagsType) => {
        item.customColor = tagsColor(item.name);
        return item;
      });
      if (selectTags.length) {
        const newTags = [...tags, ...selectTags];
        handleDefaultValue(newTags);
      }
    }
  };

  const handleDefaultValue = (list?: TagsType[]) => {
    const currentListTags: string[] = currentTags?.split(';') || [];
    const filterTags: TagsType[] = [];

    if (currentListTags) {
      for (const currentListTag of currentListTags) {
        const currentItemTag = currentListTag;
        const tagsList = list ?? tags;
        for (const listTag of tagsList) {
          const tag = listTag;
          if (tag.name === currentItemTag) {
            filterTags.push(tag);
          }
        }
      }
    }
    if (filterTags.length !== currentListTags.length && currentListTags) {
      getSelectTags();
    }
    setTags(list ?? tags);
    setListTags(list ?? tags);
    setValue(filterTags);
  };

  const loadTags = async () => {
    if (pagination.total) return;
    const { data } = await getListTags({
      includeDisabled,
      count: pagination.count,
      offset: pagination.offset,
      text: type,
    });
    const { tags: listTags = [], total } = data;
    let newTags = listTags
      .map((item: TagsType) => {
        item.customColor = tagsColor(item.name);
        return item;
      })
      .filter((item: TagsType) => {
        const exist = tags.find((tag) => tag._id === item._id);
        if (!exist) return item;
      });

    if (type === 'room') {
      newTags = newTags.filter((item: TagsType) => {
        if (departmentsIds.some((dep) => item.departments.includes(dep)) || !item.departments.length) {
          return item;
        }
      });
    }
    const list = [...tags, ...newTags];
    if (newTags.length) {
      setTags(list);
      setListTags(list);
    }

    const isTotal = list.length === total || !newTags.length;
    setPagination((old) => ({
      count: old.count,
      offset: list.length,
      total: isTotal,
    }));
  };

  const handleCloseCreateModal = (confirm: boolean, tagValue?: responseCreateTagModal) => {
    if (currentTag && tagValue && currentTag.name !== tagValue.tagName) {
      const currentListTags: string[] = currentTags?.split(';') || [];
      const index = currentListTags.findIndex((item) => item === currentTag.name);

      if (index) {
        currentListTags[index] = tagValue.tagName;
      }

      currentTags = currentListTags.join(';');
    }
    if (confirm) {
      setPagination({ count: 100, offset: 0, total: false });
      loadTags();
    }
    setOpenModal((oldState) => !oldState);
    setCurrentTag(undefined);
  };

  const handleValue = () => {
    const newValue = value.map((item) => item.name).join(';');

    handleChange(newValue);
  };

  useEffect(() => {
    handleValue();
  }, [value]);

  useEffect(() => {
    if (currentTags && tags.length) {
      handleDefaultValue();
    }
  }, [tags, currentTags]);

  useEffect(() => {
    if (open || isFocus) {
      loadTags();
    }
  }, [open, pagination, isFocus]);

  // Set value with previous selected tags
  useEffect(() => {
    if (currentTags && isFirstRender.current)
      (async () => {
        const { data } = await getListTags({ includeDisabled, text: type });
        if (data.tags?.length) {
          const currentTagNames = currentTags.split(';');
          const previousSelectedTags = data.tags.filter((tag) => currentTagNames.includes(tag.name));
          setValue(previousSelectedTags);
          isFirstRender.current = false;
        }
      })();
  }, [currentTags, type, isFirstRender]);

  return {
    isFocus,
    openModal,
    setOpenModal,
    currentTag,
    setCurrentTag,
    handleFocus,
    handleDefaultValue,
    setValue,
    value,
    handleCloseCreateModal,
    listTags,
    loadTags,
    pagination,
  };
};

export default useEditTags;
