import { shouldForwardPropHelper } from '@engyalo/design-system';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const ButtonLanguage = styled('button', {
  shouldForwardProp: shouldForwardPropHelper,
})(
  ({ theme }) => `
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  gap: 1.6rem;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  color: ${theme.palette.neutral.var80};
  background: ${theme.palette.neutral.var10};

  &.selected {
    color: ${theme.palette.primary.main};
    background: ${theme.palette.neutral.var20};
    border: 1px solid ${theme.palette.primary.main};
  }
`
);
