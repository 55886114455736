import { createContext } from 'react';
import { IAgentOfDepartment } from '../transfer/provider';

export interface ISelectAgent {
  filterAgents: (value: string) => void;
  handleAgentSelected: (value: string, onClick: boolean) => void;
  allAgents: IAgentOfDepartment[];
  agentsFiltered: IAgentOfDepartment[];
  agentSelected: string;
  handleAgentSelection: () => void;
  handleAgentOnHover: (value: string) => void;
  resetAgents: () => void;
  showSelectAgent: boolean;
  handleSelectAgent: () => void;
  loadAgents: (filter?: string) => void;
}

const SelectAgentContext = createContext<ISelectAgent>({} as ISelectAgent);

export default SelectAgentContext;
