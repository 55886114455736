import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiButtonBase-root.MuiIconButton-root {
    color: ${(props) => props.theme.palette.neutral.var40};
  }
`;

export const CancelSelection = styled(Typography)`
  color: ${({ theme }) => theme.palette.neutral.var80};
  align-items: center;
  text-align: center;
  font-family: 'Circular TT';
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding-bottom: 12px;
`;

export const FiltersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;
