import { ModelsOutgoingMessage } from '@engyalo/salesdesk-api-ts-client';
import ScrollToBottomButton from 'components/ScrollToBottomButton';
import MessagesList from 'components/MessagesList';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { groupMessagesByDateStamp } from 'utils/messageUtils';
import { getChatHistory } from 'services/chat';
import { logError } from 'services/Logger';
import { NotFoundChats } from 'components/DrawerMessages/style';
import { useTranslation } from 'react-i18next';
import LoadingAnimation from 'components/AnimationLoading';
import { MILLIS_IN_ONE_DAY } from 'constants/';
import { CHAT_RETENTION_DAYS as CHAT_RETENTION_DAYS_DEFAULT } from 'constants/defaultValues';
import { useTheme } from '@mui/material/styles';
import { ButtonArea, LoadingWrapper, MessagesArea, Wrapper } from './styled';

type ChatsHistoryProps = {
  filterValue: string;
  chatId: string;
  chatDate: string;
};

const ChatsHistory: React.FC<ChatsHistoryProps> = (props) => {
  const { chatId, filterValue, chatDate } = props;
  const CHAT_RETENTION_DAYS = Number.parseInt(process.env.CHAT_RETENTION_DAYS || CHAT_RETENTION_DAYS_DEFAULT, 10);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<ModelsOutgoingMessage[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();

  const filteredConversations = useMemo(
    () =>
      filterValue
        ? messages.filter((message) => message.chat?.text?.body.toLowerCase().includes(filterValue.toLowerCase()))
        : messages,
    [filterValue, messages]
  );

  const groupedMessages = useMemo(() => groupMessagesByDateStamp(filteredConversations), [filteredConversations]);

  const isOldChat = useMemo(() => {
    if (!CHAT_RETENTION_DAYS) {
      return false;
    }

    const date = new Date(chatDate);
    const timeDifference = Date.now() - date.getTime();
    return timeDifference > CHAT_RETENTION_DAYS * MILLIS_IN_ONE_DAY;
  }, [CHAT_RETENTION_DAYS, chatDate]);

  const fetchChatHistory = useCallback(async (roomId: string) => {
    try {
      setLoading(true);
      const { data: messagesHistory } = await getChatHistory({ roomId });

      setMessages(messagesHistory);
    } catch (error) {
      logError(`Error while fetching chat history for room id ${roomId}`, error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChatHistory(chatId);
  }, [chatId, fetchChatHistory]);

  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingAnimation color={theme.palette.neutral.var90} />
      </LoadingWrapper>
    );
  }

  if (!filteredConversations.length) {
    return (
      <NotFoundChats variant="subtitle1" color="neutral.var70">
        {t('notFoundChatOrMessage')}
      </NotFoundChats>
    );
  }

  return (
    <Wrapper>
      {!isOldChat && (
        <>
          <MessagesArea ref={containerRef}>
            <MessagesList messages={groupedMessages} />
          </MessagesArea>
          <ButtonArea>
            <ScrollToBottomButton containerRef={containerRef} />
          </ButtonArea>
        </>
      )}
    </Wrapper>
  );
};

export default ChatsHistory;
