import React, { useState } from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { ITable } from './types';
import { IconSortButton, StyledTableCell, StyledTableCellHead, StyledTableRow, IconDelete, IconKebab } from './style';
import { RowsPerPage } from '../../constants/pagination';
import { SortDirection } from '../../constants';
import { IOrder } from '../../types/order';
import { KebabOptions } from '../KebabOptions';

const HIDE_SORT_ICON = ['sharedWith', 'open', 'closed'];

const getIconColor = (isArrowUp: boolean, sortDirection: string, sortBy: string, field: string, theme: Theme) => {
  const highlightedColor = theme.palette.neutral.var90;
  const greyedOutColor = theme.palette.neutral.var40;

  if (sortBy !== field) {
    return greyedOutColor;
  }

  if ((sortDirection === 'asc' && isArrowUp) || (sortDirection === 'desc' && !isArrowUp)) {
    return highlightedColor;
  }

  return greyedOutColor;
};

const CustomTable: React.FC<ITable> = (props: ITable) => {
  const theme = useTheme();

  const {
    headItems,
    rows,
    page,
    hasButtonOptions,
    handleClickItem,
    handleDeleteItem,
    hasKebabButton,
    handleOpenKebab,
    anchorElKebab,
    handleCallApi,
    order,
    orderBy,
    tableName,
  } = props;

  const { t } = useTranslation();
  const [orderState, setOrder] = React.useState<IOrder>(order || 'desc');
  const [orderByState, setOrderBy] = React.useState<string>(orderBy || headItems[0]);
  const [openKebab, setOpenKebab] = useState(false);
  const [idxFocused, setIndexFocus] = useState(-1);

  const [itemSelected, setItemSelected] = useState<any>(undefined);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOrderSort = (item: string, orderParam: IOrder) => {
    setOrder(orderParam);
    setOrderBy(item);
    if (handleCallApi) {
      if (orderParam === 'desc') {
        handleCallApi(item, SortDirection.DESC);
      } else {
        handleCallApi(item, SortDirection.ASC);
      }
    }
  };

  const getSortValue = (value: any, itemObj: any) => {
    if (orderByState === 'tags' && itemObj.hasOwnProperty('tagsList')) {
      return itemObj.tagsList.toLowerCase();
    }

    if (typeof value === 'object') {
      if (value?.props?.label) {
        return value.props.label.toLowerCase();
      }
      if (value?.props?.children?.length) {
        return value.props.children[1].toLowerCase();
      }
      if (orderByState === 'status' && itemObj) {
        return itemObj.statusValue.toLowerCase();
      }
      if (orderByState === 'teams' && itemObj) {
        return itemObj.teamsValue.toLowerCase();
      }
      if (orderByState === 'enable' && itemObj) {
        return value.props.checked;
      }
    }
    return value;
  };

  const handleSort = (rows: Array<any>) => {
    if (handleCallApi && rows.length) return rows;
    if (rows.length) {
      const values = rows.sort((a: any, b: any) => {
        const aValue: string =
          orderByState === 'updatedAt'
            ? a.dateValue
            : typeof a[orderByState] === 'string'
            ? a[orderByState].toLowerCase()
            : getSortValue(a[orderByState], a);

        const bValue: string =
          orderByState === 'updatedAt'
            ? b.dateValue
            : typeof b[orderByState] === 'string'
            ? b[orderByState].toLowerCase()
            : getSortValue(b[orderByState], b);
        if (orderState === 'desc') {
          return aValue < bValue ? -1 : 1;
        }
        return aValue > bValue ? -1 : 1;
      });

      if (rows.length > RowsPerPage) {
        return values.slice((page - 1) * RowsPerPage, (page - 1) * RowsPerPage + RowsPerPage);
      }
      return values;
    }
    return [];
  };

  const handleKebab = () => {
    setOpenKebab((state) => !state);
  };

  const handleSortBy = (item: string) => {
    if (!HIDE_SORT_ICON.includes(item)) {
      return (
        <Stack marginLeft="2px" direction="row" gap="4px">
          <IconSortButton>
            <i
              role="presentation"
              data-testid="orderByAsc"
              className="fa-light fa-arrow-up fa-xs"
              style={{
                color: getIconColor(true, orderState, orderByState, item, theme),
              }}
              onClick={() => handleOrderSort(item, 'asc')}
            />
          </IconSortButton>
          <IconSortButton>
            <i
              role="presentation"
              data-testid="orderByDesc"
              className="fa-light fa-arrow-down fa-xs"
              style={{
                color: getIconColor(false, orderState, orderByState, item, theme),
              }}
              onClick={() => handleOrderSort(item, 'desc')}
            />
          </IconSortButton>
        </Stack>
      );
    }

    return <div data-testid="hiddenSortIcon" />;
  };
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table data-testid="custom-table" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {headItems.map((item: any, index: number) => (
                  <StyledTableCellHead
                    sortDirection={orderByState === item ? orderState : false}
                    key={`${item}-${index}`}
                    onMouseOver={() => {
                      item === 'label' && setShowTooltip(true);
                    }}
                    onMouseLeave={() => {
                      setShowTooltip(false);
                    }}
                  >
                    <Tooltip open={showTooltip && item === 'label'} title={`${t('tooltipLabelCustomField')}`}>
                      <TableSortLabel
                        sx={{
                          color: `${theme.palette.primary.main} !important`,
                        }}
                        active={orderByState === item}
                        direction={orderByState === item ? orderState : 'asc'}
                        aria-label={t(item)}
                        IconComponent={() => handleSortBy(item)}
                      >
                        {t(item)}{' '}
                        {item === 'label' && (
                          <i
                            className="fa-solid fa-circle-question"
                            style={{
                              color: `${theme.palette.neutral.var50}`,
                              marginLeft: 3,
                            }}
                          />
                        )}
                      </TableSortLabel>
                    </Tooltip>
                  </StyledTableCellHead>
                ))}
                {(hasButtonOptions || hasKebabButton) && <StyledTableCellHead />}
              </TableRow>
            </TableHead>
            <TableBody>
              {handleSort(rows).map((row, index) => (
                <StyledTableRow
                  data-testid="row-item-table"
                  id="tableRowItem"
                  key={`${row.name}-${index}`}
                  onClick={(event) => {
                    event.preventDefault();
                    handleClickItem(row);
                  }}
                >
                  {Object.entries(row)
                    .filter(([key, value]) => headItems.includes(key))
                    .map(([key, value]: any, index) => (
                      <StyledTableCell
                        data-testid="row-item-cell"
                        sx={{
                          textDecoration: key === 'email' && value ? 'underline' : '',
                        }}
                        key={index}
                        component="th"
                        scope="row"
                      >
                        {value || '-'}
                      </StyledTableCell>
                    ))}
                  {(hasButtonOptions || hasKebabButton) && (handleDeleteItem || handleOpenKebab) && (
                    <StyledTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      key={index}
                      component="th"
                      scope="row"
                      data-test-id={`optionButton-${index}`}
                    >
                      <div style={{ width: '35px', height: '35px' }}>
                        {hasKebabButton && handleOpenKebab ? (
                          <IconKebab
                            data-testid="kebabItemTable"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.nativeEvent.stopImmediatePropagation();
                              handleOpenKebab(event);
                              handleKebab();
                              setIndexFocus(index);
                              setItemSelected(row);
                            }}
                            isShowingOptions={openKebab && index === idxFocused}
                          >
                            <i
                              className="fa-regular fa-ellipsis-vertical"
                              style={{
                                color: theme.palette.neutral.var90,
                              }}
                            />
                          </IconKebab>
                        ) : (
                          handleDeleteItem && (
                            <IconDelete
                              data-testid="deleteItemTable"
                              id="deleteIcon"
                              onClick={(event) => {
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                handleDeleteItem(row);
                              }}
                            >
                              <i
                                className="fa-regular fa-trash-can"
                                style={{
                                  color: theme.palette.tertiary.main,
                                }}
                              />
                            </IconDelete>
                          )
                        )}
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {anchorElKebab && (
        <KebabOptions
          anchorElKebab={anchorElKebab}
          open={openKebab}
          handlePopoverKebab={handleKebab}
          itemSelected={itemSelected}
          kebabName={tableName ?? ''}
        />
      )}
    </>
  );
};

export default CustomTable;
