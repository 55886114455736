import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonsRow from '../../../../components/ButtonsStack';
import GenericModal from '../../../../components/Modal';
import { Container } from './style';
import { IModalWarningMobile } from './types';

const ModalWarningMobile: React.FC<IModalWarningMobile> = (props) => {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  return (
    <GenericModal open={open} title={t('titleModalIsMobileWarningAdminPanel')} handleModal={() => handleClose()}>
      <Container>
        <Typography variant="body1" color="neutral.var80" textAlign="center" paddingTop="20px">
          {t('messageModalIsMobileWarningAdminPanel')}
        </Typography>
        <ButtonsRow gap="20px" justifyContent="center">
          <Button onClick={() => handleClose()} variant="text">
            {t('goBack')}
          </Button>
          <Button onClick={() => handleClose(true)} disableElevation variant="contained">
            {t('access')}
          </Button>
        </ButtonsRow>
      </Container>
    </GenericModal>
  );
};
export default ModalWarningMobile;
