import { TableRow, TableCell, tableCellClasses, styled } from '@mui/material';

import { shouldForwardPropHelper } from '@engyalo/design-system';
import shadows from '../../theme/shadows';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: '140px',
  },
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.neutral.var30}`,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(even)': {
    borderRadius: 0,
  },
  '&:last-child td, &:last-child th': {
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.neutral.var30}`,
  },
  ':hover': {
    background: theme.palette.neutral.var20,
  },
}));

export const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  top: '-1px',
  position: 'sticky',
  borderBottom: `1px solid ${theme.palette.neutral.var40}`,
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '1.2rem',
  color: theme.palette.primary.main,
  background: theme.palette.common.white,
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  fontFeatureSettings: "'tnum' on, 'lnum' on",
}));

export const IconSortButton = styled('div')`
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IconDelete = styled('div')`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.tertiary.light};
  box-shadow: ${shadows.shadowBlack2};
  border-radius: 4px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  cursor: pointer;
  #tableRowItem:hover & {
    display: flex;
  }
`;

interface IKebabIcon {
  isShowingOptions: boolean;
}

export const IconKebab = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IKebabIcon>`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.neutral.var20};
  box-shadow: ${shadows.shadowBlack2};
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  display: ${({ isShowingOptions }) => (isShowingOptions ? 'flex' : 'none')};
  cursor: pointer;
  #tableRowItem:hover & {
    display: flex;
  }
`;
