import { ChatApi } from '@engyalo/salesdesk-api-ts-client';
import { UploadMediaApi } from '@engyalo/salesdesk-bff-ts-client';
import { CancelToken } from 'axios';
import AxiosInstanceBFF from 'services/AxiosInstanceBFF';
import AxiosInstanceYalo from 'services/AxiosInstanceYalo';

const chatApi = new ChatApi(undefined, undefined, AxiosInstanceYalo);
const uploadMediaApi = new UploadMediaApi(undefined, undefined, AxiosInstanceBFF);

type GetChatHistoryParams = {
  roomId: string;
  cancelToken?: CancelToken;
};

export const getChatHistory = ({ roomId, cancelToken }: GetChatHistoryParams) =>
  chatApi.getChatHistory(roomId, { cancelToken });

export const uploadMedia: UploadMediaApi['uploadMediaFile'] = (file, options) =>
  uploadMediaApi.uploadMediaFile(file, options);
