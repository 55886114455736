import { styled } from '@mui/material/styles';
import colors from '../../theme/colors';

interface IOptionsContainer {
  position?: string;
}

export const OptionsContainer = styled('div')<IOptionsContainer>`
  display: flex;
  float: right;
  position: inherit;
  right: 0;
  position: ${({ position }) => position || 'absolute'};
`;

interface IOptions {
  parentItem: any;
  isUserlogged: boolean;
  isbot: boolean;
  iserror: boolean | null;
  ismidia: boolean;
  isanotation: boolean;
  isdiscussion: boolean;
}

function hoverChildren(parentItem: string) {
  return `#${parentItem}:hover & {
    display: flex;
    border-radius: 50%;
    z-index: 9;
    justify-content: center;
  }`;
}

export const Options = styled('div')<IOptions>`
  width: 22px;
  height: 22px;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.palette.common.white};
  display: none;
  cursor: pointer;
  ${({ parentItem }) => hoverChildren(parentItem)}
`;
