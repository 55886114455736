import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IError } from '../../../components/Tags/CreateTagModal/types';
import { CustomFieldsType } from '../../../types/customFields';
import { getCustomFields } from '../../../services/getCustomFields';
import { deleteCustomFields } from '../../../services/deleteCustomFields';

const useCustomFieldsManager = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [allCustomFields, setCustomFields] = useState<Array<CustomFieldsType>>([]);
  const [selectCustomField, setSelectCustomField] = useState<CustomFieldsType | undefined>();
  const [headerColumns, setHeaderColumns] = useState<Array<string>>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showModalUnsavedChages, setShowModalUnsavedChages] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<IError>();

  const handleCurrentPage = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  }, []);

  const handleClickItem = async (deleteItem: Boolean, item?: any) => {
    const {
      data: { customField = {} },
    } = await getCustomFields(item.field ?? item._id);

    setSelectCustomField(customField);

    if (deleteItem) {
      setOpenDeleteModal(!openDeleteModal);
      return;
    }
    setShowDrawer(true);
  };

  const handleTable = (list?: Array<CustomFieldsType>) => {
    const listValues = list || allCustomFields;
    setHeaderColumns(['field', 'label']);

    const values = listValues.map((item) => ({
      field: item._id,
      label: item.label,
    }));
    setLoading(false);
    setTableRows(values);
  };

  const loadCustomFields = async () => {
    try {
      const {
        data: { customFields = [] },
      } = await getCustomFields();

      const finalCustomFields = customFields.filter((el: any) => el.visibility === 'visible');

      setCustomFields(finalCustomFields);
      setTotalItems(finalCustomFields.length);
      handleTable(finalCustomFields);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDeleteCustomField = async (confirm: boolean) => {
    setLoading(true);
    if (confirm && selectCustomField && selectCustomField._id) {
      try {
        const { data } = await deleteCustomFields(selectCustomField._id);
        const response = JSON.parse(data.message);
        if (response.error) {
          throw new Error(response?.error?.error);
        }
        if (response?.result) {
          setSelectCustomField(undefined);
          loadCustomFields();
          setLoading(false);
          setShowDrawer(false);
        }
      } catch (error) {
        const errorValues = {
          title: t('errorDeleteCustomFieldTitle'),
          bodyMessage: t('errorDeleteCustomFieldMessage'),
        };
        setLoading(false);
        setErrorMessage(errorValues);
        setOpenErrorModal(true);
        console.log(error);
      }
    }
    setLoading(false);
    setOpenDeleteModal(false);
  };

  const handleSearchCustomFields = (search: string) => {
    const filter = allCustomFields.filter(
      (item: CustomFieldsType) =>
        item._id.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );

    setTotalItems(filter.length);
    handleTable(filter);
  };

  const handleChangeForm = (isChanged: boolean) => {
    setUnsavedChanges(isChanged);
  };

  useEffect(() => {
    setLoading(true);
    loadCustomFields();
  }, []);

  return {
    handleSearchCustomFields,
    handleClickItem,
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    selectCustomField,
    setShowDrawer,
    showDrawer,
    unsavedChanges,
    showModalUnsavedChages,
    setUnsavedChanges,
    setShowModalUnsavedChages,
    loadCustomFields,
    handleChangeForm,
    openDeleteModal,
    handleConfirmDeleteCustomField,
    openErrorModal,
    errorMessage,
    setOpenErrorModal,
    setSelectCustomField,
    loading,
  };
};

export default useCustomFieldsManager;
