import React from 'react';
import { Typography } from '@mui/material';
import { IDateBeginningConversation } from './types';
import { Container, BorderLine } from './style';

const DateBeginnigConversation: React.FC<IDateBeginningConversation> = ({ date }: IDateBeginningConversation) => (
  <Container>
    <BorderLine />
    <Typography variant="h4" sx={{ whiteSpace: 'nowrap', margin: '0 0.8rem' }}>
      {date}
    </Typography>
    <BorderLine />
  </Container>
);

export default DateBeginnigConversation;
