import { Department, IDepartmentsListUnit } from 'services/types';

const botMails = ['bot@yalo.com', 'bot@bot.com'];

const excludedDepartmentNames = ['bot'];

export const filterBotDepartment = (
  departments: Department[] | IDepartmentsListUnit[]
): IDepartmentsListUnit[] | Department[] =>
  departments.filter(
    (department) => !excludedDepartmentNames.includes(department.name) && !botMails.includes(department.email)
  );
