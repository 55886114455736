import { AxiosResponse } from 'axios';
import { environmentUtils } from 'utils';
import { ISchedule, ISchedulePost } from 'views/ServiceManagement/BusinessHours/types';
import AxiosInstanceBFF from '../AxiosInstanceBFF';

export const businessHoursUrl = 'v1/business-hours/';

export const createSchedule = async (schedule: ISchedulePost): Promise<AxiosResponse<string>> => {
  const { name, departmentIds, timezone, workHours } = schedule;
  const instanceId = environmentUtils.getEnvironmentInstance();
  const body = { name, departmentIds, timezone, workHours, instanceId };
  return AxiosInstanceBFF.post(businessHoursUrl, body);
};

export const updateSchedule = async (schedule: ISchedule): Promise<AxiosResponse<string>> => {
  const { id, departmentIds, timezone, workHours } = schedule;
  const body = { departmentIds, timezone, workHours };
  return AxiosInstanceBFF.patch(`${businessHoursUrl}${id}`, body);
};
