import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IFormCreateContactManager } from './types';
import DrawerContext from '../../../../contexts/drawer/context';
import { createYupSchema } from '../../../../helpers/yupSchemaCreator';
import { createContact } from '../../../../services/createContact';
import { searchUser } from '../../../../services/contactSearch';
import ConversationContext from '../../../../contexts/conversation/context';
import { User } from '../../../../services/types';
import { getLiveChatUsersAgent } from '../../../../services/getLivechatUsersAgt';
import { ICreateContactManager } from '.';

const useNewContact = ({ handleOpenNewUser, sendNotification }: ICreateContactManager) => {
  const { customFields } = useContext(DrawerContext);
  const { handleSelectContact } = useContext(ConversationContext);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stateSchema, setStateSchema] = useState(yup.object().shape({}));
  const [agents, setAllAgents] = useState<User[]>([]);
  const [showModalError, setShowErrorConnectionModal] = useState(false);
  const [showModalChanges, setShowModalChanges] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setError,
    setValue,
    getValues,
  } = useForm<IFormCreateContactManager>({
    mode: 'onChange',
    resolver: yupResolver(stateSchema),
  });

  const onSubmit: SubmitHandler<IFormCreateContactManager> = async (form) => {
    if (form.name === '' && form.phone === '') {
      setError('name', { type: 'required', message: 'fieldIsRequired' });
      setError('phone', { type: 'required', message: 'fieldIsRequired' });
      return;
    }

    if (form.name === '') {
      setError('name', { type: 'required', message: 'fieldIsRequired' });
      return;
    }
    if (form.phone === '') {
      setError('phone', { type: 'required', message: 'fieldIsRequired' });
      return;
    }
    try {
      setLoadingRequest(true);
      const { name, agentResponsibleForThisContact, phone, countryCode } = form;
      const agent = agents.filter((el) => el._id === agentResponsibleForThisContact)[0];

      let contactManager = {};

      contactManager = {
        username: agent?.username ?? '',
      };
      const finalPhone = countryCode.slice(1, countryCode.length) + phone;
      form.phone = finalPhone;

      const finalToken = `whatsapp:${finalPhone}`;
      const props = {
        token: finalToken,
        name,
        phone: finalPhone,
        customFields: form,
        contactManager,
      };

      const { data: contact } = await createContact(props);

      if (sendNotification) {
        handleSelectContact({
          _id: contact.contact,
          name,
          phone: finalPhone,
          lastChat: '',
          tagsList: '',
        });
      }
      handleOpenNewUser();
    } catch (error) {
      setShowErrorConnectionModal(true);
    } finally {
      setLoadingRequest(false);
    }
  };

  const getUsers = async () => {
    const {
      data: { users },
    } = await getLiveChatUsersAgent();
    setAllAgents(users);
  };

  const checkField = async (field: string) => {
    if (field === 'phone') {
      const finalPhone = getValues('countryCode') + getValues('phone');

      const { data: contact } = await searchUser(field, finalPhone.slice(1, finalPhone.length));
      if (contact.contact) {
        setError('phone', { type: 'required', message: 'numberAlreadyExists' });
      }
    }
  };

  useEffect(() => {
    if (customFields) {
      const yepSchema = customFields.reduce(createYupSchema, {});
      const validateSchema = yup.object().shape(yepSchema);
      setStateSchema(validateSchema);
      setLoading(false);
    }
  }, [customFields]);

  useEffect(() => {
    getUsers();
  }, []);

  return {
    handleSubmit,
    onSubmit,
    agents,
    control,
    errors,
    isValid,
    loadingRequest,
    customFields,
    loading,
    showModalError,
    setShowErrorConnectionModal,
    isDirty,
    showModalChanges,
    setShowModalChanges,
    setValue,
    checkField,
  };
};

export default useNewContact;
