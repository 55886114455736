import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, Tooltip } from '@engyalo/design-system';
import { FormControl, MenuItem, Typography } from '@mui/material';
import { t } from 'i18next';
import { TimeUpdateOptions } from '../../useServiceDashboard';

interface ITimeSelector {
  timeUpdateSelected: number;
  setTimeUpdateSelected: React.Dispatch<React.SetStateAction<number>>;
}

const TimeSelector = ({ timeUpdateSelected, setTimeUpdateSelected }: ITimeSelector) => {
  const theme = useTheme();
  const finalValue = TimeUpdateOptions.filter((el) => el.value === timeUpdateSelected)[0];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const convertedSelectedValue = parseInt(event.target.value, 10);
    setTimeUpdateSelected(convertedSelectedValue);
  };

  return (
    <FormControl fullWidth>
      <Typography variant="caption2" color="neutral.var100">
        {t('autoUpdate')}
        <Tooltip title={t('autoUpdateTooltip')} placement="bottom">
          <i className="fa-duotone fa-circle-question" style={{ color: theme.palette.neutral.var40, marginLeft: 3 }} />
        </Tooltip>
      </Typography>

      <TextField
        id="intervalSelectorServiceDashboard"
        inputProps={{ 'data-testid': 'intervalSelectorServiceDashboard' }}
        value={finalValue.value}
        onChange={handleChange}
        placeholder={t('autoUpdate')}
        fullWidth
        select
      >
        {TimeUpdateOptions.map(({ title, value, prefix }) => (
          <MenuItem key={value} value={value}>
            {`${t(prefix)} ${t(title)}`}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default TimeSelector;
