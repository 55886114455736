import React from 'react';
import { ListItem, ListItemButton } from '@mui/material';
import { ActionIcon, ActionText } from './style';

export enum ACTION_TYPES {
  TRANSFER = 'transfer',
  INTERNAL_DISCUSSION = 'internalDiscussion',
  ON_HOLD = 'onHold',
  SEARCH = 'search',
  HISTORY = 'history',
}

interface ActionProps {
  id?: string;
  icon: JSX.Element;
  type: ACTION_TYPES;
  title: string;
  isVisible?: boolean;
  onClick: (type: ACTION_TYPES) => void;
}

const Action: React.FC<ActionProps> = ({ id, icon, title, isVisible = true, type, onClick }) => {
  const handleClick = () => {
    onClick(type);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleClick} id={id}>
        <ActionIcon>{icon}</ActionIcon>
        <ActionText>{title}</ActionText>
      </ListItemButton>
    </ListItem>
  );
};

export default Action;
