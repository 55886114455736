import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';
import { Template } from './types';

export type GetAllTemplatesProps = {
  Count: number;
  Offset: number;
  accountId: string;
  search?: string;
  sort: string;
  sortValue: number;
  departmentsIds?: string;
  isActiveSalesdesk?: boolean;
};

type GetAllTemplates = { Templates: Template[]; Count: number; Offset: number; Total: number };
export const getVerifiedTemplates = async (props: GetAllTemplatesProps): Promise<AxiosResponse<GetAllTemplates>> => {
  const { Count, Offset, accountId, search, sort, sortValue, departmentsIds, isActiveSalesdesk } = props;
  const params = {
    Count,
    Offset,
    accountId,
    search,
    departmentsIds,
    isActiveSalesdesk,
    sort: { Field: sort, Sort: `${sortValue}` },
  };

  return AxiosInstanceYalo.get('v1/template', {
    params,
  });
};
