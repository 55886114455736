import { Autocomplete, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Radio, Tooltip } from '@engyalo/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import LoadingAnimation from '../../../../components/AnimationLoading';

import { ButtonFormContainer, FormContainer, FormCustom, Span, CustomChip, CheckboxIcon } from '../style';
import { IFormCustomFields, IPropsForm } from '../types';
import { schemaCustomFields } from './yupSchema';
import { saveCustomFields } from '../../../../services/saveCustomFields';
import { CheckboxCustom } from '../../../../components/Checkbox/style';
import ModalErrorCustomField from '../../../../components/ErrorModal';

const FormCreateCustomFields: React.FC<IPropsForm> = ({
  customField,
  onClose,
  formIsChanged,
  setSelectCustomField,
}: IPropsForm) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    getValues,
  } = useForm<IFormCustomFields>({
    mode: 'onChange',
    resolver: yupResolver(schemaCustomFields),
  });
  const disableInputOptions = watch('type') === 'input';
  const [loading, setLoading] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const [browserConnection, setBrowserConnection] = useState(true);
  const [showModalErrorInternet, setShowModalErrorInternet] = useState(false);

  const onSubmit: SubmitHandler<IFormCustomFields> = async (form) => {
    setLoading(true);
    if (browserConnection === false) {
      setLoading(false);
      setShowModalErrorInternet(true);
    } else {
      try {
        let options = form?.options?.join(',');
        options = options?.concat(',');

        const fieldRequired = form.required !== customField?.required ? form.required : customField?.required;

        const { data } = await saveCustomFields(
          form.field,
          form.label,
          form.type,
          !!customField?._id,
          fieldRequired,
          form.type === 'input' ? '' : options
        );

        const { message } = data;
        const dataParsed = JSON.parse(message);

        if (dataParsed.error) {
          setLoading(false);
          setShowModalError(true);
          return;
        }

        if (data.success) {
          setLoading(false);
          onClose(true);
          setSelectCustomField(undefined);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (formIsChanged) {
      formIsChanged(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (customField?.options) {
      const auxOptions = customField?.options.slice(0, customField?.options.length - 1);

      if (auxOptions) {
        setValue('options', auxOptions?.split(','));
      } else {
        setValue('options', []);
      }
    }

    setValue('required', customField?.required ?? false);
    setValue('label', customField?.label ?? '');
    setValue('type', customField?.type ?? 'input');
  }, [customField]);

  useEffect(() => {
    window.addEventListener('offline', (e) => {
      setBrowserConnection(false);
      setShowModalErrorInternet(true);
    });

    window.addEventListener('online', (e) => {
      setBrowserConnection(true);
    });
  }, []);

  return (
    <>
      <FormContainer>
        <FormCustom onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '0px 16px',
            }}
          >
            <div
              style={{
                display: customField?._id ? 'none' : 'block',
              }}
            >
              <Controller
                name="field"
                control={control}
                defaultValue={customField?._id || ''}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <TextField
                      {...field}
                      sx={{ width: '100%' }}
                      placeholder={t('placeholderCustomField')}
                      error={!!errors.field}
                      helperText={t(errors.field?.message ?? '')}
                      InputProps={{}}
                      label={t('field')}
                      onChange={(event) => {
                        const parsed = event.target.value
                          .normalize('NFD')
                          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');

                        onChange(parsed.trim());
                      }}
                    />
                  );
                }}
              />
              <Span variant="caption1" color="neutral.var50" sx={{ display: errors.field ? 'none' : 'flex' }}>
                {t('specialCharactersInfo')}
              </Span>
            </div>

            <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: customField?._id ? '' : '40px' }}>
              {t('label')}{' '}
              <Tooltip title={t('tooltipLabelCustomField') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>

            <Controller
              name="label"
              control={control}
              defaultValue=""
              render={({ field }) => {
                const { value } = field;
                return (
                  <TextField
                    {...field}
                    value={value}
                    sx={{ width: '100%' }}
                    placeholder={t('placeholderCustomFieldLabel')}
                    error={!!errors.label}
                    helperText={t(errors.label?.message ?? '')}
                    InputProps={{}}
                  />
                );
              }}
            />

            <Typography variant="caption1" color="neutral.var80">
              {t('fieldType')}
            </Typography>
            <Controller
              name="type"
              control={control}
              defaultValue="input"
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={value || 'input'}
                    name="radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="input" control={<Radio size="small" />} label={`${t('textField')}`} />
                    <FormControlLabel value="select" control={<Radio size="small" />} label={`${t('selectField')}`} />
                  </RadioGroup>
                );
              }}
            />
            {getValues('type') === 'select' && (
              <>
                <Controller
                  name="options"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const { onChange, value } = field;

                    return (
                      <Autocomplete
                        disabled={disableInputOptions}
                        value={value}
                        disableClearable
                        multiple
                        id="customfields"
                        options={[]}
                        freeSolo
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderTags={(tagValue: readonly string[], getTagProps) =>
                          tagValue.map((option: string, index: number) => {
                            if (option && option !== '') {
                              return <CustomChip label={option} {...getTagProps({ index })} />;
                            }
                            return null;
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={value?.length === 0 ? t('customFieldOptions') : ''}
                            sx={{ borderRadius: '4px' }}
                            error={!!errors.options}
                            helperText={t(t((errors.options as any)?.message) ?? '')}
                          />
                        )}
                        sx={{
                          maxHeight: '100px',
                          overflowY: 'scroll',
                        }}
                      />
                    );
                  }}
                />
                <Span variant="caption1" color="neutral.var50">
                  {t('separeWithEnter')}
                </Span>
              </>
            )}

            <Controller
              name="required"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <CheckboxCustom
                      disableRipple
                      disableTouchRipple
                      icon={<CheckboxIcon />}
                      checked={field.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(event.target.checked);
                      }}
                      sx={{ marginRight: '4px' }}
                    />
                  }
                  label={`${t('mandatoryFilling')}`}
                  sx={{ marginTop: '24px', marginLeft: 0 }}
                />
              )}
            />
          </div>
          <ButtonFormContainer>
            <Button
              disabled={!isValid || !isDirty}
              type="submit"
              fullWidth
              variant="contained"
              startIcon={loading ? null : <i className="fa-regular fa-check fa-sm" />}
            >
              {loading ? <LoadingAnimation color={theme.palette.neutral.var50} /> : <>{t('saveField')}</>}
            </Button>
          </ButtonFormContainer>
        </FormCustom>
      </FormContainer>
      {showModalError && (
        <ModalErrorCustomField
          open={showModalError}
          message={t('fiedlAlreadyExists')}
          title={t('fieldAlreadyExistsTitle')}
          handleClose={() => {
            setShowModalError(false);
          }}
        />
      )}

      {showModalErrorInternet && (
        <ModalErrorCustomField
          open={showModalErrorInternet}
          title={t('connectionErrorModalTitle')}
          message={t('connectionErrorMessage')}
          handleClose={() => {
            setShowModalErrorInternet(false);
          }}
        />
      )}
    </>
  );
};
export default FormCreateCustomFields;
