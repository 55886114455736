import { Typography, css } from '@mui/material';
import { styled } from '@engyalo/design-system';

export const DayLabel = styled(Typography)(
  () => css`
    min-width: 20%;
    flex: 1;
    text-transform: capitalize;
  `
);
