/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import AuthContext from '../../contexts/auth/context';
import useEnvironment from '../../hooks/useEnvironments';

interface IWithFeatureFlagProps {
  featureFlag: string;
  WrappedComponent: React.ReactElement;
  FalseComponent?: React.ReactElement;
  NullComponent?: React.ReactElement;
  LoaderComponent?: React.ReactElement;
}

const WithFeatureFlag = ({
  featureFlag,
  WrappedComponent,
  FalseComponent = <div />,
  NullComponent,
  LoaderComponent,
}: IWithFeatureFlagProps) => {
  const { currentUserInfo } = React.useContext(AuthContext);
  const { email, username } = currentUserInfo;
  const { getInstance } = useEnvironment();
  const instance = getInstance();

  const resolveState = (treatment: string) => {
    switch (treatment) {
      case 'on':
        return WrappedComponent;
      case 'off':
        return FalseComponent;
      default:
        return NullComponent || FalseComponent;
    }
  };

  const attributes = { mail: email, instance, username };

  return (
    <SplitTreatments names={[featureFlag]} attributes={attributes}>
      {({ treatments: { [featureFlag]: featureTreatment }, isReady }) =>
        isReady ? resolveState(featureTreatment.treatment) : LoaderComponent || NullComponent || FalseComponent
      }
    </SplitTreatments>
  );
};

export default WithFeatureFlag;
