import { useTranslation } from 'react-i18next';
import { YDSGridColDef } from '@engyalo/design-system/dist/src/DataGrid/customCols';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { ScheduleTableProps } from './types';
import { getColumns } from './constants';
import NoRows from './NoRows';
import { StyledGrid as DataGrid } from '../styles';

const ScheduleTable = ({ schedules, loading, ...actions }: ScheduleTableProps) => {
  const { t } = useTranslation();
  const columns = getColumns(actions, t);
  const { onEdit = () => {} } = actions;

  return (
    <DataGrid
      loading={loading}
      rows={schedules}
      columns={columns as YDSGridColDef<GridValidRowModel>[]}
      onRowClick={({ row }) => onEdit(row)}
      showActionsOnHover
      hideFooter
      slots={{
        noRowsOverlay: NoRows,
      }}
    />
  );
};

export default ScheduleTable;
