import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const updatePassword = async (password: string): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'setUserPassword',
      params: [password],
    }),
  };
  return AxiosInstance.post('method.call/setUserPassword', params);
};
