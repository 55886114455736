import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getDepartmentsListUnit } from '../../../../services/getDepartmentsUnit';
import { getMonitors } from '../../../../services/getMonitors';
import { getMonitorsListUnit } from '../../../../services/getMonitorsUnit';
import { saveUnit } from '../../../../services/saveUnit';
import { IMonitorsSelected } from '../../../../types/unit';
import { IUnitFormProps, IUseUnitProps } from '../types';
import { schemaUnit } from './yupSchema';
import { IDepartmentsListUnit, User } from '../../../../services/types';

const useUnitModal = ({ unit, handleClose }: IUseUnitProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IUnitFormProps>({
    resolver: yupResolver(schemaUnit),
  });
  const [listSupervisors, setListSupervisors] = useState<User[]>([]);
  const [currentDepartments, setCurrentDepartments] = useState<IDepartmentsListUnit[]>([]);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const onSubmit: SubmitHandler<IUnitFormProps> = async (form) => {
    const { name, supervisors } = form;
    try {
      let selectSupervisors: IMonitorsSelected[] = [];
      if (supervisors) {
        selectSupervisors = listSupervisors
          .filter((item) => supervisors.includes(item._id))
          .map((item: any) => ({
            monitorId: item._id,
            username: item.username,
          }));
      }
      const departments = currentDepartments.map((item) => ({ departmentId: item._id }));
      const { data } = await saveUnit(
        unit?._id || null,
        { name, visibility: unit?.visibility || 'public' },
        selectSupervisors,
        departments
      );
      const response = JSON.parse(data.message);
      if (response.result) {
        handleClose(true);
        return;
      }
      throw response?.error;
    } catch (error) {
      setShowModalError(true);
    }
  };

  const getListMonitorsUnit = async (unitId: string) => {
    try {
      const {
        data: { monitors },
      } = await getMonitorsListUnit(unitId);

      const values = monitors.map((item) => item.monitorId);

      setValue('supervisors', values);
    } catch (error) {
      console.log(error);
    }
  };

  const getListDeparmentsUnit = async (unitId: string) => {
    try {
      const {
        data: { departments },
      } = await getDepartmentsListUnit(unitId);
      setCurrentDepartments(departments);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAvailableSupervisors = async () => {
    try {
      const {
        data: { monitors = [] },
      } = await getMonitors();
      setListSupervisors(monitors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (unit?._id) {
      getListDeparmentsUnit(unit?._id);
      setValue('name', unit.name);
    }
    loadAvailableSupervisors();
  }, [unit?._id]);

  useEffect(() => {
    if (unit) {
      getListMonitorsUnit(unit._id);
    }
  }, [listSupervisors]);

  return {
    control,
    errors,
    listSupervisors,
    showModalError,
    showModalDelete,
    setShowModalDelete,
    setShowModalError,
    watch,
    handleSubmit,
    onSubmit,
  };
};

export default useUnitModal;
