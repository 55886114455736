import { useEffect, useContext, useRef, useState } from 'react';
import WebSocketService, { SocketEvents } from 'services/Websocket';
import AuthContext from 'contexts/auth/context';
import { environmentUtils } from 'utils';
import { logError, logInfo } from 'services/Logger';
import { WEBSOCKET_EVENT_LOG_MESSAGE } from 'constants/defaultValues';

const useWebsocket = () => {
  const [isConnected, setIsConnected] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const socketServiceRef = useRef<WebSocketService | null>(null);

  const { userId, authToken } = currentUser;

  const handleSocketOpen = () => {
    setIsConnected(true);
    logInfo(WEBSOCKET_EVENT_LOG_MESSAGE.STATUS_CHANGE, {
      open: true,
      source: 'salesdesk',
    });
  };

  const handleSocketClose = (event: CloseEvent) => {
    setIsConnected(false);
    logInfo(WEBSOCKET_EVENT_LOG_MESSAGE.STATUS_CHANGE, {
      ...event,
      open: false,
      source: 'salesdesk',
    });
  };

  const handleSocketError = (event: ErrorEvent) => {
    logError('Salesdesk WebSocket Error', event);
  };

  useEffect(() => {
    if (userId && authToken) {
      const url = environmentUtils.getWebsocketURL(userId, authToken);

      socketServiceRef.current = WebSocketService.getInstance(url, {
        minReconnectionDelay: 1000,
        minUptime: 100,
      });

      socketServiceRef.current.on(SocketEvents.Open, handleSocketOpen);
      socketServiceRef.current.on(SocketEvents.Close, handleSocketClose);
      socketServiceRef.current.on(SocketEvents.Error, handleSocketError);
    }

    return () => {
      socketServiceRef.current?.disconnect();
      socketServiceRef.current?.off(SocketEvents.Open, handleSocketOpen);
      socketServiceRef.current?.off(SocketEvents.Close, handleSocketClose);
      socketServiceRef.current?.off(SocketEvents.Error, handleSocketError);
    };
  }, [userId, authToken]);

  return {
    webSocket: socketServiceRef.current,
    isConnected,
  };
};

export default useWebsocket;
