import React, { ReactNode, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { TFunction } from 'i18next';
import useSegment from 'services/Analytics';
import { getContactInfo } from '../../services/contactInfo';
import { getCustomFields } from '../../services/getCustomFields';
import { CustomFieldsType } from '../../types/customFields';
import ChatsContext from '../chats/context';
import DrawerContext, { TABS } from './context';
import MenuChatsContext from '../menuChats/context';
import { getLastContactNotification } from '../../services/getLastContactNotification';
import { convertDatesTable } from '../../helpers/formatterDates';
import AuthContext from '../auth/context';
import { getAllContactNotifications } from '../../services/getAllContactNotifications';
import { ContactInfo, Notification } from '../../services/types';
import { logError } from '../../services/Logger';
import { STORAGE_KEYS } from '../../constants/defaultValues';

const DEFAULT_LOCALE = window.navigator.language;
const DEAFULT_TABLE_TEMPLATE = 'templatesHistory';
const LANGUAGE_LOCAL_STORAGE = localStorage.getItem(STORAGE_KEYS.LANGUAGE);

type tableRow = {
  _id: string;
  template: string;
  dateSent: ReactNode;
};

export type TabsState = TABS | null;

export interface IDrawerContext {
  contactInfo?: ContactInfo;
  loadUserInfo: (text?: string) => Promise<void>;
  customFields: CustomFieldsType[];
  isediting: boolean;
  setEditing: (value: boolean) => void;
  handleEditing: (confirm?: boolean) => void;
  handleTabSelection: (tab: TabsState) => void;
  selectedTab: TabsState;
  loading: boolean;
  getAllNotificationsContact: (contactId: string) => Promise<void>;
  headerColumns: string[];
  tableRows: tableRow[];
}

export const LAST_NOTIFICATION_ERROR = 'get latest notification error';

// TODO: improve this function and move to a utils directory
export const maskPhoneNumber = (phone: string) => phone.replace(/^(\d{2})(\d{7})(\d{4}).*/, '+$1 $2-$3');

const getLastNotification = async (id: string): Promise<Notification | undefined> => {
  try {
    const { data: lastNotificationInfo } = await getLastContactNotification(id);
    return lastNotificationInfo;
  } catch (error) {
    logError(LAST_NOTIFICATION_ERROR, error);
  }
  return undefined;
};

export const handleTable = (list: Notification[], t: TFunction) => {
  const listValues = list;

  const values = listValues.map((item) => {
    const finalDate = convertDatesTable(
      item.CreatedAt,
      LANGUAGE_LOCAL_STORAGE || DEFAULT_LOCALE,
      DEAFULT_TABLE_TEMPLATE
    );
    const auxFinalText = finalDate.text.split(' ');
    return {
      _id: item.Contact.id,
      template: item.Template.name,
      dateSent: (
        <Typography whiteSpace="break-spaces">
          {typeof finalDate.time === 'number' && finalDate.text
            ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
            : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${finalDate.time}`}
        </Typography>
      ),
    };
  });

  return {
    columns: ['template', 'dateSent'],
    rows: values,
  };
};

const DrawerProvider: React.FC = (props) => {
  const { children } = props;

  const [selectedTab, setSelectedTab] = useState<TabsState>(null);

  const { serviceSelected } = useContext(ChatsContext);
  const { openContactManager, handleReload } = useContext(MenuChatsContext);
  const { currentUserInfo } = useContext(AuthContext);
  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const [customFields, setCustomFields] = useState<Array<CustomFieldsType>>([]);
  const [lastOpenedUser, setLastOpenedUser] = useState('');
  const [loading, setLoading] = useState(false);
  const { sendMoreActionsEvent } = useSegment();

  const { t } = useTranslation();

  const [isediting, setEditing] = useState(false);

  const [headerColumns, setHeaderColumns] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<tableRow[]>([]);

  const loadUserInfo = useCallback(
    async (visitorId?: string) => {
      setLoading(true);

      if ((serviceSelected && serviceSelected._id) || visitorId) {
        const _id = visitorId || serviceSelected?.v._id;

        setLastOpenedUser(_id);
        if (_id) {
          const [lastNotificationData, { data }] = await Promise.all([getLastNotification(_id), getContactInfo(_id)]);
          let finalDate;
          let auxFinalText: string[] = [''];
          if (lastNotificationData?.ID) {
            finalDate = convertDatesTable(
              lastNotificationData.CreatedAt,
              LANGUAGE_LOCAL_STORAGE || DEFAULT_LOCALE,
              DEAFULT_TABLE_TEMPLATE
            );
            auxFinalText = finalDate.text.split(' ');
          }

          const { contact } = data;

          if (contact) {
            const info = {
              _id,
              name: contact.name,
              phone: contact.phone ? contact.phone : [],
              token: contact.token,
              username: contact.username,
              lastChat: contact.lastChat,
              livechatData: contact.livechatData ? contact.livechatData : {},
              visitorEmails: contact.visitorEmails ? contact.visitorEmails : [],
              status: contact.status,
              ts: contact.ts,
              _updatedAt: contact._updatedAt,
              department: contact.department,
              lastNotificationInfo: finalDate &&
                lastNotificationData && {
                  name: lastNotificationData.Template.name,
                  agent:
                    lastNotificationData.Agent.id.trim() === currentUserInfo._id
                      ? t('you')
                      : lastNotificationData.Agent.name,
                  date:
                    typeof finalDate.time === 'number' && finalDate.text
                      ? `${finalDate.time} ${t(`${auxFinalText[0]}`)}`
                      : `${t(`${finalDate.date}`)}${t(`${auxFinalText[0]}`)} ${t(`${auxFinalText[1] || ''}`)}\n${
                          finalDate.time
                        }`,
                },
            };
            setContactInfo(info);
            handleReload();
            setLoading(false);
          }
        }
      }
    },
    [currentUserInfo._id, handleReload, openContactManager, serviceSelected, t]
  );

  const loadCustomFields = async () => {
    try {
      const { data } = await getCustomFields();
      const { customFields } = data;
      const customFieldsFiltered = customFields.filter((item: any) => item.scope === 'visitor');
      setCustomFields(customFieldsFiltered);
    } catch (error) {
      logError('Error while fetching Custom Fields', error);
    }
  };

  const handleTabSelection = useCallback(
    (tab: TabsState) => {
      setSelectedTab(tab);
      sendMoreActionsEvent(tab);
    },
    [sendMoreActionsEvent]
  );

  const getAllNotificationsContact = useCallback(
    async (contactId: string) => {
      const { data } = await getAllContactNotifications(contactId);
      const { Notifications } = data;
      const { columns, rows } = handleTable(Notifications, t);
      setHeaderColumns(columns);
      setTableRows(rows);
    },
    [t]
  );

  const handleEditing = useCallback(
    (confirm?: boolean) => {
      if (confirm && openContactManager) {
        loadUserInfo(lastOpenedUser);
      }
      setEditing((state) => !state);
    },
    [lastOpenedUser, loadUserInfo, openContactManager]
  );

  useEffect(() => {
    if (serviceSelected?._id) {
      loadUserInfo();
    }
  }, [serviceSelected?._id, openContactManager]);

  useEffect(() => {
    loadCustomFields();
  }, []);

  useEffect(() => {
    if (tableRows.length && headerColumns.length) {
      setHeaderColumns([]);
      setTableRows([]);
    }
  }, [selectedTab]);

  const memoizedValues = useMemo(
    () => ({
      contactInfo,
      loadUserInfo,
      customFields,
      isediting,
      setEditing,
      handleEditing,
      handleTabSelection,
      selectedTab,
      loading,
      getAllNotificationsContact,
      headerColumns,
      tableRows,
    }),
    [
      contactInfo,
      customFields,
      headerColumns,
      isediting,
      loading,
      selectedTab,
      tableRows,
      getAllNotificationsContact,
      handleEditing,
      handleTabSelection,
      loadUserInfo,
    ]
  );

  return <DrawerContext.Provider value={memoizedValues}>{children}</DrawerContext.Provider>;
};

export default DrawerProvider;
