import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import GenericModal from '../../Modal';
import AlertsContext from '../../../contexts/alerts/context';
import { Buttons, MessageModal, ContainerModal } from './style';
import { ITransferErrorModal } from './types';

const ModalTransferError: React.FC<ITransferErrorModal> = ({
  handleClose,
  open,
  title,
  message,
  confirmButtonText,
  cancelButton,
}: ITransferErrorModal) => {
  const { t } = useTranslation();
  const { alerts } = useContext(AlertsContext);

  // Hide modal when alerts are displayed
  useEffect(() => {
    if (alerts?.length > 0) {
      handleClose();
    }
  }, [alerts, handleClose]);

  return (
    <GenericModal title={title} handleModal={handleClose} open={open}>
      <ContainerModal data-testid="modalTransferError">
        <MessageModal data-testid="errorMessageTransfer" variant="body1" color="neutral.var100">
          {message}
        </MessageModal>
        <Buttons>
          {cancelButton && (
            <Button
              data-testid="cancelButton"
              onClick={() => handleClose()}
              disableElevation
              variant="outlined"
              color="neutral2"
            >
              {t('cancel')}
            </Button>
          )}
          {confirmButtonText && (
            <Button data-testid="confirmButton" onClick={() => handleClose(true)} disableElevation>
              {confirmButtonText}
            </Button>
          )}
        </Buttons>
      </ContainerModal>
    </GenericModal>
  );
};

export default ModalTransferError;
