import { GridRowParams } from '@mui/x-data-grid-pro';
import { Icon } from '@mui/material';
import { GridActionsCellItem, Switch, Tooltip } from '@engyalo/design-system';
import { ISchedule } from '../types';
import type { GetScheduleColumns } from './types';
import StatusTag from './StatusTag';
import DayTags from './DayTags';

export const getColumns: GetScheduleColumns = (actions, t) => [
  {
    field: 'name',
    headerName: t('business-hours.table.name'),
  },
  {
    field: 'id',
    headerName: t('business-hours.table.id'),
  },
  {
    field: 'status',
    headerName: t('business-hours.table.status'),
    width: 120,
    renderCell: ({ row }) => <StatusTag {...row} />,
  },
  {
    field: 'workHours',
    headerName: t('business-hours.table.days'),
    width: 290,
    renderCell: ({ row }) => <DayTags {...row} />,
  },
  {
    field: 'timezone',
    headerName: t('business-hours.table.timezone'),
    width: 180,
  },
  {
    field: 'enabled',
    headerName: t('business-hours.table.enabled'),
    width: 100,
    valueGetter: ({ row }) => {
      const { enabledAt, disabledAt } = row;
      return enabledAt && !disabledAt;
    },
    renderCell: ({ value, row }) => (
      <Switch
        data-testid={row.name}
        checked={value}
        onClick={(e) => e.stopPropagation()}
        onChange={(_e, enable) => {
          actions.onToggleEnabled?.(row, enable);
        }}
      />
    ),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 56,
    minWidth: 64,
    getActions: ({ row }: GridRowParams<ISchedule>) => [
      <Tooltip title={t('business-hours.actions.delete')}>
        <GridActionsCellItem
          label={t('business-hours.actions.delete')}
          key="delete"
          data-testid={row.id}
          icon={<Icon className="fa-solid fa-trash" />}
          color="neutral"
          onClick={() => actions.onDelete?.(row)}
        />
      </Tooltip>,
    ],
  },
];
