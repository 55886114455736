import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, CircularProgress, Stack, useMediaQuery, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BoxForm, HeaderForm, BoxInputs, ButtonSubmit, TextInput, PasswordParametersText, Container } from './style';
import useResetPassword from './useResetPassword';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ErrorMessage } from './ErrorMessage';
import { Footer } from './Footer';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { Bg, BlueCircle, GreenTriangle, YellowBorderedCircle, YellowHalfCircle } from '../login/style';

export const ResetPassword: React.FC = () => {
  const theme = useTheme();
  const {
    translation,
    handleSubmit,
    onSubmit,
    control,
    invalidCredentials,
    errors,
    loading,
    connectionError,
    userEmail,
    token,
  } = useResetPassword();

  const isMobile = useMediaQuery((cssTheme: Theme) => cssTheme.breakpoints.down('md'));

  const phrases = ['Keep it simple.', 'It’s all about connections.'];

  const [randomNumber, setRandomNumber] = useState(-1);

  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 2));
  }, []);

  return (
    <Container>
      <Bg style={{ display: isMobile ? 'none' : 'flex' }}>
        <BlueCircle />
        <YellowBorderedCircle />
        <GreenTriangle />
        <YellowHalfCircle />
        <Typography
          variant="h1"
          display="flex"
          textAlign="center"
          flexDirection="column"
          color="common.white"
          width="100%"
        >
          {phrases[randomNumber]}
        </Typography>
      </Bg>
      <div
        style={{
          position: 'relative',
          flex: '1 ',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <BoxForm onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ background: 'neutral.var30', borderRadius: '6px' }}>
            <HeaderForm>
              <Logo />
              <Typography variant="subtitle1" color="neutral.var90" textAlign="center">
                {translation('newPassword')}
              </Typography>
            </HeaderForm>
            <Typography variant="caption1" color="neutral.var60" textAlign="center">
              {token ? capitalizeFirstLetter(translation('createNewPassword')) : translation('newPasswordMessage')}{' '}
              {token ? '' : userEmail}
            </Typography>
            {(invalidCredentials || connectionError) && (
              <ErrorMessage translation={translation} connectionError={connectionError} />
            )}

            <BoxInputs>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    value={field.value || ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            width: '42px',
                            height: '40px',
                            maxHeight: '42px',
                            borderRadius: '3px 0px 0px 3px',
                            background: 'neutral.var60',
                            justifyContent: 'center',
                            marginRight: '12px',
                          }}
                        >
                          <i className="fa-regular fa-lock-keyhole" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginTop: '12px' }}
                    error={!!errors.password}
                    placeholder={translation('createNewPassword')}
                    type="password"
                  />
                )}
              />

              <PasswordParametersText haserror={!!errors.password}>
                {translation('passwordParameters')}
              </PasswordParametersText>

              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    value={field.value || ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            width: '42px',
                            height: '40px',
                            maxHeight: '42px',
                            borderRadius: '3px 0px 0px 3px',
                            background: 'neutral.var60',
                            justifyContent: 'center',
                            marginRight: '12px',
                          }}
                        >
                          <i className="fa-regular fa-lock-keyhole" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginTop: '12px' }}
                    error={!!errors.confirmPassword}
                    helperText={translation(errors.confirmPassword?.message ?? '')}
                    placeholder={translation('confirmPassword')}
                    type="password"
                  />
                )}
              />
            </BoxInputs>

            <ButtonSubmit
              type="submit"
              disabled={loading}
              sx={{
                background: loading ? 'neutral.var60 !important' : '',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: theme.palette.neutral.var40 }} />
              ) : (
                <>
                  {translation('continue')}{' '}
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{
                      color: theme.palette.common.white,
                      marginTop: '-2px',
                    }}
                  />
                </>
              )}
            </ButtonSubmit>
          </Stack>
          <Footer translation={translation} />
        </BoxForm>
      </div>
    </Container>
  );
};
