import { Popover } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@engyalo/design-system';
import useAdvancedFilter from './useAdvancedFilter';
import GenericAutocomplete from '../Autocomplete';
import { Container, FilterRow, Footer, ListItemSelectFilter } from './style';
import { renderDinamicSelectorInput } from './renderDinamicSelectorInput';
import { handleRenderCustomFieldText } from './handleRenderCustomFieldText';
import { handleRenderTags } from './handleRenderTags';
import { handleRenderAttendBy } from './handleRenderAttendBy';
import { handleRenderTeams } from './handleRenderTeams';
import { Department, User } from '../../services/types';

export interface IFilterOptions {
  _id: string;
  name: string;
}

interface ICustomFieldsChatsManager {
  [key: string]: string;
}
export interface IFilterChats {
  open?: boolean;
  tags?: string[];
  agentId?: string;
  departmentId?: string;
  onhold?: boolean;
  customFields?: ICustomFieldsChatsManager;
}

export interface IFilterFields {
  [key: string]: (index: number, value?: string) => JSX.Element;
}

export interface FilterProps {
  _id: string;
  name: string;
  selectFilter?: string;
  value?: string;
  options: IFilterOptions[];
}
export interface IAdvancedFiltersProps {
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  handleClose: () => void;
  handleValue: (value: FilterProps[]) => void;
  currentFilters: IFilterChats;
  agents: User[];
  departments: Department[];
  isAgentChatManager?: boolean;
  removeFilterByChatStatus: boolean;
}

const AdvancedFilters: React.FC<IAdvancedFiltersProps> = (props) => {
  const { anchorEl, handleClose, handleValue, currentFilters, agents, departments, removeFilterByChatStatus } = props;
  const { t } = useTranslation();

  const { filters, addFilter, removeItem, selectFilter, selectValueFilter, clearFilter } = useAdvancedFilter({
    handleValue,
    currentFilters,
    agents,
    departments,
    removeFilterByChatStatus,
  });

  const situationFieldValue = (index: number, value?: string) => {
    const options = [
      { _id: 'allOptions', name: t('all') },
      { _id: 'open', name: t('opened') },
      { _id: 'closed', name: t('closed') },
      { _id: 'onhold', name: t('onHold') },
    ];
    return (
      <GenericAutocomplete
        hideSearchIcon
        disableCloseOnSelect
        placeholder={`${t('select')}...`}
        defaultValue={value}
        renderOption={(properties, option) => (
          <ListItemSelectFilter {...properties}>{option.name}</ListItemSelectFilter>
        )}
        handleChange={(inputValue) => {
          selectValueFilter(index, inputValue);
        }}
        limitTagsNumber={1}
        multiple={false}
        options={options}
      />
    );
  };

  const customFieldText = handleRenderCustomFieldText(selectValueFilter);

  const tagsFieldValue = handleRenderTags(selectValueFilter);

  const teamsFieldValue = handleRenderTeams(departments, selectValueFilter);

  const attendByFieldValue = handleRenderAttendBy(agents, selectValueFilter);

  const filterFields: IFilterFields = {
    tags: tagsFieldValue,
    teams: teamsFieldValue,
    attendedBy: attendByFieldValue,
    situation: situationFieldValue,
  };

  const handleRenderDinamicSelectorInput = renderDinamicSelectorInput(filterFields, customFieldText);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        marginTop: '6px',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid="popoverAdvancedFilters"
    >
      <Container>
        {filters.map((item, index) => {
          const key = item.selectFilter ?? '';

          return (
            <FilterRow key={item._id}>
              <GenericAutocomplete
                hideSearchIcon
                prefixPlaceholder={t('filter')}
                placeholder={`${t('select')}...`}
                defaultValue={item.selectFilter}
                renderOption={(properties, option) => (
                  <ListItemSelectFilter {...properties}>{option.name}</ListItemSelectFilter>
                )}
                handleChange={(value) => {
                  selectFilter(index, value);
                }}
                limitTagsNumber={1}
                multiple={false}
                options={item.options}
              />

              {handleRenderDinamicSelectorInput(key, index, item.value)}

              <IconButton data-testid="btnRemoveFilter" onClick={() => removeItem(item._id)}>
                <i className="fa-regular fa-circle-minus fa-sm" />
              </IconButton>
            </FilterRow>
          );
        })}
        <Footer>
          <Button
            data-testid="btnAddFilter"
            size="small"
            sx={{
              width: '155px',
            }}
            variant="outlined"
            onClick={() => addFilter()}
          >
            {`${t('addFilter')}...`}
          </Button>
          <IconButton onClick={() => clearFilter()} color="neutral2" variant="outlined">
            <i className="fa-regular fa-filter-slash fa-xs" />
          </IconButton>
        </Footer>
      </Container>
    </Popover>
  );
};

export default AdvancedFilters;
