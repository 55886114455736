import { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logError } from 'services/Logger';
import { ResetPasswordProps } from './types';
import { schemaResetPassword } from './yupSchema';
import AuthContext from '../../contexts/auth/context';
import { updatePassword } from '../../services/updateUserPassword';
import { resetUserPassword } from '../../services/resetUserPassword';

const useResetPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordProps>({
    resolver: yupResolver(schemaResetPassword),
  });
  const { token } = useParams();
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const { currentUserInfo, getUserInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [browserConnection, setBrowserConnection] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('offline', () => {
      setBrowserConnection(false);
    });

    window.addEventListener('online', () => {
      setBrowserConnection(true);
    });
  }, []);

  useEffect(() => {
    if (currentUserInfo.email) {
      setUserEmail(currentUserInfo.email);
    }
  }, [currentUserInfo.email]);

  const onSubmit: SubmitHandler<ResetPasswordProps> = async (form: ResetPasswordProps) => {
    setInvalidCredentials(false);
    setConnectionError(false);
    setLoading(true);

    if (browserConnection === false) {
      setLoading(false);
      setConnectionError(true);
    } else {
      try {
        const { password } = form;

        if (token) {
          // token na URL que viria atraves do email
          const response = await resetUserPassword(token, password);
          const { message } = response.data;
          const dataParsed = JSON.parse(message);
          if (dataParsed.error) {
            setInvalidCredentials(true);
            setLoading(false);
            return;
          }
          localStorage.setItem('authToken', dataParsed.result.token);
          localStorage.setItem('userId', dataParsed.result.id);
          getUserInfo();
          navigate('/');
        } else {
          const response = await updatePassword(password);
          const { message } = response.data;
          const dataParsed = JSON.parse(message);
          setLoading(false);

          if (!dataParsed.error) {
            navigate('/');
          }
        }
        //  Disabling rule cause by default error should be either unknown or any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logError('Error on reset password', error);
        if (error.status === 401) {
          setInvalidCredentials(true);
          setLoading(false);
        }
      }
    }
  };

  return {
    control,
    handleSubmit,
    translation: t,
    onSubmit,
    invalidCredentials,
    errors,
    loading,
    connectionError,
    userEmail,
    token,
  };
};

export default useResetPassword;
