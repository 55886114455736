import React from 'react';
import { Option, Section, Wrapper, Title } from './styled';
import { InteractiveMessageComponentProps } from '../InteractiveMessage';
import { MessageHeader } from '../styled';

const ListContent: React.FC<InteractiveMessageComponentProps> = ({ list }) => {
  if (!list) {
    return null;
  }

  const { sections, body } = list;

  return (
    <Wrapper>
      <MessageHeader>{body}</MessageHeader>
      {sections.map((section) => {
        const { id, title, options } = section;

        return (
          <Section key={id}>
            <Title>{title}</Title>
            {options.map((row) => {
              const { id: rowId, title: rowTitle, description } = row;

              return (
                <Option key={rowId} title={description}>
                  <span>{rowTitle}</span>
                </Option>
              );
            })}
          </Section>
        );
      })}
    </Wrapper>
  );
};

export default ListContent;
