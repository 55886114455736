import React from 'react';
import { ButtonsStack } from './style';
import { IButtonsStack } from './types';

const ButtonsRow: React.FC<IButtonsStack> = (props: IButtonsStack) => {
  const { children, justifyContent, gap, marginTop } = props;
  return (
    <ButtonsStack justifyContent={justifyContent} gap={gap} marginTop={marginTop}>
      {children}
    </ButtonsStack>
  );
};

export default ButtonsRow;
