import React, { useCallback, useState, useEffect } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { Tooltip } from '@engyalo/design-system';
import { Visitor } from 'services/types';
import { logError } from 'services/Logger';
import { getVisitorByTerm } from '../../../services/getVisitorByTerm';
import { tagsColor } from '../../../helpers/tagsColors';
import { CustomChip } from '../../../components/Tags/style';
import { RowsPerPage } from '../../../constants/pagination';
import { IOrder } from '../../../types/order';
import { convertToLocaleDateString } from '../../../helpers/formatterDates';
import { STORAGE_KEYS } from '../../../constants/defaultValues';
import { SortDirection } from '../../../constants';

export interface TableRowData {
  _id: string;
  name: string;
  lastChat: string;
  tagsList: string;
  tags?: Array<JSX.Element> | string;
  phone?: string;
  username?: string;
}

export enum TABLE_COLUMNS {
  NAME = 'name',
  PHONE = 'phone',
  LAST_CHAT = 'lastChat',
  TAGS = 'tags',
  USERNAME = 'username',
}

export type SortOptions = TABLE_COLUMNS | '';

export type SortValue = -1 | 1;

export const TABLE_COLUMNS_TO_API_FIELD: Record<TABLE_COLUMNS, string> = {
  [TABLE_COLUMNS.NAME]: 'name',
  [TABLE_COLUMNS.PHONE]: 'phone',
  [TABLE_COLUMNS.LAST_CHAT]: 'lastChat.ts',
  [TABLE_COLUMNS.TAGS]: 'livechatData.tags',
  [TABLE_COLUMNS.USERNAME]: 'username',
};

export const MOBILE_HEADER = [TABLE_COLUMNS.NAME, TABLE_COLUMNS.LAST_CHAT];
export const DESKTOP_HEADER = [TABLE_COLUMNS.NAME, TABLE_COLUMNS.PHONE, TABLE_COLUMNS.TAGS, TABLE_COLUMNS.LAST_CHAT];

const ORDER_TO_VALUE: Record<IOrder, SortValue> = {
  asc: SortDirection.ASC,
  desc: SortDirection.DESC,
};

const VALUE_TO_ORDER: Record<SortValue, IOrder> = {
  1: 'asc',
  [-1]: 'desc',
};

const useContactManager = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [currentPage, setCurrentPage] = useState(1);
  const [headerColumns, setHeaderColumns] = useState<Array<TABLE_COLUMNS>>([]);
  const [tableRows, setTableRows] = useState<Array<TableRowData>>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pagination, setPagination] = useState({
    count: RowsPerPage,
    offset: 0,
  });

  const [anchorElKebab, setAnchorElKebab] = useState<HTMLDivElement | null>(null);
  const [lastChatId, setLastChatId] = useState('');
  const [showContactManager, setShowContactManager] = useState(false);
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const [contactsOrder, setContactsOrder] = useState<IOrder>('asc');
  const [sortContactsBy, setsortContactsBy] = useState<SortOptions>(TABLE_COLUMNS.NAME);
  const [openNewUserDrawer, setOpenNewUserDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactSearched, setContactSearched] = useState('');
  const [inputText, setInputText] = useState('');

  const handleCurrentPage = useCallback((_, value: number) => {
    setCurrentPage(value);
    setPagination((old) => ({
      ...old,
      offset: (value - 1) * RowsPerPage,
    }));
  }, []);

  const getTags = (tagsData: Array<string> | string | undefined, contactId: string): JSX.Element[] => {
    const tags = typeof tagsData === 'string' ? tagsData.split(';') : [];

    if (!tags.length) return [];

    const firstTagChip = (
      <CustomChip
        style={{ marginRight: '8px' }}
        key={`chip-${contactId}-${tags[0]}`}
        label={tags[0]}
        customcolor={tagsColor(tags[0])}
      />
    );

    if (tags.length === 1) {
      return [firstTagChip];
    }

    return [
      <Tooltip
        key="tooltip"
        title={
          <div style={{ textAlign: 'justify' }}>
            {tags.map((tagName) => (
              <div key={`tooltip-${contactId}-${tagName}`}>{tagName}</div>
            ))}
          </div>
        }
      >
        <div>
          {firstTagChip}
          <CustomChip key={`chip-${contactId}-aggregator`} label={`+${tags.length - 1}`} />
        </div>
      </Tooltip>,
    ];
  };

  const handleOpenKebab = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElKebab(event.currentTarget);
  };

  const generateTableDataFromVisitors = (visitors: Visitor[] = []): [TABLE_COLUMNS[], TableRowData[]] => {
    let header;
    let rows;

    if (isMobile) {
      header = MOBILE_HEADER;

      rows = visitors.map((item) => {
        const auxTags = item.livechatData && item.livechatData.tags ? item.livechatData.tags.replaceAll(';', ',') : '';

        const row: TableRowData = {
          _id: item._id,
          name: item.name,
          lastChat: item.lastChat
            ? convertToLocaleDateString(item.lastChat.ts, languageLocalStorage || window.navigator.language)
            : '-',
          tagsList: auxTags || '-',
        };

        return row;
      });
    } else {
      header = DESKTOP_HEADER;

      rows = visitors.map((item) => {
        const auxTags = item.livechatData && item.livechatData.tags ? item.livechatData.tags.replaceAll(';', ',') : '';

        const tags = getTags(item.livechatData?.tags, item._id);

        const row: TableRowData = {
          _id: item._id,
          name: item.name,
          phone: item.phone && item.phone[0].phoneNumber !== 'undefined' ? item.phone[0].phoneNumber : '-',
          tags: tags.length > 0 ? tags : '-',
          lastChat: item.lastChat
            ? convertToLocaleDateString(item.lastChat.ts, languageLocalStorage || window.navigator.language)
            : '-',
          tagsList: auxTags || '-',
        };

        return row;
      });
    }

    return [header, rows];
  };

  const loadContacts = async (term?: string, sortField?: SortOptions, sortValue: SortValue = 1) => {
    setLoading(true);

    if (term) {
      setContactSearched(term);
    }

    setsortContactsBy(sortField || '');
    setContactsOrder(VALUE_TO_ORDER[sortValue]);

    const sortBy = sortField ? TABLE_COLUMNS_TO_API_FIELD[sortField] : TABLE_COLUMNS_TO_API_FIELD[TABLE_COLUMNS.NAME];

    try {
      const {
        data: { visitors, total },
      } = await getVisitorByTerm({
        term,
        count: pagination.count,
        offset: pagination.offset,
        sort: sortBy,
        sortValue,
      });

      setTotalItems(total);

      const [header, rows] = generateTableDataFromVisitors(visitors);

      setHeaderColumns(header);
      setTableRows(rows);
    } catch (error) {
      logError('An error occurred while loading contacts:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadContacts(inputText, sortContactsBy, ORDER_TO_VALUE[contactsOrder]);
  }, [pagination, isMobile]);

  return {
    handleOpenKebab,
    handleCurrentPage,
    loadContacts,
    setLastChatId,
    setShowContactManager,
    setOpenNewUserDrawer,
    setInputText,
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    anchorElKebab,
    lastChatId,
    showContactManager,
    contactsOrder,
    sortContactsBy,
    openNewUserDrawer,
    loading,
    contactSearched,
    inputText,
  };
};

export default useContactManager;
