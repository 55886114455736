import { Agent } from 'services/types';
import { DASHBOARD_ANALYTICS_KEYS } from '../../../constants/defaultValues';
import { getAgentsByDepartments } from '../../../services/getAgentsByDepartments';
import { getUsersList } from '../../../services/getUsersList';
import { NOT_USERS } from './useServiceDashboard';

export const loadAgentslist = async (selectedTeam: string) => {
  const {
    data: { users },
  } = await getUsersList('');
  const { data: agents } = await getAgentsByDepartments(selectedTeam);

  const onlyAgentUsers = users.filter(
    ({ type, roles, username }) =>
      type &&
      !NOT_USERS.includes(type) &&
      roles &&
      roles.includes(DASHBOARD_ANALYTICS_KEYS.LIVECHAT_AGENT_ROLE) &&
      username !== DASHBOARD_ANALYTICS_KEYS.YALO_SYSTEM_USER_ADMIN
  );

  const agentsByDepartment = onlyAgentUsers.filter(({ _id }) => agents.agents.find((agt) => agt.agentId === _id));

  return agentsByDepartment;
};

export const loadAgentslistFromMultiDepartments = async (selectedTeams: string[]) => {
  const {
    data: { users },
  } = await getUsersList('');

  const agentsPromises = selectedTeams.map((selectedTeam) => getAgentsByDepartments(selectedTeam));
  const responses = await Promise.all(agentsPromises);
  const agentsHash: Record<string, Agent> = {};
  responses.forEach(({ data }) => {
    data.agents.forEach((agent) => {
      if (!agentsHash[agent._id]) {
        agentsHash[agent._id] = agent;
      }
    });
  });
  const agents = Object.values(agentsHash);

  const onlyAgentUsers = users.filter(
    ({ type, roles, username }) =>
      type &&
      !NOT_USERS.includes(type) &&
      roles &&
      roles.includes(DASHBOARD_ANALYTICS_KEYS.LIVECHAT_AGENT_ROLE) &&
      username !== DASHBOARD_ANALYTICS_KEYS.YALO_SYSTEM_USER_ADMIN
  );

  const agentsByDepartment = onlyAgentUsers.filter(({ _id }) => agents.find((agt) => agt.agentId === _id));

  return agentsByDepartment;
};
