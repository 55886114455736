import React, { useContext, useEffect, useState } from 'react';
import { Stack, Popper, useMediaQuery, Theme } from '@mui/material';
import Picker, { IEmojiData } from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { StackIcons } from '../styled';
import { AttachFile } from '../../AttachFile';
import InputMessage from './InputMessage';
import ChatsContext from '../../../../contexts/chats/context';
import SnackbarCustom from '../../../../components/Snackbar';
import CannedMessagesContext from '../../../../contexts/cannedMessages/context';
import { EmojiButton, MainStack } from './style';
import { SendMessage } from '../hooks/useChat';
import useInputMessage from './InputMessage/useInputMessage';

export interface IMessageStack {
  showQuickMessagesIcon: boolean;
  isDiscussion: boolean;
  isWsConnected: boolean;
  isChatLoading?: boolean;
  sendMessage?: SendMessage;
}

const ChatMessageStack: React.FC<IMessageStack> = (props: IMessageStack) => {
  const { showQuickMessagesIcon, isDiscussion, isWsConnected, isChatLoading, sendMessage } = props;
  const { discussionId, serviceLimitReached, setEmoji, serviceSelected } = useContext(ChatsContext);

  const { t } = useTranslation();
  const theme = useTheme();
  const { disabledInputChat } = useInputMessage(discussionId, isDiscussion);
  const [showEmojis, setShowEmojis] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const isMobile = useMediaQuery((currentTheme: Theme) => currentTheme.breakpoints.down('md'));
  const [openSnacbar, setOpenSnabar] = useState(false);
  const { handleQuickMessages, showQuickMessages } = useContext(CannedMessagesContext);

  const onEmojiClick = (_: unknown, emojiObject: IEmojiData) => {
    setEmoji(emojiObject.emoji);
    setShowEmojis(false);
  };

  const handleSnackbar = () => {
    if (disabledInputChat && serviceLimitReached) {
      setOpenSnabar((old) => !old);
    }
  };

  useEffect(() => {
    if (showEmojis === false) {
      setAnchorEl(null);
    }
  }, [showEmojis]);

  useEffect(() => {
    setShowEmojis(false);
  }, [serviceSelected?._id]);

  return (
    <>
      <MainStack
        data-testid="chatMessageStack"
        sx={{
          [`${theme.breakpoints.down('md')}`]: {
            position: 'fixed',
            bottom: 0,
            width: '-webkit-fill-available',
          },
        }}
        direction="column"
      >
        <Stack gap="4px" direction="row">
          <StackIcons>
            <Tooltip title={`${t('sendEmoji')}`} placement="top">
              <EmojiButton
                data-testid="btnShowEmojis"
                disabled={disabledInputChat}
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  setAnchorEl(event.currentTarget);
                  setShowEmojis(!showEmojis);
                }}
                color={showEmojis ? 'primary' : 'neutral2'}
                variant="text"
              >
                <i className="fa-regular fa-face-smile fa-sm" />
              </EmojiButton>
            </Tooltip>
            {!isMobile && (
              <>
                <AttachFile disabled={disabledInputChat} sendMessage={sendMessage} />

                <IconButton
                  data-testid="btnShowCannedMessages"
                  disabled={disabledInputChat}
                  onClick={handleQuickMessages}
                  sx={{
                    display: showQuickMessagesIcon ? 'flex' : 'none',
                  }}
                  variant="text"
                  color={showQuickMessages ? 'primary' : 'neutral2'}
                >
                  <Tooltip title={`${t('quickMessages')}`} placement="top">
                    <i className="fa-regular fa-square-bolt fa-sm" />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </StackIcons>

          <InputMessage
            showQuickMessagesIcon={showQuickMessagesIcon}
            discussionId={discussionId}
            isDiscussion={isDiscussion}
            sendMessage={sendMessage}
            isWsConnected={isWsConnected}
            isChatLoading={isChatLoading}
          />
        </Stack>
      </MainStack>

      {showEmojis && (
        <Popper
          style={{ zIndex: 9999 }}
          placement="right-end"
          id="emoji-popper"
          open={showEmojis}
          anchorEl={anchorEl}
          data-testid="emojiPopper"
        >
          <Picker
            disableAutoFocus
            onEmojiClick={onEmojiClick}
            pickerStyle={{
              bottom: '45px',
              left: '-25px',
              height: isDiscussion ? '250px' : '320px',
            }}
          />
        </Popper>
      )}

      {openSnacbar && (
        <SnackbarCustom
          open={openSnacbar}
          message={t('serviceLimitReachedMessageSnackbar') || ''}
          handleClose={() => handleSnackbar()}
          type="warning"
        />
      )}
    </>
  );
};
export default ChatMessageStack;
