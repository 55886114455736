import { useTranslation } from 'react-i18next';
import { MessageBubble } from '@engyalo/design-system';
import { InteractiveMessageComponentProps } from '../InteractiveMessage';

const UserReplyContent: React.FC<InteractiveMessageComponentProps> = ({ userReply }) => {
  const { t } = useTranslation();
  if (!userReply) {
    return null;
  }

  const { title } = userReply;

  return <MessageBubble variant="user" text={t('messages.interactive.userReply', { option: title })} />;
};

export default UserReplyContent;
