import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

type Monitors = {
  monitorId: string;
  username: string;
};

type DeparmentsSave = {
  departmentId: string;
};

export const saveUnit = async (
  unitId: string | null,
  unitInfo: { name?: string; visibility?: string },
  monitors: Monitors[],
  departments?: DeparmentsSave[]
): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'livechat:saveUnit',
      params: [unitId, unitInfo, monitors, departments],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveUnit', params);
};
