import React from 'react';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { Container } from './style';

interface CardProps {
  data: string;
  title: string;
}

const Card = ({ data, title }: CardProps) => (
  <Container>
    <Typography variant="h1">{data}</Typography>
    <Typography variant="subtitle1">{t(title)}</Typography>
  </Container>
);

export default Card;
