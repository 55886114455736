import { styled } from '@mui/material/styles';

export const ClickableElement = styled('span')`
  cursor: pointer;
`;

interface IAudioCustom {
  type: string;
}

export const CustomAudio = styled('audio')<IAudioCustom>`
  max-width: ${({ type }) => (type === 'discussion' ? '25vw' : '18vw')};
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
  }

  &&::-webkit-media-controls-panel {
    background-color: #fff;
  }

  &&::-webkit-media-controls-mute-button {
    display: none !important;
  }

  &&::-webkit-media-controls-volume-slider {
    display: none !important;
  }

  &&::-webkit-media-controls-play-button {
    display: flex;
    width: 18px;
    height: 24px;
  }

  &&::-webkit-media-controls-timeline {
    width: 7vw;
  }
`;
