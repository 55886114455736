import { stringToColor } from './stringToColor';

export const stringAvatar = (name = '?') => {
  const splitedName = name.trim().split(' ');
  const initials = splitedName.length > 1 ? `${splitedName[0][0]}${splitedName[1][0]}` : `${splitedName[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: '36px',
      height: '36px',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '8%',
    },
    children: initials.toUpperCase(),
  };
};
