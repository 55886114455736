import * as yup from 'yup';

export function createYupSchema(schema: any, config: any) {
  const { _id, required, regexp } = config;

  let validator = yup.string();
  if (required) {
    validator = validator.required('fieldIsRequired');
  }
  if (regexp) {
    validator = validator.matches(regexp, 'invalidValue');
  }
  schema[_id] = validator;
  return schema;
}
