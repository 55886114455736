import { AxiosResponse } from 'axios';
import AxiosInstanceYalo from './AxiosInstanceYalo';

export const sendNotification = async (
  AgentID: string,
  ContactID: string,
  TemplateID: string,
  TeamID: string,
  Params: object
): Promise<AxiosResponse> =>
  AxiosInstanceYalo.post('v1/notification', {
    AgentID,
    ContactID,
    TemplateID,
    TeamID,
    Params,
  });
