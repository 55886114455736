import React from 'react';
import { IconLoadingRequest } from './style';
import { IAnimationLoading } from './types';

const LoadingAnimation: React.FC<IAnimationLoading> = (props: IAnimationLoading) => {
  const { color, size } = props;
  const fontSize = size || 20;
  return (
    <IconLoadingRequest
      data-testid="LoadingAnimationTestId"
      fill={color}
      style={{ width: fontSize, height: fontSize }}
    />
  );
};

export default LoadingAnimation;
