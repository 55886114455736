import React, { useContext } from 'react';
import { List, Popover, useMediaQuery, Theme } from '@mui/material';

import AuthContext from 'contexts/auth/context';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Container } from './style';
import { IMoreActions } from './types';
import useActionHandlers from './useActionHandlers';

import Action, { ACTION_TYPES } from './Action';

export const MoreActions: React.FC<IMoreActions> = (props: IMoreActions) => {
  const { open, anchorEl, handleClose, onHold, showOnHoldHeader, handleMenuClick } = props;

  const { allowsManualOnHold } = useContext(AuthContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const actionHandlers = useActionHandlers(handleMenuClick, handleClose);

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Popover
      id="tags-moreactions"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        marginTop: '50px',
      }}
      transformOrigin={{
        vertical: props.position === 'bottom' ? 'bottom' : 'top',
        horizontal: props.position === 'bottom' ? 'right' : 'left',
      }}
    >
      <Container data-testid="moreActions">
        <List>
          {
            // In the original action options, it was considering disabled props
            // (checkDisableDiscussion and checkDisableTransfer) for each item, but they were never used
            // TODO: check if they were meant to be used
          }
          <Action
            type={ACTION_TYPES.TRANSFER}
            isVisible={isMobile}
            title={t('transferChatKebabMobile')}
            icon={
              <i
                className="fa-regular fa-share"
                style={{
                  color: theme.palette.neutral.var80,
                }}
              />
            }
            onClick={actionHandlers.transfer}
          />

          <Action
            type={ACTION_TYPES.INTERNAL_DISCUSSION}
            isVisible={isMobile}
            title={t('discussionKebabMobile')}
            icon={
              <i
                className="fa-regular fa-screen-users"
                style={{
                  color: theme.palette.neutral.var80,
                }}
              />
            }
            onClick={actionHandlers.internalDiscussion}
          />

          <Action
            type={ACTION_TYPES.ON_HOLD}
            isVisible={!!allowsManualOnHold}
            title={onHold && showOnHoldHeader ? t('resumeOnHold') : t('putChatOnHold')}
            icon={
              <i
                className="fa-regular fa-circle-pause fa-xs"
                style={{
                  color: theme.palette.neutral.var80,
                }}
              />
            }
            onClick={actionHandlers.onHold}
          />

          <Action
            type={ACTION_TYPES.SEARCH}
            title={t('searchInChat')}
            icon={
              <i
                className="fa-regular fa-magnifying-glass fa-xs"
                style={{
                  color: theme.palette.neutral.var80,
                }}
              />
            }
            onClick={actionHandlers.search}
          />

          <Action
            type={ACTION_TYPES.HISTORY}
            title={t('chatHistory')}
            icon={
              <i
                className="fa-solid fa-clock-rotate-left fa-xs"
                style={{
                  color: theme.palette.neutral.var80,
                }}
              />
            }
            onClick={actionHandlers.history}
          />
        </List>
      </Container>
    </Popover>
  );
};
