import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  height: 100%;
`;

export const SectionInfo = styled('div')`
  padding: 16px;
  width: 100%;
`;
export const SectionButton = styled('div')`
  height: 52px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
`;

export const ContainerName = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding-bottom: 16px;
`;

export const ContainerContactInfo = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

export const SectionItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  gap: 8px;
`;
