import { IRoom } from '../../types/room';
import { SubscribeType } from '../../types/subscribe';
import { MessageType } from '../../types/message';

export enum ChatModalAction {
  Open = 'open',
  Close = 'close',
}

export interface IChatsContext {
  activeInput: bolean;
  serviceSelected: IRoom;
  handleActiveInput: () => void;
  handleServiceSelected: (service: any) => void;
  initChat: () => Promise<void>;
  inAttendanceChatsLength: number;
  handleUnreadMessage: (chatId: string, unread: boolean) => void;
  isSelectedItems: boolean;
  handleSelectedItems: () => void;
  subscribeChats: Array<SubscribeType>;
  getSubscribesChats: () => Promise<void>;
  handleReload: () => void;
  toggleCloseChatModal: (action: ChatModalAction) => void;
  handleFinishChat: (room: any) => void;
  loadUserInfo: (service: any) => void;
  setServiceSelected: (service: any) => void;
  setErrorCloseChat: (value: boolean) => void;
  putChatOnHold: (value: string) => Promise<void>;
  errorCloseChat: boolean;
  openModalCloseChat: boolean;
  chatId: string;
  basicContactInfo: {
    [key: string]: string;
    _id: string;
    name: string;
    email: string;
    phone: string;
    documentType: string;
    documentNumber: string;
    tags: string;
  };
  serviceLimitReached: boolean;
  disabledInputChat: boolean;
  loadDiscussionInfo: (serviceId: string) => Promise<void>;
  discussionId: string;
  loadRoomInfo: () => Promise<void>;
  alertBarMessage: string;
  handleOpenDiscussion: (value: boolean) => void;
  discussionOpened: boolean;
  userIsInDiscussion: boolean;
  setInAttendanceLength: React.Dispatch<React.SetStateAction<number>>;
  setEmoji: (value: string) => void;
  emoji: string;
  handleAddErrorMessages: (message: MessageType) => void;
  handleTrySendAgainMessage: () => Promise<void>;
  goToNewChat: (value: any) => void;
  isMobile: boolean;
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
}
