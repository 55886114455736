import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { Visitor } from './types';

type GetVisitorByTerm = { visitors: Visitor[]; count: number; offset: number; total: number; success: boolean };

export interface GetVisitorByTermProps {
  count: number;
  offset: number;
  term?: string;
  sort: string;
  sortValue: number;
}
export const getVisitorByTerm = async (props: GetVisitorByTermProps): Promise<AxiosResponse<GetVisitorByTerm>> => {
  const { term = '', count, offset, sort, sortValue } = props;
  const params = {
    term,
    offset,
    count,
    sort: { [sort]: sortValue },
  };

  return AxiosInstance.get('livechat/visitors.search', {
    params,
  });
};
