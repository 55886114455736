import { InputAdornment, Theme, useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@engyalo/design-system';
import debounce from 'lodash.debounce';
import { useControlledProp } from 'hooks/useControlledProp';
import { InputContainer } from './style';
import { IInputSearch } from './types';

const InputSearch: React.FC<IInputSearch> = (props: IInputSearch) => {
  const { onChange, placeholder, padding, value, testId, allowEmptySearch, debounce: debounceTime } = props;
  const { t } = useTranslation();
  const [textToSearch, setTextToSearch] = useState('');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  /**
   * If we apply the debounce this valueWithoutDebounce we can change the text of the input while the external value is waiting the debounce time
   * We avoid the text being frozen in each digit
   */
  const [valueWithoutDebounce, setValueWithoutDebounce] = useControlledProp(value ?? '');
  const inputText = debounceTime ? valueWithoutDebounce : value;
  const fallbackValue = inputText === '' && allowEmptySearch ? inputText : textToSearch;

  const handleChange = useMemo(
    () => (debounceTime ? debounce(onChange, debounceTime) : onChange),
    [debounceTime, onChange]
  );

  return (
    <InputContainer className="inputSearch" padding={padding}>
      <TextField
        inputProps={{ 'data-testid': testId || 'inputSearch' }}
        value={inputText || fallbackValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValueWithoutDebounce(event.target.value);
          handleChange(event.target.value);
          setTextToSearch(event.target.value);
        }}
        placeholder={t(placeholder)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ color: 'neutral.var60' }} position="end">
              <i className="fa-regular fa-magnifying-glass fa-xs" />
            </InputAdornment>
          ),
          endAdornment: isMobile && (
            <InputAdornment
              data-testid="deleteText"
              sx={{
                color: 'neutral.var50',
                display: value || textToSearch ? 'flex' : 'none',
              }}
              position="start"
              onClick={() => {
                const newValue = textToSearch.substring(0, textToSearch.length - 1);
                onChange(newValue);
                setTextToSearch(newValue);
              }}
            >
              <i className="fa-regular fa-delete-left" />
            </InputAdornment>
          ),
        }}
        // sx={{ minWidth: '100px' }}
      />
    </InputContainer>
  );
};
export default InputSearch;
