export default {
  colorVioletGradient: '#1A0670',
  colorOriginalsWhite: '#FFFFFF',
  colorBlack: '#010101',
  colorBlackWithOpacity: '#000000DE',
  colorBlackWithOpacity54: '#0000008A',
  colorWhite100: '#a0a0a0',
  colorWhite80: '#F1F1F1CC',
  colorWhite60: '#F1F1F1',
  colorWhite40: '#F2F2F2',

  colorNeutral10: '#F7F9F9',
  colorNeutral20: '#EEF1F3',
  colorNeutral30: '#DDE2E5',
  colorNeutral40: '#BFC7CE',
  colorNeutral50: '#8A98A5',
  colorNeutral60: '#5E6C78',
  colorNeutral70: '#3B454E',
  colorNeutral80: '#18222D',
  colorNeutral90: '#0E151C',
  colorNeutral100: '#06090B',
  colorPrimary10: '#D7E2FF',
  colorPrimary20: '#B5C9FF',
  colorPrimary30: '#92AFFF',
  colorPrimary40: '#6887F1',
  colorPrimary50: '#486CE9',
  colorPrimary60: '#003FEE',
  colorPrimary70: '#3F44C6',
  colorPrimary80: '#3136AB',
  colorPrimary90: '#2C2D8F',
  colorPrimary100: '#081F63',
  colorSecondary10: '#FFF1CC',
  colorSecondary20: '#FFE5A0',
  colorSecondary30: '#FFDB7C',
  colorSecondary40: '#FBC660',
  colorSecondary50: '#EDAE3E',
  colorSecondary60: '#DBA13D',
  colorSecondary70: '#B58940',
  colorSecondary80: '#816639',
  colorSecondary90: '#3D311F',
  colorSecondary100: '#2C251A',
  colorTertiary10: '#FFE9F0',
  colorTertiary20: '#FFDBE6',
  colorTertiary30: '#FFC8D9',
  colorTertiary40: '#FD98B6',
  colorTertiary50: '#BD5278',
  colorTertiary60: '#A50C57',
  colorTertiary70: '#811349',
  colorTertiary80: '#5F0C35',
  colorTertiary90: '#4C092A',
  colorTertiary100: '#3A061F',
  colorError10: '#FFE3DD',
  colorError20: '#F5D3CB',
  colorError30: '#E99797',
  colorError40: '#E57373',
  colorError50: '#EF5350',
  colorError60: '#F44336',
  colorError70: '#BA422F',
  colorError80: '#A01600',
  colorError90: '#461A1A',
  colorError100: '#231111',
  colorWarning10: '#FFEED4',
  colorWarning20: '#FBE4C1',
  colorWarning30: '#F6CC8A',
  colorWarning40: '#FFC281',
  colorWarning50: '#FF9B00',
  colorWarning60: '#DB8601',
  colorWarning70: '#AD5300',
  colorWarning80: '#683D00',
  colorWarning90: '#4A2800',
  colorWarning100: '#2B1703',
  colorSuccess10: '#E7FDF5',
  colorSuccess20: '#D3F9EB',
  colorSuccess30: '#95E5C8',
  colorSuccess40: '#40E4A9',
  colorSuccess50: '#1CC98A',
  colorSuccess60: '#01A569',
  colorSuccess70: '#18805A',
  colorSuccess80: '#29514C',
  colorSuccess90: '#1E3E3A',
  colorSuccess100: '#0D2522',
  colorInformational10: '#EDF9FF',
  colorInformational20: '#DFF4FF',
  colorInformational30: '#CCEDFF',
  colorInformational40: '#ABE1FF',
  colorInformational50: '#67C9FF',
  colorInformational60: '#49AADF',
  colorInformational70: '#1B7EB5',
  colorInformational80: '#045987',
  colorInformational90: '#054162',
  colorInformational100: '#0A293D',
};
