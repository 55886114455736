const useEnvironment = (): {
  getBasePath: () => string;
  getInstance: () => string;
  getUrl: (type: 'http' | 'websocket') => string;
} => {
  const getBasePath = () => {
    const paths = window.location.pathname.split('/').filter((item) => item !== '');

    if (!paths.length) {
      return '';
    }

    if (process.env.REACT_APP_EPHEMERAL) {
      const [ephemeralId, instance] = paths;
      return `/${ephemeralId}/${instance}`;
    }

    const [instance] = paths;
    return `/${instance}`;
  };

  const getInstance = () => {
    let path = '';
    const paths = getBasePath()
      .split('/')
      .filter((item) => item !== '');

    if (paths.length > 0) {
      if (process.env.REACT_APP_EPHEMERAL) {
        [, path] = paths;
        return path;
      }

      [path] = paths;
      return path;
    }

    return '';
  };

  const getUrl = (type: 'http' | 'websocket') => {
    const baseURL = `rc-${getInstance()}.yalochat.com`;
    if (type === 'http') {
      return `https://${baseURL}`;
    }
    return `wss://${baseURL}/websocket`;
  };

  return {
    getInstance,
    getBasePath,
    getUrl,
  };
};

export default useEnvironment;
