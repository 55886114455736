import { useState, useContext, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { AxiosResponse } from 'axios';
import { FormLoginProps } from './types';
import { schemaLogin, schemaLoginReset } from './yupSchema';
import { login } from '../../services/login';
import { me } from '../../services/me';
import AuthContext from '../../contexts/auth/context';
import { sendEmailResetPassword } from '../../services/sendEmailResetPassword';
import useSegment from '../../services/Analytics';

const useLogin = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormLoginProps>({
    resolver: yupResolver(forgotPassword ? schemaLoginReset : schemaLogin),
  });
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const { handleCurrentUser, handleCurrentUserInformation } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [browserConnection, setBrowserConnection] = useState(true);
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { identifySegmentUser, sendEvent, EVENT_NAMES } = useSegment();

  useEffect(() => {
    window.addEventListener('offline', () => {
      setBrowserConnection(false);
    });

    window.addEventListener('online', () => {
      setBrowserConnection(true);
    });
  }, []);

  const onSubmit: SubmitHandler<FormLoginProps> = async (form) => {
    setInvalidCredentials(false);
    setConnectionError(false);
    setLoading(true);
    if (browserConnection === false) {
      setLoading(false);
      setConnectionError(true);
    } else {
      try {
        const { username, password } = form;
        if (forgotPassword) {
          await sendEmailResetPassword(username);
          setEmailSent(true);
          setLoading(false);
          setForgotPassword(false);
          return;
        }
        const response = await login(username, password);

        const { data } = response.data;
        handleCurrentUser(data);
        const { name, email, _id: id } = data.me;

        datadogRum.setUser({
          id,
          name,
          email,
        });

        const userConfigs = await me();

        handleCurrentUserInformation(userConfigs.data);
        identifySegmentUser(userConfigs.data);
        sendEvent(EVENT_NAMES.USER_LOGIN);

        setLoading(false);

        if (userConfigs.data.requirePasswordChange) {
          navigate('/resetPassword');
          return;
        }

        navigate('/');
      } catch (e) {
        const error = e as AxiosResponse;
        const { status } = error;
        if (status === 401) {
          setInvalidCredentials(true);
          setLoading(false);
        }
      }
    }
  };

  return {
    control,
    handleSubmit,
    translation: t,
    onSubmit,
    invalidCredentials,
    errors,
    loading,
    connectionError,
    forgotPassword,
    setForgotPassword,
    emailSent,
  };
};

export default useLogin;
