import { styled } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

export const Container = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
`;

export const TabsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  height: 4.8rem;
`;

export const ContainerListChats = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NotFoundChatsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 32px 0px;
`;

export const MainText = styled(Typography)`
  width: 24rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ChatList = styled(List)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
`;
