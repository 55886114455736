import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, TextField } from '@engyalo/design-system';

export const LoginContainer = styled('div')`
  background: white;
  display: flex;
  min-height: 100vh;
  justify-content: center;
`;

export const Bg = styled('div')`
  background: black;
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 4.4rem;
    line-height: 115%;
    text-align: center;
    color: #ffffff;
    z-index: 100;
    width: 41rem;
    margin: auto;
  }
`;

export const BlueCircle = styled('div')`
  height: 80%;
  aspect-ratio: 1 / 1;
  background: transparent;
  border: 2px solid #0246ff;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: -60%;
`;

export const YellowBorderedCircle = styled('div')`
  height: 50rem;
  aspect-ratio: 1 / 1;
  background: transparent;
  border: 2px solid #eeae3d;
  border-radius: 100%;
  position: absolute;
  top: -25rem;
  right: -25rem;
`;

export const YellowHalfCircle = styled('div')`
  width: 25rem;
  height: 50rem;
  background-color: #eeae3d;
  border-bottom-left-radius: 1000rem;
  border-top-left-radius: 1000rem;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  top: -25rem;
  position: absolute;
  left: 40%;
`;

export const GreenTriangle = styled('div')`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 33vh 33vh 0px;
  border-color: transparent transparent #01a569 transparent;
  bottom: 0;
  position: absolute;
  left: 56%;
`;

export const BoxForm = styled('form')`
  border-radius: 6px;
  align-self: center;
  z-index: 1;
  max-width: 345px;
`;

export const HeaderForm = styled(Stack)`
  height: 121px;
  border-radius: 6px 6px 0px 0px;
  background: ${({ theme }) => theme.palette.neutral.var10};
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const BoxInputs = styled(Stack)`
  background: ${({ theme }) => theme.palette.common.white};
  padding: 16px;
`;

export const ButtonSubmit = styled(Button)(({ theme }) => ({
  fontFamily: 'Avenir Next !important',
  fontStyle: 'normal',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '1px',
  height: '68px',
  background: `${theme.palette.common.black} !important`,
  color: `${theme.palette.common.white} !important`,
  borderTopLeftRadius: '0px !important',
  borderTopRightRadius: '0px !important',
  textTransform: 'uppercase',
}));

export const TextInput = styled(TextField)`
  background: ${({ theme }) => theme.palette.common.white} !important;
  border-radius: 3px;

  .MuiOutlinedInput-input {
    padding: 0px;
    height: 36px;
  }

  .MuiFormControl-root-MuiTextField-root {
    margin: 0px;
    padding: 0px;
  }
  .MuiOutlinedInput-root {
    &.Mui-focused {
      border: ${({ theme }) => `1px solid ${theme.palette.neutral.var40}`};
    }

    padding: 0px;
    &.Mui-focused fieldset {
      border: none;
    }
  }

  input:-webkit-autofill {
    padding: 0px;
    -webkit-box-shadow: ${({ theme }) => `0 0 0px 1000px ${theme.palette.common.white} inset`};
  }

  :hover {
    border: none;
  }
`;
