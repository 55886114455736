import { styled } from '@mui/material/styles';

export const UnReadBadge = styled('div')`
  width: 18px;
  height: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme }) => theme.palette.primary.darker};
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 10px;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: 0;
`;
