import React from 'react';
import { Button } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import { MainText } from './style';
import GenericModal from '../../../../../components/Modal';

import ButtonsRow from '../../../../../components/ButtonsStack';

export const ModalErrorPermission = (props: any) => {
  const { openErrorPermission, setErrorPermission } = props;
  const { t } = useTranslation();
  return (
    <>
      {openErrorPermission && (
        <GenericModal open={openErrorPermission} title={t('permissionRecordAudio')}>
          <MainText variant="body1" color="neutral.var80">
            {t('messagePermissionRecordAudio')}
          </MainText>
          <ButtonsRow justifyContent="space-around">
            <Button
              onClick={() => {
                setErrorPermission(false);
              }}
            >
              {t('ok')}
            </Button>
          </ButtonsRow>
        </GenericModal>
      )}
    </>
  );
};
