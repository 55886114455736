import { STORAGE_KEYS, YALO_ADMIN } from 'constants/defaultValues';
import { User } from 'services/types';
import { changeLivechatStatus } from 'services/changeLivechatStatus';
import { getLivechatUserInfo } from 'services/getLivechatUserInfo';
import { logError } from 'services/Logger';
import { setUserStatus } from 'services/setUserStatus';
import { UserStatus, UserStatusLivechat } from 'types/user';

interface UserStatusArgs {
  status: User['status'];
  statusLivechat: User['statusLivechat'];
}

interface UserStatusCheckerArgs {
  setter: (user: User) => void;
}

const waitFetchTime = 3000; // 3 seconds
const userStatusIntervalTime = 180000; // 3 minutes
let userStatusInterval: NodeJS.Timeout | null = null;

/**
 * Check if user status should be checked
 * @returns True if user ID is set and not offline manually, false otherwise
 */
const shouldSkip = () => {
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);
  const manualOffline = localStorage.getItem(STORAGE_KEYS.MANUAL_OFFLINE) === 'true';
  const username = localStorage.getItem(STORAGE_KEYS.USERNAME);

  return !userId || manualOffline || username === YALO_ADMIN;
};

/**
 * Force user status to online and available if necessary
 * @returns True if status was forced, false otherwise
 */
export const forceUserStatus = async ({ status, statusLivechat }: UserStatusArgs) => {
  const isOnline = status === UserStatus.Online;
  const isAvailable = statusLivechat === UserStatusLivechat.Available;

  if (shouldSkip() || (isOnline && isAvailable)) {
    return false;
  }

  try {
    if (!isOnline) {
      const response = await setUserStatus(UserStatus.Online);
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update user status to online.');
      }
    }

    if (!isAvailable) {
      const response = await changeLivechatStatus(UserStatusLivechat.Available);
      const message = response.data.message && JSON.parse(response.data.message);
      if (!response.data.success || message?.error) {
        throw new Error(message?.error?.error || 'Failed to update live chat status to available.');
      }
    }

    return true;
  } catch (error) {
    logError('Error forcing user status to online and available:', error);
    return false;
  }
};

/**
 * Retrieve user livechat information
 * @returns User object if found, null otherwise
 */
export const retrieveUserLivechat = async (): Promise<User | null> => {
  const userId = localStorage.getItem(STORAGE_KEYS.USER_ID);

  if (!userId) {
    return null;
  }

  try {
    const response = await getLivechatUserInfo(userId);
    if (!response.data.success) {
      throw new Error('Failed to retrieve user live chat information.');
    }
    return response.data.user;
  } catch (error) {
    logError('Error retrieving user live chat information:', error);
    return null;
  }
};

/**
 * Check user status and force to online and available if necessary
 * @param setter Function to set user state
 */
export const userStatusChecker = async ({ setter }: UserStatusCheckerArgs) => {
  if (shouldSkip()) {
    return;
  }

  const userLivechat = await retrieveUserLivechat();

  if (userLivechat) {
    setter(userLivechat);
    const statusForced = await forceUserStatus(userLivechat);

    // Check status again if forced
    if (statusForced) {
      userStatusChecker({ setter });
    }
  }
};

/**
 * Initialize user status checker interval, clearing previous interval if exists
 */
const userStatusCheckerInterval = ({ setter }: UserStatusCheckerArgs) => {
  if (userStatusInterval) {
    clearInterval(userStatusInterval);
  }

  userStatusInterval = setInterval(() => {
    userStatusChecker({ setter });
  }, userStatusIntervalTime);
};

/**
 * Initialize user status checker interval and on focus event
 * @param setter Function to set user state
 * @returns Cleanup function
 */
export const initUserStatusChecker = async ({ setter }: UserStatusCheckerArgs) => {
  const onFocus = async () => {
    userStatusChecker({ setter });
    userStatusCheckerInterval({ setter });
  };

  window.addEventListener('focus', onFocus);
  userStatusCheckerInterval({ setter });

  // Wait some time to let status be updated before initial fetch
  await new Promise((resolve) => setTimeout(resolve, waitFetchTime));
  userStatusChecker({ setter });

  // return cleanup function
  return () => {
    window.removeEventListener('focus', onFocus);
    if (userStatusInterval) {
      clearInterval(userStatusInterval);
    }
  };
};
