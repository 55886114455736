import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Option = styled('div')<{ selected?: boolean }>`
  border: 0.1rem solid ${({ theme }) => theme.palette.neutral.var30};
  color: ${({ theme, selected }) => (selected ? theme.palette.text.primary : theme.palette.text.secondary)};
  background-color: ${({ selected, theme }) => (selected ? theme.palette.neutral.var30 : theme.palette.neutral.var10)};
  padding: 1rem 2rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, selected }) => !selected && theme.palette.action.hover};
  }
`;
