import React, { useContext, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PhotoSwipeLightbox, { DataSource } from 'photoswipe';
import { useTranslation } from 'react-i18next';
import useEnvironment from '../../../../hooks/useEnvironments';
import 'photoswipe/dist/photoswipe.css';
import './style.css';
import { IGallery } from './types';
import { ReactComponent as ModalErrorIcon } from '../../../../assets/icons/media-error.svg';
import WebsocketContext from '../../../../contexts/websocket/context';
import { IAttachment, MessageType } from '../../../../types/message';

const downloadIcon =
  '<path d="M14 20.5H4c-2.11 0-3.75-1.64-3.75-3.75v-2.813c0-.507.39-.937.938-.937a.95.95 0 01.937.938v2.812c0 1.055.82 1.875 1.875 1.875h10c1.016 0 1.875-.82 1.875-1.875v-2.813c0-.507.39-.937.938-.937a.95.95 0 01.937.938v2.812c0 2.07-1.68 3.75-3.75 3.75zm-4.375-6.484l5.313-5c.39-.352.39-.938.039-1.329-.352-.39-.938-.39-1.329-.039l-3.71 3.516V1.438A.95.95 0 009 .5a.925.925 0 00-.938.938v9.726l-3.75-3.516c-.39-.351-.976-.351-1.328.04-.156.195-.234.43-.234.625 0 .273.078.507.273.703l5.313 5a.92.92 0 001.289 0z" fill="#2A2264"/>';
const closeIcon =
  '<path d="M10.688 9.313a.964.964 0 010 1.406A.97.97 0 0110 11a.99.99 0 01-.719-.281L6 7.438l-3.313 3.28A.97.97 0 012 11a.99.99 0 01-.719-.281.964.964 0 010-1.406L4.563 6 1.28 2.719a.964.964 0 010-1.406.964.964 0 011.407 0L6 4.592l3.281-3.28a.964.964 0 011.406 0 .964.964 0 010 1.406L7.408 6.03l3.28 3.282z" fill="#2A2264"/>';
const arrowLeft =
  '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.4375 13.5312L0.71875 7.5625C0.5625 7.375 0.5 7.1875 0.5 7C0.5 6.84375 0.5625 6.65625 0.6875 6.5L6.40625 0.53125C6.6875 0.21875 7.1875 0.21875 7.46875 0.5C7.78125 0.78125 7.78125 1.25 7.5 1.5625L2.28125 7L7.53125 12.5C7.8125 12.7812 7.8125 13.2812 7.5 13.5625C7.21875 13.8438 6.71875 13.8438 6.4375 13.5312Z" fill="#2A2264"/></svg>';
const arrowRight =
  '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.53125 0.5L7.25 6.46875C7.375 6.625 7.46875 6.8125 7.46875 7C7.46875 7.1875 7.375 7.375 7.25 7.5L1.53125 13.4688C1.25 13.7812 0.75 13.7812 0.46875 13.5C0.15625 13.2188 0.15625 12.75 0.4375 12.4375L5.6875 6.96875L0.4375 1.53125C0.15625 1.25 0.15625 0.75 0.46875 0.46875C0.75 0.1875 1.25 0.1875 1.53125 0.5Z" fill="#2A2264"/></svg>';

const ModalError: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <>
      <ModalErrorIcon style={{ margin: 'auto' }} />
      <h1 className="media-error-title">{t('errorShowingMidiaTitle')}</h1>
      <p className="media-error-subtitle">
        {t('notPossibleShowFile')}
        <br />
        {t('checkYourConnection')}
        <br />
        {t('andTryAgain')}.
      </p>
    </>
  );
};

const SimpleGallery: React.FC<IGallery> = (props: IGallery) => {
  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  const { t } = useTranslation();
  const { username, url, onClose, type, isMobile } = props;
  const { messagesList, messagesListDiscussion } = useContext(WebsocketContext);

  useEffect(() => {
    const finalMediasArray: DataSource = [];

    const messagesAux = type === 'discussion' ? messagesListDiscussion : messagesList;

    let indexMedia = 0;

    messagesAux
      .filter(
        (el) =>
          el.attachments && el.attachments?.length > 0 && (el.attachments[0].image_type || el.attachments[0].video_type)
      )
      .forEach((itemMsg: MessageType, idx: number) => {
        if (itemMsg.attachments && itemMsg.attachments.length > 0) {
          itemMsg.attachments.forEach((attach: IAttachment) => {
            if (attach.image_type || attach.video_type) {
              if (url === attach.image_url || url === attach.video_url) {
                indexMedia = idx;
              }
              if (attach.video_type) {
                const videoHTML = `<div style="width: 100%; height: 100vh; padding-top: 50px;display: flex;
                  align-content: center;
                  align-items: center;
                  justify-content: center;
                ">
                <video style="max-height:100%" controls>
                  <source
                    src="${baseUrl}${attach.video_url}"
                    type="${attach.video_type}"
                  />
                </video></div>`;
                finalMediasArray.push({ html: videoHTML });
                return;
              }
              finalMediasArray.push({
                src: `${baseUrl}${attach.title_link}`,
                width: attach.image_dimensions?.width,
                height: attach.image_dimensions?.height,
                alt: `${t('imageSentBy')} ${username}`,
              });
            }
          });
        }
      });

    let lightbox = new PhotoSwipeLightbox({
      dataSource: finalMediasArray,
      loop: false,
      gallery: '#gallery',
      children: 'a',
      bgOpacity: 1,
      index: indexMedia,
      counter: false,
      close: false,
      zoom: false,
      initialZoomLevel: 'fit',
      wheelToZoom: true,
      secondaryZoomLevel: 2.5,
      arrowNextSVG: arrowRight,
      arrowPrevSVG: arrowLeft,
      pswpModule: () => import('photoswipe'),
      padding: isMobile ? { top: 0, bottom: 0, left: 0, right: 0 } : { top: 80, bottom: 40, left: 240, right: 240 },
    });

    lightbox.on('uiRegister', () => {
      lightbox.ui?.registerElement({
        name: 'text-label',
        order: 7,
        isButton: false,
        tagName: 'div',
        className: 'text-label',
        html: '',

        onInit: (el, pswp) => {
          const element = el;
          pswp.on('change', () => {
            const isVideo = pswp.currSlide?.data.html;
            element.innerText = `${isVideo ? t('videoSent') : t('imageSent')} por ${username}`;
            element.setAttribute('href', pswp.currSlide?.data.src || '');
          });
        },
      });

      lightbox.ui?.registerElement({
        name: 'download-button',
        order: 8,
        isButton: true,
        tagName: 'a',
        className: 'icon-button',

        // SVG with outline
        html: {
          isCustomSVG: true,
          inner: downloadIcon,
          outlineID: 'pswp__icn-download',
        },

        onInit: (el, pswp) => {
          el.setAttribute('download', '');
          el.setAttribute('target', '_blank');
          el.setAttribute('rel', 'noopener');
          el.children[0].setAttribute('viewBox', '0 0 17 22');
          el.children[0].setAttribute('width', '20');
          el.children[0].setAttribute('height', '20');

          pswp.on('change', () => {
            const parser = new DOMParser();
            const videoHTML = pswp.currSlide?.data.html || '';
            const videoElementNode = parser.parseFromString(videoHTML, 'text/html');
            const src = videoElementNode.querySelector('source')?.src || '';
            el.setAttribute('href', videoHTML ? src : pswp.currSlide?.data.src || '');
          });
        },
      });

      lightbox.ui?.registerElement({
        name: 'close-button',
        order: 9,
        isButton: true,
        tagName: 'a',
        className: 'icon-button close-button',

        // SVG with outline
        html: {
          isCustomSVG: true,
          inner: closeIcon,
          outlineID: 'pswp__icn-download',
        },
        onInit: (el) => {
          el.children[0].setAttribute('viewBox', '0 0 12 11');
          el.children[0].setAttribute('width', '12');
          el.children[0].setAttribute('height', '11');
        },
        onClick: () => {
          lightbox.close();
        },
      });
    });

    lightbox.addFilter('contentErrorElement', () => {
      const el = document.createElement('div');
      el.className = 'midia-error-msg';
      el.innerHTML = ReactDOMServer.renderToStaticMarkup(<ModalError />);
      return el;
    });

    lightbox.init();

    lightbox.on('close', () => {
      onClose();
    });

    return () => {
      lightbox.destroy();
      lightbox = {} as PhotoSwipeLightbox;
    };
  }, []);

  return <div className="pswp-gallery" id="teste" />;
};
export default SimpleGallery;
