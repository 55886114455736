import React, { useEffect, useState } from 'react';
import { Switch } from '@engyalo/design-system';
import { HIDDEN_DEPARTMENTS } from 'constants/defaultValues';
import { getDepartmentsListUnit } from '../../../services/getDepartmentsUnit';
import { getUnits } from '../../../services/getUnits';
import { ITeams } from '../../../types/department';
import { getDepartmentInfo } from '../../../services/getDepartmentInfo';
import { saveDepartment } from '../../../services/saveDepartment';
import { getDepartments } from '../../../services/getDepartments';
import { Department, Unit } from '../../../services/types';

export interface ITableRowsTeamsManager {
  team: string;
  agents: number;
  supervisors: number;
  unit: string;
  enable: JSX.Element;
  _id: string;
}

const useTeamsManager = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [headerColumns, setHeaderColumns] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<ITableRowsTeamsManager[]>([]);
  const [totalItems, setTotalItems] = useState<number>(-1);
  const [teams, setTeams] = useState<ITeams[]>([]);
  const [showModalEnableTeam, setShowModalEnableTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<ITeams | null>({} as ITeams);
  const [filterTableRows, setFilterTableRows] = useState<ITableRowsTeamsManager[]>([]);
  const [openDrawerForm, setOpenDrawerForm] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState(1);

  const handleFilter = (text?: string) => {
    if (text) {
      setFilterTableRows(tableRows.filter((item) => item.team.toLowerCase().includes(text.toLowerCase())));
      return;
    }
    setFilterTableRows(tableRows);
  };

  const handleCurrentPage = (_: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleNewTeam = () => {
    setOpenDrawerForm(true);
    setCurrentTeam({} as ITeams);
  };

  const onClickItem = (item: ITeams) => {
    const selectTeam = teams.find((team) => team._id === item._id);
    if (selectTeam) {
      setCurrentTeam({ ...item, unitId: selectTeam.unitId });
    }

    setOpenDrawerForm(true);
  };

  const disabledItem = (team: ITeams) => {
    setShowModalEnableTeam(true);
    setCurrentTeam(team);
  };

  const removeListValuesClientSide = (list: ITeams[]) =>
    list.filter((value) => !HIDDEN_DEPARTMENTS.includes(value.name));

  const handleTable = (list?: ITeams[]) => {
    const listValues = list || teams;
    const filteredListValues = removeListValuesClientSide(listValues);

    setHeaderColumns(['team', 'agents', 'supervisors', 'unit', 'enable']);
    const rows = filteredListValues.map((item) => {
      const agentsNumber = Number(item.numAgents || 0);
      return {
        team: item.name,
        agents: agentsNumber,
        supervisors: item.numMonitors,
        unit: item.unitName,
        enable: (
          <Switch
            data-testid="switchEnableDisableTeam"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              disabledItem(item);
            }}
            checked={item.enabled}
          />
        ),
        _id: item._id,
      };
    });
    setTableRows(rows);
    setFilterTableRows(rows);
  };

  const processUnits = async (units: Unit[]) => {
    const teamsList: ITeams[] = [];
    const promises = units
      .filter((unit) => unit.numDepartments > 0)
      .map(async (unit) => {
        const {
          data: { departments },
        } = await getDepartmentsListUnit(unit._id);

        const departmentList = departments.map((department) => ({
          ...department,
          unitId: unit._id,
          unitName: unit.name,
          numMonitors: unit.numMonitors,
        }));

        return departmentList;
      });

    const departmentLists = await Promise.all(promises);
    const flattenedList = departmentLists.flat();

    teamsList.push(...flattenedList);
    return teamsList;
  };

  const loadTeams = async () => {
    setLoading(true);
    try {
      const {
        data: { departments },
      } = await getDepartments();

      const userDepartments = departments;
      const {
        data: { units },
      } = await getUnits();

      const teamsList = await processUnits(units);

      const finalTeams: ITeams[] = [];
      userDepartments.forEach((element: Department) => {
        teamsList.forEach((el) => {
          if (el._id === element._id) {
            finalTeams.push(el);
          }
        });
      });

      setTeams(finalTeams);
      setTotalItems(finalTeams.length);
      handleTable(finalTeams);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCloseEnableModal = async (confirm?: boolean) => {
    if (confirm && currentTeam) {
      try {
        const {
          data: { department },
        } = await getDepartmentInfo(currentTeam._id);
        department.enabled = !department.enabled;
        const propsSaveDepartment = {
          requestId: `${requestId}`,
          _id: department._id,
          name: department.name,
          email: department.email,
          chatClosingTags: department.chatClosingTags,
          enabled: department.enabled,
          maxNumberSimultaneousChat: department.maxNumberSimultaneousChat,
          visitorInactivityTimeoutInSeconds: department.visitorInactivityTimeoutInSeconds,
          abandonedRoomsCloseCustomMessage: department.abandonedRoomsCloseCustomMessage,
        };
        const {
          data: { message },
        } = await saveDepartment(propsSaveDepartment);
        const response = JSON.parse(message);
        if (response.result) {
          setTableRows([]);
          setFilterTableRows([]);
          loadTeams();
          setRequestId(requestId + 1);
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
        setShowErrorModal(true);
      }
    }
    setShowModalEnableTeam(false);
    setCurrentTeam(null);
  };

  useEffect(() => {
    loadTeams();
  }, []);

  return {
    currentPage,
    headerColumns,
    filterTableRows,
    tableRows,
    totalItems,
    showModalEnableTeam,
    currentTeam,
    openDrawerForm,
    showErrorModal,
    setShowErrorModal,
    handleCloseEnableModal,
    handleCurrentPage,
    onClickItem,
    handleFilter,
    handleNewTeam,
    setOpenDrawerForm,
    loadTeams,
    loading,
  };
};

export default useTeamsManager;
