/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useCallback } from 'react';
import { FileQueue, IChatStore } from 'types/chat';

export const initialState: IChatStore = {
  selectedCannedMessages: {},
  roomTagNames: [],
  fileQueue: [],
};

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    setSelectedCannedMessages: (state, action: PayloadAction<IChatStore['selectedCannedMessages']>) => {
      state.selectedCannedMessages = action.payload;
    },
    setRoomTagNames: (state, action: PayloadAction<IChatStore['roomTagNames']>) => {
      state.roomTagNames = action.payload;
    },
    addFileQueue: (state, action: PayloadAction<FileQueue>) => {
      state.fileQueue.push(action.payload);
    },
    updateFileQueue: (state, action: PayloadAction<FileQueue>) => {
      state.fileQueue = state.fileQueue.map((file) =>
        file.name === action.payload.name ? { ...file, ...action.payload } : file
      );
    },
    removeFileQueue: (state, action: PayloadAction<string>) => {
      state.fileQueue = state.fileQueue.filter((file) => file.name !== action.payload);
    },
    resetChat: () => initialState,
  },
});

export const { setSelectedCannedMessages, setRoomTagNames, resetChat } = chatSlice.actions;

export function useChatStore() {
  const dispatch = useAppDispatch();
  return {
    setSelectedCannedMessages: useCallback(
      (cannedMessage: IChatStore['selectedCannedMessages']) => dispatch(setSelectedCannedMessages(cannedMessage)),
      [dispatch]
    ),
    setRoomTagNames: useCallback(
      (tagNames: IChatStore['roomTagNames']) => dispatch(setRoomTagNames(tagNames)),
      [dispatch]
    ),
    addFileQueue: useCallback((file: FileQueue) => dispatch(chatSlice.actions.addFileQueue(file)), [dispatch]),
    updateFileQueue: useCallback((file: FileQueue) => dispatch(chatSlice.actions.updateFileQueue(file)), [dispatch]),
    removeFileQueue: useCallback(
      (fileName: string) => dispatch(chatSlice.actions.removeFileQueue(fileName)),
      [dispatch]
    ),
    resetChat: useCallback(() => dispatch(resetChat()), [dispatch]),
  };
}

export default chatSlice.reducer;
