import axios, { AxiosError, AxiosResponse } from 'axios';
import { logError } from './Logger';

const AxiosInstanceGAI = axios.create({
  baseURL: `${process.env.REACT_APP_GAI_API_URL}`,
  headers: { 'Content-type': 'application/json', Authorization: `Bearer ${process.env.REACT_APP_GAI_API_TOKEN}` },
});

AxiosInstanceGAI.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const { response = { ...error } } = error || {};
    logError('AxiosError', error);
    return Promise.reject(response);
  }
);

export default AxiosInstanceGAI;
