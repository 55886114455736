import { useContext, useEffect, useState } from 'react';
import { useChatStore } from 'redux/reducers/chatReducer';
import { useAppSelector } from 'hooks/redux';
import { selectChatRoomTagNames } from 'redux/selectors';
import ChatsContext from '../../../../contexts/chats/context';
import { tagsColor } from '../../../../helpers/tagsColors';

type ChipTagColor = {
  color: string;
  text: string;
};

type ChipTag = {
  name: string;
  customColor: ChipTagColor;
};

const useSubHeader = () => {
  const [anchorElTag, setAnchorElTag] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const { serviceSelected } = useContext(ChatsContext);
  const [chipTags, setChipTags] = useState<Array<ChipTag>>([]);
  const tagsSelected = useAppSelector(selectChatRoomTagNames);
  const { setRoomTagNames: setTagsSelected } = useChatStore();
  const handleOptionClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    setAnchorElTag(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElTag(null);
  };

  const loadChatInfo = () => {
    const room = serviceSelected;
    if (room.tags?.length > 0) {
      setTagsSelected(room.tags);
      return;
    }
    setTagsSelected([]);
    setChipTags([]);
  };

  useEffect(() => {
    loadChatInfo();
  }, [serviceSelected]);

  // Sync chipTags with tagsSelected
  useEffect(() => {
    const list = tagsSelected
      .map((item) => {
        const color = tagsColor();
        return {
          name: item,
          customColor: color,
        };
      })
      .slice(0, 2);
    if (tagsSelected.length > 2) {
      const color = tagsColor();
      list.push({
        name: `+${tagsSelected.length - 2}`,
        customColor: color,
      });
    }
    setChipTags(list);
  }, [tagsSelected]);

  return {
    chipTags,
    anchorElTag,
    handleOptionClick,
    handleClose,
    serviceSelected,
    tagsSelected,
  };
};

export default useSubHeader;
