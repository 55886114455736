import { ChipProps, StackProps } from '@mui/material';
import { Tooltip } from '@engyalo/design-system';
import { tagsColor } from 'helpers/tagsColors';
import { CustomChip } from '../style';
import { TagListWrapper, TagsTooltip } from './style';

export interface ITagList extends StackProps {
  tags: string[];
  maxTags?: number;
  size?: ChipProps['size'];
}

const defaultMaxTags = 2;

const TagList = (props: ITagList) => {
  const { tags = [], maxTags = defaultMaxTags, size, ...rest } = props;
  const tagsToShow = tags.slice(0, maxTags);
  const tooltipTags = tags.slice(maxTags);
  const showTooltip = Boolean(tooltipTags.length);
  const tooltipContent = tooltipTags.map((tag) => (
    <CustomChip key={tag} label={tag} size={size} customcolor={tagsColor(tag)} />
  ));

  return (
    <TagListWrapper gap="0.4rem" {...rest}>
      {tagsToShow.map((tag) => (
        <CustomChip key={tag} label={tag} size={size} customcolor={tagsColor(tag)} />
      ))}
      {showTooltip && (
        <Tooltip title={<TagsTooltip>{tooltipContent}</TagsTooltip>}>
          <CustomChip label={`+${tooltipTags.length}`} size={size} data-testId="moreTags" />
        </Tooltip>
      )}
    </TagListWrapper>
  );
};

export default TagList;
