import { Box, Checkbox, FormControlLabel, Typography, Icon } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@engyalo/design-system';

import PreviewTemplateContent from 'components/PreviewTemplateContent';
import PreviewTemplateButtons from 'components/PreviewTemplateButtons';
import { ButtonFormContainer, FormContainer, FormCustom, CheckboxIcon, ListItemSelect } from '../style';

import { CheckboxCustom } from '../../../../components/Checkbox/style';
import GenericAutocomplete from '../../../../components/Autocomplete';
import { updateTemplate } from '../../../../services/updateVerifiedTemplate';
import { ReactComponent as Loading } from '../../../../assets/icons/loadingYalo.svg';
import { IDepartmentsListUnit, TemplateInfo } from '../../../../services/types';

export interface IPropsVerifiedTemplates {
  HSMSelected?: TemplateInfo;
  onClose: (success: boolean) => void;
  handleErrorUpdt: (value: boolean) => void;
  loadHSMs: () => void;
  teams: IDepartmentsListUnit[];
  formIsChanged?: (isChanged: boolean) => void;
}

export interface IFormVerifiedTemplate {
  enabled: boolean;
  teams?: string[];
}

const FormVerifiedTemplates: React.FC<IPropsVerifiedTemplates> = ({
  HSMSelected,
  onClose,
  formIsChanged,
  handleErrorUpdt,
  loadHSMs,
  teams,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    setValue,
  } = useForm<IFormVerifiedTemplate>();

  const { Messages = [] } = HSMSelected || {};
  const { Buttons = [] } = Messages[0] || {};
  const onSubmit: SubmitHandler<IFormVerifiedTemplate> = async (form) => {
    try {
      const teamsWithoutAllOptions = form.teams?.filter((el) => el !== 'allOptions');

      await updateTemplate(HSMSelected?.ID || '', form.enabled, teamsWithoutAllOptions || ['']);

      onClose(true);
      loadHSMs();
    } catch (error) {
      console.log(error);
      handleErrorUpdt(true);
    }
  };

  useEffect(() => {
    if (formIsChanged) {
      formIsChanged(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (HSMSelected?.ID) {
      const ids = HSMSelected.SharedWith.map((item: any) => item.id) as any[];

      setValue('teams', ids);
    }
  }, [HSMSelected]);

  return (
    <FormContainer data-testid="formVerifiedTemplate">
      <FormCustom onSubmit={handleSubmit(onSubmit)}>
        {HSMSelected?.ID ? (
          <div
            style={{
              width: '100%',
            }}
          >
            <Typography variant="caption1" color="common.black" sx={{ marginTop: '40px' }}>
              {t('hsm')}
            </Typography>
            <PreviewTemplateContent Message={Messages[0]} />
            <PreviewTemplateButtons Buttons={Buttons} />

            <div style={{ marginTop: '40px' }}>
              <Typography variant="caption1" color="common.black">
                {t('sharedWith')}
              </Typography>
              <Controller
                name="teams"
                control={control}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <GenericAutocomplete
                      placeholder={t('selectTeams')}
                      defaultValue={value}
                      renderOption={(props, option, { selected }) => {
                        props.className = '';
                        return (
                          <ListItemSelect {...props}>
                            <Checkbox checked={selected} />
                            {option.name}
                          </ListItemSelect>
                        );
                      }}
                      handleChange={(value) => {
                        const newValue = value.map((item: any) => item._id);
                        onChange(newValue);
                      }}
                      limitTagsNumber={1}
                      multiple
                      options={
                        teams.length
                          ? [{ _id: 'allOptions', name: t('allTeams') }, ...teams.filter((item) => item.name !== 'bot')]
                          : []
                      }
                    />
                  );
                }}
              />
            </div>

            <div
              style={{
                marginTop: '2.4rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Controller
                name="enabled"
                control={control}
                defaultValue={HSMSelected.IsActiveSalesdesk}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <CheckboxCustom
                        disableRipple
                        disableTouchRipple
                        icon={<CheckboxIcon />}
                        checked={field.value}
                        onChange={(event: any) => {
                          field.onChange(event.target.checked);
                        }}
                        sx={{ marginRight: '.4rem' }}
                      />
                    }
                    label={`${t('enable')}`}
                    sx={{ marginLeft: 0, marginRight: '.8rem' }}
                  />
                )}
              />
              <Tooltip title={`${t('teamResponsibleMessage')}`} placement="top">
                <Icon
                  className="fa-solid fa-circle-question"
                  sx={{
                    color: 'neutral.var40',
                    marginLeft: '5px',
                    cursor: 'pointer',
                    fontSize: '1.1rem',
                  }}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <Box alignSelf="center">
            <Loading />
          </Box>
        )}

        <ButtonFormContainer>
          <Button type="submit" fullWidth disabled={!isDirty} startIcon={<i className="fa-regular fa-check" />}>
            {t('saveChanges')}
          </Button>
        </ButtonFormContainer>
      </FormCustom>
    </FormContainer>
  );
};
export default FormVerifiedTemplates;
