import React from 'react';
import { IDrawer } from './types';
import { DrawerWrapper } from './style';

const Drawer: React.FC<IDrawer> = (props: IDrawer) => {
  const { children, open, ...rest } = props;
  return (
    <DrawerWrapper data-testid="drawer" variant="persistent" anchor="right" open={open} {...rest}>
      {children}
    </DrawerWrapper>
  );
};

export default Drawer;
