import { Typography } from '@mui/material';
import { styled, shouldForwardPropHelper, shadow8 } from '@engyalo/design-system';

const maxWidth = 1920;

export const HomeContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, ${theme.palette.common.black} 70px, ${theme.palette.neutral.var10} 0%);
  
  ${theme.breakpoints.up('xl')} {
    padding: 24px;
  }

  ${theme.breakpoints.down(maxWidth)} {
    html.font-size-medium &,
    html.font-size-large & {
      padding: 0;
    }
  }
`
);

export const MainContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 192rem;

  ${theme.breakpoints.up('xl')} {
    border-radius: 8px;
    box-shadow: ${shadow8};
  }

  ${theme.breakpoints.down(maxWidth)} {
    html.font-size-medium &,
    html.font-size-large & {
      border-radius: 0;
      box-shadow: none;
    }
  }
`
);

export const QueueMenuWrapper = styled('div')(
  ({ theme }) => `
  width: 30%;
  max-width: 48rem;
  min-width: 320px;

  ${theme.breakpoints.down('md')} {
    display: none;

    &.show {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
`
);

export const ChatWrapper = styled('div')(
  ({ theme }) => `
  flex: 1;
  overflow: hidden;
  ${theme.breakpoints.down('md')} {
    display: none;

    &.show {
      width: 100%;
      display: block;
    }
  }
`
);

export const DrawerWrapper = styled('div')(
  ({ theme }) => `
  position: relative;
  width: 30%;
  max-width: 48rem;
  min-width: 32px;
  background-color: ${theme.palette.background.paper};

  ${theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 100%;
  }
`
);

export const ChatContainer = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral.var10};
  justify-content: space-between;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
`;

export const MainText = styled(Typography)`
  width: 300px;
  height: 60px;
  left: 0px;
  margin-top: 32px;

  display: flex;
  align-items: center;
  text-align: center;
`;
