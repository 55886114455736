import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerCannedMessages = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ContainerTableAndFooter = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonFormContainer = styled('div')`
  padding: 8px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  justify-content: space-between;
`;

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  gap: 40px;
  height: 100%;
`;

export const PreviewBubbleMessage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  width: 334px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  margin: 32px;
`;

export const TextMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  max-width: 334px;
  overflow: hidden;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`;
