import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, Popover } from '@mui/material';

import { Tooltip } from '@engyalo/design-system';
import PutOnHoldModals from 'components/PutOnHoldModals';
import { Container, ListItemTxt } from './style';
import { IKebabOptions, IOptions } from './types';
import useKebabOptions from './useKebabOptions';

export const KebabOptions: React.FC<IKebabOptions> = (props: IKebabOptions) => {
  const { anchorElKebab, open, handlePopoverKebab, itemSelected, kebabName, loadRoom } = props;

  const { options, showTooltipCopied, translation, openOnHoldModal, handleCloseOnHoldModal } = useKebabOptions(
    itemSelected,
    handlePopoverKebab,
    kebabName
  );

  return (
    <>
      <Popover
        data-testid="kebab-moreactions-chats"
        id="kebab-moreactions-chats"
        open={open}
        anchorEl={anchorElKebab}
        onClose={handlePopoverKebab}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container>
          <List>
            {options.map((item: IOptions, idx: number) => (
              <ListItem disablePadding key={idx}>
                <Tooltip
                  open={showTooltipCopied && item.type === 'copyLink'}
                  title={`${translation('copied')}`}
                  placement="top"
                >
                  <ListItemButton
                    data-testid="itemKebab"
                    onClick={() => {
                      item.function();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '16px' }}>{item.icon}</ListItemIcon>
                    <ListItemTxt>{item.title}</ListItemTxt>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Container>
      </Popover>
      <PutOnHoldModals open={openOnHoldModal} handleClose={handleCloseOnHoldModal} onConfirm={loadRoom} />
    </>
  );
};
