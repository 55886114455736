import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Stack, Typography, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DrawerContext, { TABS } from 'contexts/drawer/context';
import { Button, IconButton, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { ChatModalAction } from 'contexts/chats/types.d';
import PutOnHoldModals from 'components/PutOnHoldModals';
import { StackHeader } from '../styled';
import { IHeader } from './types';
import ChatsContext from '../../../../contexts/chats/context';
import { MoreActions } from '../../../../components/MoreActions';
import MenuTransfer from '../../../../components/Transfer';
import TransferProvider from '../../../../contexts/transfer/provider';
import useEnvironment from '../../../../hooks/useEnvironments';
import Discussion from '../../Discussion';
import SelectAgentProvider from '../../../../contexts/selectAgentContext/provider';
import AuthContext from '../../../../contexts/auth/context';
import useSegment from '../../../../services/Analytics';
import ConversationContext from '../../../../contexts/conversation/context';
import { checkDisableDiscussion, checkDisableTransfer } from '../../../../helpers/checkDisableButton';

export enum MENU {
  TRANSFER = 'transfer',
  DISCUSSION = 'discussion',
  CLOSE_CHAT = 'closeChat',
  MORE_ACTIONS = 'moreActions',
  PUT_ON_HOLD = 'putOnHold',
}

type MenuState = MENU | null;

const Header: React.FC<IHeader> = ({ serviceSelected, showOnHoldHeader, openDrawer, discussionNotification }) => {
  const discussionButtonRef = useRef<HTMLButtonElement>(null);
  const transferButtonRef = useRef<HTMLButtonElement>(null);
  const moreActionsButtonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();
  const { getBasePath } = useEnvironment();
  // TODO: remove modal toggles from context
  const { basicContactInfo, toggleCloseChatModal, discussionId, handleOpenDiscussion, userIsInDiscussion, isMobile } =
    useContext(ChatsContext);
  const { handleStartNewConversation } = useContext(ConversationContext);
  const { currentUserInfo } = useContext(AuthContext);
  const { handleTabSelection } = useContext(DrawerContext);
  const [domain, setDomain] = useState('');
  const theme = useTheme();

  const [selectedMenu, setSelectedMenu] = useState<MenuState>(null);

  const { sendEvent, EVENT_NAMES } = useSegment();

  const handleArrowBackClick = useCallback(() => {
    window.open(domain, '_self');
  }, [domain]);

  const handleMenuClick = (menu: MENU) => {
    setSelectedMenu(menu);

    if (menu === MENU.MORE_ACTIONS) {
      sendEvent(EVENT_NAMES.USER_CLICKED_ON_KEBABI_MENU);
    }

    if (menu === MENU.DISCUSSION) {
      handleOpenDiscussion(true);
    }
  };

  const handleClose = () => {
    setSelectedMenu(null);

    // sohuld this only be set to discussions?
    handleOpenDiscussion(false);
  };

  const handleFinishChatModalOpen = () => {
    toggleCloseChatModal(ChatModalAction.Open);
  };

  const handleDrawerOpen = () => {
    if (window.location.search?.includes('history=true')) {
      handleTabSelection(TABS.CHATS_HISTORY);
      window.history.replaceState(null, '', window.location.pathname);
    } else {
      handleTabSelection(TABS.CONTACT_INFO);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      handleDrawerOpen();
    }
  }, []);

  useEffect(() => {
    const path = getBasePath();
    setDomain(path);
  }, []);

  return (
    <>
      <StackHeader data-testid="header">
        <Stack direction="row" alignItems="center" gap="8px" overflow="hidden">
          {isMobile ? (
            <Icon
              data-testid="arrowBackButtonChatHeader"
              onClick={handleArrowBackClick}
              className="fa-solid fa-arrow-left fa-xs"
              sx={{
                color: 'neutral.var80',
              }}
            />
          ) : (
            <Tooltip title={`${t('contactDetails')}`} placement="top">
              <IconButton data-testid="openDrawer" variant="outlined" color="neutral2" onClick={handleDrawerOpen}>
                <i className="fa-regular fa-user fa-xs" />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            variant="h3"
            sx={{ cursor: 'pointer' }}
            data-testid="openDrawerClickName"
            noWrap
            onClick={handleDrawerOpen}
          >
            {basicContactInfo.name}
          </Typography>
          {!openDrawer && !isMobile && (
            <Typography
              variant="body1"
              data-testid="openDrawerClickDocNumber"
              onClick={handleDrawerOpen}
              sx={{ marginLeft: '20px', cursor: 'pointer' }}
            >
              {basicContactInfo.documentType} {basicContactInfo.documentNumber}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap="8px">
          {!isMobile && (
            <>
              <Tooltip title={`${t('internalDiscussion')}`} placement="top">
                <IconButton
                  // id="discussion"
                  ref={discussionButtonRef}
                  data-testid="btnOpenDiscussion"
                  disabled={checkDisableDiscussion(userIsInDiscussion, serviceSelected, currentUserInfo)}
                  onClick={() => handleMenuClick(MENU.DISCUSSION)}
                >
                  {serviceSelected?._id === discussionNotification?.prid && discussionNotification?.unread ? (
                    <i
                      className="fa-regular fa-screen-users"
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <i
                      className="fa-regular fa-screen-users"
                      style={{
                        color: 'neutral.var70',
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={`${t('transfer')}`} placement="top">
                <IconButton
                  ref={transferButtonRef}
                  data-testid="btnOpenTransfer"
                  disabled={checkDisableTransfer(serviceSelected, currentUserInfo)}
                  // id="transfer"
                  color="neutral2"
                  variant="outlined"
                  onClick={() => handleMenuClick(MENU.TRANSFER)}
                >
                  <i className="fa-solid fa-share-all fa-sm" />
                </IconButton>
              </Tooltip>
            </>
          )}

          {isMobile ? (
            <IconButton
              data-testid="closeChatButtonChatHeader"
              disabled={
                serviceSelected?.closedBy ||
                serviceSelected.usersCount === 1 ||
                serviceSelected?.servedBy?._id !== currentUserInfo._id
              }
              id="closeChat"
              onClick={handleFinishChatModalOpen}
              variant="outlined"
            >
              <i className="fa-regular fa-box-check fa-sm" />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              onClick={
                serviceSelected?.closedBy
                  ? () => {
                      handleStartNewConversation(true);
                    }
                  : handleFinishChatModalOpen
              }
            >
              {serviceSelected?.closedBy ? t('newConversation') : t('finalize')}
            </Button>
          )}

          <Tooltip title={`${t('moreOptionsMenu')}`} placement="top">
            <IconButton
              ref={moreActionsButtonRef}
              data-testid="btnOpenMoreActions"
              id="moreActions"
              onClick={() => handleMenuClick(MENU.MORE_ACTIONS)}
              variant="text"
              color="neutral2"
            >
              <i className="fa-regular fa-ellipsis-vertical fa-2xs" />
            </IconButton>
          </Tooltip>
        </Stack>
      </StackHeader>

      <SelectAgentProvider>
        <Discussion
          open={selectedMenu === MENU.DISCUSSION}
          roomId={discussionId}
          anchorEl={discussionButtonRef?.current}
          handleClose={handleClose}
          departmentId={serviceSelected.departmentId}
        />
      </SelectAgentProvider>

      <TransferProvider>
        <MenuTransfer
          open={selectedMenu === MENU.TRANSFER}
          departmentId={serviceSelected.departmentId}
          anchorEl={transferButtonRef?.current}
          handleClose={handleClose}
          isMobile={isMobile}
        />
      </TransferProvider>

      <PutOnHoldModals open={selectedMenu === MENU.PUT_ON_HOLD} handleClose={handleClose} />

      <MoreActions
        open={selectedMenu === MENU.MORE_ACTIONS}
        handleMenuClick={handleMenuClick}
        anchorElMoreOpen={selectedMenu === MENU.MORE_ACTIONS}
        anchorEl={moreActionsButtonRef?.current}
        handleClose={handleClose}
        position="bottom"
        chatId={serviceSelected?._id}
        visitorToken={serviceSelected?.v?.token}
        onHold={serviceSelected?.onHold}
        showOnHoldHeader={showOnHoldHeader}
      />
    </>
  );
};

export default Header;
