import { ListItem, styled, Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-x: hidden;
`;

export const FieldsSection = styled('div')`
  gap: 40px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ListItemSelect = styled(ListItem)`
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  background: ${({ theme }) => theme.palette.common.white};
  :hover {
    color: ${({ theme }) => theme.palette.neutral.var90};
    background: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :active {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
`;

export const FooterButton = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  height: 52px;
  box-shadow: ${({ theme }) => ` inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;

export const Span = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  :first-letter {
    text-transform: capitalize;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemForm = styled('div')`
  width: 100%;
`;

export const Header = styled('div')`
  display: flex;
  flex-direction: row;
  height: 4.8rem;
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  gap: 8px;
`;

export const BackButton = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  cursor: pointer;
`;

export const OptionalLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  margin-left: 3px;
`;

export const helperTextStyle = {
  alignSelf: 'end',
};
