import { styled } from '@mui/material/styles';

export const OptionButton = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  fill: ${({ theme }) => theme.palette.neutral.var80};
`;

export const OptionButtonText = styled('span')`
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var80};
`;
