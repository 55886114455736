/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { schemaTeam } from './yupSchema';
import { ITeamFormSchema, IUseFormTeam } from '../types';
import { getUnits } from '../../../../services/getUnits';
import { IUnit } from '../../../../types/unit';
import { STORAGE_KEYS } from '../../../../constants/defaultValues';
import { saveUnit } from '../../../../services/saveUnit';
import { saveDepartment } from '../../../../services/saveDepartment';
import { editAgentsFromDepartments } from '../../../../services/editAgentsFromDepartment';
import { getDepartmentInfo } from '../../../../services/getDepartmentInfo';
import { IDepartmentInfo } from '../../../../types/department';
import { UserInformation } from '../../../../types/user';
import { logError } from '../../../../services/Logger';
import { getLiveChatUsersAgent } from '../../../../services/getLivechatUsersAgt';
import { getListDeparmentsUnit, getListMonitorsUnit } from '../../../../helpers/getByUnits';

const CONVERT_TIME_IN_SECONDS_TO_MINUTES = 60;

const useFormTeam = ({ handleClose, team }: IUseFormTeam) => {
  const defaultUnitStorage = localStorage.getItem(STORAGE_KEYS.DEFAULT_UNIT);
  const [agentsList, setAgents] = useState<UserInformation[]>([]);
  const [unitsState, setUnits] = useState<IUnit[]>([]);
  const defaultUnit = unitsState.find((item) => item.name === defaultUnitStorage);
  const [isLoadForm, setIsLoadForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm<ITeamFormSchema>({
    resolver: yupResolver(schemaTeam),
  });
  const [showModalUnsavedChanges, setShowModalUnsavedChanges] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showModalUnit, setShowModalUnit] = useState(false);
  const [currentSelectUnit, setCurrentSelectUnit] = useState<IUnit>();
  const [currentTeam, setCurrentTeam] = useState<IDepartmentInfo>();
  const [showModalDeleteUnit, setShowModalDeleteUnit] = useState(false);
  const [requestId, setRequestId] = useState(1);

  const loadAgents = async () => {
    try {
      const {
        data: { users },
      } = await getLiveChatUsersAgent();
      setAgents(users as UserInformation[]);
    } catch (error) {
      logError('error getLiveChatUsersAgent useFormteam', error);
    }
  };

  const loadUnits = async () => {
    try {
      const {
        data: { units },
      } = await getUnits();
      const list = units.filter((item) => item.type === 'u');
      setUnits(list);
    } catch (error) {
      logError('error loadUnits useFormteam', error);
    }
  };

  const loadTeam = async () => {
    reset({
      agents: [],
      chatClosingTags: [],
      name: '',
      email: '',
      visitorInactivityTimeoutInSeconds: undefined,
      abandonedRoomsCloseCustomMessage: '',
      maxNumberSimultaneousChat: undefined,
      unit: '',
    });
    if (team?.teamId) {
      const {
        data: { agents, department },
      } = await getDepartmentInfo(team.teamId);
      setCurrentTeam({ department, agents });
      setValue('name', department.name);
      setValue('email', department.email);
      setValue('chatClosingTags', department.chatClosingTags);
      setValue('abandonedRoomsCloseCustomMessage', department.abandonedRoomsCloseCustomMessage);
      if (department.visitorInactivityTimeoutInSeconds) {
        const parsedVisitorInactivityTimeoutInSeconds = parseInt(department.visitorInactivityTimeoutInSeconds, 10);
        const value = parseFloat(
          (parsedVisitorInactivityTimeoutInSeconds / CONVERT_TIME_IN_SECONDS_TO_MINUTES).toFixed(2)
        ).toString();
        setValue('visitorInactivityTimeoutInSeconds', value);
      }
      if (department.maxNumberSimultaneousChat) {
        setValue('maxNumberSimultaneousChat', department.maxNumberSimultaneousChat);
      }
      if (agents.length) {
        const ids = agents.map((item) => item.agentId);
        setValue('agents', ids);
      }
      setValue('unit', team.unitId);
    }
  };

  const onSubmit: SubmitHandler<ITeamFormSchema> = async (form) => {
    setIsSaving(true);
    const {
      unit,
      email,
      name,
      visitorInactivityTimeoutInSeconds,
      agents,
      chatClosingTags,
      maxNumberSimultaneousChat,
      abandonedRoomsCloseCustomMessage,
    } = form;
    const timeoutInSeconds = visitorInactivityTimeoutInSeconds
      ? Number(visitorInactivityTimeoutInSeconds) * CONVERT_TIME_IN_SECONDS_TO_MINUTES
      : '';
    try {
      const params = {
        requestId: `${requestId}`,
        _id: team?.teamId ? team?.teamId : null,
        email,
        name,
        visitorInactivityTimeoutInSeconds: timeoutInSeconds.toString(),
        chatClosingTags: chatClosingTags || [],
        maxNumberSimultaneousChat: maxNumberSimultaneousChat?.toString() || '',
        abandonedRoomsCloseCustomMessage: abandonedRoomsCloseCustomMessage || '',
        enabled: true,
      };
      const {
        data: { message },
      } = await saveDepartment(params);
      const response = JSON.parse(message);
      const { result } = response;
      const departmentId = result?._id;
      if (departmentId) {
        setRequestId(requestId + 1);
        const departments = await getListDeparmentsUnit(unit);
        const monitors = await getListMonitorsUnit(unit);
        departments.push({ departmentId, ...result });
        const unitInfo = unitsState.find((item) => item._id === unit);
        if (unitInfo) {
          await saveUnit(unitInfo._id, { name: unitInfo.name, visibility: unitInfo.visibility }, monitors, departments);
        }

        if (agents) {
          const departmentAgents = agentsList
            .filter((agent) => agent.departments?.includes(departmentId))
            .map((agent) => agent._id);

          const makeAgentDetails = (id: string) => {
            const foundAgent = agentsList.find((agent) => agent._id === id);
            return { agentId: id, username: foundAgent?.username };
          };

          const agentsToAdd = agents
            .filter((id) => !departmentAgents.includes(id))
            .map(makeAgentDetails)
            .filter((agentDetials) => agentDetials.username);

          const agentsToRemove = departmentAgents.filter((id) => !agents.includes(id)).map(makeAgentDetails);

          if (agentsToAdd.length || agentsToRemove.length) {
            await editAgentsFromDepartments(departmentId, agentsToRemove, agentsToAdd);
          }
        }
      }
      handleClose(true);
    } catch (error) {
      logError('error submit form useFormteam', error);
      setShowErrorModal(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangeForm = (isChanged: boolean) => {
    setUnsavedChanges(isChanged);
  };

  const loadValues = async () => {
    await Promise.all([loadAgents(), loadUnits()]);
    setIsLoadForm(true);
  };

  const handleModalUnit = (unit?: IUnit) => {
    setShowModalUnit(true);
    setCurrentSelectUnit(unit);
  };

  const handleDeleteUnit = (unit: IUnit) => {
    setShowModalDeleteUnit(true);
    setCurrentSelectUnit(unit);
  };

  const handleCloseModalUnit = (confirm?: boolean) => {
    setShowModalUnit(false);
    setShowModalDeleteUnit(false);
    if (confirm) {
      loadUnits();
    }
  };

  useEffect(() => {
    if (isLoadForm) {
      loadTeam();
    }
  }, [team?.teamId, isLoadForm]);

  useEffect(() => {
    loadValues();
  }, []);

  useEffect(() => {
    handleChangeForm(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (isLoadForm) {
      const unit = unitsState.find((item) => item.name === defaultUnitStorage);
      setValue('unit', unit?._id);
    }
  }, [isLoadForm, unitsState]);

  return {
    control,
    errors,
    agents: agentsList,
    units: unitsState,
    showModalUnsavedChanges,
    unsavedChanges,
    defaultUnit,
    isLoadForm,
    showErrorModal,
    currentTeam,
    isSaving,
    showModalUnit,
    currentSelectUnit,
    showModalDeleteUnit,
    handleDeleteUnit,
    setShowErrorModal,
    handleSubmit,
    onSubmit,
    setShowModalUnsavedChanges,
    handleChangeForm,
    handleModalUnit,
    handleCloseModalUnit,
  };
};

export default useFormTeam;
