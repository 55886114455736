import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const editAgentsFromDepartments = async (
  departmentId: string,
  agentsToRemove: any[],
  agentsToAdd: any[]
): Promise<AxiosResponse> => {
  const params = {
    remove: agentsToRemove,
    upsert: agentsToAdd,
  };
  return AxiosInstance.post(`livechat/department/${departmentId}/agents`, params);
};
