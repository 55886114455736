import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IChatsWaitingForServiceProps } from './types';
import { Container, NumberOfClientsWaiting, Icon } from './style';

const ChatsWaitingForService = ({ numberOfChats, ...props }: IChatsWaitingForServiceProps) => {
  const { t } = useTranslation();
  return (
    <Container {...props}>
      <Icon className="fa-solid fa-user-clock" fontSize="small" />
      <NumberOfClientsWaiting>{numberOfChats}</NumberOfClientsWaiting>
      <Typography variant="body1">{t('clientsWaiting')}</Typography>
    </Container>
  );
};

export default ChatsWaitingForService;
