import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';

import ChatManagerProvider from 'contexts/chatManager/provider';
import SplitProvider from 'providers/SplitProvider';
import AppWrapper from 'containers/AppWrapper';
import Login from '../views/login';
import { Home } from '../views/home';
import AuthProvider from '../contexts/auth/provider';
import ChatsProvider from '../contexts/chats/provider';
import WebsocketProvider from '../contexts/websocket/provider';
import PrivateRoute from './PrivateRoute';
import DrawerProvider from '../contexts/drawer/provider';
import { Settings } from '../views/settings';
import { Omnichannel } from '../views/omnichannel';
import useEnvironment from '../hooks/useEnvironments';
import { setBaseUrlAxiosInstance } from '../services/AxiosInstance';
import { setBaseUrlAxiosInstanceYalo } from '../services/AxiosInstanceYalo';
import ServiceManagement from '../views/ServiceManagement';
import AlertsProvider from '../contexts/alerts/provider';
import DepartmentProvider from '../contexts/departments/provider';
import MenuChatsProvider from '../contexts/menuChats/provider';
import { ResetPassword } from '../views/resetPassword';
import ConversationProvider from '../contexts/conversation/provider';

const BrowserWrapper: React.FC<{}> = ({ children }) => {
  const { getInstance } = useEnvironment();

  if (process.env.REACT_APP_EPHEMERAL) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    );
  }
  return <BrowserRouter basename={`/${getInstance()}`}>{children}</BrowserRouter>;
};

const RoutesApp: React.FC = () => {
  const { getInstance, getUrl } = useEnvironment();

  React.useEffect(() => {
    const instance = getInstance();
    const url = getUrl('http');
    setBaseUrlAxiosInstance(url);
    setBaseUrlAxiosInstanceYalo(instance);
  }, []);

  useEffect(() => {
    const locationUrl = window.location.href;

    if (locationUrl.includes('resetPasswordhttps://')) {
      const UrlSplited = locationUrl.split('resetPassword');

      const auxToken = UrlSplited[1].split('reset-password');

      window.location.replace(`${UrlSplited[0].substring(0)}resetPassword/${auxToken[1].substring(1)}`);
    }
  }, []);

  return (
    <SplitProvider>
      <BrowserWrapper>
        <AlertsProvider>
          <AuthProvider>
            <AppWrapper>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <WebsocketProvider>
                        <ChatsProvider>
                          <MenuChatsProvider>
                            <DrawerProvider>
                              <DepartmentProvider>
                                <ConversationProvider>
                                  <ChatManagerProvider>
                                    <Home />
                                  </ChatManagerProvider>
                                </ConversationProvider>
                              </DepartmentProvider>
                            </DrawerProvider>
                          </MenuChatsProvider>
                        </ChatsProvider>
                      </WebsocketProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/:chatId"
                  element={
                    <PrivateRoute>
                      <WebsocketProvider>
                        <ChatsProvider>
                          <MenuChatsProvider>
                            <DrawerProvider>
                              <DepartmentProvider>
                                <ConversationProvider>
                                  <ChatManagerProvider>
                                    <Home />
                                  </ChatManagerProvider>
                                </ConversationProvider>
                              </DepartmentProvider>
                            </DrawerProvider>
                          </MenuChatsProvider>
                        </ChatsProvider>
                      </WebsocketProvider>
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route
                  path="/resetPassword/:token"
                  element={
                    <WebsocketProvider>
                      <ResetPassword />
                    </WebsocketProvider>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <PrivateRoute permission="admin">
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/omnichannel"
                  element={
                    <PrivateRoute permission="livechat-monitor">
                      <Omnichannel />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/service-manager"
                  element={
                    <PrivateRoute>
                      <WebsocketProvider>
                        <DepartmentProvider>
                          <ChatManagerProvider>
                            <ServiceManagement />
                          </ChatManagerProvider>
                        </DepartmentProvider>
                      </WebsocketProvider>
                    </PrivateRoute>
                  }
                />
              </Routes>
            </AppWrapper>
          </AuthProvider>
        </AlertsProvider>
      </BrowserWrapper>
    </SplitProvider>
  );
};

export default RoutesApp;
