import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

interface ChangeLivechatStatusResponse {
  message: string;
  success: boolean;
}

export const changeLivechatStatus = async (status?: string): Promise<AxiosResponse<ChangeLivechatStatusResponse>> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'livechat:changeLivechatStatus',
      params: [{ status }],
    }),
  };
  return AxiosInstance.post('method.call/livechat:changeLivechatStatus', params);
};
