import { Checkbox, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import GenericAutocomplete from '../../../../components/Autocomplete';
import ModalError from '../../../../components/ErrorModal';
import GenericModal from '../../../../components/Modal';
import { ListItemDropdown, OptionalLabel } from '../../style';
import { Field } from '../Form/style';
import { ConfirmationButtons, ContainerFields, Footer, Form } from './style';
import useUnitModal from './useUnitModal';
import DeleteUnitModal, { IUnitModal } from '../DeleteUnit';

const UnitModal: React.FC<IUnitModal> = (props) => {
  const theme = useTheme();

  const { open, unit, handleClose } = props;
  const { t } = useTranslation();
  const {
    control,
    errors,
    listSupervisors,
    showModalError,
    showModalDelete,
    setShowModalDelete,
    watch,
    handleSubmit,
    onSubmit,
    setShowModalError,
  } = useUnitModal({
    unit,
    handleClose,
  });
  return (
    <>
      <GenericModal
        open={open}
        title={unit?._id ? t('editUnit') : t('createNewUnit')}
        hasCloseIcon
        handleModal={() => handleClose()}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ContainerFields>
            <Field>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: '100%' }}
                    placeholder={t('name')}
                    error={!!errors.name}
                    helperText={errors.name ? t('fieldIsRequired') : ''}
                    label={t('name')}
                  />
                )}
              />
            </Field>

            <Field>
              <Typography variant="caption1" color="neutral.var80">
                {t('supervisors')}
                <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
                <Tooltip title={t('supervisorsTooltipInfo')}>
                  <i
                    className="fa-solid fa-circle-question"
                    style={{
                      color: theme.palette.neutral.var50,
                      marginLeft: 3,
                    }}
                  />
                </Tooltip>
              </Typography>
              <Controller
                name="supervisors"
                control={control}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <GenericAutocomplete
                      placeholder={t('selectSupervisors')}
                      defaultValue={value}
                      renderOption={(props, option, { selected }) => {
                        const { className, ...rest } = props;
                        return (
                          <ListItemDropdown {...rest}>
                            <Checkbox disableRipple disableTouchRipple style={{ marginRight: 8 }} checked={selected} />
                            {option.name}
                          </ListItemDropdown>
                        );
                      }}
                      handleChange={(value) => {
                        const newValue = value.map((item: any) => item._id);
                        onChange(newValue);
                      }}
                      limitTagsNumber={1}
                      multiple
                      options={listSupervisors}
                      error={!!errors.supervisors}
                      renderCountItemsSelecteds
                      labelCountItemsSelecteds={t('supervisors')}
                    />
                  );
                }}
              />
            </Field>
          </ContainerFields>
          <Footer>
            {unit?._id && (
              <IconButton onClick={() => setShowModalDelete(true)} size="small" color="warning">
                <i className="fa-solid fa-trash-can" />
              </IconButton>
            )}
            <ConfirmationButtons>
              <Button onClick={() => handleClose()} variant="text">
                {t('cancel')}
              </Button>
              <Button disabled={!watch('name')} type="submit">
                {unit?._id ? t('save') : t('create')}
              </Button>
            </ConfirmationButtons>
          </Footer>
        </Form>
      </GenericModal>
      {showModalError && (
        <ModalError
          handleClose={() => setShowModalError(!showModalError)}
          message={t('errorOnSaveMessage')}
          title={t('errorOnSaveTitle')}
          open={showModalError}
        />
      )}
      {showModalDelete && (
        <DeleteUnitModal handleClose={() => setShowModalDelete(!showModalDelete)} open={showModalDelete} unit={unit} />
      )}
    </>
  );
};

export default UnitModal;
