/* eslint-disable import/no-extraneous-dependencies */
import { environmentUtils } from 'utils';
import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import AnimationLoading from '../components/AnimationLoading';

interface ISplitProviderProps {
  children: React.ReactElement;
}

const authorizationKey = `${process.env.REACT_APP_FF_TOKEN}`;
const key = environmentUtils.getEnvironmentInstance();
// eslint-disable-next-line new-cap
const factory = SplitSdk({
  core: {
    authorizationKey,
    key,
  },
} as SplitIO.IBrowserSettings);

const SplitProvider = ({ children }: ISplitProviderProps) => (
  <SplitFactory factory={factory} updateOnSdkTimedout>
    {({ isReady, isTimedout }) => {
      if (isTimedout)
        return (
          <h1>This website requires access to split.io. Please make sure your browser is not blocking requests.</h1>
        );
      if (!isReady) return <AnimationLoading />;
      return children;
    }}
  </SplitFactory>
);
export default SplitProvider;
