import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const sendMessage = async (message: string, chatId: string): Promise<AxiosResponse> => {
  const params = {
    message: {
      rid: chatId,
      msg: message,
    },
  };

  return AxiosInstance.post('chat.sendMessage', params);
};
