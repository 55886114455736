type StatusArgs = { error?: boolean; pending?: boolean; delivered?: boolean; read?: boolean };

type IconConditionPair = [(args: StatusArgs) => boolean, string];

const statusIconRules: IconConditionPair[] = [
  [({ error }) => Boolean(error), 'fa-circle-exclamation'],
  [({ pending }) => Boolean(pending), 'fa-clock'],
  [({ delivered, read }) => Boolean(delivered) && !read, 'fa-check-double'],
  [({ read }) => Boolean(read), 'fa-check-double $read'],
];

export const getStatusIconClass = ({ error, pending, delivered, read }: StatusArgs): string => {
  const [, iconClass] = statusIconRules.find(([validate]) => validate({ error, pending, delivered, read })) || [];
  return `status-icon fa-regular ${iconClass || 'fa-check'}`;
};
