import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, TextField } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import ChatsContext from 'contexts/chats/context';
import WebsocketContext from 'contexts/websocket/context';
import { getAgentInfo } from 'services/getAgentInfo';
import useSegment from 'services/Analytics';
import { useNavigate } from 'react-router-dom';
import { sendCustomField } from 'services/sendCustomField';
import { logError } from 'services/Logger';
import GenericModal from '../Modal';
import ButtonsRow from '../ButtonsStack';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';

export const ON_HOLD_CUSTOM_FIELD_KEY = 'putInHoldCause';

interface IPutOnHoldModalsProps {
  open: boolean;
  handleClose: () => void;
  onConfirm?: () => void | Promise<void>;
}

export interface IAgent {
  emails: Array<unknown>;
  livechat: {
    maxNumberSimultaneousChat: string;
  };
  name: string;
  status: string;
  username: string;
  _id: string;
}

// TODO: Refactor PutOnHoldModals.
// Purpose: Manage modals and logic for putting conversations on hold.
// Changes:
// 1. Added "loading" state to ensure agent info is ready before holding a chat.
// 2. Moved agent info loading from `MoreActions` to here.
// Considerations:
// - The current flow might benefit from further optimizations or a new UI design.
// - Ensure agent info is available when required.

const PutOnHoldModals: React.FC<IPutOnHoldModalsProps> = ({ open, handleClose, onConfirm }) => {
  const { t } = useTranslation();
  const { sendEvent, EVENT_NAMES } = useSegment();

  const navigate = useNavigate();

  const { putChatOnHold, setServiceSelected, handleServiceSelected, serviceSelected } = useContext(ChatsContext);

  const { showOnHoldHeader, handleSnackBar, setSnackBarMessage, handleResumeOnHoldChat } = useContext(WebsocketContext);

  const [loading, setLoading] = useState(false);
  const [agentInfo, setAgentInfo] = useState({} as IAgent);
  const [anotation, setAnotation] = useState('');
  const [modalError, setOpenModalError] = useState(false);
  const [openModalPutOnHoldAgentLimit, setOpenModalPutOnHoldAgentLimit] = useState(false);

  const handleModalError = () => {
    setOpenModalError((state) => !state);
    setOpenModalPutOnHoldAgentLimit(false);
  };

  const handleModalPutOnHoldAgentLimit = () => {
    setOpenModalPutOnHoldAgentLimit((state) => !state);
  };

  const callGetAgentInfo = async () => {
    try {
      setLoading(true);

      const { data } = await getAgentInfo(serviceSelected._id, serviceSelected.v.token);
      const { agent } = data;
      setAgentInfo(agent);
    } catch (error) {
      logError('error while fetching agent information');
    } finally {
      setLoading(false);
    }
  };

  const putOnHold = async (confirm?: boolean) => {
    try {
      if (Number.isNaN(parseInt(agentInfo.livechat?.maxNumberSimultaneousChat, 10)) && confirm === undefined) {
        await putChatOnHold(serviceSelected._id);
        await sendCustomField(serviceSelected.v.token, ON_HOLD_CUSTOM_FIELD_KEY, anotation);
        setServiceSelected({});
        setSnackBarMessage(t('chatPutOnHoldMessage'));
        handleSnackBar();
        navigate('/', { replace: true });
        return;
      }
      if (!Number.isNaN(parseInt(agentInfo.livechat?.maxNumberSimultaneousChat, 10)) && confirm === undefined) {
        handleModalPutOnHoldAgentLimit();
        return;
      }
      if (confirm) {
        await putChatOnHold(serviceSelected._id);
        await sendCustomField(serviceSelected.v.token, ON_HOLD_CUSTOM_FIELD_KEY, anotation);
        navigate('/', { replace: true });
        setServiceSelected({});
        handleModalPutOnHoldAgentLimit();
        setSnackBarMessage(t('chatPutOnHoldMessage'));
        handleSnackBar();
        handleClose();
      }
    } catch (err) {
      handleModalError();
    }
  };

  useEffect(() => {
    if (open) {
      sendEvent(EVENT_NAMES.USER_CLICKED_ON_PUT_ON_HOLD);
      callGetAgentInfo();
    }
  }, [open]);

  useEffect(() => {
    if (open && serviceSelected.onHold && showOnHoldHeader) {
      handleClose();
      handleServiceSelected({});
      (async () => {
        await handleResumeOnHoldChat(serviceSelected?._id);
        onConfirm?.();
      })();
    }
  }, [open, serviceSelected?._id, serviceSelected.onHold, showOnHoldHeader, onConfirm]);

  return (
    <>
      <GenericModal open={open} title={t('putChatOnHold')} hasCloseIcon handleModalClose={handleClose}>
        <Stack width="334px">
          <Typography variant="body1" color="neutral.var100">
            {t('putOnHoldMainMessage')}
          </Typography>
          <Typography variant="caption1" sx={{ marginTop: '32px' }}>
            {t('putInHoldCause')} ({t('optional')})
          </Typography>
          <TextField
            placeholder={t('rememberAboutPause')}
            onChange={(event) => {
              setAnotation(event.target.value);
            }}
          />
        </Stack>
        <ButtonsRow justifyContent="space-around">
          <Button sx={{ width: '14.8rem' }} variant="outlined" color="neutral2" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            disabled={loading}
            sx={{ width: '14.8rem' }}
            onClick={() => {
              putOnHold();
            }}
          >
            {t('putChatOnHold')}
          </Button>
        </ButtonsRow>
      </GenericModal>
      <GenericModal open={openModalPutOnHoldAgentLimit} title={`${t('putChatOnHold')}?`}>
        <Stack width="323px">
          <Typography variant="body1" color="neutral.var100">
            {t('putOnHoldAgentLimitMessage')}
          </Typography>
        </Stack>
        <ButtonsRow justifyContent="space-around">
          <Button sx={{ textTransform: 'capitalize' }} onClick={handleModalPutOnHoldAgentLimit}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              await putOnHold(true);
              onConfirm?.();
            }}
            sx={{ textTransform: 'none' }}
          >
            {capitalizeFirstLetter(t('putChatOnHold'))}
          </Button>
        </ButtonsRow>
      </GenericModal>
      <GenericModal open={modalError} title={t('putOnHoldError')}>
        <Stack width="300px">
          <Typography variant="body1" color="neutral.var100">
            {t('putOnHoldErrorMessage')}
          </Typography>
        </Stack>
        <ButtonsRow justifyContent="space-around">
          <Button variant="contained" onClick={handleModalError}>
            {t('ok')}
          </Button>
        </ButtonsRow>
      </GenericModal>
    </>
  );
};

export default PutOnHoldModals;
