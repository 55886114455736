import React, { useContext } from 'react';
import { ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from '@engyalo/design-system';
import { SessionInfo, SessionName, SessionConversationCounter, CustomIcon } from './style';

import { ISessionInfoView } from '../../contexts/menuChats/types';
import AuthContext from '../../contexts/auth/context';

export const SessionInfoView = ({ item, index, type }: ISessionInfoView) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUserInfo } = useContext(AuthContext);
  const handleExpandIcon = () => {
    if (type === 'yourChats' && (index === 0 || index === 1)) {
      return null;
    }
    if (item.expanded) {
      return (
        <i
          className="fa-regular fa-chevron-up fa-2xs"
          style={{
            color: theme.palette.neutral.var70,
            marginRight: '16px',
            marginTop: '16px',
          }}
        />
      );
    }
    return (
      <i
        className="fa-regular fa-chevron-down fa-2xs"
        style={{
          color: theme.palette.neutral.var70,
          marginRight: '16px',
          marginTop: '16px',
        }}
      />
    );
  };

  const getNumberChats = () => {
    if (
      currentUserInfo.livechat &&
      currentUserInfo.livechat.maxNumberSimultaneousChat !== '' &&
      item.id === 'inAttendance' &&
      type === 'yourChats'
    ) {
      return `${item.rooms.length}/${currentUserInfo.livechat?.maxNumberSimultaneousChat} ${t('conversations')}`;
    }
    const textConversations = item.rooms.length === 1 ? 'conversation' : 'conversations';
    return `${item.rooms.length} ${t(textConversations)}`;
  };

  const getColorIcon = (roomsLength: number, expanded: boolean) =>
    roomsLength || expanded ? theme.palette.neutral.var90 : theme.palette.neutral.var50;

  return (
    <SessionInfo>
      <CustomIcon>
        {item.icon !== '' ? (
          <i
            className={`fa-regular fa-${item.icon} fa-xl`}
            style={{
              color: getColorIcon(item.rooms.length, item.expanded),
            }}
          />
        ) : (
          <i
            className="fa-regular fa-copy"
            style={{
              color: getColorIcon(item.rooms.length, item.expanded),
            }}
          />
        )}
      </CustomIcon>

      <ListItemText
        sx={{ margin: 0 }}
        primary={
          <SessionName variant="subtitle1" isExpanded={item.expanded} hasChats={item.rooms.length > 0}>
            {item.title}
          </SessionName>
        }
        secondary={
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '17px',
            }}
          >
            {item.rooms.length > 0 ? (
              <SessionConversationCounter variant="caption1" color="neutral.var70">
                {getNumberChats()}
              </SessionConversationCounter>
            ) : (
              <Typography variant="caption1" color="neutral.var50">
                {t('emptyChatsMessage')}
              </Typography>
            )}
            {currentUserInfo.livechat &&
              currentUserInfo.livechat.maxNumberSimultaneousChat !== '' &&
              Number(currentUserInfo.livechat.maxNumberSimultaneousChat) === item.rooms.length &&
              type === 'yourChats' &&
              item.id === 'inAttendance' && (
                <p>
                  <Tooltip title={t('ServiceLimitReached') || ''}>
                    <i
                      className="fa-regular fa-triangle-exclamation"
                      style={{
                        color: theme.palette.neutral.var70,
                      }}
                    />
                  </Tooltip>
                </p>
              )}
          </span>
        }
      />

      {handleExpandIcon()}
    </SessionInfo>
  );
};
