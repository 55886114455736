import { List, ListItem, ListItemIcon, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardPropHelper } from '@engyalo/design-system';
import { IMobile } from '../../types/mobile';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const Header = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  height: 4.8rem;
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
`;

export const Body = styled(Stack)`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

export const SideBar = styled(Stack)`
  display: flex;
  flex-direction: column;
  padding: 8px 0px 0px;
  gap: 8px;
  width: 256px;
  background: ${({ theme }) => theme.palette.common.white};
  border-right: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
  height: 100%;
`;

export const ViewOption = styled(Stack)`
  height: 100%;
  width: 100%;
`;

export const SidebarList = styled(List)`
  padding: 8px 8px 0;
  gap: 8px;
`;

interface IEnableItem {
  isActive: boolean;
  isCollapseOpen?: boolean;
}
export const SidebarListIcon = styled(ListItemIcon)`
  min-width: 22px;
  justify-content: center;
`;

export const SidebarListItem = styled(ListItem, {
  shouldForwardProp: shouldForwardPropHelper,
})<IEnableItem>`
  padding: 8px 4px 8px 8px;
  gap: 8px;
  width: 239px;
  min-height: 3.6rem;
  border-radius: 4px;
  background: ${({ isActive, isCollapseOpen, theme }) =>
    isActive ? theme.palette.primary.main : isCollapseOpen ? theme.palette.neutral.var20 : theme.palette.common.white};
  cursor: pointer;
  :hover {
    ${({ isActive, theme }) => !isActive && `background: ${theme.palette.neutral.var20}`};
  }
  :active {
    background: ${({ theme }) => theme.palette.neutral.var30};
  }
`;

export const SidebarListItemText = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<IEnableItem>`
  width: 183px;
  line-height: 1.2em;
  color: ${({ isActive, theme }) => (isActive ? theme.palette.common.white : theme.palette.neutral.var70)};
`;

export const TableContainer = styled('div')`
  display: flex;
  overflow-y: scroll;
  margin-bottom: 48px;
`;

export const FooterPagination = styled('div')`
  display: flex;
  padding: 16px 48px;
  gap: 24px;
  height: 52px;
  width: calc(100% - 256px);
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20};`};
  position: absolute;
  bottom: 0;
`;

export const ItemManagement = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const ContainerTableAndFooter = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Circular TT Bold';
  color: ${({ theme }) => theme.palette.neutral.var80};
`;

export const FiltersContainer = styled('div')<IMobile>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: ${({ isMobile }) => (isMobile === 1 ? '8px' : '')};
`;

export const SearchContainer = styled('div')<IMobile>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ isMobile }) => (isMobile === 1 ? '8px' : '')};
`;

export const OptionalLabel = styled('span')`
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.neutral.var60};
  margin-left: 3px;
`;

export const helperTextStyle = {
  alignSelf: 'end',
};

export const ListItemDropdown = styled(ListItem)`
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  cursor: pointer;
  border: ${({ theme }) => ` 0px solid ${theme.palette.neutral.var20};`};
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  background: ${({ theme }) => theme.palette.common.white};
  :hover {
    color: ${({ theme }) => theme.palette.neutral.var80};
    background: ${({ theme }) => `${theme.palette.neutral.var30} !important`};
  }
  :focus {
    background-color: ${({ theme }) => `${theme.palette.neutral.var30} !important`};
  }
  :active {
    background-color: ${({ theme }) => `${theme.palette.neutral.var30} !important`};
  }
  text-transform: capitalize;
`;
