import { styled } from '@mui/material/styles';

interface IInputSearch {
  padding?: string;
}

export const InputContainer = styled('div')<IInputSearch>`
  padding: ${({ padding }) => padding || '8px 6px 8px 6px'};
  width: 100%;
  align-items: center;
`;
