import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  max-width: 25rem;
  width: 100%;
`;

export const Section = styled('div')`
  overflow: hidden;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const Title = styled('div')`
  font-weight: 600;
  padding: 0.8rem;
  background-color: ${({ theme }) => theme.palette.neutral.var20};
  color: ${({ theme }) => theme.palette.text.primary};
  border-bottom: 0.1rem solid ${({ theme }) => theme.palette.divider};
`;

export const Option = styled('div')<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  color: ${({ theme, selected }) => (selected ? theme.palette.text.primary : theme.palette.text.secondary)};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.action.selected : theme.palette.background.paper};
  padding: 0.8rem 1.6rem;
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, selected }) => !selected && theme.palette.action.hover};
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.palette.divider};
  }
`;
