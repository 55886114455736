import React, { useContext } from 'react';
import { List } from '@mui/material';
import { SelectAgentContainer, ListItem, AgentName } from './style';
import SelectAgentContext from '../../../contexts/selectAgentContext/context';

const SelectAgent: React.FC = () => {
  const { handleAgentSelected, agentsFiltered, handleAgentOnHover } = useContext(SelectAgentContext);

  return (
    <SelectAgentContainer data-testid="selectAgentContainer">
      <List
        sx={{
          overflowY: 'scroll',
        }}
        disablePadding
      >
        {agentsFiltered.map((agent) => (
          <ListItem
            data-testid="agentItem"
            tabIndex={0}
            key={agent._id}
            disablePadding
            onClick={() => {
              handleAgentSelected(agent.username, true);
            }}
            onMouseOver={() => {
              handleAgentOnHover(agent.username);
            }}
          >
            <AgentName variant="subtitle2" id="shortcut-id">
              {agent.name}
            </AgentName>
          </ListItem>
        ))}
      </List>
    </SelectAgentContainer>
  );
};

export default SelectAgent;
