/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { Checkbox, FormControlLabel, ListItem, RadioGroup, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Radio, Tooltip } from '@engyalo/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import GenericAutocomplete from '../../../../components/Autocomplete';
import EditTags from '../../../../components/Tags/EditTags';
import DepartmentContext from '../../../../contexts/departments/context';
import { ButtonFormContainer, FormContainer, FormCustom } from '../style';
import { schemaCannedMessages } from './yupSchema';
import { saveCannedMessages } from '../../../../services/saveCannedMessages';
import { ModalEdit } from '../../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import InputEditorText from '../../../../components/InputEditorText';
import { OptionalLabel } from '../../style';
import LoadingAnimation from '../../../../components/AnimationLoading';
import PreviewMessageModal from '../../../../components/PreviewMessageModal';
import { CannedMessage } from '../../../../services/types';
import { closeChatSuffixes } from '../../../../constants';

export interface IPropsForm {
  cannedMessage?: CannedMessage;
  onClose: (success: boolean) => void;
  formIsChanged?: (isChanged: boolean) => void;
}

export interface IFormCannedMessages {
  shortcut: string;
  text: string;
  scope: string;
  tags?: string[];
  departmentId?: string;
}

const shortcutField = 'shortcut';

const FormCannedMessages: React.FC<IPropsForm> = (props) => {
  const { cannedMessage, onClose, formIsChanged } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { departments } = useContext(DepartmentContext);
  const [showPreviewMessage, setShowPreviewMessage] = useState(false);
  const [openAlertEditInfo, setOpenAlertEditInfo] = useState({
    open: false,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    watch,
  } = useForm<IFormCannedMessages>({
    resolver: yupResolver(schemaCannedMessages),
  });

  const handleAlertEditInfo = () => {
    setOpenAlertEditInfo((old) => ({
      ...old,
      open: !old.open,
    }));
  };

  const onSubmit: SubmitHandler<IFormCannedMessages> = async (form) => {
    setLoading(true);
    try {
      const parameters = {
        ...form,
        _id: cannedMessage?._id,
        tags: form.tags?.length ? form.tags : [],
      };

      const { data } = await saveCannedMessages(parameters);
      if (data.success) {
        setLoading(false);
        onClose(true);
        return;
      }
      throw new Error(data);
    } catch (error) {
      console.log(error);
      setOpenAlertEditInfo({ open: true, error: true });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formIsChanged) {
      formIsChanged(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (cannedMessage && cannedMessage.departmentId) {
      setValue('departmentId', cannedMessage.departmentId);
    }
  }, [cannedMessage]);

  function findCloseSuffix(value?: string) {
    return closeChatSuffixes.find((suffix) => value?.endsWith(suffix));
  }

  return (
    <>
      <FormContainer>
        <FormCustom onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
            data-testid="form"
          >
            <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
              {t('shortcut')}{' '}
              <Tooltip title={t('shortcutInfoMessage') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="shortcut"
              control={control}
              defaultValue={cannedMessage?.shortcut || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ width: '100%' }}
                  placeholder={`!${t('shortcut')}`}
                  error={!!errors.shortcut}
                  helperText={errors.shortcut?.message}
                  inputProps={{ 'data-testid': 'shortcut' }}
                />
              )}
            />
            <Typography variant="caption1" color="neutral.var80">
              {t('text')}
              <Typography
                data-testid="showPreviewMessage"
                variant="caption1"
                onClick={() => setShowPreviewMessage(true)}
                color="primary.main"
                sx={{ cursor: 'pointer', marginLeft: '5px' }}
              >
                {t('viewMessage')}
              </Typography>
            </Typography>

            <Controller
              name="text"
              control={control}
              defaultValue={cannedMessage?.text || ''}
              render={({ field }) => (
                <InputEditorText
                  value={field.value}
                  handleValue={field.onChange}
                  placeholder={t('cannedMessagePlaceholder')}
                  error={!!errors.text}
                  helperText={errors.text?.message}
                />
              )}
            />
            <Typography variant="caption1" color="neutral.var80">
              {`${t('tagsLinked')}`}
              <OptionalLabel>{`(${t('optional')})`} </OptionalLabel>
              <Tooltip title={t('tagsInfoMessage') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="tags"
              control={control}
              defaultValue={cannedMessage?.tags || []}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <EditTags
                    placeholder={t('selectTagsPlaceholder')}
                    type="room"
                    editTag={false}
                    createTag={false}
                    handleChange={(selectTags: string) => {
                      const splitedTags = !selectTags ? [] : selectTags.split(';');
                      if (splitedTags !== value) {
                        onChange(splitedTags);
                      }
                    }}
                    currentTags={value?.join(';')}
                    // open
                  />
                );
              }}
            />
            <Typography variant="caption1" color="neutral.var80">
              {t('shareWith')}
              <Tooltip title={t('scopeInfoMessage') || ''}>
                <i
                  className="fa-solid fa-circle-question"
                  style={{
                    color: theme.palette.neutral.var50,
                    marginLeft: 3,
                  }}
                />
              </Tooltip>
            </Typography>
            <Controller
              name="scope"
              control={control}
              defaultValue={cannedMessage?.scope || 'global'}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={value || 'global'}
                    name="radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value="global"
                      control={<Radio size="small" />}
                      label={`${t('allOrganization')}`}
                    />
                    <FormControlLabel value="user" control={<Radio size="small" />} label={`${t('onlyMe')}`} />
                    <FormControlLabel
                      value="department"
                      control={<Radio size="small" />}
                      label={`${t('specificTeam')}`}
                    />
                    <GenericAutocomplete
                      disabled={value !== 'department'}
                      placeholder={t('selectOneTeam')}
                      defaultValue={cannedMessage?.departmentId || ''}
                      renderOption={(properties, option) => (
                        <ListItem
                          sx={{
                            height: '36px',
                            gap: '6px',
                            padding: '8px 12px',
                          }}
                          {...properties}
                        >
                          <Typography variant="caption1">{option.name}</Typography>
                        </ListItem>
                      )}
                      handleChange={(val) => {
                        setValue('departmentId', val?._id);
                      }}
                      limitTagsNumber={1}
                      multiple={false}
                      options={departments}
                      error={!!errors.departmentId}
                    />
                  </RadioGroup>
                );
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="shouldCloseChats"
                  checked={Boolean(findCloseSuffix(watch(shortcutField)))}
                  onChange={() => {
                    const shortcut = watch(shortcutField);
                    const suffix = findCloseSuffix(shortcut);
                    if (suffix) {
                      setValue(shortcutField, shortcut.replace(suffix, ''));
                    } else {
                      setValue(shortcutField, `${shortcut}$${t('finalize').toLowerCase()}`);
                    }
                  }}
                  data-testid="shouldCloseChats"
                />
              }
              label={t('quickMessagesCloseCheckbox')}
            />
          </div>
          <ButtonFormContainer>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              data-testid="saveButton"
              disabled={!isDirty || loading}
              startIcon={loading ? null : <i className="fa-regular fa-check fa-sm" />}
            >
              {loading ? <LoadingAnimation color={theme.palette.neutral.var50} /> : <>{t('saveModel')}</>}
            </Button>
          </ButtonFormContainer>
        </FormCustom>
      </FormContainer>

      {showPreviewMessage && (
        <PreviewMessageModal
          title={t('previewModelMessage')}
          open={showPreviewMessage}
          message={getValues('text')}
          handleClose={() => setShowPreviewMessage(false)}
        />
      )}

      <ModalEdit
        open={openAlertEditInfo.open}
        errorUpdt={openAlertEditInfo.error}
        handleEditing={(confirm) => {
          if (confirm) {
            handleAlertEditInfo();
          }
        }}
        handleModal={() => handleAlertEditInfo()}
      />
    </>
  );
};
export default FormCannedMessages;
