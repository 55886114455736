import { ListItem, List, Checkbox, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import TabPanel from '../../Tabs/TabPanel';
import {
  Container,
  IconEditTag,
  ButtonContainer,
  NotFoundContainer,
  OptionItem,
  CheckboxIcon,
  CustomPopover,
} from './style';
import { CustomChip } from '../style';
import CreateTagModal from '../CreateTagModal';
import useMenuTags from './useMenuTags';
import InputSearch from '../../InputSearch';
import { Tags } from '../../../services/types';
import { tagsColor } from '../../../helpers/tagsColors';

export interface IMenuTags {
  anchorEl?: HTMLDivElement | HTMLButtonElement;
  handleClose: () => void;
  chatId: string;
  clientToken: string;
  contactId: string;
  hasPermission: boolean;
  showChatTags?: boolean;
}

export interface ITagsList {
  tag: Tags;
  selected: boolean;
}

const MenuTags: React.FC<IMenuTags> = (props) => {
  const theme = useTheme();
  const { anchorEl, handleClose, chatId, clientToken, contactId, showChatTags, hasPermission } = props;
  const { t } = useTranslation();

  const {
    selectedTags,
    handleChecked,
    setOpenModal,
    setCurrentTag,
    openModal,
    currentTag,
    tabValue,
    handleChange,
    filterTags,
    typeDescription,
    handleCloseModal,
    loadTags,
  } = useMenuTags({
    anchorEl,
    clientToken,
    chatId,
    contactId,
  });

  const [open, setOpen] = useState<boolean>(false);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (anchorEl) {
      setOpen(Boolean(anchorEl));
    }
  }, [anchorEl]);

  const renderTagsList = () => (
    <Container data-testid="menuTags">
      <InputSearch
        placeholder="searchTags"
        value={inputText}
        onChange={(value) => {
          filterTags(typeDescription, value);
          setInputText(value);
        }}
      />

      {selectedTags.length ? (
        <List
          data-testid="tagList"
          onScroll={(ev) => {
            const isBottom =
              ev.currentTarget.scrollHeight - ev.currentTarget.scrollTop === ev.currentTarget.clientHeight;
            if (isBottom) {
              loadTags();
            }
          }}
          disablePadding
          sx={{
            width: '100%',
            minHeight: '20px',
            maxHeight: '200px',
            overflowY: 'scroll',
          }}
        >
          {selectedTags.map((item, index) => (
            <ListItem
              divider={index !== selectedTags.length - 1}
              disablePadding
              sx={{ height: '44px' }}
              key={item.tag._id}
            >
              <OptionItem>
                <div>
                  <Checkbox
                    data-testid="tagItem"
                    disableRipple
                    disableTouchRipple
                    checked={item.selected}
                    sx={{
                      padding: 0,
                      width: '16px',
                      heigth: '16px',
                      marginRight: '16px',
                      borderRadius: '4px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '21px',
                        padding: 0,
                      },
                      '& input:focus + *': {
                        background: theme.palette.informational.light,
                        borderRadius: '4px',
                      },
                    }}
                    icon={<CheckboxIcon />}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChecked(event.target.checked, item.tag.name, typeDescription)
                    }
                  />
                  <CustomChip size="small" label={item.tag.name} customcolor={tagsColor(item.tag.name)} />
                </div>
                {hasPermission && (
                  <IconEditTag
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenModal((oldState) => !oldState);
                      setCurrentTag(item.tag);
                    }}
                  >
                    <i className="fa-regular fa-pencil fa-xs" />
                  </IconEditTag>
                )}
              </OptionItem>
            </ListItem>
          ))}
        </List>
      ) : (
        <NotFoundContainer>
          <Typography variant="caption1" color="neutral.var50">
            {t('tagsNotFound')}
          </Typography>
        </NotFoundContainer>
      )}
      {hasPermission && (
        <ButtonContainer>
          <Button
            data-testid="newTagButton"
            id="newTag"
            onClick={() => {
              setOpenModal((oldState) => !oldState);
            }}
            fullWidth
            color="neutral2"
            disableElevation
            startIcon={<i className="fa-regular fa-plus" />}
          >
            {t('newTag')}
          </Button>
        </ButtonContainer>
      )}

      {openModal && (
        <CreateTagModal
          typeDescription={typeDescription}
          tag={currentTag}
          open={openModal}
          handleCloseModal={() => handleCloseModal()}
        />
      )}
    </Container>
  );

  return (
    <CustomPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <Tabs value={tabValue} onChange={handleChange} aria-label="tags tabs" sx={{ width: '100%' }} variant="fullWidth">
        <Tab
          style={{ width: '100%' }}
          onClick={() => filterTags('room')}
          label={t('chatTags')}
          id="tags-tab-0"
          aria-controls="tags-tabpanel-0"
          key={0}
        />

        <Tab
          style={{ display: !showChatTags ? 'none' : 'flex' }}
          onClick={() => filterTags('visitor')}
          label={t('contactTags')}
          id="tags-tab-1"
          aria-controls="tags-tabpanel-1"
          key={1}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {renderTagsList()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {renderTagsList()}
      </TabPanel>
    </CustomPopover>
  );
};

export default MenuTags;
