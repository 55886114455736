import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Inline, Container, Content, IconView } from './style';
import { IInlineNotification } from './types';
import DropDown from '../../../DropDown';
import ChatsContext from '../../../../contexts/chats/context';
import { IOptionsListProps } from '../../../../types/optionsList';
import WebsocketContext from '../../../../contexts/websocket/context';
import AuthContext from '../../../../contexts/auth/context';
import { getDepartmentInfo } from '../../../../services/getDepartmentInfo';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';
import { getUserInfo } from '../../../../services/getUserInfo';
import { logError } from '../../../../services/Logger';
import { STORAGE_KEYS } from '../../../../constants/defaultValues';

const InlineNotification: React.FC<IInlineNotification> = (props: IInlineNotification) => {
  const theme = useTheme();
  const { time, type, transferData, user, menuOptions, lastItem, typeListChat, comment } = props;
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [inlineData, setInlineData] = useState<{
    title: string;
    icon: React.SVGProps<SVGSVGElement>;
  }>();

  const [inlineDataTransfer, setInlineDataTransfer] = useState<{
    title: string;
    icon: React.SVGProps<SVGSVGElement>;
  }>();
  const [width, setWidth] = useState(0);
  const { initChat, serviceSelected, disabledInputChat, handleServiceSelected, isMobile } = useContext(ChatsContext);
  const { getCurrentUserId } = useContext(AuthContext);
  const { handleResumeOnHoldChat } = useContext(WebsocketContext);
  const [options, setOptions] = useState<Array<IOptionsListProps>>([]);
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE);
  const INLINEMESSAGECONTAINERID = 'inlineMessageContainer';
  const updateSize = () => {
    if (containerRef.current) {
      const value = containerRef.current.clientWidth * 0.7;
      setWidth(value);
    }
  };

  const getInfo = async () => {
    const userId = getCurrentUserId();
    let transferredToName = '';
    let transferredBYName = '';
    if (transferData !== undefined) {
      const { transferredBy, transferredTo } = transferData;

      if (transferredBy) {
        transferredBYName = transferredBy._id === userId ? `${capitalizeFirstLetter(t('you'))}` : transferredBy.name;
      }

      if (transferredTo) {
        transferredToName = transferredTo._id === userId ? `${t('you')}` : transferredTo.name;
      }
    }

    if (type === 'livechat-started') {
      const { data } = await getUserInfo(userId ?? '');
      if (serviceSelected?.servedBy) {
        const name = data.user._id === userId ? `${capitalizeFirstLetter(t('you'))}` : user.name;

        setInlineData({
          title: `${name} ${t('startedTheAttendance')} `,
          icon: (
            <i
              className="fa-regular fa-comment-dots"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      } else {
        setInlineData({
          title: `${t('contactReceivedByFlow')} `,
          icon: <i className="fa-regular fa-message-bot" />,
        });
      }
    } else if (type === 'livechat_transfer_history' && transferData !== undefined) {
      let departmentName;
      if (!transferData.previousDepartment) {
        const { transferredBy, scope } = transferData;
        logError(
          `No previous department data was found. Transferred by ${transferredBy?.username} (${transferredBy?.type}); scope is: ${scope}.`
        );
        departmentName = `[${t('unknown')}]`;
      } else {
        const { data } = await getDepartmentInfo(transferData.previousDepartment);
        departmentName = data.department.name ? data.department.name : `[${t('unknown')}]`;
      }

      if (transferData.scope === 'department') {
        const name = transferData.nextDepartment?.name || '';
        const { transferredBy } = transferData;

        const currentTransferByName =
          transferredBy?._id === userId ? `${capitalizeFirstLetter(t('you'))}` : transferredBy?.name;

        setInlineData({
          title: `${currentTransferByName} ${t('transferChatFrom')} ${departmentName} ${t('to')} ${name} ${
            transferData?.comment ? t('withAnotation') : ''
          }${transferData?.comment ? `"${transferData?.comment}"` : ''}`,
          icon: (
            <i
              className="fa-regular fa-share"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
        if (!disabledInputChat) {
          const filterOptions = menuOptions
            .filter((item) => item.type === 'joinChat')
            .map((item) => {
              const currentItem = item;
              currentItem.function = () => initChat();
              return item;
            });
          setOptions(filterOptions);
        }
      } else if (transferData.scope === 'agent') {
        setInlineData({
          title: `${transferredBYName} ${t('transferredChatTo')} ${transferredToName} ${
            transferData?.comment ? t('withAnotation') : ''
          }${transferData?.comment ? `"${transferData?.comment}"` : ''}`,
          icon: (
            <i
              className="fa-regular fa-share"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      } else {
        setInlineDataTransfer({
          title: `${t('returnChatToTheQueue')} ${departmentName}`,
          icon: (
            <i
              className="fa-regular fa-comment-exclamation"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });

        setInlineData({
          title: `${transferredBYName} ${t('returnChatToTheQueue')} ${departmentName}`,
          icon: (
            <i
              className="fa-regular fa-share"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
        if (!disabledInputChat) {
          const filterOptions = menuOptions
            .filter((item) => item.type === 'joinChat')
            .map((item) => {
              const currentItem = item;
              currentItem.function = () => initChat();
              return item;
            });
          setOptions(filterOptions);
        }
      }
    } else if (type === 'livechat-close') {
      const hasComment = comment?.split(' TICKET');

      if (user._id === userId) {
        setInlineData({
          title: `${capitalizeFirstLetter(t('you'))} ${t('finishedTheChat')}
        ${hasComment && hasComment[0] !== '' ? `${t('withAnotation')} "${comment}"` : ''}`,
          icon: (
            <i
              className="fa-regular fa-box-check"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      } else {
        setInlineData({
          title: `${user.name} ${t('finishedTheChat')}
        ${hasComment && hasComment[0] !== '' ? `${t('withAnotation')} "${comment}"` : ''}`,
          icon: (
            <i
              className="fa-regular fa-box-check"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      }
    } else if (type === 'omnichannel_placed_chat_on_hold') {
      const filterOptions = menuOptions
        .filter((item) => item.type === 'omnichannel_placed_chat_on_hold')
        .map((item) => {
          const currentItem = item;
          currentItem.function = () => {
            handleServiceSelected({});
            handleResumeOnHoldChat(serviceSelected._id);
          };
          return item;
        });
      if (user._id === userId) {
        setInlineData({
          title: `${capitalizeFirstLetter(t('you'))} ${t('putChatOnHoldMessage')}`,
          icon: (
            <i
              className="fa-regular fa-circle-pause"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      } else {
        setInlineData({
          title: `${user.name} ${t('putChatOnHoldMessage')}`,
          icon: (
            <i
              className="fa-regular fa-circle-pause"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      }

      setOptions(filterOptions);
    } else if (type === 'omnichannel_on_hold_chat_resumed') {
      if (user._id === userId) {
        setInlineData({
          title: `${capitalizeFirstLetter(t('you'))} ${t('userAssumedChat')}`,
          icon: (
            <i
              className="fa-regular fa-circle-play fa-xs"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      } else {
        setInlineData({
          title: `${user.name} ${t('userAssumedChat')}`,
          icon: (
            <i
              className="fa-regular fa-circle-play fa-xs"
              style={{
                color: theme.palette.neutral.var70,
              }}
            />
          ),
        });
      }
    }
  };

  useEffect(() => {
    getInfo();
    updateSize();
    window.addEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <Container ref={containerRef} type={typeListChat} id={INLINEMESSAGECONTAINERID}>
        {inlineData && (
          <Inline type={typeListChat} width={width}>
            <Content>
              {typeListChat === 'chat' && !isMobile && <IconView>{inlineData.icon}</IconView>}
              <Typography variant="caption1" color="neutral.var80" data-testid="title">
                {t(inlineData.title)}
              </Typography>
              <Typography variant="overline" color="neutral.var70" data-testid="time">
                {time.toLocaleTimeString([languageLocalStorage || window.navigator.language], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Typography>
            </Content>
            {lastItem || options.length ? (
              <DropDown
                isUserlogged={false}
                isbot={false}
                iserror={false}
                ismidia={false}
                isanotation={false}
                isdiscussion={false}
                colorIcon={theme.palette.neutral.var50 || ''}
                item={INLINEMESSAGECONTAINERID}
                listOptions={options}
                position="relative"
              />
            ) : null}
          </Inline>
        )}
      </Container>

      {inlineDataTransfer && (
        <Container ref={containerRef} type={typeListChat}>
          <Inline type={typeListChat} width={width}>
            <Content>
              {typeListChat === 'chat' && !isMobile && <IconView>{inlineDataTransfer.icon}</IconView>}
              <Typography variant="caption1" color="neutral.var80" data-testid="titleTransfer">
                {inlineDataTransfer.title}
              </Typography>
              <Typography variant="overline" color="neutral.var70">
                {time.toLocaleTimeString([languageLocalStorage || window.navigator.language], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Typography>
            </Content>
          </Inline>
        </Container>
      )}
    </>
  );
};

export default InlineNotification;
