import { styled } from '@mui/material';

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerFields = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

export const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px 0px;
  width: 100%;
`;

export const ConfirmationButtons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
`;
