import { styled, Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
  margin-top: 28px;
`;

export const CurrentInfo = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const UserInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  height: 100%;
`;

export const TextUserInfo = styled(Typography)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const FormItem = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
