import { createContext } from 'react';
import { Department, User } from 'services/types';
import { IOrder } from 'types/order';
import { FilterProps, IFilterChats } from '../../components/ChatsAdvancedFilters';

export interface IChatManagerContext {
  currentPage: number;
  tableRows: IChatTableItem[];
  totalItems: number;
  reportRows: IChatTableItem[];
  handleCurrentPage: (_: React.ChangeEvent<unknown>, value: number) => void;
  handleClickItem: (item?: IChatTableItem) => void;
  chatId: string;
  showDrawer: boolean;
  handleShowDrawer: () => void;
  setContactName: React.Dispatch<React.SetStateAction<string>>;
  setTicket: React.Dispatch<React.SetStateAction<string>>;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  ticket: string;
  contactName: string;
  language: string;
  showError: boolean;
  handleShowError: () => void;
  showAdvancedFilters: boolean;
  setShowAdvancedFilters: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseAdvancedFilter: () => void;
  handleClickAvancedFilter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  anchorEl: HTMLButtonElement | HTMLDivElement | null;
  handleFilterChats: (filters: FilterProps[]) => void;
  currentAvancedFilters: IFilterChats;
  agents: User[];
  departmentsState: Department[];
  removeFilter: (removeItem: string) => void;
  clearFilters: () => void;
  loading: boolean;
  loadChats: (
    filters?: IFilterChats,
    sortType?: SortChatsManagerHome,
    sortValue?: number,
    isReport?: boolean,
    resetPagination?: boolean
  ) => Promise<void>;
  orderChat: IOrder;
  orderByChat: SortChatsManagerHome;
  filterDateBy: string;
  setFilterDateBy: React.Dispatch<React.SetStateAction<string>>;
  lastUpdate: string;
}

export const DATE_FILTER_OPTIONS = ['startedAt', 'closedAt'];

export enum SortChatsManagerHome {
  contact = 'fname',
  team = 'departmentId',
  attendedBy = 'servedBy',
  lastMessage = 'lm',
  startedAt = 'ts',
  situation = 'open',
}

export interface IChatTableItem {
  _id: string;
  open: boolean;
  ticket: string;
  contact: string;
  phone?: string;
  team: React.ReactElement;
  attendedBy: string;
  lastMessage: string;
  startedAt?: string;
  closedAt?: string;
  situation?: string;
  teamName?: string;
  tags?: string;
}

const ChatManagerContext = createContext<IChatManagerContext>({} as IChatManagerContext);

export default ChatManagerContext;
