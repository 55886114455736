import { createContext } from 'react';

export enum Severity {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type Alert = {
  severity: Severity;
  message: string;
  occurrences: number;
  timeout: number | NodeJS.Timeout;
  id: string;
};

export interface IAddAlertArgs {
  message: string;
  severity: Severity;
  timeout?: number;
}

export interface IAlertsContext {
  alerts: Alert[];
  removeAlert: (id: string) => void;
  addAlert: (args: IAddAlertArgs) => void;
}

const AuthContext = createContext<IAlertsContext>({} as IAlertsContext);

export default AuthContext;
