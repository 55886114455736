import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { logError } from 'services/Logger';

const API_KEY = 'X4PxEYg0lp3WfWvcV2hxb3NS8dqbC4MD5RSHehcd';

const AxiosInstanceLooker = axios.create({
  headers: { 'Content-type': 'application/json' },
});

AxiosInstanceLooker.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers.set({ 'x-api-key': API_KEY });
    return config;
  },
  async (error: AxiosError) => Promise.reject(error)
);

AxiosInstanceLooker.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const { response = { ...error } } = error || {};
    logError('AxiosError', error);
    return Promise.reject(response);
  }
);

const axiosMockInstanceLooker = AxiosInstanceLooker;
export default axiosMockInstanceLooker;
