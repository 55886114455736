import { Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IMobile } from '../../../types/mobile';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  justify-content: space-between;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var20} `};
`;

export const Header = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 4.8rem;
  justify-content: 'flex-start';
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20} `};
  gap: 8px;
`;

export const StackTitle = styled(Typography)`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

export const Main = styled('div')<IMobile>`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.8rem);
  padding: ${({ isMobile }) => (!isMobile ? '40px 48px 48px' : '')};
  box-sizing: border-box;
  gap: ${({ isMobile }) => (isMobile ? '' : '32px')};
  position: relative;
`;

export const Pagination = styled('div')<IMobile>`
  left: 0;
  bottom: 0px;
  width: 100%;

  display: flex;
  padding: ${({ isMobile }) => (!isMobile ? '16px 48px' : '')};
  gap: 24px;
  height: 4.8rem;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20} `};
  position: absolute;
`;
