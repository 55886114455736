import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { MainText } from '../style';
import GenericModal from '../../../../components/Modal';
import { IProps } from './types';
import WebsocketContext from '../../../../contexts/websocket/context';
import { setUserStatus } from '../../../../services/setUserStatus';
import { IRoom } from '../../../../types/room';
import MenuChatsContext from '../../../../contexts/menuChats/context';
import ButtonsRow from '../../../../components/ButtonsStack';

export const ModalLogout: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { showModalLogout, setshowModalLogout } = props;
  const { logout, returnToQueue } = useContext(WebsocketContext);
  const [chatsToReturnToQueue, setChatsToReturn] = useState<Array<IRoom>>([]);
  const { accordeonsYourChats } = useContext(MenuChatsContext);
  const navigate = useNavigate();

  const handlerLogout = async () => {
    chatsToReturnToQueue.forEach(async (chat) => {
      if (chat.livechatData.userKey) {
        await returnToQueue(chat._id, chat.livechatData.userKey);
      }
    });

    await setUserStatus('offline');
    logout();
    datadogRum.clearUser();
    localStorage.clear();
    navigate('/login');
  };

  useEffect(() => {
    const chatsToReturn = accordeonsYourChats.filter((el: any) => el.id === 'inAttendance')[0];

    setChatsToReturn(chatsToReturn.rooms);
  }, [accordeonsYourChats]);

  return (
    <GenericModal open={showModalLogout} title={`${t('messageLogoutTitle')}`}>
      <MainText>{t('messageLogout')}</MainText>
      <ButtonsRow justifyContent="center" gap="2rem" marginTop="0">
        <Button
          color="neutral2"
          variant="outlined"
          onClick={() => {
            setshowModalLogout(false);
          }}
        >
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={handlerLogout} sx={{ minWidth: '12rem' }}>
          {t('exit')}
        </Button>
      </ButtonsRow>
    </GenericModal>
  );
};
