import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMediaQuery, Theme } from '@mui/material';
import AuthContext from '../../../contexts/auth/context';
import { fileToBase64 } from '../../../helpers/fileTobase64';
import useEnvironment from '../../../hooks/useEnvironments';
import { saveUserProfile } from '../../../services/saveUserProfile';
import { setAvatar } from '../../../services/setAvatar';
import { IEditProfileFormProps, IUseEditProfileProps } from './types';
import { schemaEditProfile } from './yupSchema';

const useEditProfile = ({ handleCloseModal }: IUseEditProfileProps) => {
  const { getUrl } = useEnvironment();
  const baseURL = getUrl('http');
  const { currentUserInfo, getUserInfo, updateAvatarURL } = useContext(AuthContext);
  const [showModalError, setShowModalError] = useState(false);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const defaultAvatarURL = `${baseURL}/avatar/${currentUserInfo.username}`;
  const [avatarURL, setAvatarURL] = useState<string>(defaultAvatarURL);
  const {
    control,
    formState: { errors, dirtyFields },
    getFieldState,
    handleSubmit,
    watch,
  } = useForm<IEditProfileFormProps>({
    mode: 'onSubmit',
    defaultValues: {
      name: currentUserInfo.name,
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schemaEditProfile),
  });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const onSubmit = async (form: IEditProfileFormProps) => {
    const { name, password = '' } = form;
    try {
      await saveAvatarImage();
      const { data } = await saveUserProfile(name, password, currentUserInfo.username, currentUserInfo.nickname);
      const response = JSON.parse(data.message);
      if (response.result) {
        await getUserInfo();
        handleCloseModal();
      }
      throw response;
    } catch (error) {
      setShowModalError(true);
      console.log(error);
    }
  };

  const saveAvatarImage = async () => {
    if (currentFile) {
      try {
        const formData = new FormData();
        formData.append('image', currentFile, currentFile.name);
        formData.append('userId', currentUserInfo._id);
        updateAvatarURL('');
        setAvatarURL('');
        await setAvatar(formData);
        updateAvatarURL(defaultAvatarURL);
      } catch (error) {
        console.log(error);
        setShowModalError(true);
      }
    }
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      try {
        const { files } = event.target;
        if (files && files.length > 0) {
          const file = files[0];
          const base64 = await fileToBase64(file);
          if (base64 && typeof base64 === 'string') {
            setAvatarURL(base64);
          }
          setCurrentFile(file);
        }
      } catch (error) {
        console.log(error);
        setShowModalError(true);
      }
    }
  };

  const checkIsChangeForm = () => {
    if (dirtyFields.name || dirtyFields.password || dirtyFields.confirmPassword) {
      setIsChangeForm(true);
      return;
    }
    setIsChangeForm(false);
  };

  useEffect(() => {
    checkIsChangeForm();
  }, [dirtyFields.name, dirtyFields.confirmPassword, dirtyFields.password]);

  return {
    uploadImage,
    onSubmit,
    watch,
    setShowModalError,
    handleSubmit,
    getFieldState,
    control,
    avatarURL,
    currentUserInfo,
    errors,
    currentFile,
    isChangeForm,
    showModalError,
    isMobile,
  };
};

export default useEditProfile;
