import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const deleteCustomFields = async (id: string): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      method: 'livechat:removeCustomField',
      id: uuidv4().toString(),
      params: [id],
    }),
  };
  return AxiosInstance.post('method.call/livechat:removeCustomField', params);
};
