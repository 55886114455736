import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { ChartsTotals } from './types';

export const getDashChartsTotals = async (
  startDate: string,
  endDate: string,
  departmentId?: string
): Promise<AxiosResponse<ChartsTotals>> => {
  const params = {
    departmentId,
    start: startDate,
    end: endDate,
  };

  return AxiosInstance.get('livechat/analytics/dashboards/charts/chats', {
    params,
  });
};

export const getDashChartsTotalsByMultipleDepartments = async (
  startDate: string,
  endDate: string,
  departmentIds: string[]
): Promise<ChartsTotals> => {
  const promises = departmentIds.map((departmentId) => getDashChartsTotals(startDate, endDate, departmentId));
  const results = await Promise.all(promises);

  const aggregate: ChartsTotals = {
    closed: 0,
    onhold: 0,
    open: 0,
    queued: 0,
    success: true,
  };

  results.forEach((result) => {
    const { data } = result;
    aggregate.closed += data.closed;
    aggregate.onhold += data.onhold;
    aggregate.open += data.open;
    aggregate.queued += data.queued;
  });

  return aggregate;
};
