import * as React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, OptionalLabel, FormCustom } from './style';

interface IFormSuggestedMessages {
  collectionId: string;
}

enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

const schemaSuggestedMessages: SchemaOf<IFormSuggestedMessages> = Yup.object({
  collectionId: Yup.string().required(),
}).required();

// TODO: replace for a real call to the API
const mockPost = (data: IFormSuggestedMessages) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 1000);
  });

const SuggestedMessages = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [status, setStatus] = React.useState<Status>(Status.IDLE);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<IFormSuggestedMessages>({
    mode: 'onChange',
    resolver: yupResolver(schemaSuggestedMessages),
  });

  const isLoading = status === Status.LOADING;

  const onSubmit: SubmitHandler<IFormSuggestedMessages> = async ({ collectionId }) => {
    setStatus(Status.LOADING);
    mockPost({ collectionId })
      .then(() => {
        setStatus(Status.RESOLVED);
        setValue('collectionId', '');
      })
      .catch(() => {
        setStatus(Status.REJECTED);
      });
  };

  return (
    <Container>
      <Typography variant="h2" color="common.black">
        {t('suggestedMessagesTitle')}
      </Typography>
      <FormCustom onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="caption1"
          color="neutral.var80"
          sx={{ marginTop: '0px', fontSize: '1.2rem', fontWeight: '500' }}
        >
          {t('collectionId')} <OptionalLabel>{`(${t('optional')})`}</OptionalLabel>
          <Tooltip title={t('tooltipCollectionId')}>
            <i
              className="fa-solid fa-circle-question"
              style={{
                color: theme.palette.neutral.var50,
                marginLeft: 3,
              }}
            />
          </Tooltip>
        </Typography>
        <Controller
          name="collectionId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              inputProps={{
                'data-testid': 'collectionId',
              }}
              error={!!errors.collectionId}
              helperText={errors.collectionId ? t('fieldIsRequired') : ''}
              fullWidth
            />
          )}
        />
        <Button disabled={!isValid || isLoading} type="submit" size="medium" variant="contained">
          {t('save')}
        </Button>
      </FormCustom>
    </Container>
  );
};

export default SuggestedMessages;
