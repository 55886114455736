import { styled } from '@mui/material';

export const Container = styled('div')`
  gap: 40px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Fields = styled('div')`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
`;
