import { styled } from '@mui/material/styles';
import { Typography, Popover, Stack, ListItem as ListItemMui } from '@mui/material';
import { shouldForwardPropHelper } from '@engyalo/design-system';

export const CustomPopover = styled(Popover)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    .MuiPaper-root {
      max-width: 100% !important;
      max-height: 100% !important;
      width: 100%;
      left: 0 !important;
      top: 0 !important;
      height: 100%;
    }
  }
`;

export const DiscussionWrapper = styled(Stack)`
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 420px;
    width: 388px;
  }
`;

export const DiscussionHeader = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DiscussionBody = styled(Stack, {
  shouldForwardProp: shouldForwardPropHelper,
})<{ hasMessages: boolean }>`
  ${({ theme, hasMessages }) => ({
    background: hasMessages ? theme.palette.neutral.var10 : theme.palette.common.white,
    overflowY: 'scroll',
    height: '100%',
    justifyContent: 'center',
  })}
`;

export const SelectAgentContainer = styled(Stack)`
  background: ${({ theme }) => theme.palette.common.white};
  border-top: ${({ theme }) => `solid 1px ${theme.palette.neutral.var20}`};
  width: auto;
  max-height: 150px;
`;

export const ListItem = styled(ListItemMui)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  :focus,
  :hover {
    background: ${({ theme }) => theme.palette.neutral.var10};
    border-radius: 4px;
    outline: none;
  }
`;

export const AgentName = styled(Typography)`
  color: ${({ theme }) => theme.palette.neutral.var60};
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
`;
