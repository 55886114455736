import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from 'services/AxiosInstance';
import AxiosInstanceBFF from 'services/AxiosInstanceBFF';
import AxiosInstanceYalo from 'services/AxiosInstanceYalo';
import { logError, logWarn } from 'services/Logger';
import { STORAGE_KEYS } from 'constants/defaultValues';
import AlertsContext, { Alert, IAddAlertArgs, Severity } from './context';
import { isBFFError, isRocketChatApiError, isRocketChatMethodError } from './guards';

const DISPLAY_TIME_MILLIS = 5000;

export type RocketChatApiError = {
  error: number | string | Record<string, unknown>;
};

export type RocketChatMethodError = {
  message: string;
};

export type RocketChatError = RocketChatApiError & RocketChatMethodError;

export type BFFError = string | Record<string, string | number | Record<string, unknown>>;

export type BFFErrorResponse = {
  data: BFFError;
};

export type YaloError = {
  data: string;
};

const logFnMapping: { [key in Severity]: Function } = {
  [Severity.WARNING]: logWarn,
  [Severity.ERROR]: logError,
  [Severity.SUCCESS]: () => {},
};

enum AXIOS_TYPE {
  API = 'api',
  BFF = 'bff',
  YALO = 'yalo',
}

enum BFFErrorKeys {
  CreateErrorDepartmentOrNameExists = 'CreateErrorDepartmentOrNameExists',
  CreateErrorInvalidField = 'CreateErrorInvalidField',
  UpdateErrorInvalidFields = 'UpdateErrorInvalidFields',
  UpdateErrorDepartmentExists = 'UpdateErrorDepartmentExists',
  DeleteErrorNotFound = 'DeleteErrorNotFound',
  GetErrorNotFound = 'GetErrorNotFound',
}

const AlertProvider: React.FC = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  function removeAlert(id: string) {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  }

  const addAlert = useCallback(({ message, severity, timeout = DISPLAY_TIME_MILLIS }: IAddAlertArgs) => {
    const scheduleRemoval = (id: string) => setTimeout(removeAlert, timeout, id);
    setAlerts((previousAlerts) => {
      const matchingMessageAlert = previousAlerts.find((alert) => alert.message === message);

      // Group alerts with matching message and update their scheduled removal
      if (matchingMessageAlert) {
        return previousAlerts.map((alert) => {
          if (alert.message !== message) {
            return alert;
          }

          clearTimeout(alert.timeout);
          return {
            ...alert,
            occurrences: alert.occurrences + 1,
            timeout: scheduleRemoval(matchingMessageAlert.id),
          };
        });
      }

      // Add new alert and defer its own removal
      const id = uuidv4();
      return [
        ...previousAlerts,
        {
          id,
          message,
          severity,
          occurrences: 1,
          timeout: scheduleRemoval(id),
        },
      ];
    });
  }, []);

  const getErrorMessageFromBff = (error: BFFError) => {
    if (typeof error === 'string') {
      return error;
    }
    return error?.message || error?.Message;
  };

  const handleResponse = useCallback(
    (
      response: AxiosResponse<RocketChatError | BFFErrorResponse | YaloError>,
      severityLevel: Severity,
      handler: (error: string, severityLevel: Severity) => void,
      type: `${AXIOS_TYPE}`
    ) => {
      let responseError = {};
      const isYaloError = type === AXIOS_TYPE.YALO;
      const isBffError = type === AXIOS_TYPE.BFF && isBFFError(response?.data);
      const isApiError = type === AXIOS_TYPE.API;

      if (isApiError && isRocketChatApiError(response.data)) {
        responseError = response.data.error;
      }

      if (isBffError) {
        responseError = getErrorMessageFromBff(response.data);
      }

      if (isYaloError) {
        responseError = response.data;
      }

      if (['string', 'number'].includes(typeof responseError)) {
        handler(responseError.toString(), severityLevel);
        return;
      }

      let methodCallMessage;
      try {
        if (isApiError && isRocketChatMethodError(response.data)) {
          methodCallMessage = response.data?.message && JSON.parse(response.data.message);
        }
      } catch {
        return;
      }

      const methodCallError = methodCallMessage?.error;
      if (methodCallError?.error) {
        handler(methodCallError.error, severityLevel);
      }
    },
    []
  );

  useEffect(() => {
    function handleApiError(error: string, severity: Severity) {
      const err = new Error(error);
      const log = logFnMapping[severity];
      const found = error.match(/\[(?<rcErrorKey>.*)\]$/);
      const errorKey = `rocketChat.${found?.groups?.rcErrorKey || error}`;
      const message = t(errorKey).toString();
      const hasContent = message !== errorKey;

      log(error, err, {
        wasUserNotified: hasContent,
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID),
      });

      if (hasContent) {
        addAlert({ message, severity });
      }
    }

    function handleBffError(error: string, severity: Severity) {
      const err = new Error(error);
      const errorMessageKey = Object.keys(BFFErrorKeys).find((key) => error.includes(key));
      let keyError = 'unexpectedError';
      if (errorMessageKey) {
        keyError = `business-hours.error.${errorMessageKey}`;
      }
      const log = logFnMapping[severity];
      const message = t(keyError).toString();
      const hasContent = message !== keyError;
      log(error, err, {
        wasUserNotified: hasContent,
        userId: localStorage.getItem(STORAGE_KEYS.USER_ID),
      });

      if (hasContent) {
        addAlert({ message, severity });
      }
    }

    const apiErrorsInterceptor = AxiosInstance.interceptors.response.use(
      (response) => {
        handleResponse(response, Severity.WARNING, handleApiError, AXIOS_TYPE.API);
        return response;
      },
      (error) => {
        if (error.response) {
          handleResponse(error.response, Severity.ERROR, handleApiError, AXIOS_TYPE.API);
          throw error.response;
        } else {
          logError('An unexpected error occurred', error);
          throw error;
        }
      }
    );

    const bffApiErrorInterceptor = AxiosInstanceBFF.interceptors.response.use(
      (response) => response,
      (error) => {
        handleResponse(error.response, Severity.ERROR, handleBffError, AXIOS_TYPE.BFF);
        throw error.response;
      }
    );

    const yaloErrorInterceptor = AxiosInstanceYalo.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.code === AxiosError.ERR_CANCELED) {
          throw error;
        }
        handleResponse(error.response, Severity.ERROR, handleApiError, AXIOS_TYPE.YALO);
        throw error.response;
      }
    );

    return () => {
      AxiosInstance.interceptors.response.eject(apiErrorsInterceptor);
      AxiosInstanceBFF.interceptors.response.eject(bffApiErrorInterceptor);
      AxiosInstanceYalo.interceptors.response.eject(yaloErrorInterceptor);
    };
  }, [addAlert, handleResponse, t]);

  const memoizedValues = useMemo(() => ({ alerts, removeAlert, addAlert }), [addAlert, alerts]);

  return <AlertsContext.Provider value={memoizedValues}>{children}</AlertsContext.Provider>;
};

export default AlertProvider;
