import { Avatar } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import { formatMessageTime } from 'helpers/formatterDates';
import { InfoArea } from './styled';

type ContactInfoAreaProps = {
  name: string;
  timestamp: string;
  isBot?: boolean;
};

const ContactInfoArea: React.FC<ContactInfoAreaProps> = ({ name, timestamp, isBot }) => {
  const { i18n, t } = useTranslation();
  const displayName = isBot ? t('flow') : name;

  return (
    <InfoArea>
      <Avatar variant="letters" displayName={displayName} letter={displayName} />
      <span>{formatMessageTime(Number(timestamp), i18n.language)}</span>
    </InfoArea>
  );
};

export default ContactInfoArea;
