import React, { useContext } from 'react';
import { Typography, useMediaQuery, Theme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@engyalo/design-system';
import { managementRoles } from 'constants/roles';
import TagList from 'components/Tags/TagList';
import MenuTags from '../../../../components/Tags/MenuTags';
import { StackHeader, TagsContainer, InfoTagContainer, InfoArea } from './style';
import useSubHeader from './useSubheader';
import usePermissions from '../../../../hooks/usePermissions';
import useSegment from '../../../../services/Analytics';
import { checkDisableTags } from '../../../../helpers/checkDisableButton';
import AuthContext from '../../../../contexts/auth/context';

const SubHeader: React.FC = () => {
  const { handleOptionClick, anchorElTag, handleClose, serviceSelected, tagsSelected } = useSubHeader();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { checkPermission } = usePermissions();
  const { sendEvent, EVENT_NAMES } = useSegment();
  const { currentUser, currentUserInfo } = useContext(AuthContext);

  const theme = useTheme();
  const isAgentResponsible =
    // eslint-disable-next-line no-underscore-dangle
    serviceSelected?.servedBy?._id === currentUser.userId;

  return (
    <StackHeader data-testid="subHeader">
      <TagsContainer
        onClick={(event) => {
          if (isMobile) {
            handleOptionClick(event);
          }
        }}
      >
        {!isMobile && (
          <Tooltip title={`${t('changeTagChat')}`}>
            <IconButton
              data-testid="btnOpenMenuTags"
              onClick={handleOptionClick}
              disabled={checkDisableTags(serviceSelected, currentUserInfo)}
              variant="outlined"
              color="neutral2"
            >
              <i className="fa-regular fa-tags fa-sm" />
            </IconButton>
          </Tooltip>
        )}

        <TagsContainer>
          {tagsSelected.length > 0 ? (
            <TagList tags={tagsSelected} />
          ) : (
            <Typography variant="body1" color="neutral.var60" lineHeight={1.2}>
              {t('addTagToChat')}
            </Typography>
          )}
        </TagsContainer>
      </TagsContainer>

      <InfoArea>
        {isAgentResponsible && (
          <InfoTagContainer color={theme.palette.primary.main}>{t('chat.subHeader.assignedToYouTag')}</InfoTagContainer>
        )}

        {serviceSelected?.livechatData?.ticket && (
          <Tooltip placement="top" title={t('copyTicket') || ''} leaveDelay={500}>
            <InfoTagContainer
              clickable
              data-testid="handleTooltipTicket"
              aria-label={`${t('ticket')} ${serviceSelected.livechatData.ticket}`}
              onClick={() => {
                navigator.clipboard.writeText(serviceSelected.livechatData.ticket);
                sendEvent(EVENT_NAMES.USER_COPY_A_TICKET_ID);
              }}
            >
              <Typography variant="overline" color="neutral.var80">
                {t('ticket')} {serviceSelected.livechatData.ticket}
              </Typography>
              <i className="fa-light fa-copy" />
            </InfoTagContainer>
          </Tooltip>
        )}
      </InfoArea>

      {anchorElTag && (
        <MenuTags
          hasPermission={checkPermission(managementRoles)}
          anchorEl={anchorElTag}
          handleClose={handleClose}
          chatId={serviceSelected?._id}
          clientToken={serviceSelected?.v?.token}
          contactId={serviceSelected?.v?._id}
          showChatTags={false}
        />
      )}
    </StackHeader>
  );
};

export default SubHeader;
