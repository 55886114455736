import { styled } from '@mui/material/styles';
import { Popover } from '@mui/material';

export const CustomPopover = styled(Popover)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    .MuiPaper-root {
      max-width: 100% !important;
      width: 100%;
      left: 0 !important;
      top: 82px !important;
    }
  }
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const OptionItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-inline: 16px;
`;

export const IconEditTag = styled('div')`
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.var10};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
`;

export const NotFoundContainer = styled('div')`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.var10};
`;

export const ButtonContainer = styled('div')`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var10}`};
`;

export const CheckboxIcon = styled('span')`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  border: ${({ theme }) => `1.3px solid ${theme.palette.neutral.var50}`};
  background-color: ${({ theme }) => theme.palette.neutral.var10};
`;
