import { TFunction } from 'i18next';
import { daysOfWeek } from '../constants';
import { IGenericValueLabelItem } from './types';

// TODO: this should be change with the real list
const departments: IGenericValueLabelItem[] = [
  {
    value: 'Deparment 1',
    label: 'Deparment 1',
  },
  {
    value: 'Deparment 2',
    label: 'Deparment 2',
  },
  {
    value: 'Deparment 3',
    label: 'Deparment 3',
  },
];

const getAvailableDays = (t: TFunction) =>
  daysOfWeek.map((day) => ({
    value: day,
    label: t(`business-hours.days.${day}`),
  }));

export { departments, getAvailableDays };
