import { ListItem, styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 610px;
`;

export const FilterRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
`;

export const ListItemSelectFilter = styled(ListItem)`
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  cursor: pointer;
  border: ${({ theme }) => `0px solid ${theme.palette.neutral.var20}`};
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  background: ${({ theme }) => theme.palette.common.white};
  :hover {
    color: ${({ theme }) => theme.palette.neutral.var90};
    background: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :active {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  text-transform: capitalize;
`;
