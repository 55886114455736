import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { IPropsSaveDepartment } from '../types/department';

export const saveDepartment = async (props: IPropsSaveDepartment): Promise<AxiosResponse> => {
  const {
    _id,
    chatClosingTags,
    email,
    enabled,
    maxNumberSimultaneousChat,
    name,
    visitorInactivityTimeoutInSeconds,
    abandonedRoomsCloseCustomMessage,
    requestId,
  } = props;
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: requestId,
      method: 'livechat:saveDepartment',
      params: [
        _id,
        {
          enabled,
          name,
          description: '',
          showOnRegistration: false,
          showOnOfflineForm: false,
          requestTagBeforeClosingChat: chatClosingTags.length > 0,
          email,
          chatClosingTags,
          offlineMessageChannelName: '',
          waitingQueueMessage: '',
          departmentsAllowedToForward: [],
          maxNumberSimultaneousChat,
          visitorInactivityTimeoutInSeconds,
          abandonedRoomsCloseCustomMessage,
        },
        [],
      ],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveDepartment', params);
};
