import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useWebsocket from 'hooks/useWebsocket';
import { logError } from 'services/Logger';
import ChatsContext from '../../contexts/chats/context';
import ContextDrawer from '../../components/ContextDrawer';
import { ModalCloseConversation } from '../../components/ModalCloseConversation';
import { ModalErrorCloseConversation } from '../../components/ModalErrorCloseConversation';
import { TimeOutModal } from '../../components/TimeOutModal';
import WebsocketContext from '../../contexts/websocket/context';
import CannedMessagesProvider from '../../contexts/cannedMessages/provider';
import QueueMenu from './QueueMenu';
import DrawerContext from '../../contexts/drawer/context';
import EmptyChatPage from './EmptyChatPage';
import SnackbarCustom, { SnackbarType } from '../../components/Snackbar';
import ContactManager from './ContactManager';
import ChatsManager from './ChatsManager';
import ServiceDashboard from './ServiceDashboard';
import MenuChatsContext from '../../contexts/menuChats/context';
import { getRoomInfo } from '../../services/getRoomInfo';
import useSegment from '../../services/Analytics';
import Conversation from './Conversation';
import ConversationContext from '../../contexts/conversation/context';
import { WEBSOCKET_EVENT_LOG_MESSAGE } from '../../constants/defaultValues';
import { ChatWrapper, DrawerWrapper, HomeContainer, MainContainer, QueueMenuWrapper } from './style';
import Chat from './Chat';

type AlertConditions = {
  isBrowserConnected: boolean;
  isWsConnected: boolean;
};

type AlertProps = {
  translationKeys: {
    message: string;
    title: string;
  };
  type: SnackbarType;
};

const getAlertProps = ({ isBrowserConnected, isWsConnected }: AlertConditions): AlertProps | undefined => {
  const conditions: Array<[boolean, AlertProps]> = [
    [
      !isBrowserConnected,
      {
        translationKeys: {
          title: 'browser.offline.title',
          message: 'browser.offline.message',
        },
        type: 'warning',
      },
    ],
    [
      !isWsConnected,
      {
        translationKeys: {
          title: 'websocket.reconnection.title',
          message: 'websocket.reconnection.message',
        },
        type: 'warning',
      },
    ],
  ];

  const [, options] = conditions.find(([check]) => check) || [];

  return options;
};

export const Home: React.FC = () => {
  const { chatId } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((mTheme: Theme) => mTheme.breakpoints.down('md'));
  const { serviceSelected, openModalCloseChat, errorCloseChat, handleServiceSelected, setErrorCloseChat, setIsMobile } =
    useContext(ChatsContext);

  const { handleTabSelection: updateDrawerActiveTab, selectedTab: selectedDrawerTab } = useContext(DrawerContext);
  const { openContactManager, openChatsManager, openServiceDashboard } = useContext(MenuChatsContext);
  const { openStartNewConversation, handleStartNewConversation } = useContext(ConversationContext);
  const {
    browserConnection: isBrowserConnected,
    showSnackbar,
    snackBarMessage,
    handleSnackBar,
    snackBarType,
    websocketIsConnect,
    websocketHasConnected,
  } = useContext(WebsocketContext);

  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const { sendPage, sendConversationSelectedEvent } = useSegment();
  const { webSocket, isConnected: isWsConnected } = useWebsocket();
  const alertOptions = getAlertProps({ isWsConnected, isBrowserConnected });
  const serviceId = serviceSelected?._id; // eslint-disable-line no-underscore-dangle

  const loadRoomInfo = async () => {
    if (chatId) {
      try {
        const {
          data: { room = {} },
        } = await getRoomInfo(chatId);

        handleServiceSelected(room);
        sendConversationSelectedEvent(room);
      } catch (error) {
        logError('Error while fetching Room Info', error);
      }
    } else {
      handleServiceSelected(null);
    }
    sendPage();
  };

  const handleSnackbar = () => {
    setOpenSnackbarSuccess(false);
    handleSnackBar();
  };

  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile, setIsMobile]);

  useEffect(() => {
    window.sessionStorage.removeItem('drawerOpen');

    if (!serviceId) {
      updateDrawerActiveTab(null);
    }

    if (openStartNewConversation && serviceId) {
      handleStartNewConversation(false);
    }
  }, [serviceId]);

  useEffect(() => {
    if (websocketIsConnect && websocketHasConnected && chatId) {
      loadRoomInfo();
    }
  }, [chatId, websocketIsConnect, websocketHasConnected]);

  useEffect(() => {
    if (showSnackbar) {
      setOpenSnackbarSuccess(true);
    }
  }, [showSnackbar]);

  const connectionAlertDelay = Number.parseInt(process.env.CONNECTION_ALERT_DELAY || '5000', 10);

  let initiallySelectedTab;

  const shouldShowQueueMenu =
    !selectedDrawerTab && !serviceId && !openContactManager && !openChatsManager && !openStartNewConversation;

  const shouldShowChat =
    (!selectedDrawerTab && serviceId) || openContactManager || openChatsManager || openStartNewConversation;

  return (
    <HomeContainer data-testid="home">
      {alertOptions && (
        <SnackbarCustom
          data-testid="salesdesk-ws-alert"
          logName={WEBSOCKET_EVENT_LOG_MESSAGE.RECONNECTION_BFF}
          open={Boolean(alertOptions)}
          title={t(alertOptions.translationKeys.title)}
          message={t(alertOptions.translationKeys.message)}
          type={alertOptions.type}
          delayMillis={connectionAlertDelay}
        />
      )}
      <MainContainer>
        <QueueMenuWrapper className={shouldShowQueueMenu ? 'show' : ''}>
          <QueueMenu />
        </QueueMenuWrapper>
        <ChatWrapper className={shouldShowChat ? 'show' : ''}>
          {openContactManager ? (
            <ContactManager />
          ) : openChatsManager ? (
            <ChatsManager initialTab={initiallySelectedTab} />
          ) : openStartNewConversation ? (
            <Conversation />
          ) : openServiceDashboard ? (
            <ServiceDashboard />
          ) : (
            <CannedMessagesProvider>
              {serviceSelected?._id ? (
                <Chat chatId={serviceSelected._id} webSocket={webSocket} isWsConnected={isWsConnected} />
              ) : (
                <EmptyChatPage />
              )}
              {openSnackbarSuccess && (
                <SnackbarCustom
                  hasTextButton
                  open={openSnackbarSuccess}
                  message={t(snackBarMessage) || ''}
                  handleClose={handleSnackbar}
                  type={snackBarType || 'success'}
                />
              )}
            </CannedMessagesProvider>
          )}
        </ChatWrapper>

        {selectedDrawerTab && (
          <DrawerWrapper>
            <ContextDrawer open={!!selectedDrawerTab} tabSelect={selectedDrawerTab} />
          </DrawerWrapper>
        )}

        {openModalCloseChat && <ModalCloseConversation open={openModalCloseChat} />}

        {errorCloseChat && (
          <ModalErrorCloseConversation openError={errorCloseChat} handleErrorCloseChat={setErrorCloseChat} />
        )}

        <TimeOutModal />
      </MainContainer>
    </HomeContainer>
  );
};
