import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardPropHelper } from '@engyalo/design-system';
import { IMobile } from '../../../types/mobile';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  justify-content: space-between;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var20}`};
`;

export const Main = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.8rem);
  padding: ${({ isMobile }) => (isMobile ? '' : '40px 48px 48px')};
  box-sizing: border-box;
  gap: ${({ isMobile }) => (isMobile ? '' : '32px')};
  position: relative;
`;

export const Header = styled(Stack, {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 4.8rem;
  justify-content: 'flex-start';
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  gap: ${({ isMobile }) => (isMobile ? '0.8rem' : '')};
  padding: 0.8rem 0;
`;

export const SearchContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableContainer = styled('div')`
  display: flex;
  overflow-y: scroll;
  margin-bottom: 48px;
`;

export const PaginationContainer = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})<IMobile>`
  left: 0;
  bottom: 0px;
  width: 100%;

  display: flex;
  padding: ${({ isMobile }) => (isMobile ? '' : '16px 48px')};
  gap: 24px;
  height: 4.8rem;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.palette.neutral.var20}`};
  position: absolute;
`;

export const AutoCompleteStyle = {
  borderRadius: '4px',
  width: '100%',
  ':hover': {
    border: '1px solid neutral.var40',
  },
  '.MuiAutocomplete-endAdornment': {
    top: 'auto',
    fontSize: '1.7rem',
    right: '1.2rem !important',
  },
  '.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      content: 'none',
    },
  },
  '.MuiTouchRipple-root': {
    display: 'none',
  },
};

export const AutoCompleteInputStyle = {
  '.MuiInputBase-root': {
    height: 'auto',
    minHeight: '1.4375em',
    maxHeight: '4rem',
    padding: '11px 4px 11px 11px',
    '.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused': {
      padding: '0',
    },
  },
};
