import { IconButton, Tabs, Tab } from '@engyalo/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IHeaderProps } from '../types';
import { Container, Title, ViewTypeContainer } from './style';

const HeaderUserManager: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { type, currentTabValue, onCloseModal, setCurrentTabValue, isManager } = props;
  const { t } = useTranslation();

  return (
    <Container sx={{ padding: type === 'edit' ? '0px 8px' : '', gap: '8px' }}>
      {type === 'view' ? (
        <ViewTypeContainer>
          <Title>{t('user')}</Title>
          <IconButton onClick={onCloseModal} size="small" color="neutral2" variant="text">
            <i className="fa-regular fa-xmark fa-2xs" />
          </IconButton>
        </ViewTypeContainer>
      ) : (
        <>
          {type === 'edit' && (
            <IconButton onClick={onCloseModal} color="neutral2" variant="text">
              <i className="fa-solid fa-arrow-left fa-2xs" />
            </IconButton>
          )}

          <Tabs
            value={currentTabValue}
            aria-label="tags tabs"
            sx={{
              width: '100%',
            }}
          >
            <Tab
              label={t('userInformations')}
              id="tags-tab-0"
              aria-controls="tags-tabpanel-0"
              key={0}
              onClick={() => {
                setCurrentTabValue(0);
              }}
            />
            <Tab
              disabled={(type === 'new' || type === 'edit') && currentTabValue === 0 && isManager}
              label={t('teams')}
              id="tags-tab-1"
              aria-controls="tags-tabpanel-1"
              key={1}
              onClick={() => {
                !isManager && setCurrentTabValue(1);
              }}
            />
          </Tabs>
          {type === 'new' && (
            <IconButton
              size="small"
              color="neutral2"
              onClick={() => {
                onCloseModal();
              }}
            >
              <i className="fa-regular fa-xmark fa-2xs" />
            </IconButton>
          )}
        </>
      )}
    </Container>
  );
};

export default HeaderUserManager;
