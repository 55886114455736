import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { IconButton } from '@engyalo/design-system';

export const MainText = styled(Typography)`
  width: 275px;
  height: 60px;
  left: 0px;
  margin-top: 32px;

  display: flex;
  align-items: center;
  text-align: center;
`;

export const MicButton = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: '0px 18px';
  }
`;

export const QuickMessageTagIcon = styled('i')`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  margin-right: 10px;
`;
