import forOwn from 'lodash.forown';

const percentEncodingMapping = {
  '%3A': ':',
  '%24': '$',
  '%2C': ',',
  '%20': '+',
  '%5B': '[',
  '%5D': ']',
};

function encode(val: string) {
  return Object.entries(percentEncodingMapping).reduce(
    (result, [percentEncoding, char]) => result.replace(new RegExp(percentEncoding, 'gi'), char),
    encodeURIComponent(val)
  );
}

// Mimics axios v0 serializing logic, changed in v1 by https://github.com/axios/axios/pull/4734
export default function paramsSerializer(params?: unknown[] | Object) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }

  const parts: string[] = [];

  forOwn(params, (param, key) => {
    if (param === null || typeof param === 'undefined') {
      return;
    }

    const isArray = Array.isArray(param);
    const prop = isArray ? `${key}[]` : key;
    const values = isArray ? param : [param];

    forOwn(values, (value) => {
      let serlializedValue;
      if (value instanceof Date) {
        serlializedValue = value.toISOString();
      } else if (value instanceof Object) {
        serlializedValue = JSON.stringify(value);
      }

      parts.push(`${encode(prop)}=${encode(serlializedValue || String(value))}`);
    });
  });

  return parts.join('&');
}
