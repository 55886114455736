import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { shouldForwardPropHelper } from '@engyalo/design-system';

export const Container = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SessionInfo = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 16px;
`;

interface IExpanded {
  isExpanded: boolean;
  hasChats: boolean;
}

export const CustomIcon = styled('div')`
  margin-top: 5px;
  margin-right: 8px;
`;

export const SessionName = styled(Typography, {
  shouldForwardProp: shouldForwardPropHelper,
})<IExpanded>`
  display: flex;
  text-align: left;
  align-items: center;
  font-style: normal;
  color: ${({ hasChats, isExpanded, theme }) =>
    hasChats || isExpanded ? theme.palette.neutral.var90 : theme.palette.neutral.var50};
  letter-spacing: 0;
`;

export const SessionConversationCounter = styled(Typography)`
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: 3px;
`;

export const Button = styled('button')`
  height: 36px;
  width: 229px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  padding: 10px 20px 10px 20px;
  background: ${({ theme }) => theme.palette.tertiary.dark};
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  &:hover {
    background: ${({ theme }) => theme.palette.tertiary.dark};
    color: ${({ theme }) => theme.palette.tertiary.main};
  }
`;

export const IconButton = styled('button')`
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 8px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  &:hover {
    background: ${({ theme }) => theme.palette.neutral.var20};
  }
`;

export const ButtonsStack = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 10px 0px;
`;
