import Message, { MessageType } from 'components/Messages';
import { useTranslation } from 'react-i18next';
import { convertSeparatorToDate, formatDate } from 'helpers/formatterDates';
import { Typography } from '@mui/material';
import { MessageGroup, DateSeparator } from './styled';

export type MessageList = { [date: string]: Array<MessageType> };

type MessageClick = (message: MessageType) => void;

type MessagesListProps = { messages: MessageList; onMessageClick?: MessageClick };

const MessagesList: React.FC<MessagesListProps> = ({ messages, onMessageClick }) => {
  const { i18n } = useTranslation();

  const messageEntries = Object.entries(messages);

  if (!messageEntries.length) {
    return null;
  }

  return (
    <div>
      {messageEntries.map(([dateStamp, messagesArray]) => {
        const formattedDate = formatDate(convertSeparatorToDate(dateStamp), i18n.language);

        return (
          <MessageGroup className="messageGroup" key={dateStamp}>
            <DateSeparator>
              <Typography variant="h4">{formattedDate}</Typography>
            </DateSeparator>
            <div>
              {messagesArray.map((message) => (
                <Message key={message.id} message={message} onClick={onMessageClick} />
              ))}
            </div>
          </MessageGroup>
        );
      })}
    </div>
  );
};

export default MessagesList;
