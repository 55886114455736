import React, { FC } from 'react';
import { t } from 'i18next';
import { Stack, Divider, Typography } from '@mui/material';
import { ContactSelected, TemplateSelected } from '../../../contexts/conversation/provider';

type Header = {
  contactSelected?: ContactSelected;
  templateSelected?: TemplateSelected;
  showHistoryData: boolean;
  isMobile: boolean;
};

const checkHeaderText = (showHistory: boolean, contactName?: string, templateName?: string) => {
  if (contactName && templateName && !showHistory) {
    return `${t('sendTemplate')} "${templateName}" ${t('to')} ${contactName}`;
  }
  if (templateName && !showHistory) {
    return `${t('sendTemplate')} "${templateName}"`;
  }
  if (showHistory) {
    return `${t('activeMessageHistory')}`;
  }
  return `${t('startNewConversation')} ${contactName ? `${t('with')} ${contactName}` : ''}`;
};

export const HeaderConversation: FC<Header> = (props) => {
  const { contactSelected, templateSelected, showHistoryData, isMobile } = props;
  return (
    <Stack direction="row" justifyContent="space-between" width="100%" gap="0.8rem">
      <Stack sx={{ justifyContent: 'center' }}>
        <Typography
          variant="subtitle2"
          color="neutral.var80"
          lineHeight="1.5rem"
          sx={{ cursor: 'pointer' }}
          data-testid="headerSendTemplate"
        >
          {checkHeaderText(showHistoryData, contactSelected?.name, templateSelected?.template)}
        </Typography>
      </Stack>
      {contactSelected?.name && templateSelected?.template && !isMobile && <Divider orientation="vertical" flexItem />}
      {!isMobile && (
        <Stack sx={{ paddingLeft: '16px', justifyContent: 'center' }}>
          <Typography variant="subtitle2" color="neutral.var70">
            {contactSelected?.name && templateSelected?.template && !isMobile && <>{t('messagePreview')}</>}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
