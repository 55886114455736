import { useContext } from 'react';
import { MENU } from 'views/home/Chat/Header/Header';
import DrawerContext, { TABS } from 'contexts/drawer/context';

type ActionHandlers = {
  transfer: () => void;
  internalDiscussion: () => void;
  onHold: () => void;
  search: () => void;
  history: () => void;
};

const useActionHandlers = (handleMenuClick: (menu: MENU) => void, handleClose: () => void): ActionHandlers => {
  const { handleTabSelection } = useContext(DrawerContext);

  const transfer = () => {
    handleMenuClick(MENU.TRANSFER);
  };

  const internalDiscussion = () => {
    handleMenuClick(MENU.DISCUSSION);
  };

  const onHold = () => {
    handleMenuClick(MENU.PUT_ON_HOLD);
  };

  const search = () => {
    handleTabSelection(TABS.SEARCH_MESSAGES);
    handleClose();
  };

  const history = () => {
    handleTabSelection(TABS.CHATS_HISTORY);
    handleClose();
  };

  return {
    transfer,
    internalDiscussion,
    onHold,
    search,
    history,
  };
};

export default useActionHandlers;
