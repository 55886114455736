import { Checkbox, TextField } from '@engyalo/design-system';
import { MenuItem } from '@mui/material';
import { IMultiSelectProps, isStringArray } from './types';

const MultiSelector = ({
  items,
  onChange,
  selectedValue,
  itemLabel,
  placeholder,
  id,
  error,
  helperText,
}: IMultiSelectProps) => (
  <TextField
    SelectProps={{
      multiple: true,
      renderValue: (selected) => {
        if (!isStringArray(selected) || !selected.length) {
          return <span className="placeholder">{placeholder}</span>;
        }

        const desireValue = selected.map((item) => items.find(({ value }) => value === item)?.label);
        return desireValue.join(', ');
      },
    }}
    placeholder={placeholder}
    select
    value={selectedValue}
    onChange={onChange}
    id={id}
    label={itemLabel}
    data-testid={id}
    error={error}
    helperText={helperText}
  >
    {items.map(({ value, label }) => {
      const isChecked = !!selectedValue.find((item) => item === value);
      return (
        <MenuItem key={value} value={value}>
          <Checkbox checked={isChecked} />
          {label}
        </MenuItem>
      );
    })}
  </TextField>
);

export default MultiSelector;
