import { Avatar, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { ChatName, ClientNameEllipsis, ContainerChatName, Item, ItemTitleAllChats } from './style';
import { IChatItemFilterProps } from './types';
import { stringAvatar } from '../../helpers/stringAvatar';

const ChatItemFilter: React.FC<IChatItemFilterProps> = ({
  id,
  name,
  closedAt,
  handleOpenChat,
  tabType,
  servedBy,
  department,
  closedBy,
}: IChatItemFilterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getChatName = () => {
    if (tabType === 'yourChats') {
      return name;
    }

    let username = '';
    if (!servedBy && !closedBy) {
      username = department.name;
    } else {
      username = servedBy ? servedBy.username : closedBy ? closedBy.username : '';
    }

    return (
      <ItemTitleAllChats>
        <ClientNameEllipsis variant="subtitle1">{name}</ClientNameEllipsis>
        <i className="fa-duotone fa-chevron-right" />
        <ClientNameEllipsis variant="subtitle1">{username}</ClientNameEllipsis>
      </ItemTitleAllChats>
    );
  };

  return (
    <Item onClick={handleOpenChat}>
      <ListItemAvatar>
        <Avatar {...stringAvatar(name)} src="" />
      </ListItemAvatar>
      <ListItemText
        primary={
          <ContainerChatName>
            <ChatName variant="subtitle1">{getChatName()}</ChatName>
            {closedAt && (
              <Tooltip title={`${t('finished')}`}>
                <i
                  className="fa-regular fa-box-check fa-2xs"
                  style={{
                    color: theme.palette.neutral.var80,
                  }}
                />
              </Tooltip>
            )}
          </ContainerChatName>
        }
      />
    </Item>
  );
};

export default ChatItemFilter;
