import { Box, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { IPickers } from '../types';
import { DayLabel } from '../styles';
import { getValidTimedDate } from '../../utils';
import { HourRange } from '../../types';

const Pickers = ({ opens, closes, onChange, dayLabel }: IPickers) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" gap={2} alignItems="center">
      <DayLabel variant="subtitle2" data-testid="day-label">
        {t(dayLabel)}
      </DayLabel>
      <TimePicker
        data-testid="time-picker-start"
        ampm={false}
        value={getValidTimedDate(opens)}
        onChange={(newValue) => onChange(newValue, HourRange.START)}
        renderInput={(props) => (
          <TextField
            {...props}
            data-testid="time-picker-start"
            inputProps={{
              ...props.inputProps,
              placeholder: t('business-hours.timePicker.start'),
            }}
          />
        )}
      />
      <TimePicker
        value={getValidTimedDate(closes)}
        ampm={false}
        onChange={(newValue) => onChange(newValue, HourRange.END)}
        renderInput={(props) => (
          <TextField
            {...props}
            data-testid="time-picker-end"
            inputProps={{
              ...props.inputProps,
              placeholder: t('business-hours.timePicker.end'),
            }}
          />
        )}
      />
    </Box>
  );
};

export default Pickers;
