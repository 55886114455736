import * as Yup from 'yup';
import { SchemaOf } from 'yup';

interface ISchemaValidator {
  password: string;
  confirmPassword: string;
}

export const schemaResetPassword: SchemaOf<ISchemaValidator> = Yup.object({
  password: Yup.string()
    .required('messageErrorEmptyPassword')
    .min(6)
    .test('isValidPass', ' is not valid', (value) => {
      if (value) {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber];
        conditions.forEach((condition) => {
          if (condition) {
            validConditions += 1;
          }
        });
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
      return false;
    }),
  confirmPassword: Yup.string()
    .required('emptyConfirmPasswordError')
    .oneOf([Yup.ref('password'), null], 'passwordsMustBeEquals'),
}).required();
