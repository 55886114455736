import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';

export const getChatsByCustomField = async (ticket: string): Promise<AxiosResponse> => {
  const params = {
    customFields: {
      ticket,
    },
  };
  return AxiosInstance.get('livechat/rooms', {
    params,
  });
};
