import React from 'react';
import { BubbleTail } from '../style';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrowRightChat.svg';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrowLeftChat.svg';

export enum TailDirection {
  LEFT = 'left',
  RIGHT = 'right',
}
interface IChatBubbleTailProps {
  visible: boolean;
  direction: TailDirection;
}

const ChatBubbleTail: React.FC<IChatBubbleTailProps> = ({ visible, direction }) => {
  const arrowProps = {
    width: 8,
    height: 10,
  };

  if (!visible) {
    return null;
  }

  return (
    <BubbleTail className="chat-bubble-tail" direction={direction}>
      {direction === TailDirection.LEFT ? <ArrowLeft {...arrowProps} /> : <ArrowRight {...arrowProps} />}
    </BubbleTail>
  );
};

export default ChatBubbleTail;
