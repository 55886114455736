import { IWorkHour, DayOfWeek } from './types';

export const START_DATE = '00:00';
export const END_DATE = '23:59';

export const DEFAULT_DAY_CONFIG: Omit<IWorkHour, 'dayOfWeek'> = {
  opens: START_DATE,
  closes: END_DATE,
};

export const daysOfWeek = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];

export const emptyWorkHours = '-- : --';
