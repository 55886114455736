import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderMessageTitle = styled(Typography)`
  justify-content: space-between;
  align-items: center;
`;

export const ErroMessageTryAgain = styled(Typography)`
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const HeaderMessageTime = styled(Typography)`
  text-transform: uppercase;
  font-weight: 500;
`;
