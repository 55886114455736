import { styled } from '@mui/material/styles';

export const Tags = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8px;
  flex-wrap: wrap;
  gap: 8px;
`;
