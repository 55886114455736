import { ListItemIcon, ListItemText } from '@mui/material';
import colors from 'theme/colors';
import { styled } from '@mui/material/styles';

export const ActionText = styled(ListItemText)`
  span {
    color: ${colors.colorNeutral80};
    font-size: 14px;
    margin: 0px 8px;
  }
`;

export const ActionIcon = styled(ListItemIcon)`
  min-width: 16px;
  margin-right: 8px;
`;
