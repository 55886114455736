import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { IFormCreateUserManager, IFormTeamsUserManager, IFormEditUserManager } from '../types';

export const schemaCreateUserManager: SchemaOf<IFormCreateUserManager> = Yup.object({
  name: Yup.string().required(''),
  email: Yup.string().email().required(''),
  username: Yup.string().required(''),
  password: Yup.string()
    .min(6)
    .test('isValidPass', ' is not valid', (value) => {
      if (value) {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber];
        conditions.forEach((condition) => (condition ? validConditions++ : null));
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
      return false;
    })
    .required(''),
  role: Yup.string().required(''),
}).required();

export const schemaTeamsUserManager: SchemaOf<IFormTeamsUserManager> = Yup.object({
  units: Yup.array(),
  teams: Yup.array(),
  maxNumberSimultaneousChat: Yup.number().optional(),
}).required();

export const schemaEditUserManager: SchemaOf<IFormEditUserManager> = Yup.object({
  name: Yup.string().required(''),
  email: Yup.string().email().required(''),
  username: Yup.string().required(''),
  role: Yup.string().required(''),
}).required();
