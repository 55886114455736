import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { User, UserTypes } from './types';

type VerificationTokens = {
  token: string;
  address: string;
  when: string;
};

type LoginTokens = {
  when: string;
  hashedToken: string;
};

type Rooms = {
  _id: string;
  rid: string;
  name: string;
  t: string;
  unread: boolean;
  roles?: string[];
};
export interface UserInfo extends User {
  createdAt: string;
  services: {
    password: {
      bcrypt: string;
    };
    email: {
      verificationTokens: VerificationTokens[];
    };
    resume: {
      loginTokens: LoginTokens[];
    };
  };
  type: `${UserTypes}`;
  active: true;
  roles: string[];
  lastLogin: string;
  statusConnection: string;
  utcOffset: number;
  avatarETag: string;
  rooms: Rooms[];
}

export interface UserInfoResponse {
  user: UserInfo;
}

export const getUserInfo = async (userId: string): Promise<AxiosResponse<UserInfoResponse>> =>
  AxiosInstance.get('users.info', {
    params: { userId },
  });
