import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Button } from '@engyalo/design-system';
import InputSearch from '../../../components/InputSearch';
import CustomPagination from '../../../components/Pagination';
import CustomTable from '../../../components/Table';
import ConfirmDeleteCustomField from '../../../components/ConfirmDeleteModal';
import ModalErrorCustomField from '../../../components/ErrorModal';
import { FooterPagination, TableContainer } from '../style';
import { Container, SearchContainer } from './style';
import useCustomFieldsManager from './useCustomFieldsManager';
import Drawer from '../../../components/Drawer';
import HeaderDrawerMenu from '../HeaderDrawerMenu';
import FormCreateCustomFields from './FormCreateCustomFields';
import { ModalEdit } from '../../../components/ContextDrawer/EditContactInformation/ModalEdit';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';
import { CustomFieldsType } from '../../../types/customFields';

const CustomFieldsManager: React.FC = () => {
  const { t } = useTranslation();
  const {
    handleSearchCustomFields,
    handleClickItem,
    currentPage,
    headerColumns,
    tableRows,
    totalItems,
    handleCurrentPage,
    selectCustomField,
    setShowDrawer,
    showDrawer,
    unsavedChanges,
    showModalUnsavedChages,
    setShowModalUnsavedChages,
    loadCustomFields,
    handleChangeForm,
    openDeleteModal,
    handleConfirmDeleteCustomField,
    openErrorModal,
    errorMessage,
    setOpenErrorModal,
    setSelectCustomField,
    loading,
  } = useCustomFieldsManager();
  const [inputText, setInputText] = useState('');

  return (
    <>
      <Container>
        <Typography variant="h2" color="common.black">
          {t('customFields')}
        </Typography>
        <SearchContainer>
          <InputSearch
            padding="8px 16px 8px 0px"
            placeholder={t('search')}
            value={inputText}
            onChange={(value) => {
              handleSearchCustomFields(value);
              setInputText(value);
            }}
          />
          <Button
            size="small"
            onClick={() => {
              setSelectCustomField({} as CustomFieldsType);
              setShowDrawer(true);
            }}
            variant="outlined"
            sx={{
              minInlineSize: 'fit-content',
            }}
          >
            {t('newField')}
          </Button>
        </SearchContainer>
        {totalItems >= 1 ? (
          <TableContainer>
            <CustomTable
              handleClickItem={(item: any) => handleClickItem(false, item)}
              page={currentPage}
              headItems={headerColumns}
              rows={tableRows}
              hasButtonOptions
              handleDeleteItem={(item) => handleClickItem(true, item)}
            />
          </TableContainer>
        ) : loading ? (
          <Box alignSelf="center">
            <Loading />
          </Box>
        ) : (
          <Typography variant="body1" color="neutral.var60" textAlign="center">
            {t('noFieldFound')}
          </Typography>
        )}
      </Container>
      <FooterPagination>
        <CustomPagination count={totalItems} page={currentPage} handleChangePage={handleCurrentPage} />
      </FooterPagination>

      {showDrawer && (
        <Drawer open={showDrawer}>
          <HeaderDrawerMenu
            handleClickBackButton={() => {
              if (unsavedChanges) {
                setShowModalUnsavedChages(true);
                return;
              }
              setShowDrawer(!showDrawer);
              setSelectCustomField(undefined);
            }}
            title={selectCustomField?._id ? `${t('edit')} ${selectCustomField?._id}` : t('createField')}
            showDeleteButton={!!selectCustomField?._id}
            handleClickDeleteButton={() => {
              handleClickItem(true, selectCustomField);
            }}
          />
          <FormCreateCustomFields
            onClose={() => {
              setShowDrawer(!showDrawer);
              loadCustomFields();
            }}
            formIsChanged={handleChangeForm}
            customField={selectCustomField}
            setSelectCustomField={setSelectCustomField}
          />
        </Drawer>
      )}

      {showModalUnsavedChages && (
        <ModalEdit
          open={showModalUnsavedChages}
          errorUpdt={false}
          handleEditing={(confirm) => {
            if (confirm) {
              setShowDrawer(!showDrawer);
            }
            setShowModalUnsavedChages(false);
          }}
          handleModal={() => {
            handleChangeForm(false);
            setShowModalUnsavedChages((state) => !state);
          }}
        />
      )}

      {openDeleteModal && (
        <ConfirmDeleteCustomField
          title="deleteCustomFieldTitle"
          message="messageDeleteCustomField"
          open={openDeleteModal}
          handleConfirm={(confirm: boolean) => handleConfirmDeleteCustomField(confirm)}
          loading={loading}
        />
      )}

      {openErrorModal && errorMessage && (
        <ModalErrorCustomField
          open={openErrorModal}
          message={errorMessage.bodyMessage}
          title={errorMessage.title}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
    </>
  );
};

export default CustomFieldsManager;
