import { styled } from '@mui/material/styles';
import { ReactComponent as Loading } from '../../assets/icons/btnLoadRequest.svg';

export const IconLoadingRequest = styled(Loading)`
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
