import { BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Audio } from './styled';
import { ChatMessageComponentProps } from '../ChatMessage';
import MediaMessageFallback from '../MediaMessageFallback';
import { logError } from '../../../../services/Logger';

const AudioMessage: React.FC<ChatMessageComponentProps> = (props) => {
  const { username, name, audio } = props;

  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  if (!audio) {
    return null;
  }

  const { url, mimeType: type } = audio;
  const author = name || username;
  const audioLabel = t('messages.audio.ariaLabel', { name: author });

  const handleError = (event: BaseSyntheticEvent) => {
    setHasError(true);
    logError('Error loading audio', event.target.error, { event, targetURL: url });
  };

  return hasError ? (
    <MediaMessageFallback />
  ) : (
    <Audio aria-label={audioLabel} src={url} controls preload="metadata" onError={handleError}>
      <source data-testid="sourceElement" src={url} type={type} />
    </Audio>
  );
};

export default AudioMessage;
