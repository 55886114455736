/* eslint-disable no-underscore-dangle */
import { OutgoingMessageChatMessage, OutgoingMessageMessageContext } from '@engyalo/salesdesk-api-ts-client';
import { UserMessageMessageKind } from '@engyalo/salesdesk-chat-socket-ts-client';
import { AuthorRoles } from 'services/types';
import { IRoom } from 'types/room';
import { MessageType } from 'components/Messages';
import { t } from 'i18next';
import cloneDeep from 'lodash.clonedeep';
import { IAccordeon } from './types';

export function isTextNotification(notification: MessageType) {
  return (
    notification.kind === OutgoingMessageMessageContext.Chat && notification.chat!.kind === UserMessageMessageKind.Text
  );
}

export function updateRoom(room: IRoom, chatMessage: OutgoingMessageChatMessage): IRoom {
  const { text, id, timestamp, authorRole, name, username } = chatMessage;
  const timestampISOString = new Date(Number(timestamp)).toISOString();

  return {
    ...room,
    onHold: room.onHold ? false : room.onHold,
    unreadChat: true,
    lastMessage: {
      ...room.lastMessage,
      _id: id,
      msg: text!.body,
      _updatedAt: timestampISOString,
      ts: timestampISOString,
      t: '',
      u: {
        ...room.lastMessage.u,
        _id: '',
        name: authorRole === AuthorRoles.Bot ? t('flow') : name || '',
        username: authorRole === AuthorRoles.User ? `whatsapp:${username}` : username,
      },
    },
  };
}

function handleOnHoldNotification(accordions: IAccordeon[], notification: OutgoingMessageChatMessage) {
  const accordionInAttendanceIndex = accordions.findIndex((a) => a.id === 'inAttendance');
  const accordionOnHoldIndex = accordions.findIndex((a) => a.id === 'onHold');
  const roomIndex = accordions[accordionOnHoldIndex]?.rooms.findIndex((r) => r._id === notification.roomId);
  const room = accordions[accordionOnHoldIndex]?.rooms[roomIndex];

  accordions[accordionOnHoldIndex].rooms.splice(roomIndex, 1);
  accordions[accordionInAttendanceIndex].rooms.unshift(updateRoom(room, notification));

  return accordions;
}

function handleNotification(accordions: IAccordeon[], notification: OutgoingMessageChatMessage, isFIFO: boolean) {
  return accordions.map((accordion) => {
    const unnotifiedRooms = accordion.rooms.filter((room) => room._id !== notification.roomId);

    const notifiedRoom = accordion.rooms.find((room) => room._id === notification.roomId);
    if (notifiedRoom) {
      if (isFIFO) {
        const notifiedRoomIndex = accordion.rooms.findIndex((room) => room._id === notification.roomId);
        const accordionCopy = cloneDeep(accordion);
        accordionCopy.rooms[notifiedRoomIndex] = updateRoom(notifiedRoom, notification);

        return accordionCopy;
      }
      return { ...accordion, rooms: [updateRoom(notifiedRoom, notification), ...unnotifiedRooms] };
    }

    return accordion;
  });
}

export function updateAccordions(
  accordions: IAccordeon[],
  notification: OutgoingMessageChatMessage,
  isFIFO: boolean
): IAccordeon[] {
  const notifiedAccordion = accordions.find((accor) => accor.rooms.some((r) => r._id === notification.roomId));

  switch (notifiedAccordion?.id) {
    case 'pending':
      return handleNotification(accordions, notification, isFIFO);
    case 'onHold':
      return handleOnHoldNotification(accordions, notification);
    default:
      return accordions;
  }
}

export function shouldNotifyOnNewMessage(accordions: IAccordeon[], notification: OutgoingMessageChatMessage): boolean {
  const inServiceAccordion = accordions.find((acc) => acc.id === 'inAttendance');
  const { roomId } = notification;

  if (roomId && inServiceAccordion?.rooms?.length) {
    return inServiceAccordion.rooms.some((room) => room._id === roomId);
  }

  return false;
}
