import { useCallback, useContext } from 'react';
import { TABS } from 'contexts/drawer/context';
import AuthContext from '../contexts/auth/context';
import {
  AnalyticsWindow,
  EventMessageProperties,
  EVENT_NAMES,
  TAB_SELECTED_TYPE,
  QUEUE_CONVERSATION_TYPE,
} from '../types/analytics';
import { UserInformation, UserInformationSegment } from '../types/user';
import useEnvironment from '../hooks/useEnvironments';

const EVENT_MAP = {
  [TABS.CONTACT_INFO]: EVENT_NAMES.USER_CHECK_CUSTOMER_INFO,
  [TABS.CHATS_HISTORY]: EVENT_NAMES.USER_CLICKED_ON_HISTORY_CONVERSATION,
  [TABS.SEARCH_MESSAGES]: EVENT_NAMES.USER_CLICKED_ON_SEARCH_THIS_CONVERSATION,
};

declare let window: AnalyticsWindow;

const useSegment = () => {
  const ORGANIZATION_ID = 'Yalo';
  const TAB_YOUR_CHATS_INDEX = 0;
  const TAB_ALL_CHATS_INDEX = 1;
  const { currentUserInfo } = useContext(AuthContext);
  const { getInstance } = useEnvironment();

  const addCustomProperties = useCallback(
    (user: UserInformation, custom?: EventMessageProperties): UserInformationSegment => {
      const userEvent: UserInformationSegment = {
        ...user,
        ...custom,
        organizationId: ORGANIZATION_ID,
        customerName: getInstance(),
      };
      return userEvent;
    },
    [getInstance]
  );

  const identifySegmentUser = (user: UserInformation) => {
    window.analytics?.identify(user._id, addCustomProperties(user));
  };

  const sendEvent = useCallback(
    (eventType: string, customProps?: EventMessageProperties) => {
      window.analytics?.track(eventType, addCustomProperties(currentUserInfo, customProps));
    },
    [currentUserInfo, addCustomProperties]
  );

  const sendPage = useCallback(() => {
    window.analytics?.page();
  }, []);

  const sendMenuHomeEvent = useCallback(
    (tabSelectedIndex: number) => {
      let tabSelected = '';
      if (tabSelectedIndex === TAB_YOUR_CHATS_INDEX) {
        tabSelected = TAB_SELECTED_TYPE.YOUR_CONVERSATIONS;
      } else if (tabSelectedIndex === TAB_ALL_CHATS_INDEX) {
        tabSelected = TAB_SELECTED_TYPE.ALL_CONVERSATIONS;
      }

      sendEvent(EVENT_NAMES.TABS_SELECTED, { tabSelected });
    },
    [sendEvent]
  );

  const sendMoreActionsEvent = useCallback(
    (tabSelected: TABS | null) => {
      if (tabSelected) {
        const eventName = EVENT_MAP[tabSelected];

        if (eventName) {
          sendEvent(eventName);
        }
      }
    },
    [sendEvent]
  );

  const sendConversationSelectedEvent = useCallback(
    (room: any) => {
      let queue = QUEUE_CONVERSATION_TYPE.IN_ATTENDANCE;
      if (room.closedBy) {
        queue = QUEUE_CONVERSATION_TYPE.CLOSED_TODAY;
      } else if (room.onHold) {
        queue = QUEUE_CONVERSATION_TYPE.ON_HOLD;
      } else if (!room.servedBy && !room.closedBy) {
        queue = QUEUE_CONVERSATION_TYPE.PENDING;
      }

      sendEvent(EVENT_NAMES.USER_SELECTED_CONVERSATION, { queue });
    },
    [sendEvent]
  );

  return {
    EVENT_NAMES,
    identifySegmentUser,
    sendEvent,
    sendPage,
    sendMoreActionsEvent,
    sendMenuHomeEvent,
    sendConversationSelectedEvent,
  };
};

export default useSegment;
