import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReactComponent as IconPhoneYalo } from '../../../assets/icons/IconPhoneYalo.svg';
import { Container } from './style';

const EmptyChatPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <IconPhoneYalo />
      <Typography variant="h2" color="common.black" margin="30px 0px 12px 0px">
        {t('SelectOneChat')}
      </Typography>
      <Typography variant="subtitle1" color="neutral.var60">
        {t('ManageYourServicesFromList')}
      </Typography>
    </Container>
  );
};

export default EmptyChatPage;
