import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AxiosInstance from './AxiosInstance';

export const addAgentsToDiscussion = async (rid: string, users: Array<string>): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'addUsersToRoom',
      params: [{ rid, users }],
    }),
  };
  return AxiosInstance.post('method.call/addUsersToRoom', params);
};
