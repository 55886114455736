import { ListItem, styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormCustom = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: 100%;
  justify-content: space-between;
`;

export const FieldsSection = styled('div')`
  gap: 40px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ListItemSelect = styled(ListItem)`
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  cursor: pointer;
  border: ${({ theme }) => `0px solid ${theme.palette.neutral.var20}`};
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.neutral.var90};
  background: ${({ theme }) => theme.palette.common.white};
  :hover {
    color: ${({ theme }) => theme.palette.neutral.var90};
    background: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
  :active {
    background-color: ${({ theme }) => theme.palette.neutral.var30} !important;
  }
`;

export const FooterButton = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  height: 52px;
  box-shadow: ${({ theme }) => `inset 0px 1px 0px ${theme.palette.neutral.var20}`};
`;
