import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, FormControlLabel, Stack, useMediaQuery, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Button, Switch, TextField } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FontSizePicker from 'views/home/QueueMenu/FontSizePicker';
import { Container, NotificationStack, SwitchStack, ContentStack, Icon } from './style';
import { IModalPreferences, IFormPreferences } from './types';
import GenericModal from '../../../../components/Modal';
import { ReactComponent as PlayIcon } from '../../../../assets/icons/playSoundIcon.svg';
import LanguagePicker from '../LanguaguePicker';
import PreferencesContext from '../../../../contexts/preferences/context';
import usePreferences from './usePreferences';
import ButtonsRow from '../../../../components/ButtonsStack';
import { flag, useFeatureFlag } from '../../../../hooks/useFeatureFlag';

export const ModalPreferences: React.FC<IModalPreferences> = (props: IModalPreferences) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const { playSound, notify, userPreferences, loading, disable, updateGlobalFontSize } = useContext(PreferencesContext);
  const { onSubmit, maxNumberSimultaneousChat } = usePreferences(handleClose);
  const isSettingMaxChatsAllowed = useFeatureFlag(flag.ALLOW_SETTING_MAX_CHATS);

  const handleCancel = () => {
    handleClose();
    updateGlobalFontSize();
  };

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm<IFormPreferences>({
    mode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        maxNumberSimultaneousChat: Yup.number().min(0).optional(),
      })
    ),
  });

  return (
    <GenericModal
      open={open}
      hasCloseIcon
      title={`${t('preferences')}`}
      handleModal={handleCancel}
      isFullScreen={isMobile}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Container>
            <Typography variant="h4" color="neutral.var70">
              {t('notifications')}
            </Typography>

            <NotificationStack>
              <SwitchStack>
                <Controller
                  name="desktopNotifications"
                  control={control}
                  defaultValue={userPreferences?.desktopNotifications !== 'nothing'}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(event: any) => {
                            field.onChange(event.target.checked);
                          }}
                        />
                      }
                      label=""
                    />
                  )}
                />

                <ContentStack>
                  <Typography variant="body1" color="common.black">
                    {t('desktopNotifications')}
                  </Typography>
                  <Typography variant="caption1" color="neutral.var60">
                    {t('desktopNotificationsInfo')}
                  </Typography>
                </ContentStack>
              </SwitchStack>
              <Icon
                onClick={() => {
                  notify();
                }}
                disableRipple
                aria-label={t('testNotification')}
              >
                <PlayIcon style={{ cursor: 'pointer', alignSelf: 'center' }} />
              </Icon>
            </NotificationStack>

            <NotificationStack>
              <SwitchStack>
                <Controller
                  name="soundNotifications"
                  control={control}
                  defaultValue={userPreferences?.notificationsSoundVolume !== 0}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(event: any) => {
                            field.onChange(event.target.checked);
                          }}
                        />
                      }
                      label=""
                    />
                  )}
                />
                <ContentStack>
                  <Typography variant="body1" color="common.black">
                    {t('soundAlerts')}
                  </Typography>
                  <Typography variant="caption1" color="neutral.var60">
                    {t('soundAlertsInfo')}
                  </Typography>
                </ContentStack>
              </SwitchStack>
              <Icon
                onClick={() => {
                  playSound();
                }}
                disableRipple
                aria-label={t('playSound')}
              >
                <PlayIcon style={{ cursor: 'pointer', alignSelf: 'center' }} />
              </Icon>
            </NotificationStack>

            <Typography variant="h4" color="neutral.var70">
              {t('systemLanguage')}
            </Typography>
            <LanguagePicker />
            <FontSizePicker />
            {isSettingMaxChatsAllowed && (
              <>
                <Typography variant="h4" color="neutral.var70">
                  {t('maxNumberSimultaneousChat')}
                </Typography>
                <Controller
                  name="maxNumberSimultaneousChat"
                  control={control}
                  defaultValue={maxNumberSimultaneousChat}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      error={!!errors.maxNumberSimultaneousChat}
                      type="number"
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                    />
                  )}
                />
              </>
            )}
          </Container>
          <ButtonsRow justifyContent="flex-end" gap="1.6rem" marginTop="0">
            <Button variant="outlined" color="neutral2" onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <Button disabled={disable ? !disable : !isDirty} type="submit" sx={{ minWidth: '12rem' }}>
              {loading ? <CircularProgress size={25} /> : <>{t('save')}</>}
            </Button>
          </ButtonsRow>
        </Stack>
      </form>
    </GenericModal>
  );
};
