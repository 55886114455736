import { DependencyList, RefObject } from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Fab } from './styled';
import { useScrollToBottom } from './hooks';

interface ScrollToBottomButtonProps {
  className?: string;
  containerRef: RefObject<HTMLElement>;
  deps?: DependencyList;
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({ className, containerRef, deps }) => {
  const { visible, scrollToBottom } = useScrollToBottom({ containerRef, deps });

  if (!visible) {
    return null;
  }

  return (
    <Fab className={className} onClick={scrollToBottom} size="small">
      <ArrowDownIcon />
    </Fab>
  );
};

export default ScrollToBottomButton;
