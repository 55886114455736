import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../../../constants/defaultValues';
import { deleteUnit } from '../../../../services/deleteUnit';
import { getUnits } from '../../../../services/getUnits';
import { saveUnit } from '../../../../services/saveUnit';
import { IUnit } from '../../../../types/unit';
import { IUseUnitProps } from '../types';
import { logError } from '../../../../services/Logger';
import { getListDeparmentsUnit, getListMonitorsUnit } from '../../../../helpers/getByUnits';

type NewUnitMonitors = {
  monitorId: string;
  username: string;
};

type DeparmentsSelected = {
  departmentId: string;
};

const useDeleteUnit = (props: IUseUnitProps) => {
  const { handleClose, unit } = props;
  const [unitsList, setUnitsList] = useState<IUnit[]>([]);
  const [newUnit, setNewUnit] = useState('');
  const [showModalError, setModalError] = useState(false);
  const defaultUnitStorage = localStorage.getItem(STORAGE_KEYS.DEFAULT_UNIT);

  const loadUnits = async () => {
    try {
      const {
        data: { units },
      } = await getUnits();
      setUnitsList(units);
      const defaultUnit = units.find((item) => item.name === defaultUnitStorage)?._id;
      if (defaultUnit) {
        setNewUnit(defaultUnit);
      }
    } catch (error) {
      logError('error getUnits usedeleteunit', error);
      throw Error('error get units');
    }
  };

  const transferTeams = async (
    newUnitMonitors: NewUnitMonitors[],
    newUnitCurrentDepartments?: DeparmentsSelected[],
    departmentsToMove?: DeparmentsSelected[]
  ) => {
    const infoUnit = unitsList.find(({ _id }) => _id === newUnit);
    const allDeparments = newUnitCurrentDepartments?.concat(departmentsToMove || []);
    const {
      data: { message = '' },
    } = await saveUnit(
      newUnit,
      { name: infoUnit?.name, visibility: infoUnit?.visibility },
      newUnitMonitors,
      allDeparments
    );
    const response = JSON.parse(message);
    if (response.result) return;

    throw response?.error;
  };

  const handleDeleteUnit = async () => {
    if (unit?._id && newUnit) {
      try {
        const newUnitMonitors = await getListMonitorsUnit(newUnit);
        const newUnitCurrentDepartments = await getListDeparmentsUnit(newUnit);
        const departmentsToMove = await getListDeparmentsUnit(unit._id);
        const {
          data: { message },
        } = await deleteUnit(unit?._id, uuidv4());
        const response = JSON.parse(message);
        if (message && newUnitMonitors) {
          await transferTeams(newUnitMonitors, newUnitCurrentDepartments, departmentsToMove);
          handleClose(true);
          return;
        }
        throw response?.error;
      } catch (error) {
        setModalError(true);
      }
    }
  };

  useEffect(() => {
    loadUnits();
  }, []);

  return {
    unitsList,
    showModalError,
    handleDeleteUnit,
    setNewUnit,
    setModalError,
  };
};

export default useDeleteUnit;
