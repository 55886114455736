import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 48px 0px;
  gap: 32px;
`;

export const OptionalLabel = styled('span')`
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.neutral.var60};
  margin-left: 3px;
`;

export const FormCustom = styled('form')`
  max-width: 480px;

  .MuiButton-root {
    margin-top: 16px;
  }
`;
