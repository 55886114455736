import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import GenericModal from '../Modal';
import AlertsContext from '../../contexts/alerts/context';
import { BodyText, FooterButtons, ContainerModal } from './style';
import { IModalError } from './types';

const ModalError: React.FC<IModalError> = (props: IModalError) => {
  const { handleClose, open, title, message } = props;
  const { t } = useTranslation();
  const { alerts } = useContext(AlertsContext);

  // Hide modal when alerts are displayed
  useEffect(() => {
    if (alerts?.length > 0) {
      handleClose();
    }
  }, [alerts, handleClose]);

  return (
    <GenericModal open={open} title={title}>
      <ContainerModal data-testid="modalError">
        <BodyText variant="body1" color="neutral.var100">
          {message}
        </BodyText>
        <FooterButtons>
          <Button data-testid="okButton" onClick={() => handleClose()} disableElevation>
            {t('Ok')}
          </Button>
        </FooterButtons>
      </ContainerModal>
    </GenericModal>
  );
};

export default ModalError;
