import { Switch } from '@engyalo/design-system';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'react-i18next';
import { setUseSameSchedule } from 'redux/reducers/businessHoursReducer';

const SameScheduleSelector = () => {
  const { t } = useTranslation();
  const useSameSchedule = useAppSelector((state) => state.businessHours.useSameSchedule);
  const dispatch = useAppDispatch();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUseSameSchedule(e.target.checked));
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <FormControlLabel
        data-testid="sameScheduleSelector"
        sx={{ marginLeft: 0 }}
        control={<Switch checked={useSameSchedule} onChange={handleChange} />}
        label={<Typography>{t('useSameScheduleLabel')}</Typography>}
      />
    </Box>
  );
};

export default SameScheduleSelector;
