import timezones, { TimeZone } from 'timezones-list';
import { Icon, TextFieldProps, Autocomplete, SvgIconProps } from '@mui/material';
import { TextField } from '@engyalo/design-system';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';

export const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 448 512" style={{ fontSize: '1.2rem' }} preserveAspectRatio="xMidYMid meet" {...props}>
    <path d="M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z" />{' '}
  </SvgIcon>
);

interface ITimezoneSelectorProps extends Omit<TextFieldProps, 'onChange'> {
  value?: string;
  errorMessage?: string;
  onChange?: (value: string) => void;
}

export const warnedTimezones = [
  'America/Mexico_City',
  'America/Chihuahua',
  'America/Hermosillo',
  'America/Mazatlan',
  'America/Bahia_Banderas',
  'America/Monterrey',
  'America/Merida',
];

const warningIcon = <Icon className="fa-solid fa-triangle-exclamation" fontSize="inherit" color="secondary" />;

const TimezoneSelector = ({
  value = '',
  errorMessage = '',
  helperText,
  onChange,
  ...props
}: ITimezoneSelectorProps) => {
  const { t } = useTranslation();
  const showWarning = warnedTimezones.includes(value);
  const warningMessage = showWarning ? (
    <>
      {warningIcon} {t('business-hours.timeZone.warning')}
    </>
  ) : (
    ''
  );

  const selectedValue: TimeZone = timezones.find((timezone) => timezone.tzCode === value) || timezones[0];

  return (
    <Autocomplete
      data-testid="timezone-select"
      popupIcon={<ArrowIcon />}
      options={timezones}
      value={selectedValue}
      getOptionLabel={(option) => option.label}
      sx={{
        borderRadius: '4px',
        width: '100%',
        border: errorMessage ? '1px solid error.main' : 'none',
        ':hover': {
          border: '1px solid neutral.var40',
        },
        '.MuiAutocomplete-endAdornment': {
          top: 'auto',
          fontSize: '1.7rem',
          right: '1.2rem !important',
        },
        '.MuiAutocomplete-popupIndicator, .MuiAutocomplete-clearIndicator': {
          color: 'rgba(0, 0, 0, 0.54)',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&:after': {
            content: 'none',
          },
        },
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          helperText={t(errorMessage) || warningMessage || helperText}
          sx={{
            '.MuiInputBase-root': {
              height: 'auto',
              minHeight: '1.4375em',
              maxHeight: '4rem',
              padding: '11px 4px 11px 11px',
              '.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused': {
                padding: '0',
              },
            },
          }}
        />
      )}
      onChange={(_, newValue) => {
        const updateValue = newValue?.tzCode || '';
        onChange?.(updateValue);
      }}
    />
  );
};

export default TimezoneSelector;
