import { shouldForwardPropHelper } from '@engyalo/design-system';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChatContainer = styled('div', {
  shouldForwardProp: shouldForwardPropHelper,
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral.var10};
  justify-content: space-between;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
`;

export const HeaderArea = styled('div')();

export const MainArea = styled('div')`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const MessagesWrapper = styled('div')`
  height: 100%;
`;

export const MessagesArea = styled('div')`
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 2rem 2rem 0;

  html.medium & {
    padding: 1rem 1rem 0;
  }

  html.large & {
    padding: 0.5rem 0.5rem 0;
  }
`;

export const ChatEndAuxiliary = styled('div')`
  margin-top: 4rem;
  height: 0;
`;

export const ButtonArea = styled('div')`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
`;

export const FooterArea = styled('div')``;

export const StackIcons = styled('div')`
  display: flex;
  direction: row;
  align-items: flex-end;
  justify-content: space-around;
  margin: 8px;
  gap: 2px;
  img {
    width: 18px;
    height: 20px;
  }
  align-self: 'self-end';
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: auto;
    margin: 8px 0px;
  }
`;

export const StackHeader = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
  padding: 6px 12px;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
`;
