import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.neutral.var10};
  height: 100%;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.var30}`};
`;
