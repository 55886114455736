import { TFunction } from 'i18next';
import { IWorkHour } from './types';
import { daysOfWeek, emptyWorkHours } from './constants';

/**
 * Receives a time string and returns a Date object with the time set
 * @param time
 * @returns Date
 */
export const getValidTimedDate = (time: string): Date => {
  const [hour = 0, minute = 0] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hour, minute, 0, 0);
  return date;
};

/**
 * Format work hours array and return a string array with the formatted labels for all days of the week
 */
export const formatWorkHours = (workHours: IWorkHour[], t: TFunction) => {
  const today = daysOfWeek[new Date().getDay()];
  const reFormatHour = (time: string) => time.split(':').slice(0, 2).join(':');

  return daysOfWeek.map((day) => {
    const isToday = today === day;
    const workHour = workHours.find((current) => current.dayOfWeek === day);
    const { opens = '', closes = '' } = workHour || {};
    const dayLabel = t(`business-hours.days.${day}`);
    const dayShortLabel = t(`business-hours.days.short.${day}`);
    const hourLabel = workHour ? `${reFormatHour(opens)} - ${reFormatHour(closes)}` : emptyWorkHours;

    return {
      dayLabel,
      dayShortLabel,
      hourLabel,
      isToday,
      hasWorkHours: !!workHour,
    };
  });
};
