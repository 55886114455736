import React from 'react';
import { Stack } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import LoadingAnimation from '../AnimationLoading';
import ButtonsRow from '../ButtonsStack';
import GenericModal from '../Modal';
import { Span } from './style';
import { IFormChangePassword } from '.';

export interface ICheckPassword {
  validatePassword: () => void;
  showModalPasswordAdmin: boolean;
  handleShowModalEditPassword: () => void;
  admPassword: string;
  errors: FieldErrors<IFormChangePassword>;
  loading: boolean;
  isValid: boolean;
  setAdmPassword: (value: string) => void;
}

export const CheckPassword: React.FC<ICheckPassword> = (props: ICheckPassword) => {
  const theme = useTheme();
  const {
    validatePassword,
    showModalPasswordAdmin,
    handleShowModalEditPassword,
    admPassword,
    errors,
    loading,
    isValid,
    setAdmPassword,
  } = props;
  const { t } = useTranslation();
  return (
    <GenericModal
      open={showModalPasswordAdmin}
      title={`${t('confirmPasword')}`}
      hasCloseIcon
      handleModal={handleShowModalEditPassword}
    >
      <Stack width="100%" data-testid="checkPassword">
        <Span>{t('password')} </Span>

        <TextField
          value={admPassword}
          placeholder={`${t('password')}`}
          error={!!errors.password}
          helperText={t(errors.password?.message ?? '')}
          InputProps={{}}
          onChange={(event: any) => {
            setAdmPassword(event.target.value);
          }}
          type="password"
          inputProps={{ role: 'inputPasswordAdmin' }}
        />

        <ButtonsRow justifyContent="flex-end">
          <Button
            variant="outlined"
            color="neutral2"
            sx={{
              width: '14.8rem',
            }}
            onClick={handleShowModalEditPassword}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid="confirmPasswordButton"
            disabled={!isValid}
            sx={{
              marginLeft: '8px',
              width: '14.8rem',
            }}
            onClick={() => {
              validatePassword();
            }}
          >
            {loading ? <LoadingAnimation color={theme.palette.neutral.var90} /> : t('confirm')}
          </Button>
        </ButtonsRow>
      </Stack>
    </GenericModal>
  );
};
