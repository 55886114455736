import React from 'react';
import { TextOptionEnum } from './TextOptionEnum';

export const icons = [
  {
    icon: <i className="fa-duotone fa-b" />,
    textOption: TextOptionEnum.BOLD,
  },
  {
    icon: <i className="fa-duotone fa-italic" />,
    textOption: TextOptionEnum.ITALIC,
  },
  {
    icon: <i className="fa-duotone fa-strikethrough" />,
    textOption: TextOptionEnum.STROKE,
  },
  {
    icon: <i className="fa-regular fa-face-smile" />,
    textOption: TextOptionEnum.EMOJI,
  },
  {
    icon: <i className="fa-duotone fa-link" />,
    textOption: TextOptionEnum.INLINE_CODE,
  },
];
