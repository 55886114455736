import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ListItem, Typography } from '@mui/material';
import { Button, TextField } from '@engyalo/design-system';
import GenericModal from '../../Modal';
import { Buttons, Form } from './styles';
import { IError, ITagsModal } from './types';
import { ContainerModal } from '../style';
import ErrorTagModal from '../ErrorTagModal';
import GenericAutocomplete from '../../Autocomplete';
import DepartmentContext from '../../../contexts/departments/context';
import { saveTag } from '../../../services/saveTag';
import { IDepartment } from '../../../types/department';

const CreateTagModal: React.FC<ITagsModal> = (props) => {
  const { open, tag, handleCloseModal, typeDescription } = props;
  const { t } = useTranslation();
  const [tagValue, setTagValue] = useState<string>('');
  const [departmentsSelected, setDepartmentsSelected] = useState<string[]>();
  const [existsTag, setExistsTag] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<IError>();
  const [idRequest, setIdRequest] = useState(0);
  const { departments } = useContext(DepartmentContext);
  const options = useMemo(
    () => (departments.length ? [{ _id: 'allOptions', name: t('allTeams') }, ...departments] : []),
    [departments, t]
  );

  useEffect(() => {
    if (tag) {
      setTagValue(tag.name);
    }
  }, []);

  useEffect(() => {
    if (tag) {
      setDepartmentsSelected(tag.departments);
    }
  }, [tag]);

  const handleSaveTag = async () => {
    if (tagValue && !tagValue.trim() === false) {
      const tagId = tag?._id;
      try {
        const paramsSaveTag = {
          idRequest: `${idRequest}`,
          name: tagValue,
          description: typeDescription,
          id: tagId,
          departmentsIds: departmentsSelected,
        };
        const { data } = await saveTag(paramsSaveTag);
        const { message } = data;
        const response = JSON.parse(message);

        if (response.result && tagValue) {
          setIdRequest(idRequest + 1);
          handleCloseModal(true, {
            tagName: tagValue,
          });
          return;
        }
        throw response.error;
      } catch (error: any) {
        const errorInfo = {
          title: tag && tag._id ? t('errorWhenEditTag') : t('errorWhenCreateTag'),
          bodyMessage: tag && tag._id ? t('errorMessageWhenEditTag') : t('errorMessageWhenCreateTag'),
        };
        if (
          (error.writeErrors &&
            error.writeErrors.length > 0 &&
            error.writeErrors[0].err.errmsg.includes('duplicate key')) ||
          error.code === 11000
        ) {
          setExistsTag(true);
          errorInfo.bodyMessage = t('existsTags');
        }
        setErrorMessage(errorInfo);
        setOpenErrorModal(true);
      }
    }
  };

  return (
    <GenericModal
      open={open}
      title={tag ? t('editTag') : t('createNewTag')}
      handleModal={() => {
        handleCloseModal(false);
      }}
      hasCloseIcon
    >
      <ContainerModal data-testid="createTagModal">
        <Form>
          <TextField
            value={tagValue}
            onChange={(event) => {
              if (existsTag) {
                setExistsTag(false);
              }
              setTagValue(event.target.value);
            }}
            inputProps={{
              maxLength: 30,
              'data-testid': 'inputTagName',
            }}
            placeholder={typeDescription === 'room' ? t('createNewTagConversation') : t('createNewTagContact')}
            error={existsTag}
            helperText={existsTag && t('existsTags')}
          />
          {typeDescription === 'room' && (
            <GenericAutocomplete
              prefixPlaceholder={t('teams')}
              placeholder={t('selectTeam')}
              renderOption={(properties, option, { selected }) => (
                <ListItem
                  sx={{
                    height: '36px',
                    padding: '8px 12px',
                  }}
                  {...properties}
                >
                  <Checkbox checked={selected} />
                  <Typography variant="body1" color="neutral.var90">
                    {option.name}
                  </Typography>
                </ListItem>
              )}
              handleChange={(value) => {
                setDepartmentsSelected(value.map((item: IDepartment) => item._id));
              }}
              limitTagsNumber={1}
              multiple
              defaultValue={tag?.departments}
              options={options}
            />
          )}
        </Form>
        <Buttons>
          <div>
            <Button
              data-testid="cancelButton"
              onClick={() => handleCloseModal(false)}
              disableElevation
              color="neutral2"
              variant="outlined"
            >
              {t('cancel')}
            </Button>
            <Button
              data-testid="createNewTagButton"
              onClick={() => handleSaveTag()}
              disabled={!tagValue}
              sx={{
                marginLeft: '20px',
              }}
              disableElevation
            >
              {tag ? t('save') : t('create')}
            </Button>
          </div>
        </Buttons>
      </ContainerModal>

      {openErrorModal && errorMessage && (
        <ErrorTagModal
          open={openErrorModal}
          bodyMessage={errorMessage.bodyMessage}
          title={errorMessage.title}
          handleCloseModal={() => setOpenErrorModal(false)}
        />
      )}
    </GenericModal>
  );
};

export default CreateTagModal;
