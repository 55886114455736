import { getDashChartsTotalsByMultipleDepartments } from '../../../services/dashChartsTotals';
// import { getMaxDashAgentTotalizerFromMultipleDepartments } from '../../../services/dashAgentTotalizer';
// import { isBusiestTime, Totalizer } from '../../../services/types';
// import { convertTime12to24 } from '../../../helpers/formatterDates';
// import { getLanguage } from '../../../constants/defaultValues';

export interface IConversationStatus {
  totalNew: string;
  awaitingService: string;
  inService: string;
  finishedToday: string;
  onHold?: string;
  // busiestTime: string;
}

export const getConversationsStats = async (
  dateStart: string,
  dateEnd: string,
  teamIds: string[],
  showOnHold: boolean
) => {
  const conversationStatus: IConversationStatus = {} as IConversationStatus;
  const dataCharts = await getDashChartsTotalsByMultipleDepartments(dateStart, dateEnd, teamIds);
  // const dataBusiestTime = await getMaxDashAgentTotalizerFromMultipleDepartments(dateStart, dateEnd, teamIds);
  // const systemLanguage = getLanguage();
  // const getBusiestTime = dataBusiestTime.reduce((acc, currentValue) => {
  //   if (isBusiestTime(currentValue)) {
  //     acc.push(currentValue);
  //   }
  //   return acc;
  // }, [] as Totalizer[]);

  // const [{ value: busiestTime }] = getBusiestTime;

  conversationStatus.totalNew = `${dataCharts.open + dataCharts.closed + dataCharts.onhold + dataCharts.queued}`;
  conversationStatus.awaitingService = `${dataCharts.queued}`;
  conversationStatus.inService = `${dataCharts.open + dataCharts.onhold}`;
  conversationStatus.finishedToday = `${dataCharts.closed}`;
  conversationStatus.onHold = showOnHold ? `${dataCharts.onhold}` : undefined;
  // conversationStatus.busiestTime = busiestTime === '- -' ? '-' : convertTime12to24(busiestTime, systemLanguage);

  return conversationStatus;
};
