import { CircularProgress, Icon } from '@mui/material';
import { STATUS_UPLOAD } from 'types/chat';

export const progressColors: Record<STATUS_UPLOAD, 'primary' | 'success' | 'error'> = {
  [STATUS_UPLOAD.LOADING]: 'primary',
  [STATUS_UPLOAD.SUCCESS]: 'success',
  [STATUS_UPLOAD.FAILED]: 'error',
};

export const statusIcons: Record<STATUS_UPLOAD, JSX.Element> = {
  [STATUS_UPLOAD.LOADING]: <CircularProgress size={12} thickness={6} />,
  [STATUS_UPLOAD.SUCCESS]: (
    <Icon className="fa-solid fa-circle-check" fontSize="small" color="success" sx={{ padding: 0 }} />
  ),
  [STATUS_UPLOAD.FAILED]: (
    <Icon className="fa-solid fa-circle-exclamation" fontSize="small" color="error" sx={{ padding: 0 }} />
  ),
};

export const percentageColors: Record<STATUS_UPLOAD, string> = {
  [STATUS_UPLOAD.LOADING]: 'primary.darker',
  [STATUS_UPLOAD.SUCCESS]: 'success.darker',
  [STATUS_UPLOAD.FAILED]: 'error.darker',
};
