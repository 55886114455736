import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { FontSizeOptions } from 'types/user';
import AxiosInstance from './AxiosInstance';

export const saveUserPreferences = async (
  language: string,
  desktopNotifications: string,
  notificationsSoundVolume: number,
  fontSize: FontSizeOptions
): Promise<AxiosResponse> => {
  const params = {
    message: JSON.stringify({
      msg: 'method',
      id: uuidv4(),
      method: 'saveUserPreferences',
      params: [
        {
          language,
          desktopNotifications,
          notificationsSoundVolume,
          fontSize,
        },
      ],
    }),
  };
  return AxiosInstance.post('method.call/livechat:saveUserPreferences', params);
};
