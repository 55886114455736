import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@engyalo/design-system';
import GenericModal from '../../Modal';
import { BodyText, ContainerModal, FooterButtons } from '../style';
import { IErrorTagModal } from './types';

const ErrorTagModal: React.FC<IErrorTagModal> = (props: IErrorTagModal) => {
  const { handleCloseModal, open, title, bodyMessage } = props;
  const { t } = useTranslation();
  return (
    <GenericModal open={open} title={title}>
      <ContainerModal data-testid="errorTagModal">
        <BodyText variant="body1" color="neutral.var100">
          {bodyMessage}
        </BodyText>
        <FooterButtons>
          <Button data-testid="okButton" onClick={() => handleCloseModal()} disableElevation variant="contained">
            {t('Ok')}
          </Button>
        </FooterButtons>
      </ContainerModal>
    </GenericModal>
  );
};

export default ErrorTagModal;
