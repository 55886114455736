import React, { useContext, useEffect, useState } from 'react';

import { getCustomerInfo } from 'services/getCustomerInfo';
import { STORAGE_KEYS } from 'constants/defaultValues';
import { logError } from 'services/Logger';
import { Container, LoadingContainer } from './styles';
import { getAnalytics } from '../../../services/getAnalytics';
import AuthContext from '../../../contexts/auth/context';
import { ReactComponent as Loading } from '../../../assets/icons/loadingYalo.svg';

const defaultPath = 'delivery';
const LOCALE_PT_BR = 'pt';
const LOCALE_ES = 'es';
const LOCALE_PT_ANALYTICS_FORMAT = 'pt_BR';
const LOCALE_ES_ANALYTICS_FORMAT = 'es_ES';
const LOCALE_EN_ANALYTICS_FORMAT = 'en';

export interface IAnalyticsType {
  type: 'overview' | 'agents_performance' | 'salesdesk_performance' | 'csat_salesdesk';
}

const userLocaleLanguageFormat = (language: string) => {
  if (language.includes(LOCALE_PT_BR)) {
    return LOCALE_PT_ANALYTICS_FORMAT;
  }
  if (language.includes(LOCALE_ES)) {
    return LOCALE_ES_ANALYTICS_FORMAT;
  }

  return LOCALE_EN_ANALYTICS_FORMAT;
};

const Analytics: React.FC<IAnalyticsType> = ({ type }: IAnalyticsType) => {
  const { DateTimeFormat: dateTimeFormat } = Intl;
  const userTimeZone = dateTimeFormat().resolvedOptions().timeZone;
  const [dashboardURL, setDashboardURL] = useState('');
  const [botId, setBotId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [loading, setLoading] = useState(true);
  const { currentUserInfo } = useContext(AuthContext);
  const currentPath = localStorage.getItem(STORAGE_KEYS.PATH) || defaultPath;

  const loadAnalyticsData = async () => {
    try {
      const propsGetAnalytics = {
        type,
        userTimezone: userTimeZone,
        embedURL: window.location.hostname,
        userLocale: userLocaleLanguageFormat(navigator.languages[0]),
        userFirstName: currentUserInfo.name.split(' ')[0],
        userLastName: currentUserInfo.name.split(' ')[1],
        customerName,
        yaloBotId: botId,
      };

      const {
        data: { success, url },
      } = await getAnalytics(propsGetAnalytics);

      if (success) {
        setDashboardURL(url);
        setLoading(false);
      }
    } catch (error) {
      logError('error getAnalytics', error);
    }
  };

  const loadCustomerInfo = async () => {
    try {
      const {
        data: { BotID, CustomerName },
      } = await getCustomerInfo(currentPath);

      setBotId(BotID);
      setCustomerName(CustomerName);
    } catch (error) {
      logError('error getCustomerInfo', error);
    }
  };

  useEffect(() => {
    loadCustomerInfo();
  }, []);

  useEffect(() => {
    if (botId && customerName) {
      loadAnalyticsData();
    }
  }, [type, botId, customerName]);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <iframe data-testid="analytics" title="iframeAnalytics" style={{ width: '100%' }} src={dashboardURL} />
      )}
    </Container>
  );
};

export default Analytics;
