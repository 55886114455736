import { useTranslation } from 'react-i18next';
import { formatMessageTime } from 'helpers/formatterDates';
import { Status, Wrapper } from './styled';
import { getPropsForKind } from './utils';
import { SpecificMessageProps } from '../Message';

const StatusMessage: React.FC<SpecificMessageProps> = ({ status }) => {
  const { t, i18n } = useTranslation();

  if (!status) {
    return null;
  }

  const { kind, timestamp, message, ...rest } = status;
  const { iconClass, translationKey, translationOptions } = getPropsForKind(kind, rest);

  const baseMessage = t(translationKey, translationOptions);
  const notes = message ? `. ${t('messages.status.notes', { message })}` : '';
  const statusMessage = `${baseMessage}${notes}`;

  return (
    <Wrapper>
      <Status variant="caption1" color="neutral.var80">
        <i className={`fa-regular ${iconClass}`} />
        <span>{statusMessage}</span>
        <span>{formatMessageTime(Number(timestamp), i18n.language)}</span>
      </Status>
    </Wrapper>
  );
};

export default StatusMessage;
