import type { Root } from '@rocket.chat/message-parser';

export interface MessageType {
  id: string;
  msg: string;
  date: Date;
  from: string;
  username: string;
  color?: string;
  userId: string;
  attachments?: Array<IAttachment>;
  rid: string;
  ts?: string & {
    $date: number;
  };
  t: IMessageTypes;
  u: {
    name: string;
    username: string;
    _id: string;
  };
  transferData?: {
    comment: string;
    previousDepartment: string;
    scope: string;
    transferredBy?: {
      name: string;
      type: string;
      username: string;
      _id: string;
    };
    transferredTo?: {
      name: string;
      username: string;
      _id: string;
    };
  };
  customFields?: {
    timestamp: number;
    type: 'interactive';
    values: {
      action?: {
        button_reply: {
          id: number;
          title: string;
        };
      };
      type: string;
      list_reply: {
        id: number;
        title: string;
      };
    };
  };
  hasError?: boolean;
  alias?: string;
  md?: Root;
}

export interface MessageResponse extends MessageType {
  _id: string;
}

export enum LivechatStatus {
  STARTED = 'livechat-started',
  CLOSE = 'livechat-close',
  TRANSFER_HISTORY = 'livechat_transfer_history',
  PUT_CHAT_ON_HOLD = 'omnichannel_placed_chat_on_hold',
  CHAT_ON_HOLD_RESUME = 'omnichannel_on_hold_chat_resumed',
  COMMAND = 'command',
  UL = 'ul',
}

export type IMessageTypes =
  | LivechatStatus.STARTED
  | LivechatStatus.CLOSE
  | LivechatStatus.TRANSFER_HISTORY
  | LivechatStatus.PUT_CHAT_ON_HOLD
  | LivechatStatus.CHAT_ON_HOLD_RESUME
  | LivechatStatus.COMMAND
  | LivechatStatus.UL;

export interface IAttachment {
  image_dimensions?: {
    width: number;
    height: number;
  };
  image_preview?: string;
  image_size?: number;
  image_type?: string;
  image_url?: string;
  image_title?: string;
  image_title_link?: string;
  image_title_link_download?: boolean;
  audio_type?: string;
  audio_url?: string;
  title?: string;
  title_link?: string;
  video_type?: string;
  video_url?: string;
  ts: string;
  type: string;
}

export interface IPhotoSwipeImg {
  src?: string;
  srcSet?: string;
  html?: string;
  width?: number;
  height?: number;
  alt?: string;
}
