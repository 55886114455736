import { styled } from '@mui/material/styles';

export const InfoArea = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 0 0.8rem;
  line-height: 1.4rem;
`;
