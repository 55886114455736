import { AxiosResponse } from 'axios';
import AxiosInstance from './AxiosInstance';
import { User } from './types';

interface GetLivechatUserInfo {
  user: User;
  success: boolean;
}
export const getLivechatUserInfo = async (userId: string): Promise<AxiosResponse<GetLivechatUserInfo>> =>
  AxiosInstance.get(`livechat/users/agent/${userId}`);
