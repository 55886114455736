import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

export const CheckboxIcon = styled('span')`
  border-radius: 4px;
  width: 16px;
  height: 16px;
  border: ${({ theme }) => `1.3px solid ${theme.palette.neutral.var50}`};
  background-color: ${({ theme }) => theme.palette.neutral.var20};
`;

export const CheckboxCustom = styled(Checkbox)`
  padding: 0;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border-radius: 4px;
  & .MuiSvgIcon-root {
    font-size: 21px;
    padding: 0;
  }
  & input:focus + * {
    background: ${({ theme }) => theme.palette.informational.light};
    border-radius: 4px;
  }
`;
