import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { IconButton, Tab, Tabs } from '@engyalo/design-system';
import { maskPhoneNumber } from 'contexts/drawer/provider';
import { useTranslation } from 'react-i18next';
import { DrawerMessageType } from 'components/DrawerMessages/types.d';
import { Header, HeaderTitle, TabPanel } from './style';

import ChatsContext from '../../../contexts/chats/context';
import ContactInformation from '../ContactInformation';
import useEdit from '../EditContactInformation/useEdit';
import DrawerContext, { TABS } from '../../../contexts/drawer/context';
import EditContactInformation from '../EditContactInformation';
import HistoryChatsList from '../HistoryChatsList';
import { ModalEdit } from '../EditContactInformation/ModalEdit';
import DrawerMessages from '../../DrawerMessages';
import { ContactInfo } from '../../../services/types';

const ContactDrawer: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const { serviceSelected } = useContext(ChatsContext);
  const { contactInfo, isediting, setEditing, handleEditing, selectedTab, handleTabSelection } =
    useContext(DrawerContext);
  const [historyId, setHistoryId] = useState('');
  const [currentInfo, setCurrentInfo] = useState<ContactInfo>();
  const [isDirty, setIsDirty] = useState(false);
  const [showModalUnsavedChages, setShowModalUnsavedChages] = useState(false);
  const displayName = currentInfo?.name || maskPhoneNumber(contactInfo?.phone[0].phoneNumber || '');

  useEdit({
    token: contactInfo ? contactInfo.token : '',
    handleEditing,
  });

  const handleOpenHistory = (id?: string) => {
    setHistoryId(id || '');
  };

  const handleHistoryClose = () => {
    setHistoryId('');
  };

  const handleDirtyForm = (dirty: boolean) => {
    setIsDirty(dirty);
  };

  const handleBackModal = () => {
    if (isDirty) {
      setShowModalUnsavedChages(true);
    } else {
      handleEditing();
    }
  };

  const handleDrawerClose = () => {
    handleTabSelection(null);
  };

  const handleDrawerOpen = (_: unknown, tab: TABS) => {
    handleTabSelection(tab);
  };

  useEffect(() => {
    setCurrentInfo(contactInfo);

    // This flow should be reviewed
    // Probably the context is not necessary to control the editing state
    setEditing(false);
  }, [contactInfo, setEditing]);

  return (
    <>
      {historyId ? (
        <DrawerMessages type={DrawerMessageType.HISTORY} roomId={historyId} user="agent" onClose={handleHistoryClose} />
      ) : (
        <>
          {isMobile && (
            <IconButton
              color="neutral2"
              variant="text"
              onClick={() => {
                if (isediting) {
                  handleBackModal();
                } else {
                  handleDrawerClose();
                }
              }}
              sx={{ marginTop: '8px', marginLeft: '8px' }}
            >
              <i className="fa-regular fa-arrow-left fa-xs" />
            </IconButton>
          )}
          <Header isEditing={isediting}>
            <Tabs
              defaultValue={TABS.CONTACT_INFO}
              value={selectedTab}
              onChange={handleDrawerOpen}
              aria-label="tags tabs"
              sx={{
                width: '100%',
                gap: '0px !important',
                display: isediting === true ? 'none' : 'flex',
              }}
            >
              <Tab label={t('contacts')} id="contact-tab" aria-controls="contact-tabpanel" value={TABS.CONTACT_INFO} />
              <Tab label={t('history')} id="history-tab" aria-controls="history-tabpanel" value={TABS.CHATS_HISTORY} />
            </Tabs>

            <>
              {isediting && !isMobile && (
                <IconButton
                  color="neutral2"
                  variant="text"
                  data-testid="HeaderBackButtonIcon"
                  onClick={() => handleBackModal()}
                >
                  <i className="fa-regular fa-arrow-left fa-xs" />
                </IconButton>
              )}
              <HeaderTitle variant="subtitle1" color="neutral.var90" isEditing={isediting}>
                {`${t('edit')}  ${isediting ? displayName : ''}`}
              </HeaderTitle>
            </>
            {isediting === false && !isMobile && (
              <IconButton
                color="neutral2"
                variant="text"
                aria-label={t('close')}
                data-testid="headerIconCloseDrawer"
                onClick={handleDrawerClose}
              >
                <i className="fa-regular fa-xmark fa-xs" />
              </IconButton>
            )}
          </Header>
          <TabPanel
            role="tabpanel"
            id="contact-tabpanel"
            aria-labelledby="contact-tab"
            aria-hidden={selectedTab !== TABS.CONTACT_INFO}
            display={selectedTab === TABS.CONTACT_INFO}
            style={{ overflowY: 'scroll' }}
          >
            {selectedTab === TABS.CONTACT_INFO &&
              (isediting ? (
                <EditContactInformation
                  handleEditing={handleEditing}
                  isediting={isediting}
                  handleIsDirtyForm={handleDirtyForm}
                />
              ) : (
                <ContactInformation maskPhoneNumber={maskPhoneNumber} open />
              ))}
          </TabPanel>
          <TabPanel
            role="tabpanel"
            id="history-tabpanel"
            aria-labelledby="history-tab"
            aria-hidden={selectedTab !== TABS.CHATS_HISTORY}
            display={selectedTab === TABS.CHATS_HISTORY}
          >
            {selectedTab === TABS.CHATS_HISTORY && (
              <HistoryChatsList
                openHistory={(value) => handleOpenHistory(value)}
                roomId={currentInfo?.lastChat?._id ? currentInfo.lastChat._id : serviceSelected._id}
                visitorId={currentInfo?._id || serviceSelected._id}
              />
            )}
          </TabPanel>
        </>
      )}

      <ModalEdit
        open={showModalUnsavedChages}
        errorUpdt={false}
        handleEditing={(confirm) => {
          if (confirm) {
            handleEditing();
          }
          setShowModalUnsavedChages(false);
        }}
        handleModal={() => setShowModalUnsavedChages(false)}
      />
    </>
  );
};

export default ContactDrawer;
