import { styled } from '@mui/material/styles';

export const MessageGroup = styled('div')`
  position: relative;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.palette.neutral.var10};
`;

export const DateSeparator = styled('div')`
  z-index: 1;
  background-color: inherit;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.neutral.var70};
  background-color: ${({ theme }) => `${theme.palette.neutral.var10}80`};
  padding: 0.5rem 1rem;
  backdrop-filter: blur(1px);
  border-radius: 1rem;

  > * {
    order: 2;
  }

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    border-bottom: 0.1rem solid black;
    height: 0.1rem;
    margin: 0 1rem;
    border-color: ${({ theme }) => theme.palette.neutral.var50};
  }

  &::before {
    order: 1;
  }

  &::after {
    order: 3;
  }
`;
