import React, { useContext, useEffect, useState } from 'react';
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { Button, TextField, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { RocketChatUser } from 'services/types';
import { IFormUserProps, IFormCreateUserManager } from '../types';
import { Container, FormCustom, ListItemSelect, FieldsSection, FooterButton } from './style';
import { schemaCreateUserManager, schemaEditUserManager } from './yupSchema';
import GenericAutocomplete from '../../../../components/Autocomplete';
import { ReactComponent as Loading } from '../../../../assets/icons/loadingYalo.svg';
import { createUser } from '../../../../services/createUser';
import AuthContext from '../../../../contexts/auth/context';
import PasswordRules from '../../../../components/PasswordRules';
import { getUserInfo } from '../../../../services/getUserInfo';
import { updateUserInfo } from '../../../../services/updateUsersInfo';
import EditAvatar from '../../../../components/EditAvatar';
import useEnvironment from '../../../../hooks/useEnvironments';
import { setAvatar } from '../../../../services/setAvatar';
import LoadingAnimation from '../../../../components/AnimationLoading';
import WebsocketContext from '../../../../contexts/websocket/context';

const FormUserInfo: React.FC<IFormUserProps> = (props: IFormUserProps) => {
  const theme = useTheme();
  const { id, onCloseForm, isChangeForm, handleShowModalEditPassword, setShowErrorConnectionModal } = props;
  const { t } = useTranslation();
  const { currentUserInfo } = useContext(AuthContext);
  const { lastUpdatedAvatar } = useContext(WebsocketContext);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const rolesOptions = [{ _id: 'livechat-agent', name: t('agent') }];
  const managerOptions = [
    { _id: 'livechat-monitor', name: t('supervisor') },
    { _id: 'livechat-manager', name: t('manager') },
  ];
  if (currentUserInfo.roles.includes('livechat-manager')) {
    rolesOptions.push(...managerOptions);
  }

  const [loading, setLoading] = useState(false);

  const { getUrl } = useEnvironment();
  const baseURL = getUrl('http');

  const [avatarURL, setAvatarURL] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    getValues,
    setValue,
    getFieldState,
    setError,
  } = useForm<IFormCreateUserManager>({
    mode: 'onChange',
    resolver: yupResolver(id ? schemaEditUserManager : schemaCreateUserManager),
  });

  const getRoles = (role: string) => {
    if (role === 'livechat-agent') {
      return ['user', 'livechat-agent'];
    }
    if (role === 'livechat-monitor') {
      return ['user', 'livechat-agent', 'livechat-monitor'];
    }
    return ['user', 'livechat-agent', 'livechat-monitor', 'livechat-manager'];
  };

  const onSubmit: SubmitHandler<IFormCreateUserManager> = async (form) => {
    try {
      const { name, email, password, role, username } = form;

      const roles = getRoles(role);
      setLoadingRequest(true);

      const user: RocketChatUser = {
        name,
        email,
        username,
        roles,
      };

      const { data } = id
        ? await updateUserInfo({ ...user, userId: id })
        : await createUser({
            ...user,
            password,
            requirePasswordChange: true,
            sendWelcomeEmail: true,
            setRandomPassword: false,
            verified: true,
            joinDefaultChannels: true,
          });

      if (data.success && data.user) {
        if (role === 'livechat-manager') {
          onCloseForm('close', data.user._id);
          return;
        }
        onCloseForm('changeTab', data.user._id);
        return;
      }
      setShowErrorConnectionModal(true);
    } catch (error: any) {
      if (
        error.data &&
        error.data.errorType === 'error-input-is-not-a-valid-field' &&
        error.data.details?.field === 'Username'
      ) {
        setError('username', { type: 'custom', message: t('invalidValue') });
      }
      if (error.data && error.data.errorType === 'error-field-unavailable') {
        if (error.data.error.includes(`${form.email} is already in use`)) {
          setError('email', { type: 'custom', message: t('emailExists') });
          return;
        }
        if (error.data.error.includes(`${form.username} is already in use`)) {
          setError('username', {
            type: 'custom',
            message: t('usernameExists'),
          });
          return;
        }
      }
      setShowErrorConnectionModal(true);
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleUsername = () => {
    const email = getValues('email');
    if (email) {
      const username = email.split('@')[0];
      setValue('username', username);
    } else {
      setValue('username', '');
    }
  };

  const getContactData = async (loadAllData: boolean) => {
    if (loadAllData) {
      setLoading(true);
    }
    const {
      data: { user },
    } = await getUserInfo(id ?? '');

    const defaultAvatarURL = `${baseURL}/avatar/${user.username}?etag=${user.avatarETag}`;

    setAvatarURL(defaultAvatarURL);
    setValue('name', user.name);
    setValue('email', user.emails[0].address);
    setValue('username', user.username);

    const isManager = user.roles.includes('livechat-manager');

    if (isManager) {
      setValue('role', 'livechat-manager');
    } else if (user.roles.includes('livechat-monitor')) {
      setValue('role', 'livechat-monitor');
    } else {
      setValue('role', 'livechat-agent');
    }

    setLoading(false);
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      try {
        const { files } = event.target;
        if (files && files.length > 0) {
          const file = files[0];
          const formData = new FormData();
          formData.append('image', file, file.name);
          formData.append('userId', id ?? '');
          setAvatarURL('');
          await setAvatar(formData);
          setAvatarURL(lastUpdatedAvatar);
        }
      } catch (error) {
        console.log(error);
        setShowErrorConnectionModal(true);
      } finally {
        getContactData(false);
      }
    }
  };

  const checkHelperTextEmail = (errorsParameters: FieldErrors) => {
    if (errorsParameters.email?.type === 'custom') {
      return errorsParameters.email.message;
    }

    if (errorsParameters.email?.type === 'required') {
      return t('fieldIsRequired');
    }

    if (errorsParameters.email?.type === 'email') {
      return t('invalidEmail');
    }

    return '';
  };

  const checkHelperTextUserName = (errorsParameters: FieldErrors) => {
    if (errorsParameters.username?.type === 'custom') {
      return errorsParameters.username.message;
    }

    if (errorsParameters.username?.type === 'required') {
      return t('fieldIsRequired');
    }

    return '';
  };

  const handleRenderContentButton = () => {
    if (loadingRequest) {
      return <LoadingAnimation color={theme.palette.neutral.var90} />;
    }

    if (id) {
      return t('saveChanges');
    }

    return t('createUser');
  };

  useEffect(() => {
    setAvatarURL(lastUpdatedAvatar);
  }, [lastUpdatedAvatar]);

  useEffect(() => {
    handleUsername();
  }, [watch('email')]);

  useEffect(() => {
    isChangeForm(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (id) {
      getContactData(true);
    }
  }, [id]);

  return (
    <Container>
      {loading ? (
        <Box alignSelf="center">
          <Loading />
        </Box>
      ) : (
        <FormCustom onSubmit={handleSubmit(onSubmit)} data-testid="formUser">
          <FieldsSection>
            {id && (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <EditAvatar height="120px" width="120px" handleImage={uploadImage} urlImage={avatarURL} />
                </div>

                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleShowModalEditPassword}
                    fullWidth
                    variant="outlined"
                    startIcon={<i className="fa-regular fa-key" />}
                  >
                    {t('redefinePassword')}
                  </Button>
                </div>
              </>
            )}

            <div style={{ width: '100%' }}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('placeholderInputName')}
                    error={!!errors.name}
                    helperText={errors.name ? t('fieldIsRequired') : ''}
                    label={t('name')}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('placeholderInputEmail')}
                    error={!!errors.email}
                    helperText={checkHelperTextEmail(errors)}
                    label={t('email')}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </div>
            <div style={{ width: '100%' }}>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder=""
                    error={!!errors.username}
                    helperText={checkHelperTextUserName(errors)}
                    label={t('username')}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </div>
            {!id && (
              <div style={{ width: '100%' }}>
                <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
                  {t('password')}{' '}
                  <Tooltip title={t('tooltipPassword') || ''}>
                    <i
                      className="fa-solid fa-circle-question"
                      style={{
                        color: theme.palette.neutral.var50,
                        marginLeft: 3,
                      }}
                    />
                  </Tooltip>
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      data-testid="userPassword"
                      type="password"
                      sx={{ width: '100%' }}
                      placeholder={t('enterStrongPassword')}
                      error={!!errors.password}
                    />
                  )}
                />
                <PasswordRules watch={watch} getFieldState={getFieldState} />{' '}
              </div>
            )}
            <div style={{ width: '100%' }}>
              <Typography variant="caption1" color="neutral.var80" sx={{ marginTop: '0px' }}>
                {t('role')}
              </Typography>
              <Controller
                name="role"
                control={control}
                defaultValue={rolesOptions[0]._id}
                render={({ field }) => {
                  const { onChange, value } = field;
                  return (
                    <GenericAutocomplete
                      disableCloseOnSelect
                      defaultValue={value}
                      placeholder={t('selectOneOption')}
                      renderOption={(props, option) => {
                        props.className = '';
                        return (
                          <ListItemSelect disablePadding {...props}>
                            {t(option.name)}
                          </ListItemSelect>
                        );
                      }}
                      handleChange={(value) => {
                        onChange(value?._id);
                      }}
                      limitTagsNumber={1}
                      multiple={false}
                      options={rolesOptions}
                    />
                  );
                }}
              />
            </div>
          </FieldsSection>

          <FooterButton>
            <Button
              disabled={!isValid || loadingRequest}
              type="submit"
              fullWidth
              variant="contained"
              startIcon={loadingRequest ? null : <i className="fa-regular fa-check fa-sm" />}
            >
              {handleRenderContentButton()}
            </Button>
          </FooterButton>
        </FormCustom>
      )}
    </Container>
  );
};

export default FormUserInfo;
