import { useState, useEffect, useCallback } from 'react';
import { format, endOfDay, subDays, startOfDay } from 'date-fns';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import FeatureFlags from 'constants/featureFlags';
import { formatDateAndHour } from 'helpers/formatterDates';
import { getUsersListByUsernames } from 'services/getUsersList';
import isEqual from 'lodash.isequal';
import useEnvironment from '../../../hooks/useEnvironments';
import { Department, ChatStatus, UserOnlineStatus } from '../../../services/types';
import { STORAGE_KEYS } from '../../../constants/defaultValues';
import { renderAvatar } from './RenderAvatar';
import { getUserStats } from './getUserStats';
import { loadTeams } from './loadTeams';
import { loadAgentslistFromMultiDepartments } from './loadAgentslist';
import { IConversationStatus, getConversationsStats } from './getConversationsStats';
import { getDateFormat } from './getDateFormat ';

interface IAgents {
  agent?: unknown;
  username: string;
  status?: `${UserOnlineStatus}`;
  active?: boolean;
  open?: string;
  closed?: string;
  onhold?: string;
  avatarETag?: string;
  name?: string;
  type?: string;
  roles?: string[];
  lastLogin?: string;
  maxNumberSimultaneousChat?: string;
}

export interface IDepartments extends Department {
  unitId?: string;
  unitName?: string;
  numMonitors?: number;
}

export interface ITeamsDashboardAnalytics {
  _id: string;
  name: string;
}

interface IAgentTableAnalytics {
  agent: React.ReactNode;
  username: string;
  status?: `${UserOnlineStatus}`;
  open?: string;
  closed?: string;
  maxChats?: string;
  lastLogin?: string;
}

const TWENTY_FOUR_HOURS_IN_MILISECONDS = 86400000;
const THIRTY_SECONDS_IN_MILISECONDS = 30000;
const ONE_MINUTE_IN_MILISECONDS = 60000;
const FIVE_MINUTES_IN_MILISECONDS = 300000;
const TEN_MINUTES__IN_MILISECONDS = 600000;

export const TimeUpdateOptions = [
  { prefix: '', title: 'timeSelectorNever', value: TWENTY_FOUR_HOURS_IN_MILISECONDS },
  { prefix: 'every', title: '30 s', value: THIRTY_SECONDS_IN_MILISECONDS },
  { prefix: 'every', title: '1 min', value: ONE_MINUTE_IN_MILISECONDS },
  { prefix: 'every', title: '5 min', value: FIVE_MINUTES_IN_MILISECONDS },
  { prefix: 'every', title: '10 min', value: TEN_MINUTES__IN_MILISECONDS },
];

export const NOT_USERS = ['system', 'bot', 'app', 'admin'];

export default function useServiceDashboard() {
  const [shouldIncludePreviousDayData, setShouldIncludePreviousDayData] = useState(false);
  const [timeUpdateSelected, setTimeUpdateSelected] = useState(TWENTY_FOUR_HOURS_IN_MILISECONDS);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);
  const [teams, setTeams] = useState<ITeamsDashboardAnalytics[]>([]);
  const [agents, setAgents] = useState<IAgents[]>([]);
  const [conversationStats, setConversationStats] = useState<IConversationStatus>({} as IConversationStatus);
  const [agentsStats, setAgentStats] = useState<ChatStatus[]>([]);
  const [agentsTable, setAgentsTable] = useState<IAgents[]>([]);
  const [tableHeader, setTableHeader] = useState<string[]>([]);
  const [totalOnline, setTotalOnline] = useState(0);
  const showOnHold = useFeatureFlag(FeatureFlags.SHOW_ON_HOLD_METRIC);
  const [selectedTeams, setSelectedTeams] = useState<string[]>(() => {
    const previousSelectedTeam = localStorage.getItem(STORAGE_KEYS.DASHBOARD_SELECTED_TEAMS);
    if (previousSelectedTeam) {
      return JSON.parse(previousSelectedTeam);
    }
    return [];
  });

  const now = new Date();
  const dateStart = startOfDay(shouldIncludePreviousDayData ? subDays(now, 1) : now).toISOString();
  const [dateEnd, setDateEnd] = useState(endOfDay(now).toISOString());
  const [formatedDate, setFormatedDate] = useState('');
  const [formatedHour, setFormatedHour] = useState(format(now.getTime(), 'HH:mm'));
  const [loadingDataError, setLoadingDataError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getUrl } = useEnvironment();
  const baseUrl = getUrl('http');
  const languageLocalStorage = localStorage.getItem(STORAGE_KEYS.LANGUAGE) ?? '';

  const loadDashboard = useCallback(
    async (teamIds: string[]) => {
      const resultConversationStatus = await getConversationsStats(dateStart, dateEnd, teamIds, showOnHold);
      setConversationStats(resultConversationStatus);
      setAgentStats(await getUserStats(dateStart, dateEnd, teamIds));
      const departmentAgents = await loadAgentslistFromMultiDepartments(selectedTeams);
      const agentIds = departmentAgents.map((agent) => agent.username);
      const userList = await getUsersListByUsernames(agentIds);

      const agentsList: IAgents[] = departmentAgents.map((a) => {
        const user = userList.data.users.find((u) => u._id === a._id);
        const lastLogin = user?.lastLogin ? formatDateAndHour(new Date(user?.lastLogin), languageLocalStorage) : '';

        return {
          ...a,
          lastLogin,
          maxNumberSimultaneousChat: user?.livechat?.maxNumberSimultaneousChat,
        };
      });
      setAgents(agentsList);
      setLoading(false);
    },
    [dateEnd, dateStart, languageLocalStorage, selectedTeams, showOnHold]
  );

  useEffect(() => {
    const dateFormat = getDateFormat();
    const fetchBaseData = async () => {
      const { finalTeams, previousSelectedTeam } = await loadTeams();
      setTeams(finalTeams);
      if (!isEqual(previousSelectedTeam, selectedTeams)) {
        setSelectedTeams(previousSelectedTeam);
      }
    };

    fetchBaseData().catch(() => setLoadingDataError(true));
    setFormatedDate(format(new Date(), dateFormat));
  }, []);

  useEffect(() => {
    const agentColumn = (item: IAgents) => {
      const { username, name, avatarETag, status, active } = item;
      return renderAvatar(baseUrl, username, avatarETag, name, status || UserOnlineStatus.Online, active);
    };
    let finalAgents: IAgents[] = [];

    if (agentsStats) {
      finalAgents = agents.reduce((accumulator, currentAgent) => {
        const { username, status, lastLogin, maxNumberSimultaneousChat } = currentAgent;
        const agent = agentsStats.find((item) => item.username === username);

        const newAgent: IAgentTableAnalytics = {
          agent: agentColumn(currentAgent),
          username,
          status,
          lastLogin,
          open: '',
          maxChats: maxNumberSimultaneousChat,
          closed: '',
        } as IAgentTableAnalytics;

        if (agent) {
          newAgent.open = agent.open;
          newAgent.closed = agent.closed;
        }

        accumulator.push(newAgent);

        return accumulator;
      }, finalAgents);
    }

    setAgentsTable(finalAgents);

    const currentTotalOnline = finalAgents.filter((item) => item.status === 'online').length;

    setTotalOnline(currentTotalOnline);

    if (finalAgents.length) {
      setTableHeader(Object.keys(finalAgents[0]));
    }
  }, [agentsStats, agents]);

  useEffect(() => {
    setDateEnd(endOfDay(new Date()).toISOString());
    if (selectedTeams.length) {
      setLoading(true);
      loadDashboard(selectedTeams);
    }
    localStorage.setItem(STORAGE_KEYS.DASHBOARD_SELECTED_TEAMS, JSON.stringify(selectedTeams));
  }, [loadDashboard, selectedTeams, shouldIncludePreviousDayData]);

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    const id = setInterval(() => {
      setDateEnd(endOfDay(new Date()).toISOString());
      setFormatedHour(format(new Date().getTime(), 'HH:mm'));
      setLoading(true);
      loadDashboard(selectedTeams);
    }, timeUpdateSelected);
    setIntervalId(id);
    return () => clearInterval(intervalId);
  }, [selectedTeams, timeUpdateSelected]);

  return {
    shouldIncludePreviousDayData,
    setShouldIncludePreviousDayData,
    timeUpdateSelected,
    setTimeUpdateSelected,
    selectedTeams,
    setSelectedTeams,
    teams,
    agents,
    conversationStats,
    agentsTable,
    tableHeader,
    totalOnline,
    formatedDate,
    formatedHour,
    loadingDataError,
    setLoadingDataError,
    loading,
  };
}
