import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Contact = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 150px;
  justify-content: space-between;
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const MainText = styled(Typography)`
  width: 24rem;
  left: 0px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ItemForm = styled('div')`
  width: 100%;
`;

export const helperTextStyle = {
  alignSelf: 'end',
};
