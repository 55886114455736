import { datadogLogs } from '@datadog/browser-logs';
import pkg from '../../package.json';

function buildDatadogParameters(message: string, error?: unknown, messageContext?: Record<string, unknown> | unknown) {
  if (error instanceof Error) {
    return [message, messageContext, error];
  }

  if (error instanceof Object && messageContext instanceof Object) {
    return [message, { ...error, ...messageContext }];
  }

  if (!error) {
    return [message, messageContext];
  }

  return [message, error];
}

export enum ErrorStack {
  networkError = 'Network Error',
  stack = 'Failed to load',
  couldNotLoadUserflow = 'Could not load Userflow.js',
  Error401 = 'Request failed with status code 401',
}

export enum BASE_URL {
  splitIO = 'split.io',
  segmentIO = 'segment.io',
}

class Logger {
  private clientToken: string;

  private loggerInstance: any;

  private shouldLog: Boolean;

  constructor(clientToken: string) {
    this.clientToken = clientToken;
    this.shouldLog = Boolean(clientToken);
    this.startLog();
  }

  static beforeSend = (event: any) => {
    const { http } = event;
    const isFromExcludedHost = http?.url && Object.values(BASE_URL).find((baseURL) => http.url.includes(baseURL));
    const isFalsePositiveStackError = Object.values(ErrorStack).find((falseErrorString) =>
      event.error?.stack?.includes(falseErrorString)
    );
    if (isFromExcludedHost || isFalsePositiveStackError) {
      return false;
    }
    return event;
  };

  private startLog() {
    if (!this.shouldLog) {
      return;
    }

    datadogLogs.init({
      clientToken: this.clientToken,
      service: process.env.REACT_APP_DD_SERVICE,
      env: process.env.REACT_APP_DD_ENV,
      version: `${pkg.version}`,
      site: process.env.REACT_APP_DD_SITE,
      beforeSend: Logger.beforeSend,
    });

    this.loggerInstance = datadogLogs.logger;
  }

  public info(message: string, messageContext?: object | unknown) {
    if (this.shouldLog) {
      this.loggerInstance.info(...buildDatadogParameters(message, messageContext));
    }
  }

  public warn(message: string, error?: unknown, messageContext?: object | unknown) {
    if (this.shouldLog) {
      this.loggerInstance.warn(...buildDatadogParameters(message, error, messageContext));
    }
  }

  public error(message: string, error?: unknown, messageContext?: object | unknown) {
    if (this.shouldLog) {
      this.loggerInstance.error(...buildDatadogParameters(message, error, messageContext));
    }
  }
}

const logger = new Logger(process.env.REACT_APP_DD_TOKEN as string);
export const logInfo = logger.info.bind(logger);
export const logError = logger.error.bind(logger);
export const logWarn = logger.warn.bind(logger);
export const { beforeSend } = Logger;
